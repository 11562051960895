//import { sleepMs } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';

export const createAuditsQuery = async (interval, serviceName, entityType, action) => {
  console.log('createAuditsQuery()');
  const headers = await getAccessHeaders();

  const data = {
    interval,
    serviceName,
    filters: [],
  };

  //#region Optional Fields

  if (entityType && entityType !== '<ALL>') {
    data.filters.push({
      property: 'EntityType',
      value: entityType,
    });
  }

  if (action && action !== '<ALL>') {
    data.filters.push({
      property: 'Action',
      value: action,
    });
  }

  return handleResponse(
    await fetch(`https://api.${headers.env}/api/v2/audits/query`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', authorization: `bearer ${headers.token}` },
      body: JSON.stringify(data),
    })
  );
};

export const getAuditsQueryStatus = async (queryId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`https://api.${headers.env}/api/v2/audits/query/${queryId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', authorization: `bearer ${headers.token}` },
    })
  );
};
export const getAuditsQueryResults = async (queryId, cursor) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`https://api.${headers.env}/api/v2/audits/query/${queryId}/results?pageSize=100${cursor ? `&cursor=${cursor}` : ''}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', authorization: `bearer ${headers.token}` },
    })
  );
};
export const getAuditsQueryServiceMapping = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`https://api.${headers.env}/api/v2/audits/query/servicemapping`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', authorization: `bearer ${headers.token}` },
    })
  );
};

//#region "EVENT BRIDGE INTEGRATION"

export const getEbIntegration = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/auditviewer/genesyscloud/eb-integration`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postEbIntegration = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/auditviewer/genesyscloud/eb-integration`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const deleteEbIntegration = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/auditviewer/genesyscloud/eb-integration`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "EVENTS FILTERS"

export const getEventFilterPresets = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/auditviewer/events-filters/presets`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getEventsFilters = async (protocol, endpoint) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/auditviewer/events-filters?protocol=${encodeURIComponent(protocol)}&endpoint=${encodeURIComponent(endpoint)}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    }),
    true,
    false
  );
};

export const putEventsFilter = async (data) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/auditviewer/events-filters`, {
      method: 'PUT',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const deleteEventsFilter = async (protocol, endpoint) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/auditviewer/events-filters?protocol=${encodeURIComponent(protocol)}&endpoint=${encodeURIComponent(endpoint)}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion
