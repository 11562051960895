import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Tabs, Popconfirm, notification } from 'antd';
import { FaRegTrashCan } from 'react-icons/fa6';
import { getConversations, deleteUserConversations } from 'src/api/securityqa';
import useUserInfo from 'src/hooks/useUserInfo';
import SQAConversationContext from 'src/context/SQAConversationContext';

const SettingsModal = ({ onClose }) => {
  const userInfo = useUserInfo();

  const [showModal, setShowModal] = useState(true);

  const { setConversations, setConversation } = useContext(SQAConversationContext);

  const handleOk = async () => {
    setShowModal(false);
    if (onClose) onClose();
  };

  const handleCancel = async () => {
    setShowModal(false);
    if (onClose) onClose();
  };

  const handleDeleteAllConversations = async () => {
    console.log('Deleting all conversations');
    try {
      await deleteUserConversations(userInfo.id);

      // Refresh list of conversations
      const currentConversations = await getConversations(userInfo.id);
      setConversations(currentConversations);

      // Clear the current conversation
      setConversation(null);
    } catch (error) {
      console.error(error);
      notification.error({ message: 'Error', description: error.message });
    }
  };

  return (
    <>
      {showModal && (
        <Modal
          open={showModal}
          title={'Settings'}
          onCancel={handleCancel}
          footer={[
            <Button key='btnOK' type='primary' className='btn-label btn-margin-right' onClick={handleOk}>
              OK
            </Button>,
            <Button key='btnCancel' className='btn-label btn-margin-right' onClick={handleCancel}>
              Cancel
            </Button>,
          ]}>
          <Tabs
            tabPosition='left'
            type='card'
            size='large'
            items={[
              {
                label: 'General',
                key: '1',
                children: (
                  <>
                    <div className='flex flex-row items-center justify-between'>
                      Delete all conversations
                      <Popconfirm title='Delete all conversations' description='Are you sure? This cannot be undone' okText='Yes' cancelText='No' onConfirm={handleDeleteAllConversations}>
                        <Button type='primary' danger>
                          <FaRegTrashCan />
                        </Button>
                      </Popconfirm>
                    </div>
                  </>
                ),
              },
            ]}
          />
        </Modal>
      )}
    </>
  );
};

SettingsModal.propTypes = {
  onClose: PropTypes.func,
};

export default SettingsModal;
