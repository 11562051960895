import React, { useState, useEffect, useContext } from 'react';
import FsiReportContext from 'src/context/FsiReportContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import { getProjectQuestionsAvailable } from 'src/api/fsireport';
import NoData from 'src/components/layout/NoData';
import QuestionAddModalItem from './QuestionAddModalItem';
import { notArrayOrEmpty } from 'src/misc/Misc';
import SkeletonTable from 'src/components/layout/SkeletonTable';

const QuestionAddModal = ({ onSubmit, onClose }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { currentProject } = useContext(FsiReportContext);

  const [questionsAvailable, setQuestionsAvailable] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getProjectQuestionsAvailable(currentProject.id);
        setQuestionsAvailable(resp);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleQuestionOnSelect = (e) => {
    const { id, selected } = e;
    if (selected && !selectedQuestions.includes(id)) {
      setSelectedQuestions([...selectedQuestions, id]);
    } else if (!selected && selectedQuestions.includes(id)) {
      setSelectedQuestions(selectedQuestions.filter((questionId) => questionId !== id));
    }
  };

  const handleOnClose = () => {
    if (!onClose) return;
    onClose();
  };

  const handleOnSubmit = () => {
    if (!onSubmit) return;
    onSubmit(selectedQuestions);
  };

  return (
    <Modal
      title='Add questions'
      width={800}
      open={true}
      onCancel={handleOnClose}
      footer={[
        <Button key='Submit' type='primary' onClick={handleOnSubmit} disabled={true === notArrayOrEmpty(selectedQuestions)}>
          Add {selectedQuestions.length === 1 ? `${selectedQuestions.length} question` : `${selectedQuestions.length} questions`}
        </Button>,
        <Button key='Cancel' onClick={handleOnClose}>
          Cancel
        </Button>,
      ]}>
      {null === questionsAvailable && <SkeletonTable />}
      {questionsAvailable?.length === 0 && (
        <div className='p-12 flex justify-center'>
          <NoData
            label={
              <div className='text-center'>
                All questions
                <br />
                haven already been added to this report
              </div>
            }
          />
        </div>
      )}

      {questionsAvailable?.length > 0 && (
        <div className='flex flex-col gap-2'>
          {questionsAvailable.map((questionAvailable, index) => (
            <QuestionAddModalItem key={index} questionAvailable={questionAvailable} onSelect={handleQuestionOnSelect} />
          ))}{' '}
        </div>
      )}
    </Modal>
  );
};

QuestionAddModal.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default QuestionAddModal;
