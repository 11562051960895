import React from 'react';
import SubjectPicker from '../SubjectPicker';

const DSRModeRealOrFakeStep = () => {
  return (
    <div>
      <div className='font-bold text-xl '>Search for a GDPR Subject</div>
      <div className='font-extralight'>
        <div className='mt-3'>
          <SubjectPicker />
        </div>
        <div className='mt-2'>
          Once you have a contact selected, click on <strong>Next</strong> to continue.
        </div>
      </div>
    </div>
  );
};

DSRModeRealOrFakeStep.propTypes = {};

export default DSRModeRealOrFakeStep;
