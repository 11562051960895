import React, { Component } from 'react';
import SecurityQaWidgetContext from './SecurityQaWidgetContext';

var conversationGlobal = null;
export default class SecurityQaWidgetProvider extends Component {
  state = {
    conversation: null,
    theme: null,
  };

  render() {
    return (
      <SecurityQaWidgetContext.Provider
        value={{
          ...this.state,
          setTheme: (theme) => {
            this.setState({ theme });
          },
          setConversation: (conversation) => {
            conversationGlobal = conversation;
            this.setState({ conversation });
          },
          addQuestion: (question) => {
            const conversation = { ...conversationGlobal };
            conversation.content.push({
              message: question,
              role: 'user',
            });
            conversationGlobal = conversation;
            this.setState({ conversation });
          },
          updateResponse: (response) => {
            const conversation = { ...conversationGlobal };
            if (conversation.content[conversation.content.length - 1].role === 'system') {
              conversation.content[conversation.content.length - 1].message += response;
            } else {
              conversation.content.push({
                role: 'system',
                message: response,
              });
            }
            conversationGlobal = conversation;
            this.setState({ conversation });
          },
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </SecurityQaWidgetContext.Provider>
    );
  }
}
