import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';
import { getStepLabel, getStatusLabel, getStepIcon, getStatusColor, getStepStage } from '../Misc/Dictionary';
import UserJourneyChart from '../Components/UserJourneyChart/UserJourneyChart';

const JobStatus = ({ currentJob, steps }) => {
  if (!currentJob) return null;
  if (!Array.isArray(steps)) return null;

  const getItems = () => {
    let myItems = [];
    for (const step of steps) {
      myItems.push({
        timestamp: step.start_time ?? null,
        stageId: getStepStage(step.step),
        color: getStatusColor(step.status),
        icon: getStepIcon(step.step),
        blink: step.status === 'waiting' || step.status === 'searching',
        props: {
          step: getStepLabel(step.step),
          status: getStatusLabel(step.status),
          message: step.message,
        },
      });
    }

    return myItems;
  };

  const getStages = () => {
    if (!currentJob?.type) return [];
    switch (currentJob.type) {
      case '1':
        return [{ id: 'gdpr', label: 'Status' }];
      default:
        // type 2 is a default
        return [
          { id: 'setup', label: 'Setup' },
          { id: 'interacting', label: 'Interacting' },
          { id: 'report', label: 'Report' },
          { id: 'gdpr', label: 'GDPR' },
        ];
    }
  };

  return (
    <>
      <Row>
        <Col style={{ width: '100%' }}>
          <UserJourneyChart stageList={getStages()} itemList={getItems()} />
        </Col>
      </Row>
    </>
  );
};

JobStatus.propTypes = {
  currentJob: PropTypes.object,
  steps: PropTypes.array,
};

JobStatus.defaultProps = {};

export default JobStatus;
