import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import useTheme from 'src/hooks/useTheme';
import { Button, Form, Input, InputNumber, Modal, notification, Select, Space, Popconfirm, Checkbox, Tooltip, Table, Upload, message } from 'antd';
import { getAiModelConnectors, patchAiModelConnectorConfig, postAiModelConnectorConfig, deleteAiModelConnectorConfig, startAiModelConnectorConfig, isRunning, listS3Files, prepareToUploadS3Object, deleteS3Object } from 'src/api/aiModels';
import { EyeInvisibleOutlined, EyeTwoTone, InboxOutlined } from '@ant-design/icons';
import AIModelsContext from 'src/context/AIModelsContext';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { PiFoldersFill } from 'react-icons/pi';
import { FaRegTrashCan } from 'react-icons/fa6';
import { sleepMs } from 'src/misc/Misc';
import styled from 'styled-components';
import MultipleInput from 'src/components/controls/MultipleInput/MultipleInput';
import SharepointItemsPicker from 'src/components/controls/SharepointItemsPicker/SharepointItemsPicker';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ReactJson from 'react-json-view';

dayjs.extend(relativeTime);

const { Option } = Select;
const { Dragger } = Upload;

const StylesWrapper = styled.div`
  .ant-upload .ant-upload-btn {
    padding: 0 !important;
  }
`;

const AiModelConnectorsModal = ({ aiModelId, connector, open, onClose, onSubmit }) => {
  const [form] = Form.useForm();
  const { theme } = useTheme();
  const { isDarkMode } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();

  const [edit, setEdit] = useState(connector ? true : false);
  const [disabled, setDisabled] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [busy, setBusy] = useState(false);
  const [connectorStatus, setConnectorStatus] = useState(true);
  const [isRunningDocker, setIsRunningDocker] = useState(null);
  const [activeConnectorDetails, setActiveConnectorDetails] = useState(null);
  const [startingDocker, setStartingDocker] = useState(false);
  const [busyTable, setBusyTable] = useState(false);
  const [showSharepointItemsPicker, setShowSharepointItemsPicker] = useState(false);

  const [siteMode, setSiteMode] = useState(false);

  const [s3Files, setS3Files] = useState([]);
  const [previewRFPIOQuery, setPreviewRFPIOQuery] = useState({});

  const [selectedAiModelConnector, setSelectedAiModelConnector] = useState(null);

  const [uploadS3Url, setUploadS3Url] = useState(null);
  const [uploadS3Processed, setUploadS3Processed] = useState([]);

  const { removeConnectorDef, aiModelConnectors, setAiModelConnectors } = useContext(AIModelsContext);
  const { TextArea } = Input;

  const s3UploadProps = {
    name: 'file',
    action: uploadS3Url,
    method: 'PUT',
    multiple: false,
    openFileDialogOnClick: false,
    showUploadList: uploadS3Url ? true : false,
    fileList: uploadS3Processed,

    beforeUpload: async (file) => {
      const resp = await prepareToUploadS3Object(aiModelId, connector.id, file.name);
      setUploadS3Url(resp);
      await sleepMs(100); // wait for the URL to be set
    },

    onChange(info) {
      console.log('onChange', info.file.status);

      setUploadS3Processed(info.fileList.filter((f) => f.status === 'uploading'));
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setUploadS3Url(null);
        loadS3Files(selectedAiModelConnector, connector);
      } else if (info.file.status === 'error') {
        console.log('Upload error:', info);
        message.error(`${info.file.name} file upload failed.`);
        setUploadS3Url(null);
      }
    },
  };

  useEffect(() => {
    if (aiModelId) loadData();
  }, [aiModelId]);

  const updateRFPIOPreviewWindow = (e) => {
    try {
      const cleanedJSON = e
        .replace(/\\n/g, '') // Remove escaped newlines
        .replace(/\\"/g, '"') // Unescape quotes
        .replace(/'/g, '"')
        .trim(); // Trim spaces

      setPreviewRFPIOQuery(JSON.parse(cleanedJSON));
    } catch (error) {
      setPreviewRFPIOQuery({ error: 'Invalid JSON format' });
    }
  };

  useEffect(() => {
    console.log('[Modal] aiModelConnectors:', aiModelConnectors);
    console.log('[Modal] selectedAiModelConnector:', selectedAiModelConnector);

    if (edit && aiModelConnectors?.length > 0) {
      setSelectedAiModelConnector(connector);

      console.log('[Modal] all AI model connectors:', aiModelConnectors);
      console.log('[Modal] Selected connector:', connector);
      const aiModelConnector = aiModelConnectors.find((x) => connector.connector_id === x.id);
      console.log('[Modal] aiModelConnector:', aiModelConnector);

      form.setFieldsValue({
        id: connector.id,
        name: connector.name,
        description: connector.description,
        connector_id: connector.connector_id,
      });

      console.log('Current connector Status: ', connector?.aws_config?.enabled);
      setConnectorStatus(connector?.aws_config?.enabled);

      switch (selectedAiModelConnector?.name) {
        case 'Web':
          console.log('Set fields for WebConnector:', connector);
          form.setFieldsValue({
            scraper_base_url: connector.config.scraper_base_url,
            scraper_allowed_paths: connector.config.scraper_allowed_paths,
            scraper_disallowed_paths: connector.config.scraper_disallowed_paths,
            scraper_depth_limit: connector.config.scraper_depth_limit,
            scraper_pages_total_limit: connector.config.scraper_pages_total_limit,
          });
          break;
        case 'Genesys Sharepoint':
          console.log('Set fields for G Sharepoint');
          form.setFieldsValue({
            root_folder_name: connector.config.root_folder_name,
            hostname: connector.config.hostname,
            drive_id: connector.config.drive_id,
            site_id: connector.config.site_id,
            file_age_days: connector.config.file_age_days,
            tag_folder_path: connector.config.tag_folder_path,
          });

          setSiteMode(connector.config.site_id ? true : false);

          break;
        case 'Genesys S3':
          loadS3Files(selectedAiModelConnector, connector);
          break;
        case 'RFPIO':
          console.log('Set fields for RFPIO');
          form.setFieldsValue({
            rfpio_query: connector.config.rfpio_query,
          });

          updateRFPIOPreviewWindow(connector.config.rfpio_query);

          break;
        case 'Seismic':
          console.log('Set fields for Seismic');
          form.setFieldsValue({
            team_site_id: connector.config.team_site_id,
            content_ids: connector.config.content_ids,
          });
          break;
        default:
          break;
      }

      handleOnSelect(connector.connector_id);
      console.log('Form.values:', form.getFieldsValue());
    } else if (selectedAiModelConnector && aiModelConnectors?.length > 0) {
      console.log('Set default values for selected connector:', selectedAiModelConnector.id);
      switch (selectedAiModelConnector?.name) {
        case 'Web':
          form.setFieldsValue({
            scraper_base_url: '',
            scraper_allowed_paths: [],
            scraper_disallowed_paths: [],
            scraper_depth_limit: 10,
            scraper_pages_total_limit: 500,
          });
          break;
        case 'Genesys Sharepoint':
          form.setFieldsValue({
            root_folder_name: '',
            hostname: 'genesyslab.sharepoint.com',
            drive_id: '',
            site_id: '',
            file_age_days: 365,
            tag_folder_path: false,
          });
          break;
        case 'RFPIO':
          // default RFPIO query add
          form.setFieldsValue({
            rfpio_query: '{\n  "collectionList": ["Genesys"]\n}',
          });

          updateRFPIOPreviewWindow('{\n  "collectionList": ["Genesys"]\n}');
          break;
        case 'Seismic':
          form.setFieldsValue({
            team_site_id: '1',
            content_ids: [],
          });
          break;
        default:
          break;
      }
    }
  }, [aiModelConnectors, selectedAiModelConnector]);

  const loadData = async () => {
    console.log('[Modal] Load data for aiModelConnectors');
    try {
      if (connector) {
        const isRun = await isRunning(connector.id);
        setIsRunningDocker(isRun?.lastStatus ? true : false);
        setActiveConnectorDetails(isRun);
      }
    } catch (error) {
      console.error(error);
    }

    if (aiModelConnectors.length > 0) return;
    executeAsyncProcess(async () => {
      console.log('[Modal] Load data for aiModelConnectors');
      try {
        const currentAiModelConnectors = await getAiModelConnectors();
        setAiModelConnectors(currentAiModelConnectors);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const loadS3Files = async (modelDef, connector) => {
    console.log('loadS3Files()', modelDef, connector);
    try {
      switch (modelDef.name) {
        case 'Genesys S3':
          console.log('listFiles ...');
          setBusyTable(true);
          const resp = await listS3Files(connector.model_id, connector?.id);
          console.log('listS3Files:', resp);
          setS3Files(resp);
          setBusyTable(false);
          break;

        default:
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBusyTable(false);
    }
  };

  const handleOnFinish = async (values) => {
    values.connector_id = selectedAiModelConnector.id;
    console.log('[Modal] Success:', values);
    setUpdating(true);

    let setFormFailed = false;

    let currentAwsConfig = connector?.aws_config || {};
    currentAwsConfig = { ...currentAwsConfig, enabled: connectorStatus };

    try {
      if (edit) {
        switch (selectedAiModelConnector?.name) {
          case 'Web':
            console.log('update connector def (web)', values);

            values = {
              connector_id: values.connector_id,
              id: values.id,
              name: values.name,
              description: values.description,
              config: {
                scraper_base_url: values.scraper_base_url,
                scraper_allowed_paths: values.scraper_allowed_paths,
                scraper_disallowed_paths: values.scraper_disallowed_paths,
                scraper_depth_limit: values.scraper_depth_limit,
                scraper_pages_total_limit: values.scraper_pages_total_limit,
              },
            };

            await patchAiModelConnectorConfig(connector.id, {
              name: values.name,
              description: values.description,
              config: values.config,
              aws_config: currentAwsConfig,
            });

            console.log('Config updated');

            break;
          case 'Genesys Sharepoint':
            console.log('update connector def (Genesys Sharepoint)');

            values = {
              connector_id: values.connector_id,
              id: values.id,
              name: values.name,
              description: values.description,
              config: {
                hostname: values.hostname,
                drive_id: siteMode ? undefined : values.drive_id,
                root_folder_name: siteMode ? undefined : values.root_folder_name,
                site_id: values.site_id,
                tag_folder_path: values.tag_folder_path,
                file_age_days: values.file_age_days,
              },
            };

            await patchAiModelConnectorConfig(connector.id, {
              name: values.name,
              description: values.description,
              config: values.config,
              aws_config: currentAwsConfig,
            });

            console.log('Config updated');

            break;
          case 'Genesys S3':
            console.log('update connector def (Genesys S3)');

            values = {
              connector_id: values.connector_id,
              id: values.id,
              name: values.name,
              description: values.description,
            };

            await patchAiModelConnectorConfig(connector.id, {
              name: values.name,
              description: values.description,
              aws_config: currentAwsConfig,
            });

            console.log('Config updated');

            break;
          case 'RFPIO':
            console.log('update connector def (RFPIO)');
            const rfipoQuery = values.rfpio_query.replace(/'/g, '"');
            try {
              JSON.parse(rfipoQuery); // parse the JSON string
            } catch (error) {
              message.error('Invalid JSON format');
              console.log(error);
              setFormFailed = true;
              return;
            }

            values = {
              connector_id: values.connector_id,
              id: values.id,
              name: values.name,
              description: values.description,
              config: {
                rfpio_query: rfipoQuery,
              },
            };

            await patchAiModelConnectorConfig(connector.id, {
              name: values.name,
              description: values.description,
              config: values.config,
              aws_config: currentAwsConfig,
            });

            console.log('Config updated');

            break;
          case 'Seismic':
            console.log('update connector def (Seismic)');

            values = {
              connector_id: values.connector_id,
              id: values.id,
              name: values.name,
              description: values.description,
              config: {
                team_site_id: values.team_site_id,
                content_ids: values.content_ids,
              },
            };

            await patchAiModelConnectorConfig(connector.id, {
              name: values.name,
              description: values.description,
              config: values.config,
              aws_config: currentAwsConfig,
            });

            console.log('Config updated');

            break;
          default:
            console.error('Unknown connector_id:', values.connector_id);
            return;
        }
      } else {
        switch (selectedAiModelConnector?.name) {
          case 'Web':
            console.log('create connector def', selectedAiModelConnector?.name);
            await postAiModelConnectorConfig({
              name: values.name,
              description: values.description,
              connector_id: values.connector_id,
              model_id: aiModelId,
              config: {
                scraper_base_url: values.scraper_base_url,
                scraper_allowed_paths: values.scraper_allowed_paths,
                scraper_disallowed_paths: values.scraper_disallowed_paths,
                scraper_depth_limit: values.scraper_depth_limit,
                scraper_pages_total_limit: values.scraper_pages_total_limit,
              },
              aws_config: currentAwsConfig,
            });

            break;
          case 'Genesys Sharepoint':
            console.log('create connector def', selectedAiModelConnector?.name);
            await postAiModelConnectorConfig({
              name: values.name,
              description: values.description,
              connector_id: values.connector_id,
              model_id: aiModelId,
              config: {
                hostname: values.hostname,
                drive_id: siteMode ? undefined : values.drive_id,
                root_folder_name: siteMode ? undefined : values.root_folder_name,
                site_id: values.site_id,
                tag_folder_path: values.tag_folder_path,
                file_age_days: values.file_age_days,
              },
              aws_config: currentAwsConfig,
            });

            break;
          case 'Genesys S3':
            console.log('create connector def', selectedAiModelConnector?.name);
            await postAiModelConnectorConfig({
              name: values.name,
              description: values.description,
              connector_id: values.connector_id,
              model_id: aiModelId,
              config: {},
              aws_config: currentAwsConfig,
            });

            break;
          case 'RFPIO':
            console.log('create connector def', selectedAiModelConnector?.name);
            const rfipoQuery = values.rfpio_query.replace(/'/g, '"');
            try {
              JSON.parse(rfipoQuery); // parse the JSON string
            } catch (error) {
              message.error('Invalid JSON format');
              console.log(error);
              setFormFailed = true;
              return;
            }

            await postAiModelConnectorConfig({
              name: values.name,
              description: values.description,
              connector_id: values.connector_id,
              model_id: aiModelId,
              config: {
                rfpio_query: rfipoQuery,
              },
              aws_config: currentAwsConfig,
            });

            break;
          case 'Seismic':
            console.log('create connector def', selectedAiModelConnector?.name);
            await postAiModelConnectorConfig({
              name: values.name,
              description: values.description,
              connector_id: values.connector_id,
              model_id: aiModelId,
              config: {
                team_site_id: values.team_site_id,
                content_ids: values.content_ids,
              },
              aws_config: currentAwsConfig,
            });

            break;
          default:
            console.error('Unknown connector_id:', selectedAiModelConnector?.name);
            return;
        }
      }

      values.aws_config = currentAwsConfig;
      values.aws_config.enabled = connectorStatus;
    } catch (error) {
      console.error(error);
    } finally {
      if (!setFormFailed) {
        if (onSubmit) onSubmit(values);
      }
      setUpdating(false);
    }
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('[Modal] Failed:', errorInfo);
    notification.error({
      message: 'Error',
      description: <>Provided data is invalid</>,
      duration: 5,
    });
  };

  const handleOnReset = () => {
    //setModified(false);
    setEdit(false);
  };

  const checkConnectorSelected = (rule, value) => {
    if (selectedAiModelConnector) {
      return Promise.resolve();
    }
    return Promise.reject('Select an AI model connector');
  };

  const handleOnSelect = (v) => {
    console.log('handleOnSelect() set SelectedConnector ', v);
    const currentSelectedAiModelConnector = aiModelConnectors.find((aimc) => aimc.id === v);
    setSelectedAiModelConnector(currentSelectedAiModelConnector);
  };

  const handleOnValuesChange = async (changedValues, allValues) => {
    form
      .validateFields()
      .then(() => {
        setDisabled(false);
      })
      .catch((errorFields) => {
        setDisabled(errorFields.errorFields.length > 0);
      });
  };

  const handleDeleteConnector = async () => {
    setDeleting(true);
    console.log('Delete connector_id:', connector.id);
    try {
      await deleteAiModelConnectorConfig(connector.id);
      removeConnectorDef(connector.id);
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleting(false);
    }
  };

  const handleOnStartConnectorDef = async () => {
    console.log('Start connector_id:', connector.id);
    try {
      setStartingDocker(true);
      await startAiModelConnectorConfig(connector.id);
      setIsRunningDocker(true);
    } catch (error) {
      console.error(error);
    } finally {
      setStartingDocker(false);
    }
  };

  const onChangeStatus = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setConnectorStatus(e.target.checked);
  };

  const deleteS3Item = async (id) => {
    console.log('deleteS3Item', id);
    setBusy(true);

    try {
      await deleteS3Object(aiModelId, connector.id, id);
      setS3Files(s3Files.filter((f) => f.name !== id));
    } catch (error) {
      console.log(error);
    } finally {
      setBusy(false);
    }
  };

  const handleUpdateSharepointData = (data) => {
    console.log('handleUpdateSharepointData', data);
    setShowSharepointItemsPicker(false);
    if (!data) return;

    form.setFieldsValue({
      root_folder_name: data.folderRootPath,
      drive_id: data.driveId,
      site_id: data.siteId,
    });

    //setSiteMode(connector.config.site_id ? true : false);
  };

  return (
    <Modal
      open={open}
      title={
        edit ? (
          <>
            <span>Edit Connector</span>
            <span className='text-xs text-center text-gray-500 ml-2 align-top'>({connector.id})</span>
          </>
        ) : (
          'Add a Connector'
        )
      }
      forceRender
      okButtonProps={{ disabled: disabled }}
      onCancel={() => {
        if (onClose) onClose();
      }}
      footer={[
        <>
          <div className={`flex flex-auto ${updating || deleting ? 'disabled' : ''}`}>
            <div className='flex flex-auto text-left'>
              {edit && (
                <Popconfirm title='Delete connector' description='Are you sure? This cannot be undone.' okText='Yes' cancelText='No' onConfirm={handleDeleteConnector}>
                  <Button key='delete' danger className='text-left'>
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </div>

            <div className='flex  text-right'>
              <Button key='cancel' onClick={onClose}>
                Cancel
              </Button>
              <Button loading={updating} key='submit' type='primary' className='ml-2' onClick={form.submit}>
                Submit
              </Button>
            </div>
          </div>
          {/* <div className='text-center pt-2 text-xs'>
            <a href={`https://eu-central-1.console.aws.amazon.com/cloudwatch/home?region=eu-central-1#logsV2:log-groups/log-group/$252Fecs$252F${process.env.STAGE}-spc-embeddings-web-${selectedModel.id}-${currentConnectorAWSConfig?.id}`}>AWS Docker Logs</a>
          </div> */}
        </>,
      ]}>
      <StylesWrapper>
        <Form disabled={updating || deleting || busy} form={form} style={{ maxWidth: 600 }} layout='vertical' initialValues={{}} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} onReset={handleOnReset} onValuesChange={handleOnValuesChange} autoComplete='off'>
          <Form.Item name='id' hidden={true} />
          <Form.Item label='Connector Name (source name)' name='name' rules={[{ required: true, message: 'Enter a name for your connector' }]}>
            <Input disabled={edit} placeholder='Enter a name for your connector' autoComplete='off' maxLength={64} allowClear />
          </Form.Item>
          <Form.Item label='Description' name='description' rules={[{ required: true, message: 'Enter a description of your cnnector' }]}>
            <Input placeholder='Enter a quick description of your connector' autoComplete='off' maxLength={255} allowClear />
          </Form.Item>
          <Form.Item label='Available Connectors' name='connector_id' rules={[{ required: true, message: 'Select an AI model connector', validator: checkConnectorSelected }]}>
            <div className='ml-1 mt-2'>
              <Select style={{ width: '100%' }} placeholder='Select an AI model connector' allowClear value={edit ? connector.connector_id : selectedAiModelConnector?.connector_id} onSelect={handleOnSelect} disabled={edit}>
                {aiModelConnectors?.map((aiModelConnector) => (
                  <Option key={aiModelConnector.id} value={aiModelConnector.id}>
                    {aiModelConnector.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Form.Item>
          <div className='flex flex-auto'>
            <Checkbox checked={connectorStatus} onChange={onChangeStatus} className={`${selectedAiModelConnector ? '' : 'hidden'} flex flex-auto mb-2`}>
              <span>Enabled</span>
              <span className='text-xs pl-2'>(scheduled to run every midnight)</span>
            </Checkbox>
            {edit && (
              <Tooltip placement='bottom' title={`${isRunningDocker ? `Current status: ${activeConnectorDetails.lastStatus ? activeConnectorDetails.lastStatus.toLowerCase() : 'Unknown'} ${activeConnectorDetails.startedAt ? `(${dayjs(activeConnectorDetails.startedAt).fromNow()})` : ''}` : ''}`} arrow={true} color='black'>
                <Button disabled={isRunningDocker || isRunningDocker === null || startingDocker} onClick={handleOnStartConnectorDef} loading={startingDocker}>
                  {isRunningDocker === null ? <AiOutlineLoading3Quarters className='animate-spin' /> : 'Start Now'}
                </Button>
              </Tooltip>
            )}
          </div>
          {selectedAiModelConnector?.name === 'Web' && (
            <div className='my-4'>
              <Form.Item label='Base Url' name='scraper_base_url' rules={[{ required: true, message: 'Enter base URL for search' }]}>
                <Input placeholder='(e.g. https://company.my.com/docs)' autoComplete='off' allowClear />
              </Form.Item>
              <Form.Item name='scraper_allowed_paths'>
                <MultipleInput name='scraper_allowed_paths' label='Allowed Paths' placeholder='Add your allowed path' rules={[{ required: true, message: 'Enter paths that will be allowed during the scan' }]} />
              </Form.Item>
              <Form.Item name='scraper_disallowed_paths'>
                <MultipleInput name='scraper_disallowed_paths' label='Disallowed Paths' placeholder='Add your disallowed path' rules={[{ required: false, message: 'Enter paths that will be not allowed during the scan' }]} />
              </Form.Item>
              <Space>
                <Form.Item label='Depth Limit' name='scraper_depth_limit' rules={[{ required: true, message: 'Required' }]}>
                  <InputNumber defaultValue='12' placeholder='12' autoComplete='off' allowClear />
                </Form.Item>
                <Form.Item label='Pages total limit' name='scraper_pages_total_limit' rules={[{ required: true, message: 'Required' }]}>
                  <InputNumber defaultValue='10000' placeholder='Enter total limit for pages' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} autoComplete='off' allowClear />
                </Form.Item>
              </Space>
            </div>
          )}
          {selectedAiModelConnector?.name === 'Genesys Sharepoint' && (
            <div className='my-4'>
              <Form.Item label='Sharepoint Host Name' name='hostname'>
                <Input disabled autoComplete='off' />
              </Form.Item>
              <div className='text-center '>
                <Button onClick={() => setShowSharepointItemsPicker(!showSharepointItemsPicker)}>
                  <PiFoldersFill />
                  Sharepoint Picker
                </Button>
              </div>
              <SharepointItemsPicker open={showSharepointItemsPicker} onClose={() => setShowSharepointItemsPicker(false)} onSelect={(x) => handleUpdateSharepointData(x)} />
              <div className='font-xs font-extralight'>
                <div className='mt-4 mb-4'>
                  <Form.Item label='Drive ID' name='drive_id' disabled hidden>
                    <Input placeholder='(e.g. b!t6zcu8HEHkiimIcTr95FUe...)' autoComplete='off' allowClear disabled />
                  </Form.Item>

                  <Form.Item label='Root folder name' name='root_folder_name' hidden>
                    <Input placeholder='(e.g. https://company.my.com/docs)' autoComplete='off' allowClear disabled />
                  </Form.Item>
                  <div className='opacity-60 overflow-hidden mt-2 mb-2'>Folder path: {form.getFieldsValue()?.root_folder_name ? form.getFieldsValue()?.root_folder_name : 'not set'}</div>
                </div>
                <Form.Item label='Site ID (only required to scan pages)' name='site_id'>
                  <Input placeholder='(e.g. lab.sharepoint.com,d375723b...)' autoComplete='off' allowClear onChange={(e) => setSiteMode(e.target.value === '' ? false : true)} />
                </Form.Item>
              </div>
              <Form.Item label='Max File Age (days)' name='file_age_days' rules={[{ required: false }]}>
                <Input type='number' placeholder='(e.g. 90)' autoComplete='off' allowClear />
              </Form.Item>
              <Form.Item name='tag_folder_path' rules={[{ required: false }]} valuePropName='checked'>
                <Checkbox>Tag Folder Path in metadata</Checkbox>
              </Form.Item>
            </div>
          )}
          {selectedAiModelConnector?.name === 'Genesys S3' && !edit && (
            <div className='my-4 text-center text-blue-400'>
              <span>Confirm your model selection by submitting this window. Then re-open this Connector to continue.</span>
            </div>
          )}
          {selectedAiModelConnector?.name === 'Genesys S3' && edit && (
            <div className='mt-2 mb-4'>
              <Dragger {...s3UploadProps}>
                <Table
                  loading={busyTable}
                  columns={[
                    {
                      title: 'Name',
                      dataIndex: 'name',
                      sorter: (a, b) => a.name.localeCompare(b.name),
                    },
                    {
                      title: 'Last modified',
                      dataIndex: 'lastModified',
                      render: (text) => <span className='text-xs'>{new Date(text).toLocaleString()}</span>,
                      sorter: (a, b) => new Date(a.lastModified) - new Date(b.lastModified),
                      defaultSortOrder: 'descend',
                    },
                    {
                      title: 'Action',
                      key: 'action',
                      width: 50,
                      align: 'center',
                      render: (record) => (
                        <Space className='btn'>
                          <Popconfirm placement='topLeft' title='Delete' description='Do you want to delete this file?' okText='Yes' cancelText='No' onConfirm={() => deleteS3Item(record.name)}>
                            <Button disabled={busy} type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }} className='hover:scale-125'>
                              <FaRegTrashCan />
                            </Button>
                          </Popconfirm>
                        </Space>
                      ),
                    },
                  ]}
                  dataSource={s3Files}
                  size='small'
                />
              </Dragger>
              <div className='text-center text-xs mt-2 text-blue-400'>
                <Space>
                  <InboxOutlined />
                  Drag & Drop files to upload them
                </Space>
              </div>
            </div>
          )}
          {selectedAiModelConnector?.name === 'RFPIO' && (
            <div className='my-4'>
              <Form.Item label='RFPIO Advanced Query' name='rfpio_query'>
                <TextArea
                  className='mt-1'
                  rows={6}
                  placeholder='Custom query for https://app.rfpio.com
/rfpserver/ext/v1/answer-lib/search'
                  autoComplete='off'
                  onChange={(e) => {
                    updateRFPIOPreviewWindow(e.target.value);
                  }}
                />
              </Form.Item>
              <div className='mb-2'>JSON Preview:</div>
              <ReactJson style={{ padding: '12px', borderRadius: '0.25rem', fontSize: '0.7rem' }} collapsed={false} displayArrayKey={false} src={previewRFPIOQuery ?? {}} theme={isDarkMode ? 'shapeshifter' : 'shapeshifter:inverted'} iconStyle='square' enableClipboard={false} enableAdd={false} enableEdit={false} enableDelete={false} displayDataTypes={false} displayObjectSize={false} />
            </div>
          )}
          {selectedAiModelConnector?.name === 'Seismic' && (
            <div className='my-4'>
              <div className='font-xs font-extralight'>
                <div className='mt-4 mb-4'>
                  <Form.Item label='Team Site Id' name='team_site_id' hidden>
                    <Input placeholder='1' autoComplete='off' allowClear disabled />
                  </Form.Item>
                </div>
                <Form.Item label='Content Ids' name='content_ids'>
                  <Input placeholder='(e.g. c8ff1271-ebd8-4761-835a-8d2a4cf8ee68,71872dd4-df2c-4f03-b6ce-417414af7c4b,...)' autoComplete='off' allowClear />
                </Form.Item>
              </div>
            </div>
          )}
        </Form>
      </StylesWrapper>
    </Modal>
  );
};

AiModelConnectorsModal.propTypes = {
  aiModelId: PropTypes.number,
  edit: PropTypes.bool.isRequired,
  connector: PropTypes.object,
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AiModelConnectorsModal;
