import React, { useState } from 'react';
import { Button, Popover, Input, Form, message } from 'antd';
import useUserInfo from 'src/hooks/useUserInfo';
import { postFeedback } from 'src/api/notifications';
import { MdOutlineFeedback } from 'react-icons/md';
import Space2 from './Space2';

const Feedback = () => {
  const MIN_LENGTH = 30;
  const MAX_LENGTH = 2000;

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { email } = useUserInfo();

  const handleSubmit = async (values) => {
    console.log('Feedback:', values);
    setLoading(true);
    try {
      await postFeedback(values);
      console.log('Feedback sent successfully');
      message.success('Feedback sent successfully. Thank you!');
      setVisible(false);
      form.resetFields();
    } catch (error) {
      console.error('Failed to send feedback:', error);
    }
    setLoading(false);
  };

  return (
    <Popover
      content={
        <div className={loading ? 'disabled' : null}>
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item name='email' initialValue={email} hidden>
              <Input type='hidden' />
            </Form.Item>
            <Form.Item name='url' initialValue={window.location.href} hidden>
              <Input type='hidden' />
            </Form.Item>
            <Form.Item
              name='feedback'
              rules={[
                { required: true, message: 'Please enter your feedback' },
                { min: MIN_LENGTH, message: `This should contain at least ${MIN_LENGTH} characters`, validateTrigger: 'onBlur' },
              ]}
            >
              <Input.TextArea autoSize={{ minRows: 6, maxRows: 12 }} maxLength={MAX_LENGTH} showCount />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
              <Button type='primary' htmlType='submit' loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      }
      title={
        <Space2>
          <div>Send Feedback</div>
          <div className='text-xs font-light opacity-60'>(min {MIN_LENGTH} characters)</div>
        </Space2>
      }
      trigger='click'
      open={visible}
      onOpenChange={setVisible}
      overlayStyle={{ width: '600px' }}
      placement='bottomRight'
    >
      <Button type='text' onClick={() => setVisible(!visible)}>
        <div className='flex flex-row items-center justify-center'>
          <MdOutlineFeedback />
        </div>
      </Button>
    </Popover>
  );
};

export default Feedback;
