import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Table, ConfigProvider } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';

const CondensedTable1 = styled.div`
  .ant-table-cell {
    padding: 1px 4px !important;
  }
  .ant-pagination {
    margin: 8px 0 !important;
  }
`;

const CondensedTable = (props) => {
  const customizeRenderEmpty = () => (
    <div
      style={{
        textAlign: 'center',
      }}
    >
      <FileTextOutlined
        style={{
          marginTop: 25,
          fontSize: 40,
        }}
      />
      <p>{props.noDataMessage}</p>
    </div>
  );

  return (
    <ConfigProvider renderEmpty={props.noDataMessage ? customizeRenderEmpty : undefined}>
      <CondensedTable1>
        <Table {...props} />
      </CondensedTable1>
    </ConfigProvider>
  );
};

CondensedTable.propTypes = {
  noDataMessage: PropTypes.string,
};

export default CondensedTable;
