import React from 'react';
import { Space } from 'antd';
import { MdSecurity } from 'react-icons/md';
import { IoIosStats } from 'react-icons/io';
import { BsPersonRaisedHand } from 'react-icons/bs';

const ClientCredentialsConfig = {
  securityadvisor: {
    header: (
      <Space className='btn'>
        <MdSecurity />
        Security Advisor
      </Space>
    ),
    name: 'Security Advisor',
    oAuthClientName: 'SPC Security Advisor',
  },
  customerinsights: {
    header: (
      <Space className='btn'>
        <IoIosStats />
        Customer Insights
      </Space>
    ),
    name: 'Customer Insights',
    oAuthClientName: 'Customer Insights',
  },
  datasubjectrequests: {
    header: (
      <Space className='btn'>
        <BsPersonRaisedHand />
        Data Subject Requests
      </Space>
    ),
    name: 'Data Subject Requests',
    oAuthClientName: 'SPC Data Subject Requests',
  },
};

export default ClientCredentialsConfig;
