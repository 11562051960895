import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useUserInfo from 'src/hooks/useUserInfo';
import { Dropdown, Space } from 'antd';
import { filterItemsBasedOnUserPermissions } from 'src/misc/Session';
import { FaSortDown } from 'react-icons/fa';
import { notEmptyArray } from 'src/misc/Misc';

const PageSwitch = ({ pageList, label, groupingType = null }) => {
  const { permissions: userPermissions } = useUserInfo();
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [active, setActive] = useState('');

  useEffect(() => {
    //#region "DETERMINE GROUPS"
    const groupList = pageList?.reduce((acc, item) => {
      if (!item.group) return acc;
      if (!acc.includes(item.group)) acc.push(item.group);
      return acc;
    }, []);
    //#endregion

    //#region "ADD NOT GROUPED ITEMS"
    let i = pageList
      ?.filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions))
      ?.filter((item) => !item.group)
      ?.map((page) => {
        return {
          key: page.path,
          label: (
            <Space className='btn'>
              {page.icon} {page.label}
            </Space>
          ),
          disabled: page.path === window.location.pathname,
        };
      });
    //#endregion

    //#region "ADD GROUPED ITEMS"
    if (true === notEmptyArray(groupList)) {
      groupList.forEach((group) => {
        const children = pageList
          ?.filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions))
          ?.filter((item) => item.group === group)
          ?.map((page) => {
            return {
              key: page.path,
              label: (
                <Space className='btn'>
                  {page.icon} {page.label}
                </Space>
              ),
              disabled: page.path === window.location.pathname,
            };
          });
        if (true === notEmptyArray(children)) {
          i = i.concat({
            key: group,
            label: group,
            type: groupingType,
            children,
          });
        }
      });
    }
    //#endregion

    setItems(i);
    setActive(pageList.find((item) => item.path === window.location.pathname)?.label ?? 'Unknown');
    //console.log('aaaaa', i, pageList);
  }, [pageList]);

  const handleMenuOnClick = (e) => {
    //console.log('handleMenuOnCklick()', e);
    if (!e.key) return;
    navigate(e.key);
  };

  return (
    <Dropdown menu={{ items, onClick: handleMenuOnClick }} trigger={['click']}>
      <div className='cursor-pointer'>
        <Space>
          <span className='font-extralight'>
            {label || active} <FaSortDown />
          </span>
        </Space>
      </div>
    </Dropdown>
  );
};

PageSwitch.propTypes = {
  pageList: PropTypes.array,
  label: PropTypes.string,
  groupingType: PropTypes.string, // null or 'group'
};

export default PageSwitch;
