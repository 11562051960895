import React, { useState } from 'react';
import { FaRegClock, FaRegCalendar } from 'react-icons/fa6';
import Space2 from 'src/components/layout/Space2';
import TicketTimeTrackingSubmit from './TicketTimeTrackingSubmit';
import TicketTimeTrackingEntries from './TicketTimeTrackingEntries';
import CondensedTabs from 'src/components/layout/CondensedTabs';
//import PropTypes from 'prop-types';

const TicketTimeTracking = () => {
  const [currentTab, setCurrentTab] = useState('submit');
  return (
    <>
      <CondensedTabs
        size='small'
        type='card'
        activeKey={currentTab}
        onChange={(key) => setCurrentTab(key)}
        items={[
          {
            key: 'submit',
            label: (
              <Space2>
                <FaRegClock />
                Submit time
              </Space2>
            ),
          },
          {
            key: 'entries',
            label: (
              <Space2>
                <FaRegCalendar />
                Time entries
              </Space2>
            ),
          },
        ]}
      />
      <>
        {currentTab === 'submit' && <TicketTimeTrackingSubmit />}
        {currentTab === 'entries' && <TicketTimeTrackingEntries />}
      </>
    </>
  );
};

TicketTimeTracking.propTypes = {};

export default TicketTimeTracking;
