import React, { useState } from 'react';
import AssignmentsRoleSelector from './AssignmentsRoleSelector';
import AssignmentsAssignedItems from './AssignmentsAssignedItems';
import PageLayoutType1 from 'src/components/layout/PageLayoutType1';
//import PropTypes from 'prop-types';

const Assignments = () => {
  const [selectedRole, setSelectedRole] = useState(null);

  return <PageLayoutType1 height='calc(100vh - 195px)' leftWidth='500px' left={<AssignmentsRoleSelector onRoleSelected={(role) => setSelectedRole(role)} />} right={<AssignmentsAssignedItems role={selectedRole} />} />;
};

Assignments.propTypes = {};

export default Assignments;
