import React, { useContext, useState, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { Input, Select, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { notArrayOrEmpty, notEmptyArray, getDistinct1 } from 'src/misc/Misc';
import TeacketUsersPicker from '../../Components/ServicesUsersPicker';
import RangePicker from 'src/components/controls/RangePicker/RangePicker';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

//import PropTypes from 'prop-types';

const TicketListSearchBar = () => {
  const { servicesForTenant, setTicketListUsers, ticketListPriorities, setTicketListPriorities, ticketListStatuses, ticketListTypes, setTicketListStatuses, setTicketListTypes, ticketListServices, setTicketListServices, ticketListQuery, setTicketListQuery, ticketListStartDate, ticketListEndDate, setTicketListStartDate, setTicketListEndDate, ticketListGroupBy, setTicketListGroupBy } = useContext(TeacketContext);

  const [statusList, setStatusList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);

  useEffect(() => {
    // console.log('servicesForTenant:', servicesForTenant);
    if (true === notArrayOrEmpty(servicesForTenant)) return;
    let sl = [];
    let pl = [];
    let tt = [];
    for (const service of servicesForTenant) {
      if (true === notEmptyArray(service?.props?.statusList)) sl = sl.concat(service.props.statusList.map((x) => x.status));
      if (true === notEmptyArray(service?.props?.priorityList)) pl = pl.concat(service.props.priorityList.map((x) => x.priority));
      if (true === notEmptyArray(service?.props?.ticketTypes)) tt = tt.concat(service.props.ticketTypes);
    }
    sl = getDistinct1(sl);
    pl = getDistinct1(pl);

    // console.log('sl:', sl);
    // console.log('pl:', pl);
    setStatusList(sl);
    setPriorityList(pl);
    setTicketTypes(tt);
  }, [servicesForTenant]);

  const groupableColumns = [
    { key: 'account', title: 'Account', dataIndex: 'acc_name' },
    { key: 'category', title: 'Category', dataIndex: 'category' },
    { key: 'priority', title: 'Priority', dataIndex: 'priority' },
    { key: 'status', title: 'Status', dataIndex: 'status' },
  ];

  return (
    <div className='flex flex-row flex-wrap gap-4'>
      <div>
        <RangePicker
          startDate={ticketListStartDate}
          endDate={ticketListEndDate}
          onChange={(v) => {
            setTicketListStartDate(v.startDate);
            setTicketListEndDate(v.endDate);
          }}
        />
      </div>
      {ticketTypes.length > 0 && (
        <>
          <div>
            <Select
              mode='multiple'
              value={ticketListTypes}
              onChange={(v) => {
                setTicketListTypes(v);
              }}
              className='w-60'
              options={ticketTypes?.map((x) => {
                return { value: x.type, label: x.name };
              })}
              allowClear
              placeholder='[all types]'
            />
          </div>
        </>
      )}
      <div>
        <TeacketUsersPicker serviceList={servicesForTenant?.map((x) => x.id)} onPick={(e) => setTicketListUsers(e)} />
      </div>
      <div>
        <Select
          mode='multiple'
          value={ticketListPriorities}
          onChange={(v) => {
            setTicketListPriorities(v);
          }}
          className='w-60'
          options={priorityList?.map((x) => {
            return { value: x, label: x };
          })}
          allowClear
          placeholder='[all priorities]'
        />
      </div>
      <div>
        <Select
          mode='multiple'
          value={ticketListStatuses}
          onChange={(v) => {
            setTicketListStatuses(v);
          }}
          className='w-60'
          options={statusList?.map((x) => {
            return { value: x, label: x };
          })}
          allowClear
          placeholder='[all statuses]'
        />
      </div>
      <div>
        <Select
          mode='multiple'
          value={ticketListServices}
          onChange={(v) => {
            setTicketListServices(v);
          }}
          className='w-60'
          options={servicesForTenant?.map((x) => {
            return { value: x.id, label: x.name };
          })}
          allowClear
          placeholder='[all services]'
        />
      </div>
      <div>
        <Input
          className='w-60'
          placeholder='Search...'
          prefix={<SearchOutlined className='mr-2' />}
          addonAfter={
            <Tooltip
              title={
                <div className='text-xs' style={{ lineHeight: '1rem' }}>
                  <div className='font-bold'>Search by:</div>
                  <div>
                    <ul className='m-0'>
                      <li>id</li>
                      <li>title</li>
                      <li>category / subcategory</li>
                      <li>account</li>
                    </ul>
                  </div>
                </div>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          }
          allowClear
          value={ticketListQuery}
          onChange={(e) => {
            setTicketListQuery(e.target.value);
          }}
        />
      </div>
      <div>
        <Select placeholder='Group By' style={{ width: 120 }} onChange={(value) => setTicketListGroupBy(value)} value={ticketListGroupBy} allowClear>
          {groupableColumns.map((column) => (
            <Option key={column.key} value={column.dataIndex}>
              {column.title}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

TicketListSearchBar.propTypes = {};

export default TicketListSearchBar;
