import React, { useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { Button, Space } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import WhenDesktop from 'src/components/layout/WhenDesktop';
//import PropTypes from 'prop-types';

const TicketHistoryBtns = () => {
  const { setCurrentTicketHistoryForceReload } = useContext(TeacketContext);
  return (
    <>
      <Button
        size='small'
        className='btn'
        onClick={() => {
          setCurrentTicketHistoryForceReload(true);
        }}
      >
        <Space className='btn'>
          <ReloadOutlined />
          <WhenDesktop>
            <span>Refresh</span>
          </WhenDesktop>
        </Space>
      </Button>
    </>
  );
};

TicketHistoryBtns.propTypes = {};

export default TicketHistoryBtns;
