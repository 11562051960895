import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useUserInfo from 'src/hooks/useUserInfo';
import { MASTER_ADMIN_PERMISSION } from 'src/misc/Config';

const PermissionsProvider = ({ children, requiredPermissions, isControl }) => {
  const navigate = useNavigate();
  const { permissions: userPermissions } = useUserInfo();

  const [isAllowed, setIsAllowed] = useState(null);

  useEffect(() => {
    const determined = determineIsAllowed();
    if (determined) {
      setIsAllowed(true);
      return;
    }
    if (!isControl) {
      navigate('/403');
    }
    setIsAllowed(false);
  }, []);

  const determineIsAllowed = () => {
    // console.log('isAllowed()', { requiredPermissions, userPermissions });
    // <MASTER_ADMIN_PERMISSION can view everything>
    if (userPermissions.includes(MASTER_ADMIN_PERMISSION)) {
      return true;
    }
    // </MASTER_ADMIN_PERMISSION can view everything>

    for (const requiredPermission of requiredPermissions) {
      if (userPermissions.includes(requiredPermission)) {
        return true;
      }
    }
    // console.warn("[PermissionsProvider] user doesn't have required permissions for the resource: ", requiredPermissions);
    return false;
  };

  if (isAllowed) return children;

  return null;
};

PermissionsProvider.propTypes = {
  children: PropTypes.node,
  requiredRoles: PropTypes.array,
  requiredPermissions: PropTypes.array,
  isControl: PropTypes.bool,
};

PermissionsProvider.defaultProps = {
  requiredRoles: [],
  isControl: false,
};

export default PermissionsProvider;
