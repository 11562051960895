import React, { useContext, useState } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import FsiReportContext from 'src/context/FsiReportContext';
import { Button, Space, message } from 'antd';
import { FilePdfOutlined, ReloadOutlined, FolderOpenOutlined } from '@ant-design/icons';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { getReportList, postCreateReport, getReportFolder, patchProjectProps } from 'src/api/fsireport';
import { dateTimeSorter, notEmptyArray } from 'src/misc/Misc';
import ExecutionSummary from './ExecutionSummary';

const ReportsWidgetBtns = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();
  const { currentProject, currentProjectReportList, setCurrentProjectReportList, exeSummaries } = useContext(FsiReportContext);

  // const { executeAsyncProcess } = useAsyncProcesses();

  const [isLoadingFolder, setIsLoadingFolder] = useState(false);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [executionSummaryOpen, setExecutionSummaryOpen] = React.useState(false);

  const handleOpenFolder = async () => {
    setIsLoadingFolder(true);
    try {
      const { url } = (await getReportFolder(currentProject.id)) ?? {};
      if (url) window.open(url, '_blank');
    } catch (error) {
      console.error(error);
    }
    setIsLoadingFolder(false);
  };

  const handleRefresh = async () => {
    setIsLoadingRefresh(true);
    try {
      const resp = ((await getReportList(currentProject.id)) ?? []).sort((a, b) => dateTimeSorter(b, a, 'lastModifiedDateTime'));
      setCurrentProjectReportList(resp);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingRefresh(false);
  };

  const handleExecutiveSummaryCreateReport = async () => {
    await executeAsyncProcess(async () => {
      try {
        const key = '05e211fa-ed90-4c6d-b570-f7cd2f1e4cb7';
        messageApi.open({
          key,
          type: 'loading',
          content: 'Creating report...',
          duration: 0,
        });
        await patchProjectProps(currentProject.id, { exeSummaries });
        await postCreateReport(currentProject.id);
        const resp = ((await getReportList(currentProject.id)) ?? []).sort((a, b) => dateTimeSorter(b, a, 'lastModifiedDateTime'));
        messageApi.open({
          key,
          type: 'success',
          content: 'Report created',
          duration: 3,
        });
        setCurrentProjectReportList(resp);
        setExecutionSummaryOpen(false);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleExecutiveSummaryOnCancel = () => {
    setExecutionSummaryOpen(false);
  };

  const btnsDisabled = () => {
    if (!currentProject) return true;
    if (currentProjectReportList === null) return true;
    return false;
  };

  const handleExecutiveSummaryOnSave = async () => {
    await executeAsyncProcess(async () => {
      try {
        const key = 'ac5970bc-a0f1-4792-8c4e-a068ad0ba32c';
        setExecutionSummaryOpen(false);
        messageApi.open({
          key,
          type: 'loading',
          content: 'Saving executive summary...',
          duration: 0,
        });
        await patchProjectProps(currentProject.id, { exeSummaries });
        messageApi.open({
          key,
          type: 'success',
          content: 'Executive summary saved',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <>
      {contextHolder}
      {executionSummaryOpen && <ExecutionSummary onCreateReport={handleExecutiveSummaryCreateReport} onCancel={handleExecutiveSummaryOnCancel} onSave={handleExecutiveSummaryOnSave} />}

      <Space>
        {true === notEmptyArray(currentProjectReportList) && (
          <Button size='small' onClick={handleOpenFolder} disabled={btnsDisabled()} loading={isLoadingFolder} icon={<FolderOpenOutlined />}>
            <WhenDesktop>SharePoint</WhenDesktop>
          </Button>
        )}

        <Button size='small' onClick={handleRefresh} disabled={btnsDisabled()} loading={isLoadingRefresh} icon={<ReloadOutlined />}>
          <WhenDesktop>Refresh</WhenDesktop>
        </Button>

        <Button size='small' type='primary' disabled={btnsDisabled()} icon={<FilePdfOutlined />} onClick={() => setExecutionSummaryOpen(true)}>
          <WhenDesktop>Create new</WhenDesktop>
        </Button>
      </Space>
    </>
  );
};

ReportsWidgetBtns.propTypes = {};

export default ReportsWidgetBtns;
