import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TitleBar from 'src/components/layout/TitleBar';
import { Steps, Button, Input, Result } from 'antd';
import { LeftOutlined, RightOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { sleepMs } from 'src/misc/Misc';

//import PropTypes from 'prop-types';

const TenantSetup = () => {
  const { tenantId } = useParams();
  const { executeAsyncProcess, isBusy } = useAsyncProcesses();
  const navigate = useNavigate();

  const stepList = [
    {
      id: 1,
      title: 'Name',
      description: 'Provide a name of your orgnization',
    },
    {
      id: 2,
      title: 'Admin password',
      description: 'Set up a master admin password',
    },
    {
      id: 3,
      title: 'Summary',
      description: 'Review your tenant settings',
    },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [orgName, setOrgName] = useState('');
  const [adminPassword, setAdminPassword] = useState('');

  useEffect(() => {
    verifyIfOrgIdCanBeSetup();
  }, []);

  //#region "API calls"

  const verifyIfOrgIdCanBeSetup = async () => {
    console.log('verifyIfOrgIdCanBeSetup()', tenantId);
    executeAsyncProcess(async () => {
      // call the API function here
      await sleepMs(2000);
    });
  };

  const submitSetup = async () => {
    console.log('submitSetup()');
    executeAsyncProcess(async () => {
      // call the API function here
      await sleepMs(2000);
      setCurrentStep(-1);
    });
  };

  //#endregion

  //#region "UI elemenets"

  const step0 = (
    <>
      <div className='mt-6 text-sm'>Name</div>
      <Input value={orgName} onChange={(e) => setOrgName(e.target.value)} maxLength={30} showCount />
    </>
  );

  const step1 = (
    <>
      <div className='mt-6 text-sm'>Admin password</div>
      <Input.Password value={adminPassword} onChange={(e) => setAdminPassword(e.target.value)} />
    </>
  );

  const step2 = (
    <>
      <div className='mt-6 text-sm'>Tenant name</div>
      <div>{orgName}</div>
      <div className='mt-2 text-sm'>Admin password</div>
      <div>{'*'.repeat(adminPassword.length)}</div>
    </>
  );

  //#endregion

  return (
    <>
      <TitleBar title='Tenant Setup' />
      <div className={`flex flex-row justify-center items-center h-[calc(100vh-50px)] ${isBusy ? 'disabled' : ''}`}>
        <div className='w-[800px] h-[400px] border border-solid border-indigo-500 rounded-lg shadow-lg flex flex-row'>
          {currentStep === -1 ? (
            <>
              <div className='flex flex-row justify-center items-center w-full'>
                <Result
                  status='success'
                  title='Your org has been successfully set up!'
                  subTitle='Go to the landing page and login.'
                  extra={[
                    <Button
                      type='primary'
                      key='console'
                      onClick={() => {
                        navigate('/');
                      }}>
                      Go to the landing page
                    </Button>,
                  ]}
                />
              </div>
            </>
          ) : (
            <>
              <div className='flex-initial p-4 bg-indigo-50' style={{ borderRadius: '0.5rem 0 0 0.5rem' }}>
                <Steps
                  direction='vertical'
                  size='small'
                  current={currentStep}
                  items={stepList.map((x) => {
                    return { title: x.title };
                  })}
                />
              </div>
              <div className='flex-auto p-4 flex flex-col'>
                <div className='flex-auto overflow-auto'>
                  <div className='text-xl'>{stepList[currentStep].title}</div>
                  <div className='text-sm font-light'>{stepList[currentStep].description}</div>
                  <hr className='my-2 border-indigo-500' />
                  {currentStep === 0 && step0}
                  {currentStep === 1 && step1}
                  {currentStep === 2 && step2}
                </div>
                <div className='flex flex-row justify-end gap-2'>
                  {currentStep > 0 && (
                    <Button
                      onClick={() => {
                        setCurrentStep(currentStep - 1);
                      }}>
                      <LeftOutlined />
                      Back
                    </Button>
                  )}
                  {currentStep < stepList.length - 1 && (
                    <Button
                      onClick={() => {
                        setCurrentStep(currentStep + 1);
                      }}>
                      Next
                      <RightOutlined />
                    </Button>
                  )}
                  {currentStep === stepList.length - 1 && (
                    <Button type='primary' disabled={orgName.length < 1 || adminPassword.length < 1} onClick={submitSetup} loading={isBusy}>
                      <CloudUploadOutlined />
                      Submit
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

// OrgSetup.propTypes = {};

export default TenantSetup;
