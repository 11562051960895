import React, { useContext, useState, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { notArrayOrEmpty, notEmptyArray, getDistinct3, getDistinct1 } from 'src/misc/Misc';
//import PropTypes from 'prop-types';

const Filters = () => {
  const { servicesForTenant, myTicketsQuery, myTicketsPriorities, setMyTicketsQuery, setMyTicketsStatuses, setMyTicketsPriorities } = useContext(TeacketContext);

  const statusGroupList = [
    { value: 'Open', label: 'Open' },
    { value: 'Escalated', label: 'Escalated' },
    { value: 'Closed', label: 'Closed' },
  ];

  const [selectedStatusGroup, setSelectedStatusGroup] = useState('Open');
  const [statusList, setStatusList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);

  useEffect(() => {
    console.log('selectedStatusGroup:', selectedStatusGroup);
    console.log('statusList:', statusList);

    let statuses = null;

    switch (selectedStatusGroup) {
      case 'Open': {
        statuses =
          statusList
            .filter((x) => !x.isClosed)
            ?.map((x) => x.status)
            ?.join(',') ?? null;
        break;
      }
      case 'Escalated': {
        statuses =
          statusList
            .filter((x) => true === x.isEscalated)
            ?.map((x) => x.status)
            ?.join(',') ?? null;
        break;
      }
      case 'Closed': {
        statuses =
          statusList
            .filter((x) => true === x.isClosed)
            ?.map((x) => x.status)
            ?.join(',') ?? null;
        break;
      }
      default: {
        statuses = null;
      }
    }
    console.log('statuses:', statuses);
    setMyTicketsStatuses(statuses);
  }, [selectedStatusGroup, statusList]);

  useEffect(() => {
    // console.log('servicesForTenant:', servicesForTenant);
    if (true === notArrayOrEmpty(servicesForTenant)) return;
    let sl = [];
    let pl = [];
    for (const service of servicesForTenant) {
      if (true === notEmptyArray(service?.props?.statusList)) sl = sl.concat(service.props.statusList.map((x) => x));
      if (true === notEmptyArray(service?.props?.priorityList)) pl = pl.concat(service.props.priorityList.map((x) => x.priority));
    }
    sl = getDistinct3(sl, 'status');
    pl = getDistinct1(pl);
    // console.log('sl:', sl);
    // console.log('pl:', pl);
    setStatusList(sl);
    setPriorityList(pl);
  }, [servicesForTenant]);

  return (
    <div className='flex flex-col gap-1'>
      <Input
        size='small'
        className='w-full'
        placeholder='Search...'
        prefix={<SearchOutlined className='mr-2' />}
        allowClear
        value={myTicketsQuery}
        onChange={(e) => {
          setMyTicketsQuery(e.target.value);
        }}
      />
      <Select allowClear size='small' className='w-full' placeholder='[All statuses]' value={selectedStatusGroup} onChange={(v) => setSelectedStatusGroup(v)} options={statusGroupList} />
      <Select
        allowClear
        size='small'
        className='w-full'
        placeholder='[All priorities]'
        value={myTicketsPriorities}
        onChange={(v) => setMyTicketsPriorities(v)}
        options={priorityList?.map((x) => {
          return { value: x, label: x };
        })}
      />
    </div>
  );
};

Filters.propTypes = {};

export default Filters;
