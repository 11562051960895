import React, { useState, useEffect, useContext } from 'react';
import { Select, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { notArrayOrEmpty } from 'src/misc/Misc';
import FeatrueListLoader from '../FeatruesAccess/FeatrueListLoader';
import GlobalContext from 'src/context/GlobalContext';

const FeaturePicker = ({ width = '300px', onPick, placeholder = 'select feature...' }) => {
  const [itemList, setItemList] = useState(null);
  const { featureList } = useContext(GlobalContext);

  useEffect(() => {
    if (true === notArrayOrEmpty(featureList)) return;
    setItemList(
      featureList.map((d) => {
        return { label: d.feature, value: d.feature };
      })
    );
  }, [featureList]);

  const handleOnSelect = (v) => onPick && onPick(v);

  const handleOnClear = () => onPick && onPick(null);

  return (
    <FeatrueListLoader loadingElement={<Skeleton.Input style={{ width }} active />}>
      <Select options={itemList} placeholder={placeholder} onSelect={handleOnSelect} onClear={handleOnClear} style={{ width }} autoComplete='off' allowClear />
    </FeatrueListLoader>
  );
};

FeaturePicker.propTypes = {
  width: PropTypes.string,
  onPick: PropTypes.func,
  placeholder: PropTypes.string,
};

export default FeaturePicker;
