import React from 'react';
import { PropTypes } from 'prop-types';
import { ROW_HEIGHT_INT } from './common';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey } from '@ant-design/colors';
import { Popover } from 'antd';
import * as dayjs from 'dayjs';

const ItemItem = ({ topOffset, leftOffset, timestamp, color, icon, props }) => {
  const STROKE_HUE = 8;
  const FILL_HUE = 3;
  const POPOVER_FG_HUE = 2;

  const getColor = (hue) => {
    switch (color) {
      case 'red':
        return red[hue];
      case 'volcano':
        return volcano[hue];
      case 'gold':
        return gold[hue];
      case 'yellow':
        return yellow[hue];
      case 'lime':
        return lime[hue];
      case 'green':
        return green[hue];
      case 'cyan':
        return cyan[hue];
      case 'blue':
        return blue[hue];
      case 'geekblue':
        return geekblue[hue];
      case 'purple':
        return purple[hue];
      case 'magenta':
        return magenta[hue];
      default:
        if (hue === 0) return '#eeeeee';
        return grey[hue];
    }
  };

  const getProps = () => {
    const result = [];
    if (icon)
      result.push(
        <div style={{ textAlign: 'center', fontSize: '1.4rem' }}>
          <i className={icon} />
        </div>
      );
    for (let p of Object.keys(props)) {
      if (p && props[p] !== null && props[p] !== '') {
        result.push(
          <div>
            <strong>{p}</strong>: {props[p]}
          </div>
        );
      }
    }
    if (timestamp)
      result.push(
        <div style={{ textAlign: 'center', fontSize: '0.8em', fontWeight: 400, opacity: '0.6', marginTop: '8px', paddingTop: '4px', borderTop: `1px dotted ${getColor(POPOVER_FG_HUE)}` }}>
          {dayjs(timestamp).fromNow()}
          <br />
          {dayjs(timestamp).format('llll')}
        </div>
      );
    return result;
  };

  return (
    <>
      <svg height={ROW_HEIGHT_INT} width={ROW_HEIGHT_INT} style={{ position: 'absolute', top: `${topOffset}px`, left: `${leftOffset}px` }}>
        <line x1={0} y1={ROW_HEIGHT_INT / 2} x2={ROW_HEIGHT_INT} y2={ROW_HEIGHT_INT / 2} style={{ stroke: cyan[6], strokeWidth: 3 }} />

        <Popover placement='top' content={<div style={{ maxWidth: '300px' }}>{getProps()}</div>}>
          <g>
            <circle cx={ROW_HEIGHT_INT / 2} cy={ROW_HEIGHT_INT / 2} r={ROW_HEIGHT_INT / 2.5} stroke={getColor(STROKE_HUE)} strokeWidth={2} fill={getColor(FILL_HUE)} style={{ cursor: 'pointer' }} />
          </g>
        </Popover>
      </svg>
      <div style={{ position: 'absolute', top: `${topOffset}px`, left: `${leftOffset}px`, height: `${ROW_HEIGHT_INT}px`, width: `${ROW_HEIGHT_INT}px`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', fontSize: '1.1em', pointerEvents: 'none' }}>
        <div className='flex flex-row justify-center items-center' style={{ color: getColor(STROKE_HUE), position: 'relative' }}>
          {icon}
        </div>
      </div>
    </>
  );
};

ItemItem.propTypes = {
  topOffset: PropTypes.number.isRequired,
  leftOffset: PropTypes.number.isRequired,
  timestamp: PropTypes.string,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string,
  props: PropTypes.object.isRequired,
};

ItemItem.defaultProps = {
  topOffset: 0,
  leftOffset: 0,
  color: 'grey',
  props: { title: 'No title' },
};

export default ItemItem;
