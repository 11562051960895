import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { PieChart } from '@mui/x-charts/PieChart';
import NoData from 'src/components/layout/NoData';
import MuiTheme from 'src/misc/MuiTheme';

const OpenedByCategory = () => {
  const { dashboardDataTickets } = useContext(TeacketContext);

  const [data, setData] = useState(null);

  useEffect(() => {
    const { openedByCategory } = dashboardDataTickets ?? {};
    if (!openedByCategory) return;

    const d = openedByCategory.map((item) => ({
      id: item.category,
      label: item.category,
      value: parseInt(item.count ?? 0),
    }));
    setData(d);
  }, [dashboardDataTickets]);

  if (!data)
    return (
      <div className='py-12'>
        <NoData />
      </div>
    );

  return (
    <div>
      <MuiTheme>
        <PieChart
          series={[
            {
              arcLabel: (item) => item.value,
              data,
              innerRadius: 20,
              outerRadius: 85,
              paddingAngle: 2,
              cornerRadius: 4,
            },
          ]}
          height={200}
        />
      </MuiTheme>
    </div>
  );
};

OpenedByCategory.propTypes = {};

export default OpenedByCategory;
