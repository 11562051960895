import React, { Component } from 'react';
import SQAConversationContext from './SQAConversationContext';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';

var currentMessage = '';

export default class GlobalProvider extends Component {
  state = {
    conversation: null,
    conversations: [],
    switchingConversation: false,
    selectedModel: null,
    availableModels: [],
    showPopup: false,
    streaming: false,
  };

  /*
    Conversation format:
    {
      id: 1,
      title: 'New Conversation',
      content: [
        {
          message: 'Hello',
          sender: 'user',
        },
        {
          message: 'This is a message from the model',
          sender: 'system',
        }
      ],
      created_at: '2021-08-10T18:00:00.000Z',
      updated_at: '2021-08-10T18:00:00.000Z',
    }
  */

  render() {
    return (
      <SQAConversationContext.Provider
        value={{
          conversation: this.state.conversation,
          setConversation: (conversation) => this.setState({ conversation }),
          availableModels: this.state.availableModels,
          setAvailableModels: (models) => this.setState({ availableModels: models }),
          selectedModel: this.state.selectedModel,
          setSelectedModel: (model) => this.setState({ selectedModel: model }),
          streaming: this.state.streaming,
          setStreaming: (value) => this.setState({ streaming: value }),
          updateConversationSubject: (id, subject) => {
            let conversations = [...this.state.conversations];
            conversations.forEach((conversation) => {
              if (conversation.id === id) {
                conversation.title = subject;
                conversation.animate = true;
              }
            });
            this.setState({ conversations });
            this.setState({ conversation: { ...this.state.conversation, title: subject } });
          },
          clearAnimationFlag: (id) => {
            let conversations = [...this.state.conversations];
            conversations.forEach((conversation) => {
              if (conversation.id === id) {
                delete conversation.animate;
              }
            });
            this.setState({ conversations });
          },
          addConversation: (conversation) => {
            this.setState({ conversations: [...this.state.conversations, conversation], conversation });
          },
          resetCurrentMessage: () => (currentMessage = ''),
          addMessage: async (message, sender) => {
            // console.log('addMessage', message, role);

            if (message.indexOf('showpopup') !== -1) {
              message = message.replace(/showpopup/g, '');
              //TODO: Check local storage for the popup
              console.log('Set show popup to true');
              this.setState({ showPopup: true });
            }

            currentMessage += message;

            const lastContentItem = this.state.conversation?.content[this.state.conversation?.content?.length - 1] ?? null;
            //console.log('lastContentItem', lastContentItem);

            const sameRole = lastContentItem?.sender === sender;
            //console.log('sameRole', sameRole);

            if (sameRole) {
              // <the same role>
              let content = true === notEmptyArray(this.state.conversation?.content) ? this.state.conversation.content : [];
              if (lastContentItem) {
                lastContentItem.message = currentMessage;
                let conversation = {
                  ...this.conversation,
                  content,
                };
                this.setState(conversation);
              }
              // </the same role>
            } else {
              // <the role has changed>
              currentMessage = message;
              console.log('== currentMessage', currentMessage);

              let content = notEmptyArray(this.state.conversation?.content) ? this.state.conversation.content : [];
              console.log('== content', content);

              content.push({ message: currentMessage, sender });
              console.log('== new content', content);

              const conversation = {
                ...this.state.conversation,
                content,
              };

              console.log('== updated conversation', conversation);
              this.setState(conversation);
              // <the role has changed>
            }
          },
          newQuestionMessage: async (message) => {
            const conversation = { ...this.state.conversation };
            if (notArrayOrEmpty(conversation.content)) conversation.content = [];
            conversation.content.push({ message, sender: 'user' });
            conversation.content.push({ message: ' ', sender: 'system' });
            this.setState({ conversation });
            return { ...conversation };
          },
          conversations: this.state.conversations,
          showPopup: this.state.showPopup,
          setConversations: (conversations) => this.setState({ conversations }),
          setSwitchingConversation: (value) => this.setState({ switchingConversation: value }),
          switchingConversation: this.state.switchingConversation,
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </SQAConversationContext.Provider>
    );
  }
}
