import React from 'react';
import { PropTypes } from 'prop-types';
import { ROW_HEIGHT_INT } from './common';
import { cyan } from '@ant-design/colors';

const ItemFinisher = ({ topOffset, leftOffset }) => {
  return (
    <svg height={ROW_HEIGHT_INT} width={ROW_HEIGHT_INT * 2} style={{ position: 'absolute', top: `${topOffset}px`, left: `${leftOffset}px` }}>
      <line x1={0} y1={ROW_HEIGHT_INT / 2} x2={ROW_HEIGHT_INT} y2={ROW_HEIGHT_INT / 2} style={{ stroke: cyan[6], strokeWidth: 3 }} />
      <circle cx={ROW_HEIGHT_INT} cy={ROW_HEIGHT_INT / 2} r={ROW_HEIGHT_INT / 8} stroke={cyan[6]} strokeWidth={3} fill={cyan[2]} />
    </svg>
  );
};

ItemFinisher.propTypes = {
  topOffset: PropTypes.number.isRequired,
  leftOffset: PropTypes.number.isRequired,
};

ItemFinisher.defaultProps = {
  topOffset: 0,
  leftOffset: 0,
};

export default ItemFinisher;
