import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import { getTimeTrackingDashboardUsersAccountsByDate } from 'src/api/teacket';
import CondensedTable from 'src/components/layout/CondensedTable';
import dayjs from 'dayjs';
import Space2 from 'src/components/layout/Space2';

const DashboardDataUsersAccountsByDate = ({ accountId, from, to, serviceId, userId }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const columns = [
    {
      title: 'Date',
      key: 'date',
      render: (record) => (
        <Space2>
          <span className='font-light text-xs'>{dayjs(record?.date).utc(true).format('MMM DD')}</span>
          <span className='font-light text-xs opacity-60'>{dayjs(record?.date).utc(true).format('ddd')}</span>
        </Space2>
      ),
    },
    {
      title: 'Hours',
      key: 'hours',
      align: 'right',
      width: '80px',
      render: (record) => <span className='font-light text-xs'>{record?.hours}</span>,
    },
  ];

  useEffect(() => {
    console.log('userId:', userId);
    if (!userId) return;
    loadData();
  }, [userId]);

  const loadData = async () => {
    console.log('loadData');
    setLoading(true);
    try {
      let resp = await getTimeTrackingDashboardUsersAccountsByDate(serviceId, userId, accountId, from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'));
      setData(resp);
    } catch {
      console.error('Error loading data');
      setData(null);
    }
    setLoading(false);
  };

  if (!data && loading) return <SkeletonTable />;

  return <CondensedTable showHeader={false} columns={columns} dataSource={data} pagination={false} rowKey={'date'} />;
};

DashboardDataUsersAccountsByDate.propTypes = {
  from: PropTypes.object,
  to: PropTypes.object,
  serviceId: PropTypes.string,
  accountId: PropTypes.string,
  userId: PropTypes.string,
};

export default DashboardDataUsersAccountsByDate;
