import React, { useState, useEffect, useContext } from 'react';
import FsiReportContext from 'src/context/FsiReportContext';
import { Table, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { getProjectList } from 'src/api/fsireport';
import { notEmptyArray } from 'src/misc/Misc';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

//import PropTypes from 'prop-types';

var projectListCurrentPage = 1;

const ProjectListWidget = () => {
  const navigate = useNavigate();
  const { projectList, setProjectList, projectQuery, projectListLoading, setProjectListLoading } = useContext(FsiReportContext);

  const columns = [
    {
      title: 'Account',
      key: 'acc',
      sorter: (a, b) => a.accName.localeCompare(b.accName),
      render: (record) => (
        <div>
          <div className='font-bold'>{record.accName}</div>
          <div className='font-light text-xs opacity-60'>{record.accId}</div>
        </div>
      ),
    },
    {
      title: 'Last Report',
      key: 'lastReportDateTime',
      width: 250,
      align: 'right',
      sorter: (a, b) => {
        if (!a.lastReportDateTime && !b.lastReportDateTime) {
          return 0;
        }
        if (!a.lastReportDateTime) {
          return -1; // Treat null as oldest
        }
        if (!b.lastReportDateTime) {
          return 1; // Treat null as oldest
        }
        return dayjs(a.lastReportDateTime).diff(dayjs(b.lastReportDateTime));
      },
      render: (record) => {
        const isOld = record?.lastReportDateTime && dayjs().diff(dayjs(record.lastReportDateTime), 'month') > 3;
        return (
          <>
            {record?.lastReportDateTime ? (
              <>
                {dayjs(record.lastReportDateTime).fromNow()}
                {isOld && (
                  <Tooltip title='Last generated report is older than 3 months'>
                    <WarningOutlined style={{ color: 'red', marginLeft: 8 }} />
                  </Tooltip>
                )}
              </>
            ) : (
              'n/a'
            )}
          </>
        );
      },
    },
  ];

  const [pageSize] = useState(25);
  const [thereIsMore, setThereIsMore] = useState(false);

  useEffect(() => {
    console.log('ProjectListWidget useEffect', projectQuery);
    projectListCurrentPage = 1;
    setProjectList(null);
    setThereIsMore(false);
    loadData();
  }, [projectQuery]);

  const loadData = async (loadMore = false) => {
    try {
      setProjectListLoading(true);
      const q = projectQuery?.trim() ?? null;
      const resp = await getProjectList(q?.length > 0 ? q : null, projectListCurrentPage, pageSize);
      projectListCurrentPage++;
      setThereIsMore(resp?.length === pageSize ? true : false);
      if (true === notEmptyArray(resp)) {
        if (loadMore) setProjectList([...projectList, ...resp]);
        else setProjectList(resp);
      }
      setProjectListLoading(false);
    } catch (error) {
      if (error === 'cancelled') return;
      console.log(error);
      setProjectListLoading(false);
    }
  };

  const handleOnRow = (record) => {
    return {
      onClick: async () => {
        navigate(`/fsi-report/projects/${record.id}`);
      },
    };
  };

  return (
    <Table
      //projectListLoading={thereIsMore && projectListLoading}
      loading={projectListLoading}
      pagination={{
        pageSize,
        total: thereIsMore ? projectList?.length + 1 : projectList?.length ?? 0,
        onChange: (page, pageSize) => (thereIsMore && page > projectList?.length / pageSize ? loadData(true) : null),
      }}
      size='small'
      columns={columns}
      dataSource={projectList}
      rowKey={'id'}
      onRow={handleOnRow}
      rowClassName='cursor-pointer'
    />
  );
};

ProjectListWidget.propTypes = {};

export default ProjectListWidget;
