import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import { Button, Select, Spin } from 'antd';
import RangePicker from 'src/components/controls/RangePicker/RangePicker';
import { SendOutlined } from '@ant-design/icons';
import { getBrowserTimeZone } from 'src/misc/Timezone';
import dayjs from 'dayjs';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getDashboardsTickets, getDistinctRequestTypes, getDistinctRequestStatuses, getDistinctRequestCategories, getDistinctRequestSubCategoriesForCategories } from 'src/api/teacket';
import { getDistinctRegions, getDistinctQuarters, getDistinctPlatforms, getDistinctForecasts, getDistinctStages, getDistinctTypes } from 'src/api/snowflake';
import { notEmptyArray } from 'src/misc/Misc';

const DashboardFilters = ({ onSubmit, includeServices = true, includeRegions = false, includeSubRegions = false, includeQuarters = false, includeACVs = false, includePlatforms = false, includeForecasts = false, includeStages = false, includeTypes = false, includeRequestTypes = false, includeRequestStatuses = false, includeRequestCategories = false, includeRequestSubCategories = false }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { servicesForTenant, dashboardListServices, setDashboardRegions, setDashboardSubRegions, setDashboardQuarters, setDashboardACVs, setDashboardPlatforms, setDashboardForecasts, setDashboardStages, setDashboardTypes, setDashboardRequestTypes, setDashboardRequestStatuses, setDashboardRequestCategories, setDashboardRequestSubCategories, setDashboardListServices, dashboardStartDate, setDashboardStartDate, dashboardEndDate, setDashboardEndDate, setDashboardDataTickets } =
    useContext(TeacketContext);

  //#region Use States

  const [initiated, setInitiated] = useState(false);

  const [regions, setRegions] = useState({});
  const [fetchingRegions, setFetchingRegions] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedSubRegions, setSelectedSubRegions] = useState([]);

  const [quarters, setQuarters] = useState([]);
  const [fetchingQuarters, setFeatchingQuarters] = useState(false);
  const [selectedQuarters, setSelectedQuarters] = useState([]);

  const [selectedACVs, setSelectedACVs] = useState([]);

  const [platforms, setPlatforms] = useState([]);
  const [fetchingPlatforms, setFetchingPlatforms] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  const [forecasts, setForecasts] = useState([]);
  const [fetchingForecasts, setFetchingForecasts] = useState(false);
  const [selectedForecasts, setSelectedForecasts] = useState([]);

  const [stages, setStages] = useState([]);
  const [fetchingStages, setFetchingStages] = useState(false);
  const [selectedStages, setSelectedStages] = useState([]);

  const [types, setTypes] = useState([]);
  const [fetchingTypes, setFetchingTypes] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const [requestTypes, setRequestTypes] = useState([]);
  const [fetchingRequestTypes, setFetchingRequestTypes] = useState(false);
  const [selectedRequestTypes, setSelectedRequestTypes] = useState([]);

  const [requestStatuses, setRequestStatuses] = useState([]);
  const [fetchingRequestStatuses, setFetchingRequestStatuses] = useState(false);
  const [selectedRequestStatuses, setSelectedRequestStatuses] = useState([]);

  const [requestCategories, setRequestCategories] = useState([]);
  const [fetchingRequestCategories, setFetchingRequestCategories] = useState(false);
  const [selectedRequestCategories, setSelectedRequestCategories] = useState([]);

  const [requestSubCategories, setRequestSubCategories] = useState([]);
  const [fetchingRequestSubCategories, setFetchingRequestSubCategories] = useState(false);
  const [selectedRequestSubCategories, setSelectedRequestSubCategories] = useState([]);

  //#endregion

  const acvs = ['$100,000+', '$500,000+'];

  useEffect(() => {
    setDefaultRange();
    if (includeRegions) {
      loadRegions();
    }
    if (includeQuarters) {
      loadQuarters();
    }
    if (includePlatforms) {
      loadPlatforms();
    }
    if (includeForecasts) {
      loadForecasts();
    }
    if (includeStages) {
      loadStages();
    }
    if (includeTypes) {
      loadTypes();
    }
    if (includeRequestTypes) {
      loadRequestTypes();
    }
    if (includeRequestStatuses) {
      loadRequestStatuses();
    }
    if (includeRequestCategories) {
      loadRequestCategories();
    }
  }, []);

  useEffect(() => {
    if (selectedRequestCategories.length === 0) {
      setRequestSubCategories([]);
      return;
    }

    if (includeRequestSubCategories) {
      loadRequestSubCategories();
    }
  }, [selectedRequestCategories]);

  useEffect(() => {
    if (initiated) return;
    if (!dashboardStartDate) return;
    if (!dashboardEndDate) return;
    setInitiated(true);
  }, [dashboardStartDate, dashboardEndDate]);

  const getSubRegionsOptions = () => {
    // When no regions selected, hide all subregions
    if (!selectedRegions || selectedRegions.length === 0) {
      return [];
    }

    // When specific regions are selected, show only their subregions
    return selectedRegions.map((region) => ({
      label: <span>{region}</span>,
      title: region,
      options: (regions[region] || []).map((subRegion) => ({
        value: subRegion,
        label: subRegion,
      })),
    }));
  };

  const loadRegions = async () => {
    setFetchingRegions(true);
    const result = await getDistinctRegions();
    setRegions(result);
    setFetchingRegions(true);
  };

  const loadQuarters = async () => {
    setFeatchingQuarters(true);
    const result = await getDistinctQuarters();
    setQuarters(result);
    setFeatchingQuarters(false);
  };

  const loadPlatforms = async () => {
    setFetchingPlatforms(true);
    const result = await getDistinctPlatforms();
    setPlatforms(result);
    setFetchingPlatforms(false);
  };

  const loadForecasts = async () => {
    setFetchingForecasts(true);
    const result = await getDistinctForecasts();
    setForecasts(result);
    setFetchingForecasts(true);
  };

  const loadStages = async () => {
    setFetchingStages(true);
    const result = await getDistinctStages();
    setStages(result);
    setFetchingStages(true);
  };

  const loadTypes = async () => {
    setFetchingTypes(true);
    const result = await getDistinctTypes();
    setTypes(result);
    setFetchingTypes(true);
  };

  const loadRequestTypes = async () => {
    setFetchingRequestTypes(true);
    const result = await getDistinctRequestTypes();
    setRequestTypes(result);
    setFetchingRequestTypes(true);
  };

  const loadRequestStatuses = async () => {
    setFetchingRequestStatuses(true);
    const result = await getDistinctRequestStatuses();
    setRequestStatuses(result);
    setFetchingRequestStatuses(true);
  };

  const loadRequestCategories = async () => {
    setFetchingRequestCategories(true);
    const result = await getDistinctRequestCategories();
    setRequestCategories(result);
    setFetchingRequestCategories(true);
  };

  const loadRequestSubCategories = async () => {
    setFetchingRequestSubCategories(true);
    const result = await getDistinctRequestSubCategoriesForCategories(selectedRequestCategories);
    setRequestSubCategories(result);
    setFetchingRequestSubCategories(true);
  };

  const setDefaultRange = () => {
    const timezone = getBrowserTimeZone();
    const start = dayjs().tz(timezone).subtract(7, 'days').startOf('day');
    const end = dayjs().tz(timezone).subtract(1, 'days').endOf('day');
    setDashboardStartDate(start);
    setDashboardEndDate(end);
  };

  const handleOnSubmit = async () => {
    console.log('handleOnSubmit()');

    // console.log('typeof onSubmit:', typeof onSubmit);
    if (onSubmit) {
      onSubmit();
      return;
    }

    await executeAsyncProcess(async () => {
      try {
        const result = await getDashboardsTickets(dayjs(dashboardStartDate).format(), dayjs(dashboardEndDate).format(), true === notEmptyArray(dashboardListServices) ? dashboardListServices : null);
        setDashboardDataTickets(result);
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <div className='flex flex-row flex-wrap gap-4'>
      <div>
        <RangePicker
          startDate={dashboardStartDate}
          endDate={dashboardEndDate}
          onChange={(v) => {
            setDashboardStartDate(v.startDate);
            setDashboardEndDate(v.endDate);
          }}
        />
      </div>
      {includeServices && (
        <div>
          <Select
            mode='multiple'
            value={dashboardListServices}
            onChange={(v) => setDashboardListServices(v)}
            className='w-60'
            options={servicesForTenant?.map((x) => ({
              value: x.id,
              label: x.name,
            }))}
            allowClear
            placeholder='[all services]'
          />
        </div>
      )}
      {includeRegions && (
        <div>
          <Select
            mode='multiple'
            value={selectedRegions}
            onChange={(v) => {
              setSelectedRegions(v);
              setDashboardRegions(v);

              // Reset subregion selection when regions change
              setSelectedSubRegions([]);
            }}
            className='w-60'
            options={Object.keys(regions)?.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingRegions ? <Spin size='small' /> : null}
            placeholder='[all regions]'
          />
        </div>
      )}
      {includeSubRegions && (
        <div>
          <Select
            mode='multiple'
            value={selectedSubRegions}
            onChange={(v) => {
              setSelectedSubRegions(v);
              setDashboardSubRegions(v);
            }}
            className='w-60'
            options={getSubRegionsOptions()}
            allowClear
            placeholder='[all sub-regions]'
            disabled={includeRegions && selectedRegions.length === 0}
          />
        </div>
      )}
      {includeQuarters && (
        <div>
          <Select
            mode='multiple'
            value={selectedQuarters}
            onChange={(v) => {
              setSelectedQuarters(v);
              setDashboardQuarters(v);
            }}
            className='w-60'
            options={quarters.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingQuarters ? <Spin size='small' /> : null}
            placeholder='[all quarters]'
          />
        </div>
      )}
      {includeACVs && (
        <div>
          <Select
            mode='multiple'
            value={selectedACVs}
            onChange={(v) => {
              setSelectedACVs(v);
              setDashboardACVs(v);
            }}
            className='w-60'
            options={acvs.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            placeholder='[all ACVs]'
          />
        </div>
      )}
      {includePlatforms && (
        <div>
          <Select
            mode='multiple'
            value={selectedPlatforms}
            onChange={(v) => {
              setSelectedPlatforms(v);
              setDashboardPlatforms(v);
            }}
            className='w-60'
            options={platforms.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingPlatforms ? <Spin size='small' /> : null}
            placeholder='[all platforms]'
          />
        </div>
      )}
      {includeForecasts && (
        <div>
          <Select
            mode='multiple'
            value={selectedForecasts}
            onChange={(v) => {
              setSelectedForecasts(v);
              setDashboardForecasts(v);
            }}
            className='w-60'
            options={forecasts.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingForecasts ? <Spin size='small' /> : null}
            placeholder='[all forecasts]'
          />
        </div>
      )}
      {includeStages && (
        <div>
          <Select
            mode='multiple'
            value={selectedStages}
            onChange={(v) => {
              setSelectedStages(v);
              setDashboardStages(v);
            }}
            className='w-60'
            options={stages.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingStages ? <Spin size='small' /> : null}
            placeholder='[all stages]'
          />
        </div>
      )}
      {includeTypes && (
        <div>
          <Select
            mode='multiple'
            value={selectedTypes}
            onChange={(v) => {
              setSelectedTypes(v);
              setDashboardTypes(v);
            }}
            className='w-60'
            options={types.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingTypes ? <Spin size='small' /> : null}
            placeholder='[all types]'
          />
        </div>
      )}
      {includeRequestTypes && (
        <div>
          <Select
            mode='multiple'
            value={selectedRequestTypes}
            onChange={(v) => {
              setSelectedRequestTypes(v);
              setDashboardRequestTypes(v);
            }}
            className='w-60'
            options={requestTypes?.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingRequestTypes ? <Spin size='small' /> : null}
            placeholder='[all request types]'
          />
        </div>
      )}
      {includeRequestStatuses && (
        <div>
          <Select
            mode='multiple'
            value={selectedRequestStatuses}
            onChange={(v) => {
              setSelectedRequestStatuses(v);
              setDashboardRequestStatuses(v);
            }}
            className='w-60'
            options={requestStatuses?.map((x) => ({
              value: x,
              label: x,
            }))}
            allowClear
            notFoundContent={fetchingRequestStatuses ? <Spin size='small' /> : null}
            placeholder='[all request statuses]'
          />
        </div>
      )}
      {includeRequestCategories && (
        <>
          <div>
            <Select
              mode='multiple'
              value={selectedRequestCategories}
              onChange={(v) => {
                setSelectedRequestCategories(v);
                setDashboardRequestCategories(v);
              }}
              className='w-60'
              options={requestCategories?.map((x) => ({
                value: x,
                label: x,
              }))}
              allowClear
              notFoundContent={fetchingRequestCategories ? <Spin size='small' /> : null}
              placeholder='[all request categories]'
            />
          </div>
          <div>
            <Select
              mode='multiple'
              disabled={selectedRequestCategories.length === 0}
              value={selectedRequestSubCategories}
              onChange={(v) => {
                setSelectedRequestSubCategories(v);
                setDashboardRequestSubCategories(v);
              }}
              className='w-60'
              options={requestSubCategories?.map((x) => ({
                value: x,
                label: x,
              }))}
              allowClear
              notFoundContent={fetchingRequestSubCategories ? <Spin size='small' /> : null}
              placeholder='[all request sub-categories]'
            />
          </div>
        </>
      )}
      <div>
        <Button onClick={handleOnSubmit}>
          <SendOutlined />
        </Button>
      </div>
    </div>
  );
};

DashboardFilters.propTypes = {
  onSubmit: PropTypes.func,
  includeServices: PropTypes.bool,
  includeRegions: PropTypes.bool,
  includeSubRegions: PropTypes.bool,
  includeQuarters: PropTypes.bool,
  includeACVs: PropTypes.bool,
  includePlatforms: PropTypes.bool,
  includeForecasts: PropTypes.bool,
  includeStages: PropTypes.bool,
  includeTypes: PropTypes.bool,
  includeRequestTypes: PropTypes.bool,
  includeRequestStatuses: PropTypes.bool,
  includeRequestCategories: PropTypes.bool,
  includeRequestSubCategories: PropTypes.bool,
};

export default DashboardFilters;
