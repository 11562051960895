import React, { useEffect, useState } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getReportsOrphaned, deletePermission, deleteRole } from 'src/api/accesscontrol';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import CondensedTable from 'src/components/layout/CondensedTable';
import { Button, message } from 'antd';
import { FaRegTrashCan } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import useTheme from 'src/hooks/useTheme';
import { DEFAULT_ROLE } from 'src/misc/Config';

//import PropTypes from 'prop-types';

const Orphans = () => {
  const { theme } = useTheme();
  const { executeAsyncProcess, isBusy } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const columnsPermissions = [
    {
      title: 'Permission',
      key: 'permission',
      width: '35%',
      render: (record) => (
        <span className='text-xs break-words' style={{ lineBreak: 'anywhere' }}>
          {record.permission}
        </span>
      ),
    },
    {
      title: 'Description',
      key: 'description',
      render: (record) => <span className='text-xs'>{record.description}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      width: '60px',
      align: 'center',
      render: (record) => (
        <YesNoDialog
          title='Delete'
          body={
            <>
              Do you want to delete permission <strong>{record.permission}</strong>?
            </>
          }
          onYesClick={() => {
            handlePermissionDelete(record.permission);
          }}
          iconYes={<MdDelete />}
          showRed={true}
          labelYes='Yes, delete permission'
          labelNo='Cancel'
        >
          <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }}>
            <FaRegTrashCan />
          </Button>
        </YesNoDialog>
      ),
    },
  ];

  const columnsRoles = [
    {
      title: 'Role',
      key: 'role',
      width: '35%',
      render: (record) => (
        <span className='text-xs' style={{ lineBreak: 'anywhere' }}>
          {record.role}
        </span>
      ),
    },
    {
      title: 'Description',
      key: 'description',
      render: (record) => <div className='text-xs' dangerouslySetInnerHTML={{ __html: record.description }} />,
    },
    {
      title: 'Action',
      key: 'action',
      width: '60px',
      align: 'center',
      render: (record) => (
        <>
          {record.role !== DEFAULT_ROLE && (
            <YesNoDialog
              title='Delete'
              body={
                <>
                  Do you want to delete role <strong>{record.role}</strong>?
                </>
              }
              onYesClick={() => {
                handleRoleDelete(record.role);
              }}
              iconYes={<MdDelete />}
              showRed={true}
              labelYes='Yes, delete role'
              labelNo='Cancel'
            >
              <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }}>
                <FaRegTrashCan />
              </Button>
            </YesNoDialog>
          )}
        </>
      ),
    },
  ];

  const [data, setData] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (loadMore = false) => {
    await executeAsyncProcess(async () => {
      try {
        const resp = await getReportsOrphaned();
        setData(resp);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handlePermissionDelete = async (permission) => {
    console.log('handlePermissionDelete', permission);
    executeAsyncProcess(async () => {
      try {
        await deletePermission(permission);
        const permissionsNoRoles = [...data.permissionsNoRoles].filter((x) => x.permission !== permission);
        const d = { ...data, permissionsNoRoles };
        setData(d);
        messageApi.open({
          type: 'success',
          content: 'Permission has been deleted',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleRoleDelete = async (role) => {
    console.log('handleRoleDelete', role);
    executeAsyncProcess(async () => {
      try {
        await deleteRole(role);
        const rolesNoAssignments = [...data.rolesNoAssignments].filter((x) => x.role !== role);
        const rolesNoPermissions = [...data.rolesNoPermissions].filter((x) => x.role !== role);
        const d = { ...data, rolesNoAssignments, rolesNoPermissions };
        setData(d);
        messageApi.open({
          type: 'success',
          content: 'Role has been deleted',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <div className='grid grid-cols-1 xl:grid-cols-3 gap-4'>
      {contextHolder}
      <DraggableLayoutItem title='Permissions - w/o any role'>{!data && isBusy ? <SkeletonTable /> : <CondensedTable columns={columnsPermissions} dataSource={data?.permissionsNoRoles ?? null} pagination={{ size: 'small' }} />}</DraggableLayoutItem>
      <DraggableLayoutItem title='Roles - w/o any permission'>{!data && isBusy ? <SkeletonTable /> : <CondensedTable columns={columnsRoles} dataSource={data?.rolesNoPermissions ?? null} pagination={{ size: 'small' }} />}</DraggableLayoutItem>
      <DraggableLayoutItem title='Roles - w/o any assignment'>{!data && isBusy ? <SkeletonTable /> : <CondensedTable columns={columnsRoles} dataSource={data?.rolesNoAssignments ?? null} pagination={{ size: 'small' }} />}</DraggableLayoutItem>
    </div>
  );
};

Orphans.propTypes = {};

export default Orphans;
