import React, { Component } from 'react';
import AITemplatesContext from './AITemplatesContext';

export default class AIModelsContextProvider extends Component {
  state = {
    aiTemplates: [],
    selectedTemplate: null,
  };

  render() {
    return (
      <AITemplatesContext.Provider
        value={{
          ...this.state,
          aiTemplates: this.state.aiTemplates,
          setAiTemplates: (aiTemplates) => this.setState({ aiTemplates }),
          setSelectedTemplate: (selectedTemplate) => this.setState({ selectedTemplate }),
          setSelectedTemplateById: (selectedTemplateId) => {
            const selectedTemplate = this.state.aiTemplates.find((u) => selectedTemplateId === u.id);
            this.setState({ selectedTemplate });
          },
          selectedTemplate: this.state.selectedTemplate,
          addNewCreatedAiTemplate: (aiTemplate) => {
            const aiTemplates = [{ ...aiTemplate, newCreated: true }].concat(this.state.aiTemplates);
            this.setState({ aiTemplates });
          },
          updateAiTemplate: (aiTemplateId, props) => {
            console.log('updateAiTemplate', aiTemplateId, props);
            const aiTemplates = this.state.aiTemplates.map((u) => {
              if (aiTemplateId === u.id) {
                return { ...u, ...props };
              }
              return u;
            });
            this.setState({ aiTemplates });
          },
          deleteAiTemplate: (aiTemplateId) => {
            console.log('deleteAiTemplate', aiTemplateId);
            const aiTemplates = this.state.aiTemplates.filter((u) => aiTemplateId !== u.id);
            this.setState({ aiTemplates });
          },
        }}>
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </AITemplatesContext.Provider>
    );
  }
}
