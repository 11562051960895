import React, { useEffect, useState, useContext } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import PropTypes from 'prop-types';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import { getTimeTrackingDashboardAccounts } from 'src/api/teacket';
import { textSorter, numberSorter } from 'src/misc/Misc';
import Space2 from 'src/components/layout/Space2';
import CondensedTable from 'src/components/layout/CondensedTable';
import DashboardDataAccountsUsers from './DashboardDataAccountsUsers';

const DashboardDataAccounts = ({ from, to, serviceId }) => {
  const { commandPressed } = useContext(GlobalContext);

  const columns = [
    {
      title: 'Account',
      key: 'account',
      render: (record) => (
        <>
          <Space2>
            <span className='font-bold text-sm'>{record?.acc_name}</span>
            {commandPressed && <span className='font-light text-xs text-genesys-orange-base'>{record?.acc_id}</span>}
          </Space2>
        </>
      ),
      sorter: (a, b) => textSorter(a, b, 'acc_name'),
    },
    {
      title: 'Hours',
      key: 'hours',
      align: 'right',
      width: '80px',
      render: (record) => <span className='font-bold text-sm'>{record?.hours}</span>,
      sorter: (a, b) => numberSorter(a, b, 'hours'),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    console.log('from/to:', from, to);
    if (!from || !to) return;
    loadData();
  }, [from, to]);

  const loadData = async () => {
    console.log('loadData');
    setLoading(true);
    try {
      const resp = await getTimeTrackingDashboardAccounts(serviceId, from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'));
      setData(resp);
    } catch {
      console.error('Error loading data');
      setData(null);
    }
    setLoading(false);
  };

  if (loading) return <SkeletonTable />;

  return (
    <CondensedTable
      showHeader={false}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={'acc_id'}
      expandable={{
        expandedRowRender: (record) => (
          <p style={{ margin: 0 }} className='pl-10'>
            <DashboardDataAccountsUsers from={from} to={to} serviceId={serviceId} accountId={record?.acc_id} />
          </p>
        ),
        // rowExpandable: () => true,
      }}
    />
  );
};

DashboardDataAccounts.propTypes = {
  selectedFilters: PropTypes.object,
  from: PropTypes.object,
  to: PropTypes.object,
  serviceId: PropTypes.string,
};

export default DashboardDataAccounts;
