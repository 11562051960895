import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import TitleBar from 'src/components/layout/TitleBar';
import { Button, Input, Select, Tag, Space, Alert, message } from 'antd';
import UserPicker from 'src/components/controls/UserPicker/UserPicker';
import TenantPicker from 'src/components/controls/TenantPicker/TenantPicker';
import { postRoleAssignment, getRoleAssignments, deleteRoleAssignment } from 'src/api/accesscontrol';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import { FaCircleMinus, FaPersonCircleQuestion } from 'react-icons/fa6';
import { LuUsers2, LuBuilding } from 'react-icons/lu';
import { MdOutlineMailOutline } from 'react-icons/md';
import { TbApi } from 'react-icons/tb';
import { Tooltip } from '@mui/material';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { MdDelete } from 'react-icons/md';
import AssignmentsDepartments from './AssignmentsDepartments';
import AccountTypePicker from './AccountTypePicker';
import { uuid } from 'short-uuid';
import CondensedTable from 'src/components/layout/CondensedTable';
import NoData from 'src/components/layout/NoData';

const AssignmentsAssignedItems = ({ role }) => {
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const INPUT_WIDTH = '300px';

  const OBJECT_TYPE_LIST = [
    { value: 'user', label: 'User', icon: <LuUsers2 /> },
    { value: 'tenant', label: 'Tenant', icon: <LuBuilding /> },
    { value: 'email', label: 'Email', icon: <MdOutlineMailOutline /> },
    { value: 'acc_type', label: 'Account Type', icon: <FaPersonCircleQuestion /> },
    { value: 'gc-client-credentials', label: 'GC Client Credentials', icon: <TbApi /> },
  ];

  const columns = [
    {
      title: 'Type',
      key: 'object_type',
      width: 180,
      render: (record) => (
        <Space className='btn'>
          {OBJECT_TYPE_LIST.find((x) => x.value === record.object_type).icon}
          <div>{OBJECT_TYPE_LIST.find((x) => x.value === record.object_type).label}</div>
          {'tenant' === record.object_type && (
            <>
              {'include' === record?.props?.departmentsMode && (
                <Tooltip title='Contains includes' placement='top'>
                  <Tag color='blue'>I</Tag>
                </Tooltip>
              )}
              {'exclude' === record?.props?.departmentsMode && (
                <Tooltip title='Contains excludes' placement='top'>
                  <Tag color='red'>E</Tag>
                </Tooltip>
              )}
            </>
          )}
        </Space>
      ),
    },
    {
      title: 'Object',
      key: 'object',
      render: (record) => {
        switch (record.object_type) {
          case 'user':
            return (
              <div className='flex flex-row'>
                <Tooltip title={`Click to open: ${record.user_name}`} placement='top'>
                  <div
                    className='cursor-pointer hover:scale-105 transition-all'
                    onClick={() => {
                      window.open(`/master-admin/users?userId=${record.user_id}`, '_blank');
                    }}
                  >
                    <div className='font-semibold '>
                      {record.user_name} {record.justCreated && <Tag color='blue'>just assigned</Tag>}
                    </div>
                    <div className='opacity-80 text-xs font-light'>{record.user_id}</div>
                  </div>
                </Tooltip>
              </div>
            );
          case 'tenant':
            return (
              <div className='flex flex-row'>
                <Tooltip title={`Click to open: ${record.tenant_name}`} placement='top'>
                  <div
                    className='cursor-pointer hover:scale-105 transition-all'
                    onClick={() => {
                      window.open(`/master-admin/tenants?tenantId=${record.tenant_id}`, '_blank');
                    }}
                  >
                    <div className='font-semibold'>
                      {record.tenant_name} {record.justCreated && <Tag color='blue'>just assigned</Tag>}
                    </div>
                    <div className='opacity-80 text-xs font-light'>{record.tenant_id}</div>
                  </div>
                </Tooltip>
              </div>
            );
          case 'gc-client-credentials':
            return (
              <>
                <div className='font-semibold'>
                  {record.generic_name} {record.justCreated && <Tag color='blue'>just assigned</Tag>}
                </div>
                <div className='opacity-80 text-xs font-light'>{record.generic_id}</div>
              </>
            );
          default:
            return (
              <div className='font-semibold'>
                {record.generic_id} {record.justCreated && <Tag color='blue'>just assigned</Tag>}
              </div>
            );
        }
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      align: 'center',
      render: (record) => (
        <Space className='btn'>
          <YesNoDialog
            title='Unassign'
            body={<>Do you want to unassign this object?</>}
            onYesClick={() => {
              handlePernamentlyDelete(record.assignment_id);
            }}
            iconYes={<MdDelete />}
            showRed={true}
            labelYes='Yes, delete assignment'
            labelNo='Cancel'
          >
            <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }}>
              <FaCircleMinus />
            </Button>
          </YesNoDialog>
        </Space>
      ),
    },
  ];

  const [selectedObjectType, setSelectedObjectType] = useState(OBJECT_TYPE_LIST[0].value);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [resetSelection, setResetSelection] = useState(false);
  const [pageSize] = useState(10);
  const [roleAssignments, setRoleAssignments] = useState(null);

  useEffect(() => {
    console.log('role:', role);
    setRoleAssignments(null);
    if (role) loadData();
  }, [role]);

  useEffect(() => {
    if (resetSelection) setResetSelection(false);
  }, [resetSelection]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getRoleAssignments({ role: role.role });
        console.log('resp', resp);
        setRoleAssignments(resp);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const getAssignControl = () => {
    switch (selectedObjectType) {
      case 'user':
        return <UserPicker width={INPUT_WIDTH} onPick={handleUserOnPick} />;
      case 'tenant':
        return <TenantPicker width={INPUT_WIDTH} onPick={handleTenantOnPick} />;
      case 'email':
        return <Input placeholder='Enter email address...' style={{ width: INPUT_WIDTH }} value={selectedValue} onChange={handleGenericOnChange} />;
      case 'acc_type':
        return <AccountTypePicker width={INPUT_WIDTH} onChange={handleAccountTypeOnChange} />;
      case 'gc-client-credentials':
        return (
          <Space>
            <Input placeholder='Enter client id...' style={{ width: INPUT_WIDTH }} value={selectedValue} onChange={handleGenericValueOnChange} />
            <Input placeholder='Enter description...' style={{ width: INPUT_WIDTH }} value={selectedName} onChange={handleGenericNameOnChange} />
          </Space>
        );
      default:
        return "Can't find control for selectedObjectType";
    }
  };

  const handleUserOnPick = (v) => {
    console.log('handleUserOnPick:', v);
    setSelectedValue(v?.user_id);
    setSelectedName(v?.user_name);
  };

  const handleTenantOnPick = (v) => {
    console.log('handleTenantOnPick:', v);
    setSelectedValue(v?.id);
    setSelectedName(v?.name);
  };

  const handleAccountTypeOnChange = (v) => {
    console.log('handleAccountTypeOnChange:', v);
    setSelectedValue(v);
    setSelectedName(v);
  };

  // use this handler if value and name are the same - for instance for email
  const handleGenericOnChange = (e) => {
    console.log('handleGenericOnChange:', e.target.value);
    setSelectedValue(e.target.value);
    setSelectedName(e.target.value);
  };

  const handleGenericValueOnChange = (e) => {
    console.log('handleGenericVaueOnChange:', e.target.value);
    setSelectedValue(e.target.value);
  };

  const handleGenericNameOnChange = (e) => {
    console.log('handleGenericNameOnChange:', e.target.value);
    setSelectedName(e.target.value);
  };

  const handleBtnAssing = () => {
    console.log('handleBtnAssing');
    executeAsyncProcess(async () => {
      let request = { role: role.role, object_type: selectedObjectType };
      try {
        switch (selectedObjectType) {
          case 'user':
            request.user_id = selectedValue;
            break;
          case 'tenant':
            request.tenant_id = selectedValue;
            break;
          case 'gc-client-credentials':
            request.generic_id = selectedValue;
            request.generic_name = selectedName;
            break;
          default:
            request.generic_id = selectedValue;
            break;
        }
        const resp = await postRoleAssignment(request);
        console.log('postRoleAssignment:', resp);
        const ra = [...roleAssignments];
        switch (selectedObjectType) {
          case 'user':
            resp.user_name = selectedName;
            break;
          case 'tenant':
            resp.tenant_name = selectedName;
            break;
          default:
          // nothing to do on default
        }
        ra.unshift({ ...resp, justCreated: true });
        setRoleAssignments(ra);
        setSelectedName(null);
        setSelectedValue(null);
        setResetSelection(true);

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Item has been assigned',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handlePernamentlyDelete = (assignmentId) => {
    console.log('handlePernamentlyDelete', role);
    executeAsyncProcess(async () => {
      try {
        await deleteRoleAssignment(assignmentId);
        const ra = roleAssignments.filter((x) => x.assignment_id !== assignmentId);
        setRoleAssignments(ra);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Object has been unassigned',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleAssignmentOnSave = (assignment) => {
    console.log('handleAssignmentOnSave:', assignment);
    const ra = [...roleAssignments];
    const idx = ra.findIndex((x) => x.assignment_id === assignment.assignment_id);
    ra[idx] = { ...ra[idx], ...assignment };
    setRoleAssignments(ra);
  };

  if (!role)
    return (
      <div className='h-96 flex flex-row justify-center items-center'>
        <NoData />
      </div>
    );

  return (
    <div className='h-full max-h-ful w-full overflow-auto flex flex-col gap-2'>
      {contextHolder}
      <div className='flex-initial'>
        <TitleBar
          isSticky={false}
          busyIndicator={false}
          colorBackground={theme.backgroundBase}
          title={
            <Space>
              <div>{role.role}</div>
              <div className='font-extralight opacity-80'>role assignments</div>
            </Space>
          }
          afterTitleExtras={<></>}
        ></TitleBar>
        <WhenDesktop>
          <div className='px-2 h-14 flex-initial flex flex-row gap-2 overflow-hidden items-center' style={{ backgroundColor: theme.backgroundLight }}>
            <Select
              className='w-48'
              options={OBJECT_TYPE_LIST}
              value={selectedObjectType}
              onSelect={(v) => {
                setSelectedName(null);
                setSelectedValue(null);
                setSelectedObjectType(v);
              }}
            />
            {!resetSelection && <div>{getAssignControl()}</div>}

            <Button type='primary' disabled={!selectedValue || !selectedName} onClick={handleBtnAssing}>
              Assign
            </Button>
          </div>
        </WhenDesktop>
      </div>
      {role.for_tenant_admins && <Alert message={<>This role is marked as [for tenant admins].</>} showIcon type='warning' className='py-1 px-2' />}
      <Alert message='Please inform users that they must wait 5 minutes or log out and log back in for changes to take effect.' showIcon className='py-1 px-2' />
      <div className='flex-auto overflow-auto'>
        <CondensedTable style={{ marginTop: '1px' }} pagination={{ pageSize }} size='small' columns={columns} dataSource={roleAssignments} rowKey={'assignment_id'} expandable={{ expandedRowRender: (record) => <AssignmentsDepartments record={record} onSave={handleAssignmentOnSave} />, rowExpandable: (record) => record.object_type === 'tenant' }} />
      </div>
    </div>
  );
};

AssignmentsAssignedItems.propTypes = {
  role: PropTypes.string,
};

export default AssignmentsAssignedItems;
