import React, { useState, useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { getTicketTimeTrackingTotal } from 'src/api/teacket';
import PropTypes from 'prop-types';
import { FaRegClock } from 'react-icons/fa6';
import Space2 from 'src/components/layout/Space2';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';

const TicketTimeTrackingTotal = () => {
  const { currentTicket, setCurrentTicketTimeTrackingForceReload, currentTicketTimeTrackingForceReload } = useContext(TeacketContext);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentTicket?.id) return;
    loadData();
  }, [currentTicket]);

  useEffect(() => {
    if (!currentTicketTimeTrackingForceReload) return;
    loadData();
    setCurrentTicketTimeTrackingForceReload(false);
  }, [currentTicketTimeTrackingForceReload]);

  const loadData = async () => {
    setLoading(true);
    try {
      const { total: t } = await getTicketTimeTrackingTotal(currentTicket.id);
      setTotal(t ?? 0);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleOnWsMessageReceived = (e) => {
    console.log('[TicketTimeTrackingTotal] handleOnWsMessageReceived()', e);
    try {
      if ('TICKET_TIME_TRACKING_TOTAL' !== e.action) return;
      const { total: t } = e?.data ?? {};
      setTotal(t ?? 0);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='text-sm'>
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['TICKET_TIME_TRACKING_TOTAL']} />
      <Space2>
        <FaRegClock className={loading ? 'animate-pulse' : null} />
        {total || total === 0 ? total : '...'}
      </Space2>
    </div>
  );
};

TicketTimeTrackingTotal.propTypes = { ticketId: PropTypes.string };

export default TicketTimeTrackingTotal;
