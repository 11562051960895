import React, { useState } from 'react';
import { Select, Spin } from 'antd';
import { searchTenants } from 'src/api/tenants';
import PropTypes from 'prop-types';
import { isRequestCancelled } from 'src/misc/Misc';
import { FaHashtag, FaAws } from 'react-icons/fa';
import Space2 from 'src/components/layout/Space2';

const { Option } = Select;

const TenantPicker = ({ width, onPick }) => {
  const [tenantList, setTenantList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClear = async () => {
    console.log('handleClear');
    setTenantList([]);
    onPick(null);
  };

  const handleSearchForTenants = async (v) => {
    setTenantList([]);
    if (v.length < 3) return;
    try {
      setLoading(true);
      let resp = await searchTenants(v, 1, 25, ['id', 'name', 'third_party_env']);
      // console.log('Tenants:', resp);
      if (resp) {
        setTenantList(resp?.entities ?? []);
      }
      setLoading(false);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnSelect = (v) => {
    console.log('TenantPicker onSelect:', v);
    const tenant = tenantList.find((t) => t.id === v);
    onPick(tenant);
  };

  return (
    <Select
      showSearch
      allowClear
      placeholder='type to search tenant...'
      notFoundContent={loading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
      filterOption={false}
      onSearch={handleSearchForTenants}
      onClear={handleClear}
      onSelect={handleOnSelect}
      style={{ width }}
      autoComplete='off'
      optionRender={({ value }) => {
        const item = tenantList.find((x) => x.id === value);
        if (!item) return null;
        return (
          <div className='flex flex-col'>
            <div className='font-bold'>{item.name}</div>
            <div className='text-xs font-light opacity-60 overflow-hidden'>
              <Space2>
                <FaHashtag />
                {item.id}
              </Space2>
            </div>
            {item.third_party_env && (
              <div className='text-xs font-light opacity-60 overflow-hidden'>
                <Space2>
                  <FaAws />
                  {item.third_party_env}
                </Space2>
              </div>
            )}
          </div>
        );
      }}
    >
      {tenantList.map((d) => (
        <Option key={d.id}>{d.name}</Option>
      ))}
    </Select>
  );
};

TenantPicker.propTypes = {
  width: PropTypes.string,
  onPick: PropTypes.func,
};

TenantPicker.defaultProps = {
  width: '220px',
};

export default TenantPicker;
