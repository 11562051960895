//const PLATFORM_NAME = 'spc';
export const DEFAULT_CALLBACK_URL_SIGNED_IN = '/home';
export const DEFAULT_CALLBACK_URL_SIGNED_IN_ERROR = '/signinerror';
export const DEFAULT_CALLBACK_URL_SIGNED_OFF = '/signin';
export const SESSION_STORAGE_KEYS = {
  authTargetPath: 'spc.auth.targetPath',
};
export const LOCAL_STORAGE_KEYS = {
  appsCopilotAcknowledgeMNDAPopup: 'spc.apps.copilot.acknowledge.mndaPopup',
  appsCopilotDefaultModelId: 'spc.apps.copilot.favModel',
  authProvider: 'spc.auth.provider',
  authAccessToken: 'spc.auth.access_token',
  authRefreshToken: 'spc.auth.refresh_token',
  authExpiresAt: 'spc.auth.expires_at',
  authLogoutReason: 'spc.auth.logout_reason',
  invitationLinkId: 'spc.signin.invitationLinkId',
  isDarkMode: 'spc.theme.isDarkMode',
  layoutHome: 'spc.layout.home',
  layoutHomeHidden: 'spc.layout.homeHidden',
  layoutDashboards: 'spc.layout.dashboards',
  layoutDashboardsHidden: 'spc.layout.dashboardsHidden',
  layoutMasterAdmin: 'spc.layout.masterAdmin',
  layoutMasterAdminHidden: 'spc.layout.masterAdminHidden',
  layoutSecurityAdvisor: 'spc.layout.securityAdvisor',
  layoutSecurityAdvisorEvents: 'spc.layout.securityAdvisorEvents',
  layoutSecurityAdvisorEventsHidden: 'spc.layout.securityAdvisorEventsHidden',
  layoutSecurityAdvisorHidden: 'spc.layout.securityAdvisorHidden',
  layoutSecurityAdvisorOptions: 'spc.layout.securityAdvisorOptions',
  layoutSecurityAdvisorOptionsHidden: 'spc.layout.securityAdvisorOptionsHidden',
  layoutAuditViewerEvents: 'spc.layout.auditViewerEvents',
  layoutAuditViewerEventsHidden: 'spc.layout.auditViewerEventsHidden',
  layoutTeacketHome: 'spc.layout.teacketHome',
  layoutTeacketHomeHidden: 'spc.layout.teacketHomeHidden',
  layoutTeacketTicket: 'spc.layout.teacketTicket',
  layoutTeacketTicketHidden: 'spc.layout.teacketTicketHidden',
  layoutTeacketTicketCreate: 'spc.layout.teacketTicketCreate',
  layoutTeacketTicketCreateHidden: 'spc.layout.teacketTicketCreateHidden',
  layoutTeacketTicketModal: 'spc.layout.teacketTicketModal',
  layoutTeacketTicketModalHidden: 'spc.layout.teacketTicketModalHidden',
  layoutTeacketDashboard: 'spc.layout.layoutTeacketDashboard',
  layoutTeacketDashboardHidden: 'spc.layout.layoutTeacketDashboardHidden',
  teacketDashboardTtServiceId: 'spc.teacket.dashboardTimeTracking.serviceId',
  layoutFsiReport: 'spc.layout.fsiReport',
  layoutFsiReportHidden: 'spc.layout.fsiReportHidden',
  layoutFsiProject: 'spc.layout.fsiReportProject',
  layoutFsiProjectHidden: 'spc.layout.fsiReportProjectHidden',
  layoutFsiQuestions: 'spc.layout.fsiReportQuestions',
  layoutFsiQuestionsHidden: 'spc.layout.fsiQuestionsHidden',
  recentGcRegions: 'spc.signin.recentGcRegions',
  selectedGcRegion: 'spc.signin.gcRegion',
  showWelcome: 'spc.signin.showWelcome',
};
export const IDENTITY_PROVIDERS = {
  awsCognito: 'cognito',
  genesysCloud: 'gc',
};
export const LOGOUT_REASONS = {
  userLoggedOut: '1',
  tokenExpired: '2',
};

export let ENV = null;

export const determineEnv = () => {
  // <localhost>
  if (window.location.hostname === 'localhost') {
    ENV = {
      name: 'localhost',
      showCognitoLogin: true,
      showVideoBtn: true,
      cognitoClientId: '5ttfj6im96f81orjanljgd6qcd',
      cognitoDomain: 'auth.dev.spc.genesys.com',
      apiUrl: 'http://localhost:4000',
      webSocketsUrl: 'wss://x66oc9ydkg.execute-api.eu-central-1.amazonaws.com/dev',
      //streamingResponseUrl: 'https://dev-spc-sqa-gr-lb-1037897956.eu-central-1.elb.amazonaws.com/response',
      streamingResponseUrl: 'http://127.0.0.1/response',
      simpleResponseUrl: 'http://127.0.0.1/simple',
      chatWidgetStreamingResponseUrl: 'http://127.0.0.1/chat-widget/response',
      //streamingResponseUrl: 'https://resplb.dev.spc.genesys.com/response',
      //webSocketsUrl: 'ws://localhost:3002',
    };
  }
  // </localhost>

  // <aws dev>
  if (window.location.hostname === 'd3i1f60x4es9y.cloudfront.net' || window.location.hostname === 'dev.spc.genesys.com') {
    ENV = {
      name: 'awsdev',
      showCognitoLogin: true,
      showVideoBtn: true,
      cognitoClientId: '5ttfj6im96f81orjanljgd6qcd',
      cognitoDomain: 'auth.dev.spc.genesys.com',
      apiUrl: 'https://api.dev.spc.genesys.com',
      webSocketsUrl: 'wss://x66oc9ydkg.execute-api.eu-central-1.amazonaws.com/dev',
      //streamingResponseUrl: 'http://dev-spc-sqa-gr-lb-1037897956.eu-central-1.elb.amazonaws.com/response',
      streamingResponseUrl: 'https://resplb.dev.spc.genesys.com/response',
      simpleResponseUrl: 'https://resplb.dev.spc.genesys.com/simple',
      chatWidgetStreamingResponseUrl: 'https://resplb.dev.spc.genesys.com/chat-widget/response',
    };
  }
  // </aws dev>

  // <aws prod>
  if (window.location.hostname === 'd2uk19hd8g5wtc.cloudfront.net' || window.location.hostname === 'spc.genesys.com') {
    ENV = {
      name: 'awsprod',
      showCognitoLogin: false,
      showVideoBtn: true,
      cognitoClientId: '62907a16vgsoh5c23tkkkc6or7',
      cognitoDomain: 'auth.spc.genesys.com',
      apiUrl: 'https://api.spc.genesys.com',
      webSocketsUrl: 'wss://ubz5p7phrh.execute-api.eu-central-1.amazonaws.com/prod',
      streamingResponseUrl: 'https://resplb.spc.genesys.com/response',
      simpleResponseUrl: 'https://resplb.spc.genesys.com/simple',
      chatWidgetStreamingResponseUrl: 'https://resplb.spc.genesys.com/chat-widget/response',
    };
  }
  // </aws prod>
};

export const gcRegionList = [
  {
    region: 'us-east-1',
    displayName: 'Americas (US East)',
    alternateName: 'Americas (US East)',
    baseUrl: 'mypurecloud.com',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionAmericasUSEast',
  },
  {
    region: 'us-east-2',
    displayName: 'Americas (US East 2)',
    alternateName: 'FedRamp (US East 2)',
    baseUrl: 'use2.us-gov-pure.cloud',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionAmericasUSEast2',
  },
  {
    region: 'us-west-2',
    displayName: 'Americas (US West)',
    alternateName: 'Americas (US West)',
    baseUrl: 'usw2.pure.cloud',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionAmericasUSWest',
  },
  {
    region: 'ca-central-1',
    displayName: 'Americas (Canada)',
    alternateName: 'Americas (Canada)',
    baseUrl: 'cac1.pure.cloud',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionAmericasCanada',
  },
  {
    region: 'sa-east-1',
    displayName: 'Americas (São Paulo)',
    alternateName: 'Americas (São Paulo)',
    baseUrl: 'sae1.pure.cloud',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionAmericasSae1',
  },
  {
    region: 'ap-south-1',
    displayName: 'Asia Pacific (Mumbai)',
    alternateName: 'Asia Pacific (Mumbai)',
    baseUrl: 'aps1.pure.cloud',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionAsiaPacificMumbai',
  },
  {
    region: 'ap-northeast-3',
    displayName: 'Asia Pacific (Osaka)',
    alternateName: 'Asia Pacific (Osaks)',
    baseUrl: 'apne3.pure.cloud',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionAsiaPacificOsaka',
  },
  {
    region: 'ap-northeast-2',
    displayName: 'Asia Pacific (Seoul)',
    alternateName: 'Asia Pacific (Seoul)',
    baseUrl: 'apne2.pure.cloud',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionAsiaPacificSeoul',
  },
  {
    region: 'ap-southeast-2',
    displayName: 'Asia Pacific (Sydney)',
    alternateName: 'Asia Pacific (Sydney)',
    baseUrl: 'mypurecloud.com.au',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionAsiaPacificSydney',
  },
  {
    region: 'ap-northeast-1',
    displayName: 'Asia Pacific (Tokyo)',
    alternateName: 'Asia Pacific (Tokyo)',
    baseUrl: 'mypurecloud.jp',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionAsiaPacificTokyo',
  },
  {
    region: 'eu-central-1',
    displayName: 'Europe (Frankfurt)',
    alternateName: 'EMEA (Frankfurt)',
    baseUrl: 'mypurecloud.de',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionEuropeFrankfurt',
  },
  {
    region: 'eu-west-1',
    displayName: 'Europe (Ireland)',
    alternateName: 'EMEA (Ireland)',
    baseUrl: 'mypurecloud.ie',
    //clientId: window.location.hostname === 'localhost' ? '0b3d905a-75c8-424e-adc2-bddf685d6d00' : '07e01e9c-dd79-4f6d-a493-13043f68ed73', // TEMP FOR TEST
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionEuropeIreland',
  },
  {
    region: 'eu-west-2',
    displayName: 'Europe (London)',
    alternateName: 'EMEA (London)',
    baseUrl: 'euw2.pure.cloud',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionEuropeLondon',
  },
  {
    region: 'eu-central-2',
    displayName: 'Europe (Zurich)',
    alternateName: 'EMEA (Zurich)',
    baseUrl: 'euc2.pure.cloud',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionEuropeZurich',
  },
  {
    region: 'me-central-1',
    displayName: 'Middle East (UAE)',
    alternateName: 'Middle East (UAE)',
    baseUrl: 'mec1.pure.cloud',
    clientId: '07e01e9c-dd79-4f6d-a493-13043f68ed73',
    id: 'signin-home-dropdown-regionMiddleEastUAE',
  },
];
export const MASTER_ADMIN_PERMISSION = 'admin.master';
export const TENANT_ADMIN_PERMISSION = 'admin.tenant';

export const MOBILE_TRESHOLD = 1023;
export const DESKTOP_TRESHOLD = 1024;
export const SCREEN_MODES = {
  mobile: 'mobile',
  desktop: 'desktop',
};

export const DEFAULT_ROLE = 'Default Role';
