import React, { useEffect, useState } from 'react';
import { Modal, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';

const MNDAPopup = ({ open }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  useEffect(() => {
    const acknowledged = localStorage.getItem(LOCAL_STORAGE_KEYS.appsCopilotAcknowledgeMNDAPopup);
    console.log('acknowledged', acknowledged);
    setModalOpen(acknowledged !== 'true' && open);
  }, [open]);

  const handleOk = (e) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.appsCopilotAcknowledgeMNDAPopup, 'true');
    setModalOpen(false);
  };

  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
  };

  return (
    <Modal
      title='MNDA'
      open={modalOpen}
      onOk={handleOk}
      cancelButtonProps={{
        style: { display: 'none' },
      }}
      okButtonProps={{
        disabled: !checkboxChecked,
      }}>
      <p>Some of the content requires an MNDA (Mutual Non-Disclosure Agreement). If you share this with an external party, check in Salesforce if the opportunity has signed an MNDA by checking the "MNDA Executed?" field in RFP Support Requests. If not present, check with a SPC security architect.</p>
      <Checkbox onChange={handleCheckboxChange}>I acknowledge that the content requires a MNDA if I share this content outside of Genesys</Checkbox>
    </Modal>
  );
};

MNDAPopup.propTypes = {
  open: PropTypes.bool,
};

export default MNDAPopup;
