import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';
// import { objToQueryStringParams } from 'src/misc/Misc';

//#region "VIDEO"

export const getMediaVideoList = async () =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/media/videos`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const getMediaVideo = async (videoId) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/media/videos/${videoId}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const getMediaPublicVideo = async (videoId) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/media/videos/public/${videoId}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

//#endregion
