import React, { useEffect, useState, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import useTheme from 'src/hooks/useTheme';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import LoaderServicesForTenant from '../Loaders/LoaderServicesForTenant';
import { message, Space, Table, Tag, Button, Tooltip } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import { pageList } from '../Misc/misc';
import PageSwitch from 'src/components/layout/PageSwitch';
import TicketListControl from '../TicketList/components/TicketListControl';
import { getTeamDashboardData } from 'src/api/teacket';
import DashboardFilters from '../Dashboard/components/DashboardFilters';
import dayjs from 'dayjs';
import { getBrowserTimeZone } from 'src/misc/Timezone';
import styled from 'styled-components';
import useExport from 'src/hooks/useExport';
import { FaDownload } from 'react-icons/fa';
import { MdOutlineEmail, MdOutlineGroups } from 'react-icons/md';
import { IoIosCloudOutline } from 'react-icons/io';
import { TbWorldCheck } from 'react-icons/tb';
import BarLoader from 'react-bar-loader';

const StyledDiv = styled.div`
  .ant-table {
    margin-block: 0 !important;
    margin-inline: 0 !important;
  }
`;

const TeamDashboard = () => {
  const { theme } = useTheme();
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { exportToCSV } = useExport();
  const { executeAsyncProcess } = useAsyncProcesses();

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initiated, setInitiated] = useState(false);

  const { dashboardListServices, /* ticketListCurrentPage, ticketListPriorities, ticketListStatuses, ticketListTypes, pageSize, ticketListQuery, */ dashboardStartDate, setDashboardStartDate, dashboardEndDate, setDashboardEndDate } = useContext(TeacketContext);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      {
        title: 'Dashboards',
      },
      {
        title: 'Team Dashboard',
      },
    ]);

    setDefaultRange();

    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    if (initiated) return;
    if (!dashboardStartDate) return;
    if (!dashboardEndDate) return;

    setInitiated(true);

    executeAsyncProcess(async () => {
      loadData();
    });
  }, [dashboardStartDate, dashboardEndDate]);

  const setDefaultRange = () => {
    const timezone = getBrowserTimeZone();
    const start = dayjs().tz(timezone).subtract(7, 'days').startOf('day');
    const end = dayjs().tz(timezone).subtract(1, 'days').endOf('day');
    setDashboardStartDate(start);
    setDashboardEndDate(end);
  };

  const loadData = async () => {
    try {
      setLoading(true);
      setData([]);

      console.log('Loading Team Dashboard data:', {
        from: dayjs(dashboardStartDate).format(),
        to: dayjs(dashboardEndDate).format(),
        dashboardListServices,
      });
      const currentData = await getTeamDashboardData(dayjs(dashboardStartDate).format(), dayjs(dashboardEndDate).format(), dashboardListServices);

      // Add unique keys if they don't exist
      const dataWithKeys = currentData.map((item, index) => ({
        ...item,
        key: `${item.member}-${index}`, // Create a unique key
      }));

      console.log('Team Member data:', dataWithKeys);
      setData(dataWithKeys);
    } catch (error) {
      console.error(error);
      message.error('Failed to load data');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: 'Member',
      dataIndex: 'member',
      key: 'member',
      fixed: 'center',
      sorter: (a, b) => (a.member || '').localeCompare(b.member || ''),
      sortOrder: sortedInfo.columnKey === 'member' && sortedInfo.order,
      render: (text, record) => (
        <Tooltip
          title={
            <>
              <IoIosCloudOutline /> {record.tenant_name}
              <br />
              <MdOutlineEmail /> {record.email}
              <br />
              {record.department && (
                <>
                  <MdOutlineGroups /> {record.department}
                  <br />
                </>
              )}
              {record.country && (
                <>
                  <TbWorldCheck /> {record.country}
                  <br />
                </>
              )}
            </>
          }
          arrow>
          <Tag className='px-3 py-1.5 rounded-full font-medium border-0 bg-genesys-azure-100 text-genesys-azure-800 dark:bg-genesys-azure-900 dark:text-genesys-azure-100'>{text}</Tag>
        </Tooltip>
      ),
    },
    {
      title: 'Program Manager',
      children: [
        {
          title: 'Total',
          children: [
            {
              title: '#',
              dataIndex: 'pm',
              key: 'pm',
              sorter: (a, b) => (a.pm || 0) - (b.pm || 0),
              sortOrder: sortedInfo.columnKey === 'pm' && sortedInfo.order,
            },
            {
              title: 'ACV',
              dataIndex: 'pmacv',
              key: 'pmacv',
              sorter: (a, b) => (a.pmacv || 0) - (b.pmacv || 0),
              sortOrder: sortedInfo.columnKey === 'pmacv' && sortedInfo.order,
              render: (value) => (value ? `$${Math.round(value).toLocaleString()}` : '-'),
            },
          ],
        },
        {
          title: 'Open',
          children: [
            {
              title: '#',
              dataIndex: 'pmopen',
              key: 'pmopen',
              sorter: (a, b) => (a.pmopen || 0) - (b.pmopen || 0),
              sortOrder: sortedInfo.columnKey === 'pmopen' && sortedInfo.order,
            },
            {
              title: 'ACV',
              dataIndex: 'pmopenacv',
              key: 'pmopenacv',
              sorter: (a, b) => (a.pmopenAcv || 0) - (b.pmopenAcv || 0),
              sortOrder: sortedInfo.columnKey === 'pmopenacv' && sortedInfo.order,
              render: (value) => (value ? `$${Math.round(value).toLocaleString()}` : '-'),
            },
          ],
        },
      ],
    },
    {
      title: 'Team Member',
      children: [
        {
          title: 'Total',
          children: [
            {
              title: '#',
              dataIndex: 'tm',
              key: 'tm',
              sorter: (a, b) => (a.tm || 0) - (b.tm || 0),
              sortOrder: sortedInfo.columnKey === 'tm' && sortedInfo.order,
            },
            {
              title: 'ACV',
              dataIndex: 'tmacv',
              key: 'tmacv',
              sorter: (a, b) => (a.tmacv || 0) - (b.tmacv || 0),
              sortOrder: sortedInfo.columnKey === 'tmacv' && sortedInfo.order,
              render: (value) => (value ? `$${Math.round(value).toLocaleString()}` : '-'),
            },
          ],
        },
        {
          title: 'Open',
          children: [
            {
              title: '#',
              dataIndex: 'tmopen',
              key: 'tmopen',
              sorter: (a, b) => (a.tmOpen || 0) - (b.tmOpen || 0),
              sortOrder: sortedInfo.columnKey === 'tmopen' && sortedInfo.order,
            },
            {
              title: 'ACV',
              dataIndex: 'tmopenacv',
              key: 'tmopenacv',
              sorter: (a, b) => (a.tmopenacv || 0) - (b.tmopenacv || 0),
              sortOrder: sortedInfo.columnKey === 'tmopenacv' && sortedInfo.order,
              render: (value) => (value ? `$${Math.round(value).toLocaleString()}` : '-'),
            },
          ],
        },
      ],
    },
    {
      title: 'Primary Team Member',
      children: [
        {
          title: 'Total',
          children: [
            {
              title: '#',
              dataIndex: 'ptm',
              key: 'ptm',
              sorter: (a, b) => (a.ptm || 0) - (b.ptm || 0),
              sortOrder: sortedInfo.columnKey === 'ptm' && sortedInfo.order,
            },
            {
              title: 'ACV',
              dataIndex: 'ptmacv',
              key: 'ptmacv',
              sorter: (a, b) => (a.ptmacv || 0) - (b.ptmacv || 0),
              sortOrder: sortedInfo.columnKey === 'ptmacv' && sortedInfo.order,
              render: (value) => (value ? `$${Math.round(value).toLocaleString()}` : '-'),
            },
          ],
        },
        {
          title: 'Open',
          children: [
            {
              title: '#',
              dataIndex: 'ptmopen',
              key: 'ptmopen',
              sorter: (a, b) => (a.ptmopen || 0) - (b.ptmopen || 0),
              sortOrder: sortedInfo.columnKey === 'ptmopen' && sortedInfo.order,
            },
            {
              title: 'ACV',
              dataIndex: 'ptmopenacv',
              key: 'ptmopenacv',
              sorter: (a, b) => (a.ptmopenacv || 0) - (b.ptmopenacv || 0),
              sortOrder: sortedInfo.columnKey === 'ptmopenacv' && sortedInfo.order,
              render: (value) => (value ? `$${Math.round(value).toLocaleString()}` : '-'),
            },
          ],
        },
      ],
    },
    {
      title: 'Subject Matter Expert',
      children: [
        {
          title: 'Total',
          children: [
            {
              title: '#',
              dataIndex: 'sme',
              key: 'sme',
              sorter: (a, b) => (a.sme || 0) - (b.sme || 0),
              sortOrder: sortedInfo.columnKey === 'sme' && sortedInfo.order,
            },
            {
              title: 'ACV',
              dataIndex: 'smeacv',
              key: 'smeacv',
              sorter: (a, b) => (a.smeacv || 0) - (b.smeacv || 0),
              sortOrder: sortedInfo.columnKey === 'smeacv' && sortedInfo.order,
              render: (value) => (value ? `$${Math.round(value).toLocaleString()}` : '-'),
            },
          ],
        },
        {
          title: 'Open',
          children: [
            {
              title: '#',
              dataIndex: 'smeopen',
              key: 'smeopen',
              sorter: (a, b) => (a.smeopen || 0) - (b.smeopen || 0),
              sortOrder: sortedInfo.columnKey === 'smeopen' && sortedInfo.order,
            },
            {
              title: 'ACV',
              dataIndex: 'smeopenacv',
              key: 'smeopenacv',
              sorter: (a, b) => (a.smeopenacv || 0) - (b.smeopenacv || 0),
              sortOrder: sortedInfo.columnKey === 'smeopenacv' && sortedInfo.order,
              render: (value) => (value ? `$${Math.round(value).toLocaleString()}` : '-'),
            },
          ],
        },
      ],
    },
  ];

  const expandableConfig = {
    expandedRowClassName: 'expanded-row',
    expandRowByClick: true,
    expandedRowKeys: expandedKeys,
    expandedRowRender: (record) => {
      if (!record.member || record.member === 'N/A') return null;

      return <TicketListControl userId={record.user_id} showRequesterAndUserTickets={false} />;
    },
    onExpand: (expanded, record) => {
      setExpandedKeys((prevKeys) => {
        if (expanded) {
          return [...prevKeys, record.key];
        }
        return prevKeys.filter((key) => key !== record.key);
      });
    },
  };

  return (
    <StyledDiv>
      <PermissionsProvider requiredPermissions={['apps.dashboards.greenteacket.teamdashboard.view']}>
        <LoaderServicesForTenant>
          <TitleBar
            title={
              <Space className='btn'>
                <BsCupHotFill />
                <div>
                  <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
                </div>
              </Space>
            }
            afterTitleExtras={
              <div className='flex flex-row items-center gap-4'>
                <div>
                  <PageSwitch pageList={pageList} />
                </div>
                <div>
                  <Button
                    onClick={() => {
                      exportToCSV({ columns, data, fileName: 'GreenTeacketTeamDashboard.csv', combineHeaderColumnNames: true });
                    }}>
                    <FaDownload />
                  </Button>
                </div>
              </div>
            }
          />
          <div className='p-6 flex flex-col gap-4'>
            <div className='p-4 rounded-lg bg-blue-300/50 dark:bg-sky-700/50'>
              <DashboardFilters onSubmit={loadData} />
            </div>
            {loading ? <BarLoader color={theme.primary} backgroundColor={theme.light} height='2' width='100%' /> : <div style={{ height: '1px', marginTop: '1px', backgroundColor: theme.primary }}></div>}
            <Table bordered columns={columns} dataSource={data} onChange={handleChange} pagination={false} size='middle' expandable={expandableConfig} scroll={{ x: 'max-content' }} className='shadow-sm resizable-table rounded-lg overflow-hidden' />
          </div>
        </LoaderServicesForTenant>
      </PermissionsProvider>
    </StyledDiv>
  );
};

export default TeamDashboard;
