import React from 'react';
import PropTypes from 'prop-types';
import StyledScrollbars from './StyledScrollbars';
import useTheme from 'src/hooks/useTheme';
import useScreen from 'src/hooks/useScreen';

const PageLayoutType1 = ({ left, right, leftWidth = '400px', height = 'calc(100vh - 116px)' }) => {
  const { theme } = useTheme();
  const { isDesktop } = useScreen();
  return (
    <StyledScrollbars color={theme.borderLight} bgColor={'transparent'} className={'p-2 w-full lg:overflow-hidden'} style={{ height: isDesktop ? height : null }}>
      <div className='flex flex-col lg:flex-row gap-4 h-full'>
        <div className={'flex-initial  overflow-auto pb-6 lg:pb-0'} style={{ width: isDesktop ? leftWidth : null, minWidth: isDesktop ? leftWidth : null, maxWidth: isDesktop ? leftWidth : null }}>
          {left}
        </div>
        <div className='flex-auto overflow-auto pb-6 lg:pb-0'>{right}</div>
      </div>
    </StyledScrollbars>
  );
};

PageLayoutType1.propTypes = {
  leftWidth: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
  height: PropTypes.string,
};

export default PageLayoutType1;
