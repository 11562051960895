import React, { Component } from 'react';
import AuditViewerContext from './AuditViewerContext';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';

//import { getMyNotifications } from 'src/api/notifications';

export default class AuditViewerProvider extends Component {
  state = {
    ebIntegrationInstalled: null,
    serviceName: null,
    entityType: '<ALL>',
    action: '<ALL>',
    topic: null,
    subscriptions: null,
    hasCurrentUserSubscribed: null,
    currentUserFilterConfig: null,
    snsSubscriptionsAddNew: false,
    snsSubscriptionsReload: false,
    // EVENT FILTER PRESETS:
    eventsFilterPresetList: null,
  };

  render() {
    return (
      <AuditViewerContext.Provider
        value={{
          ...this.state,
          setEbIntegrationInstalled: (ebIntegrationInstalled) => this.setState({ ebIntegrationInstalled }),
          setServiceName: (serviceName) => this.setState({ serviceName }),
          setEntityType: (entityType) => this.setState({ entityType }),
          setAction: (action) => this.setState({ action }),
          setFilterConfig: (currentUserFilterConfig) => this.setState({ currentUserFilterConfig }),
          setTopic: (topic) => this.setState({ topic }),
          setSubscriptions: (subscriptions) => this.setState({ subscriptions }),
          setHasCurrentUserSubscribed: (hasCurrentUserSubscribed) => this.setState({ hasCurrentUserSubscribed }),
          addManyCurrentUserFilterList: (filterItems) => {
            if (true === notArrayOrEmpty(filterItems)) return;
            let currentUserFilterConfig = true === notEmptyArray(this.state.currentUserFilterConfig) ? [...this.state.currentUserFilterConfig] : [];
            this.setState({ currentUserFilterConfig: currentUserFilterConfig.concat(filterItems) });
          },
          addCurrentUserFilterList: (filterItem) => {
            const currentUserFilterConfig = true === notEmptyArray(this.state.currentUserFilterConfig) ? [...this.state.currentUserFilterConfig] : [];
            currentUserFilterConfig.push(filterItem);
            this.setState({ currentUserFilterConfig });
          },
          removeCurrentUserFilterList: (filterItem) => {
            const currentUserFilterConfig = true === notEmptyArray(this.state.currentUserFilterConfig) ? [...this.state.currentUserFilterConfig] : [];
            const index = currentUserFilterConfig.findIndex((x) => x.serviceName === filterItem.serviceName && x.entityType === filterItem.entityType && x.action === filterItem.action);
            if (index > -1) {
              currentUserFilterConfig.splice(index, 1);
            }
            this.setState({ currentUserFilterConfig });
          },
          subCurrentUserFilterList: (filterItem, prop = 'subscribed') => {
            const currentUserFilterConfig = true === notEmptyArray(this.state.currentUserFilterConfig) ? [...this.state.currentUserFilterConfig] : [];
            let item = currentUserFilterConfig.find((x) => x.serviceName === filterItem.serviceName && x.entityType === filterItem.entityType && x.action === filterItem.action);
            item[prop] = true;
            this.setState({ currentUserFilterConfig });
          },
          unsubCurrentUserFilterList: (filterItem, prop = 'subscribed') => {
            const currentUserFilterConfig = true === notEmptyArray(this.state.currentUserFilterConfig) ? [...this.state.currentUserFilterConfig] : [];
            let item = currentUserFilterConfig.find((x) => x.serviceName === filterItem.serviceName && x.entityType === filterItem.entityType && x.action === filterItem.action);
            item[prop] = undefined;
            this.setState({ currentUserFilterConfig });
          },
          setSnsSubscriptionsAddNew: (snsSubscriptionsAddNew) => this.setState({ snsSubscriptionsAddNew }),
          setSnsSubscriptionsReload: (snsSubscriptionsReload) => this.setState({ snsSubscriptionsReload }),

          // EVENT FILTER PRESETS:
          setEventsFilterPresetList: (eventsFilterPresetList) => this.setState({ eventsFilterPresetList }),
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </AuditViewerContext.Provider>
    );
  }
}
