import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';

const AiModelBadge = ({ id, name, description, onClick, isSelected, isDisabled, newCreated }) => {
  const disabledRibbon = (control, isDisabled) => {
    if (isDisabled)
      return (
        <Badge.Ribbon text='Disabled' color='red'>
          {control}
        </Badge.Ribbon>
      );
    if (newCreated)
      return (
        <Badge.Ribbon text='Just created' color='blue'>
          {control}
        </Badge.Ribbon>
      );
    return control;
  };

  return (
    <div
      key={id}
      className={'hover:scale-105 hover:opacity-100 hover:shadow-md transition-all'}
      onClick={() => {
        if (onClick) onClick(id);
      }}
    >
      {disabledRibbon(
        <>
          <div className={`text-white p-2 ${id === 'default' ? 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500' : 'bg-purple-900'} flex flex-col gap-1 justify-start  items-center cursor-pointer opacity-100 min-h-28  w-28 h-48 py-2 dark:shadow-indigo-800 ${isSelected ? 'border-2 border-gray-400 dark:border-indigo-600 ' : 'border border-gray-300 dark:border-indigo-900 '} border-solid rounded-md`}>
            <div className='pt-2 font-semibold text-center'>{name}</div>

            <div className='pt-2 text-xs text-left italic'>
              <p className='overflow-hidden max-h-24 w-16'>{description}</p>
            </div>
          </div>
        </>,
        isDisabled
      )}
    </div>
  );
};
/*
<div className='relative overflow-hidden text-5xl rounded-md w-full p-2 text-center h-20' style={{ backgroundColor: false === isDarkMode ? imageBgColor : imageBgColorDark, color: theme.backgroundBase }}>
<div className='absolute z-10 roubded-md top-0 right-0 bottom-0 left-0' style={{ background: 'linear-gradient( #ffffff55, #00000033)' }}></div>
<div className='absolute z-20 rounded-md top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center'>{icon}</div>
</div>
<div className='mt-2 overflow-hidden whitespace-nowrap text-xs font-bold text-center'>{title}</div>
*/

AiModelBadge.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  picture: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  newCreated: PropTypes.bool,
};

export default AiModelBadge;
