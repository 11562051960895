import React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import useTheme from 'src/hooks/useTheme';

const AWSFrame = () => {
  const { isDarkMode } = useTheme();

  const themeMode = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={themeMode}>
      <div className='max-w-xl'>
        <iframe style={{ border: 'none', overflow: 'hidden' }} title='AWS Dashboard' src='https://cloudwatch.amazonaws.com/dashboard.html?dashboard=AI-Stats&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTExMjYxNzg3MDg5OSIsIlUiOiJ1cy1lYXN0LTFfYUlxaUltRVA2IiwiQyI6Ijd2aTl2M3U2dGVrN3VpZnA5cWl0Nm84ZWRxIiwiSSI6InVzLWVhc3QtMTo1YzViMmVkMC01NWNkLTQxYzktOWUxYS00NDMxNDliOWY0MGQiLCJNIjoiUHVibGljIn0=' width='1920' height='800' />
      </div>
    </ThemeProvider>
  );
};

export default AWSFrame;
