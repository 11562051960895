import React, { useState, useContext, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
//import useUserInfo from 'src/hooks/useUserInfo';
//import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { patchTicketProperties } from 'src/api/teacket';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';
import { Select, Tag, message, InputNumber, Button, Typography, Space, Tooltip, Spin } from 'antd';
import { uuid } from 'short-uuid';
import { WarningFilled } from '@ant-design/icons';
import { getTicketReloadOpp } from 'src/api/teacket';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

//#region Constants

export const regionList = {
  APAC: ['ANZ - GTM 1', 'ANZ - GTM 2', 'ANZ - Others', 'Greater China - GTM 1', 'Greater China - GTM 2', 'Greater China - Others', 'India - GTM 1', 'India - Others', 'Japan - GTM 1', 'Japan - GTM 2', 'Japan - Others', 'South East Asia - GTM 1', 'South East Asia - GTM 2', 'South East Asia - Others', 'South Korea - GTM 1', 'South Korea - Others'],
  DX: ['EMEA Europe', 'EMEA UK&I', 'NA Central', 'NA Inside East', 'NA Inside West', 'NA Mid-Atlantic', 'NA Northeast', 'NA Southeast', 'NA West'],
  EMEA: ['ALPS Enterprise & DACH Commercial', 'Benelux, Eastern Europe & Israel, Russia & Poland', 'Mid-Market - Tier 4 Central DACH & France', 'Mid-Market - Tier 4 North', 'Mid-Market - Tier 4 South Spain, Italy & Middle East', 'France & Africa', 'Germany Enterprise', 'Iberia', 'Italy, Middle East & Turkey', 'Nordics Enterprise & Commercial', 'UKI - Finance & Technology', 'UKI - Retail, Industrial & Ireland', 'UKI - Public Sector'],
  LATAM: ['Andean', 'Brazil Commercial', 'Brazil Enterprise', 'Brazil Large Accounts', 'DX Cloud', 'Mexico', 'Southern Cone'],
  MIGRATIONS: ['EMEA', 'NA East', 'NA West'],
  NA: ['Banking', 'Canada Commercial', 'Canada Enterprise', 'Canada Territory', 'Geo Commercial', 'Geo Enterprise', 'Geo Territory', 'Government Education', 'Government Federal', 'Government Local', 'Government State East', 'Government State West', 'Healthcare', 'Insurance', 'Retail', 'Tech'],
  SERVICEPRO: ['EMEA', 'NA'],
};

export const relationshipList = [
  { value: 'Direct', label: 'Direct' },
  { value: 'Indirect', label: 'Indirect (via partner)' },
];

export const customerTypeList = [
  { value: 'ExistingCustomer', label: 'Existing Customer' },
  { value: 'NewLogo', label: 'New Logo' },
];

export const marketSegmentList = [
  { value: 'Mid-market', label: 'Mid-market' },
  { value: 'Commercial', label: 'Commercial' },
  { value: 'Enterprise', label: 'Enterprise' },
  { value: 'Public Sector', label: 'Public Sector' },
];

export const productList = [
  // ACTIVE items:
  { value: 'Digital/AI/DX', label: 'Digital/AI/DX', active: true },
  { value: 'Engage/MultiCloud', label: 'Engage/MultiCloud', active: true },
  { value: 'Genesys Cloud', label: 'Genesys Cloud', active: true },
  { value: 'Latitude by Genesys', label: 'Latitude by Genesys', active: true },
  { value: 'Pointillist', label: 'Pointillist', active: true },
  { value: 'PureConnect', label: 'PureConnect', active: true },
];

//#endregion

const formatCurrency = (value, currency) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};

function truncateString(input) {
  // Use regex to match and remove any initial number followed by a dot
  return input.replace(/^\d+\.\s*/, '');
}

const getRegionItems = () => {
  return Object.keys(regionList);
};

const getTerritoryItems = (region) => {
  if (!region) return [];
  return regionList[region] || [];
};

const OpportunityDetails = () => {
  const { currentTicket, currentTicketOppDetails, updateCurrentTickeOppDetailst, currentTicketMembers, determineIsWidgetReadOnly } = useContext(TeacketContext);
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const [numberOfSeats, setNumberOfSeats] = useState(0);
  const [numberOfSeatsChanged, setNumberOfSeatsChanged] = useState(false);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (currentTicket?.opp_id) reloadOppDetails();
  }, []);

  const reloadOppDetails = async () => {
    console.log('reloadOppDetails()');

    try {
      setBusy(true);
      const newDetails = await getTicketReloadOpp(currentTicket.id);
      console.log('newDetails:', newDetails);
      if (newDetails && Object.keys(newDetails).length > 0) {
        updateCurrentTickeOppDetailst(newDetails);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Opportunity details updated.',
          duration: 3,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBusy(false);
    }
  };

  const updateTicketProperties = async (props) => {
    console.log('updateTicketProperties', props);

    if (currentTicket) {
      await executeAsyncProcess(async () => {
        const key = uuid();
        try {
          messageApi.open({
            key,
            type: 'loading',
            content: 'Updating Opportunity details...',
            duration: 3,
          });

          await patchTicketProperties(currentTicket.id, props);
        } catch {
          console.error('updateTicketProperties error');
          messageApi.open({
            key,
            type: 'error',
            content: 'Failed to update ticket properties.',
            duration: 3,
          });
        } finally {
          messageApi.open({
            key,
            type: 'success',
            content: 'Ticket updated.',
            duration: 3,
          });
        }
      });
    }
    updateCurrentTickeOppDetailst(props);
  };

  const handleRegionChanged = (v) => {
    console.log('handleRegionChanged', v);
    updateTicketProperties({ OPPORTUNITY_COUNTRY_REGION: v, OPPORTUNITY_COUNTRY_SUB_REGION: null });
  };

  const handleSubRegionChanged = (v) => {
    console.log('handleSubRegionChanged', v);
    updateTicketProperties({ OPPORTUNITY_COUNTRY_SUB_REGION: v });
  };

  const handleCustomerRelationshipChanged = (v) => {
    console.log('handleCustomerRelationshipChanged', v);
    updateTicketProperties({ OPPORTUNITY_DIRECT_INDIRECT_SALE: v });
  };

  const handleDealTypeChanged = (v) => {
    console.log('handleDealTypeChanged', v);
    updateTicketProperties({ OPPORTUNITY_DEAL_TYPE: v });
  };

  const handleMarketSegmentChanged = (v) => {
    console.log('handleMarketSegmentChanged', v);
    updateTicketProperties({ OPPORTUNITY_SALES_SEGMENT: v });
  };

  const handleProductChanged = (v) => {
    console.log('handleProductChanged', v);
    updateTicketProperties({ OPPORTUNITY_PRODUCT: v });
  };

  const handleNumberOfSeatsChanged = (v) => {
    setNumberOfSeats(v);
    updateCurrentTickeOppDetailst({ OPPORTUNITY_SEATS: v });
    setNumberOfSeatsChanged(true);
  };

  const handleSaveSeatsClick = () => {
    console.log('handleSaveClick', numberOfSeats);
    updateTicketProperties({ OPPORTUNITY_SEATS: numberOfSeats });
    setNumberOfSeatsChanged(false);
  };

  // <TODO: move this handler to "more global" place - maybe to LoaderCurrentTicket or create a dedicated component? - this component is responsible for displaying part of the ticket data only>
  const handleOnWsMessageReceived = async (message) => {
    try {
      console.log('[TicketProperties] TICKET_UPDATED_PROPS:', message);
      if (!message?.data?.details) return;

      updateCurrentTickeOppDetailst(message.data.details);

      messageApi.open({
        key: uuid(),
        type: 'success',
        content: 'Opportunity Details Updated.',
        duration: 3,
      });
    } catch (error) {
      console.error(error);
    }
  };
  // </TODO: move this handler to "more global" place - maybe to LoaderCurrentTicket or create a dedicated component?>

  const areAllPropertiesNull = (obj) => obj && Object.values(obj).every((value) => value === null || value === undefined);

  const assignedSCLabel = () => {
    let bWarning = false;
    const currentAssignedSC = currentTicketMembers?.find((x) => x.role === 'Assigned SC')?.user_name;

    if (currentAssignedSC && currentAssignedSC !== currentTicketOppDetails?.OPPORTUNITY_SC_NAME) bWarning = true;

    return (
      <>
        <label className='text-xs font-semibold'>Assigned SC:</label>
        <label className='text-xs'>
          <Tooltip title={currentTicketOppDetails?.OPPORTUNITY_SC_EMAIL}>
            {currentTicketOppDetails?.OPPORTUNITY_SC_NAME || (
              <>
                <Space>
                  <div className='bg-orange-400' style={{ height: 6, width: 6, borderRadius: 6, border: '0px', marginTop: '2px' }}></div>
                  n/a
                </Space>
              </>
            )}
          </Tooltip>
          {bWarning && (
            <Tooltip title='Assigned SC do not match current ticket Members'>
              <span className='ml-2 text-orange-400'>
                <WarningFilled />
              </span>
            </Tooltip>
          )}
        </label>
      </>
    );
  };

  return (
    <>
      {contextHolder}
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['TICKET_UPDATED_PROPS']}></GlobalWsMessenger>
      {!currentTicketOppDetails || areAllPropertiesNull(currentTicketOppDetails) ? (
        <div className='text-center text-gray-500'>No opportunity selected.</div>
      ) : (
        <>
          <div className={`${busy || true === determineIsWidgetReadOnly('Opportunity Details') ? 'disabled' : ''}`}>
            <div className='text-center mb-4'>
              <Tag color='magenta'>
                ACV: {formatCurrency(currentTicketOppDetails?.OPPORTUNITY_GROSS_ACV_BOOKING || 0, currentTicketOppDetails?.OPPORTUNITY_CURRENCY_ISO_CODE || 'USD')} {currentTicketOppDetails?.OPPORTUNITY_CURRENCY_ISO_CODE === 'USD' ? '' : `(${formatCurrency(currentTicketOppDetails?.OPPORTUNITY_GROSS_ACV_BOOKING_USD?.toFixed(2) || 0, 'USD') || '$0'})`}
              </Tag>
              <div className='relative'>
                <div className={`absolute inset-0 flex justify-center items-center ${!busy ? 'z-50' : 'z-0'}`}>
                  <Spin spinning={busy} />
                </div>
                {/* Other components go here */}
              </div>

              <div className='text-xs text-gray-500 mt-1'>
                <span>Gross ACV Booking Value</span>
              </div>
            </div>
            <div className='flex flex-col gap-2 mt-2 text-gray-500'>
              <div className='flex justify-between'>
                <label className='text-xs font-semibold'>Owner:</label>
                <label className='text-xs'>
                  <Tooltip title={currentTicketOppDetails?.OPPORTUNITY_OWNER_EMAIL}>
                    {currentTicketOppDetails?.OPPORTUNITY_OWNER_NAME || (
                      <>
                        <Space>
                          <div className='bg-orange-400' style={{ height: 6, width: 6, borderRadius: 6, border: '0px', marginTop: '2px' }}></div>
                          n/a
                        </Space>
                      </>
                    )}
                  </Tooltip>
                </label>
              </div>
              <div className='flex justify-between'>{assignedSCLabel()}</div>
              <div className='flex justify-between'>
                <label className='text-xs font-semibold'>Stage Name:</label>
                <label className='text-xs'>
                  {currentTicketOppDetails?.OPPORTUNITY_STAGE_NAME || (
                    <>
                      <Space>
                        <div className='bg-orange-400' style={{ height: 6, width: 6, borderRadius: 6, border: '0px', marginTop: '2px' }}></div>
                        n/a
                      </Space>
                    </>
                  )}
                </label>
              </div>
              <div className='flex justify-between'>
                <label className='text-xs font-semibold'>Number of Agents:</label>
                <label className='text-xs'>
                  {currentTicketOppDetails?.OPPORTUNITY_AGENTS && truncateString(currentTicketOppDetails.OPPORTUNITY_AGENTS) !== 'N/A' ? (
                    truncateString(currentTicketOppDetails.OPPORTUNITY_AGENTS)
                  ) : (
                    <>
                      <Space>
                        <div className='bg-orange-400' style={{ height: 6, width: 6, borderRadius: 6, border: '0px', marginTop: '2px' }}></div>
                        n/a
                      </Space>
                    </>
                  )}
                </label>
              </div>
              <div className='flex justify-between'>
                <label className='text-xs font-semibold'>DSR URL:</label>
                <label className='text-xs'>
                  {currentTicketOppDetails?.OPPORTUNITY_DSR_URL ? (
                    <Typography.Text
                      className='text-xs text-left  text-gray-500  scale-85'
                      copyable={{
                        tooltips: [currentTicketOppDetails?.OPPORTUNITY_DSR_URL, 'Copied'],
                        text: currentTicketOppDetails?.OPPORTUNITY_DSR_URL,
                      }}
                    >
                      url
                    </Typography.Text>
                  ) : (
                    <>
                      <Space>
                        <div className='bg-orange-400' style={{ height: 6, width: 6, borderRadius: 6, border: '0px', marginTop: '2px' }}></div>
                        n/a
                      </Space>
                    </>
                  )}
                </label>
              </div>

              <hr className='text-center opacity-10 w-[90%]' />

              <label className='block text-xs -mb-1'>Region</label>
              <Select
                size='small'
                onChange={handleRegionChanged}
                options={getRegionItems().map((x) => ({
                  value: x,
                  label: <span>{x}</span>,
                }))}
                value={currentTicketOppDetails?.OPPORTUNITY_COUNTRY_REGION}
              />
              <label className='block text-xs -mb-1'>Sub-region</label>
              <Select
                size='small'
                onChange={handleSubRegionChanged}
                options={getTerritoryItems(currentTicketOppDetails?.OPPORTUNITY_COUNTRY_REGION).map((x) => ({
                  value: x,
                  label: <span>{x}</span>,
                }))}
                value={currentTicketOppDetails?.OPPORTUNITY_COUNTRY_SUB_REGION}
              />
              <label className='block text-xs -mb-1'>Relationship</label>
              <Select
                size='small'
                onChange={handleCustomerRelationshipChanged}
                options={relationshipList.map((x) => ({
                  value: x.value,
                  label: <span>{x.label}</span>,
                }))}
                value={currentTicketOppDetails?.OPPORTUNITY_DIRECT_INDIRECT_SALE}
              />
              <label className='block text-xs -mb-1'>Deal Type</label>
              <Select
                size='small'
                onChange={handleDealTypeChanged}
                options={customerTypeList.map((x) => ({
                  value: x.value,
                  label: <span>{x.label}</span>,
                }))}
                value={currentTicketOppDetails?.OPPORTUNITY_DEAL_TYPE}
              />
              <label className='block text-xs -mb-1'>Market Segement</label>
              <Select
                size='small'
                onChange={handleMarketSegmentChanged}
                options={marketSegmentList.map((x) => ({
                  value: x.value,
                  label: <span>{x.label}</span>,
                }))}
                value={currentTicketOppDetails?.OPPORTUNITY_SALES_SEGMENT}
              />
              <label className='block text-xs -mb-1'>Product</label>
              <Select
                size='small'
                onChange={handleProductChanged}
                options={productList.map((x) => ({
                  value: x.value,
                  label: <span>{x.label}</span>,
                }))}
                value={currentTicketOppDetails?.OPPORTUNITY_PRODUCT || 'Genesys Cloud'}
              />

              <label className='block text-xs -mb-1'>Number of Seats</label>
              <InputNumber
                changeOnWheel
                min={0}
                max={9999999}
                onChange={handleNumberOfSeatsChanged}
                size='small'
                value={currentTicketOppDetails?.OPPORTUNITY_SEATS || 0}
                addonAfter={
                  currentTicket && (
                    <Button disabled={!numberOfSeatsChanged} type='link' size='small' onClick={handleSaveSeatsClick} className='p-0'>
                      <span className='text-sm'>Save</span>
                    </Button>
                  )
                }
              />
            </div>
            {currentTicket?.opp_name && (
              <div className='text-center mt-4 text-gray-500 text-xs'>
                <div>
                  <Typography.Text
                    className='text-xs text-left  text-gray-500  scale-85 mr-2'
                    copyable={{
                      tooltips: [currentTicket.opp_name, 'Copied'],
                      text: currentTicket.opp_name,
                    }}
                  >
                    name
                  </Typography.Text>
                  {'/ '}
                  <Typography.Text
                    className='text-xs text-left  text-gray-500  scale-85'
                    copyable={{
                      tooltips: [`https://genesys.lightning.force.com/lightning/r/Opportunity/${currentTicket?.opp_id}/view`, 'Copied'],
                      text: `https://genesys.lightning.force.com/lightning/r/Opportunity/${currentTicket?.opp_id}/view`,
                    }}
                  >
                    url
                  </Typography.Text>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

// OpportunityDetails.propTypes = {
//   opportunity: PropTypes.object,
//   onChange: PropTypes.func,
// };

export default OpportunityDetails;
