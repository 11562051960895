import StylesWrapper from '../ExportViewer/ExportViewer.styles';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getContentTree, getContentFileUrl } from 'src/api/dataSubjectRequests';
import { Tree, Spin, Row, Col, Typography, Button, Menu, Dropdown, Space, Switch, Tooltip } from 'antd';
import JsonViewer from './JsonViewer';
import TextViewer from './TextViewer';
import { CloudDownloadOutlined, DownOutlined } from '@ant-design/icons';

const { DirectoryTree } = Tree;
const { Title } = Typography;

const ExportViewer = ({ jobId, requestId }) => {
  const DO_NOT_SHOW = 0;
  const SHOW_JSON = 1;
  const SHOW_TEXT = 3;

  const [treeData, setTreeData] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodeData, setSelectedNodeData] = useState(null);
  const [limit, setLimit] = useState('50');
  const [manySeparators, setManySeparators] = useState(true);

  useEffect(() => {
    loadData(limit, manySeparators);
  }, []);

  const loadData = async (limit1, manySeparators1) => {
    console.log('loadData()');
    try {
      setTreeData(null);
      const resp = await getContentTree(jobId, requestId, limit1, manySeparators1);
      console.log(resp);
      setTreeData(resp);
    } catch (error) {
      console.error(error);
    }
  };

  const loadItemData = async (key) => {
    console.log('loadItemData()');
    try {
      const resp = await getContentFileUrl(key);
      console.log(resp);
      setSelectedNodeData(resp);
    } catch (error) {
      console.error(error);
    }
  };

  const howToShow = () => {
    console.log('dddd', selectedNodeData);
    if (!selectedNodeData) return DO_NOT_SHOW;
    if (selectedNodeData?.extractedObject?.file?.endsWith('.json')) return SHOW_JSON;

    if (selectedNodeData?.extractedObject?.file?.includes('analytics!')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.file?.includes('contacts-service!')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.file?.includes('journey-session-store!')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.file?.includes('directory!')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.file?.includes('user!')) return SHOW_JSON;

    if (selectedNodeData?.extractedObject?.path?.array?.includes('analytics')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.path?.array?.includes('architect-data-service')) return SHOW_TEXT;
    if (selectedNodeData?.extractedObject?.path?.array?.includes('content-management')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.path?.array?.includes('directory')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.path?.array?.includes('edge-config-user')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.path?.array?.includes('gamification-service')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.path?.array?.includes('greetings')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.path?.array?.includes('messaging')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.path?.array?.includes('postino-service')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.path?.array?.includes('push')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.path?.array?.includes('REALTIME')) return SHOW_TEXT;
    if (selectedNodeData?.extractedObject?.path?.array?.includes('squonk-service')) return SHOW_JSON;
    if (selectedNodeData?.extractedObject?.path?.array?.includes('user')) return SHOW_JSON;
    return DO_NOT_SHOW;
  };

  const showFileContent = () => {
    switch (howToShow()) {
      case SHOW_JSON:
        return <JsonViewer fileUrl={selectedNodeData?.preSignedUrl} />;
      case SHOW_TEXT:
        return <TextViewer fileUrl={selectedNodeData?.preSignedUrl} />;
      default:
        // DO_NOT_SHOW is a default one
        return <Title level={5}>No content preview for this type of file</Title>;
    }
  };

  if (!treeData)
    return (
      <div className='center'>
        <Spin size='small' tip='Loading data...' />
      </div>
    );

  return (
    <StylesWrapper>
      <Row gutter={24} align='middle' style={{ minHeight: '32px' }}>
        <Col sm={24} md={24} lg={12}>
          <Row gutter={16} align='middle'>
            <Col span={8}>
              <Title level={4} style={{ marginBottom: 0 }}>
                List of files:
              </Title>
            </Col>
            <Col span={16} className='right smaller'>
              <Space size='middle' align='center'>
                <div>
                  <Tooltip title='Folders separators'>
                    <Switch
                      checked={manySeparators}
                      onChange={(v) => {
                        setManySeparators(v);
                        loadData(limit === 'Unlimited' ? '-1' : limit, v);
                      }}
                      size='small'
                      checkedChildren='/ and !'
                      unCheckedChildren='only /'
                    />
                  </Tooltip>
                </div>
                <div>
                  Max number of files per folder:
                  <Dropdown
                    placement='bottomRight'
                    overlay={
                      <Menu
                        onClick={(e) => {
                          setLimit(e.key);
                          setSelectedNode(null);
                          setSelectedNodeData(null);
                          loadData(e.key === 'Unlimited' ? '-1' : e.key, manySeparators);
                        }}
                      >
                        <Menu.Item key='50'>50</Menu.Item>
                        <Menu.Item key='200'>200</Menu.Item>
                        <Menu.Item key='Unlimited'>Unlimited</Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <a href onClick={(e) => e.preventDefault()}>
                      <Space>
                        {limit}
                        <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                </div>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col sm={24} md={24} lg={12} className='exp-vwr-panel-right'>
          <Title level={4}>
            {!selectedNode ? (
              <span className='quitelight'>No file selected</span>
            ) : selectedNodeData?.extractedObject?.file ? (
              <Row gutter={8} align='middle'>
                <Col span={18}>
                  File preview
                  <span className='btn-margin-left smaller quitelight'> | {selectedNodeData.extractedObject.file}</span>
                </Col>
                <Col span={6} className='right'>
                  <Button
                    size='small'
                    type='link'
                    icon={<CloudDownloadOutlined />}
                    onClick={() => {
                      const pom = document.createElement('a');
                      pom.href = selectedNodeData?.preSignedUrl?.file;
                      pom.download = selectedNodeData?.extractedObject?.file;
                      pom.target = '_blank';
                      pom.click();
                    }}
                  >
                    Download
                  </Button>
                </Col>
              </Row>
            ) : null}
          </Title>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col sm={24} md={24} lg={12} className='exp-vwr-panel'>
          <DirectoryTree
            treeData={treeData}
            onSelect={(keys, e) => {
              const nodes = e?.selectedNodes;
              setSelectedNodeData(null);
              if (Array.isArray(nodes) && nodes.length === 1 && nodes[0].isLeaf && nodes[0].key) {
                setSelectedNode(nodes[0]);
                loadItemData(nodes[0].key);
              } else {
                setSelectedNode(null);
              }
            }}
            titleRender={(item) => (
              <>
                {item.title}
                {!item.isLeaf && item.children?.length > 0 ? <span className='btn-margin-left smaller quitelight'>| items: {item.children.length}</span> : null}
              </>
            )}
          />
        </Col>
        <Col sm={24} md={24} lg={12} className='exp-vwr-panel exp-vwr-panel-right'>
          {selectedNode && !selectedNodeData && (
            <div className='center'>
              <Spin size='small' tip='Loading data...' />
            </div>
          )}
          {selectedNode && selectedNodeData && <div>{showFileContent()}</div>}
        </Col>
      </Row>
    </StylesWrapper>
  );
};

ExportViewer.propTypes = {
  jobId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
};

ExportViewer.defaultProps = {};

export default ExportViewer;
