import React, { useContext, useEffect, useState } from 'react';
import RiskChart from './RiskChart';
import SecurityAdvisorContext from 'src/context/SecurityAdvisorContext';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const RiskLevel = () => {
  const { job, getSortedWorkers } = useContext(SecurityAdvisorContext);
  const [lastScore, setLastScore] = useState(null);

  useEffect(() => {
    if (getSortedWorkers) {
      let workers = getSortedWorkers();

      const firstWorkerWithScore = workers.find((worker) => worker.score !== null);
      console.log('First worker with score:', firstWorkerWithScore?.score);
      setLastScore(firstWorkerWithScore ?? null);
    }
  }, [job]);

  return (
    <div className='flex flex-col items-center'>
      <div>
        <RiskChart value={lastScore?.score} categories={lastScore?.score_categories} />
      </div>
      {lastScore ? (
        <>
          <div className='font-semibold'>{dayjs(lastScore.updated_at).fromNow()}</div>
          <div className='text-xs'>at {dayjs(lastScore.updated_at).format('LL')}</div>
        </>
      ) : null}
    </div>
  );
};

RiskLevel.propTypes = {};

export default RiskLevel;
