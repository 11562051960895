import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { TbSum, TbUser } from 'react-icons/tb';
import { FaCirclePlus } from 'react-icons/fa6';
import { FaExclamationTriangle } from 'react-icons/fa';
import useTheme from 'src/hooks/useTheme';
import { Popover, Tooltip } from 'antd';
import UserTicketListHoursSubmit from './UserTicketListHoursSubmit';
import TeacketContext from 'src/context/TeacketContext';
import { notArrayOrEmpty } from 'src/misc/Misc';

const UserTicketListHours = ({ ticketId, hoursTotalDefaultValue, userHoursTotalDefaultValue }) => {
  const { theme } = useTheme();
  const [hoursIsOpen, setHoursIsOpen] = useState(false);
  const { userTicketList, setUserTicketList } = useContext(TeacketContext);

  const handleOnSubmit = (e) => {
    console.log('UserTicketListHours handleOnSubmit:', e);
    // <sync hours in the table>
    if (true === notArrayOrEmpty(userTicketList)) return;
    const utl = [...userTicketList];
    const idx = utl.findIndex((ut) => ut.id === ticketId);
    if (idx === -1) return;
    utl[idx].hours_total = e.total;
    utl[idx].user_hours_total = e.user_total;
    setUserTicketList(utl);
    // </sync hours in the table>
    setHoursIsOpen(false);
  };

  return (
    <>
      <div className='flex flex-row gap-1 items-center'>
        <div className='flex-auto'>
          {userHoursTotalDefaultValue ? (
            <Tooltip title='Your hours' mouseEnterDelay={1}>
              <div className='flex flex-row font-light text-xs items-center gap-1 cursor-default'>
                <TbUser />
                {userHoursTotalDefaultValue}
              </div>
            </Tooltip>
          ) : (
            <Tooltip title="You haven't reported the time you've spent on this ticket!">
              <div className='flex flex-row font-light text-xs items-center gap-1 cursor-default'>
                <TbUser />
                <FaExclamationTriangle className='text-orange-500 hover:scale-110' />
              </div>
            </Tooltip>
          )}
          {hoursTotalDefaultValue && (
            <Tooltip title='Total hours' mouseEnterDelay={1}>
              <div className='flex flex-row font-light text-xs items-center gap-1 cursor-default'>
                <TbSum />
                {hoursTotalDefaultValue}
              </div>
            </Tooltip>
          )}
        </div>
        <div className='flex flex-row items-center'>
          {/* <Tooltip title='Add time entry' mouseEnterDelay={1}> */}
          <Popover
            open={hoursIsOpen}
            onOpenChange={(open) => setHoursIsOpen(open)}
            title={
              <>
                Add time entry for this week <span className='text-xs opacity-60 font-light'> | {ticketId}</span>
              </>
            }
            trigger='click'
            content={<UserTicketListHoursSubmit ticketId={ticketId} onSubmit={handleOnSubmit} />}
          >
            <FaCirclePlus className='hover:scale-110 cursor-pointer' style={{ color: theme.primary }} />
          </Popover>
          {/* </Tooltip> */}
        </div>
      </div>
    </>
  );
};

UserTicketListHours.propTypes = {
  ticketId: PropTypes.string,
  hoursTotalDefaultValue: PropTypes.number,
  userHoursTotalDefaultValue: PropTypes.number,
};

export default UserTicketListHours;
