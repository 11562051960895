import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import MuiTheme from 'src/misc/MuiTheme';
import { ResponsiveChartContainer, BarPlot, LinePlot, MarkPlot, ChartsXAxis, ChartsYAxis } from '@mui/x-charts';
import NoData from 'src/components/layout/NoData';
import useTheme from 'src/hooks/useTheme';
import { colors } from '../../Misc/misc';

//import PropTypes from 'prop-types';

const CompositionInPeriods = () => {
  const { isDarkMode } = useTheme();
  const { dashboardDataTickets } = useContext(TeacketContext);

  const [dataOpened, setDataOpened] = useState(null);
  const [dataClosed, setDataClosed] = useState(null);
  const [dataRemaining, setDataRemaining] = useState(null);
  const [period, setPeriod] = useState(null);

  useEffect(() => {
    const { openedByPeriods, closedByPeriods, remainingByPeriods } = dashboardDataTickets ?? {};
    if (!openedByPeriods || !closedByPeriods || !remainingByPeriods) return;
    let d;

    d = openedByPeriods.map((item) => ({
      id: item.label,
      label: item.label,
      value: item.value,
    }));
    setDataOpened(d);

    d = closedByPeriods.map((item) => ({
      id: item.label,
      label: item.label,
      value: item.value,
    }));
    setDataClosed(d);

    d = remainingByPeriods.map((item) => ({
      id: item.label,
      label: item.label,
      value: item.value,
    }));
    setDataRemaining(d);

    const p = remainingByPeriods[0]?.period ?? null;
    setPeriod(p);
  }, [dashboardDataTickets]);

  if (!dataOpened || !dataClosed || !dataRemaining)
    return (
      <div className='py-12'>
        <NoData />
      </div>
    );

  return (
    <MuiTheme>
      <ResponsiveChartContainer
        colors={isDarkMode ? [colors.open.light, colors.closed.light, colors.remaining.light] : [colors.open.dark, colors.closed.dark, colors.remaining.dark]}
        height={300}
        series={[
          {
            type: 'bar',
            data: dataOpened.map((x) => x.value),
          },
          {
            type: 'bar',
            data: dataClosed.map((x) => x.value),
          },
          {
            type: 'line',
            data: dataRemaining.map((x) => x.value),
          },
        ]}
        xAxis={[
          {
            data: dataOpened.map((x) => x.label),
            scaleType: 'band',
            id: 'x-axis-id',
          },
        ]}
      >
        <BarPlot />
        <LinePlot />
        <MarkPlot />
        <ChartsXAxis label={period} position='bottom' axisId='x-axis-id' />
        <ChartsYAxis />
      </ResponsiveChartContainer>
    </MuiTheme>
  );
};

CompositionInPeriods.propTypes = {};

export default CompositionInPeriods;
