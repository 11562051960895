import React, { Component } from 'react';
import HomePageContext from './HomePageContext';

export default class GlobalProvider extends Component {
  state = {
    drawerTitle: null,
    drawerContent: null,
    isDownloading: false,
  };

  render() {
    return (
      <HomePageContext.Provider
        value={{
          ...this.state,
          openDrawer: (title, content) => this.setState({ drawerTitle: title, drawerContent: content }),
          closeDrawer: () => this.setState({ drawerTitle: null, drawerContent: null }),
          setIsDownloading: (isDownloading) => this.setState({ isDownloading }),
        }}>
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </HomePageContext.Provider>
    );
  }
}
