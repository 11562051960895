import React from 'react';
import { FaFaceSmile } from 'react-icons/fa6';
import PropTypes from 'prop-types';

const Smile = ({ show, label }) => {
  if (!show) return null;
  return (
    <div className='flex flex-col justify-center items-center text-yellow-500 dark:text-yellow-500 animate-pop'>
      <FaFaceSmile className='text-2xl' />
      <div className='text-md font-bold'>{label}</div>
    </div>
  );
};

Smile.propTypes = {
  show: PropTypes.bool,
  label: PropTypes.string,
};

export default Smile;
