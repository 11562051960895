import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import UserResultantPermissions from 'src/components/controls/UserResultantPermissions/UserResultantPermissions';
import { getDeterminePermissions } from 'src/api/accesscontrol';
import { Skeleton } from 'antd';

const UserPermissions = ({ userId }) => {
  const { executeAsyncProcess } = useAsyncProcesses();

  const [resultantPermissions, setResultantPermissions] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getDeterminePermissions(userId);
        setResultantPermissions(resp.resultantPermissions);
      } catch (error) {
        console.log(error);
      }
    });
  };

  if (!resultantPermissions)
    return (
      <div className='flex flex-col gap-2'>
        <Skeleton.Input active size={18} />
        <Skeleton.Input className='ml-6' active size={18} />
        <Skeleton.Input className='ml-12' active size={18} />
        <Skeleton.Input className='ml-12' active size={18} />
        <Skeleton.Input active size={18} />
        <Skeleton.Input className='ml-6' active size={18} />
        <Skeleton.Input className='ml-6' active size={18} />
      </div>
    );

  return <UserResultantPermissions resultantPermissions={resultantPermissions} />;
};

UserPermissions.propTypes = {
  userId: PropTypes.string,
};

export default UserPermissions;
