import React, { useEffect, useState } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useTheme from 'src/hooks/useTheme';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { Space, message } from 'antd';
import { getDepartments, getGroups, createGroup, deleteGroup } from 'src/api/users';
import { MdGroup } from 'react-icons/md';

const Groups = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { isDarkMode } = useTheme();

  const [, /*loading*/ setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = React.useState([]);
  const [newGroupName, setNewGroupName] = React.useState('');
  const [departments, setDepartments] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'Groups',
      },
    ]);

    loadData();

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      const currentDepartments = await getDepartments();
      console.log('Current Departments:', currentDepartments);
      setDepartments(currentDepartments);

      const currentGroups = await getGroups();
      console.log('Current Groups:', currentGroups);
      setGroups(currentGroups);
    } catch (error) {
      messageApi.error('Failed to load departments');
    } finally {
      setLoading(false);
    }
  };

  // Handle department selection
  const handleDepartmentSelect = (department) => {
    setSelectedDepartments((prev) => {
      if (prev.includes(department)) {
        return prev.filter((dept) => dept !== department);
      }
      return [...prev, department];
    });
  };

  // Create new group
  const handleCreateGroup = async () => {
    try {
      if (newGroupName.trim() && selectedDepartments.length > 0) {
        const newGroup = {
          name: newGroupName,
          departments: selectedDepartments,
        };

        // Save group in database with the selected departments
        setLoading(true);
        const group = await createGroup({
          name: newGroup.name,
          departments: newGroup.departments,
        });
        setLoading(false);

        setGroups((prev) => [...prev, group]);
        setNewGroupName('');
        setSelectedDepartments([]);
      }
    } catch (error) {
      console.error('Failed to create group:', error);
      messageApi.error('Failed to create group');
    }
  };

  // Delete group
  const handleDeleteGroup = async (groupId) => {
    try {
      // Delete group from database
      await deleteGroup(groupId);
      setGroups((prev) => prev.filter((group) => group.id !== groupId));
    } catch (error) {
      console.error('Failed to delete group:', error);
      messageApi.error('Failed to delete group');
    }
  };

  return (
    <PermissionsProvider requiredPermissions={['features.groups']}>
      {contextHolder}
      <TitleBar
        title={
          <Space>
            <MdGroup />
            Groups
          </Space>
        }
      />

      <div className='flex p-4'>
        {/* <!-- Left section --> */}
        <div className={`w-1/2 border p-4 rounded-lg shadow-sm ${isDarkMode ? ' bg-gray-800' : 'bg-gray-100'}`}>
          <div className='p-6 max-w-4xl mx-auto'>
            <h1 className='text-2xl font-bold mb-6'>Department Group Manager</h1>

            {/* Group Creation */}
            <div className='mb-6'>
              <h2 className='text-xl font-semibold mb-3'>Create New Group</h2>
              <div className='flex gap-4'>
                <input type='text' value={newGroupName} onChange={(e) => setNewGroupName(e.target.value)} placeholder='Enter group name' className='flex-1 p-2 border rounded' />
                <button onClick={handleCreateGroup} disabled={!newGroupName.trim() || selectedDepartments.length === 0} className='px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300'>
                  Create Group
                </button>
              </div>
            </div>

            {/* Department Selection */}
            <div className='mb-6'>
              <h2 className='text-xl font-semibold mb-3'>Select Departments</h2>
              <div className='grid grid-cols-2 gap-4 md:grid-cols-3'>
                {departments.map((department) => (
                  <div key={department} className={`p-3 border rounded cursor-pointer ${selectedDepartments.some((selected) => selected === department) ? 'bg-genesys-warmred-900' : 'bg-genesys-warmred-500'} font-bold`} onClick={() => handleDepartmentSelect(department)}>
                    {department}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Right section: Existing Groups --> */}
        <div className='w-1/2 pl-4'>
          <div className={`border border-gray-300 p-4 rounded-lg shadow-sm ${isDarkMode ? ' bg-gray-800' : 'bg-slate-100'}`}>
            <h3 className='text-lg font-semibold'>Existing Groups</h3>
            <div className='space-y-4'>
              {groups?.map((group) => (
                <div key={group.id} className='pl-4 pb-2 pr-2 border border-dotted rounded border-genesys-warmred-500'>
                  <div className='flex justify-between items-center'>
                    <h3 className='text-lg font-medium'>{group.name}</h3>
                    <button onClick={() => handleDeleteGroup(group.id)} className='text-red-500 hover:text-red-700'>
                      Delete
                    </button>
                  </div>
                  <div className='flex flex-wrap gap-2'>
                    {group.departments.map((department) => (
                      <span key={department} className='px-2 py-1 bg-genesys-warmred-500 text-white rounded-full text-sm'>
                        {department}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PermissionsProvider>
  );
};

export default Groups;
