import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import GlobalContext from 'src/context/GlobalContext';
import { getFeatureList } from 'src/api/accesscontrol';
import { notArrayOrEmpty } from 'src/misc/Misc';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';

const FeatrueListLoader = ({ children, loadingElement }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { featureList, setFeatureList } = useContext(GlobalContext);

  useEffect(() => {
    if (notArrayOrEmpty(featureList)) loadData();
  }, []);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getFeatureList();
        setFeatureList(resp);
      } catch (error) {
        console.log(error);
      }
    });
  };

  if (true === notArrayOrEmpty(featureList)) return <>{loadingElement ? loadingElement : <FullScreenSpin title='Please wait' subtitle='Loading feature list' />}</>;

  return <>{children}</>;
};

FeatrueListLoader.propTypes = {
  children: PropTypes.node,
  loadingElement: PropTypes.any,
};

export default FeatrueListLoader;
