import styled from 'styled-components';

import { ROW_HEIGHT, FOOTER_HEIGHT } from './common';
import { cyan } from '@ant-design/colors';

const StylesWrapper = styled.div`
  .ujc-main-box {
    display: flex;
    flex-flow: row;
  }

  .ujc-row-headers {
    flex: auto 1 0;
    max-width: 250px;
    overflow-x: hidden;
    display: flex;
    flex-flow: column;
  }

  .ujc-headers-item {
    height: ${ROW_HEIGHT} !important;
    text-align: right;
    padding: 4px 8px;
  }

  .ujc-headers-footer {
    height: ${FOOTER_HEIGHT};
  }

  .ujc-rows {
    position: relative;
    flex: auto 1 1;
    display: flex;
    flex-flow: column;
  }

  .ujc-rows-backgrounds {
    z-index: 1;
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .ujc-rows-backgrounds-item {
    height: ${ROW_HEIGHT} !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ujc-rows-backgrounds-item-line {
    background-color: ${cyan[2]};
    width: 100% !important;
    height: 1px !important;
  }

  .ujc-backgrounds-footer {
    height: ${FOOTER_HEIGHT};
  }

  .ujc-rows-data {
    z-index: 2;
    display: flex;
    flex-flow: row;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

export default StylesWrapper;
