import { useContext } from 'react';
import PropTypes from 'prop-types';
import GlobalContext from '../context/GlobalContext';
import { DEFAULT_CALLBACK_URL_SIGNED_OFF } from 'src/misc/Config';

const PrivateRoute = ({ children }) => {
  const globalContext = useContext(GlobalContext);
  if (globalContext.userInfo) return children;
  window.location.href = DEFAULT_CALLBACK_URL_SIGNED_OFF;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
