import React from 'react';
import { Select } from 'antd';
import LangList from './LangList';
import LangToCountry from './LangToCountry';
import PropTypes from 'prop-types';
import { notEmptyArray } from 'src/misc/Misc';
import { DownOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import countryCodeToFlagEmoji from 'country-code-to-flag-emoji';

const LangPicker = ({ width, size, value, defaultValue, onChange, supportedLanguages, maxCount, allowClear }) => {
  const getOptions = () => {
    const result = LangList.map((lang) => {
      return {
        value: lang.code,
        label: lang.name,
      };
    });
    return true === notEmptyArray(supportedLanguages) ? result.filter((x) => supportedLanguages.includes(x.value)) : result;
  };

  const handleOnChange = (value) => {
    console.log('handleOnChange', value);
    if (!onChange) return;
    onChange(value);
  };

  const suffix = (
    <>
      <span>
        {value?.length ?? 0} / {maxCount}
      </span>
      <DownOutlined />
    </>
  );

  const getLanguageFlag = (code) => {
    // flags are related to countries, not languages
    // some languages are spoken in multiple countries
    // so we need to map the language code to a country code sometines
    const countryCode = LangToCountry[code];
    if (!countryCode && !code) return null;
    const flag = countryCodeToFlagEmoji(countryCode ?? code);
    return flag;
  };

  return (
    <Select
      options={getOptions()}
      optionFilterProp='label'
      value={value}
      defaultValue={defaultValue}
      mode='multiple'
      style={{ width }}
      size={size}
      allowClear={allowClear}
      showSearch
      onChange={handleOnChange}
      maxCount={maxCount}
      suffixIcon={maxCount && suffix}
      tagRender={({ label, value, closable, onClose }) => {
        const onPreventMouseDown = (event) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
          <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} size={size} className='text-sm'>
            {getLanguageFlag(value)} <span>{label}</span> <span className='font-light opacity-60'>({value})</span>
          </Tag>
        );
      }}
      optionRender={({ label, value }) => {
        return (
          <div>
            {getLanguageFlag(value)} <span>{label}</span> <span className='font-light opacity-60'>({value})</span>
          </div>
        );
      }}
    />
  );
};

LangPicker.propTypes = {
  value: PropTypes.array,
  defaultValue: PropTypes.array,
  size: PropTypes.string,
  width: PropTypes.string,
  onChange: PropTypes.func,
  supportedLanguages: PropTypes.array,
  maxCount: PropTypes.number,
  allowClear: PropTypes.bool,
};

LangPicker.defaultProps = {
  size: null,
  width: '100%',
};

export default LangPicker;
