import React, { useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { Card, Space } from 'antd';
import CountUp from 'react-countup';
import useTheme from 'src/hooks/useTheme';
import NoData from 'src/components/layout/NoData';
import { colors } from '../../Misc/misc';
//import PropTypes from 'prop-types';

const DashboardTickets = () => {
  const { theme, isDarkMode } = useTheme();

  const { dashboardDataTickets } = useContext(TeacketContext);
  return (
    <div className='my-4 flex flex-row gap-2 justify-center flex-wrap'>
      <Card
        size='small'
        title={
          <Space className='btn' style={{ color: theme.textBase }}>
            Open
          </Space>
        }
        styles={{ header: { padding: 0, backgroundColor: isDarkMode ? colors.open.dark : colors.open.light } }}
        className='w-44 text-center'>
        <div className='text-center text-5xl'>{dashboardDataTickets?.opened || dashboardDataTickets?.opened === 0 ? <CountUp end={dashboardDataTickets?.opened} separator=',' duration={3} /> : <NoData />}</div>
      </Card>

      <Card
        size='small'
        title={
          <Space className='btn' style={{ color: theme.textBase }}>
            Closed
          </Space>
        }
        styles={{ header: { padding: 0, backgroundColor: isDarkMode ? colors.closed.dark : colors.closed.light } }}
        className='w-44 text-center'>
        <div className='text-center text-5xl'>{dashboardDataTickets?.closed || dashboardDataTickets?.closed === 0 ? <CountUp end={dashboardDataTickets?.closed} separator=',' duration={3} /> : <NoData />}</div>
      </Card>

      <Card
        size='small'
        title={
          <Space className='btn' style={{ color: theme.textBase }}>
            Remaining open
          </Space>
        }
        styles={{ header: { padding: 0, backgroundColor: isDarkMode ? colors.remaining.dark : colors.remaining.light } }}
        className='w-44 text-center'>
        <div className='text-center text-5xl'>{dashboardDataTickets?.remaining || dashboardDataTickets?.remaining === 0 ? <CountUp end={dashboardDataTickets?.remaining} separator=',' duration={3} /> : <NoData />}</div>
      </Card>
    </div>
  );
};

DashboardTickets.propTypes = {};

export default DashboardTickets;
