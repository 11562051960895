import React, { useEffect, useContext, useState } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import FeatrueListLoader from './FeatrueListLoader';
import PropTypes from 'prop-types';
import { notArrayOrEmpty } from 'src/misc/Misc';
import { Result } from 'antd';
import { getFeaturesAccess as apiGetFeaturesAccess } from 'src/api/accesscontrol';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import FreeTrialActivation from './FreeTrialActivation';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import { describeFeaturesAccess } from './Misc';

const FeaturesAccess = ({ children, feature, featureDisplayName }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { featureList, setFeaturesAccess: contextSetFeaturesAccess, getFeatureAccess: contextGetFeatureAccess, setCurrentFeature, resetCurrentFeature, setCurrentFeatureDisplayName, resetCurrentFeatureDisplayName } = useContext(GlobalContext);

  const [showFreeTrialWctivation, setShowFreeTrialWctivation] = useState(false);
  const [featureAccessDetermined, setFeatureAccessDetermined] = useState(false);

  useEffect(() => {
    setCurrentFeature(feature);
    setCurrentFeatureDisplayName(featureDisplayName);
    return () => {
      resetCurrentFeature();
      resetCurrentFeatureDisplayName();
    };
  }, []);

  useEffect(() => {
    verifyAccess();
  }, [featureList]);

  const verifyAccess = async () => {
    if (true === notArrayOrEmpty(featureList)) return;

    //#region "feature not provided or invalid"
    if (!feature) {
      console.error('Feature not provided');
      return;
    }
    const featureConfig = featureList.find((f) => f.feature === feature);
    if (!featureConfig) {
      console.error(`Invalid feature [${feature}]`);
      return;
    }
    //#endregion

    //#region "querying feature access"
    let featureAccess = await getFeatureAccessData();
    console.log('featureAccess:', featureAccess);
    //#endregion

    //#region "determining"
    if (!featureAccess || (!featureAccess.free_trial_from && !featureAccess.free_trial_to && !featureAccess.paid_access_from && !featureAccess.paid_access_to && !featureAccess.freeTrialError && !featureAccess.paidAccessError)) {
      console.log('Show free trial activation');
      setShowFreeTrialWctivation(true);
      return;
    }

    featureAccess = describeFeaturesAccess(featureAccess);
    console.log('featureAccess described:', featureAccess);
    setFeatureAccessDetermined(featureAccess);
    //#endregion
  };

  const getFeatureAccessData = async () => {
    let result = null;
    await executeAsyncProcess(async () => {
      try {
        result = await contextGetFeatureAccess(feature);
        // console.log('result from context:', result);
        if (!result) {
          result = await apiGetFeaturesAccess(feature);
          // console.log('result from db:', result);
          contextSetFeaturesAccess(feature, result);
        }
      } catch (error) {
        console.log(error);
      }
    });
    return result;
  };

  const desc = (
    <div>
      {featureAccessDetermined.freeTrialDesc && (
        <div>
          <span className='font-light opacity-60'>Free trial:</span> {featureAccessDetermined.freeTrialDesc}
        </div>
      )}
      {featureAccessDetermined.paidAccessDesc && (
        <div>
          <span className='font-light opacity-60'>Paid access:</span> {featureAccessDetermined.paidAccessDesc}
        </div>
      )}
    </div>
  );

  const getContent = () => {
    if (showFreeTrialWctivation) return <FreeTrialActivation feature={feature} featureDisplayName={featureDisplayName} />;
    if (!featureAccessDetermined) return <FullScreenSpin title='Please wait' subtitle='Determining feature access' />;
    if (featureAccessDetermined.freeTrialError || featureAccessDetermined.paidAccessError) return <Result status='error' title='The feature is not available' subTitle='Error' />;
    if (featureAccessDetermined.freeTrialHasAccess || featureAccessDetermined.paidAccessHasAccess) return children;
    return <Result status='404' title='The feature is not available' subTitle={desc} />;
  };

  // if (featureAccessDetermined) return <Result status='403' title='The feature is not available' subTitle={errorMessage} />;

  return <FeatrueListLoader>{getContent()}</FeatrueListLoader>;
};

FeaturesAccess.propTypes = {
  feature: PropTypes.string,
  featureDisplayName: PropTypes.string,
  days: PropTypes.number,
  children: PropTypes.any,
};

export default FeaturesAccess;
