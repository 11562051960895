import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import useUserInfo from 'src/hooks/useUserInfo';
import { filterItemsBasedOnUserPermissions } from 'src/misc/Session';
import DashboardsContext from 'src/context/DashboardsContext';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import TitleBar from 'src/components/layout/TitleBar';
import { Space, Tabs } from 'antd';
import { MdDashboard } from 'react-icons/md';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import AiAssistant from './AIAssistant';
import SecurityAdvisor from './SecurityAdvisor';
import SubjectRequests from './SubjectRequests';
import AuditViewer from './AuditViewer';
import GreenTeacket from './GreenTeacket';
import FSIReport from './FSIReport';
import useTheme from 'src/hooks/useTheme';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from './Misc/misc';
import Downloads from './FileDownloads';

const StyledDiv = styled.div`
  .ant-tabs-nav {
    margin: 8px 8px 8px 8px;
    border-bottom: 1px solid ${(props) => props.theme.primary};
  }
`;

const Dashboards = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { layoutItemsSelector } = useContext(DashboardsContext);
  const { theme } = useTheme();

  const { permissions: userPermissions } = useUserInfo();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Dashboards',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const [activeTab, setActiveTab] = useState('1');

  const tabs = [
    {
      label: 'AI Assistant',
      key: '1',
      children: <AiAssistant />,
    },
    {
      label: 'Security Advisor',
      key: '2',
      children: <SecurityAdvisor />,
      permissions: ['apps.copilot.view'],
    },
    {
      label: 'Subject Requests',
      key: '3',
      children: <SubjectRequests />,
      permissions: ['apps.datasubjectrequests.view'],
    },
    {
      label: 'Audit Viewer',
      key: '4',
      children: <AuditViewer />,
      permissions: ['apps.auditviewer.view'],
    },
    {
      label: 'Green Teacket',
      key: '5',
      children: <GreenTeacket />,
      permissions: ['apps.teacket.view'],
    },
    {
      label: 'FSI Report',
      key: '6',
      children: <FSIReport />,
      permissions: ['apps.fsireport.view'],
    },
    {
      label: 'Downloads',
      key: '7',
      children: <Downloads />,
      permissions: ['admin.master'],
    },
  ].filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions));

  return (
    <PermissionsProvider requiredPermissions={['apps.dashboards.view', 'admin.tenant']}>
      <TitleBar
        className='mb-12'
        title={
          <Space className='btn'>
            <MdDashboard />
            Dashboards
          </Space>
        }
        afterTitleExtras={<PageSwitch pageList={pageList} />}>
        {layoutItemsSelector}
      </TitleBar>
      <StyledDiv theme={theme}>
        <Tabs
          onChange={(key) => setActiveTab(key)}
          activeKey={activeTab}
          type='card'
          items={tabs?.map((x) => {
            return { ...x, children: undefined };
          })}
        />
        {tabs.find((x) => x.key === activeTab)?.children}
      </StyledDiv>
    </PermissionsProvider>
  );
};

export default Dashboards;
