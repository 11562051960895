import React, { useState, useEffect } from 'react';
import { Table, Spin } from 'antd';
import { getDeprecations } from 'src/api/fsireport';
import PropTypes from 'prop-types';
import { uuid } from 'short-uuid';

const Deprecations = ({ exportData }) => {
  const [loading, setLoading] = useState(false);
  const [deprecations, setDeprecations] = useState([]);

  const columns = [
    {
      title: 'Details',
      key: 'details',
      id: uuid(),
      render: (record) =>
        record.link ? (
          <a href={record.link} className='text-xs'>
            {record.details}
          </a>
        ) : (
          <span className='text-xs'>{record.details}</span>
        ),
    },
    {
      title: 'Announced Date',
      key: 'announced',
      width: 130,
      align: 'center',
      render: (record) => <span className='text-xs'>{record.announced}</span>,
    },
    {
      title: 'Effective Date',
      key: 'effective',
      width: 130,
      align: 'center',
      render: (record) => <span className='text-xs'>{record.effective}</span>,
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    console.log('[Incidents] loadData()');
    try {
      setLoading(true);
      const resp = await getDeprecations();
      setDeprecations(resp);
      if (exportData) exportData(resp);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className='text-center align-middle'>
          <Spin />
        </div>
      ) : (
        <>
          <Table bordered title={() => <p className='text-center m-0 p-0 '>Upcoming deprecations (3 months)</p>} size='small' pagination={false} columns={columns} dataSource={deprecations.future} rowKey={'id'} />
          <p></p>
          <Table bordered title={() => <p className='text-center m-0 p-0 '>Past deprecations (3 months)</p>} size='small' pagination={false} columns={columns} dataSource={deprecations.past} rowKey={'id'} />
          <p className='mb-2'></p>
        </>
      )}
    </>
  );
};

Deprecations.propTypes = {
  exportData: PropTypes.func,
};

export default Deprecations;
