import React, { useEffect, useState, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import styled from 'styled-components';
import { getAccountInformation } from 'src/api/snowflake';
import { Tabs, Tooltip } from 'antd';
import { MdCloudQueue, MdPeopleOutline } from 'react-icons/md';
import Contacts from './Contacts';
import Deployments from './Deployments';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import NoData from 'src/components/layout/NoData';
//import PropTypes from 'prop-types';

const Styled = styled.div`
  .ant-tabs-tab {
    padding: 2px 12px !important;
  }
`;

const TicketSfData = () => {
  const { currentTicket } = useContext(TeacketContext);

  const [isLoading, setIsLoading] = useState(false);
  const [accountInformation, setAccountInformation] = useState(null);

  useEffect(() => {
    if (!currentTicket?.acc_id) return;
    laodData();
  }, [currentTicket?.acc_id]);

  const laodData = async () => {
    setIsLoading(true);
    try {
      setAccountInformation(null);
      let accInfo = await getAccountInformation(currentTicket?.acc_id);

      if (accInfo?.deployments?.length && currentTicket?.dep_id) {
        const { deployments } = accInfo;

        // Separate selected and rest deployments
        const selectedDeployment = deployments.find(({ PURECLOUD_ORGID__C }) => PURECLOUD_ORGID__C === currentTicket.dep_id);
        const restDeployments = deployments.filter(({ PURECLOUD_ORGID__C }) => PURECLOUD_ORGID__C !== currentTicket.dep_id);

        // Update deployments with selected deployment first
        accInfo.deployments = selectedDeployment ? [selectedDeployment, ...restDeployments] : restDeployments;
      }
      setAccountInformation(accInfo);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  if (!currentTicket) return <SkeletonTable />;
  if (!currentTicket.acc_id) return <NoData />;
  if (isLoading) return <SkeletonTable />;
  if (!accountInformation) return <NoData />;

  return (
    <Styled>
      <Tabs
        type='card'
        size='small'
        defaultActiveKey='contacts'
        items={[
          {
            label: (
              <Tooltip title='Designated contacts'>
                <MdPeopleOutline />
              </Tooltip>
            ),
            key: 'contacts',
            children: <Contacts data={accountInformation?.contacts} />,
          },
          {
            label: (
              <Tooltip title='Deployments'>
                <MdCloudQueue />
              </Tooltip>
            ),
            key: 'deployments',
            children: <Deployments dataDeployments={accountInformation?.deployments} ticketDeploymentId={currentTicket?.dep_id} dataProductLine={accountInformation?.productLine} />,
          },
        ]}
      />
    </Styled>
  );
};

TicketSfData.propTypes = {};

export default TicketSfData;
