import React from 'react';
import styled from 'styled-components';
import { Popover, Button, Checkbox } from 'antd';
import { LuLayoutDashboard } from 'react-icons/lu';
import PropTypes from 'prop-types';

const StylesWrapper = styled.div`
  .ant-checkbox-group {
    flex-direction: column;
  }

  .ant-switch-checked {
    background-color: #ff0000ff !important;
    font-weight: bold;
  }
`;

const LayoutItemsSelector = ({ allComponentIds, hiddenIds, onShownComponentsIdsChange, onLayoutReset }) => {
  const getCheckboxOptions = () => allComponentIds?.sort().map((x) => ({ label: x, value: x }));

  const content = (
    <StylesWrapper>
      <div>
        <div className='mt-4'>
          <Checkbox.Group
            options={getCheckboxOptions()}
            defaultValue={allComponentIds.filter((x) => !hiddenIds?.includes(x))}
            onChange={(v) => {
              if (onShownComponentsIdsChange) onShownComponentsIdsChange(v);
            }}
          />
        </div>

        <div className='mt-4'>
          <Button
            size='small'
            onClick={() => {
              if (!onLayoutReset) return;
              onLayoutReset();
            }}>
            Reset and reload
          </Button>
        </div>
      </div>
    </StylesWrapper>
  );

  return (
    <StylesWrapper>
      <Popover content={content} title='Adjust the layout' trigger='click'>
        <Button type='text' icon={<LuLayoutDashboard />} />
      </Popover>
    </StylesWrapper>
  );
};

LayoutItemsSelector.propTypes = {
  allComponentIds: PropTypes.array,
  hiddenIds: PropTypes.array,
  onShownComponentsIdsChange: PropTypes.func,
  onLayoutReset: PropTypes.func,
};

LayoutItemsSelector.defaultProps = {
  defaultDraggable: false,
};

export default LayoutItemsSelector;
