import React, { useEffect } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { Space, Button, notification } from 'antd';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { deleteSnsTopic } from 'src/api/subscriptions';
import { deleteEbIntegration } from 'src/api/auditviewer';
import { sleepMs } from 'src/misc/Misc';
import { AV_APP_ID } from './Misc/misc';
import GcPermissionsProvider from 'src/providers/GcPermissionsProvider';
import { AiOutlineAudit } from 'react-icons/ai';

//import PropTypes from 'prop-types';

const EventsUninstall = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { executeAsyncProcess } = useAsyncProcesses();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Audit Viewer',
        path: '/audit-viewer',
      },
      {
        title: 'Notifications',
        path: '/audit-viewer/events',
      },
      {
        title: 'Uninstall',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const handleUninstall = async () => {
    console.log('handleUninstall()');
    await executeAsyncProcess(async () => {
      //#region "Uninstall SNS Topic"
      try {
        await deleteSnsTopic(AV_APP_ID);
        notification.success({
          message: 'Success',
          description: <>SNS Topic has been uninstalled</>,
          duration: 5,
        });
      } catch (error) {
        console.error(error);
        notification.error({
          message: 'Error',
          description: (
            <>
              <div>Uninstalling SNS Topic error</div>
              <div className='text-xs opacity-60'>{error.toString()}</div>
            </>
          ),
          duration: 5,
        });
      }
      //#endregion

      //#region "Uninstall EB integration"
      try {
        await deleteEbIntegration();
        notification.success({
          message: 'Success',
          description: <>Event Bridge Integration has been uninstalled</>,
          duration: 5,
        });
      } catch (error) {
        console.error(error);
        notification.error({
          message: 'Error',
          description: (
            <>
              <div>Uninstalling Event Bridge Integration error</div>
              <div className='text-xs opacity-60'>{error.toString()}</div>
            </>
          ),
          duration: 5,
        });
      }
      //#endregion

      await sleepMs(5000); // allow to display a notification before re-loading the page
    });

    window.location.href = '/audit-viewer';
  };

  return (
    <PermissionsProvider requiredPermissions={['apps.auditviewer.view', 'features.sns.edit']}>
      <GcPermissionsProvider
        requiredPermissions={[
          {
            domain: 'integrations',
            entityName: 'integration',
            action: 'delete',
          },
        ]}
      >
        <TitleBar
          title={
            <Space className='btn'>
              <AiOutlineAudit />
              Audit viewer
              <span className='font-thin'>Notifications</span>
              <span className='font-thin'>Uninstall</span>
            </Space>
          }
        ></TitleBar>

        <div className='p-4'>
          <Button type='primary' onClick={handleUninstall}>
            Force uninstall
          </Button>
        </div>
      </GcPermissionsProvider>
    </PermissionsProvider>
  );
};

EventsUninstall.propTypes = {};

export default EventsUninstall;
