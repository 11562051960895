import React, { useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import Item from './Item';
//import PropTypes from 'prop-types';

const Items = () => {
  const { myTickets } = useContext(TeacketContext);

  return (
    <div className='flex flex-col gap-1'>
      {myTickets
        ?.sort((t) => t.id)
        .map((ticket, index) => (
          <Item key={index} ticket={ticket} />
        ))}
    </div>
  );
};

Items.propTypes = {};

export default Items;
