import React from 'react';
//import PropTypes from 'prop-types';

const LoadingLabel = () => {
  return <div className='text-xs animate-pulse font-light'>Loading...</div>;
};

LoadingLabel.propTypes = {};

export default LoadingLabel;
