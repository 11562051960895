import React, { useState, useContext, useEffect } from 'react';
import { Input, Radio, message, Table, Popover } from 'antd';
import { getGdprSubjects } from 'src/api/dataSubjectRequests';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';

import PropTypes from 'prop-types';
import uuid from 'short-uuid';

const SubjectPicker = () => {
  const searchTypeList = [
    { value: 'NAME', label: 'Name' },
    { value: 'ADDRESS', label: 'Address' },
    { value: 'PHONE', label: 'Phone' },
    { value: 'EMAIL', label: 'Email' },
    { value: 'TWITTER', label: 'Twitter' },
  ];

  const { setSubject } = useContext(DataSubjectRequestsContext);

  const [searchPhrase, setSearchPrase] = useState(null);
  const [selectedSearchType, setSelectedSearchType] = useState(searchTypeList[0].value);
  const [loading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    console.log('subjects:', subjects);
  }, [subjects]);

  const handleOnSearch = async (v) => {
    console.log('handleOnSearch()', v);
    setSubject(null);
    const searchPhraseTrimmed = v?.trim() ?? '';
    setSearchPrase(searchPhraseTrimmed);
    console.log('searchPhraseTrimmed:', searchPhraseTrimmed);
    if (searchPhraseTrimmed.length < 3) return;
    setLoading(true);
    try {
      setSubjects(null);

      const resp = await getGdprSubjects(selectedSearchType, searchPhraseTrimmed);

      // We need an 'id' property for each entry
      resp.entities = resp.entities.map((x) => {
        x.id = uuid.generate();
        return x;
      });

      if (Array.isArray(resp.entities)) {
        setSubjects(resp.entities);
      }
    } catch (error) {
      console.error(error);
      message.error(error.toString());
    }
    setLoading(false);
  };

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: 'User Id',
      dataIndex: 'userId',
      key: 'userId',
      render: (text) =>
        text ? (
          <>
            <Popover content={text}>{text?.substring(0, 8) + '...'}</Popover>
          </>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Emails',
      dataIndex: 'emailAddresses',
      key: 'emailAddresses',
      render: (text) => (text ? text?.join(', ') : 'N/A'),
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumbers',
      key: 'phoneNumbers',
      render: (text) => (text ? text?.join(', ') : 'N/A'),
    },
  ];

  return (
    <div className='flex flex-col pb-2'>
      <div className='flex flex-col flex-auto pb-4'>
        <Radio.Group
          value={selectedSearchType}
          size='small'
          style={{ marginBottom: 16 }}
          onChange={(e) => {
            setSelectedSearchType(e.target.value);
          }}
        >
          {searchTypeList.map((x) => (
            <Radio.Button key={x.value} value={x.value}>
              {x.label}
            </Radio.Button>
          ))}
        </Radio.Group>

        <Input.Search
          autoFocus
          loading={loading}
          placeholder='Type a searching phrase...'
          onSearch={handleOnSearch}
          value={searchPhrase}
          onChange={(e) => {
            setSearchPrase(e.target.value);
          }}
          enterButton
          className='pb-2'
        />

        {Array.isArray(subjects) && <div className='quitelight smaller right'>Results: {subjects?.length ?? 0}</div>}
      </div>
      <div>
        <Table
          rowSelection={{
            type: 'radio',
            selectedRowKeys,
            // This is triggered when a user clicks on the radio button
            onChange: (selectedRowKeys, selectedRows) => {
              console.log('onChange():', selectedRows);
              if (selectedRows.length > 0) {
                setSubject(selectedRows[0]);
                setSelectedRowKeys([selectedRows[0].id]);
              } else {
                setSubject(null);
                setSelectedRowKeys([]);
              }
            },
          }}
          onRow={(record) => ({
            // This is triggered when a row is clicked
            onClick: () => {
              console.log('onRow.onClick():', record);
              setSubject(record);
              setSelectedRowKeys([record.id]);
            },
          })}
          columns={columns}
          rowKey={'id'}
          // Extra information to be displayed when a row is expanded
          expandable={{
            expandedRowRender: (record) => (
              <div className='flex flex-col'>
                <div className='flex flex-row'>
                  <span className='font-bold mr-1'>User Id:</span>
                  <span>{record.userId || 'N/A'}</span>
                </div>
                <div className='flex flex-row'>
                  <span className='font-bold mr-1'>Name:</span>
                  <span>{record.name || 'N/A'}</span>
                </div>
                <div className='flex flex-row'>
                  <span className='font-bold mr-1'>Addresses:</span>
                  <span>{record.addresses?.join(', ') || 'N/A'}</span>
                </div>
                <div className='flex flex-row'>
                  <span className='font-bold mr-1'>Emails:</span>
                  <span>{record.emailAddresses?.join(', ') || 'N/A'}</span>
                </div>
                <div className='flex flex-row'>
                  <span className='font-bold mr-1'>Phone Numbers:</span>
                  <span>{record.phoneNumbers?.join(', ') || 'N/A'}</span>
                </div>
                <div className='flex flex-row'>
                  <span className='font-bold mr-1'>External Contact:</span>
                  <span>{record.externalContactId || 'N/A'}</span>
                </div>
                <div>
                  <span className='font-bold mr-1'>Dialer Contact:</span>
                  {record.dialerContactId ? (
                    <div className='flex flex-col ml-4'>
                      <div>
                        <span className='font-bold mr-1'>Id:</span>
                        <span>{record.dialerContactId?.id || 'N/A'}</span>
                      </div>
                      <div>
                        <span className='font-bold mr-1'>Contact List Id:</span>
                        <span>{record.dialerContactId?.contactListId || 'N/A'}</span>
                      </div>
                    </div>
                  ) : (
                    <>N/A</>
                  )}
                </div>
                <div>
                  <span className='font-bold mr-1'>Journey Customer:</span>
                  {record.journeyCustomer ? (
                    <div className='flex flex-col ml-4'>
                      <div>
                        <span className='font-bold mr-1'>Type:</span>
                        <span>{record.journeyCustomer?.type || 'N/A'}</span>
                      </div>
                      <div>
                        <span className='font-bold mr-1'>Id:</span>
                        <span>{record.journeyCustomer?.id || 'N/A'}</span>
                      </div>
                    </div>
                  ) : (
                    <>N/A</>
                  )}
                </div>
                <div>
                  <span className='font-bold mr-1'>Social Handle:</span>
                  {record.socialHandle ? (
                    <div className='flex flex-col ml-4'>
                      <div>
                        <span className='font-bold mr-1'>Type:</span>
                        <span>{record.socialHandle?.type || 'N/A'}</span>
                      </div>
                      <div>
                        <span className='font-bold mr-1'>Value:</span>
                        <span>{record.socialHandle?.value || 'N/A'}</span>
                      </div>
                    </div>
                  ) : (
                    <>N/A</>
                  )}
                </div>
                <div className='flex flex-row'>
                  <span className='font-bold mr-1'>External Id:</span>
                  <span>{record.externalId || 'N/A'}</span>
                </div>
              </div>
            ),
          }}
          dataSource={subjects}
        />
      </div>
    </div>
  );
};

SubjectPicker.propTypes = {
  onSubjectPick: PropTypes.func,
};

SubjectPicker.defaultProps = {
  onSubjectPick: () => {
    console.warn('onSubjectPick not provided');
  },
};

export default SubjectPicker;
