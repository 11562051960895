import React, { useState, useContext } from 'react';
import { Radio, Input, Select } from 'antd';
import { requestTypeList, replacementTypeList } from '../../Misc/Dictionary';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';

const { Option } = Select;

const DSRRequestTypeStep = () => {
  const { useRealApi, setRequestType, requestType, wizard_UpdateOldValue, wizard_SetUpdateOldValue, wizard_ReplacementValue, wizard_SetReplacementValue, job, wizard_SetReplacementType } = useContext(DataSubjectRequestsContext);

  const [replacementType, setReplacementType] = useState(replacementTypeList[0].value);

  const replacementSelect = (
    <Select
      style={{ width: '120px' }}
      value={replacementType}
      onSelect={(v) => {
        setReplacementType(v);
        wizard_SetReplacementType(v);
      }}
    >
      {replacementTypeList.map((x) => (
        <Option key={x.value} value={x.value}>
          {x.label}
        </Option>
      ))}
    </Select>
  );

  return (
    <div>
      <div className='font-bold text-xl'>Select your request type</div>
      <div className='font-extralight'>
        <div className='mt-3'>
          <>
            <div>
              <Radio.Group
                value={requestType}
                size='small'
                style={{ marginBottom: 12 }}
                onChange={(e) => {
                  setRequestType(e.target.value);
                }}
              >
                {requestTypeList.map((x) => (
                  <Radio.Button key={x.value} value={x.value}>
                    <i className={`${x.icon} btn-margin-right`} />
                    {x.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>

            {/* <GDPR_EXPORT> */}
            {requestType === 'GDPR_EXPORT' && (
              <div style={{ marginBottom: 12 }}>
                <div style={{ marginBottom: 12 }}>
                  {useRealApi ? (
                    <div>The request will export all the data related to the selected subject.</div>
                  ) : (
                    <div>
                      The request will export all the data related to the selected subject.
                      <div>
                        As you selected to <strong>not use real API requests</strong>, no real data will be exported.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* </GDPR_EXPORT> */}

            {/* <GDPR_UPDATE> */}
            {requestType === 'GDPR_UPDATE' && (
              <div style={{ marginBottom: 12 }}>
                <div style={{ marginBottom: 12 }}>
                  {useRealApi ? (
                    <div>The request will update the subject data. Please enter the update details below:</div>
                  ) : (
                    <div>
                      As you selected to <strong>not use real API requests</strong>, the request will simulate an update to the selected subject.
                    </div>
                  )}
                </div>
                <div style={{ marginBottom: 12 }}>
                  <Input
                    addonAfter={replacementSelect}
                    autoComplete='off'
                    style={{ maxWidth: '400px' }}
                    placeholder='Enter old value...'
                    value={wizard_UpdateOldValue}
                    onChange={(e) => {
                      wizard_SetUpdateOldValue(e.target.value);
                    }}
                    onBlur={() => {
                      if (wizard_UpdateOldValue) wizard_SetUpdateOldValue(wizard_UpdateOldValue.trim());
                    }}
                  />
                </div>
                <div style={{ marginBottom: 12 }}>
                  <Input
                    autoComplete='off'
                    style={{ maxWidth: '400px' }}
                    placeholder='Enter new value...'
                    value={wizard_ReplacementValue}
                    onChange={(e) => {
                      wizard_SetReplacementValue(e.target.value);
                    }}
                    onBlur={() => {
                      if (wizard_ReplacementValue) wizard_SetReplacementValue(wizard_ReplacementValue.trim());
                    }}
                  />
                </div>
              </div>
            )}
            {/* </GDPR_UPDATE> */}

            {/* <GDPR_DELETE> */}
            {requestType === 'GDPR_DELETE' && (
              <div style={{ marginBottom: 12 }}>
                <div style={{ marginBottom: 12 }}>
                  {useRealApi ? (
                    <div>The request will delete (or anonymize) the subject data.</div>
                  ) : (
                    <div>
                      As you selected to <strong>not use real API requests</strong>, the request will simulate deletion of the selected subject.
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* </GDPR_DELETE> */}
          </>
        </div>
        {!job && (
          <div className='mt-6'>
            Click on <strong>Next</strong> to continue.
          </div>
        )}
      </div>
    </div>
  );
};

DSRRequestTypeStep.propTypes = {};

export default DSRRequestTypeStep;
