import styled from 'styled-components';

const StyledScrollbars = styled.div`
  /* <SCROLLBARS CSS> */
  /* Firefox */
  /* * {
    scrollbar-width: thin !important;
    scrollbar-color: ${(props) => props.color} ${(props) => props.bgColor} !important;
  } */

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 6px !important;
  }

  *::-webkit-scrollbar-track {
    background: ${(props) => props.bgColor ?? 'white'} !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.color ?? 'black'} !important;
    border-radius: 3px !important;
    border: 1px solid ${(props) => props.bgColor ?? 'white'} !important;
  }
  /* </SCROLLBARS CSS> */
`;

export default StyledScrollbars;
