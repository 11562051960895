import React, { useState } from 'react';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';

//import PropTypes from 'prop-types';

const AccessDenied = () => {
  const [baseUrl] = useState(localStorage.getItem(LOCAL_STORAGE_KEYS.selectedGcRegion));
  return (
    <>
      <div className='mt-8'>
        <div className='font-bold text-2xl'>What should you do?</div>
        <div className='my-8'>
          <h4>
            Authorize an OAuth client: <span className='text-genesys-aqua-base'>07e01e9c-dd79-4f6d-a493-13043f68ed73</span>
          </h4>
          <p>Only an approved Genesys Cloud administrator can authorize an OAuth client. Users without permissions are shown a permissions error indicating an administrator is required to approve this application.</p>
          <p>
            To authorize known client IDs:
            <ol className='list-decimal list-inside'>
              <li className='mb-2'>
                Click <strong>Admin</strong>.
              </li>
              <li className='mb-2'>
                Under <strong>Integrations</strong>, click <strong>Authorized Applications</strong>.
              </li>
              <li className='mb-2'>
                Click <strong>Authorize a client</strong>.
                <br />
                <br />
                <img src='/images/signinerror/auth_01.jpg' alt='screen' className='w-3/4' />
              </li>
              <li className='mb-2'>
                Enter the <strong>Client ID</strong> and click <strong>Authorize client</strong>.
                <br />
                <br />
                <img src='/images/signinerror/auth_02.jpg' alt='screen' className='w-1/3' />
              </li>
              <li className='mb-2'>
                Select the required scope.
                <br />
                <br />
                <img src='/images/signinerror/auth_03.jpg' alt='screen' className='w-3/4' />
              </li>
              <li className='mb-2'>
                Click <strong>Approve</strong>.
              </li>
            </ol>
          </p>
          {baseUrl && (
            <p className='mt-8'>
              If you have a relevant administrative permissions, you can use the following link to navigate directly to the <strong>Authorized Applications</strong> page:
              <br />
              <a href={`https://apps.${baseUrl}/directory/#/admin/integrations/authorized-apps`} target='_blank' rel='noreferrer'>
                {`https://apps.${baseUrl}/directory/#/admin/integrations/authorized-apps`}
              </a>
            </p>
          )}
        </div>
      </div>
    </>
  );
};

AccessDenied.propTypes = {};

export default AccessDenied;
