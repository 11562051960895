import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { postGcPermissions } from 'src/api/auth';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import { Result, Tag, Space } from 'antd';

const GcPermissionsProvider = ({ children, requiredPermissions, subTitle = <div className='text-lg'>To run this tool you need to have specific Genesys Cloud permissions.</div> }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const [result, setResult] = useState(null);

  useEffect(() => {
    verifyPermissions();
  }, []);

  const verifyPermissions = async () => {
    await executeAsyncProcess(async () => {
      try {
        const resp = await postGcPermissions(requiredPermissions);
        setResult(resp);
      } catch (error) {
        console.log(error);
      }
    });
  };

  if (!result) return <FullScreenSpin title='Please wait' subtitle='Verifying Genesys Cloud permissions' />;
  if (true === result?.permissionsValidated) return children;

  return (
    <div>
      <Result
        status='403'
        title='Insufficient Permissions'
        subTitle={subTitle}
        extra={
          <div className='flex flex-col gap-1'>
            {result?.requiredPermissions?.map((item, index) => (
              <div key={index}>
                {item.granted ? (
                  <Space className='text-green-800 dark:text-green-200'>
                    {item.domain} &gt; {item.entityName} &gt; {item.action}
                    <Tag size='small' color='green'>
                      Granted
                    </Tag>
                  </Space>
                ) : (
                  <Space className='text-red-800 dark:text-red-200'>
                    {item.domain} &gt; {item.entityName} &gt; {item.action}
                    <Tag size='small' color='red'>
                      Not granted
                    </Tag>
                  </Space>
                )}
              </div>
            ))}
          </div>
        }
      />
    </div>
  );
};

GcPermissionsProvider.propTypes = {
  children: PropTypes.node,
  requiredPermissions: PropTypes.arrayOf(PropTypes.object),
  appId: PropTypes.string,
  helpPage: PropTypes.string,
  subTitle: PropTypes.string,
};

export default GcPermissionsProvider;
