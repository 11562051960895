//import { sleepMs } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';
import { objToQueryStringParams } from 'src/misc/Misc';
import short from 'short-uuid';

var controllerSearchAiModels, controllerSearchAiModelConnectors;

//#region AI Models

export const searchAiModels = async (query, page = 1, pageSize = 25) => {
  controllerSearchAiModels?.abort('cancelled');
  controllerSearchAiModels = new AbortController();
  const signal = controllerSearchAiModels.signal;
  const queryStringParams = objToQueryStringParams({ query, page, pageSize });
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodels/search${queryStringParams}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getAiModels = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodels`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getMyAiModels = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodels/my`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAiModel = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodels/${id}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const addConnectorToAiModel = async (id, connector) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodels/connectors/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(connector),
    })
  );
};

export const patchAiModel = async (id, changes) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodels/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

export const postAiModel = async (newAiModel) => {
  const headers = await getAccessHeaders();
  newAiModel.id = short.generate();
  newAiModel.assigned_permission_id = `apps.copilot.model.${newAiModel.id}`;
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodels`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(newAiModel),
    })
  );
};

export const deleteAiModelApi = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodels/${id}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region AI Model Connectors

export const searchAiModelConnectors = async (query, page = 1, pageSize = 25) => {
  controllerSearchAiModelConnectors?.abort('cancelled');
  controllerSearchAiModelConnectors = new AbortController();
  const signal = controllerSearchAiModelConnectors.signal;
  const queryStringParams = objToQueryStringParams({ query, page, pageSize });
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors/search${queryStringParams}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getAiModelConnectors = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAiModelConnector = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors/${id}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const patchAiModelConnector = async (id, changes) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

export const postAiModelConnector = async (newAiModel) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(newAiModel),
    })
  );
};

export const deleteAiModelConnector = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors/${id}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region AI Model Definitions

export const postAiModelConnectorConfig = async (data) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors/config`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const patchAiModelConnectorConfig = async (id, changes) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors/config/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

export const startAiModelConnectorConfig = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors/config/${id}/start`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const isRunning = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors/config/${id}/status`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const listS3Files = async (modelId, id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors/config/${id}/files?modelId=${modelId}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const prepareToUploadS3Object = async (modelId, id, fileName) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors/config/${id}/upload?modelId=${modelId}&fileName=${encodeURIComponent(fileName)}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const deleteS3Object = async (modelId, id, fileName) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors/config/${id}/files?modelId=${modelId}&fileName=${encodeURIComponent(fileName)}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const deleteAiModelConnectorConfig = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aimodelconnectors/config/${id}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion
