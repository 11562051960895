import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import SearchServiceUser from 'src/components/pages/Teacket/Components/ServiceUserPicker';
import PropertySection from 'src/components/layout/PropertySection';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import { Alert, Button, Modal, Skeleton, Space, message } from 'antd';
import { postTicketMember, getTicketMembers, deleteTicketMember } from 'src/api/teacket';
import TicketMembersItem from './TicketMembersItem';
import { IoPersonAdd } from 'react-icons/io5';
import NoData from 'src/components/layout/NoData';
import useUserInfo from 'src/hooks/useUserInfo';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';
import { hasMasterAdminRights, hasSuperuserRights, hasSupervisorRights } from '../Misc/misc';
import { uuid } from 'short-uuid';

//import PropTypes from 'prop-types';

const TicketMembers = () => {
  const { currentTicket, getCurrentTicketService, currentTicketMembers, setCurrentTicketMembers, determineIsWidgetReadOnly, setTicketAfterUpdateSync } = useContext(TeacketContext);
  const { executeAsyncProcess, isBusy } = useAsyncProcesses();
  const { id: userId, permissions: userPermissions } = useUserInfo();
  const [messageApi, contextHolder] = message.useMessage();

  const [currentTicketService, setCurrentTicketService] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [addAsOpen, setAddAsOpen] = useState(false);

  //const userInfo = useUserInfo();

  //#region "USE EFFECTS"

  useEffect(() => {
    if (!currentTicket) return;
    const cts = getCurrentTicketService();
    console.log('currentTicketService?.props?.roleList:', cts?.props?.roleList);
    // console.log('currentTicketService:', cts);
    setCurrentTicketService(cts);
  }, [currentTicket]);

  // useEffect(() => {
  //   console.log('Current Ticket Members:', currentTicketMembers);
  // }, [currentTicketMembers]);

  useEffect(() => {
    if (!addAsOpen) setSelectedUser(null);
  }, [addAsOpen]);

  //#endregion

  //#region "MISC"

  const loadData = async (refreshNotifications) => {
    console.log('[TicketMembers] loadData() (getTicketMembers)', refreshNotifications);
    await executeAsyncProcess(async () => {
      try {
        const result = await getTicketMembers(currentTicket.id);
        setCurrentTicketMembers(result);
        //if (refreshNotifications) updateLiveNotifications(result); // update live notifications for current user (if self assigned)
      } catch (error) {
        console.error(error);
      }
    });
  };

  //#endregion

  //#region "EVENT HANDLERS"

  const updateTicketListMembers = (m) => {
    setTicketAfterUpdateSync(true);
  };

  const handleOnUserSelect = (user) => {
    console.log('handleOnUserSelect()', user);
    setSelectedUser(user);
  };

  const handleAddAs = async (role) => {
    console.log('handleAddAs()', role);

    await executeAsyncProcess(async () => {
      try {
        const newMember = await postTicketMember(currentTicket.id, selectedUser.user_id, role);
        console.log('newMember:', newMember);
        const m = true === notEmptyArray(currentTicketMembers) ? [...currentTicketMembers, newMember] : [newMember];
        setCurrentTicketMembers(m);
        //updateLiveNotifications(m); // update live notifications for current user (if self assigned)
        setAddAsOpen(false);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'People successfully updated',
          duration: 3,
        });

        updateTicketListMembers(m);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleAddAsCancel = () => setAddAsOpen(false);

  const handleOnRemove = async (user_id) => {
    console.log('handleOnRemove()', user_id);
    await executeAsyncProcess(async () => {
      try {
        await deleteTicketMember(currentTicket.id, user_id);
        const m = currentTicketMembers.filter((x) => x.user_id !== user_id);
        setCurrentTicketMembers(m);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'People successfully updated',
          duration: 3,
        });
        //updateLiveNotifications(m); // update live notifications for current user (if self assigned)
        updateTicketListMembers(m);
      } catch (error) {
        console.error(error);
      }
    });
  };

  //#endregion

  const handleOnWsMessageReceived = async (event) => {
    try {
      console.log('[TicketMembers] TICKET_MEMBERS_ADDED or TICKET_MEMBERS_REMOVED', event);
      await loadData(true);

      if (event?.action === 'TICKET_MEMBERS_ADDED') {
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: `User added (${event?.data?.user_name})`,
          duration: 3,
        });

        return;
      } else {
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'People updated (user removed)',
          duration: 3,
        });
      }

      // Reload Files
    } catch (error) {
      console.error(error);
    }
  };

  const canManageMembers = () => {
    // Admins can always manage members
    if (notEmptyArray(userPermissions) && true === hasMasterAdminRights(userPermissions)) {
      // console.log('User is an admin can always manage members');
      return true;
    }

    // If the widget is read only, nobody can manage members
    if (true === determineIsWidgetReadOnly('People')) {
      // console.log('Widget is read-only, nobody can manage members');
      return false;
    }

    // Superusers can always manage members
    if (notEmptyArray(userPermissions) && true === hasSuperuserRights(userPermissions)) {
      // console.log('User is a superuser can always manage members');
      return true;
    }

    // If the ticket has no members, nobody can manage members
    if (true === notArrayOrEmpty(currentTicketMembers)) {
      // console.log('Ticket has no members, nobody can manage members');
      return false;
    }

    // If the ticket has members, only people with a role flag can manage members
    const member = currentTicketMembers.find((x) => x.user_id === userId);
    if (member && currentTicketService?.props?.roleList?.find((x) => x.role === member.role)?.canManageMembers) {
      // console.log('User has a role flag that can manage members:', member.role);
      return true;
    }

    return false;
  };

  return (
    <>
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['TICKET_MEMBERS_ADDED', 'TICKET_MEMBERS_REMOVED']}></GlobalWsMessenger>
      {contextHolder}
      <Modal title='Add' open={addAsOpen} onCancel={handleAddAsCancel} footer={[]} forceRender={true}>
        <div className='text-left'>
          {addAsOpen && (
            <div className={isBusy && 'disabled'}>
              <SearchServiceUser serviceId={currentTicket.service_id} onSelect={handleOnUserSelect} />
              {selectedUser && true === notEmptyArray(currentTicketService.props.roleList) && (
                <>
                  {notEmptyArray(currentTicketMembers) && currentTicketMembers.some((x) => x.user_id === selectedUser.user_id) ? (
                    <div className='mt-4'>
                      <Alert message='The person is already a member of this ticket' type='warning' showIcon />
                    </div>
                  ) : (
                    <div className='mt-6'>
                      <div className='text-lg font-medium text-gray-700 dark:text-gray-50 mb-4 text-center'>
                        Select a role for <span className='text-blue-600 dark:text-blue-400'>{selectedUser.user_name}</span>
                      </div>
                      <div className='grid grid-cols-2 gap-3'>
                        {currentTicketService.props.roleList
                          .filter((role) => {
                            // If current user is a master admin, show all roles
                            if (hasMasterAdminRights(userPermissions) || hasSuperuserRights(userPermissions) || hasSupervisorRights(userPermissions)) {
                              // console.info('Current user is a master admin and can select any role');
                              return true;
                            }

                            // If current user is a super user, show all roles
                            if (hasSuperuserRights(userPermissions)) {
                              // console.info('Current user is a green teacket super user and can select any role');
                              return true;
                            }

                            // If current user is a supervisor, show all roles
                            if (hasSupervisorRights(userPermissions)) {
                              // console.info('Current user is a green teacket supervisor and can select any role');
                              return true;
                            }

                            // Get current user's role
                            const currentUserMember = currentTicketMembers?.find((m) => m.user_id === userId);
                            const currentUserRole = currentUserMember?.role; // If user is master admin or superuser, show all roles

                            // If the current user does not have a role, don't show any roles
                            if (!currentUserRole) {
                              // console.warn('Current user has no roles in this ticket');
                              return false;
                            }

                            // If the current user has a role that can select this role, show it
                            // const currentUserRoleDefinition = currentTicketService.props.roleList.find((r) => r.role === currentUserRole);
                            // console.log('currentUserRoleDefinition:', currentUserRoleDefinition);
                            if (role.rolesRequired?.length > 0 && role.rolesRequired.split(',').includes(currentUserRole)) {
                              console.info(`Current user has ${currentUserRole} and can select role ${role.role}`);
                              return true;
                            } else if (!role.rolesRequired || role.rolesRequired?.length === 0) {
                              console.info(`Current role ${role.role} does not require any specific roles to be assigned`);
                              return true;
                            }

                            return false;
                          })
                          .sort((a, b) => {
                            const nameA = a.displayName || a.role;
                            const nameB = b.displayName || b.role;
                            return nameA.localeCompare(nameB);
                          })
                          .map((role) => {
                            const isDisabled = role.maxItems && currentTicketMembers?.filter((x) => x.role === role.role).length >= role.maxItems;

                            return (
                              <Button
                                key={role.role}
                                className={`h-auto py-2.5 px-4 flex flex-col items-center justify-center transition-all duration-200 border-2 rounded-lg
                                          ${isDisabled ? 'opacity-50 cursor-not-allowed bg-gray-50 dark:bg-gray-500 border-gray-200 dark:border-gray-700' : 'bg-white dark:bg-genesys-gray-950 dark:text-genesys-gray-100 hover:bg-genesys-blue-50 border-genesys-gray-200 hover:border-genesys-blue-400 text-genesys-gray-700 hover:text-genesys-blue-600'}
                                          ${isDisabled ? '' : 'transform hover:-translate-y-0.5 hover:shadow-sm'}
                                `}
                                disabled={isDisabled}
                                onClick={() => handleAddAs(role.role, role.displayName)}>
                                <span className='text-sm font-medium dark:text-genesys-gray-300'>{`${role.displayName || role.role}${role.maxItems ? ` (${currentTicketMembers?.filter((x) => x.role === role.role).length}/${role.maxItems})` : ''}`}</span>
                              </Button>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </Modal>
      <div className='flex flex-col gap-2'>
        <PropertySection>
          <div className='flex flex-col gap-2 mx-4 my-2 justify-center'>
            {null === currentTicketMembers && (
              <div>
                <Skeleton active avatar paragraph={{ rows: 0 }} />
                <Skeleton active avatar paragraph={{ rows: 0 }} />
                <Skeleton active avatar paragraph={{ rows: 0 }} />
              </div>
            )}
            {currentTicketMembers?.length === 0 && <NoData label='Nobody is assigned yet' />}
            {currentTicketMembers?.length > 0 && currentTicketMembers.map((member, i) => <TicketMembersItem key={i} member={member} onRemove={handleOnRemove} canManageMembers={canManageMembers()} />)}
          </div>
        </PropertySection>
        {true === canManageMembers() && (
          <Button type='text' onClick={() => setAddAsOpen(true)}>
            <Space className='btn'>
              <IoPersonAdd />
              Add
            </Space>
          </Button>
        )}
      </div>
    </>
  );
};

TicketMembers.propTypes = {};

export default TicketMembers;
