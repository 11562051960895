import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';

//#region Documents

let ac = new AbortController();

// Abort the fetch
export const abortRequest = () => {
  ac.abort();
};

export const createDocument = async (path) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/documents`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ path }),
    })
  );
};

export const getDocuments = async () => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/documents`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getDocument = async (id) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/documents/${id}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const deleteDocument = async (id) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/documents/${id}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getResponse = async (question, conversationId, modelId, setMessage, setError, guessTitle, isChatWidget = false, images, copilot_v2) => {
  if (!setMessage) throw new Error('setMessage not specified for getResponse');
  ac = new AbortController();

  try {
    const headers = await getAccessHeaders();

    console.log('Fetching response from:', ENV.name, modelId);
    const response = await fetch(isChatWidget ? ENV.chatWidgetStreamingResponseUrl + `${images.length > 0 ? '-image' : ''}` : ENV.streamingResponseUrl + `${images.length > 0 ? '-image' : ''}`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        question,
        conversationId,
        guessTitle: guessTitle,
        modelId,
        images,
        copilot_v2,
      }),
      signal: ac.signal,
    });

    console.log('Response:', response);

    // Get the readable stream from the response body
    const reader = response.body?.getReader();

    // Define a function to read each chunk
    const readChunk = async () => {
      await reader
        .read()
        .then(async ({ value, done }) => {
          // Check if the stream is done

          if (done) {
            console.log('Stream finished, calling setFinished');
            //setFinished(true);
          } else {
            // Convert the chunk value to a string
            const chunkString = new TextDecoder().decode(value);
            //console.log('==> Got message:', chunkString);
            setMessage(chunkString);

            // Read the next chunk
            await readChunk();
          }
        })
        .catch((error) => {
          if (error.message === 'Fetch is aborted') {
            console.log('Request cancelled.');
          } else console.error(error);
        });
    };
    // Start reading the first chunk
    await readChunk();
  } catch (error) {
    console.error(error.message);
    if (error.message === 'Fetch is aborted') {
      console.log('Request cancelled.');
      return;
    }
    console.error(error);
    setError(error.message);
  }
};

export const getSimpleResponse = async (prompt, question) => {
  try {
    console.log('getSimpleResponse()');
    const headers = await getAccessHeaders();

    const response = await fetch(ENV.simpleResponseUrl, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        prompt,
        question,
      }),
    });

    if (response.ok) {
      const responseBody = await response.text();
      return responseBody;
    } else {
      console.error('[getSimpleResponse] Error:', response.statusText);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error(error);
  }
};

// };

//#endregion

//#region Conversations

export const createConversation = async (userId, body) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/conversations/${userId}`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const createChatWidgetConversation = async (body) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/chat-widget/conversations`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const getConversations = async (userId) => {
  const headers = await getAccessHeaders();

  let response = await fetch(`${ENV.apiUrl}/securityqa/conversations/users/${userId}`, {
    method: 'GET',
    headers: { ...headers, 'Content-Type': 'application/json' },
  });

  if (response.status === 404) {
    return [];
  }

  return handleResponse(response);
};

export const getConversation = async (id) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/conversations/${id}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const patchConversation = async (id, content) => {
  console.log('patchConversation', id, content);
  const headers = await getAccessHeaders();

  content.updated_at = new Date().toISOString();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/conversations/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ content }),
    })
  );
};

export const patchConversationAddContent = async (id, content) => {
  console.log('patchConversationAddContent', id, content);
  const headers = await getAccessHeaders();

  content.created_at = new Date().toISOString();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/conversations/${id}/messages`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ content }),
    })
  );
};

export const deleteConversation = async (id) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/conversations/${id}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const deleteUserConversations = async (userId) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/conversations/users/${userId}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region History

export const createHistoryEntry = async (body) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/chathistory`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const getHistoryEntries = async () => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/chathistory`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getHistoryEntriesForConversation = async (conversationId) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/chathistory/conversations/${conversationId}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getHistoryEntry = async (id) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/chathistory/${id}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const updateHistoryEntry = async (id, body) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/chathistory/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const deleteHistoryEntry = async (id) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/chathistory/${id}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region Evaluations

export const startEvaluationJob = async (modelId) => {
  const headers = await getAccessHeaders();
  let botUrl;
  if (ENV.name === 'awsprod') botUrl = ENV.streamingResponseUrl;
  else botUrl = 'https://resplb.dev.spc.genesys.com/response';

  console.log('startEvaluationJob botUrl:', botUrl);

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/evaluations/start`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ streamingResponseUrl: botUrl, pass_number: '1', modelId }),
    })
  );
};

export const stopEvaluationJob = async (taskArn) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/evaluations/stop`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ taskArn: taskArn }),
    })
  );
};

export const getEvaluations = async (model_id) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/evaluations${model_id ? `?model_id=${model_id}` : ''}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const isRunning = async (taskName) => {
  const headers = await getAccessHeaders();
  let taskPrefix;
  if (ENV.name === 'awsprod') taskPrefix = 'family:prod-';
  else taskPrefix = 'family:dev-';
  console.log('isRunning()', taskPrefix, taskName);
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/evaluations/status?taskName=${taskPrefix}${taskName}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region Vector Embeddings

export const getVectorEmbeddings = async () => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/vectorembeddings`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region Feedback

export const postCopilotFeedback = async (body) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/feedback`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchCopilotFeedback = async (id, body) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/feedback/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const getCopilotFeedbacks = async () => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/feedback`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const countCopilotFeedbacks = async () => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/feedback/count`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const deleteCopilotFeedback = async (id) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/feedback/${id}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region Copilot Usage

export const getCopilotUsageData = async () => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/usagedata`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getCopilotUsageData2 = async (groupBy, days) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/usagedata2?groupBy=${encodeURIComponent(groupBy)}&days=${days}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion
