import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { notArrayOrEmpty } from 'src/misc/Misc';
import { Checkbox } from 'antd';

const QuestionAddModalItem = ({ questionAvailable, onSelect }) => {
  const [translation, setTranslation] = useState(null);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (true === notArrayOrEmpty(questionAvailable?.items)) return;
    let t = questionAvailable.items.find((t) => t.language === 'en') ?? questionAvailable.items[0]; // take any if there is no English one
    setTranslation(t);
  }, [questionAvailable]);

  useEffect(() => {
    if (!onSelect) return;
    onSelect({ id: questionAvailable.question_id, selected });
  }, [selected]);

  return (
    <div
      className='flex flex-row gap-4 cursor-pointer hover:bg-sky-100 dark:hover:bg-sky-900 px-2 py-1 rounded-md'
      onClick={() => {
        setSelected(!selected);
      }}
    >
      <div>
        <Checkbox
          checked={selected}
          onChange={() => {
            setSelected(!selected);
          }}
        />
      </div>
      <div className={!selected && 'opacity-50'}>
        <div className='text-base font-bold flex flex-row items-center gap-2'>
          {translation?.title}
          <span className='font-light opacity-60 text-xs'>|</span>
          <span className='font-light opacity-60 text-xs'>{translation?.type}</span>
        </div>
        <div className='text-xs font-light'>{translation?.question}</div>
      </div>
    </div>
  );
};

QuestionAddModalItem.propTypes = {
  questionAvailable: PropTypes.object,
  onSelect: PropTypes.func,
};

export default QuestionAddModalItem;
