import React, { useState } from 'react';
import { Select, Spin } from 'antd';
import { searchUsers } from 'src/api/users';
import PropTypes from 'prop-types';
import UserImage from 'src/components/layout/UserImage';
import { isRequestCancelled } from 'src/misc/Misc';
import { MdOutlineEmail } from 'react-icons/md';
import { LuBuilding } from 'react-icons/lu';
import { FaHashtag } from 'react-icons/fa';
import Space2 from 'src/components/layout/Space2';

const { Option } = Select;

const UserPicker = ({ width, onPick }) => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClear = async () => {
    console.log('handleClear');
    setUserList([]);
    onPick(null);
  };

  const handleSearchForTenants = async (v) => {
    setUserList([]);
    if (v.length < 3) return;
    try {
      setLoading(true);
      let resp = await searchUsers(v);
      // console.log('Tenants:', resp);
      if (resp) {
        setUserList(resp);
      }
      setLoading(false);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnSelect = (v) => {
    console.log('UserPicker onSelect:', v);
    const user = userList.find((u) => u.user_id === v);
    onPick(user);
  };

  return (
    <Select
      showSearch
      allowClear
      placeholder='type to search user...'
      notFoundContent={loading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
      filterOption={false}
      onSearch={handleSearchForTenants}
      onClear={handleClear}
      onSelect={handleOnSelect}
      style={{ width }}
      autoComplete='off'
      optionRender={({ value }) => {
        const item = userList.find((x) => x.user_id === value);
        if (!item) return null;
        return (
          <div className='flex flex-row gap-2 my-1'>
            <div>
              <UserImage image={item.user_pic} size={32} />
            </div>
            <div className='flex flex-col'>
              <div className='font-bold'>{item.user_name}</div>
              <div className='text-xs font-light opacity-60 overflow-hidden'>
                <Space2>
                  <FaHashtag />
                  {item.user_id}
                </Space2>
              </div>
              <div className='text-xs font-light opacity-60 overflow-hidden'>
                <Space2>
                  <MdOutlineEmail />
                  {item.user_email}
                </Space2>
              </div>
              <div className='text-xs font-light opacity-60 overflow-hidden'>
                <Space2>
                  <LuBuilding />
                  {item.tenant_name}
                </Space2>
              </div>
            </div>
          </div>
        );
      }}
    >
      {userList.map((d) => (
        <Option key={d.user_id} disabled={d.user_disabled}>
          {d.user_name}
        </Option>
      ))}
    </Select>
  );
};

UserPicker.propTypes = {
  width: PropTypes.string,
  onPick: PropTypes.func,
};

UserPicker.defaultProps = {
  width: '300px',
};

export default UserPicker;
