import React from 'react';
import PropTypes from 'prop-types';

const BoxType3 = ({ title, children, footer, onClick }) => {
  return (
    <div className={`w-[333px] bg-sky-50 rounded-2xl p-4 text-sky-950 border border-solid border-zinc-200 dark:text-sky-50 dark:bg-black dark:border-zinc-800 ${onClick && 'cursor-pointer'}`} onClick={() => onClick && onClick()}>
      <div className='h-full flex flex-col'>
        <div className='text-2xl font-bold mb-4 overflow-hidden break-words'>{title}</div>
        <div className='flex-auto'>{children}</div>
        <div className='mt-4'>{footer}</div>
      </div>
    </div>
  );
};

BoxType3.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  footer: PropTypes.node,
  onClick: PropTypes.func,
};

export default BoxType3;
