import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Card } from 'antd';
import useTheme from 'src/hooks/useTheme';

const DocumentsCheckboxGroup = ({ options, value, onChange }) => {
  const { theme } = useTheme();

  useEffect(() => {
    console.log('selected documents:', value);
  }, [value]);

  const localOnChange = (e) => {
    let newValue = value ? [...value] : [];
    if (e.target.checked) {
      if (!newValue?.map((x) => x.name)?.includes(e.target.id)) {
        newValue.push(options.find((x) => x.name === e.target.id));
      }
    } else {
      newValue = newValue.filter((x) => x.name !== e.target.id);
    }

    // setLocalValue(newValue);
    onChange(newValue);
  };

  return (
    <div className='flex flex-col gap-4'>
      {options?.map((option, index) => (
        <Card
          styles={{ header: { backgroundColor: theme.backgroundLight } }}
          size='small'
          title={
            <div>
              <Checkbox key={index} id={option.name} checked={value?.map((x) => x.name)?.includes(option.name)} onChange={localOnChange}>
                <div className='break-words'>{option.name}</div>
              </Checkbox>
            </div>
          }
        >
          {option.desc ? (
            <div dangerouslySetInnerHTML={{ __html: option.desc }}></div>
          ) : (
            <div>
              <span className='opacity-60'>no description</span>
            </div>
          )}
        </Card>
      ))}
    </div>
  );
};

DocumentsCheckboxGroup.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DocumentsCheckboxGroup;
