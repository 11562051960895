import React, { Component } from 'react';
import FsiReportContext from './FsiReportContext';
import { notArrayOrEmpty } from 'src/misc/Misc';

export default class FsiReportProvider extends Component {
  state = {
    // PROJECTS
    projectListLoading: false,
    projectQuery: null,
    projectList: null,
    // CREATE NEW
    createNewSelectedAccount: null,
    createNewRegionList: null,
    createNewUniqueRegions: [],
    createNewLanguages: null,
    // CURRENT PROJECT
    currentProject: null,
    currentProjectReportList: null,
    currentProjectQuestions: null,
    currentProjectQuestionsDepricated: null,
    externalAnswers: null,
    exeSummaries: null,

    // Constants
    AWS_REGION_NAMES: {
      'ap-south-1': 'Asia Pacific (Mumbai)',
      'ap-northeast-3': 'Asia Pacific (Osaka)',
      'ap-northeast-2': 'Asia Pacific (Seoul)',
      'ap-southeast-2': 'Asia Pacific (Sydney)',
      'ap-northeast-1': 'Asia Pacific (Tokyo)',
      'ca-central-1': 'Americas (Canada)',
      'eu-central-1': 'EMEA (Frankfurt)',
      'eu-west-1': 'EMEA (Dublin)',
      'eu-west-2': 'EMEA (London)',
      'eu-central-2': 'EMEA (Zurich)',
      'me-central-1': 'EMEA (UAE)',
      'sa-east-1': 'Americas (Sao Paulo)',
      'us-east-1': 'Americas (US East)',
      'us-east-2': 'FedRAMP',
      'us-west-2': 'Americas (US West)',
      'af-south-1': 'Africa (Cape Town)',
      'ap-east-1': 'Asia Pacific (Hong Kong)',
      'ap-southeast-3': 'Asia Pacific (Jakarta)',
      'ap-southeast-1': 'Asia Pacific (Singapore)',
      'eu-west-3': 'EMEA (Paris)',
    },
  };

  render() {
    return (
      <FsiReportContext.Provider
        value={{
          ...this.state,
          // PROJECT LIST
          setProjectListLoading: (projectListLoading) => this.setState({ projectListLoading }),
          setProjectQuery: (projectQuery) => this.setState({ projectQuery }),
          setProjectList: (projectList) => this.setState({ projectList }),
          // CREATE NEW
          setCreateNewSelectedAccount: (createNewSelectedAccount) => this.setState({ createNewSelectedAccount }),
          setCreateNewRegionList: (createNewRegionList) => {
            if (!createNewRegionList) {
              this.setState({ createNewRegionList: null });
              return;
            }
            const uniqueRegions = createNewRegionList.reduce((acc, x) => {
              if (!acc.find((y) => y.AWS_REGION__C === x.AWS_REGION__C)) {
                acc.push(x);
              }
              return acc;
            }, []);
            this.setState({ createNewUniqueRegions: uniqueRegions.map((x) => x.AWS_REGION__C) });
            this.setState({ createNewRegionList });
          },
          setCreateNewUniqueRegions: (createNewUniqueRegions) => this.setState({ createNewUniqueRegions }),
          setCreateNewLanguages: (createNewLanguages) => this.setState({ createNewLanguages }),
          resetCreateNew: () =>
            this.setState({
              createNewSelectedAccount: null,
              createNewRegionList: null,
              createNewUniqueRegions: null,
              createNewLanguages: null,
            }),
          // CURRENT PROJECT
          setCurrentProject: (currentProject) => this.setState({ currentProject }),
          setCurrentProjectReportList: (currentProjectReportList) => this.setState({ currentProjectReportList }),
          patchCurrentProjectProps: (changes) => {
            const currentProject = { ...this.state.currentProject, props: { ...this.state.currentProject.props, ...changes } };
            this.setState({ currentProject });
          },
          setCurrentProjectQuestionsDepricated: (currentProjectQuestionsDepricated) => this.setState({ currentProjectQuestionsDepricated }),
          resetCurrentProject: () => this.setState({ currentProject: null, currentProjectReportList: null, currentProjectQuestions: null, externalAnswers: null, currentProjectQuestionsDepricated: null, ExecutionSummary: null }),
          setExternalAnswer: (newAnswer) => {
            this.setState((prevState) => ({
              externalAnswers: {
                ...prevState.externalAnswers,
                ...newAnswer,
              },
            }));
          },
          // ExeSummaries is an object with language keys and array of summaries
          // Example: { 'en': 'summary', 'es': 'resumen' }
          setExeSummaries: (exeSummaries) => {
            if (null === exeSummaries) {
              this.setState({ exeSummaries: null });
              return;
            }
            // add new summaries for the new lang to the existing ones
            this.setState((prevState) => ({
              exeSummaries: {
                ...prevState.exeSummaries,
                ...exeSummaries,
              },
            }));
          },
          // CURRENT PROJECT QUESTIONS
          setCurrentProjectQuestions: (currentProjectQuestions) => this.setState({ currentProjectQuestions }),
          patchCurrentProjectQuestion: (questionId, changes) => {
            const currentProjectQuestions = this.state.currentProjectQuestions.map((q) => (q.question_id === questionId ? { ...q, ...changes } : q));
            this.setState({ currentProjectQuestions });
          },
          patchCurrentProjectQuestions: (questions) => {
            const currentProjectQuestions = [...this.state.currentProjectQuestions];
            if (true === notArrayOrEmpty(questions)) return;
            if (true === notArrayOrEmpty(currentProjectQuestions)) return;
            for (let index in currentProjectQuestions) {
              const q = questions.find((x) => x.question_id === currentProjectQuestions[index].question_id);
              if (!q) continue;
              currentProjectQuestions[index] = { ...currentProjectQuestions[index], ...q };
            }
            this.setState({ currentProjectQuestions });
          },
          deleteCurrentProjectQuestion: (questionId) => {
            const currentProjectQuestions = this.state.currentProjectQuestions.filter((q) => q.question_id !== questionId);
            this.setState({ currentProjectQuestions });
          },
          patchCurrentProjectQuestionTranslation: (questionId, language, changes) => {
            const currentProjectQuestions = this.state.currentProjectQuestions.map((q) => {
              if (q.question_id !== questionId) return q;
              const translations = q.translations.map((t) => (t.language === language ? { ...t, ...changes } : t));
              return { ...q, translations };
            });
            this.setState({ currentProjectQuestions });
          },
          // OTHERS

          // addQuestionToCurrentProject: (question) => {
          //   const currentProject = { ...this.state.currentProject, questions: [...this.state.currentProject.questions, question] };
          //   this.setState({ currentProject });
          // },
          // removeQuestionFromCurrentProject: (questionId) => {
          //   const currentProject = { ...this.state.currentProject, questions: this.state.currentProject.questions.filter((q) => q.id !== questionId) };
          //   this.setState({ currentProject });
          // },
          // patchCurrentProjectQuestion: (question) => {
          //   const currentProject = { ...this.state.currentProject, questions: this.state.currentProject.questions.map((q) => (q.id === question.id ? question : q)) };
          //   this.setState({ currentProject });
          // },
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </FsiReportContext.Provider>
    );
  }
}
