import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import { getReportsRolesAssignments } from 'src/api/accesscontrol';
import CondensedTable from 'src/components/layout/CondensedTable';
import { Tooltip } from 'antd';
import Space2 from 'src/components/layout/Space2';

const PermissionsExplorerRolesAssignments = ({ role }) => {
  const columns = [
    {
      title: 'ID',
      key: 'assignment_id',
      render: (record) => <>{record?.assignment_id}</>,
    },
    {
      title: 'Object Type',
      key: 'object_type',
      render: (record) => <>{record?.object_type}</>,
    },

    {
      title: 'User',
      key: 'user',
      render: (record) => (
        <div className='flex flex-row'>
          <Tooltip title={`Click to open: ${record.user_name}`} placement='top'>
            <div
              className='cursor-pointer hover:scale-105 transition-all'
              onClick={() => {
                window.open(`/master-admin/users?userId=${record.user_id}`, '_blank');
              }}
            >
              <div>
                <span className='font-bold text-[0.8rem]'>{record?.user_name}</span>
              </div>
              <div className='text-[0.7rem] opacity-30' style={{ lineHeight: '0.7rem' }}>
                {record?.user_id}
              </div>
            </div>
          </Tooltip>
        </div>
      ),
    },

    {
      title: "User's Tenant",
      key: 'user_tenant_name',
      render: (record) => (
        <div className='flex flex-row'>
          <Tooltip title={`Click to open: ${record.user_tenant_name}`} placement='top'>
            <div
              className='cursor-pointer hover:scale-105 transition-all'
              onClick={() => {
                window.open(`/master-admin/tenants?tenantId=${record.user_tenant_id}`, '_blank');
              }}
            >
              <div>
                <span className='font-bold text-[0.8rem]'>{record?.user_tenant_name}</span>
              </div>
              <div className='text-[0.7rem] opacity-30' style={{ lineHeight: '0.7rem' }}>
                {record?.user_tenant_id}
              </div>
            </div>
          </Tooltip>
        </div>
      ),
    },

    {
      title: 'Tenant',
      key: 'tenant',
      render: (record) => (
        <div className='flex flex-row'>
          <Tooltip title={`Click to open: ${record.tenant_name}`} placement='top'>
            <div
              className='cursor-pointer hover:scale-105 transition-all'
              onClick={() => {
                window.open(`/master-admin/tenants?tenantId=${record.tenant_id}`, '_blank');
              }}
            >
              <div className='font-bold text-[0.8rem]'>{record?.tenant_name}</div>
              <div className='text-[0.7rem] opacity-30' style={{ lineHeight: '0.7rem' }}>
                {record?.tenant_id}
              </div>
            </div>
          </Tooltip>
        </div>
      ),
    },

    {
      title: 'Generic ID',
      key: 'generic_id',
      render: (record) => <div className='text-[0.8rem]'>{record?.generic_id}</div>,
    },

    {
      title: 'Generic Name',
      key: 'generic_name',
      render: (record) => <div className='text-[0.8rem]'>{record?.generic_name}</div>,
    },

    {
      title: 'props',
      key: 'props',
      render: (record) => <div className='text-[0.8rem]'>{JSON.stringify(record?.props)}</div>,
    },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    console.log('loadData');
    setLoading(true);
    try {
      const resp = await getReportsRolesAssignments(role);
      setData(resp);
    } catch {
      console.error('Error loading data');
      setData(null);
    }
    setLoading(false);
  };

  if (loading) return <SkeletonTable />;

  return (
    <CondensedTable
      //showHeader={false}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={'assignment_id'}
    />
  );
};

PermissionsExplorerRolesAssignments.propTypes = {
  role: PropTypes.string,
};

export default PermissionsExplorerRolesAssignments;
