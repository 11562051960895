import React, { useContext /*, useState */ } from 'react';
//import { Table, Tag, Tooltip, Space } from 'antd';
//import UserImage from 'src/components/layout/UserImage';

//import { textSorter, dateTimeSorter } from 'src/misc/Misc';
//import { getDemoTypeIcon, getDemoTypeLabel, requestTypeList, getStatusLabel, getStatusColor, getColor } from '../Misc/Dictionary';

//import dayjs from 'dayjs';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';
//import * as dayjs from 'dayjs';

//import UserImage from 'src/components/layout/UserImage';
import JobType1 from './JobType1';
import JobType2 from './JobType2';

//import PropTypes from 'prop-types';

const JobDetails = () => {
  const { job } = useContext(DataSubjectRequestsContext);

  return <>{job?.type === '1' ? <JobType1 /> : <JobType2 />}</>;
};

JobDetails.propTypes = {};

export default JobDetails;
