import React from 'react';
import { Modal, Button, Space, Form, Input, notification } from 'antd';
import PropTypes from 'prop-types';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const UserResetPassword = ({ open, onClose, onSubmit }) => {
  const handleOnFinish = (values) => {
    console.log('Success:', values);
    if (onSubmit) onSubmit(values);
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: 'Error',
      description: <>Provided data is invalid</>,
      duration: 5,
    });
  };

  const handleOnReset = () => {
    //setModified(false);
  };

  return (
    open && (
      <Modal
        open={open}
        title='Reset password for:'
        footer={null}
        onCancel={() => {
          if (onClose) onClose();
        }}>
        <Form name='passwordForm' style={{ maxWidth: 600 }} layout='vertical' initialValues={{}} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} onReset={handleOnReset} autoComplete='off'>
          <Form.Item name='password' rules={[{ required: true, message: 'Password is not provided' }]}>
            <Input.Password placeholder='enter password' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    )
  );
};

UserResetPassword.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

UserResetPassword.defaultProps = {
  open: false,
};

export default UserResetPassword;
