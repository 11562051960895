import React, { useContext, useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { Button } from 'antd';
import { TbFilterPlus, TbFilterMinus } from 'react-icons/tb';
//import PropTypes from 'prop-types';
import AuditViewerContext from 'src/context/AuditViewerContext';
import { notArrayOrEmpty } from 'src/misc/Misc';

const FilterButton = () => {
  const { serviceName, entityType, action, currentUserFilterConfig, addCurrentUserFilterList, removeCurrentUserFilterList } = useContext(AuditViewerContext);
  const [filterItemExists, setFilterExists] = useState(null);

  useEffect(() => {
    if (true === notArrayOrEmpty(currentUserFilterConfig)) {
      setFilterExists(false);
      return;
    }
    setFilterExists(currentUserFilterConfig.some((x) => x.serviceName === serviceName && x.entityType === entityType && x.action === action));
  }, [currentUserFilterConfig, serviceName, entityType, action]);

  const handleAddFilter = () => {
    addCurrentUserFilterList({ serviceName, entityType, action });
  };

  const handleRemoveFilter = () => {
    removeCurrentUserFilterList({ serviceName, entityType, action });
  };

  if (!serviceName) return null;
  if (null === filterItemExists) return null;

  return (
    <>
      {filterItemExists ? (
        <Tooltip title='Delete filter selection'>
          <Button type='text' onClick={handleRemoveFilter}>
            <TbFilterMinus className='text-xl text-red-700 dark:text-red-300' />
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title='Save filter selection'>
          <Button type='text' onClick={handleAddFilter}>
            <TbFilterPlus className='text-xl text-green-700 dark:text-green-300' />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

FilterButton.propTypes = {};

FilterButton.defaultProps = {};

export default FilterButton;
