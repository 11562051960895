import React, { useEffect, useState } from 'react';
//import PropTypes from 'prop-types';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import { getReportsPermissions } from 'src/api/accesscontrol';
import CondensedTable from 'src/components/layout/CondensedTable';
import PermissionsExplorerRoles from './PermissionsExplorerRoles';

const PermissionsExplorer = () => {
  const columns = [
    {
      title: 'Permission',
      key: 'permission',
      render: (record) => (
        <div>
          <div>{record?.permission}</div>
          <div className='text-[0.7rem] opacity-60' style={{ lineHeight: '0.7rem' }}>
            {record?.description}
          </div>
        </div>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    console.log('loadData');
    setLoading(true);
    try {
      const resp = await getReportsPermissions();
      setData(resp);
    } catch {
      console.error('Error loading data');
      setData(null);
    }
    setLoading(false);
  };

  if (loading) return <SkeletonTable />;

  return (
    <CondensedTable
      //showHeader={false}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={'permission'}
      expandable={{
        expandedRowRender: (record) => (
          <p style={{ margin: 0 }} className='pl-10'>
            <PermissionsExplorerRoles permission={record?.permission} />
          </p>
        ),
        // rowExpandable: () => true,
      }}
    />
  );
};

PermissionsExplorer.propTypes = {};

export default PermissionsExplorer;
