import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import SQAConversationContext from 'src/context/SQAConversationContext';

const Typewriter = ({ text, delay, conversationId }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const { clearAnimationFlag } = useContext(SQAConversationContext);

  // Typing logic goes here
  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      if (currentIndex === text.length - 1 && conversationId) {
        // animation finsihed, remove animate flag from the conversation object
        clearAnimationFlag(conversationId);
      }

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);

  return <span>{currentText}</span>;
};

Typewriter.propTypes = {
  text: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
  conversationId: PropTypes.number,
};

export default Typewriter;
