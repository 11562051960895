import React from 'react';
import ModalVideo2 from './ModalVideo2';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import CorporateColors from 'src/misc/CorporateColors';
//import PropTypes from 'prop-types';

const Hero2 = () => {
  return (
    <section className='relative mt-16 lg:mt-32'>
      {/* Illustration behind hero content */}
      <div className='relative max-w-6xl mx-auto px-4 sm:px-6 min-h-[100vh] lg:min-h-0'>
        <WhenDesktop>
          <img src='/images/landing/Genesys_Bowtie_529.png' className='w-full z-0' alt='Threads' />
        </WhenDesktop>

        <div className='absolute z-10 top-0 right-0 bottom-0 left-0 border-1 flex flex-col gap-8 items-center justify-center'>
          <div className='relative h-36' data-aos='zoom-y-out' data-aos-delay='800'>
            <img src='/images/landing/21743724_6505026.svg' alt='Modal video thumbnail' className='h-full' style={{ filter: `drop-shadow(0px 0px 15px ${CorporateColors.azure})` }} />
          </div>
          <div className='relative safari-backdrop-blur bg-genesys-arctic-100/70 dark:bg-genesys-arctic-800/70 rounded-2xl shadow-lg p-6 w-2/3' data-aos='zoom-y-out' data-aos-delay='200'>
            <div className='text-6xl lg:text-8xl tracking-tighter text-center'>
              Security, Privacy & Compliance <span className='bg-clip-text text-transparent bg-gradient-to-r from-genesys-navy-600 to-genesys-patina-600 dark:from-genesys-navy-400 dark:to-genesys-patina-400'>portal</span>
            </div>

            <div className='mt-4 text-xl tracking-wide font-light text-center'>Use our security & privacy tools directly from your Genesys Cloud CX organization, and get beautiful results forever.</div>
          </div>
          <div className='h-36 relative flex flex-col justify-center items-center' data-aos='zoom-y-out' data-aos-delay='1400'>
            <ModalVideo2 video='/videos/SPC - Introduction.mp4' videoWidth={1920} videoHeight={1080} />
          </div>
        </div>
      </div>
    </section>
  );
};

Hero2.propTypes = {};

export default Hero2;
