import React, { useState, useEffect, useContext } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';
import TitleBar from 'src/components/layout/TitleBar';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import { Space } from 'antd';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import JobsList from './Components/JobsList';
import JobDetails from './Components/JobDetails';
import Controls from './Components/Controls';
import AppTitle from './Components/AppTitle';
import { MdHistory } from 'react-icons/md';
import { BsPersonRaisedHand } from 'react-icons/bs';
import { IoMdPlay } from 'react-icons/io';
import { getJobs } from 'src/api/dataSubjectRequests';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';

const Dashboard = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { jobs, setJobs, setJob } = useContext(DataSubjectRequestsContext);

  const componentsGallery = [
    {
      id: 'App Title',
      col: 0,
      component: (
        <DraggableLayoutItem title={<Space className='btn'>GDPR Application</Space>} centered>
          <AppTitle />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.datasubjectrequests.view'],
    },
    {
      id: 'Control Panel',
      col: 0,
      component: (
        <DraggableLayoutItem
          title={
            <Space className='btn'>
              <IoMdPlay />
              Control Panel
            </Space>
          }
          centered
        >
          <Controls />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.datasubjectrequests.view'],
    },
    {
      id: 'Jobs List',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <Space className='btn'>
              <MdHistory />
              Jobs
            </Space>
          }
          centered
        >
          <JobsList />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.datasubjectrequests.view'],
    },
    {
      id: 'Details',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <Space className='btn'>
              <MdHistory />
              Details
            </Space>
          }
          centered
        >
          <JobDetails />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.datasubjectrequests.view'],
    },
  ];

  const [layoutItemsSelector, setLayoutItemsSelector] = useState(null);

  useEffect(() => {
    setJobs([]);
    setJob(null);
    loadData();
  }, []);

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        const resp = await getJobs();
        console.log('Loaded Jobs', resp);
        setJobs(resp?.jobs);
      } catch (error) {
        console.log(error);
      }
    });
  };

  if (!jobs) return <FullScreenSpin title='Please wait' subtitle='Loading data' />;

  return (
    <>
      <TitleBar
        title={
          <Space className='btn'>
            <BsPersonRaisedHand />
            Data Subject Requests
          </Space>
        }
      >
        {layoutItemsSelector}
      </TitleBar>

      <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={2} mainColumnIndex={1} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutSecurityAdvisor} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutSecurityAdvisorHidden} />
    </>
  );
};

// HomeMasterAdmin.propTypes = {};

export default Dashboard;
