import { BsRobot } from 'react-icons/bs';
import { /*MdDashboard,*/ MdSecurity } from 'react-icons/md';
import { LuFingerprint } from 'react-icons/lu';
import { AiOutlineAudit } from 'react-icons/ai';
import { FaPhotoVideo } from 'react-icons/fa';
// import { IoIosStats } from 'react-icons/io';

const FeaturesBlocks = () => {
  const feature = (icon, title, description, link) => (
    <div
      className={`relative flex flex-col items-center p-6 safari-backdrop-blur bg-genesys-arctic-200/30 rounded-2xl shadow-lg ${link ? ' cursor-pointer select-none hover:scale-105 transition-all' : ''}`}
      onClick={() => {
        if (!link) return;
        window.location.href = link;
      }}>
      <div className='bg-genesys-orange-base w-16 h-16 rounded-full flex flex-row justify-center items-center text-white text-3xl'>{icon}</div>
      <h4 className='text-xl text-genesys-navy-950 dark:text-genesys-navy-50 font-bold leading-snug tracking-tight mb-1'>{title}</h4>
      <p className='text-genesys-navy-950 dark:text-genesys-navy-50 text-center'>{description}</p>
    </div>
  );
  return (
    <section className='relative'>
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className='absolute inset-0 top-1/2 md:mt-24 lg:mt-0  bg-gradient-to-br from-genesys-azure-300 to-genesys-azure-200 dark:from-genesys-azure-950 dark:to-genesys-azure-800 pointer-events-none' aria-hidden='true'></div>
      {/* <div className='absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2'></div> */}

      <div className='relative max-w-6xl mx-auto px-4 sm:px-6'>
        <div className='py-12 md:py-20'>
          {/* Section header */}
          <div className='max-w-3xl mx-auto text-center pb-12 md:pb-20'>
            <h2 className='h2 mb-4'>Explore the solutions</h2>
            <p className='text-xl text-gray-600'>Experience unparalleled security with our custom Genesys Cloud solutions! Tailored specifically for your needs, our innovative tools fortify your defenses and ensure your data's integrity. Take control of your security today and explore our bespoke solutions.</p>
          </div>

          {/* Items */}
          <div className='max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-4 items-start md:max-w-2xl lg:max-w-none'>
            {feature(<BsRobot />, 'COE AI Assistant', 'Chat with our own AI models designed to answered questions for specific topics.', '/products/COEAIAssistant')}
            {feature(<MdSecurity />, 'Security Advisor', 'Scan your Genesys Cloud organization for an optimal secure configuration.', '/products/security-advisor')}
            {feature(<AiOutlineAudit />, 'Audit Viewer', 'Explore Genesys Cloud audit logs and learn how to receive real-time notifications.', '/products/audit-viewer')}
            {feature(<LuFingerprint />, 'Data Subject Requests', 'Create and manage Data Subject Requests in your own Genesys Cloud organization.', '/products/data-subject-requests')}
            {feature(<FaPhotoVideo />, 'Videos/Docs', 'Empower yourself with valuable resources by exploring our curated collection.', '  /home')}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesBlocks;
