import React, { useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import DashboardTickets from './DashboardTickets';
import OpenedByCategory from './OpenedByCategory';
import ClosedByWrapUpCode from './ClosedByWrapUpCode';
import OpenedInPeriods from './OpenedInPeriods';
import ClosedInPeriods from './ClosedInPeriods';
import RemainingOpenInPeriods from './RemainingOpenInPeriods';
import CompositionInPeriods from './CompositionInPeriods';
//import PropTypes from 'prop-types';

const DashboardData = () => {
  const { setDashboardLayoutItemsSelector } = useContext(TeacketContext);
  const componentsGallery = [
    {
      id: 'Number of tickets',
      col: 0,
      component: (
        <DraggableLayoutItem title='Number of tickets' centered>
          <DashboardTickets />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Opened',
      col: 1,
      component: (
        <DraggableLayoutItem title='Opened' centered>
          <OpenedInPeriods />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Closed',
      col: 2,
      component: (
        <DraggableLayoutItem title='Closed' centered>
          <ClosedInPeriods />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Remaining open',
      col: 3,
      component: (
        <DraggableLayoutItem title='Remaining open' centered>
          <RemainingOpenInPeriods />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Opened by category',
      col: 0,
      component: (
        <DraggableLayoutItem title='Opened by category' centered>
          <OpenedByCategory />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Closed by wrap-up code',
      col: 1,
      component: (
        <DraggableLayoutItem title='Closed by wrap-up code' centered>
          <ClosedByWrapUpCode />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Opened / Closed / Remaining',
      col: 2,
      component: (
        <DraggableLayoutItem title='Opened / Closed / Remaining' centered>
          <CompositionInPeriods />
        </DraggableLayoutItem>
      ),
    },
  ];
  return (
    <div className='p-2'>
      <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setDashboardLayoutItemsSelector(layoutItemsSelector)} columns={4} mainColumnIndex={null} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutTeacketDashboard} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutTeacketDashboardHidden} />
    </div>
  );
};

DashboardData.propTypes = {};

export default DashboardData;
