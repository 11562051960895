import React, { useState, useEffect } from 'react';
import { Select, Spin, Space } from 'antd';
// import { notEmptyArray } from 'src/misc/Misc';
import { getServiceUsers } from 'src/api/teacket';
import UserImage from 'src/components/layout/UserImage';
import { MdOutlineEmail } from 'react-icons/md';
import { LuBuilding } from 'react-icons/lu';
import PropTypes from 'prop-types';
import { isRequestCancelled } from 'src/misc/Misc';

const { Option } = Select;

const ServiceUserPicker = ({ serviceId, onSelect, size = 'large', value }) => {
  const [usersLoading, setUsersLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [defaultValue, setDefaultValue] = useState(value);

  const [selectRef, setSelectRef] = useState(null);

  useEffect(() => {
    if (selectRef) {
      // Small timeout to ensure the DOM is ready
      setTimeout(() => {
        const input = selectRef.querySelector('.ant-select-selection-search-input');
        if (input) {
          input.focus();
        }
      }, 100);
    }
  }, [selectRef]);

  const handleSearchForPermissions = async (query) => {
    setUserList([]);
    if (query.length < 3) return;
    try {
      setUsersLoading(true);
      let resp = await getServiceUsers(serviceId, query);
      console.log('Perms:', resp);
      if (resp) {
        setUserList(resp);
      }
      setUsersLoading(false);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      console.log(error);
      setUsersLoading(false);
    }
  };

  const handleClear = async () => {
    console.log('handleClear');
    setDefaultValue(null);
    setSelectedUser(null);
    setUserList([]);
    if (onSelect) onSelect(null);
  };

  // const isOptionDisabled = (key) => {
  //   return true === notEmptyArray(rolePermissions?.filter((x) => x.permission === key));
  // };

  const handleAssign = async (user_id) => {
    console.log('handleAssign', user_id);
    const user = userList.find((x) => x.user_id === user_id);
    setSelectedUser(user_id);
    if (onSelect) onSelect(user ?? { user_id });
  };

  return (
    <div ref={setSelectRef}>
      <Select
        value={selectedUser ?? defaultValue}
        showSearch
        allowClear
        placeholder='Type to find a person (geographical restrictions may apply)'
        notFoundContent={usersLoading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
        filterOption={false}
        onSearch={handleSearchForPermissions}
        onClear={handleClear}
        onSelect={handleAssign}
        style={{ width: '100%' }}
        size={size}
        optionRender={(option) => {
          const user = userList.find((x) => x.user_id === option.key);
          if (!user && value) return value;
          if (!user) return null;
          return (
            <div className='flex flex-row gap-2 my-1'>
              <div>
                <UserImage image={user.user_pic} size={32} />
              </div>
              <div className='flex flex-col'>
                <div className='overflow-hidden'>{user.user_name}</div>
                <div className='text-xs font-light opacity-60 overflow-hidden'>
                  <Space className='btn'>
                    <MdOutlineEmail />
                    {user.user_email}
                  </Space>
                </div>
                <div className='text-xs font-light opacity-60 overflow-hidden'>
                  <Space className='btn'>
                    <LuBuilding />
                    {user.tenant_name}
                  </Space>
                </div>
              </div>
            </div>
          );
        }}>
        {userList.map((user) => (
          <Option key={user.user_id} autoComplete='off'>
            {user.user_name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

ServiceUserPicker.propTypes = {
  serviceId: PropTypes.string,
  onSelect: PropTypes.func,
  size: PropTypes.string,
  value: PropTypes.object,
};

export default ServiceUserPicker;
