import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NoData from 'src/components/layout/NoData';
import { notArrayOrEmpty } from 'src/misc/Misc';

const DisplayObjectProps = ({ obj, columns = 1 }) => {
  const [parsedObj, setParsedObj] = useState(null);

  useEffect(() => {
    parseObject();
  }, [obj]);

  const parseObject = () => {
    if (!obj) {
      setParsedObj(null);
      return;
    }
    if (typeof obj !== 'object') {
      setParsedObj([{ label: 'Value', value: obj?.toString() }]);
      return;
    }

    const parsed = Object.entries(obj)
      .filter(([_, value]) => value !== null)
      .map(([key, value]) => {
        return { label: key, value: value };
      });
    setParsedObj(parsed);
  };

  if (true === notArrayOrEmpty(parsedObj))
    return (
      <div className='scale-75'>
        <NoData />
      </div>
    );

  return (
    <div className={`grid grid-flow-row grid-cols-${columns} gap-2`}>
      {parsedObj.map((item, index) => (
        <div key={index}>
          <div style={{ lineHeight: '0.8rem' }} className='text-[0.6rem] font-bold overflow-hidden'>
            {item.label}
          </div>
          <div style={{ lineHeight: '0.8rem' }} className='text-[0.8rem] font-light overflow-auto break-words'>
            {item.value}
          </div>
        </div>
      ))}
    </div>
  );
};

DisplayObjectProps.propTypes = {
  obj: PropTypes.object.isRequired,
  columns: PropTypes.number,
};

export default DisplayObjectProps;
