import React from 'react';
import useScreen from 'src/hooks/useScreen';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useMasterAdmin from 'src/hooks/useMasterAdmin';
//import PropTypes from 'prop-types';

const HomePageFilter = () => {
  const { homePageFilter, setHomePageFilter } = useMasterAdmin();
  const { isMobile } = useScreen();

  if (isMobile) return null;
  return (
    <>
      <Input
        size='small'
        autoComplete='off'
        className='w-60'
        placeholder='Search...'
        prefix={<SearchOutlined className='mr-2' />}
        allowClear
        value={homePageFilter}
        onChange={(e) => {
          setHomePageFilter(e.target.value);
        }}
      />
    </>
  );
};

HomePageFilter.propTypes = {};

export default HomePageFilter;
