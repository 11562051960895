import React from 'react';
import PropTypes from 'prop-types';
import { IDENTITY_PROVIDERS } from 'src/misc/Config';
import { Space } from 'antd';
import { ReactComponent as AwsCognito } from 'src/components/svg/aws-cognito.svg';
import { ReactComponent as GenesysCloud } from 'src/components/svg/genesys-cloud.svg';

const ThirdPartyProvider = ({ provider }) => {
  switch (provider) {
    case IDENTITY_PROVIDERS.awsCognito:
      return (
        <Space className='btn font-amazon-ember font-semibold'>
          <AwsCognito style={{ width: '24px', height: '24px' }} />
          AWS Cognito
        </Space>
      );
    case IDENTITY_PROVIDERS.genesysCloud:
      return (
        <Space className='btn font-roboto font-semibold'>
          <GenesysCloud style={{ width: '32px', height: '24px' }} className='text-orange-500' />
          Genesys Cloud
          {provider === IDENTITY_PROVIDERS.genesysCloud}
        </Space>
      );
    default:
      return 'Unknown';
  }
};

ThirdPartyProvider.propTypes = {
  provider: PropTypes.string,
};

export default ThirdPartyProvider;
