import { useContext } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import { SCREEN_MODES } from 'src/misc/Config';

const useScreen = () => {
  const { screenMode } = useContext(GlobalContext);
  const isDesktop = screenMode === SCREEN_MODES.desktop;
  const isMobile = screenMode === SCREEN_MODES.mobile;
  return { isDesktop, isMobile, screenMode };
};

export default useScreen;
