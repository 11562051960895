import React, { useState, useEffect } from 'react';
import { useInterval } from 'react-interval-hook';
import PropTypes from 'prop-types';
import { Progress } from 'antd';

const LoadingScreen = ({ lastTraceMsg }) => {
  const messages = ['We are creating a function for you', 'Please wait, magic is happening', 'Your solution is on the way', 'Just a moment', "Stay tuned, we're almost done", 'Our AI is crunching numbers'];

  const [messageIndex, setMessageIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState('...');
  const [detailedMessage, setDetailedMessage] = useState('');

  useInterval(() => {
    const newIndex = messages.length <= messageIndex + 1 ? 0 : messageIndex + 1;
    //console.log('newIndex', newIndex);
    setMessageIndex(newIndex);
  }, 3000);

  useEffect(() => {
    try {
      if (progress < 90) setProgress(progress + 10);
      const lastTraceMsgParsed = lastTraceMsg ? JSON.parse(lastTraceMsg) : null;
      console.log('lastTraceMsgParsed', lastTraceMsgParsed);

      if (lastTraceMsgParsed?.orchestrationTrace?.rationale?.text) setDetailedMessage(lastTraceMsgParsed.orchestrationTrace.rationale.text.substring(0, 250) + '...');

      if (lastTraceMsgParsed?.preProcessingTrace) {
        setProgressMessage('Pre processing');
      } else if (lastTraceMsgParsed?.orchestrationTrace) {
        setProgressMessage('Creating a function');
      } else {
        setProgressMessage('In progress');
      }
    } catch {
      console.log('Error parsing lastTraceMsg');
    }
  }, [lastTraceMsg]);

  return (
    <>
      <div className='fixed z-40 top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center bg-black opacity-70'></div>

      <div className='fixed z-50 top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center'>
        <div className='relative w-[650px] flex flex-col justify-center items-center gap-8 bg-black rounded-3xl p-8 border-3 border-solid border-sky-400'>
          <video width='640' height='400' autoPlay loop>
            <source src='misc/video1.mp4' type='video/mp4' />
          </video>
          <div className='bg-black px-8 py-2 rounded-2xl'>
            <div className='text-2xl  font-bold text-white'>{messages[messageIndex]}</div>
          </div>
          <div className='w-full'>
            <Progress percent={progress} status='active' />
          </div>
          {!detailedMessage && (
            <div className='bg-black px-8 py-2 rounded-2xl'>
              <div className='text-base animate-pulse text-white'>{progressMessage}</div>
            </div>
          )}
          {detailedMessage && (
            <div className='bg-black px-8 pb-2 rounded-2xl'>
              <div className='text-base animate-pulse text-white'>{detailedMessage}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

LoadingScreen.propTypes = {
  lastTraceMsg: PropTypes.string,
};

export default LoadingScreen;
