import React, { useContext, useEffect, useState /*useRef*/ } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import { useNavigate } from 'react-router-dom';
import useTheme from 'src/hooks/useTheme';
import { Button, Space, Popover, Tooltip, Checkbox } from 'antd';
import CondensedTableWithGrouping from 'src/components/pages/Teacket/Components/TicketListWithGrouping';
import { getTickets } from 'src/api/teacket';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import Highlighter from 'react-highlight-words';
import UserImage from 'src/components/layout/UserImage';
import BarLoader from 'react-bar-loader';
import TicketStatusColor from '../../Components/TicketStatusColor';
import TicketPriorityColor from '../../Components/TicketPriorityColor';
import { FaTableColumns } from 'react-icons/fa6';
// import { DndProvider, useDrag, useDrop } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
import TicketTypeTag from '../../Components/TicketTypeTag';
import CmdBtn from 'src/components/layout/CmdBtn';
import TicketDrawer from '../../Components/TicketDrawer';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

// If a user id is passed, local state will be used instead of the global context
const TicketListControl = ({ userId, accountId, opportunityId, showRequesterAndUserTickets = true }) => {
  const { theme } = useTheme();
  const context = useContext(TeacketContext);
  const navigate = useNavigate();

  // Use the local state if any of these vars are set
  const useLocalState = !!(userId || accountId || opportunityId);

  // Local state variables
  const [localTicketList, setLocalTicketList] = useState([]);
  const [localTicketListStartDate, setLocalTicketListStartDate] = useState(dayjs(context.dashboardStartDate).format());
  const [localTicketListEndDate, setLocalTicketListEndDate] = useState(dayjs(context.dashboardEndDate).format());
  const [localTicketListQuerying, setLocalTicketListQuerying] = useState(false);
  const [localTicketListCurrentPage, setLocalTicketListCurrentPage] = useState(1);
  const [localTicketListTotal, setLocalTicketListTotal] = useState(0);

  const [pageSize] = useState(parseInt(25));
  // const [isDragging, setIsDragging] = useState(false);
  const [isInitialUserSetupDone, setIsInitialUserSetupDone] = useState(!userId);

  useEffect(() => {
    if (useLocalState) {
      setIsInitialUserSetupDone(true);
      setLocalTicketListTotal(0);
    }
    loadData();
  }, []);

  useEffect(() => {
    if (useLocalState) {
      setLocalTicketListStartDate(dayjs(context.dashboardStartDate).format());
      setLocalTicketListEndDate(dayjs(context.dashboardEndDate).format());
    }
  }, [context.dashboardStartDate, context.dashboardEndDate]);

  useEffect(() => {
    if (useLocalState && !isInitialUserSetupDone) return;

    loadData();
  }, [localTicketListCurrentPage]);

  // Make sure all dependencies are listed below to reload data when they change
  useEffect(() => {
    setLocalTicketListCurrentPage(1);

    loadData();
  }, [context.ticketListUsers, context.ticketListServices, context.ticketListTypes, context.ticketListStatuses, context.ticketListPriorities, context.ticketListStatuses, context.dashboardListServices, localTicketListStartDate, context.ticketListStartDate, localTicketListEndDate, context.ticketListEndDate, isInitialUserSetupDone]);

  const loadData = async () => {
    try {
      setLocalTicketListQuerying(true);
      setLocalTicketList([]);

      context.setTicketList([]);
      context.setTicketListTotal(0);

      let resp;

      if (useLocalState) {
        // Use local state
        console.log('Using local state...');
        console.log('context:', context);
        if (userId) {
          resp = await getTickets(localTicketListCurrentPage, pageSize, null, context.dashboardListServices, null, context.dashboardRequestStatuses, [userId], localTicketListStartDate, localTicketListEndDate, context.dashboardRequestTypes, null, null, null, null);
        } else if (accountId) {
          if (opportunityId) {
            resp = await getTickets(localTicketListCurrentPage, pageSize, null, context.dashboardListServices, null, context.dashboardRequestStatuses, null, localTicketListStartDate, localTicketListEndDate, context.dashboardRequestTypes, null, null, accountId, opportunityId);
          } else {
            resp = await getTickets(localTicketListCurrentPage, pageSize, null, context.dashboardListServices, null, context.dashboardRequestStatuses, null, localTicketListStartDate, localTicketListEndDate, context.dashboardRequestTypes, null, null, accountId);
          }
        }

        // Do not show tickets if user is not a requester or user?
        if (!showRequesterAndUserTickets) {
          resp.entities = resp.entities.filter((x) => !x.teacket_ticket_members.find((ttm) => ttm.user_id === userId && (ttm.role === 'Requester' || ttm.role === 'User' || ttm.role === 'Assigned SC')));
        }

        setLocalTicketList(resp?.entities ?? []);
        setLocalTicketListTotal(resp?.total ?? 0);
      } else {
        // Use context
        console.log('Using context...');
        resp = await getTickets(context.ticketListCurrentPage, pageSize, context.ticketListQuery, context.dashboardListServices, context.ticketListPriorities, context.ticketListStatuses, context.ticketListUsers, context.ticketListStartDate, context.ticketListEndDate, context.ticketListTypes, null, null);

        context.setTicketList(resp?.entities ?? []);
        context.setTicketListTotal(resp?.total ?? 0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLocalTicketListQuerying(false);
    }
  };

  const columns = [
    {
      title: 'Id',
      showInGroupBy: false,
      key: 'id',
      dataIndex: 'id',
      width: 140,
      // sorter: isDragging ? false : (a, b) => a.id.localeCompare(b.id),
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (text, record) => (
        <Space>
          <CmdBtn
            type='link'
            style={{ maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis' }}
            onClick={async () => {
              context.setTicketListSelectedTicket(text);
            }}
            onClickCommandShiftPressed={() => {
              navigate(`/teacket/tickets/${record.id}`);
            }}
            onClickCommandPressed={() => {
              window.open(`/teacket/tickets/${record.id}`, '_blank');
            }}>
            <strong>{record.id}</strong>
          </CmdBtn>
          {record?.type && <TicketTypeTag type={record.type} styling='-ml-4 -mr-4' />}
        </Space>
      ),
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      width: 420,
      // sorter: isDragging ? false : (a, b) => a.title.localeCompare(b.title),
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text) => (
        <div style={{ maxWidth: '400px', wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Highlighter textToHighlight={text ?? ''} searchWords={[`${context.ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
        </div>
      ),
    },
    {
      title: 'Account',
      showInGroupBy: true,
      key: 'account',
      dataIndex: 'acc_name',
      //sorter: isDragging ? false : (a, b) => a.acc_name?.localeCompare(b.acc_name),
      sorter: (a, b) => a.acc_name?.localeCompare(b.acc_name),
      render: (text) => (
        <div>
          <Highlighter textToHighlight={text ?? ''} searchWords={[`${context.ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
        </div>
      ),
    },
    {
      title: 'Members',
      key: 'members',
      dataIndex: 'teacket_ticket_members',
      // sorter: isDragging ? false : (a, b) => a.teacket_ticket_members?.length - b.teacket_ticket_members?.length,
      sorter: (a, b) => a.teacket_ticket_members?.length - b.teacket_ticket_members?.length,
      align: 'left',
      width: 220,
      showInGroupBy: false,
      render: (text) => (
        <div className='flex flex-col gap-1 m-2 flex-wrap'>
          {text?.map((x, i) => {
            return (
              <div key={i} className='flex flex-row gap-2 items-center'>
                <UserImage image={x?.user?.user_pic} size='25px' />
                <div style={{ lineHeight: '0.5rem' }}>
                  <div className='text-sm'>{x?.user?.name}</div>
                  <div className='text-[0.6rem] font-light opacity-60'>{x?.role}</div>
                </div>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      title: 'Category',
      showInGroupBy: true,
      key: 'category',
      dataIndex: 'category',
      align: 'center',
      // sorter: isDragging ? false : (a, b) => a.category.localeCompare(b.category),
      sorter: (a, b) => a.category.localeCompare(b.category),
      render: (text, record) => (
        <div style={{ wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Highlighter textToHighlight={text ?? ''} searchWords={[`${context.ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          <div className='text-xs font-light opacity-60'>
            <Highlighter textToHighlight={record?.sub_category ?? ''} searchWords={[`${context.ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </div>
        </div>
      ),
    },
    {
      title: 'Priority',
      showInGroupBy: true,
      key: 'priority',
      dataIndex: 'priority',
      align: 'center',
      // sorter: isDragging ? false : (a, b) => a.priority?.localeCompare(b.priority),
      sorter: (a, b) => a.priority?.localeCompare(b.priority),
      render: (text, record) => (
        <div className='flex flex-row gap-2 items-center justify-center'>
          <TicketPriorityColor serviceId={record?.service_id} priority={text} />
          {text}
        </div>
      ),
    },
    {
      title: 'Status',
      showInGroupBy: true,
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      // sorter: isDragging ? false : (a, b) => a.status?.localeCompare(b.status),
      sorter: (a, b) => a.status?.localeCompare(b.status),
      render: (text, record) => (
        <div className='flex flex-row gap-2 items-center justify-center'>
          <TicketStatusColor serviceId={record?.service_id} status={text} />
          {text}
        </div>
      ),
    },
    {
      title: 'Created',
      showInGroupBy: false,
      key: 'created_at',
      dataIndex: 'created_at',
      align: 'center',
      // sorter: isDragging ? false : (a, b) => a.created_at.localeCompare(b.created_at),
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (text) =>
        text ? (
          <>
            <div>{dayjs(text).fromNow()}</div>
            <div className='font-thin text-xs'>at {dayjs(text).format('LLL')}</div>
          </>
        ) : (
          <>n/a</>
        ),
    },
    {
      title: 'Closed',
      showInGroupBy: false,
      key: 'closed_at',
      dataIndex: 'closed_at',
      align: 'center',
      // sorter: isDragging ? false : (a, b) => a.closed_at?.localeCompare(b.closed_at),
      sorter: (a, b) => a.closed_at?.localeCompare(b.closed_at),
      render: (text) =>
        text ? (
          <>
            <div>{dayjs(text).fromNow()}</div>
            <div className='font-thin text-xs'>at {dayjs(text).format('LLL')}</div>
          </>
        ) : (
          <>n/a</>
        ),
    },
  ];

  // const moveColumn = (dragIndex, hoverIndex) => {
  //   const newOrder = [...context.columnOrder];
  //   const [removed] = newOrder.splice(dragIndex, 1);
  //   newOrder.splice(hoverIndex, 0, removed);
  //   context.setColumnOrder(newOrder);
  // };

  // const DraggableColumn = ({ column, index, moveColumn }) => {
  //   const ref = useRef(null);
  //   const [{ isOver }, drop] = useDrop({
  //     accept: 'column',
  //     hover(item, monitor) {
  //       if (!ref.current) {
  //         return;
  //       }
  //       const dragIndex = item.index;
  //       const hoverIndex = index;
  //       if (dragIndex === hoverIndex) {
  //         return;
  //       }
  //       moveColumn(dragIndex, hoverIndex);
  //       item.index = hoverIndex;
  //     },
  //     collect: (monitor) => ({
  //       isOver: monitor.isOver(),
  //     }),
  //   });

  //   const [{ isDragging: columnIsDragging }, drag] = useDrag({
  //     type: 'column',
  //     item: () => {
  //       setIsDragging(true);
  //       return { type: 'column', index };
  //     },
  //     collect: (monitor) => ({
  //       isDragging: monitor.isDragging(),
  //     }),
  //     end: () => {
  //       setIsDragging(false);
  //     },
  //   });

  //   drag(drop(ref));

  //   return (
  //     <div
  //       ref={ref}
  //       style={{
  //         opacity: columnIsDragging ? 0.5 : 1,
  //         cursor: 'move',
  //         padding: '4px 8px',
  //         backgroundColor: isOver ? '#e6f7ff' : 'transparent',
  //         display: 'inline-block',
  //       }}
  //     >
  //       {column.title}
  //     </div>
  //   );
  // };

  const columnsContent = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {context.columnOrder.map((key, index) => {
        const column = columns.find((col) => col.key === key);
        return (
          <div key={column.key} style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox checked={context.visibleColumns[column.key]} onChange={() => context.toggleColumnVisibility(column.key)}>
              {column.title}
            </Checkbox>
          </div>
        );
      })}
    </div>
  );

  const filteredColumns = columns.filter((col) => context.columnOrder.includes(col.key) && context.visibleColumns[col.key]).sort((a, b) => context.columnOrder.indexOf(a.key) - context.columnOrder.indexOf(b.key));

  return (
    <>
      <TicketDrawer />
      {/* <DndProvider backend={HTML5Backend}> */}
      <div className='relative'>
        <div className='absolute animate-appear2 top-2 right-4 z-10 opacity-40 hover:opacity-100'>
          <Tooltip title='Show/Hide columns'>
            <Popover content={columnsContent} title='Show/Hide Columns' trigger='click'>
              <Button type='primary' shape='circle' size='small' className='w-8 h-8'>
                <FaTableColumns />
              </Button>
            </Popover>
          </Tooltip>
        </div>

        <div className={`relative ${localTicketListQuerying ? 'disabled' : null}`}>
          {!localTicketList ? (
            <SkeletonTable />
          ) : (
            <>
              {localTicketListQuerying ? <BarLoader color={theme.primary} backgroundColor={theme.light} height='2' width='100%' /> : <div style={{ height: '2px' }} />}
              <CondensedTableWithGrouping
                pagination={{
                  current: useLocalState ? localTicketListCurrentPage : context.ticketListCurrentPage,
                  showSizeChanger: false,
                  pageSize,
                  size: 'small',
                  total: useLocalState ? localTicketListTotal : context.ticketListTotal,
                  onChange: (page) => setLocalTicketListCurrentPage(page),
                }}
                columns={filteredColumns}
                dataSource={useLocalState ? localTicketList : context.ticketList}
                groupBy={context.ticketListGroupBy}
              />
            </>
          )}
        </div>
      </div>
      {/* </DndProvider> */}
    </>
  );
};

TicketListControl.propTypes = {
  userId: PropTypes.string,
  column: PropTypes.object,
  index: PropTypes.number,
  moveColumn: PropTypes.func,
  tickets: PropTypes.array,
  showRequesterAndUserTickets: PropTypes.bool,
  accountId: PropTypes.string,
  opportunityId: PropTypes.string,
};

export default TicketListControl;
