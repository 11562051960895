import dayjs from 'dayjs';

export const describeFeaturesAccess = (featureAccess) => {
  let additions = {
    freeTrialDesc: 'no access',
    freeTrialHasAccess: false,
    freeTrialError: false,
    paidAccessDesc: 'no access',
    paidAccessHasAccess: false,
    paidAccessError: false,
  };

  if (!featureAccess) return additions;

  //#regon "FREE TRIAL"

  if ((featureAccess.free_trial_from && !featureAccess.free_trial_to) || (!featureAccess.free_trial_from && featureAccess.free_trial_to)) {
    additions.freeTrialDesc = 'incorrect dates';
    additions.freeTrialError = true;
  } else if (featureAccess.free_trial_from && featureAccess.free_trial_to) {
    if (dayjs().isAfter(dayjs(featureAccess.free_trial_from)) && dayjs().isBefore(dayjs(featureAccess.free_trial_to))) {
      additions.freeTrialDesc = 'expires ' + dayjs(featureAccess.free_trial_to).fromNow();
      additions.freeTrialHasAccess = true;
    } else if (dayjs().isAfter(dayjs(featureAccess.free_trial_to))) {
      additions.freeTrialDesc = 'expired ' + dayjs(featureAccess.free_trial_to).fromNow();
    } else if (dayjs().isBefore(dayjs(featureAccess.free_trial_from))) {
      additions.freeTrialDesc = 'will start ' + dayjs(featureAccess.free_trial_from).fromNow();
    }
  }

  //#endregion

  //#regon "PAID ACCESS"

  if ((featureAccess.paid_access_from && !featureAccess.paid_access_to) || (!featureAccess.paid_access_from && featureAccess.paid_access_to)) {
    additions.paidAccessDesc = 'incorrect dates';
    additions.paidAccessError = true;
  } else if (featureAccess.paid_access_from && featureAccess.paid_access_to) {
    if (dayjs().isAfter(dayjs(featureAccess.paid_access_from)) && dayjs().isBefore(dayjs(featureAccess.paid_access_to))) {
      additions.paidAccessDesc = 'expires ' + dayjs(featureAccess.paid_access_to).fromNow();
      additions.paidAccessHasAccess = true;
    } else if (dayjs().isAfter(dayjs(featureAccess.paid_access_to))) {
      additions.paidAccessDesc = 'expired ' + dayjs(featureAccess.paid_access_to).fromNow();
    } else if (dayjs().isBefore(dayjs(featureAccess.paid_access_from))) {
      additions.paidAccessDesc = 'will start ' + dayjs(featureAccess.paid_access_from).fromNow();
    }
  }

  //#endregion

  return { ...featureAccess, ...additions };
};
