import React, { useEffect, useState } from 'react';
import { Form, Input, Space } from 'antd';
import { CiCirclePlus, CiCircleMinus } from 'react-icons/ci';
import PropTypes from 'prop-types';
import { notEmptyArray } from 'src/misc/Misc';

const MultipleInput = ({ value = [], onChange, name, label, placeholder, rules }) => {
  const [inputValues, setInputValues] = useState(value);

  // If we don't do this, the initial value is not set correctly
  useEffect(() => {
    setInputValues(value);
  }, [value]);

  useEffect(() => {
    console.log('Input values:', inputValues);
    onChange(inputValues);
  }, [inputValues]);

  return (
    <Form.List name={name} rules={rules}>
      {(fields, { add, remove }) => (
        <>
          <div className='flex flex-row'>
            <Space>
              {rules?.[0]?.required ? <span style={{ display: 'inline-block', marginInlineEnd: '4px', color: '#dc4446', fontSize: '14px', fontFamily: 'SimSun, sans-serif', lineHeight: 1, content: '*' }}>*</span> : <></>}
              {label}
              {/* Add button */}
              <button
                className='bg-transparent text-green-500 border-none cursor-pointer outline-none'
                onClick={(e) => {
                  e.preventDefault();
                  add();
                }}
              >
                <CiCirclePlus size={20} />
              </button>
            </Space>
          </div>
          {fields.map((field, index) => (
            <Form.Item required={rules?.[0]?.required} key={field.key}>
              <Form.Item {...field} validateTrigger={['onChange', 'onBlur']} rules={[{ required: true, whitespace: true, message: 'Please enter a value' }]} noStyle>
                <div className='flex flex-row'>
                  {/* Remove button */}
                  {fields.length > 1 ? (
                    <button
                      className='bg-transparent text-red-600 border-none cursor-pointer outline-none'
                      onClick={(e) => {
                        e.preventDefault();
                        remove(field.name);
                      }}
                    >
                      <CiCircleMinus size={20} />
                    </button>
                  ) : null}
                  {/* Input */}
                  <Input
                    placeholder={placeholder}
                    className='w-9/12'
                    value={value?.[index]}
                    onInput={(e) => {
                      let currentInputValue = notEmptyArray(inputValues) ? [...inputValues] : [];
                      currentInputValue[index] = e.target.value;
                      setInputValues(currentInputValue);
                    }}
                  />
                </div>
              </Form.Item>
            </Form.Item>
          ))}
        </>
      )}
    </Form.List>

    //   <Form.List name={name} rules={rules}>
    //     {(fields, { add, remove }, {errors}) => {
    //       return (
    //         <div>
    //           <div className='flex flex-row'>
    //             {rules?.[0]?.required ? <span style={{ display: 'inline-block', marginInlineEnd: '4px', color: '#dc4446', fontSize: '14px', fontFamily: 'SimSun, sans-serif', lineHeight: 1, content: '*' }}>*</span> : <></>}
    //             {label}
    //             <Form.Item className='mt-0'>
    //               <button
    //                 className='bg-transparent text-green-500 border-none cursor-pointer outline-none'
    //                 onClick={(e) => {
    //                   e.preventDefault();
    //                   add();
    //                 }}>
    //                 <CiCirclePlus size={25} />
    //               </button>
    //             </Form.Item>
    //           </div>
    //           {fields.map((field, index) => {
    //             console.log('inputValue:', inputValue);
    //             return inputValue.map((currentInputValue, valueIndex) => {
    //               return (
    //                 <Form.Item key={valueIndex} {...field} rules={[{ required: true, message: errorMessage }]}>
    //                   <div className='flex flex-row'>
    //                     {fields.length > 1 ? (
    //                       <button
    //                         className='bg-transparent text-red-600 border-none cursor-pointer outline-none'
    //                         onClick={(e) => {
    //                           e.preventDefault();
    //                           remove(field.name);
    //                         }}>
    //                         <CiCircleMinus size={25} />
    //                       </button>
    //                     ) : null}
    //                     <Input placeholder={placeholder} autoComplete='off' allowClear defaultValue={value} value={currentInputValue} onChange={onInputChange} />
    //                   </div>
    //                 </Form.Item>
    //               );
    //             });
    //           })}
    //         </div>
    //       );
    //     }}
    //   </Form.List>
  );
};

MultipleInput.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  rules: PropTypes.array.isRequired,
};

export default MultipleInput;
