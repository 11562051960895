import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import ReactDraggableLayout2 from 'react-draggable-layout-2';
import LayoutItemsSelector from './LayoutItemsSelector';
import { notEmptyArray } from 'src/misc/Misc';

const DraggableLayoutProvider = ({ columns, mainColumnIndex, componentsGallery, layoutKey, layoutHiddenKey, getLayoutItemsSelector, enabled, rootComponentId, extraOffsetX }) => {
  const { isDarkMode } = useTheme();

  const [components, setComponents] = useState();
  const [hiddenIds, setHiddenIds] = useState(null);

  useEffect(() => {
    const savedHiddenIdsStr = localStorage.getItem(layoutHiddenKey);
    setHiddenIds(savedHiddenIdsStr ? JSON.parse(savedHiddenIdsStr) : []);

    const savedComponentsStr = localStorage.getItem(layoutKey);
    if (!savedComponentsStr) {
      // debugger;
      let newComponents = [...componentsGallery];
      let col = 0;
      for (let element of newComponents) {
        if (element.col || element.col === 0) continue;
        element.col = col < columns ? col++ : (col = 0);
      }
      setComponents(newComponents);
    } else {
      let savedComponents = JSON.parse(savedComponentsStr).filter((x) => componentsGallery.map((y) => y.id).includes(x.id));
      savedComponents.forEach((element) => {
        element.component = componentsGallery.find((x) => x.id === element.id).component;
      });

      // <add new components>
      let newComponents = [...componentsGallery].filter((x) => !savedComponents.map((y) => y.id).includes(x.id));
      let col = 0;
      for (let element of newComponents) {
        if (element.col || element.col === 0) continue;
        element.col = col < columns ? col++ : (col = 0);
      }
      savedComponents = savedComponents.concat(newComponents);
      // </add new components>
      //debugger;
      setComponents(savedComponents);
    }
  }, []);

  useEffect(() => {
    if (getLayoutItemsSelector) getLayoutItemsSelector(<LayoutItemsSelector onShownComponentsIdsChange={handleOnShownComponentsIdsChange} allComponentIds={componentsGallery?.map((x) => x.id)} hiddenIds={hiddenIds} onLayoutReset={handleOnLayoutReset} />);
  }, [hiddenIds]);

  const handleOnComponentsChange = (updatedComponents) => {
    const toBeSaved = updatedComponents.map(({ id, col }) => {
      return { id, col };
    });
    localStorage.setItem(layoutKey, JSON.stringify(toBeSaved));
  };

  const handleOnShownComponentsIdsChange = (v) => {
    console.log('handleOnShownComponentsIdsChange', v);
    const hIds = componentsGallery.map((x) => x.id).filter((x) => !v.includes(x));
    setHiddenIds(hIds);
    localStorage.setItem(layoutHiddenKey, JSON.stringify(hIds));
  };

  const handleOnLayoutReset = () => {
    localStorage.removeItem(layoutKey);
    localStorage.removeItem(layoutHiddenKey);
    window.location.reload();
  };

  return (
    components &&
    hiddenIds && (
      <div style={{ width: '100%', minHeight: '100vh' }}>
        <ReactDraggableLayout2 columns={columns} mainColumnIndex={mainColumnIndex} defaultComponents={components} onChange={handleOnComponentsChange} isDarkMode={isDarkMode} hiddenIds={true === notEmptyArray(hiddenIds) ? hiddenIds : []} ignoredClassPrefixList={['ant-']} enabled={enabled} rootComponentId={rootComponentId} extraOffsetX={extraOffsetX} />
      </div>
    )
  );
};

DraggableLayoutProvider.propTypes = {
  columns: PropTypes.number,
  mainColumnIndex: PropTypes.number,
  enabled: PropTypes.bool,
  componentsGallery: PropTypes.array,
  layoutKey: PropTypes.string,
  layoutHiddenKey: PropTypes.string,
  getLayoutItemsSelector: PropTypes.func,
  rootComponentId: PropTypes.string,
  extraOffsetX: PropTypes.number,
};

DraggableLayoutProvider.defaultProps = {
  columns: 3,
  mainColumnIndex: 1,
  enabled: true,
  componentsGallery: [],
  rootComponentId: 'layout-root-container',
  extraOffsetX: 0,
};

export default DraggableLayoutProvider;
