import React, { useEffect } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import DashboardScreen from './Dashboard';
import ClientCredentialsProvider from 'src/providers/ClientCredentialsProvider/ClientCredentialsProvider';
import FeatureAccess from 'src/components/controls/FeatruesAccess/FeaturesAccess';

//import PropTypes from 'prop-types';

const DataSubjectRequests = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Data Subject Requests',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['apps.datasubjectrequests.view']}>
      <FeatureAccess feature='subject_request' featureDisplayName='Data Subject Requests'>
        <ClientCredentialsProvider appId='datasubjectrequests'>
          <DashboardScreen />
        </ClientCredentialsProvider>
      </FeatureAccess>
    </PermissionsProvider>
  );
};

export default DataSubjectRequests;
