import React, { useEffect, useContext } from 'react';
import useTheme from 'src/hooks/useTheme';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import AuditViewerContext from 'src/context/AuditViewerContext';
import { getSnsTopic, postSnsTopic, getSnsSubscriptions, deleteSubscribe } from 'src/api/subscriptions';
// import { putEventsFilter, deleteEventsFilter } from 'src/api/securityAdvisor';
import { AV_APP_ID } from '../../Misc/misc';
import SectionDivider from 'src/components/controls/SectionTitile/SectionDivider';
import CondensedTable from 'src/components/layout/CondensedTable';
import { Skeleton, Button, Tag, Space, notification, Result } from 'antd';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import { notArrayOrEmpty } from 'src/misc/Misc';
import { FaRegTrashCan } from 'react-icons/fa6';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import { MdUnsubscribe } from 'react-icons/md';
//import EventsFilter from './EventsFilter';
import { MdAdd } from 'react-icons/md';
import queryString from 'query-string';
import SubscriptionAddNew from './SubscriptionAddNew';

//import PropTypes from 'prop-types';

const SubscriptionsWidget = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();
  const { hasCurrentUserSubscribed, setHasCurrentUserSubscribed, snsSubscriptionsReload, setSnsSubscriptionsReload, setSnsSubscriptionsAddNew, topic, setTopic, subscriptions, setSubscriptions } = useContext(AuditViewerContext);
  const { openModal } = queryString.parse(window.location.search);

  const columns = [
    // {
    //   title: 'SubscriptionArn',
    //   dataIndex: 'SubscriptionArn',
    //   key: 'SubscriptionArn',
    // },
    {
      title: 'Protocol',
      key: 'Protocol',
      width: 140,
      render: (record) => {
        return (
          <Space>
            {record.Protocol}
            {record.justCreated && (
              <Tag color='blue' size='small'>
                new
              </Tag>
            )}
          </Space>
        );
      },
    },
    {
      title: 'Endpoint',
      key: 'Endpoint',
      render: (record) => {
        return (
          <>
            <div>{record.Endpoint}</div>
            <>{true === isConfirmed(record) ? <div className='whitespace-pre-wrap font-extralight text-[0.5rem] text-ellipsis overflow-hidden opacity-60'>{record.SubscriptionArn}</div> : <div className='font-extralight text-[0.6rem]'>Pending confirmation</div>}</>
          </>
        );
      },
    },
    {
      title: 'Action',
      key: 'Action',
      align: 'center',
      width: 80,
      render: (record) =>
        true === isConfirmed(record) && (
          <Space className='btn'>
            <YesNoDialog
              title={<>Unsubscribe {record.Protocol}</>}
              body={
                <>
                  Do you want to unsubscribe <strong>{record.Endpoint}</strong>?
                </>
              }
              onYesClick={() => {
                handleDeleteSubscription(record.SubscriptionArn, record.Protocol, record.Endpoint);
              }}
              showRed={true}
              iconYes={<MdUnsubscribe />}
              labelYes='Yes, cancel subscription'
            >
              <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }}>
                <FaRegTrashCan />
              </Button>
            </YesNoDialog>
          </Space>
        ),
    },
    // {
    //   title: 'Subscription',
    //   dataIndex: 'SubscriptionArn',
    //   key: 'SubscriptionArn',
    // },
  ];

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (topic) {
      loadSubscriptions();
      if (!hasCurrentUserSubscribed && openModal) setSnsSubscriptionsAddNew(true);
    }
  }, [topic]);

  useEffect(() => {
    if (snsSubscriptionsReload) {
      setSnsSubscriptionsReload(false);
      loadSubscriptions();
    }
  }, [snsSubscriptionsReload]);

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        // <get topic for app id and tenant id>
        let t = await getTopicAndHandle404();
        if (t) {
          setTopic(t);
          return;
        }
        // </get topic for app id and tenant id>

        // <or create if does not exist>
        t = await postSnsTopic(AV_APP_ID);
        setTopic(t);
        notification.success({
          message: 'Success',
          description: (
            <>
              <div>New topic for subscriptions has been created</div>
              <div className='font-extralight text-xs opacity-60'>{t?.TopicArn}</div>
            </>
          ),
          duration: 5,
        });
        // </or create if does not exist>
      } catch (error) {
        console.log(error);
        notification.error({
          message: 'Error',
          description: error.toString(),
          duration: 5,
        });
      }
    });
  };

  const getTopicAndHandle404 = async () => {
    try {
      const result = await getSnsTopic(AV_APP_ID);
      return result;
    } catch (error) {
      console.log(error);
      if (error?.toString().includes('404')) return null;
      throw error;
    }
  };

  const loadSubscriptions = async () => {
    await executeAsyncProcess(async () => {
      try {
        const result = await getSnsSubscriptions(AV_APP_ID);
        setSubscriptions(result);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleDeleteSubscription = async (SubscriptionArn, protocol, endpoint) => {
    await executeAsyncProcess(async () => {
      // <delete filters>
      // try {
      //   await deleteEventsFilter(protocol, endpoint);
      // } catch (error) {
      //   console.log(error);
      // }
      // </delete filters>

      // <delete subscription>
      try {
        await deleteSubscribe(AV_APP_ID, SubscriptionArn);
        notification.success({
          message: 'Success',
          description: 'Subscription has been cancelled',
          duration: 5,
        });
        const s = subscriptions.filter((x) => x.SubscriptionArn !== SubscriptionArn);
        setSubscriptions(s);
      } catch (error) {
        console.log(error);
      }
      // </delete subscription>

      // <check if user is subscribed>
      setHasCurrentUserSubscribed(false);
      // </check if user is subscribed>
    });
  };

  const isConfirmed = (item) => !(item?.SubscriptionArn?.toLowerCase()?.includes('pending') && item?.SubscriptionArn?.toLowerCase()?.includes('confirmation'));

  return (
    <div>
      <SubscriptionAddNew />

      {!subscriptions ? (
        <SkeletonTable />
      ) : (
        <>
          {true === notArrayOrEmpty(subscriptions) ? (
            <Result
              style={{ zoom: '0.6' }}
              status='404'
              // title='No subscriptions added yet'
              title={
                <>
                  In order to receive notifications, you need to add a subscription.
                  <br />
                  Click the button below to add a new subscription.
                </>
              }
              extra={
                <Button
                  style={{ zoom: '1.4' }}
                  type='primary'
                  onClick={() => {
                    setSnsSubscriptionsAddNew(true);
                  }}
                >
                  <Space className='btn'>
                    <MdAdd />
                    Add subscription
                  </Space>
                </Button>
              }
            />
          ) : (
            <CondensedTable
              columns={columns}
              dataSource={subscriptions}
              pagination={{
                pageSize: 10,
                size: 'small',
              }}
              rowKey={(record) => `${record.Protocol}-${record.Endpoint}`}
              // expandable={{
              //   expandedRowRender: (record) => (
              //     <div>
              //       TODO: filters??
              //       {/* <EventsFilter protocol={record.Protocol} endpoint={record.Endpoint} /> */}
              //     </div>
              //   ),
              //   rowExpandable: () => true,
              // }}
            />
          )}
        </>
      )}

      <SectionDivider />

      {!topic ? (
        <div className='flex flex-col gap-1 mt-1 items-center'>
          <Skeleton.Input active size={12} style={{ width: '300px' }} />
          <Skeleton.Input active size={12} style={{ width: '200px' }} />
        </div>
      ) : (
        <>
          <div className='text-center text-xs'>{topic?.Attributes?.DisplayName}</div>
          <div className='text-center font-extralight text-[0.5rem] opacity-60'>{topic?.Attributes?.TopicArn?.split(':')?.pop()}</div>
        </>
      )}
    </div>
  );
};

SubscriptionsWidget.propTypes = {};

export default SubscriptionsWidget;
