import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import useTheme from 'src/hooks/useTheme';
import { countCopilotFeedbacks } from 'src/api/securityqa';
import { Card, Spin, Space } from 'antd';
import { MdOutlineThumbDown, MdOutlineThumbUp } from 'react-icons/md';

const GraphCopilotFeedback = () => {
  const { isDarkMode } = useTheme();
  const [numberFeedbacks, setNumberFeedbacks] = useState(null);

  const themeMode = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  const loadData = async () => {
    try {
      const resp = await countCopilotFeedbacks();
      console.log('feedback count', resp);
      setNumberFeedbacks(resp);
    } catch (error) {
      console.log('Error');
      console.error(error);
    }
  };

  useEffect(() => {
    if (numberFeedbacks === null) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={themeMode}>
      {/* <div className='text-3xl text-center font-semibold whitespace-pre-wrap pb-4 w-full'>AI Assistant Feedback</div> */}

      {/* <div className='mx-4 my-1 text-3xl text-center font-semibold whitespace-pre-wrap pb-4'>Feedback count</div> */}
      <div>
        {numberFeedbacks === null ? (
          <div className='text-center'>
            <Spin />
          </div>
        ) : (
          <div className='flex flex-row flex-wrap gap-4 justify-center py-4'>
            <Card
              className='w-48'
              size='small'
              title={
                <div className='text-center'>
                  <Space className='btn'>
                    <MdOutlineThumbUp className='text-green-500' />
                    <div className='text-green-500'>great</div>
                    <div className='font-extralight text-xs'>last 30 days</div>
                  </Space>
                </div>
              }>
              <div className='text-center text-5xl'>{numberFeedbacks.countGreat}</div>
            </Card>

            <Card
              className='w-48'
              size='small'
              title={
                <div className='text-center'>
                  <Space className='btn'>
                    <MdOutlineThumbDown className='text-blue-500' />
                    <div className='text-blue-500'>bad</div>
                    <div className='font-extralight text-xs'>last 30 days</div>
                  </Space>
                </div>
              }>
              <div className='text-center text-5xl'>{numberFeedbacks.countBad}</div>
            </Card>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

GraphCopilotFeedback.propTypes = {
  tickets: PropTypes.array,
};

export default GraphCopilotFeedback;
