import React, { useContext, useState, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
//import useUserInfo from 'src/hooks/useUserInfo';
// import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { notEmptyArray } from 'src/misc/Misc';
import useUserInfo from 'src/hooks/useUserInfo';
import UserImage from 'src/components/layout/UserImage';
import { Tooltip } from 'antd';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const TicketActiveViewers = () => {
  const { activeViewers } = useContext(TeacketContext);
  const { id: userId } = useUserInfo();
  // const userInfo = useUserInfo();

  const [otherActiveViewers, setOtherActiveViewers] = useState(null);

  useEffect(() => {
    if (notEmptyArray(activeViewers)) {
      setOtherActiveViewers(activeViewers.filter((user) => user.user_id !== userId));
    }
  }, [activeViewers]);

  return (
    <>
      <div className='flex flex-row flex-wrap gap-2 mt-4 justify-end'>
        {true === notEmptyArray(otherActiveViewers) &&
          otherActiveViewers.map((viewer) => (
            <Tooltip title={viewer.user_name} key={viewer.user_id} trigger={['hover']}>
              <div>
                <UserImage image={viewer.user_pic} size='36px' />
              </div>
            </Tooltip>
          ))}
      </div>
    </>
  );
};

TicketActiveViewers.propTypes = {};

export default TicketActiveViewers;
