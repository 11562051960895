import React, { Component } from 'react';
import SecurityAdvisorContext from './SecurityAdvisorContext';
import { notEmptyArray } from 'src/misc/Misc';

//import { getMyNotifications } from 'src/api/notifications';

export default class SecurityAdvisorProvider extends Component {
  state = {
    setupHipaa: false,
    setupPci: false,
    reportData: [],
    job: null,
    optionsEditState: false,
    eventWidgetItems: null,
    eventWidgetItemsForceReload: false,
    jobsCount: null,
    // "SA Events are moved to Audit Viewer"
    // ebIntegrationInstalled: null,
    // snsSubscriptionsAddNew: false,
    // snsSubscriptionsReload: false,
    // eventsFullWidgetReload: false,
    // eventsFullWidgetFiltersOpen: false,
    // eventsFullWidgetFilters: { Role: [], AuthUser: [] },
  };

  render() {
    return (
      <SecurityAdvisorContext.Provider
        value={{
          ...this.state,
          // <Setup Wizard Steps>
          setSetupHipaa: (setupHipaa) => this.setState({ setupHipaa }),
          setSetupPci: (setupPci) => this.setState({ setupPci }),
          // </Setup Wizard Steps>
          setJob: (job) => {
            if (notEmptyArray(job?.workers)) {
              job.workers = job.workers.sort((a, b) => {
                return new Date(b.updated_at) - new Date(a.updated_at);
              });
            }
            this.setState({ job });
          },
          getSortedWorkers: () => {
            if (notEmptyArray(this.state.job?.workers)) {
              const job = { ...this.state.job };
              job.workers = job.workers.sort((a, b) => {
                return new Date(b.updated_at) - new Date(a.updated_at);
              });

              return job.workers;
            }
            return [];
          },

          updateJob: (changes) => this.setState({ job: { ...this.state.job, ...changes } }),
          setOptionsEditState: (optionsEditState) => this.setState({ optionsEditState }),
          setEventWidgetItems: (eventWidgetItems) => this.setState({ eventWidgetItems }),
          setEventWidgetItemsForceReload: (eventWidgetItemsForceReload) => this.setState({ eventWidgetItemsForceReload }),
          setJobsCount: (jobsCount) => this.setState({ jobsCount }),
          // "SA Events are moved to Audit Viewer"
          // setEbIntegrationInstalled: (ebIntegrationInstalled) => this.setState({ ebIntegrationInstalled }),
          // setSnsSubscriptionsAddNew: (snsSubscriptionsAddNew) => this.setState({ snsSubscriptionsAddNew }),
          // setSnsSubscriptionsReload: (snsSubscriptionsReload) => this.setState({ snsSubscriptionsReload }),
          // setEventsFullWidgetReload: (eventsFullWidgetReload) => this.setState({ eventsFullWidgetReload }),
          // setEventsFullWidgetFiltersOpen: (eventsFullWidgetFiltersOpen) => this.setState({ eventsFullWidgetFiltersOpen }),
          // setEventsFullWidgetFilters: (eventsFullWidgetFilters) => this.setState({ eventsFullWidgetFilters }),
          // getEventsFullWidgetFiltersCountLabel: () => {
          //   let count = 0;
          //   if (notEmptyArray(this.state.eventsFullWidgetFilters?.Role)) count += this.state.eventsFullWidgetFilters.Role.length;
          //   if (notEmptyArray(this.state.eventsFullWidgetFilters?.AuthUser)) count += this.state.eventsFullWidgetFilters.AuthUser.length;
          //   return count;
          // },
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </SecurityAdvisorContext.Provider>
    );
  }
}
