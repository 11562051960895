import React, { useState } from 'react';
import { Select, Spin } from 'antd';
import { searchPermissions } from 'src/api/accesscontrol';
import PropTypes from 'prop-types';

const { Option } = Select;

const PermissionPicker = ({ width, onPick, defaultValue, placeholder = 'type to search permission...', size }) => {
  const [permissionList, setPermissionList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClear = async () => {
    console.log('handleClear');
    setPermissionList([]);
    onPick(null);
  };

  const handleSearchForPermissions = async (v) => {
    setPermissionList([]);
    if (v.length < 3) return;
    try {
      setLoading(true);
      let resp = await searchPermissions(v);
      //console.log('permissions:', resp);
      setPermissionList(resp?.entities ?? []);
      setLoading(false);
    } catch (error) {
      if (error === 'cancelled') return;
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnSelect = (v) => {
    console.log('PermissionPicker onSelect:', v);
    const p = permissionList.find((t) => t.permission === v);
    onPick(p);
  };

  return (
    <Select
      defaultValue={defaultValue}
      showSearch
      allowClear
      placeholder={placeholder}
      notFoundContent={loading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
      filterOption={false}
      onSearch={handleSearchForPermissions}
      onClear={handleClear}
      onSelect={handleOnSelect}
      style={{ width }}
      size={size}
      autoComplete='off'
      optionRender={({ value }) => {
        const item = permissionList.find((x) => x.permission === value);
        if (!item) return null;
        return (
          <div className='flex flex-col'>
            <div className='font-bold'>{item.permission}</div>
            <div className='text-xs font-light opacity-60' style={{ textWrap: 'pretty' }} dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        );
      }}
    >
      {permissionList.map((d) => (
        <Option key={d.permission}>{d.permission}</Option>
      ))}
    </Select>
  );
};

PermissionPicker.propTypes = {
  width: PropTypes.string,
  onPick: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
};

PermissionPicker.defaultProps = {
  width: '220px',
};

export default PermissionPicker;
