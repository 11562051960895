import React, { useState, useEffect } from 'react';
import { Table, Tag } from 'antd';
import { getIncidentsForRegions } from 'src/api/fsireport';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import styled from 'styled-components';
import PropTypes from 'prop-types';

dayjs.extend(utc);

const StylesWrapper = styled.div``;

const Incidents = ({ regions, exportData }) => {
  const [loading, setLoading] = useState(false);
  const [incidents, setIncidents] = useState([]);

  const columns = [
    { title: 'Id', key: 'id', width: 50, hidden: true },
    {
      title: 'Name',
      key: 'name',
      width: 250,
      render: (record) => <span className='text-xs'>{record.name}</span>,
    },
    {
      title: 'Date',
      key: 'date',
      width: 150,
      render: (record) => <span className='text-xs'>{dayjs(record.date).format('lll')}</span>,
    },
    {
      title: 'Regions',
      key: 'regions',
      width: 180,
      render: (record) => {
        // map over the list and create a div for each key-value pair
        return record.regions.map((item) => {
          return (
            <div style={{ flex: 1, textAlign: 'left' }}>
              <span className='text-xs'>{item}</span>
            </div>
          );
        });
      },
    },
    {
      title: 'Report',
      key: 'report',
      width: 400,
      render: (record) => {
        // map over the list and create a div for each key-value pair
        return record.report.map((item) => {
          return (
            <div className='text-xs' style={{ flex: 1, textAlign: 'left' }}>
              <p>{item.body}</p>
              <p className='text-right text-genesys-gray-500 '>{dayjs(item.date).format('lll')}</p>
            </div>
          );
        });
      },
    },
    {
      title: 'Status',
      key: 'status',
      width: 100,
      align: 'center',
      render: (record) => (
        <div style={{ textAlign: 'center' }}>
          <Tag color={record.status === 'Resolved' ? 'green' : 'orange'}>{record.status}</Tag>
        </div>
      ),
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    console.log('[Incidents] loadData()');
    try {
      setLoading(true);
      const resp = await getIncidentsForRegions(regions);
      console.log(resp);
      setIncidents(resp);
      if (exportData) exportData(resp);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StylesWrapper>
      <Table size='small' pagination={false} loading={loading} columns={columns} dataSource={incidents} rowKey={'id'} />
      <p className='mb-2'></p>
    </StylesWrapper>
  );
};

Incidents.propTypes = {
  regions: PropTypes.array,
  exportData: PropTypes.func,
};

Incidents.defaultProps = {
  regions: [],
};

export default Incidents;
