// useTokenChecker.js
import { useContext } from 'react';
// import PropTypes from 'prop-types';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import GlobalContext from 'src/context/GlobalContext';
import { refreshToken } from 'src/api/common';
import { logout } from 'src/misc/Session';
import useUserInfo from 'src/hooks/useUserInfo';
import { useInterval } from 'react-interval-hook';

const EXPIRY_BUFFER = 5 * 60; // 5 minutes

const TokenProvider = () => {
  // const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.authAccessToken);

  const { tokenExpiring, setTokenExpiring } = useContext(GlobalContext);
  const userInfo = useUserInfo();

  const getTokenValidity = (buffer) => {
    const expiresAt = localStorage.getItem(LOCAL_STORAGE_KEYS.authExpiresAt);
    const provider = localStorage.getItem(LOCAL_STORAGE_KEYS.authProvider);
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.authRefreshToken);
    const env = localStorage.getItem(LOCAL_STORAGE_KEYS.selectedGcRegion);

    if (userInfo && (!expiresAt || !provider || !refreshToken || !env)) {
      console.error('UserInfo is present but thre are no data to refresh the token:', { expiresAt, provider, refreshToken, env });
      logout();
      return;
    }

    try {
      const expiryDate = new Date(expiresAt);
      const currentDate = new Date();

      const remainingTime = (expiryDate.getTime() - currentDate.getTime() - buffer || 0) / 1000;
      // console.log(`Remaining time: ${remainingTime} (${Math.floor((remainingTime % 86400) / 3600)}h ${Math.floor((remainingTime % 3600) / 60)}m ${Math.floor(remainingTime % 60)}s)`);

      return remainingTime;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useInterval(() => {
    checkTokenValidity();
  }, 10000); // Check every 10 seconds

  const checkTokenValidity = async () => {
    try {
      const tokenValiditySeconds = getTokenValidity(0);

      if (tokenValiditySeconds < 0) {
        const newAccessToken = await refreshToken();
        // console.log('New access token:', newAccessToken);
        if (!newAccessToken) {
          console.warn('Failed to refresh the token. Redirect to the login page.');
          await logout();
          return;
        }
      } else if (tokenValiditySeconds <= EXPIRY_BUFFER) {
        if (!tokenExpiring) setTokenExpiring(true);
      } else {
        if (tokenExpiring) setTokenExpiring(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return null;
};

// TokenProvider.propTypes = {};

export default TokenProvider;
