export const ALLOWED_MODELS = ['9ApTTp1ovNQttKwb17jR2h'];

export const THEMES = [
  {
    // RESOURCE CENTER THEME - INDEX [0]
    mainBackground: '#ffffff',
    humanIcon: '#23395d',
    humanBackground: '#ff501f',
    humanText: '#fff9f6',
    machineIcon: '#ff501f',
    machineBackground: '#23395d',
    machineText: '#f6f7f9',
    scrollbar: '#f0f9fb',
  },
  {
    mainBackground: '#111111',
    humanBackground: '#162a36',
    humanText: '#f0f9fb',
    machineBackground: '#450605',
    machineText: '#fff3ed',
    scrollbar: '#162a36',
  },
];
