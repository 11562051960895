import React, { useContext, useEffect } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import NoData from 'src/components/layout/NoData';
import MasterAdminContext from 'src/context/MasterAdminContext';
import { Tabs } from 'antd';
import AdminMsgsEditorData from './AdminMsgsEditorData';
import AdminMsgsEditorTargets from './AdminMsgsEditorTargets';
import AdminMsgsEditorPost from './AdminMsgsEditorPost';
import { getAdminMessage } from 'src/api/notifications';
//import PropTypes from 'prop-types';

const AdminMsgsEditor = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { adminMessagesSelectedId, adminMessagesSelected, setAdminMessagesSelected, adminMessagesTabsDisabled } = useContext(MasterAdminContext);

  useEffect(() => {
    if (!adminMessagesSelectedId) {
      setAdminMessagesSelected(null);
      return;
    }
    loadData();
  }, [adminMessagesSelectedId]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getAdminMessage(adminMessagesSelectedId);
        setAdminMessagesSelected(resp);
      } catch (error) {
        console.error('error', error);
      }
    });
  };

  if (!adminMessagesSelectedId || !adminMessagesSelected) return <NoData />;

  return (
    <Tabs
      type='card'
      size='small'
      defaultActiveKey='1'
      items={[
        {
          key: '1',
          disabled: adminMessagesTabsDisabled,
          label: 'Content',
          children: <AdminMsgsEditorData />,
        },
        {
          key: '2',
          disabled: adminMessagesTabsDisabled,
          label: 'Targets',
          children: <AdminMsgsEditorTargets />,
        },
        {
          key: '3',
          disabled: adminMessagesTabsDisabled,
          label: 'Post',
          children: <AdminMsgsEditorPost />,
        },
      ]}
    />
  );
};

AdminMsgsEditor.propTypes = {};

export default AdminMsgsEditor;
