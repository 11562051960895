import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { statusList } from '../Misc/misc';

const WorkerStatus = ({ status, errMessage }) => {
  const [statusLabel, setStatusLabel] = useState(null);
  const [statusColor, setColor] = useState(null);
  const [statusIcon, setIcon] = useState(null);

  const statusComponent = (
    <div className='flex flex-row text-left items-center gap-1'>
      <div className='text-xl my-2' style={{ color: statusColor }}>
        {statusIcon}
      </div>
      {statusLabel}
      {errMessage && <> ...</>}
    </div>
  );

  useEffect(() => {
    if (!status) return;
    setStatusLabel(statusList.find((x) => x.key === status)?.value ?? status);
    setColor(statusList.find((x) => x.key === status)?.color ?? statusList.find((x) => x.key === 'default')?.color);
    setIcon(statusList.find((x) => x.key === status)?.icon ?? statusList.find((x) => x.key === 'default')?.icon);
  }, [status]);

  if (errMessage)
    return (
      <Popover placement='top' title={'Error'} content={<div style={{ maxWidth: '300px' }}>{errMessage}</div>}>
        {statusComponent}
      </Popover>
    );

  return statusComponent;
};

WorkerStatus.propTypes = {
  status: PropTypes.string,
  errMessage: PropTypes.string,
};

export default WorkerStatus;
