//import StylesWrapper from './SubjectItem.styles';
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

const SubjectItem = ({ item, onClick }) => {
  const showUserItem = () => {
    return (
      <>
        <Row>
          <Col span={19}>
            <strong>{item.name}</strong>
          </Col>
          <Col span={5} className=' ultralight'>
            <i className='ion-person btn-margin-right' />
            User
          </Col>
        </Row>
        <div>{getProps(item, ['name'])}</div>
      </>
    );
  };

  const showExternalContactItem = () => {
    return (
      <>
        <Row>
          <Col span={19}>
            <strong>{item.name}</strong>
          </Col>
          <Col span={5} className=' ultralight'>
            <i className='ion-ios-body btn-margin-right' />
            External Contact
          </Col>
        </Row>
        <div>{getProps(item, ['name'])}</div>
      </>
    );
  };

  const showGenericItem = () => {
    return (
      <>
        <Row>
          <Col span={19}>
            <strong>{item.name}</strong>
          </Col>
          <Col span={5} className=' ultralight'>
            <i className='ion-ios-help-outline btn-margin-right' />
            Other
          </Col>
        </Row>
        <div>{getProps(item, ['name'])}</div>
      </>
    );
  };

  const getProps = (obj, excludes) => {
    const result = [];
    for (const item of Object.entries(obj)) {
      if (excludes.includes(item[0])) continue;
      result.push(
        <Row key={item[0]}>
          <Col span={5} className='ultralight smaller'>
            {item[0]}:
          </Col>
          <Col span={19} className='quitelight smaller'>
            {getPropVal(item[1])}
          </Col>
        </Row>
      );
    }
    return result;
  };

  const getPropVal = (val) => {
    if (!val) return '-';
    else if (Array.isArray(val)) return val.map((x) => (!x ? '-' : x)).join(' | ');
    else if (typeof val === 'object') return `${JSON.stringify(val).substring(0, 50)}(...)`;
    else return val;
  };

  return (
    <div
      className={onClick ? 'item-clickable' : 'item-notclickable'}
      onClick={() => {
        if (!onClick) return;
        onClick(item);
      }}
    >
      {item.userId && showUserItem()}
      {item.externalContactId && showExternalContactItem()}
      {!item.userId && !item.externalContactId && showGenericItem()}
    </div>
  );
};

SubjectItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

SubjectItem.defaultProps = {};

export default SubjectItem;
