import React from 'react';
import { Button, Result } from 'antd';

const Page404 = () => {
  return (
    <div>
      <Result
        status='500'
        title='410'
        subTitle='Sorry, the page you visited has expired.'
        extra={
          <Button type='primary' onClick={() => (window.location.href = '/')}>
            Back Home
          </Button>
        }
      />
    </div>
  );
};

Page404.propTypes = {};

export default Page404;
