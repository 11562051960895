import React from 'react';
import { Typography } from 'antd';

const { Paragraph } = Typography;

const Controls = () => {
  return (
    <Paragraph>
      <p
        className='text-justify text-pretty indent-8 text-sm mt-4 mb-4 ml-2 mr-2'
        style={{
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
          whiteSpace: 'normal',
        }}
      >
        The application is designed to streamline the creation of GDPR requests through a versatile API. It offers both real and simulated API calls, allowing users to test and execute data privacy requests efficiently. This dual functionality ensures that organizations can seamlessly integrate and verify GDPR compliance processes within their systems, enhancing data protection and regulatory adherence.
      </p>
    </Paragraph>
  );
};

Controls.propTypes = {};

export default Controls;
