import React /*useEffect*/ from 'react';
import useTheme from 'src/hooks/useTheme';
import { MdOutlineLightMode, MdOutlineDarkMode } from 'react-icons/md';
import { Button } from 'antd';

//import PropTypes from 'prop-types';

const DarkModeSwitch = () => {
  const { toggleDarkMode, isDarkMode } = useTheme();

  // useEffect(() => {
  //   console.log('isDarkMode:', isDarkMode);
  // }, [isDarkMode]);

  return (
    <Button
      type='text'
      onClick={() => {
        toggleDarkMode();
      }}>
      <div className='flex flex-row items-center justify-center'>{isDarkMode ? <MdOutlineLightMode /> : <MdOutlineDarkMode />}</div>
    </Button>
  );
};

DarkModeSwitch.propTypes = {};

export default DarkModeSwitch;
