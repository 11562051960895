import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getMediaVideo } from 'src/api/media';
import { PlayCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { LuConstruction } from 'react-icons/lu';
import CorporateColors from 'src/misc/CorporateColors';
import MediaThemeSutro from 'player.style/sutro/react';

const VideoItem = ({ videoId, title, description, thumbnail, modifiedDate }) => {
  //const { executeAsyncProcess } = useAsyncProcesses();

  const [videoUrl, setVideoUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleWatch = async () => {
    setIsLoading(true);
    try {
      const resp = await getMediaVideo(videoId);
      setVideoUrl(resp);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      {!videoId ? (
        <div className='w-[480px] overflow-hidden'>
          <div className={'w-[480px] h-[263px] text-4xl text-sky-950 bg-gray-200 dark:text-sky-50 dark:bg-gray-800 rounded-lg cursor-not-allowed flex flex-row justify-center items-center opacity-40'}>
            <LuConstruction className='text-yellow-900 dark:text-yellow-100' />
          </div>
          <div className='font-bold text-lg opacity-60'>{title}</div>
          <div className='font-light text-xs opacity-60'>{description}</div>
        </div>
      ) : (
        <div className='w-[480px] overflow-hidden'>
          {!videoUrl && (
            <div style={{ backgroundImage: !isLoading && thumbnail ? `url(${thumbnail})` : null, backgroundSize: '100% 100%' }} className={`w-[480px] h-[263px] text-5xl text-sky-950 bg-sky-50 dark:text-sky-50 dark:bg-sky-950 rounded-lg cursor-pointer flex flex-row justify-center items-center ${isLoading && 'animate-pulse'}`} onClick={handleWatch} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
              {isLoading && <LoadingOutlined />}
              {isHover && !isLoading && <PlayCircleOutlined style={{ color: 'white', filter: `drop-shadow(0px 0px 6px ${CorporateColors.blue})` }} />}
              {/* {!isLoading && !thumbnail && <PlayCircleOutlined />} */}
            </div>
          )}
          {videoUrl && (
            <div className='flex flex-row justify-center items-center'>
              <MediaThemeSutro>
                <video autoPlay witdh='480px' height='263px' slot='media' src={videoUrl} playsInline crossOrigin='anonymous'>
                  <source src={videoUrl} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </MediaThemeSutro>
            </div>
          )}
          <div className='font-light text-xs text-right mt-2'> {modifiedDate}</div>
          <div className='font-bold text-lg mt-2'>{title}</div>
          <div className='font-light text-xs'>{description}</div>
        </div>
      )}
    </>
  );
};

VideoItem.propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  modifiedDate: PropTypes.string,
};

export default VideoItem;
