// import xlsx from 'better-xlsx';
// import objectPath from 'object-path';
import { saveAs } from 'file-saver';
// import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';
import './font';

const useExport = () => {
  const buildHeaders = (columns, combineHeaderColumnNames) => {
    const addHeaders = (cols, parentTitle = '') => {
      let headers = [];
      // console.log('addHeaders() cols:', cols);
      cols.forEach(({ title, dataIndex, children }) => {
        // console.log('Current column:', { title, dataIndex, children });
        if (!title) return; // Skip if title is empty

        const fullTitle = parentTitle ? `${parentTitle} ${title}` : title;

        if (children && Array.isArray(children)) {
          // console.log('Getting headers for children:', children);
          const childHeaders = addHeaders(children, fullTitle);
          headers = headers.concat(childHeaders);
        } else {
          headers.push({
            title: fullTitle,
            dataIndex, // Keep this for later as this is the actual field name from the data
          });
        }
      });
      return headers; // Return headers array
    };

    // Call function
    return addHeaders(columns);
  };

  // const exportToXLSX = ({ columns, data, fileName }) => {
  //   const file = new xlsx.File();
  //   const sheet = file.addSheet('Sheet1');
  //   const headerRow = sheet.addRow();
  //   columns.forEach(({ title, render }) => {
  //     if (render) return;
  //     const cell = headerRow.addCell();
  //     cell.value = title;
  //   });
  //   data.forEach((record) => {
  //     const row = sheet.addRow();
  //     columns.forEach(({ dataIndex, render }) => {
  //       if (render) return;
  //       const cell = row.addCell();
  //       cell.value = objectPath.get(record, dataIndex);
  //     });
  //   });

  //   file.saveAs('blob').then((blob) => {
  //     saveAs(blob, `${fileName}.xlsx`);
  //   });
  // };

  const exportToCSV = ({ columns, data, fileName, combineHeaderColumnNames = false }) => {
    // console.log('columns:', columns);
    // console.log('data:', data);

    let csv = '';

    // Build headers
    const csvColumns = buildHeaders(columns, combineHeaderColumnNames);
    csv = csvColumns.map((c) => c.title.replaceAll('"', '""')).join(',');
    // console.log('csv headers:', csv);

    csv += '\n';

    data.forEach((record) => {
      csvColumns.forEach(({ dataIndex }, index) => {
        if (index !== 0) csv += ',';
        if (typeof record[dataIndex] === 'string') {
          csv += `"${record[dataIndex].replaceAll('"', '""')}"`;
        } else {
          csv += `"${record[dataIndex] ?? ''}"`;
        }
      });
      csv += '\n';
    });

    // console.log('csv:', csv);
    saveAs(new Blob([csv]), `${fileName}.csv`);
  };

  // const exportToPDF = ({ columns, data, fileName, pdfTheme }) => {
  //   const doc = new jsPDF();
  //   doc.setFont('FreeSans');

  //   autoTable(doc, {
  //     styles: { font: 'FreeSans' },
  //     headStyles: { fontStyle: 'normal' },
  //     head: [columns.filter((c) => !c.render).map((c) => c.title)],
  //     body: data.map((r) => columns.filter((c) => !c.render).map((c) => objectPath.get(r, c.dataIndex))),
  //     theme: pdfTheme,
  //   });

  //   doc.save(`${fileName}.pdf`);
  // };

  return {
    // exportToXLSX,
    exportToCSV,
    // exportToPDF,
  };
};

export default useExport;
