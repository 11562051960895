import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getAiTemplate, postAiTemplate, patchAiTemplate, deleteAiTemplateApi } from 'src/api/aiTemplates';
import { Button, Space, Form, Input, message, Tabs, Popover } from 'antd';
import TitleBar from 'src/components/layout/TitleBar';
import { LuMenu } from 'react-icons/lu';
import { FaRobot } from 'react-icons/fa';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import { MdDelete } from 'react-icons/md';
import { uuid } from 'short-uuid';
import AITemplatesContext from 'src/context/AITemplatesContext';

const AiTemplateEditor = ({ aiTemplateId, onSave, onDelete }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();
  const { TextArea } = Input;
  const [messageApi, contextHolder] = message.useMessage();
  const [aiTemplate, setAiTemplate] = useState(null);
  const [modified, setModified] = useState(false);

  const { deleteAiTemplate, addNewCreatedAiTemplate, setSelectedTemplate, selectedTemplate } = useContext(AITemplatesContext);

  useEffect(() => {
    if (!aiTemplateId) {
      setAiTemplate(null);
      return;
    }
    if (aiTemplate?.id === aiTemplateId) return;
    setAiTemplate(null);
    loadData();
  }, [aiTemplateId]);

  useEffect(() => {
    console.log('[AiTemplateEditor] aiTemplate:', aiTemplate);
  }, [aiTemplate]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      console.log('[AiTemplateEditor] Load data for aiTemplateId:', aiTemplateId);
      try {
        setModified(false);
        const currentAiTemplate = await getAiTemplate(aiTemplateId);
        setAiTemplate(currentAiTemplate);
        setSelectedTemplate(currentAiTemplate);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleOnChange = (values) => {
    setModified(true);
  };

  const handleOnFinish = (values) => {
    console.log('[AiTemplateEditor] Success:', values);
    executeAsyncProcess(async () => {
      try {
        let resp = null;
        if (aiTemplate) {
          resp = await patchAiTemplate(aiTemplateId, { name: values.name, content: values.content });
        } else {
          resp = await postAiTemplate(values);
          addNewCreatedAiTemplate(resp);
        }
        if (onSave) onSave(resp);
        setModified(false);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Data has been saved',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('[AiTemplateEditor] Failed:', errorInfo);
    messageApi.open({
      key: uuid(),
      type: 'error',
      content: 'Provided data is invalid',
      duration: 3,
    });
  };

  const handleOnReset = () => {
    setModified(false);
  };

  const handlePermanentlyDelete = () => {
    console.log('[AiTemplateEditor] handlePernamentlyDelete');
    executeAsyncProcess(async () => {
      try {
        await deleteAiTemplateApi(aiTemplate.id);
        deleteAiTemplate(aiTemplate.id);
        if (onDelete) onDelete(aiTemplate.id);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'AI template has been deleted',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  if (!aiTemplate)
    return (
      <div className='h-full flex flex-row justify-center items-center'>
        <div>No data</div>
      </div>
    );

  return (
    <>
      {contextHolder}
      <TitleBar
        busyIndicator={false}
        colorBackground={theme.backgroundBase}
        // title={
        //   <Space className='btn'>
        //     <Space className='btn'>
        //       <Skeleton.Input active={true} size={28} />
        //       <BsChevronRight />
        //       <Skeleton.Input active={true} size={28} />
        //       <BsChevronRight />
        //       <Skeleton.Input active={true} size={28} />
        //     </Space>
        //   </Space>
        // }
        title={aiTemplate.name}
        afterTitleExtras={<></>}>
        <Popover
          content={
            <Space direction='vertical'>
              <YesNoDialog
                title='Permanently delete'
                body={
                  <>
                    Do you want to delete <strong>{aiTemplate.name}</strong> ?
                  </>
                }
                onYesClick={handlePermanentlyDelete}
                iconYes={<MdDelete />}
                showRed={true}
                labelYes='Yes, delete AI template'
                labelNo='Cancel'>
                <Button className='w-[200px]' color={theme.textBase} disabled={selectedTemplate?.id === 'default'}>
                  Permanently delete
                </Button>
              </YesNoDialog>
            </Space>
          }
          title='Actions'
          trigger='click'
          placement='bottomRight'>
          <Button type='text' icon={<LuMenu />} />
        </Popover>
      </TitleBar>
      <div className='p-4'>
        <Tabs
          defaultActiveKey='1'
          items={[
            {
              key: '1',
              label: (
                <Space className='btn'>
                  <FaRobot />
                  AI Template
                </Space>
              ),
              children: (
                <div>
                  <Form name='aiTemplateEditor' style={{ maxWidth: 600 }} layout='vertical' initialValues={aiTemplate} onChange={handleOnChange} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} onReset={handleOnReset} autoComplete='off'>
                    {/* <Form.Item name='disabled' valuePropName='checked'>
                      <Checkbox>Is disabled?</Checkbox>
                    </Form.Item> */}
                    <Form.Item label='Id' name='id'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='Name' name='name' rules={[{ required: true, message: 'Name is required' }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label='Content' name='content'>
                      <TextArea className='mt-1' rows={6} placeholder='Enter the content of your template here...' />
                    </Form.Item>
                    <Form.Item label='Created at' name='created_at'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='Updated at' name='updated_at'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item>
                      <Space>
                        <Button htmlType='reset' disabled={!modified}>
                          Reset
                        </Button>
                        <Button type='primary' htmlType='submit' disabled={!modified}>
                          Submit
                        </Button>
                      </Space>
                    </Form.Item>
                  </Form>
                </div>
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

AiTemplateEditor.propTypes = {
  aiTemplateId: PropTypes.number,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AiTemplateEditor;
