import React from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip } from 'antd';
import { FaCheckCircle, FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';

const statusIcons = {
    Healthy: <FaCheckCircle className='text-green-500' />,
    Recovered: <FaInfoCircle className='text-orange-500' />,
    Elevated: <FaExclamationTriangle className='text-red-500' />,
};

const dictServiceNames = {
    'test': 'Test Service',
    'sharepoint': 'Sharepoint',
    'snowflake': 'Snowflake',
};

const ServiceCard = ({ name, status, lastErrorMessage, lastErrorDate, eventCount, eventCountCurrent, errCount, className }) => {
    const iconColor = {
        Healthy: 'text-green-500',
        Recovered: 'text-orange-500',
        Elevated: 'text-red-500',
        Failed: 'text-gray-500', // You can add other statuses if needed
    }[status];

    const Icon = statusIcons[status] || <FaInfoCircle className='text-gray-500' />;

    return (
        <Card className={`shadow-lg ${className}`} size='small' title={
            <div className='flex items-center justify-between'>
                <Tooltip title={`Current Status: ${status}`}>
                    <div className='flex items-center'>
                        {React.cloneElement(Icon, { className: `${iconColor} text-xl mr-2` })}
                        <span className='font-semibold'>{dictServiceNames[name] ?? name}</span>
                    </div>
                </Tooltip>
            </div>
        }>

            <div className='text-xs opacity-80'>
                <p className='mt-0'>
                    Req Count: {eventCount} ({eventCountCurrent} today)
                </p>
                {errCount > 0 && (
                    <>
                        <p className='mb-0 mt-0'>
                            Errors Count: {errCount}
                        </p>
                        <span className='mt-0'>
                            Last Error:{' '}
                            {lastErrorMessage ? (
                                <Tooltip title={lastErrorMessage}>
                                    <span className='inline-block max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap align-bottom'>
                                        {lastErrorMessage}
                                    </span>
                                </Tooltip>
                            ) : (
                                'None'
                            )}
                        </span>
                        <p className='mb-0'>
                            Error Date: {lastErrorDate ? new Date(lastErrorDate).toLocaleString() : 'N/A'}
                        </p></>
                )}
            </div>
        </Card>
    );
};

ServiceCard.propTypes = {
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    lastErrorMessage: PropTypes.string,
    lastErrorDate: PropTypes.string,
    eventCount: PropTypes.number.isRequired,
    eventCountCurrent: PropTypes.number.isRequired,
    errCount: PropTypes.number.isRequired,
    className: PropTypes.string,
};

export default ServiceCard;