import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Router from './router/Router';
import GlobalProvider from 'src/context/GlobalProvider';
import NotificationsProvider from './context/NotificationsProvider';
import SQAConversationProvider from './context/SQAConversationProvider';
import AuthProvider from './providers/AuthProvider';
import ThemeProvider from './providers/ThemeProvider';
import GlobalWsClient from './components/WebSocket/GlobalWsClient';
import MasterAdminProvider from './context/MasterAdminProvider';
import SecurityAdvisorProvider from 'src/context/SecurityAdvisorProvider';
import DataSubjectRequestsProvider from 'src/context/DataSubjectRequestsProvider';
import AIModelsProvider from 'src/context/AIModelsProvider';
import AITemplatesProvider from 'src/context/AITemplatesProvider';
import AuditViewerProvider from './context/AuditViewerProvider';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isoWeek from 'dayjs/plugin/isoWeek';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import TokenProvider from './providers/TokenProvider';
// import ModeProvider from './providers/ModeProvider';
import 'dayjs/locale/en-gb';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(quarterOfYear);
dayjs.extend(isBetween);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.locale('en-gb');
dayjs.updateLocale(dayjs.locale(), { weekStart: 1 });

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GlobalProvider>
    {/* <ModeProvider> */}
    <ThemeProvider>
      <AuthProvider>
        <TokenProvider />
        <NotificationsProvider>
          <SQAConversationProvider>
            <MasterAdminProvider>
              <AITemplatesProvider>
                <AIModelsProvider>
                  <SecurityAdvisorProvider>
                    <DataSubjectRequestsProvider>
                      <AuditViewerProvider>
                        <Router />
                        <GlobalWsClient />
                      </AuditViewerProvider>
                    </DataSubjectRequestsProvider>
                  </SecurityAdvisorProvider>
                </AIModelsProvider>
              </AITemplatesProvider>
            </MasterAdminProvider>
          </SQAConversationProvider>
        </NotificationsProvider>
      </AuthProvider>
    </ThemeProvider>
    {/* </ModeProvider> */}
  </GlobalProvider>
);
