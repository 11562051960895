import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StylesWrapper = styled.div`
  .droppable-wrapper-no-dropping {
    position: relative;
    opacity: 1;
    border: none;
    border: 2px transparent;
  }

  .droppable-wrapper-dropping {
    position: relative;
    opacity: 0.5;
    border: 1.5px dashed #408dc4;
  }

  #droppable-wrapper-drop-badge {
    position: absolute;
    z-index: 99999;
    left: 0;
    right: 0;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    width: 50px;
    text-align: center;
    pointer-events: none;
  }

  #droppable-wrapper-drop-badge-icon {
    font-size: 50px;
    color: #0000bb;
  }
`;

export function Component(props) {
  const dropRef = useRef(null);
  const [drag, setDrag] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(true);
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(true);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  useEffect(() => {
    let div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);
    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
  });

  return (
    <StylesWrapper>
      <div ref={dropRef} className={drag ? 'droppable-wrapper-dropping' : 'droppable-wrapper-no-dropping'}>
        <div id='droppable-wrapper-drop-badge' hidden={!drag}>
          <i className='ion-ios-upload-outline' id='droppable-wrapper-drop-badge-icon' />
        </div>
        {props.children}
      </div>
    </StylesWrapper>
  );
}

Component.propTypes = {
  children: PropTypes.any.isRequired,
  handleDrop: PropTypes.func.isRequired,
};

export default Component;
