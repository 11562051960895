'use client';

import { useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { Button } from 'antd';
import { ENV } from 'src/misc/Config';
import CorporateColors from 'src/misc/CorporateColors';

export default function ModalVideo({ thumb, thumbWidth, thumbHeight, thumbAlt, video, videoWidth, videoHeight }) {
  const [modalOpen, setModalOpen] = useState(false);
  const videoRef = useRef(null);

  return (
    <div>
      {ENV.showVideoBtn && (
        <Button
          style={{ backgroundColor: CorporateColors.orange, borderColor: CorporateColors.orange, height: '64px' }}
          type='primary'
          size='large'
          className='flex items-center transform  hover:scale-105 transition-transform duration-300 ease-in-out group rounded-2xl'
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <svg className='w-6 h-6 fill-current text-white shrink-0' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z' />
            <path d='M10 17l6-5-6-5z' />
          </svg>
          <span className='ml-3 text-white font-bold'>Watch the full video (4 min)</span>
        </Button>
      )}
      {/* End: Video thumbnail */}

      <Transition show={modalOpen} as={Fragment} afterEnter={() => videoRef.current?.play()}>
        <Dialog initialFocus={videoRef} onClose={() => setModalOpen(false)}>
          {/* Modal backdrop */}
          <Transition.Child className='fixed inset-0 z-[99999] bg-black bg-opacity-75 transition-opacity' enter='transition ease-out duration-200' enterFrom='opacity-0' enterTo='opacity-100' leave='transition ease-out duration-100' leaveFrom='opacity-100' leaveTo='opacity-0' aria-hidden='true' />
          {/* End: Modal backdrop */}

          {/* Modal dialog */}
          <Transition.Child className='fixed inset-0 z-[99999] overflow-hidden flex items-center justify-center transform px-4 sm:px-6' enter='transition ease-out duration-200' enterFrom='opacity-0 scale-95' enterTo='opacity-100 scale-100' leave='ttransition ease-out duration-200' leaveFrom='oopacity-100 scale-100' leaveTo='opacity-0 scale-95'>
            <div className='max-w-6xl mx-auto h-full flex items-center'>
              <Dialog.Panel className='w-full max-h-full aspect-video bg-black overflow-hidden'>
                <video ref={videoRef} width={videoWidth} height={videoHeight} loop controls>
                  <source src={video} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </Dialog.Panel>
            </div>
          </Transition.Child>
          {/* End: Modal dialog */}
        </Dialog>
      </Transition>
    </div>
  );
}

ModalVideo.propTypes = {
  thumb: PropTypes.string,
  thumbWidth: PropTypes.number,
  thumbHeight: PropTypes.number,
  thumbAlt: PropTypes.string,
  video: PropTypes.string,
  videoWidth: PropTypes.number,
  videoHeight: PropTypes.number,
};
