import React, { useContext } from 'react';
import { Row, Col, Table, Tabs, Button, Space } from 'antd';

import * as dayjs from 'dayjs';

import { textSorter } from 'src/misc/Misc';
import { requestTypeList, replacementTypeList, getStatusLabel, getStatusColor, getColor, extractName } from '../Misc/Dictionary';
import JobStatus from './JobStatus';
import ExportViewer from '../Components/ExportViewer/ExportViewer';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';
import { getJobFile, getContentFileUrl } from 'src/api/dataSubjectRequests';
import { FaCircle } from 'react-icons/fa';

const { TabPane } = Tabs;

const RequestList = () => {
  const { job } = useContext(DataSubjectRequestsContext);

  const columns = [
    {
      title: 'Type',
      key: 'requestType',
      render: (record) => (
        <>
          <i className={`${requestTypeList.find((x) => x.value === record.type)?.icon} btn-margin-right`} />
          {requestTypeList.find((x) => x.value === record.type)?.label ?? '-'}
        </>
      ),
      sorter: (a, b) => textSorter(a, b, 'type'),
    },
    {
      title: 'Status',
      key: 'status',
      align: 'left',
      render: (record) => (
        <>
          {['waiting', 'searching', 'updating', 'deleting', 'started'].includes(record.status) ? (
            <Space>
              <span className='relative flex h-3 w-3 scale-75'>
                <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75'></span>
                <span className='relative inline-flex rounded-full h-3 w-3 bg-sky-500'></span>
              </span>
              {getStatusLabel(record.status)}
            </Space>
          ) : (
            <>
              <FaCircle style={{ marginRight: '8px', scale: '0.7', color: getColor(getStatusColor(record.status), 4) }} />
              {getStatusLabel(record.status)}
            </>
          )}

          <br />
          <span className='text-xs italic'>{dayjs(record.updated_at).fromNow()}</span>
        </>
      ),
      sorter: (a, b) => textSorter(a, b, 'type'),
    },
    {
      title: 'Start time',
      key: 'startTime',
      align: 'center',
      render: (record) => {
        const stepsOrdered = record?.steps?.sort((a, b) => (a.start_time > b.start_time ? 1 : -1));
        const startTime = stepsOrdered?.[0]?.start_time ?? null;

        if (!startTime) return '-';

        return (
          <>
            {dayjs(startTime).fromNow()}
            <br />
            <span className='text-xs italic'>at {dayjs(startTime).format('LLL')}</span>
          </>
        );
      },
      sorter: (a, b) => {
        const v1 = a?.steps?.sort((a, b) => (a.start_time > b.start_time ? 1 : -1))[0]?.start_time ?? null;
        const v2 = b?.steps?.sort((a, b) => (a.start_time > b.start_time ? 1 : -1))[0]?.start_time ?? null;
        return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
      },
      defaultSortOrder: 'descend',
    },
  ];

  const expandableRow = (record) => {
    const disabledFlag = dayjs().diff(dayjs(record.updated_at), 'days') > 30;
    return (
      <Tabs defaultActiveKey='status' size='small'>
        <TabPane tab={'Status'} key='status'>
          <JobStatus currentJob={job} steps={record?.steps} />
        </TabPane>
        {'GDPR_EXPORT' === record?.type && Array.isArray(record?.objects) && record.objects.find((x) => 'file' === x.type) && (
          // Disable TabPane if record.updated_at is longer than 30 days ago
          <TabPane tab={'Zip'} key='zip' disabled={disabledFlag}>
            <Button
              type='link'
              style={{ maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis' }}
              onClick={async () => {
                const file = record.objects.find((x) => 'file' === x.type)?.value;
                const fileName = extractName(file);
                console.log('fileName', fileName);
                // const resp = await getJobFile('fixed-files', fileName); //todo: use real file name and job id
                const resp = file?.includes('fixed-files') ? await getJobFile('fixed-files', fileName) : await getContentFileUrl(file);
                let pom = document.createElement('a');
                pom.setAttribute('href', file?.includes('fixed-files') ? resp : resp.preSignedUrl);
                pom.setAttribute('download', fileName);
                pom.setAttribute('target', '_blank');
                pom.click();
              }}
            >
              <strong>{extractName(record.objects.find((x) => 'file' === x.type)?.value)}</strong>
            </Button>
          </TabPane>
        )}
        {'GDPR_EXPORT' === record?.type && Array.isArray(record?.objects) && record.objects.find((x) => 'folder' === x.type) && (
          <TabPane tab={'Files'} key='exportedFiles' disabled={disabledFlag}>
            <ExportViewer jobId={job.id} requestId={record.id} />
          </TabPane>
        )}

        {'GDPR_UPDATE' === record?.type && Array.isArray(record?.changes) && (
          <TabPane tab={'Changes'} key='changes'>
            {record.changes.map((x, i) => (
              <Row key={i} className='smaller'>
                <Col style={{ width: '80px' }} className='quitelight'>
                  {replacementTypeList.find((y) => y.value === x.type)?.label ?? x.type}:
                </Col>
                <Col>
                  From <span className='italic'>{x.existingValue}</span> to <span className='italic'> {x.updatedValue}</span>
                </Col>
              </Row>
            ))}
          </TabPane>
        )}
      </Tabs>
    );
  };

  return (
    <Row>
      <Col style={{ width: '100%' }}>
        <Table
          style={{ marginTop: '4px' }}
          size='small'
          dataSource={Array.isArray(job?.requests) ? job.requests : null}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{
            expandedRowRender: expandableRow,
          }}
        />
      </Col>
    </Row>
  );
};

RequestList.propTypes = {};

export default RequestList;
