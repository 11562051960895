import React, { useEffect, useState, useContext } from 'react';
import { Modal, Form, Input, notification } from 'antd';
import PropTypes from 'prop-types';
import { getAiModelConnectors } from 'src/api/aiModels';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import AIModelsContext from 'src/context/AIModelsContext';

const { Item } = Form;

const AiModelCreate = ({ open, onClose, onSubmit }) => {
  const [form] = Form.useForm();
  const { executeAsyncProcess } = useAsyncProcesses();

  const { setAiModelConnectors, aiModelConnectors } = useContext(AIModelsContext);

  // const [loading, setLoading] = useState(false);

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    console.log('aiModelConnectors', aiModelConnectors);
  }, [aiModelConnectors]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getAiModelConnectors();
        setAiModelConnectors(resp);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleOnValuesChange = async (changedValues, allValues) => {
    form
      .validateFields()
      .then(() => {
        setDisabled(false);
      })
      .catch((errorFields) => {
        setDisabled(errorFields.errorFields.length > 0);
      });
  };

  const handleOnFinish = (values) => {
    if (onSubmit) onSubmit(values);
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: 'Error',
      description: <>Provided data is invalid</>,
      duration: 5,
    });
  };

  const handleOnReset = () => {
    //setModified(false);
  };

  return (
    open && (
      <Modal
        open={open}
        title='Create an AI Model'
        onCancel={() => {
          if (onClose) onClose();
        }}
        okButtonProps={{ disabled: disabled }}
        onOk={form.submit}
      >
        <Form name='aiModelForm' style={{ maxWidth: 600 }} layout='vertical' form={form} initialValues={{}} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} onReset={handleOnReset} onValuesChange={handleOnValuesChange} autoComplete='off'>
          <Item label='Name' name='name' required={true} rules={[{ required: true, message: 'Enter a name for your AI model' }]}>
            <Input placeholder='Enter a name for your AI model' autoComplete='off' allowClear />
          </Item>
          <Item label='Description' name='description' required={true} rules={[{ required: true, message: 'Enter a description of your AI model' }]}>
            <Input placeholder='Enter a quick description of your AI model' autoComplete='off' allowClear />
          </Item>
          {/* <div className='mt-4'>
            <Form.Item>
              <Space>
                <Button type='primary' htmlType='submit' disabled={disabledSave}>
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </div> */}
        </Form>
      </Modal>
    )
  );
};

AiModelCreate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

AiModelCreate.defaultProps = {
  open: false,
};

export default AiModelCreate;
