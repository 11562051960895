import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const AccountTypePicker = ({ width, onChange }) => {
  return (
    <Select style={{ width }} placeholder='select an account type...' onChange={onChange}>
      <Select.Option value='PARTNER'>PARTNER</Select.Option>
      <Select.Option value='INDIRECT_CUSTOMER'>INDIRECT_CUSTOMER</Select.Option>
      <Select.Option value='DIRECT_CUSTOMER'>DIRECT_CUSTOMER</Select.Option>
    </Select>
  );
};

AccountTypePicker.propTypes = {
  width: PropTypes.string,
  onChange: PropTypes.func,
};

export default AccountTypePicker;
