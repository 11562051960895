import React from 'react';
import useUserInfo from 'src/hooks/useUserInfo';
import useScreen from 'src/hooks/useScreen';
import { Tag } from 'antd';
//import PropTypes from 'prop-types';

const TenantBadge = () => {
  const { isMobile } = useScreen();
  const { provider, tenant_name, third_party_env } = useUserInfo();

  if (isMobile || provider !== 'gc') return null;

  return (
    <Tag>
      {tenant_name} @ {third_party_env}
    </Tag>
  );
};

TenantBadge.propTypes = {};

export default TenantBadge;
