import { logout } from 'src/misc/Session';
import { message } from 'antd';
import { LOCAL_STORAGE_KEYS, ENV, IDENTITY_PROVIDERS, LOGOUT_REASONS } from 'src/misc/Config';
import dayjs from 'dayjs';

export const handleResponse = async (response, handle401 = true, showErrorMsg = true, handle403 = true, handle404 = true) => {
  if (response.ok) {
    return response.status === 200 || response.status === 202 ? response.json() : null;
  }

  if (handle404 && response.status === 404) {
    const error = { ...((await response?.json()) ?? {}), status: response.status };
    throw error;
  }

  if (handle401 && response.status === 401) {
    logout(LOGOUT_REASONS.tokenExpired);
    return;
  }

  if (response.status === 403) {
    if (handle403) {
      window.location.href = '/403';
      return;
    } else {
      const error = { ...((await response?.json()) ?? {}), status: response.status };
      throw error;
    }
  }

  if (showErrorMsg) {
    let _message = 'Unknown server error';
    try {
      const body = await response?.json();
      if (body?.message) _message = body.message;
      else if (body?.error) _message = body.error;
      else if (body?.statusCode) _message = 'Error code: ' + body.statusCode;
    } catch (error) {
      console.error(error);
    }
    // notification.error({
    //   message: 'API Error',
    //   description: <>{message ? <div className='text-xs text-gray-800'>{message}</div> : null}</>,
    //   duration: 5,
    //   placement: 'top',
    //   style: {
    //     backgroundColor: 'black',
    //   },
    // });

    message.error(_message ? <div className='text-xs max-w-3xl custom-message'>{_message}</div> : null, 5);
  }

  throw new Error(`${response.status} ${response.statusText}`); //
};

export const getAccessHeaders = async () => {
  const token = (await getAccessToken()) ?? 'not-provided';
  const env = localStorage.getItem(LOCAL_STORAGE_KEYS.selectedGcRegion) ?? 'not-provided';
  const provider = localStorage.getItem(LOCAL_STORAGE_KEYS.authProvider) ?? 'not-provided';

  if (window.location.hostname === 'localhost') {
    // This is to work around a bug with serverless-offline: https://issueantenna.com/repo/dherault/serverless-offline/issues/1766
    const authorization = 'test';
    return { token, provider, env, authorization };
  }

  return { token, provider, env };
};

export const getAccessToken = async () => {
  const access_token = localStorage.getItem(LOCAL_STORAGE_KEYS.authAccessToken);
  const refresh_token = localStorage.getItem(LOCAL_STORAGE_KEYS.authRefreshToken);
  const expires_at = localStorage.getItem(LOCAL_STORAGE_KEYS.authExpiresAt);
  if (!access_token) return null;
  if (refresh_token && dayjs().isAfter(dayjs(expires_at))) {
    return refreshToken(refresh_token);
  }
  return access_token;
};

export const refreshToken = async (refresh_token) => {
  if (!refresh_token) {
    refresh_token = localStorage.getItem(LOCAL_STORAGE_KEYS.authRefreshToken);
  }

  let body = {
    refresh_token,
  };

  const provider = localStorage.getItem(LOCAL_STORAGE_KEYS.authProvider);
  switch (provider) {
    case IDENTITY_PROVIDERS.awsCognito: {
      body = {
        ...body,
        provider: IDENTITY_PROVIDERS.awsCognito,
      };
      break;
    }
    case IDENTITY_PROVIDERS.genesysCloud: {
      const env = localStorage.getItem(LOCAL_STORAGE_KEYS.selectedGcRegion);
      if (!env) throw new Error('No Genesys Cloud region');
      body = {
        ...body,
        provider: IDENTITY_PROVIDERS.genesysCloud,
        env,
      };
      break;
    }
    default: {
      throw new Error(`Not supported provider: [${provider}]`);
    }
  }

  //TODO: Handle the case where refreshing the token fails
  const respBody = await handleResponse(
    await fetch(`${ENV.apiUrl}/auth/oauth2/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }),
    true,
    false
  );

  if (respBody.access_token && respBody.access_token && respBody.expires_in) {
    if (respBody.access_token) localStorage.setItem(LOCAL_STORAGE_KEYS.authAccessToken, respBody.access_token);
    if (respBody.refresh_token) localStorage.setItem(LOCAL_STORAGE_KEYS.authRefreshToken, respBody.refresh_token); // TODO: once the database is in place store the refresh token in the backend DB
    if (respBody.expires_in) {
      const expires_in = respBody.expires_in;
      const expires_at = new Date(new Date().getTime() + expires_in * 1000).toISOString();
      localStorage.setItem(LOCAL_STORAGE_KEYS.authExpiresAt, expires_at);
    }
    return respBody.access_token;
  }

  return null;
};
