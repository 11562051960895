import React, { Component } from 'react';
import AIModelsContext from './AIModelsContext';

export default class AIModelsContextProvider extends Component {
  state = {
    aiModels: [],
    selectedModel: null,
    aiModelConnectors: [],
  };

  render() {
    return (
      <AIModelsContext.Provider
        value={{
          ...this.state,
          aiModels: this.state.aiModels,
          setAiModels: (aiModels) => this.setState({ aiModels }),
          setSelectedModel: (selectedModel) => this.setState({ selectedModel }),
          setSelectedModelById: (selectedModelId) => {
            const selectedModel = this.state.aiModels.find((u) => selectedModelId === u.id);
            this.setState({ selectedModel });
          },
          selectedModel: this.state.selectedModel,

          updateConnectorDef: (id, props) => {
            console.log('updateConnectorDef', id, props);

            const connectors = this.state.selectedModel.connectors_config.map((c) => {
              if (id === c.id) {
                return { ...c, ...props };
              }
              return c;
            });

            this.setState({ selectedModel: { ...this.state.selectedModel, connectors_config: connectors } });
          },

          removeConnectorDef: (id) => {
            console.log('removeConnectorDef', id);
            const connectors = this.state.selectedModel.connectors_config.filter((c) => id !== c.id);
            this.setState({ selectedModel: { ...this.state.selectedModel, connectors_config: connectors } });
          },

          addNewCreatedAiModel: (aiModel) => {
            const aiModels = [{ ...aiModel, newCreated: true }].concat(this.state.aiModels);
            this.setState({ aiModels });
          },
          updateAiModel: (aiModel_id, props) => {
            console.log('updateAiModel', aiModel_id, props);
            const aiModels = this.state.aiModels.map((u) => {
              if (aiModel_id === u.id) {
                return { ...u, ...props };
              }
              return u;
            });
            this.setState({ aiModels });
          },
          deleteAiModel: (aiModel_id) => {
            console.log('deleteAiModel', aiModel_id);
            const aiModels = this.state.aiModels.filter((u) => aiModel_id !== u.id);
            this.setState({ aiModels });
          },
          aiModelConnectors: this.state.aiModelConnectors,
          setAiModelConnectors: (aiModelConnectors) => this.setState({ aiModelConnectors }),
          getConnectorNameById: (id) => {
            // console.log('all connectors', this.state.aiModelConnectors);
            const connector = this.state.aiModelConnectors.find((c) => id === c.id);
            return connector ? connector.name : 'Unknown';
          },
        }}>
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </AIModelsContext.Provider>
    );
  }
}
