import React, { useState, useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import GlobalContext from 'src/context/GlobalContext';
import PropTypes from 'prop-types';
import { Radio, DatePicker, Tooltip, Select } from 'antd';
import dayjs from 'dayjs';
import { FaCalendarCheck } from 'react-icons/fa';
import { notEmptyArray } from 'src/misc/Misc';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';

const { RangePicker } = DatePicker;
const DashboardFilters = ({ onChange }) => {
  const { servicesForTenant } = useContext(TeacketContext);
  const { commandPressed } = useContext(GlobalContext);

  const [periodType, setPeriodType] = useState('week');
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [serviceId, setServiceId] = useState(localStorage.getItem(LOCAL_STORAGE_KEYS.teacketDashboardTtServiceId) ?? servicesForTenant[0]?.id);

  useEffect(() => {
    if (!onChange) return;
    if (!selectedPeriod || !periodType || !serviceId) {
      onChange(null);
      return;
    }
    onChange({ periodType, selectedPeriod, serviceId });
  }, [selectedPeriod, serviceId]);

  useEffect(() => {
    console.log('periodType:', periodType);
    switch (periodType) {
      case 'week':
        setSelectedPeriod([dayjs().utc().subtract(3, 'week').startOf('isoWeek').startOf('day'), dayjs().utc().endOf('isoWeek').endOf('day')]);
        break;
      case 'month':
        setSelectedPeriod([dayjs().utc().subtract(2, 'month').startOf('month').startOf('day'), dayjs().utc().endOf('month').endOf('day')]);
        break;
      default:
        setSelectedPeriod(null);
        break;
    }
  }, [periodType]);

  useEffect(() => {
    console.log('selectedPeriod:', selectedPeriod);
  }, [selectedPeriod]);

  const handleWeekOnChange = (dates) => {
    console.log('handleWeekOnChange:', dates);
    if (!dates) return;
    const [from, to] = dates;
    setSelectedPeriod([dayjs(from.format('YYYY-MM-DD')).utc(true).startOf('isoWeek').startOf('day'), dayjs(to.format('YYYY-MM-DD')).utc(true).endOf('isoWeek').endOf('day')]);
  };

  const handleMonthOnChange = (dates) => {
    if (!dates) return;
    const [from, to] = dates;
    setSelectedPeriod([dayjs(from.format('YYYY-MM-DD')).utc(true).startOf('month').startOf('day'), dayjs(to.format('YYYY-MM-DD')).utc(true).endOf('month').endOf('day')]);
  };

  const handleServiceOnChange = (v) => {
    setServiceId(v);
    localStorage.setItem(LOCAL_STORAGE_KEYS.teacketDashboardTtServiceId, v);
  };

  const formatWeek = (value) => `week of ${value.startOf('week').startOf('day').format('MMM DD')}`;
  const formatMonth = (value) => value.format('MMM YYYY');

  return (
    <div className='flex flex-row gap-4 items-center'>
      <div className='flex flex-row gap-2 items-center'>
        <Radio.Group
          value={periodType}
          buttonStyle='solid'
          size='small'
          onChange={(e) => {
            setPeriodType(e.target.value);
          }}
        >
          <Radio.Button value='week'>Weeks</Radio.Button>
          <Radio.Button value='month'>Months</Radio.Button>
        </Radio.Group>
        {periodType && selectedPeriod && <RangePicker style={{ width: '320px' }} picker={periodType} value={selectedPeriod} onChange={periodType === 'week' ? handleWeekOnChange : handleMonthOnChange} format={periodType === 'week' ? formatWeek : formatMonth} size='small' allowClear={false} />}

        {commandPressed && (
          <Tooltip
            title={
              true === notEmptyArray(selectedPeriod) ? (
                <div className='text-xs opacity-60 font-thin'>
                  <div className='opacity-100 font-normal'>Resultant date range:</div>
                  <div>
                    from: <span className='opacity-100 font-bold'>{selectedPeriod[0].utc().format()}</span>
                  </div>
                  <div>
                    to: <span className='opacity-100 font-bold'>{selectedPeriod[1].utc().format()}</span>
                  </div>
                </div>
              ) : (
                'a range is not selected'
              )
            }
          >
            <FaCalendarCheck className='text-genesys-orange-base opacity-60 hover:opacity-100' />
          </Tooltip>
        )}
      </div>

      <Select
        size='small'
        value={serviceId}
        onChange={handleServiceOnChange}
        className='w-60'
        options={servicesForTenant?.map((x) => {
          return { value: x.id, label: x.name };
        })}
        placeholder='select a service...'
      />
    </div>
  );
};

DashboardFilters.propTypes = {
  onChange: PropTypes.func,
};

export default DashboardFilters;
