import React, { useContext, useState } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import SecurityAdvisorContext from 'src/context/SecurityAdvisorContext';
import { Slider, Tag, Button, message, Space, Card, Input } from 'antd';
import { patchJob } from 'src/api/securityAdvisor';

import { IoMdLogIn } from 'react-icons/io';
import { GoLaw } from 'react-icons/go';
import { SiLetsencrypt } from 'react-icons/si';
import { uuid } from 'short-uuid';
import { LuUnplug } from 'react-icons/lu';
import useTheme from 'src/hooks/useTheme';

const FineTuningSecurity = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { job, updateJob } = useContext(SecurityAdvisorContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { isDarkMode } = useTheme();

  const [options, setOptions] = useState([
    {
      id: 'oauthNotUsed',
      name: 'Stale Oauth Clients',
      properties: {
        value: job?.score_settings?.oauthNotUsed && (job?.score_settings?.oauthNotUsed.value || job?.score_settings?.oauthNotUsed.value === 0) ? job?.score_settings?.oauthNotUsed.value : 5,
        priority: job?.score_settings?.oauthNotUsed && (job?.score_settings?.oauthNotUsed.priority || job?.score_settings?.oauthNotUsed.priority === 0) ? job?.score_settings?.oauthNotUsed.priority : 5,
        percentage: job?.score_settings?.oauthNotUsed?.percentage ? job?.score_settings?.oauthNotUsed.percentage : [30, 70],
      },
      defaults: {
        value: 3,
        priority: 5,
        percentage: [30, 70],
      },
      category: 'Authorization',
      icon: <IoMdLogIn />,
    },
    {
      id: 'usersNotLogged',
      name: 'Users Not Logged In',
      default: 12,
      properties: {
        value: job?.score_settings?.usersNotLogged && (job?.score_settings?.usersNotLogged.value || job?.score_settings?.usersNotLogged.value === 0) ? job?.score_settings?.usersNotLogged.value : 12,
        priority: job?.score_settings?.usersNotLogged && (job?.score_settings?.usersNotLogged.priority || job?.score_settings?.usersNotLogged.priority === 0) ? job?.score_settings?.usersNotLogged.priority : 5,
        percentage: job?.score_settings?.usersNotLogged?.percentage ? job?.score_settings?.usersNotLogged.percentage : [30, 70],
      },
      defaults: {
        value: 12,
        priority: 5,
        percentage: [30, 70],
      },
      category: 'Authorization',
    },
    {
      id: 'sessionTimeout',
      name: 'Session Timeout',
      properties: {
        range: job?.score_settings?.sessionTimeout && job?.score_settings?.sessionTimeout.range ? job?.score_settings?.sessionTimeout.range : [15, 60],
        priority: job?.score_settings?.sessionTimeout && (job?.score_settings?.sessionTimeout.priority || job?.score_settings?.sessionTimeout.priority === 0) ? job?.score_settings?.sessionTimeout.priority : 5,
      },
      defaults: {
        range: [15, 60],
        priority: 5,
      },
      category: 'Authorization',
    },
    {
      id: 'passwordRequirements',
      name: 'Password Requirements',
      properties: {
        minimumLength: job?.score_settings?.passwordRequirements && job?.score_settings?.passwordRequirements?.minimumLength ? job?.score_settings?.passwordRequirements.minimumLength : 8,
        minimumDigits: job?.score_settings?.passwordRequirements && job?.score_settings?.passwordRequirements?.minimumDigits ? job?.score_settings?.passwordRequirements.minimumDigits : 1,
        minimumLetters: job?.score_settings?.passwordRequirements && job?.score_settings?.passwordRequirements?.minimumLetters ? job?.score_settings?.passwordRequirements.minimumLetters : 1,
        minimumUpper: job?.score_settings?.passwordRequirements && job?.score_settings?.passwordRequirements?.minimumUpper ? job?.score_settings?.passwordRequirements.minimumUpper : 1,
        minimumLower: job?.score_settings?.passwordRequirements && job?.score_settings?.passwordRequirements?.minimumLower ? job?.score_settings?.passwordRequirements.minimumLower : 1,
        minimumSpecials: job?.score_settings?.passwordRequirements && job?.score_settings?.passwordRequirements?.minimumSpecials ? job?.score_settings?.passwordRequirements.minimumSpecials : 1,
        minimumAgeSeconds: job?.score_settings?.passwordRequirements && job?.score_settings?.passwordRequirements?.minimumAgeSeconds ? job?.score_settings?.passwordRequirements.minimumAgeSeconds : 0,
        expirationDays: job?.score_settings?.passwordRequirements && job?.score_settings?.passwordRequirements?.expirationDays ? job?.score_settings?.passwordRequirements.expirationDays : 90,
        priority: job?.score_settings?.passwordRequirements && (job?.score_settings?.passwordRequirements.priority || job?.score_settings?.passwordRequirements.priority === 0) ? job?.score_settings?.passwordRequirements.priority : 5,
      },
      defaults: {
        minimumLength: 8,
        minimumDigits: 1,
        minimumLetters: 1,
        minimumUpper: 1,
        minimumLower: 1,
        minimumSpecials: 1,
        minimumAgeSeconds: 60,
        expirationDays: 90,
        priority: 5,
      },
      category: 'Authorization',
    },
    {
      id: 'encryptionKeys',
      name: 'Encryption Keys',
      properties: {
        range: job?.score_settings?.encryptionKeys || job?.score_settings?.encryptionKeys.range === 0 ? job?.score_settings?.encryptionKeys.range : [90, 180],
        priority: job?.score_settings?.encryptionKeys || job?.score_settings?.encryptionKeys.priority === 0 ? job?.score_settings?.encryptionKeys.priority : 5,
      },
      defaults: {
        range: [90, 180],
        priority: 5,
      },
      category: 'Encryption',
      icon: <SiLetsencrypt />,
    },
    {
      id: 'notTlsTrunks',
      name: 'Not TLS Trunks',
      properties: {
        priority: job?.score_settings?.notTlsTrunks && (job?.score_settings?.notTlsTrunks.priority || job?.score_settings?.notTlsTrunks.priority === 0) ? job.score_settings.notTlsTrunks.priority : 5,
        percentage: job?.score_settings?.notTlsTrunks?.percentage ? job.score_settings.notTlsTrunks.percentage : [30, 70],
      },
      defaults: {
        priority: 5,
        percentage: [30, 70],
      },
      category: 'Encryption',
    },
    {
      id: 'emebeddableDomainList',
      name: 'Emebeddable Domain List',
      properties: {
        priority: job?.score_settings?.emebeddableDomainList && (job?.score_settings?.emebeddableDomainList.priority || job?.score_settings?.emebeddableDomainList.priority === 0) ? job.score_settings.emebeddableDomainList.priority : 5,
      },
      defaults: {
        priority: 5,
      },
      category: 'Integrations',
      icon: <LuUnplug />,
    },
    {
      id: 'ipAddressAllowlist',
      name: 'IP Address Allowlist',
      properties: {
        priority: job?.score_settings?.ipAddressAllowlist && (job?.score_settings?.ipAddressAllowlist.priority || job?.score_settings?.ipAddressAllowlist.priority === 0) ? job.score_settings.ipAddressAllowlist.priority : 5,
      },
      defaults: {
        priority: 5,
      },
      category: 'Integrations',
    },
    {
      id: 'hipaa',
      name: 'HIPAA',
      properties: {
        priority: job?.score_settings?.hipaa && (job?.score_settings?.hipaa?.priority || job?.score_settings?.hipaa?.priority === 0) ? job.score_settings.hipaa.priority : 5,
      },
      defaults: {
        priority: 5,
      },
      category: 'Regulations',
      icon: <GoLaw />,
    },
    {
      id: 'pci',
      name: 'PCI',
      properties: {
        priority: job?.score_settings?.pci && (job?.score_settings?.pci?.priority || job?.score_settings?.pci?.priority === 0) ? job.score_settings.pci.priority : 5,
      },
      defaults: {
        priority: 5,
      },
      category: 'Regulations',
    },
  ]);

  const percentageComponent = (id) => {
    const option = options.find((o) => o.id === id);
    const low = option.properties.percentage[0] === 0 ? 'low: 0%' : 'low: 0-' + option.properties.percentage[0] + '%';
    const med = `med: ${option.properties.percentage[0] + 1}-${option.properties.percentage[1] - 1}%`;
    const high = option.properties.percentage[1] === 100 ? 'high: 100%' : `high: ${option.properties.percentage[1]}-100%`;

    return (
      <div className='text-center text-xs -mt-1'>
        <div className='inline-block scale-75 -mr-4'>
          <Tag color='green'>{low}</Tag>
        </div>
        <div className='inline-block scale-75 -mr-4'>
          <Tag color='orange'>{med}</Tag>
        </div>
        <div className='inline-block scale-75'>
          <Tag color='red'>{high}</Tag>
        </div>
      </div>
    );
  };

  const additionalProperties = (option) => {
    switch (option.id) {
      case 'oauthNotUsed':
        return (
          <div>
            <span className='text-xs font-light'>Select inactivity period to mark OAuth clients as Stale</span>
            <div className='flex gap-2'>
              <span className='text-xs font-extralight mb-2 mt-2'>1</span>
              <div style={{ flex: 1 }}>
                <Slider value={option.properties.value} min={1} max={24} onChange={(value) => handleOptionUpdate(option.id, { value })} />
              </div>
              <span className='text-xs font-extralight mb-2 mt-2'>24 months</span>
            </div>

            {/* Risk Level visualization */}
            <p className='text-xs font-light mb-0'>Risk Level visualization</p>
            <div className='flex gap-2'>
              <span className='text-xs font-extralight mb-2 mt-2'>0%</span>
              <div style={{ flex: 1 }}>
                <Slider
                  range={{
                    draggableTrack: true,
                  }}
                  value={option.properties.percentage}
                  min={0}
                  max={100}
                  step={5}
                  onChange={(value) => {
                    const [minVal, maxVal] = value;

                    // Ensure that the two ranges are not equal
                    if (minVal === maxVal) {
                      return; // Prevent the slider from updating if values are the same
                    }

                    handleOptionUpdate(option.id, { percentage: value });
                  }}
                />
              </div>
              <span className='text-xs font-extralight mb-2 mt-2'>100%</span>
            </div>
            {percentageComponent(option.id)}
            <p className='text-xs font-light text-left mt-2'>The ratio of all defined OAuth clients to the number of OAuth clients that have not been used for {option.properties.value} months.</p>
            {/* Risk Level visualization */}
          </div>
        );
      case 'usersNotLogged':
        return (
          <div>
            <span className='text-xs font-light'>Choose the inactivity period that marks a user as inactive.</span>
            <div className='flex gap-2'>
              <span className='text-xs font-extralight mb-2 mt-2'>1</span>
              <div style={{ flex: 1 }}>
                <Slider value={option.properties.value} min={1} max={24} onChange={(value) => handleOptionUpdate(option.id, { value })} />
              </div>
              <span className='text-xs font-extralight mb-2 mt-2'>24 months</span>
            </div>

            {/* Risk Level visualization */}
            <p className='text-xs font-light mb-0'>Risk Level visualization</p>
            <div className='flex gap-2'>
              <span className='text-xs font-extralight mb-2 mt-2'>0%</span>
              <div style={{ flex: 1 }}>
                <Slider
                  range={{
                    draggableTrack: true,
                  }}
                  value={option.properties.percentage}
                  min={0}
                  max={100}
                  step={5}
                  onChange={(value) => {
                    const [minVal, maxVal] = value;

                    // Ensure that the two ranges are not equal
                    if (minVal === maxVal) {
                      return; // Prevent the slider from updating if values are the same
                    }

                    handleOptionUpdate(option.id, { percentage: value });
                  }}
                />
              </div>
              <span className='text-xs font-extralight mb-2 mt-2'>100%</span>
            </div>
            {percentageComponent(option.id)}
            <p className='text-xs font-light text-left mt-2'>The ratio of all configured users to the number of users that were inactive for {option.properties.value} months.</p>
            {/* Risk Level visualization */}
          </div>
        );
      case 'sessionTimeout':
        return (
          <>
            <div>
              <span className='text-xs font-light'>Setting that enforces user authentication after a period of inactivity.</span>
              <div className='flex gap-2'>
                <span className='text-xs font-extralight mb-2 mt-2'>5</span>
                <div style={{ flex: 1 }}>
                  <Slider
                    range={{
                      draggableTrack: true,
                    }}
                    value={option.properties.range}
                    min={5}
                    max={480}
                    step={5}
                    onChange={(value) => {
                      const [minVal, maxVal] = value;

                      // Ensure that the two ranges are not equal
                      if (minVal === maxVal) {
                        return; // Prevent the slider from updating if values are the same
                      }
                      handleOptionUpdate(option.id, { range: value });
                    }}
                  />
                </div>
                <span className='text-xs font-extralight mb-2 mt-2'>480 minutes</span>
              </div>
            </div>
            <p className='text-xs font-light text-left mb-2'>
              If the Session Timeout parameter retrieved from Genesys Cloud is <span className='font-bold'>{option.properties.range[0]}</span> minutes or less, the security level will be set to Low Risk. If the parameter is between <span className='font-bold'>{option.properties.range[0]}</span> and <span className='font-bold'>{option.properties.range[1]}</span> minutes, it will be classified as Medium Risk. Finally, if the value is <span className='font-bold'>{option.properties.range[1]}</span>{' '}
              minutes or more, it will be designated as High Risk.
            </p>
          </>
        );
      case 'passwordRequirements':
        return (
          <div>
            <p className='text-xs font-light'>
              Set password requirements. <br />
              If any of the following parameters differ from the Genesys Cloud Organization settings, the risk level will be set to High.
            </p>
            <div className='grid grid-cols-2 gap-2'>
              <div>
                <span className='text-xs font-light'>Minimum Length ({option.properties.minimumLength})</span>
                <Slider value={option.properties.minimumLength} min={1} max={20} onChange={(value) => handleOptionUpdate(option.id, { minimumLength: value })} />
              </div>

              <div>
                <span className='text-xs font-light'>Minimum Digits ({option.properties.minimumDigits})</span>
                <Slider value={option.properties.minimumDigits} min={1} max={10} onChange={(value) => handleOptionUpdate(option.id, { minimumDigits: value })} />
              </div>
              <div>
                <span className='text-xs font-light'>Minimum Letters ({option.properties.minimumLetters})</span>
                <Slider value={option.properties.minimumLetters} min={1} max={10} onChange={(value) => handleOptionUpdate(option.id, { minimumLetters: value })} />
              </div>
              <div>
                <span className='text-xs font-light'>Minimum Upper ({option.properties.minimumUpper})</span>
                <Slider value={option.properties.minimumUpper} min={1} max={10} onChange={(value) => handleOptionUpdate(option.id, { minimumUpper: value })} />
              </div>
              <div>
                <span className='text-xs font-light'>Minimum Lower ({option.properties.minimumLower})</span>
                <Slider value={option.properties.minimumLower} min={1} max={10} onChange={(value) => handleOptionUpdate(option.id, { minimumLower: value })} />
              </div>
              <div>
                <span className='text-xs font-light'>Minimum Specials ({option.properties.minimumSpecials})</span>
                <Slider value={option.properties.minimumSpecials} min={1} max={10} onChange={(value) => handleOptionUpdate(option.id, { minimumSpecials: value })} />
              </div>

              <div>
                <span className='text-xs font-light'>Minimum Age (in seconds)</span>
                <Slider value={option.properties.minimumAgeSeconds} min={1} max={3600} onChange={(value) => handleOptionUpdate(option.id, { minimumAgeSeconds: value })} />
              </div>
              <div className='text-xs font-extralight mt-6 w-2/4	'>
                <Input size='small' type='number' value={option.properties.minimumAgeSeconds} onChange={(e) => handleOptionUpdate(option.id, { minimumAgeSeconds: e.target.value })} />
              </div>

              <div>
                <span className='text-xs font-light'>Expiration Days</span>
                <Slider value={option.properties.expirationDays} min={1} max={365} onChange={(value) => handleOptionUpdate(option.id, { expirationDays: value })} />
              </div>
              <div className='text-xs font-extralight mt-6 w-2/4	'>
                <Input size='small' type='number' value={option.properties.expirationDays} onChange={(e) => handleOptionUpdate(option.id, { expirationDays: e.target.value })} />
              </div>
            </div>
          </div>
        );
      case 'encryptionKeys':
        return (
          <>
            <div>
              <span className='text-xs font-light'>Risk level depends on the age of the last generated Encryption Keys</span>
              <div className='flex gap-2'>
                <span className='text-xs font-extralight mb-2 mt-2'>5</span>
                <div style={{ flex: 1 }}>
                  <Slider
                    range={{
                      draggableTrack: true,
                    }}
                    value={option.properties.range}
                    min={1}
                    max={365}
                    step={5}
                    onChange={(value) => {
                      const [minVal, maxVal] = value;

                      // Ensure that the two ranges are not equal
                      if (minVal === maxVal) {
                        return; // Prevent the slider from updating if values are the same
                      }
                      handleOptionUpdate(option.id, { range: value });
                    }}
                  />
                </div>
                <span className='text-xs font-extralight mb-2 mt-2'>365</span>
                {/* <span className='text-xs font-light text-right mb-1'>
                  low: {option.properties.range[0]} sec, high: {option.properties.range[1]} sec
                </span> */}
              </div>
            </div>
            <p className='text-xs font-light text-left mb-2'>
              {/* {!job?.pci_required
                ? `PCI is disabled. If last generated date for Encryption keys retrieved from Genesys Cloud will be lower or equal ${option.properties.range[0]} days, security level will be set to Low risk, if the same parameter will be greater than ${option.properties.range[0]}, it will be marked as a Medium risk.`
                : `PCI is enabled. If last generated date for Encryption keys retrieved from Genesys Cloud will be lower or equal ${option.properties.range[0]} days, security level will be set to Low risk, if the same parameter will be between ${option.properties.range[0]} and ${option.properties.range[1]}, it will be marked as Medium risk, finally it will be marked as High risk if the value will be greater than ${option.properties.range[1]} days.`} */}
              If last generated date for Encryption keys retrieved from Genesys Cloud will be lower or equal <span className='font-bold'>{option.properties.range[0]}</span> days, security level will be set to Low risk, if the same parameter will be between <span className='font-bold'>{option.properties.range[0]}</span> and <span className='font-bold'>{option.properties.range[1]}</span>, it will be marked as Medium risk, finally it will be marked as High risk if the value will be greater than{' '}
              <span className='font-bold'>{option.properties.range[1]}</span> days.
            </p>
          </>
        );
      case 'notTlsTrunks':
        return (
          <div>
            {/* Risk Level visualization */}
            <p className='text-xs font-light mb-0'>Risk Level visualization</p>
            <div className='flex gap-2'>
              <span className='text-xs font-extralight mb-2 mt-2'>0%</span>
              <div style={{ flex: 1 }}>
                <Slider
                  range={{
                    draggableTrack: true,
                  }}
                  value={option.properties.percentage}
                  min={0}
                  max={100}
                  step={5}
                  onChange={(value) => {
                    const [minVal, maxVal] = value;

                    // Ensure that the two ranges are not equal
                    if (minVal === maxVal) {
                      return; // Prevent the slider from updating if values are the same
                    }

                    handleOptionUpdate(option.id, { percentage: value });
                  }}
                />
              </div>
              <span className='text-xs font-extralight mb-2 mt-2'>100%</span>
            </div>
            {percentageComponent(option.id)}
            <p className='text-xs font-light text-left mt-2'>The ratio of all defined & enabled trunks (with protocol assigned) to the number of trunks with protocol different than TLS.</p>
            {/* Risk Level visualization */}
          </div>
        );

      case 'emebeddableDomainList':
        return (
          <div>
            <p className='text-xs font-light text-left mt-2'>Low Risk can be achieved when the feature is enabled on Genesys Cloud and there is at least one domain configured, otherwise High Risk is returned.</p>
            {/* Risk Level visualization */}
          </div>
        );
      case 'ipAddressAllowlist':
        return (
          <div>
            <p className='text-xs font-light text-left mt-2'>Low Risk can be achieved when the feature is enabled on Genesys Cloud and there is at least one ip address configured, otherwise High Risk is returned.</p>
            {/* Risk Level visualization */}
          </div>
        );
      case 'hipaa':
        return (
          <div>
            <p className='text-xs font-light text-left mt-2'>Low Risk can be achieved when the feature is enabled on Genesys Cloud, otherwise High Risk is returned.</p>
            {/* Risk Level visualization */}
          </div>
        );
      case 'pci':
        return (
          <div>
            <p className='text-xs font-light text-left mt-2'>Low Risk can be achieved when the feature is enabled on Genesys Cloud, otherwise High Risk is returned.</p>
            {/* Risk Level visualization */}
          </div>
        );

      default:
        break;
    }
  };

  if (!job) return null;

  const handleOptionUpdate = (id, value) => {
    let myOptions = [...options];
    try {
      const key = Object.keys(value)[0];
      const newValue = Object.values(value)[0];

      myOptions.find((o) => o.id === id).properties[key] = newValue;

      setOptions(myOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveOptions = async (e) => {
    await executeAsyncProcess(async () => {
      try {
        const changes = { score_settings: {} };
        options.forEach((option) => {
          changes.score_settings[option.id] = option.properties;
        });

        updateJob(changes);
        await patchJob(job.id, changes);

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Job has been updated',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleRestoreDef = async (e) => {
    let myOptions = [...options];
    myOptions.forEach((option) => {
      if (option.properties) {
        option.properties = JSON.parse(JSON.stringify(option.defaults));
      }
    });
    if (!job?.hipaa_required) options.find((o) => o.id === 'hipaa').properties.priority = 0;
    if (!job?.pci_required) options.find((o) => o.id === 'pci').properties.priority = 0;

    setOptions(myOptions);
  };

  //const msg = <span className='text-xs'>Move the slider to adjust the security score. Higher values will affect the global score.</span>;

  // Group options by category
  const groupedOptions = options.reduce((acc, option) => {
    if (!acc[option.category]) {
      acc[option.category] = { options: [], icon: option.icon };
    }
    acc[option.category].options.push(option);
    return acc;
  }, {});

  function getGradientColor(percentage) {
    const startColor = [0, 100, 0];
    const endColor = [170, 42, 0];
    const midColor = startColor.map((start, i) => {
      const end = endColor[i];
      const delta = end - start;
      return (start + delta * percentage).toFixed(0);
    });
    return `rgb(${midColor.join(',')})`;
  }

  return (
    <div>
      {contextHolder}

      <div className='pt-2 max-h-[800px] overflow-y-auto'>
        {Object.keys(groupedOptions).map((category, index) => (
          <div key={index} className='mb-2'>
            <Card
              title={
                <span>
                  {groupedOptions[category].icon} {category}
                </span>
              }
              size='small'
            >
              {groupedOptions[category].options.map((option) => (
                <div
                  key={option.id}
                  className={`mb-2 border border-gray-300 p-4 rounded-lg shadow-sm ${isDarkMode ? ' bg-gray-800' : 'bg-slate-100'}   
                ${!job?.pci_required && option.id === 'pci' ? 'disabled' : ''}
                ${!job?.hipaa_required && option.id === 'hipaa' ? 'disabled' : ''}                
                `}
                >
                  <div>{option.name}</div>
                  <div className='flex gap-2'>
                    <span className='text-xs font-extralight mb-2 mt-2'>Priority</span>
                    <div style={{ flex: 1 }}>
                      <Slider
                        styles={{
                          track: {
                            background: 'transparent',
                          },
                          tracks: {
                            background: `linear-gradient(to right, ${getGradientColor(0)} 0%, ${getGradientColor(10)} 100%)`,
                          },
                        }}
                        value={(!job?.pci_required && option.id === 'pci') || (!job?.hipaa_required && option.id === 'hipaa') ? 0 : option?.properties?.priority}
                        min={0}
                        max={10}
                        onChange={(value) => handleOptionUpdate(option.id, { priority: value })}
                      />
                    </div>
                    <span className='text-xs font-extralight mb-2 mt-2'>max</span>
                  </div>
                  {option?.properties?.priority > 0 && option.properties && additionalProperties(option)}
                  {option?.properties?.priority === 0 && <span className='text-xs font-light text-center text-red-700'>This option is disabled</span>}
                </div>
              ))}
            </Card>
          </div>
        ))}
      </div>
      <div className='relative w-full mt-4'>
        <Space>
          <Button onClick={handleRestoreDef}>Restore to Default</Button>
          <Button type='primary' className='absolute inset-y-0 right-0' onClick={handleSaveOptions}>
            Save
          </Button>
        </Space>
      </div>
    </div>
  );
};

FineTuningSecurity.propTypes = {};

export default FineTuningSecurity;
