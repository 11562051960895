import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import MuiTheme from 'src/misc/MuiTheme';
import { BarChart } from '@mui/x-charts/BarChart';
import NoData from 'src/components/layout/NoData';
import useTheme from 'src/hooks/useTheme';
import { colors } from '../../Misc/misc';

//import PropTypes from 'prop-types';

const RemainingOpenInPeriods = () => {
  const { isDarkMode } = useTheme();
  const { dashboardDataTickets } = useContext(TeacketContext);

  const [data, setData] = useState(null);
  const [period, setPeriod] = useState(null);

  useEffect(() => {
    const { remainingByPeriods } = dashboardDataTickets ?? {};
    if (!remainingByPeriods) return;

    const d = remainingByPeriods.map((item) => ({
      id: item.label,
      label: item.label,
      value: item.value,
    }));
    setData(d);

    const p = remainingByPeriods[0]?.period ?? null;
    setPeriod(p);
  }, [dashboardDataTickets]);

  if (!data)
    return (
      <div className='py-12'>
        <NoData />
      </div>
    );

  return (
    <MuiTheme>
      <BarChart colors={[isDarkMode ? colors.remaining.light : colors.remaining.dark]} xAxis={[{ label: period, scaleType: 'band', data: data?.map((x) => x.label) }]} series={[{ data: data?.map((x) => x.value) }]} height={300} />
    </MuiTheme>
  );
};

RemainingOpenInPeriods.propTypes = {};

export default RemainingOpenInPeriods;
