import React, { useContext /*, useState */ } from 'react';
//import { Table, Tag, Tooltip, Space } from 'antd';
//import UserImage from 'src/components/layout/UserImage';

//import { textSorter, dateTimeSorter } from 'src/misc/Misc';
//import { getDemoTypeIcon, getDemoTypeLabel, requestTypeList, getStatusLabel, getStatusColor, getColor } from '../Misc/Dictionary';

//import dayjs from 'dayjs';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';
//import * as dayjs from 'dayjs';

//import UserImage from 'src/components/layout/UserImage';

//import PropTypes from 'prop-types';

const JobType2 = () => {
  const { job } = useContext(DataSubjectRequestsContext);
  // This is Placeholder for Real Job Type2 (Live demo, calls etc);
  return (
    <>
      {job && (
        <div>
          Job Type 2
          <br />
          {job?.name}
        </div>
      )}
    </>
  );
};

JobType2.propTypes = {};

export default JobType2;
