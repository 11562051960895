import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';

export function ReportsHandler() {
  const [reportParams, setReportParams] = useState(null);
  const { reportId, gcEnv, gcToken } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('[ReportsHandler] reportId: ', reportId);
    console.log('[ReportsHandler] gcEnv: ', gcEnv);
    console.log('[ReportsHandler] gcToken: ', gcToken);
    if (!['5'].includes(reportId)) navigate('/404');
    const q = queryString.parse(window.location.search);
    console.log('[ReportsHandler] query string params: ', q);
    setReportParams(q);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadReportComponent = () => {
    console.log('[ReportsHandler] loadReportComponent()');
    switch (reportId) {
      // case '1':
      //   return lazy(() => import('./CustomerInsights/CustomerInsights'));
      // case '2':
      //   return lazy(() => import('./P2PMigration/P2PMigration'));
      // case '3':
      //   return lazy(() => import('./P2PMigration/P2PEngage'));
      // case '4':
      //   return lazy(() => import('./GdprDemo/GdprDemo'));
      case '5':
        return lazy(() => import('./SecurityAdvisor/SecurityAdvisor'));
      default:
        return;
    }
  };

  return <Suspense>{reportId !== null && reportParams !== null && React.createElement(loadReportComponent(), { gcEnv, gcToken, reportParams })}</Suspense>;
}

export default ReportsHandler;
