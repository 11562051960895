import React, { useContext } from 'react';
import FsiReportContext from 'src/context/FsiReportContext';
import { Input /*,Spin*/ } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//import PropTypes from 'prop-types';

const ProjectListSearch = () => {
  const { projectQuery, setProjectQuery /*, projectListLoading*/ } = useContext(FsiReportContext);
  return (
    <Input
      size='small'
      className='w-60'
      placeholder='Search...'
      //projectListLoading ? <Spin size='small' className='mr-2' /> : <SearchOutlined className='mr-2' />}
      prefix={<SearchOutlined className='mr-2' />}
      allowClear
      value={projectQuery}
      onChange={(e) => {
        setProjectQuery(e.target.value);
      }}
    />
  );
};

ProjectListSearch.propTypes = {};

export default ProjectListSearch;
