import React, { useEffect, useState } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import PropTypes from 'prop-types';
import NoData from 'src/components/layout/NoData';
import { getInvitationLink } from 'src/api/accesscontrol';
import { GiEntryDoor } from 'react-icons/gi';
import { Space, Button } from 'antd';
import dayjs from 'dayjs';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';

const InvitationLinkDetails = ({ selectedInvitationId, onResend }) => {
  const { executeAsyncProcess } = useAsyncProcesses();

  const [invitationLink, setInvitationLink] = useState(null);

  useEffect(() => {
    setInvitationLink(null);
    if (!selectedInvitationId) {
      return;
    }
    loadData();
  }, [selectedInvitationId]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getInvitationLink(selectedInvitationId);
        setInvitationLink(resp);
      } catch (error) {
        console.error('error', error);
      }
    });
  };

  return (
    <DraggableLayoutItem
      title={
        invitationLink ? (
          <Space className='whitespace-nowrap btn'>
            <GiEntryDoor className='opacity-60' />
            <div className='overflow-hidden overflow-ellipsis'>{invitationLink?.email ?? 'unknown'}</div>
          </Space>
        ) : (
          ''
        )
      }
      titleExtras={
        invitationLink ? (
          <Button
            type='primary'
            size='small'
            onClick={() => {
              if (!invitationLink?.id) return;
              if (!onResend) return;
              onResend(invitationLink.id);
            }}
          >
            Re-send the link
          </Button>
        ) : (
          ''
        )
      }
    >
      {!invitationLink ? (
        <NoData />
      ) : (
        <>
          <div className='min-h-[300px] flex flex-col gap-4 p-4'>
            <div className='grid grid-cols-2 gap-2'>
              <div>
                <div className='text-xs font-light opacity-60'>ID</div>
                <div>{invitationLink?.id}</div>
              </div>
              <div>
                <div className='text-xs font-light opacity-60'>Email</div>
                <div>{invitationLink?.email}</div>
              </div>
              <div>
                <div className='text-xs font-light opacity-60'>Tenant ID</div>
                <div>{invitationLink.tenant_id}</div>
              </div>
              <div>
                <div className='text-xs font-light opacity-60'>Tenant name</div>
                <div>{invitationLink.tenant_name}</div>
              </div>
              <div>
                <div className='text-xs font-light opacity-60'>Env</div>
                <div>{invitationLink.env}</div>
              </div>
              <div>
                <div className='text-xs font-light opacity-60'>Roles</div>
                <div>{invitationLink.roles?.join(', ')}</div>
              </div>
              <div>
                <div className='text-xs font-light opacity-60'>Expires</div>
                {invitationLink.expires_at ? (
                  <>
                    <div>{dayjs(invitationLink.expires_at).fromNow()}</div>
                    <div className='font-light opacity-60'>at {dayjs(invitationLink.expires_at).format('lll')}</div>
                  </>
                ) : (
                  <div>n/a</div>
                )}
              </div>
              <div>
                <div className='text-xs font-light opacity-60'>Created</div>
                {invitationLink.created_at ? (
                  <>
                    <div>{dayjs(invitationLink.created_at).fromNow()}</div>
                    <div className='font-light opacity-60'>at {dayjs(invitationLink.created_at).format('lll')}</div>
                  </>
                ) : (
                  <div>n/a</div>
                )}
              </div>
              <div>
                <div className='text-xs font-light opacity-60'>Updated</div>
                {invitationLink.updated_at ? (
                  <>
                    <div>{dayjs(invitationLink.updated_at).fromNow()}</div>
                    <div className='font-light opacity-60'>at {dayjs(invitationLink.updated_at).format('lll')}</div>
                  </>
                ) : (
                  <div>n/a</div>
                )}
              </div>
              <div>
                <div className='text-xs font-light opacity-60'>Used</div>
                {invitationLink.used_at ? (
                  <>
                    <div>{dayjs(invitationLink.used_at).fromNow()}</div>
                    <div className='font-light opacity-60'>at {dayjs(invitationLink.used_at).format('lll')}</div>
                  </>
                ) : (
                  <div>n/a</div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </DraggableLayoutItem>
  );
};

InvitationLinkDetails.propTypes = {
  selectedInvitationId: PropTypes.string,
  onResend: PropTypes.func,
};

export default InvitationLinkDetails;
