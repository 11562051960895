import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Modal, Button, Switch } from 'antd';
import { MdCancel } from 'react-icons/md';
import { IoMdCheckmarkCircle } from 'react-icons/io';

const YesNoDialog = ({ children, title, body, labelNo, labelYes, onNoClick, onYesClick, iconYes, iconNo, showLock, showRed = false, lockWidth, lockColor, labelLocked, labelUnlocked }) => {
  const [showModal, setShowModal] = useState(false);
  const [locked, setLocked] = useState(false);

  useEffect(() => {
    if (showModal) setLocked(showLock);
  }, [showModal]);

  const handleChildClick = (e) => {
    if (e.preventDefault) e.preventDefault();
    if (e.stopPropagation) e.stopPropagation();
    setShowModal(true);
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  const handleYesClick = async () => {
    setShowModal(false);
    if (!onYesClick) return;
    onYesClick();
  };

  const handleNoClick = async () => {
    setShowModal(false);
    if (!onNoClick) return;
    onNoClick();
  };

  return (
    <>
      {React.cloneElement(children, { onClick: handleChildClick })}
      {showModal && (
        <Modal
          open={true}
          title={title}
          onCancel={handleCancel}
          footer={[
            showLock && (
              <Switch
                key='lock'
                style={{ marginRight: '20px', backgroundColor: lockColor, width: lockWidth }}
                checked={locked}
                checkedChildren={labelLocked}
                unCheckedChildren={labelUnlocked}
                onChange={() => {
                  setLocked(!locked);
                }}
              />
            ),
            <Button icon={iconYes} key='btnYes' type='primary' danger={showRed} onClick={handleYesClick} disabled={locked} style={{ display: 'inline-flex', alignItems: 'center' }}>
              {labelYes}
            </Button>,
            <Button icon={iconNo} key='btnNo' onClick={handleNoClick} style={{ display: 'inline-flex', alignItems: 'center' }}>
              {labelNo}
            </Button>,
          ]}>
          {body}
        </Modal>
      )}
    </>
  );
};

YesNoDialog.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
  body: PropTypes.any,
  labelYes: PropTypes.string,
  labelNo: PropTypes.string,
  iconYes: PropTypes.object,
  iconNo: PropTypes.object,
  labelLocked: PropTypes.string,
  labelUnlocked: PropTypes.string,
  showLock: PropTypes.bool,
  showRed: PropTypes.bool,
  lockWidth: PropTypes.string,
  lockColor: PropTypes.string,
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func,
  chidren: PropTypes.any,
};

YesNoDialog.defaultProps = {
  title: 'Confirmation',
  body: 'Do you want to continue?',
  labelYes: 'Yes',
  labelNo: 'No',
  labelLocked: 'Locked',
  labelUnlocked: 'Unlocked',
  showLock: false,
  showRed: false,
  iconYes: <IoMdCheckmarkCircle />,
  iconNo: <MdCancel />,
};

export default YesNoDialog;
