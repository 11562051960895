import React, { useState } from 'react';
import { Select, Spin, Space } from 'antd';
import { getUsers } from 'src/api/teacket';
import PropTypes from 'prop-types';
import { MdOutlineEmail } from 'react-icons/md';
import { LuBuilding } from 'react-icons/lu';
import { isRequestCancelled } from 'src/misc/Misc';

const { Option } = Select;
//import PropTypes from 'prop-types';

const ServicesUsersPicker = ({ width = '240px', onPick, serviceList }) => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClear = async () => {
    console.log('handleClear');
    setUserList([]);
    if (onPick) onPick(null);
  };

  const handleSearchForTenants = async (v) => {
    setUserList([]);
    if (v.length < 3) return;
    try {
      setLoading(true);
      let resp = await getUsers(v, serviceList);
      // console.log('Tenants:', resp);
      if (resp) setUserList(resp);
      setLoading(false);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnChange = (v) => {
    console.log('UserPicker onSelect:', v);
    if (onPick) onPick(v);
  };

  return (
    <Select
      mode='multiple'
      showSearch
      allowClear
      placeholder='[all users]'
      notFoundContent={loading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
      filterOption={false}
      onSearch={handleSearchForTenants}
      onClear={handleClear}
      onChange={handleOnChange}
      style={{ width }}
      autoComplete='off'
      optionRender={(option) => {
        const user = userList.find((x) => x.user_id === option.key);
        if (!user) return null;
        return (
          <div className='flex flex-col gap-2 my-1'>
            <div className='flex flex-col'>
              <div className='overflow-hidden'>{user.user_name}</div>
              <div className='text-xs font-light opacity-60 overflow-hidden'>
                <Space className='btn'>
                  <MdOutlineEmail />
                  {user.user_email}
                </Space>
              </div>
              <div className='text-xs font-light opacity-60 overflow-hidden'>
                <Space className='btn'>
                  <LuBuilding />
                  {user.tenant_name}
                </Space>
              </div>
            </div>
          </div>
        );
      }}
    >
      {userList.map((d) => (
        <Option key={d.user_id} disabled={d.user_disabled}>
          <Space className='btn'>
            <div>{d.user_name}</div>
          </Space>
        </Option>
      ))}
    </Select>
  );
};

ServicesUsersPicker.propTypes = {
  serviceList: PropTypes.array,
  width: PropTypes.string,
  onPick: PropTypes.func,
};

export default ServicesUsersPicker;
