import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, Space } from 'antd';
import { FaBolt } from 'react-icons/fa';
import AuditViewerContext from 'src/context/AuditViewerContext';
import { FiSquare, FiCheckSquare } from 'react-icons/fi';

//import PropTypes from 'prop-types';

const EventsButton = () => {
  const navigate = useNavigate();
  const { ebIntegrationInstalled, hasCurrentUserSubscribed } = useContext(AuditViewerContext);

  const getItems = () => {
    if (ebIntegrationInstalled) {
      return [
        {
          key: '1',
          type: 'group',
          label: (
            <div className='flex flex-col'>
              <Space className='btn'>
                <FiCheckSquare />
                Configured
              </Space>
              {hasCurrentUserSubscribed ? (
                <Space className='btn'>
                  <FiCheckSquare />
                  Email subscribed
                </Space>
              ) : (
                <Space className='btn'>
                  <FiSquare />
                  Email not subscribed
                </Space>
              )}
            </div>
          ),
          children: [
            {
              title: 'Configure subscriptions and filters',
              icon: <Space className='btn'>Open notifications</Space>,
              onClick: handleOnClick,
            },
          ],
        },
      ];
    }
    return [
      {
        key: '1',
        type: 'group',
        label: (
          <Space className='btn'>
            <FiSquare />
            Not configured yet
          </Space>
        ),
        children: [
          {
            title: 'Configure notifications',
            icon: <Space className='btn'>Configure</Space>,
            onClick: handleOnClick,
          },
        ],
      },
    ];
  };

  const handleOnClick = () => {
    console.log('handleOnClick');
    navigate('/audit-viewer/events');
  };

  return (
    <Dropdown menu={{ items: getItems() }} placement='bottomRight'>
      <Button type='text'>
        <Space className='btn'>
          <FaBolt />
          Notifications
        </Space>
      </Button>
    </Dropdown>
  );
};

EventsButton.propTypes = {};

export default EventsButton;
