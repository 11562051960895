import React, { useEffect, useState } from 'react';
import useTheme from 'src/hooks/useTheme';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import PermissionsProvider from 'src/providers/PermissionsProvider';

import TitleBar from 'src/components/layout/TitleBar';
import { Space, Table, Tooltip, Button, Popconfirm, Card } from 'antd';
import UserImage from 'src/components/layout/UserImage';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getCopilotFeedbacks, deleteCopilotFeedback } from 'src/api/securityqa';
import { VscFeedback } from 'react-icons/vsc';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { dateTimeSorter, sleepMs, textSorter, textSorterNested } from 'src/misc/Misc';
import { FaRegTrashCan } from 'react-icons/fa6';
import { IoReload } from 'react-icons/io5';
import StyledButton from 'src/components/layout/StyledButton';
import { FiClipboard } from 'react-icons/fi';
import { MdOutlineThumbDown, MdOutlineThumbUp } from 'react-icons/md';

dayjs.extend(localizedFormat);

const MasterAdminCopilotFeedback = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const [feedbacks, setFeedbacks] = useState([]);
  const { theme } = useTheme();
  const [showNotification, setShowNotification] = React.useState(undefined);

  const { executeAsyncProcess } = useAsyncProcesses();

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getCopilotFeedbacks();
        setFeedbacks(resp);
      } catch (error) {
        console.error(error);
      }
    });
  };

  useEffect(() => {
    if (showNotification) {
      sleepMs(3000).then(() => {
        setShowNotification(undefined);
      });
    }
  }, [showNotification]);

  useEffect(() => {
    loadData();

    setBreadcrumb([
      {
        title: 'Dashboards',
        path: '/dashboards',
      },
      {
        title: 'AI Assistant Feedback',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const columns = [
    {
      title: 'Value',
      //dataIndex: 'value',
      key: 'value',
      width: 100,
      ellipsis: true,
      render: (record) => {
        switch (record.value) {
          case 'great':
            return (
              <Space className='btn text-green-500'>
                <MdOutlineThumbUp />
                {record.value}
              </Space>
            );
          case 'bad':
            return (
              <Space className='btn text-blue-500'>
                <MdOutlineThumbDown />
                {record.value}
              </Space>
            );
          default:
            return record.value ?? 'n/a';
        }
      },
      sorter: (a, b) => textSorter(a, b, 'value'),
      filters: [
        {
          text: 'Great',
          value: 'great',
        },
        {
          text: 'Bad',
          value: 'bad',
        },
      ],
      onFilter: (value, record) => record.value === value,
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      width: 300,
      ellipsis: true,
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      key: 'answer',
      ellipsis: true,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Reported By',
      key: 'userName',
      render: (record) => (
        <Tooltip title={record?.users?.name}>
          <Space>
            <UserImage image={record?.users.user_pic} size='26px' />
            {record?.users?.name}
          </Space>
        </Tooltip>
      ),
      width: 150,
      sorter: (a, b) => textSorterNested(a, b, 'users', 'name'),
    },
    {
      title: 'Date',
      key: 'created_at',
      width: 150,
      render: (record) => dayjs(record.created_at).format('YYYY-MM-DD HH:mm'),
      sorter: (a, b) => dateTimeSorter(a, b, 'created_at'),
    },
    {
      title: 'Model Name',
      key: 'modelName',
      width: 150,
      render: (record) => record?.model?.name ?? 'default',
      sorter: (a, b) => textSorterNested(a, b, 'model', 'name'),
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      align: 'center',
      render: (record) => (
        <Space className='btn'>
          <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }} className='hover:scale-125' onClick={() => copyToClipboard(record)}>
            <FiClipboard />
          </Button>
          <Popconfirm placement='topLeft' title='Delete' description='Do you want to delete this entry?' okText='Yes' cancelText='No' onConfirm={() => deleteItem(record.id)}>
            <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }} className='hover:scale-125'>
              <FaRegTrashCan />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const deleteItem = async (id) => {
    console.log('delete', id);
    executeAsyncProcess(async () => {
      try {
        await deleteCopilotFeedback(id);

        setFeedbacks(feedbacks.filter((f) => f.id !== id));
        setShowNotification('Feedback has been deleted');
      } catch (error) {
        console.log(error);
      }
    });
  };

  const copyToClipboard = (item) => {
    console.log('copyToClipboard', item);
    try {
      let message = '';
      message += `AI Assistant Feedback reported by ${item?.users?.name} on ${dayjs(item.created_at).format('YYYY-MM-DD HH:mm')}\n\n`;

      message += `Question: ${item.question}\n\n`;
      message += `Answer: ${item.answer}\n\n`;
      message += `Notes: ${item.notes}\n\n`;

      navigator.clipboard.writeText(message).then(
        function () {
          setShowNotification('Feedback copied to clipboard');
        },
        function (error) {
          console.error(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PermissionsProvider requiredPermissions={['admin.master']}>
      <TitleBar
        title={
          <Space className='btn'>
            <VscFeedback />
            AI Assistant Feedback
          </Space>
        }
        afterTitleExtras={
          <StyledButton color={theme.textBase} onClick={() => loadData()} size='small'>
            <Space className='btn'>
              <IoReload />
              Reload
            </Space>
          </StyledButton>
        }></TitleBar>
      <div className='p-4 h-full max-h-full w-full overflow-hidden'>
        <Table pagination={true} size='small' dataSource={feedbacks} columns={columns} />
      </div>
      {showNotification && (
        <span className='flex justify-center pb-4'>
          <Card size='small'>{showNotification}</Card>
        </span>
      )}
      ;
    </PermissionsProvider>
  );
};

MasterAdminCopilotFeedback.propTypes = {};

export default MasterAdminCopilotFeedback;
