import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useTheme from 'src/hooks/useTheme';
import { Button, Form, Space, Input, Modal, notification, Popconfirm, Checkbox, Table, Upload } from 'antd';

import styled from 'styled-components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FaRegTrashCan } from 'react-icons/fa6';
import FileModal from './FileModal';
import { FaPlus } from 'react-icons/fa6';
import { FiExternalLink } from 'react-icons/fi';
import { PlusOutlined } from '@ant-design/icons';

dayjs.extend(relativeTime);

const StylesWrapper = styled.div`
  .ant-upload .ant-upload-btn {
    padding: 0 !important;
  }
`;

const ItemModal = ({ item, editMode, open, onClose, onSubmit, onDelete }) => {
  const [form] = Form.useForm();
  const { theme } = useTheme();

  const { TextArea } = Input;
  const [disabled, setDisabled] = useState(true);
  const [busy, setBusy] = useState(false);
  const [itemStatus, setItemStatus] = useState(true);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const [imagesBase64, setImagesBase64] = useState([]);
  const [imagesList, setImagesList] = useState([]);

  const beforeUpload = async (file) => {
    console.log('beforeUpload()', file.type, file.size);

    const isLt2M = file.size / 1024 < 50;
    if (!isLt2M) {
      console.error('Image must smaller than 50KB!', file.uid);
      notification.warning({ message: 'Warning', description: 'Image must smaller than 50 Kb!', duration: 5 });
      return;
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Data = reader.result.split(',')[1];
      setImagesBase64([...imagesBase64, { uid: file.uid, base64Data }]);
    };
    reader.readAsDataURL(file);

    return false;
  };

  const onChange = ({ fileList: newFileList }) => {
    setImagesList(newFileList);
  };

  const onRemove = (file) => {
    setImagesBase64(imagesBase64.filter((x) => x.uid !== file.uid));
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type='button'
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const columns = [
    {
      title: 'id',
      key: 'id',
      hidden: true,
      render: (record) => <>{record.id}</>,
    },
    {
      title: 'Title',
      key: 'title',
      ellipsis: true,
      render: (record) => <>{record.title}</>,
    },
    {
      title: 'Linked File',
      key: 'url',
      ellipsis: true,
      width: 140,
      render: (record) => <>{record.url}</>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 75,
      align: 'center',
      render: (record) => (
        <Space className='btn'>
          <Button type='link' className={'p-0'}>
            <FiExternalLink onClick={() => window.open(record.web_link, '_blank')} />
          </Button>
          <Button disabled={busy} type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }} className='hover:scale-125' onClick={() => handleDeleteFile(record.id)}>
            <FaRegTrashCan />
          </Button>
        </Space>
      ),
    },
  ];

  const handleOnReset = () => {
    //setModified(false);
  };

  useEffect(() => {
    setBusy(false); //TODO: Remove later
    if (editMode) {
      setItemStatus(item.is_active);
      setFiles(item.files);

      if (item.icon) {
        setImagesBase64([{ uid: '1', base64Data: item.icon }]);
        setImagesList([{ uid: '1', name: 'image', thumbUrl: `data:image/png;base64,${item.icon}` }]);
      }

      form.setFieldsValue({
        id: item.id,
        title: item.title,
        description: item.description,
        url: item.url,
      });
    }
  }, [item]);

  const onChangeStatus = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setItemStatus(e.target.checked);
  };

  const handleDeleteFile = async (fileId) => {
    console.log('[ItemModal] handleDeleteFile:', fileId);
    setFiles((files) => files.filter((file) => file.id !== fileId));
  };

  const handleOnFinishFileModal = async (values) => {
    console.log('[ItemModal] Success(from FileModal)', values);
    // update files (setFiles) using values from values, match by values.id
    setFiles((files) => {
      const newFiles = [...files];
      const index = newFiles.findIndex((file) => file.id === values.id);
      if (index === -1) {
        // New Entry
        newFiles.push(values);
      } else newFiles[index] = values;
      return newFiles;
    });
    setFileModalOpen(false);
  };

  const handleOnFinish = async (values) => {
    console.log('[ItemModal] Success:', values);
    values.files = files;
    values.is_active = itemStatus;

    //TOOD: Image upload
    values.icon = imagesBase64[0]?.base64Data || '';

    if (onSubmit) onSubmit(values);
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('[Modal] Failed:', errorInfo);
    notification.error({
      message: 'Error',
      description: <>Provided data is invalid</>,
      duration: 5,
    });
  };

  const handleOnValuesChange = async (changedValues, allValues) => {
    form
      .validateFields()
      .then(() => {
        setDisabled(false);
      })
      .catch((errorFields) => {
        setDisabled(errorFields.errorFields.length > 0);
      });
  };

  const handleDeleteItem = async () => {
    console.log('Delete item');
    if (onDelete) onDelete(item.id);
  };

  return (
    <Modal
      open={open}
      title={editMode ? 'Edit Item' : 'New Item'}
      forceRender
      okButtonProps={{ disabled: disabled }}
      onCancel={() => {
        if (onClose) onClose();
      }}
      footer={[
        <div className='flex flex-auto'>
          <div className='flex flex-auto text-left'>
            {editMode && (
              <Popconfirm title='Delete Item' description='Are you sure? This cannot be undone.' okText='Yes' cancelText='No' onConfirm={handleDeleteItem}>
                <Button key='delete' danger className='text-left'>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </div>

          <div className='flex text-right'>
            <Button key='cancel' onClick={onClose}>
              Cancel
            </Button>
            <Button key='submit' type='primary' className='ml-2' onClick={form.submit}>
              Submit
            </Button>
          </div>
        </div>,
      ]}
    >
      <StylesWrapper>
        <Form disabled={busy} form={form} style={{ maxWidth: 600 }} layout='vertical' initialValues={{}} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} onReset={handleOnReset} onValuesChange={handleOnValuesChange} autoComplete='off'>
          <Form.Item name='id' hidden={true} />

          <div className='flex flex-auto'>
            <div className='flex flex-auto text-left'>
              <Form.Item label='Title' name='title' rules={[{ required: true, message: 'Enter a name for your item' }]}>
                <Input placeholder='Enter a name for item' autoComplete='off' maxLength={64} allowClear />
              </Form.Item>
            </div>
            <div className='flex text-right'>
              <Upload maxCount={1} accept='.jpg,.png,.gif,.webp' name='images' listType='picture-card' className='avatar-uploader' fileList={imagesList} beforeUpload={beforeUpload} onChange={onChange} onRemove={onRemove} showUploadList={{ showPreviewIcon: false }}>
                {imagesList.length === 0 && uploadButton}
              </Upload>
            </div>
          </div>

          <Form.Item label='Description' name='description'>
            <TextArea className='mt-1' rows={6} placeholder='Description of your Item' autoComplete='off' />
          </Form.Item>
          <Form.Item label='Url (external link for See More button)' name='url'>
            <Input placeholder='https://' autoComplete='off' maxLength={255} allowClear />
          </Form.Item>

          <div className='flex flex-auto'>
            <Checkbox name='is_active' checked={itemStatus} onChange={onChangeStatus}>
              <span>Enabled {!itemStatus && <span className='text-xs pl-2 text-red-800'>(When disabled, Item is not visible inside the Section)</span>}</span>
            </Checkbox>
          </div>
        </Form>
        <Button onClick={() => setFileModalOpen({})} className='flex flex-row gap-4 mt-4 mb-4'>
          <Space className='btn'>
            <FaPlus />
            Add new File
          </Space>
        </Button>
        <Table
          className='mt-4'
          size='small'
          pagination={false}
          columns={columns}
          dataSource={files}
          rowKey={'id'}
          //rowSelection={rowSelection}
          onRow={(record) => {
            return {
              onClick: (event) => {
                if (event.target?.tagName === 'path' || event.target?.tagName === 'svg' || event.target?.tagName === 'A') return;
                setFileModalOpen(record);
              }, // click row
            };
          }}
        />

        {fileModalOpen && <FileModal item={fileModalOpen} open={fileModalOpen ? true : false} onSubmit={handleOnFinishFileModal} onClose={() => setFileModalOpen(false)} />}
      </StylesWrapper>
    </Modal>
  );
};

ItemModal.propTypes = {
  item: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ItemModal;
