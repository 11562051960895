import React, { useEffect, useState, useContext } from 'react';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useTheme from 'src/hooks/useTheme';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import TitleBar from 'src/components/layout/TitleBar';
import { LuBuilding } from 'react-icons/lu';
import { Space, Switch, message } from 'antd';
import { getAiTemplates, postAiTemplate } from 'src/api/aiTemplates';
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import { FaPlus } from 'react-icons/fa6';
import StyledButton from 'src/components/layout/StyledButton';
import AiTemplateCreate from './Components/AiTemplateCreate';
import AITemplatesContext from 'src/context/AITemplatesContext';
import AiTemplateSelector from './Components/AiTemplateSelector';
import AiTemplateEditor from './Components/AiTemplateEditor';
import { uuid } from 'short-uuid';

//import PropTypes from 'prop-types';

const MasterAdminAiTemplates = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();
  const { updateAiTemplate, addNewCreatedAiTemplate, setAiTemplates, deleteAiTemplate, setSelectedTemplateById } = useContext(AITemplatesContext);
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedAiTemplateId, setSelectedAiTemplateId] = useState(null);
  const [newAiTemplateOpen, setNewAiTemplateOpen] = useState(false);
  const [layoutHorizontal, setLayoutHorizontal] = useState(true);

  useEffect(() => {
    loadData();

    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'AI Templates',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    console.log('[AiTemplates] selectedAiTemplateId', selectedAiTemplateId);
    setSelectedTemplateById(selectedAiTemplateId);
  }, [selectedAiTemplateId]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getAiTemplates();
        setAiTemplates(resp);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleAiTemplateCreateOnSubmit = (values) => {
    console.log('[AiTemplates] handleAiTemplateCreateOnSubmit', values);
    setNewAiTemplateOpen(false);
    executeAsyncProcess(async () => {
      try {
        const resp = await postAiTemplate(values);

        addNewCreatedAiTemplate(resp);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Data has been saved',
          duration: 3,
        });
        setSelectedAiTemplateId(resp.id);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleAiTemplateEditorOnSave = (aiTemplate) => {
    console.log('[AiTemplates] handleAiTemplateEditorOnSave', aiTemplate);
    const { name: aiTemplate_name, disabled: aiTemplate_disabled, content: aiTemplate_content } = aiTemplate;
    updateAiTemplate(aiTemplate.id, { name: aiTemplate_name, content: aiTemplate_content, disabled: aiTemplate_disabled });
  };

  const handleAiTemplateEditorOnDelete = (id) => {
    console.log('[AiTemplates] handleAiTemplateEditorOnDelete', id);
    deleteAiTemplate(id);
    setSelectedAiTemplateId(null);
  };

  return (
    <PermissionsProvider requiredPermissions={['admin.master']}>
      {contextHolder}
      <AiTemplateCreate open={newAiTemplateOpen} onClose={() => setNewAiTemplateOpen(false)} onSubmit={handleAiTemplateCreateOnSubmit} />
      <TitleBar
        title={
          <Space className='btn'>
            <LuBuilding />
            AI Templates
          </Space>
        }
        afterTitleExtras={
          <StyledButton color={theme.textBase} onClick={() => setNewAiTemplateOpen(true)}>
            <Space className='btn'>
              <FaPlus />
              Create new
            </Space>
          </StyledButton>
        }>
        <Space>
          <Switch
            unCheckedChildren={<>vertical</>}
            checkedChildren={<>horizontal</>}
            checked={layoutHorizontal}
            onChange={() => {
              setLayoutHorizontal(!layoutHorizontal);
            }}
          />
        </Space>
      </TitleBar>
      <div className='p-4 h-full max-h-full w-full overflow-hidden'>
        <Splitter direction={layoutHorizontal ? SplitDirection.Horizontal : SplitDirection.Vertical}>
          <div className={'overflow-hidden h-full max-h-full w-full  p-4'} style={{ backgroundColor: theme.backgroundBase }}>
            <AiTemplateSelector
              selected={selectedAiTemplateId}
              onAiTemplateSearch={(e) => {
                setSelectedAiTemplateId(null);
              }}
              onAiTemplateSelected={(e) => {
                setSelectedAiTemplateId(e);
              }}
            />
          </div>
          <div className={'overflow-scroll h-full max-h-full w-full'} style={{ backgroundColor: theme.backgroundBase }}>
            <AiTemplateEditor aiTemplateId={selectedAiTemplateId} onSave={handleAiTemplateEditorOnSave} onDelete={handleAiTemplateEditorOnDelete} />
          </div>
        </Splitter>
      </div>
    </PermissionsProvider>
  );
};
export default MasterAdminAiTemplates;
