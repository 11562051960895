import React from 'react';
import { Modal, Button, Space, Form, Input, notification } from 'antd';
import PropTypes from 'prop-types';

const CopilotCreate = ({ open, onClose, onSubmit }) => {
  const handleOnFinish = (values) => {
    console.log('Success:', values);
    if (onSubmit) onSubmit(values);
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: 'Error',
      description: <>Provided data is invalid</>,
      duration: 5,
    });
  };

  const handleOnReset = () => {
    //setModified(false);
  };

  return (
    open && (
      <Modal
        open={open}
        title='Create AI Assistant tenant'
        footer={null}
        onCancel={() => {
          if (onClose) onClose();
        }}>
        <Form name='createForm' style={{ maxWidth: 600 }} layout='vertical' initialValues={{}} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} onReset={handleOnReset} autoComplete='off'>
          <Form.Item
            label='Name'
            name='name'
            rules={[{ required: true, message: "Value can't be empty" }]} // todo: for some reason regex validation doesnt work :-(
          >
            <Input placeholder='enter tenant name' autoComplete='off' />
          </Form.Item>
          {/* <Form.Item label='Description' name='description' rules={[{ required: true, message: "Value can't be empty" }]}>
            <Input placeholder='enter description' autoComplete='off' />
          </Form.Item> */}
          <Form.Item>
            <Space>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    )
  );
};

CopilotCreate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CopilotCreate.defaultProps = {
  open: false,
};

export default CopilotCreate;
