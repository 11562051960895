import React from 'react';
import PropTypes from 'prop-types';
import useUserInfo from 'src/hooks/useUserInfo';
import { MASTER_ADMIN_PERMISSION } from 'src/misc/Config';

const WhenMeOrAdmin = ({ userId, children }) => {
  const { id: myUserId, permissions } = useUserInfo();
  if (userId !== myUserId && !permissions?.includes(MASTER_ADMIN_PERMISSION)) return null;
  return <>{children}</>;
};

WhenMeOrAdmin.propTypes = {
  userId: PropTypes.string,
  children: PropTypes.any,
};

export default WhenMeOrAdmin;
