import React, { useContext } from 'react';
import { Tooltip, Button, Space } from 'antd';
import WorkerStatus from './WorkerStatus';
import { textSorter, dateTimeSorter, numberSorter } from 'src/misc/Misc';
import dayjs from 'dayjs';
import RiskChart from './RiskChart';
import { getPreSignedUrl } from 'src/api/securityAdvisor';
import SecurityAdvisorContext from 'src/context/SecurityAdvisorContext';
import { ENV } from '../../../../misc/Config';
import { FileSearchOutlined } from '@ant-design/icons';
import { FaRegCalendarCheck } from 'react-icons/fa6';
import { FaRegFilePdf } from 'react-icons/fa';
import UserImage from 'src/components/layout/UserImage';
import CondensedTable from 'src/components/layout/CondensedTable';
import useUserInfo from 'src/hooks/useUserInfo';

//import PropTypes from 'prop-types';

const WorkersHistory = () => {
  const { getSortedWorkers } = useContext(SecurityAdvisorContext);
  const userInfo = useUserInfo();

  const columns = [
    {
      title: 'Started By',
      key: 'started_by',
      render: (record) =>
        record.is_manual ? (
          <Tooltip title={record?.started_by?.name}>
            <Space>
              <UserImage image={record?.started_by?.user_pic} size='26px' />
              {record?.started_by?.name ? record.started_by.name : <span className='ultralight'>n/a</span>}
            </Space>
          </Tooltip>
        ) : (
          <Space className='btn'>
            <FaRegCalendarCheck />
            System Scheduler
          </Space>
        ),
    },
    {
      title: 'Status',
      key: 'status',
      align: 'left',
      render: (record) => (
        <div className='flex-horizontal-align-center'>
          <WorkerStatus status={record.status} errMessage={record.err_message} />
        </div>
      ),
      sorter: (a, b) => textSorter(a, b, 'status'),
    },
    {
      title: 'Updated',
      key: 'updated_at',
      align: 'center',
      render: (record) =>
        record.updated_at ? (
          <>
            <div>{dayjs(record.updated_at).fromNow()}</div>
            <div className='font-extralight text-xs'>at {dayjs(record.updated_at).format('lll')}</div>
          </>
        ) : (
          <span className='font-extralight'>n/a</span>
        ),
      sorter: (a, b) => dateTimeSorter(a, b, 'updated_at'),
    },
    {
      title: 'Risk',
      key: 'score',
      align: 'center',
      render: (record) => {
        if (!record?.score) return <span className='ultralight'>n/a</span>;
        return <RiskChart noBackground value={record?.score} />;
      },
      sorter: (a, b) => numberSorter(a, b, 'score'),
    },
    {
      title: 'Report',
      key: 'report',
      align: 'center',
      width: '200px',
      render: (record) => {
        if (record.status !== 'success') return <span className='ultralight'>n/a</span>;
        return (
          <div>
            {/* <PermitProvider isControl featurePermissions={['admin_full']}>
              <Tooltip title={record.filesExists?.json ? null : 'File has not been generated or it is expired, please re-run the job to get a new file.'}>
                <Button
                  disabled={!record.filesExists?.json}
                  type='link'
                  onClick={() => {
                    const url = `/reports/5/${baseUrl}/${token}?jobId=${jobId}&workerId=${record.id}`;
                    window.open(url, '_blank');
                  }}
                >
                  <i className='ion-code btn-margin-right' />
                  HTML
                </Button>
              </Tooltip>
            </PermitProvider> */}
            <Tooltip title={record.filesExists?.pdf ? null : 'File has not been generated or it is expired, please re-run the job to get a new file.'}>
              <Button
                disabled={!record.filesExists?.pdf}
                type='link'
                onClick={async () => {
                  const { url } = (await getPreSignedUrl(`${record.parent_id}/${record.id}_report.pdf`)) ?? {};
                  //const { url } = (await getPreSignedUrl('report.pdf')) ?? {};
                  //console.log(url);
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', 'report.pdf');
                  link.click();
                }}
              >
                <Space className='btn'>
                  <FaRegFilePdf />
                  PDF
                </Space>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Logs',
      key: 'logs',
      align: 'center',
      hidden: !userInfo?.permissions.includes('admin.master'),
      render: (record) => {
        if (!record?.docker_task_arn) return <span className='ultralight'>n/a</span>;

        return (
          <a href={`https://eu-central-1.console.aws.amazon.com/cloudwatch/home?region=eu-central-1#logsV2:log-groups/log-group/$252Fecs$252F${ENV.name === 'awsprod' ? 'prod' : 'dev'}-spc-sa-processor/log-events/sa$252F${ENV.name === 'awsprod' ? 'prod' : 'dev'}-spc-sa-processor$252F${record?.docker_task_arn}`} target='_blank' rel='noopener noreferrer' aria-label='View logs'>
            <FileSearchOutlined />
          </a>
        );
      },
    },
  ].filter((item) => !item.hidden);

  return (
    <div>
      <CondensedTable rowKey={(record) => record.id} dataSource={getSortedWorkers()} columns={columns} size='small' pagination={{ pageSize: 5 }} />
      <p className='text-xs opacity-60'>Reports are automatically deleted after 90 days.</p>
    </div>
  );
};

WorkersHistory.propTypes = {};

export default WorkersHistory;
