import React, { useContext } from 'react';
import AuditViewerContext from 'src/context/AuditViewerContext';
import { MdAdd, MdRefresh } from 'react-icons/md';
import { Button, Space } from 'antd';
import WhenDesktop from 'src/components/layout/WhenDesktop';

const SubscriptionsWidget = () => {
  const { setSnsSubscriptionsReload, setSnsSubscriptionsAddNew } = useContext(AuditViewerContext);
  return (
    <Space>
      <Button
        size='small'
        onClick={() => {
          setSnsSubscriptionsReload(true);
        }}
      >
        <Space className='btn'>
          <MdRefresh />
          <WhenDesktop>Refresh</WhenDesktop>
        </Space>
      </Button>
      <Button
        size='small'
        onClick={() => {
          setSnsSubscriptionsAddNew(true);
        }}
      >
        <Space className='btn'>
          <MdAdd />
          <WhenDesktop>Add</WhenDesktop>
        </Space>
      </Button>
    </Space>
  );
};

export default SubscriptionsWidget;
