import { BsCupHotFill } from 'react-icons/bs';
import { MdSecurity, MdManageAccounts } from 'react-icons/md';
import { AiOutlineAudit, AiFillNotification } from 'react-icons/ai';
import { MASTER_ADMIN_PERMISSION } from 'src/misc/Config';

export const originConfig = [
  { origin: 'teacket', color: '#15803D', title: 'Green Teacket', icon: <BsCupHotFill />, permissions: ['apps.teacket.view'] },
  { origin: 'security_advisor', color: '#1D4ED8', title: 'Security Advisor', icon: <MdSecurity />, permissions: ['apps.securityadvisor.view'] },
  { origin: 'audit_viewer', color: '#A21CAF', title: 'Audit Viewer', icon: <AiOutlineAudit />, permissions: ['apps.auditviewer.view'] },
  { origin: 'master_admin', color: '#be123c', title: 'Admin Notification', icon: <MdManageAccounts /> },
  { origin: 'default', color: '#A16207', title: 'Notification', icon: <AiFillNotification />, permissions: [MASTER_ADMIN_PERMISSION] },
];
