import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Modal, List, Input, Slider, Spin, notification, Space, Dropdown } from 'antd';
import { CaretDownFilled, CaretUpFilled, FileSearchOutlined, RobotOutlined, StopOutlined, PlayCircleOutlined } from '@ant-design/icons';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useTheme from 'src/hooks/useTheme';
import { getEvaluations, startEvaluationJob, isRunning, stopEvaluationJob } from 'src/api/securityqa';
import { uuid } from 'short-uuid';
import dayjs from 'dayjs';
import StyledButton from 'src/components/layout/StyledButton';
import { DoubleRightOutlined } from '@ant-design/icons';
import { getAiModels } from 'src/api/aiModels';

import { FiClipboard } from 'react-icons/fi';

const Evaluations = () => {
  const { isDarkMode, theme } = useTheme();
  const [evaluations, setEvaluations] = useState(undefined);
  const [evaluationsFiltered, setEvaluationsFiltered] = useState([]);
  const [evaluationsSearchOnly, setEvaluationsSearchOnly] = useState([]);
  const [evaluationsScoreRange, setEvaluationsScoreRange] = useState([0, 100]);
  const [buttonDisabled, setButtonDisabled] = useState(undefined);
  const [lastEvaluationDate, setLastEvaluationDate] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [activeModel, setActiveModel] = useState('');
  const [availableModels, setAvailableModels] = useState([]);

  const [loadingEvaluations, setLoadingEvaluations] = useState(false);
  const [loadingJobProgress, setLoadingJobProgress] = useState(true);

  const themeMode = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  const { executeAsyncProcess } = useAsyncProcesses();
  const { Search } = Input;

  const loadData = async () => {
    try {
      setLoadingEvaluations(true);
      const aiModels = await getAiModels();
      const reducedModels = aiModels.map((item) => ({ key: item.id, label: item.name }));

      setAvailableModels(reducedModels);
      setActiveModel(reducedModels[0]);
      const resp = await getEvaluations(reducedModels[0].key);
      setEvaluations(resp);
      setEvaluationsFiltered(resp?.details?.data);
      setEvaluationsSearchOnly(resp?.details?.data);
      setLastEvaluationDate(dayjs(resp?.lastEvaluationDate).format('YYYY-MM-DD HH:mm'));
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEvaluations(false);
    }
  };

  const isProcessRunning = async () => {
    try {
      setLoadingJobProgress(true);
      const resp = await isRunning('spc-responses-evaluator');
      setButtonDisabled(resp?.taskArn);
      console.log('isProcessRunning', resp?.taskArn);
    } catch (error) {
      console.error(error);
      setButtonDisabled(undefined);
    } finally {
      setLoadingJobProgress(false);
    }
  };

  // Support SQS Once the evalution is finished.
  const startStopEvaluation = async () => {
    console.log('startStopEvaluation');
    await executeAsyncProcess(async () => {
      try {
        if (!buttonDisabled) {
          console.log('Check first is process running');
          const resp = await isRunning('spc-responses-evaluator');

          if (resp?.taskArn) {
            notification.error({
              message: 'Evaluator',
              description: <div className='text-xs text-gray-800'>{'Evaluation process is already running'}</div>,
              duration: 5,
            });
            setButtonDisabled(resp?.taskArn);
            return;
          }

          const task = await startEvaluationJob(activeModel?.key);
          setButtonDisabled(task?.taskArn);
        } else {
          await stopEvaluationJob(buttonDisabled);
          setButtonDisabled(undefined);
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  useEffect(() => {
    if (!evaluations) loadData();

    isProcessRunning();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chartSetting = {
    // xAxis: [
    //   {
    //     label: 'score',
    //   },
    // ],

    height: 203,
    margin: { top: 10, right: 10, bottom: 40, left: 80 },
  };

  const valueFormatter = (value) => `${value} / 100`;

  const onSearch = (value) => {
    let filteredData = evaluations?.details.data.filter((item) => item.question.toLowerCase().includes(value.toLowerCase()) || item.botResponse.toLowerCase().includes(value.toLowerCase()) || item.description.toLowerCase().includes(value.toLowerCase()) || item.expertResponse.toLowerCase().includes(value.toLowerCase()));
    // Apply score range filter
    setEvaluationsSearchOnly(filteredData);
    filteredData = filteredData.filter((item) => item.score >= evaluationsScoreRange[0] && item.score <= evaluationsScoreRange[1]);
    setEvaluationsFiltered(filteredData);
  };

  const onChangeSlider = (range) => {
    setEvaluationsScoreRange(range);
    const filteredData = evaluationsSearchOnly.filter((item) => item.score >= range[0] && item.score <= range[1]);
    setEvaluationsFiltered(filteredData);
  };

  const copyToClipboard = (item) => {
    console.log('copyToClipboard', item);
    try {
      navigator.clipboard.writeText(item?.botResponse).then(
        function () {
          //setShowNotification('Feedback copied to clipboard');
          console.log('Copied to clipboard');
        },
        function (error) {
          console.error(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleMenuOnClick = (e) => {
    console.log('handleMenuOnCklick()', e);
    if (!e.key) return;

    setActiveModel(availableModels.find((item) => item.key === e.key));

    try {
      executeAsyncProcess(async () => {
        const resp = await getEvaluations(e.key);
        setEvaluations(resp);
        setEvaluationsFiltered(resp?.details?.data);
        setEvaluationsSearchOnly(resp?.details?.data);
        setLastEvaluationDate(dayjs(resp?.lastEvaluationDate).format('YYYY-MM-DD HH:mm'));
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ThemeProvider theme={themeMode}>
      {/* <div className='mx-4 my-1 text-4xl text-center font-semibold text-teal-700 whitespace-pre-wrap pb-4'>
        <GiPotionBall /> Evaluator
      </div> */}

      <Card loading={loadingEvaluations} className='mt-3' title='Evaluator process model selection' size='small'>
        {!loadingJobProgress && (
          <>
            <Space>
              <Dropdown menu={{ items: availableModels, onClick: handleMenuOnClick }} trigger={['click']}>
                <Button type='link' style={{ color: theme.textBase }}>
                  <Space>
                    <span className=' text-green-600'> {activeModel?.label}</span>

                    <DoubleRightOutlined />
                  </Space>
                </Button>
              </Dropdown>
              <StyledButton color={buttonDisabled ? 'red' : theme.textBase} className='opacity-80' type='primary' size='small' onClick={startStopEvaluation}>
                {buttonDisabled ? (
                  <>
                    <StopOutlined /> Stop evaluation
                  </>
                ) : (
                  <div>
                    <PlayCircleOutlined /> Start evaluation
                  </div>
                )}
              </StyledButton>
            </Space>
            <div className='text-xs font-thin text-left pt-2'>
              <span>User token is used during the evaluation process.</span>
              <br />
              <span>To prevent token invalidation and disruption of the primary process, do not logout until this is finished</span>
            </div>
          </>
        )}
        {loadingJobProgress && (
          <div className='items-center text-center pt-1'>
            {' '}
            <Spin />
          </div>
        )}
      </Card>

      {evaluations?.avgs?.length > 0 && (
        <div className='pt-3'>
          <Card loading={loadingEvaluations} className='mt-3' title='Trend' size='small'>
            {evaluations && <SparkLineChart data={evaluations?.avgs} height={80} showHighlight={true} showTooltip={true} />}
          </Card>
          <Card loading={loadingEvaluations} className='mt-2' title={lastEvaluationDate} size='small' extra={<FileSearchOutlined onClick={() => setShowDetails(true)} />}>
            {evaluations && <BarChart dataset={evaluations?.lastScore} yAxis={[{ scaleType: 'band', dataKey: 'category' }]} series={[{ dataKey: 'score', valueFormatter, color: 'rgb(8 145 178)' }]} layout='horizontal' {...chartSetting} />}

            <div className='opacity-80 text-center font-nunito'>
              Questions count: {evaluations?.details?.data?.length}
              <br />
              <CaretUpFilled style={{ color: 'green' }} />
              {evaluations?.scores?.maxScore} ({evaluations?.scores?.maxCount})
              <CaretDownFilled style={{ color: 'red' }} />
              {evaluations?.scores?.minScore} ({evaluations?.scores?.minCount})
            </div>
          </Card>
        </div>
      )}

      <Modal
        open={showDetails}
        title='Evaluation results'
        onCancel={() => {
          setShowDetails(false);
        }}
        width={800}>
        <div className='flex-auto pt-4'>
          <Search className='p-4 pt-0 pb-1' placeholder='input search text' onSearch={onSearch} enterButton allowClear />
          <div className='text-xs text-center pt-0 mt-0'>count: {evaluationsFiltered?.length}</div>
          <div className='pt-0 pl-4 pr-4'>
            <Slider marks={{ 0: '0', 100: '100' }} range step={10} defaultValue={evaluationsScoreRange} onChange={onChangeSlider} />
          </div>
          <div className='text-center mt-2 text-xs'>
            Q: - Question, <RobotOutlined /> - Bot response, E: - Expert response
          </div>

          <List
            style={{ marginBottom: '20px' }}
            grid={{ gutter: 10, xs: 2, md: 3 }}
            size='small'
            dataSource={evaluationsFiltered}
            renderItem={(item) => (
              <List.Item style={{ marginTop: '20px' }}>
                <Card
                  key={uuid()}
                  className='mt-4'
                  title={
                    <Space className='btn'>
                      <Button type='text' style={{ padding: '2px 0px', height: '18px', width: '18px' }} className='hover:scale-125' onClick={() => copyToClipboard(item)}>
                        <FiClipboard />
                      </Button>
                      <span className='pt-1'>{item?.category}</span>
                    </Space>
                  }
                  size='small'
                  extra={
                    <span className='text-right'>
                      {item?.score} [{item?.pass}]
                    </span>
                  }>
                  <div className='ultralight smaller'>
                    <p>Q: {item?.question}</p>
                    <p>
                      <RobotOutlined /> {item?.botResponse}
                    </p>
                    <p>E: {item?.expertResponse}</p>
                  </div>
                  <Card type='inner' title='Bot summary'>
                    <p>{item?.description}</p>
                  </Card>
                </Card>
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </ThemeProvider>
  );
};

//

Evaluations.propTypes = {
  tickets: PropTypes.array,
};

export default Evaluations;
