import React from 'react';
import { ReactComponent as GenesysLogo } from 'src/components/svg/genesys-logo.svg';
import PropTypes from 'prop-types';

const GcPicture = ({ size, color, bgColor }) => {
  return (
    <div className='rounded-full p-1 flex flex-col justify-center' style={{ height: `${size}px`, width: `${size}px`, color: color, backgroundColor: bgColor }}>
      <GenesysLogo />
    </div>
  );
};

GcPicture.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  bgColor: PropTypes.string,
};

GcPicture.defaultProps = {
  size: 32,
  color: 'white',
  bgColor: 'black',
};

export default GcPicture;
