import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useTheme from 'src/hooks/useTheme';
import TitleBar from 'src/components/layout/TitleBar';
import { LuUsers2 } from 'react-icons/lu';
import { Space, message } from 'antd';
import UserEditor from './Components/UserEditor';
import UsersSelector from './Components/UserSelector';
import { FaPlus } from 'react-icons/fa6';
import StyledButton from 'src/components/layout/StyledButton';
import UserCreate from './Components/UserCreate';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { postUserCognito } from 'src/api/users';
import useMasterAdmin from 'src/hooks/useMasterAdmin';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { uuid } from 'short-uuid';
import useUserInfo from 'src/hooks/useUserInfo';
import { useNavigate } from 'react-router-dom';
import { MASTER_ADMIN_PERMISSION, TENANT_ADMIN_PERMISSION } from 'src/misc/Config';
import { GiEntryDoor } from 'react-icons/gi';
import PageLayoutType3 from 'src/components/layout/PageLayoutType3';

//import PropTypes from 'prop-types';

const MasterAdminUsers = () => {
  const navigate = useNavigate();

  const { userId, tenantId, tab } = queryString.parse(window.location.search);
  const userInfo = useUserInfo();
  const [messageApi, contextHolder] = message.useMessage();
  const { executeAsyncProcess } = useAsyncProcesses();
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { theme } = useTheme();
  const { updateUser, addNewCreatedUser, deleteUser } = useMasterAdmin();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [newUserOpen, setNewUserOpen] = useState(false);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'Users',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    console.log('selectedUserId', selectedUserId);
  }, [selectedUserId]);

  const handleUserCreateOnSubmit = (values) => {
    console.log('handleUserCreateOnSubmit', values);
    setNewUserOpen(false);
    executeAsyncProcess(async () => {
      try {
        const resp = await postUserCognito(values);
        console.log('resp', resp); // TODO: show new user in the list
        addNewCreatedUser(resp.user);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Data has been saved',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleUserEditorOnSave = (user) => {
    console.log('handleUserEditorOnSave', user);
    const { name: user_name, disabled: user_disabled } = user;
    updateUser(user.id, { user_name, user_disabled });
  };

  const handleUserEditorOnDelete = (id) => {
    console.log('handleUserEditorOnDelete', id);
    deleteUser(id);
    setSelectedUserId(null);
  };

  console.log('userInfo.resultantPermissions', userInfo);

  return (
    <PermissionsProvider requiredPermissions={[MASTER_ADMIN_PERMISSION, TENANT_ADMIN_PERMISSION]}>
      {contextHolder}
      <UserCreate open={newUserOpen} onClose={() => setNewUserOpen(false)} onSubmit={handleUserCreateOnSubmit} />
      <TitleBar
        title={
          <Space className='btn'>
            <LuUsers2 />
            Users
          </Space>
        }
        afterTitleExtras={
          <Space>
            {!userId && userInfo.permissions.includes(MASTER_ADMIN_PERMISSION) && (
              <StyledButton color={theme.textBase} onClick={() => setNewUserOpen(true)} size='small'>
                <Space className='btn'>
                  <FaPlus />
                  <WhenDesktop>Create user</WhenDesktop>
                </Space>
              </StyledButton>
            )}
            {!userId && (userInfo.permissions.includes(MASTER_ADMIN_PERMISSION) || userInfo.permissions.includes(TENANT_ADMIN_PERMISSION)) && (
              <StyledButton color={theme.textBase} onClick={() => navigate('/master-admin/invitation-links')} size='small'>
                <Space className='btn'>
                  <GiEntryDoor />
                  <WhenDesktop>Invite</WhenDesktop>
                </Space>
              </StyledButton>
            )}
          </Space>
        }
      />

      <PageLayoutType3 left={<UsersSelector defaultQuery={userId || tenantId} selected={selectedUserId} onUserSearch={(e) => setSelectedUserId(null)} onUserSelected={(e) => setSelectedUserId(e)} />} right={<UserEditor userId={selectedUserId} onSave={handleUserEditorOnSave} onDelete={handleUserEditorOnDelete} initialTab={tab} />} />
    </PermissionsProvider>
  );
};

// MasterAdminUsers.propTypes = {};

export default MasterAdminUsers;
