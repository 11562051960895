import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as UserMockup } from 'src/components/svg/user-mockup.svg';
import { LuBuilding } from 'react-icons/lu';
import { Badge } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const UserBadge = ({ id, name, tenantName, picture, onClick, isSelected, isDisabled, newCreated, createdAt }) => {
  const pictureStyle = {
    width: '56px',
    height: '56px',
    borderRadius: '100%',
    border: '2px solid #4288ef',
  };

  const disabledRibbon = (control, isDisabled) => {
    if (isDisabled)
      return (
        <Badge.Ribbon text='Disabled' color='red'>
          {control}
        </Badge.Ribbon>
      );
    if (newCreated)
      return (
        <Badge.Ribbon text='Just created' color='blue'>
          {control}
        </Badge.Ribbon>
      );
    return control;
  };

  return (
    <div
      key={id}
      className={'hover:scale-105 hover:opacity-100  transition-all'}
      onClick={() => {
        if (onClick) onClick(id);
      }}
    >
      {disabledRibbon(
        <div className={`flex flex-col gap-1 justify-start items-center cursor-pointer opacity-80 min-h-28 w-28 px-2 py-4 shadow-sm hover:shadow-md shadow-gray-300 dark:shadow-gray-800  ${isSelected ? 'border-2 border-genesys-azure-500' : 'border border-gray-300 dark:border-gray-700'} border-solid rounded-xl`}>
          <div className='text-center'>{picture ? <img style={pictureStyle} src={picture} alt={name} /> : <UserMockup style={pictureStyle} />}</div>
          <div className='flex-auto text-xs text-center'>{name}</div>
          <div className='flex flex-row items-center justify-center gap-1 opacity-80' style={{ fontSize: '0.5rem' }}>
            <LuBuilding />
            {tenantName ?? 'unknown org'}
          </div>
          {createdAt && (
            <div className='text-center' style={{ fontSize: '0.6rem' }}>
              {dayjs(createdAt).fromNow()}
            </div>
          )}
        </div>,
        isDisabled
      )}
    </div>
  );
};

UserBadge.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tenantName: PropTypes.string.isRequired,
  picture: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  newCreated: PropTypes.bool,
  createdAt: PropTypes.string,
};

export default UserBadge;
