import React, { useContext, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { useNavigate } from 'react-router-dom';
import { Space } from 'antd';
import CondensedTable from 'src/components/layout/CondensedTable';
import { getTicketsMyOpen } from 'src/api/teacket';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { textSorter, getDistinct, notArrayOrEmpty } from 'src/misc/Misc';
import TicketStatusColor from '../TicketStatusColor';
import TicketPriorityColor from '../TicketPriorityColor';
import TicketDrawer from '../TicketDrawer';
import UserTicketListHours from './UserTicketListHours';
import TicketTypeTag from '../../Components/TicketTypeTag';
import CmdBtn from 'src/components/layout/CmdBtn';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
//import PropTypes from 'prop-types';

const UserTicketListWidget = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const navigate = useNavigate();
  const { currentTicket, currentTicketMembers, userTicketList, setUserTicketList, userTicketListRefresh, setUserTicketListRefresh, setTicketListSelectedTicket, ticketAfterUpdateSync, setTicketAfterUpdateSync, servicesForTenant } = useContext(TeacketContext);

  useEffect(() => {
    if (!currentTicket) return;
    if (ticketAfterUpdateSync) ticketSyncAfterUpdate();
  }, [currentTicket, currentTicketMembers]);

  useEffect(() => {
    setTicketListSelectedTicket(null);
    //if (!userTicketList) loadData();
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setTicketListSelectedTicket(null);
    };
  }, []);

  useEffect(() => {
    if (!userTicketListRefresh) return;
    setUserTicketListRefresh(false);
    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTicketListRefresh]);

  const columns = [
    {
      title: 'Id',
      key: 'id',
      render: (record) => (
        <Space>
          <CmdBtn
            type='link'
            onClick={async () => {
              setTicketListSelectedTicket(record.id);
            }}
            onClickCommandShiftPressed={() => {
              navigate(`/teacket/tickets/${record.id}`);
            }}
            onClickCommandPressed={() => {
              window.open(`/teacket/tickets/${record.id}`, '_blank');
            }}
          >
            <strong>{record.id}</strong>
          </CmdBtn>
          {record?.type && <TicketTypeTag type={record.type} styling='-ml-4' />}
        </Space>
      ),
      sorter: (a, b) => textSorter(a, b, 'id'),
    },
    {
      title: 'Title',
      key: 'title',
      width: 320,
      render: (record) => <div style={{ maxWidth: '300px', wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>{record.title}</div>,
      sorter: (a, b) => textSorter(a, b, 'title'),
    },
    {
      title: 'Account',
      key: 'account',
      render: (record) => <div>{record?.acc_name}</div>,
      sorter: (a, b) => textSorter(a, b, 'account'),
    },
    {
      title: 'Category',
      key: 'category',
      align: 'center',
      render: (record) => (
        <div style={{ wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {record?.category}
          <div className='text-xs font-light opacity-60'>{record?.sub_category}</div>
        </div>
      ),
      sorter: (a, b) => textSorter(a, b, 'category'),
    },
    {
      title: 'Priority',
      key: 'priority',
      align: 'center',
      render: (record) => (
        <div className='flex flex-row gap-2 items-center justify-center'>
          <TicketPriorityColor serviceId={record?.service_id} priority={record.priority} />
          {record.priority}
        </div>
      ),
      filters: getDistinct(userTicketList ?? [], 'priority').map((priority) => ({ text: priority, value: priority })) ?? [],
      onFilter: (value, record) => record?.priority?.indexOf(value) === 0,
      sorter: (a, b) => textSorter(a, b, 'priority'),
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      render: (record) => (
        <div className='flex flex-row gap-2 items-center justify-center'>
          <TicketStatusColor serviceId={record?.service_id} status={record.status} />
          {record.status}
        </div>
      ),
      filters: getDistinct(userTicketList ?? [], 'status').map((status) => ({ text: status, value: status })) ?? [],
      onFilter: (value, record) => record?.status?.indexOf(value) === 0,
      sorter: (a, b) => textSorter(a, b, 'status'),
    },
    {
      title: 'Created',
      key: 'created_at',
      align: 'center',
      render: (record) =>
        record.created_at ? (
          <>
            <div>{dayjs(record.created_at).fromNow()}</div>
            <div className='font-thin text-xs'>at {dayjs(record.created_at).format('LLL')}</div>
          </>
        ) : (
          <>n/a</>
        ),
      sorter: (a, b) => {
        console.log('a:', a);
        return textSorter(a, b, 'created_at');
      },
    },
    {
      title: '',
      key: 'time_entries',
      align: 'center',
      render: (record) => {
        const service = servicesForTenant.find((x) => x.id === record.service_id);
        const { widgetList } = service?.props ?? [];
        //console.log('widgetList:', widgetList);
        if (true === notArrayOrEmpty(widgetList) || false === widgetList.map((w) => w.widgetId).includes('Time Tracking')) return null;
        return <UserTicketListHours ticketId={record.id} hoursTotalDefaultValue={record.hours_total} userHoursTotalDefaultValue={record.user_hours_total} />;
      },
    },
  ];

  const ticketSyncAfterUpdate = async () => {
    console.log('ticketSyncAfterUpdate()');
    try {
      const ticket = currentTicket;

      let rearrangedMembers;
      if (currentTicketMembers) {
        rearrangedMembers = [];
        currentTicketMembers.forEach((member) => {
          rearrangedMembers.push({
            live_notifications: member.live_notifications,
            role: member.role,
            ticket_id: member.ticket_id,
            user_id: member.user_id,
            user: {
              name: member.user_name,
              user_pic: member.user_pic,
            },
          });
        });
      }

      // Do not remove ticket from the list if it does not match the current filter. Just update it.

      if (rearrangedMembers) ticket.teacket_ticket_members = rearrangedMembers; // do not update if currentTicketMembers was null

      const index = userTicketList.findIndex((x) => x.id === currentTicket.id);
      userTicketList[index] = ticket;
      setUserTicketList([...userTicketList]);
    } catch (error) {
      console.error(error);
    } finally {
      setTicketAfterUpdateSync(null);
    }
  };

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        const tickets = await getTicketsMyOpen();
        setUserTicketList(tickets);
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <>
      <TicketDrawer />
      <CondensedTable columns={columns} dataSource={userTicketList} rowKey={'id'} />
    </>
  );
};

UserTicketListWidget.propTypes = {};

export default UserTicketListWidget;
