import React from 'react';
import { ReactComponent as GenesysLogo } from 'src/components/svg/genesys-logo.svg';
import PropTypes from 'prop-types';

const AppLogo = ({ minimal, onClick }) => {
  if (minimal) return <GenesysLogo style={{ height: '28px', width: '18px' }} className='text-genesys-orange-base' />;
  return (
    <div className='shrink-0 mr-4 flex flex-row gap-2 font-roboto'>
      <div
        className={`h-7 flex flex-row gap-2 items-baseline select-none ${onClick ? 'cursor-pointer' : ''}`}
        onClick={() => {
          if (!onClick) return;
          onClick();
        }}
      >
        <GenesysLogo style={{ height: '28px', width: '18px' }} className='text-genesys-orange-base' />
        <div className='text-3xl font-bold text-black dark:text-white'>SPC</div>
        <div className='text-xl opacity-70 font-extralight text-genesys-gray-base'>Portal</div>
      </div>
    </div>
  );
};

AppLogo.propTypes = {
  minimal: PropTypes.bool,
  onClick: PropTypes.func,
};

AppLogo.defaultProps = {
  minimal: false,
};

export default AppLogo;
