import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'src/misc/Misc';
import CondensedTable from 'src/components/layout/CondensedTable';
import { getOrgProductLine } from 'src/api/snowflake';
import SkeletonTable from 'src/components/layout/SkeletonTable';

const ProductLine = ({ orgId }) => {
  const [productLine, setProductLine] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, [orgId]);

  const loadData = async () => {
    setLoading(true);
    try {
      setProductLine(null);
      const resp = await getOrgProductLine(orgId);
      const pl = groupBy(resp, 'PRODUCTLINE__C');
      setProductLine(pl);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const columnsMain = [
    {
      title: 'Product line',
      key: 'product-line',
      render: (record) => {
        return <>{record.PRODUCTLINE__C}</>;
      },
    },
    {
      title: 'Items',
      key: 'items',
      align: 'right',
      render: (record) => {
        return <>{record.items?.length ?? 0}</>;
      },
    },
  ];

  const columnsSub = [
    {
      title: 'Product',
      key: 'product',
      render: (record) => {
        return <>{record.PRODUCT_NAME__C}</>;
      },
    },
    {
      title: 'Invoice start',
      key: 'invoice-start',
      render: (record) => {
        return <>{record.INVOICESTARTDATE}</>;
      },
    },
    {
      title: 'Invoice end',
      key: 'invoice-end',
      render: (record) => {
        return <>{record.INVOICEENDDATE}</>;
      },
    },
    {
      title: 'Measure',
      key: 'measure',
      render: (record) => {
        return <>{record.UNIT_OF_MEASURE__C}</>;
      },
    },
    {
      title: 'Qty',
      key: 'qty',
      render: (record) => {
        return <>{record.QUANTITY}</>;
      },
    },
    {
      title: 'Usage qty',
      key: 'usage-qty',
      render: (record) => {
        return <>{record.USAGE_QTY}</>;
      },
    },
  ];

  if (loading) {
    return <SkeletonTable />;
  }

  return (
    <div>
      <CondensedTable
        columns={columnsMain}
        dataSource={productLine}
        rowKey={(record) => record.PRODUCTLINE__C}
        pagination={{
          pageSize: 25,
          size: 'small',
        }}
        expandable={{
          expandedRowRender: (record) => (
            <div className='ml-14 mt-2 '>
              <CondensedTable
                columns={columnsSub}
                dataSource={record.items}
                rowKey={(record) => record.PRODUCT__C}
                pagination={{
                  pageSize: 10,
                  size: 'small',
                }}
              />
            </div>
          ),
          rowExpandable: (record) => record?.items?.length > 0,
        }}
      />
    </div>
  );
};

ProductLine.propTypes = {
  orgId: PropTypes.string,
};

export default ProductLine;
