import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';

export const getStatus = async (url) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/stats/integrations`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

