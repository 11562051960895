import React, { useState } from 'react';
import { Modal, Form, Input, notification } from 'antd';
import PropTypes from 'prop-types';

const { Item } = Form;
const { TextArea } = Input;

const AiTemplateCreate = ({ open, onClose, onSubmit }) => {
  const [form] = Form.useForm();

  const [disabled, setDisabled] = useState(true);

  const handleOnValuesChange = async (changedValues, allValues) => {
    form
      .validateFields()
      .then(() => {
        setDisabled(false);
      })
      .catch((errorFields) => {
        setDisabled(errorFields.errorFields.length > 0);
      });
  };

  const handleOnFinish = (values) => {
    if (onSubmit) onSubmit(values);
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: 'Error',
      description: <>Provided data is invalid</>,
      duration: 5,
    });
  };

  const handleOnReset = () => {
    //setModified(false);
  };

  return (
    open && (
      <Modal
        open={open}
        title='Create an AI Template'
        onCancel={() => {
          if (onClose) onClose();
        }}
        okButtonProps={{ disabled: disabled }}
        onOk={form.submit}>
        <Form name='aiTemplateForm' style={{ maxWidth: 600 }} layout='vertical' form={form} initialValues={{}} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} onReset={handleOnReset} onValuesChange={handleOnValuesChange} autoComplete='off'>
          <Item label='Name' name='name' required={true} rules={[{ required: true, message: 'Enter a name for your AI template' }]}>
            <Input placeholder='Enter a name for your AI template' autoComplete='off' allowClear />
          </Item>
          <Item label='Content' name='content' required={true} rules={[{ required: true, message: 'Enter the content of your AI template' }]}>
            <TextArea rows={8} placeholder='Enter the content of your AI template' autoComplete='off' allowClear />
          </Item>
        </Form>
      </Modal>
    )
  );
};

AiTemplateCreate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

AiTemplateCreate.defaultProps = {
  open: false,
};

export default AiTemplateCreate;
