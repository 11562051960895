import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import ServiceCard from './ServiceCard';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getStatus } from 'src/api/integrationsStatus';



const IntegrationsStatus = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    executeAsyncProcess(async () => {
      try {
        const resp = await getStatus();
        console.log('services:', resp);
        setServices(Array.isArray(resp) ? resp : []);

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    });
  };

  if (loading) {
    return (
      <div className='flex justify-center items-center min-h-[250px]'>
        <Spin size='large' />
      </div>
    );
  }

  return (
    <>
      <div className='flex flex-wrap justify-center gap-4 p-2 min-h-[250px]'>
        {services.map((service) => (
          <ServiceCard
            key={service.name}
            name={service.name}
            status={service.status}
            lastErrorMessage={service.lastErrorMessage}
            lastErrorDate={service.lastErrorDate}
            eventCount={service.eventCount}
            eventCountCurrent={service.eventCountCurrent}
            errCount={service.errorCount}
            className='w-64 flex-shrink-0'
          />
        ))}

      </div>
      <div className='text-center mt-4 text-xs opacity-60'>
        Errors clears at midnight <br />History retains for 30 days
      </div>
    </>
  );
};

export default IntegrationsStatus;