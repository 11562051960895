import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getTicketHistoryDetails } from 'src/api/teacket';
import JSONViewer from 'src/components/controls/JSONViewer/JSONViewer';

const TicketHistoryItem = ({ item, ticketId }) => {
  const [details, setDetails] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    console.log('loadData()');

    try {
      const result = await getTicketHistoryDetails(ticketId, item.id);
      setDetails(result);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='font-xs'>
      <JSONViewer data={details?.data ?? {}} fontSize='0.75rem' />
    </div>
  );
};

TicketHistoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  ticketId: PropTypes.string.isRequired,
};

export default TicketHistoryItem;
