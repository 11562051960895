import React, { useContext } from 'react';
import { Checkbox } from 'antd';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';

//import PropTypes from 'prop-types';

const DSRModeRealOrFakeStep = () => {
  const { useRealApi, setUseRealApi } = useContext(DataSubjectRequestsContext);

  return (
    <div>
      <div className='font-bold text-xl '>Real or fake API requests?</div>
      <div className='font-extralight'>
        <div className='mt-3'>
          <Checkbox
            onChange={(e) => {
              setUseRealApi(e.target.checked);
            }}
            checked={useRealApi}>
            Check this if you want to submit real GDPR API requests. If selected, requests will take a few days.
          </Checkbox>
        </div>
        <div className='mt-2'>
          Then click on <strong>Next</strong> to continue.
        </div>
      </div>
    </div>
  );
};

DSRModeRealOrFakeStep.propTypes = {};

export default DSRModeRealOrFakeStep;
