import React, { useContext } from 'react';
//import PropTypes from 'prop-types';
import { Space, Input, Button, Form, Switch } from 'antd';
import { postTicketConversation } from 'src/api/teacket';
import useUserInfo from 'src/hooks/useUserInfo';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import TeacketContext from 'src/context/TeacketContext';

//import PropTypes from 'prop-types';

const ConversationNewMessage = () => {
  const [postMessageForm] = Form.useForm();
  const { executeAsyncProcess } = useAsyncProcesses();
  const userInfo = useUserInfo();
  const { currentTicket, addCurrentTicketConversationMessage, currentTicketIsMasterTenant, determineIsWidgetReadOnly } = useContext(TeacketContext);

  const messageValue = Form.useWatch('message', postMessageForm);

  const handleFormOnFinish = async (values) => {
    console.log('Success:', values);

    executeAsyncProcess(async () => {
      try {
        const userId = userInfo.id;
        const text = values.message.replace(/\n/g, '<br />');
        const visibility = values.isPublic ? 'external' : 'internal';
        let newMessage = { userId, text, visibility, is_master_tenant: true === currentTicketIsMasterTenant };
        addCurrentTicketConversationMessage({ ...newMessage, isPosting: true, user_name: userInfo.name, user_pic: userInfo.user_pic });
        postMessageForm.resetFields();
        delete newMessage.owner;
        let confirmedMessage = await postTicketConversation(currentTicket.id, newMessage);
        addCurrentTicketConversationMessage({ ...confirmedMessage, isNew: true, user_name: userInfo.name, user_pic: userInfo.user_pic, is_master_tenant: true === currentTicketIsMasterTenant });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleFormOnFailure = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='mt-4'>
      <Form form={postMessageForm} name='ticket-create-form' initialValues={{ remember: true }} onFinish={handleFormOnFinish} onFinishFailed={handleFormOnFailure} autoComplete='off' layout='vertical'>
        <Form.Item label='New message' name='message' rules={[{ required: true, message: "Message can't be empty" }]} initialValue={''}>
          <Input.TextArea placeholder='Enter a message...' autoSize={{ minRows: 2, maxRows: 4 }} maxLength={5000} showCount disabled={true === determineIsWidgetReadOnly('Conversation')} />
        </Form.Item>
        <div className='flex flex-row gap-2'>
          <div>
            <Form.Item>
              <Space>
                <Button htmlType='reset' disabled={true === determineIsWidgetReadOnly('Conversation') || !messageValue}>
                  Clear Text
                </Button>
                <Button type='primary' htmlType='submit' disabled={true === determineIsWidgetReadOnly('Conversation')}>
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </div>
          <div className={`${true === determineIsWidgetReadOnly('Conversation') || currentTicketIsMasterTenant !== true ? 'hidden' : ''}`}>
            <Form.Item name='isPublic' valuePropName='checked' initialValue={true}>
              <Switch unCheckedChildren='Internal' checkedChildren='Public' disabled={true === determineIsWidgetReadOnly('Conversation') || currentTicketIsMasterTenant !== true} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

//ConversationNewMessage.propTypes = {};

export default ConversationNewMessage;
