import React from 'react';
import { useNavigate } from 'react-router-dom';
import useMasterAdmin from 'src/hooks/useMasterAdmin';
import useUserInfo from 'src/hooks/useUserInfo';
import OptionButton from 'src/components/layout/OptionButton';
import { getAdminItems } from '../Misc/Config';
import { notEmptyArray } from 'src/misc/Misc';
import SectionTitle from 'src/components/controls/SectionTitile/SectionTitle';
//import PropTypes from 'prop-types';

const AdminItems = () => {
  const { permissions: userPermissions } = useUserInfo();
  const { homePageFilter } = useMasterAdmin();
  const navigate = useNavigate();

  return (
    <div className='grid grid-cols-1 xl:grid-cols-2 gap-12 py-8 justify-center'>
      {getAdminItems(userPermissions, homePageFilter).map((groupItem, i1) => (
        <div key={i1}>
          {true === notEmptyArray(groupItem.items) && (
            <>
              <SectionTitle title={groupItem.group} />
              <div className='flex flex-row flex-wrap gap-4 p-4 justify-center'>
                {groupItem.items.map((item, i2) => (
                  <React.Fragment key={i2}>
                    <OptionButton
                      title={item.title}
                      icon={item.icon}
                      onClick={() => {
                        navigate(item.path);
                      }}
                      imageBgColor={item.imageBgColor}
                      imageBgColorDark={item.imageBgColorDark}
                    />
                  </React.Fragment>
                ))}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

AdminItems.propTypes = {};

export default AdminItems;
