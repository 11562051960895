import React from 'react';
import useUserInfo from 'src/hooks/useUserInfo';
import { ReactComponent as UserMockup } from 'src/components/svg/user-mockup.svg';
import PropTypes from 'prop-types';

const CurrentUserPicture = ({ size }) => {
  const userInfo = useUserInfo();

  return <> {userInfo.image ? <img src={userInfo.image} alt='user' className='rounded-full border-2 border-solid border-sky-500' style={{ height: `${size}px`, width: `${size}px` }} /> : <UserMockup className='rounded-full border-2 border-solid border-sky-500' style={{ height: `${size}px`, width: `${size}px` }} />}</>;
};

CurrentUserPicture.propTypes = {
  size: PropTypes.number,
};

CurrentUserPicture.defaultProps = {
  size: 32,
};

export default CurrentUserPicture;
