import React, { useEffect } from 'react';
import useTheme from 'src/hooks/useTheme';
import { useNavigate } from 'react-router-dom';
import SectionTitle from 'src/components/controls/SectionTitile/SectionTitle';
import useMasterAdmin from 'src/hooks/useMasterAdmin';
import { Spin, Card, Space } from 'antd';
import { getAdminStats } from 'src/api/accesscontrol';
import { LuUsers2 } from 'react-icons/lu';
import { notEmptyArray } from 'src/misc/Misc';
import UserBadge from '../../Users/Components/UserBadge';
import CountUp from 'react-countup';
import { PieChart } from '@mui/x-charts/PieChart';
import MuiTheme from 'src/misc/MuiTheme';
//import PropTypes from 'prop-types';

const AdminStats = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { adminStats, setAdminStats } = useMasterAdmin();

  useEffect(() => {
    if (!adminStats) loadData();
  }, []);

  const loadData = async () => {
    try {
      const resp = await getAdminStats();
      setAdminStats(resp);
    } catch (e) {
      console.error(e);
    }
  };

  if (!adminStats)
    return (
      <div className='text-center'>
        <Spin />
      </div>
    );

  return (
    <div className='py-8'>
      <SectionTitle title={'Number of users'} />
      <div className='p-4 flex flex-row justify-center'>
        <div className='flex flex-row justify-center gap-4 flex-wrap'>
          <Card
            title={
              <Space className='btn' style={{ color: theme.textBase }}>
                <LuUsers2 />
                Total
              </Space>
            }
            className='w-36 text-center'>
            <div className='text-center text-5xl'>
              <CountUp end={adminStats?.usersCount?.total ?? -1} separator=',' duration={3} />
            </div>
          </Card>
          <div className='w-80'>
            <MuiTheme>
              <PieChart
                colors={['#76b7b2', 'rgb(251 113 133)']}
                title='Users'
                series={[
                  {
                    arcLabel: (item) => item.value,
                    data: [
                      { label: 'Enabled', value: adminStats?.usersCount?.enabled },
                      { label: 'Disabled', value: adminStats?.usersCount?.disabled },
                    ],
                    innerRadius: 10,
                    outerRadius: 70,
                    paddingAngle: 5,
                    cornerRadius: 5,
                  },
                ]}
                height={170}
                slotProps={{
                  legend: {
                    hidden: false,
                    direction: 'column',
                    itemMarkWidth: 20,
                    itemMarkHeight: 10,
                    labelStyle: {
                      fontFamily: 'Nunito',
                      fontSize: 12,
                      color: theme.textBase,
                    },
                  },
                }}
              />
            </MuiTheme>
          </div>
        </div>
      </div>
      {true === notEmptyArray(adminStats?.recentUsers) && (
        <>
          <SectionTitle title={'5 most recently created users'} />
          <div className='p-4 flex flex-row gap-4 flex-wrap justify-center'>
            {adminStats?.recentUsers.map((u, i) => (
              <div key={i}>
                <UserBadge
                  key={u.user_id}
                  id={u.user_id}
                  name={u.user_name}
                  tenantName={u.tenant_name}
                  email={u.user_email}
                  picture={u.user_pic}
                  onClick={(userId) => {
                    navigate(`/master-admin/users?userId=${userId}`);
                  }}
                  isDisabled={u.user_disabled}
                  newCreated={u.newCreated}
                  createdAt={u.user_created_at}
                />
              </div>
            ))}
          </div>
        </>
      )}

      <div></div>
    </div>
  );
};

AdminStats.propTypes = {};

export default AdminStats;
