import { useContext } from 'react';
import GlobalContext from 'src/context/GlobalContext';

const useAsyncProcesses = () => {
  const execAsyncCodeInSyncFunc = async (func) => await func();
  const { executeAsyncProcess, isBusy } = useContext(GlobalContext);
  return { executeAsyncProcess, isBusy, execAsyncCodeInSyncFunc };
};

export default useAsyncProcesses;
