import React from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';
import PropTypes from 'prop-types';

const StyledSkeleton = styled.div`
  .ant-skeleton.ant-skeleton-element {
    width: 100% !important;
  }

  .ant-skeleton-input {
    width: 100% !important;
  }
`;

const SkeletonTable = ({ rows = 6 }) => {
  return (
    <StyledSkeleton>
      <div className='flex flex-col gap-1 mt-1 items-center'>
        {[...Array(rows)].map((_, i) => (
          <Skeleton.Input key={i} active size={20} />
        ))}
      </div>
    </StyledSkeleton>
  );
};

SkeletonTable.propTypes = {
  rows: PropTypes.number,
};

export default SkeletonTable;
