import React from 'react';
import Header from 'src/components/pages/Landing/components/ui/Header';
import { BsRobot } from 'react-icons/bs';
import { Space } from 'antd';
//import PropTypes from 'prop-types';

const Copilot = () => {
  return (
    <>
      <div className='fixed z-50'>
        <Header />
      </div>
      <div className='absolute flex flex-col items-center w-full'>
        <div className='mt-24 max-w-7xl '>
          <div className='my-8 font-bold text-2xl'>
            <Space>
              <BsRobot />
              COE AI Assistant
            </Space>
          </div>
          <div className='my-8'>COE AI Assistant is designed to be your virtual advisor, providing real-time assistance, insights, and expert guidance tailored specifically to the intricacies of Genesys Cloud security. Whether you're a seasoned professional seeking advanced strategies or a newcomer looking to understand the fundamentals, COE AI Assistant is here to assist you every step of the way.</div>
          <div className='flex justify-center'>
            <img src='/images/products/COEAIAssistant/COE_AI_Assistant_Screenshot.png' alt='AI Assistant' className='shadow-xl dark:shadow-genesys-gray-400 w-3/4 rounded-lg' />
          </div>
          <div className='my-8'>With its intuitive interface, COE AI Assistant makes it easy to access a wealth of information, including best practices, compliance standards, and actionable tips for optimizing security measures within your Genesys Cloud environment. No matter the challenge or question you may have, COE AI Assistant is equipped with the knowledge and resources to help you find the answers you need.</div>
          <div className='my-8'>But COE AI Assistant isn't just about providing information – it's about fostering a collaborative and interactive experience. Engage in meaningful conversations, exchange ideas with fellow users, and tap into a community of security-conscious individuals who share your commitment to safeguarding sensitive data and ensuring compliance with regulations.</div>
          <div className='my-8'>Whether you're exploring the latest security features, assessing potential risks, or seeking clarification on compliance requirements, COE AI Assistant is your go-to resource for all things Genesys Cloud security, privacy, and compliance. Empower yourself with the knowledge and support you need to navigate the ever-evolving landscape of digital security – all with the guidance of your trusted companion, COE AI Assistant.</div>
        </div>
      </div>
    </>
  );
};

// Copilot.propTypes = {};

export default Copilot;
