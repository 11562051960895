import React, { useState, useContext, useEffect } from 'react';
import FsiReportContext from 'src/context/FsiReportContext';
import { Select, Button, Modal, Space, Alert } from 'antd';
import PropTypes from 'prop-types';
import { FaAws } from 'react-icons/fa6';
import { LuBuilding } from 'react-icons/lu';

import { AiOutlineInfoCircle } from 'react-icons/ai';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { patchProjectProps } from 'src/api/fsireport';
import { getAccOrgs } from 'src/api/snowflake';

const UniqueRegions = ({ onSelect, wizardStep }) => {
  const { currentProject, createNewUniqueRegions, AWS_REGION_NAMES, createNewRegionList, patchCurrentProjectProps } = useContext(FsiReportContext);

  const [selectedItems, setSelectedItems] = useState([]);
  const [onLoadCodes, setOnLoadCodes] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { executeAsyncProcess } = useAsyncProcesses();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deploymentsForAccId, setDeploymentsForAccId] = useState(false);

  const [regionWarning, setRegionWarning] = useState(false);

  //#region Modal State

  const showModal = async () => {
    setIsModalOpen(true);

    if (wizardStep && createNewRegionList) {
      return;
    }

    //setLoading(true);

    // try {
    //   const orgs = await getAccOrgs(currentProject?.accId);
    //   setDeploymentsForAccId(orgs);
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setLoading(false);
    // }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  //#endregion

  useEffect(() => {
    if (wizardStep && createNewUniqueRegions) {
      setSelectedItems(createNewUniqueRegions);
      setOnLoadCodes(createNewUniqueRegions);
    }
    if (!wizardStep && currentProject?.props?.uniqueAwsRegions) {
      setSelectedItems(currentProject.props.uniqueAwsRegions);
      setOnLoadCodes(currentProject.props.uniqueAwsRegions);

      // Check if selected regions are accurate
      loadDeployments();
    }
  }, [currentProject, createNewUniqueRegions]);

  const loadDeployments = async () => {
    if (!currentProject) return;
    try {
      const orgs = await getAccOrgs(currentProject?.accId);
      setDeploymentsForAccId(orgs);

      const uniqueRegs = [...new Set(orgs.map((x) => x.AWS_REGION__C))];

      if (isEqual(currentProject?.props?.uniqueAwsRegions, uniqueRegs)) {
        console.log('Regions are accurate');
        setRegionWarning(false);
      } else {
        console.log('Regions are NOT accurate');
        setRegionWarning(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Convert region codes to their corresponding names for display
  const selectedNames = selectedItems.map((item) => AWS_REGION_NAMES[item]);

  // Filter the options based on selectedItems and currentProject.props.uniqueAwsRegions
  const filteredOptions = Object.keys(AWS_REGION_NAMES)
    .filter((regionKey) => !selectedItems.includes(regionKey))
    .map((regionKey) => ({
      value: regionKey,
      label: (
        <span>
          {AWS_REGION_NAMES[regionKey]} <p className='text-xs font-thin text-gray-500 mt-0 mb-0'>{regionKey}</p>
        </span>
      ),
    }));

  const handleSelect = (values) => {
    const selectedCodes = values.map((name) => Object.keys(AWS_REGION_NAMES).find((key) => AWS_REGION_NAMES[key] === name));
    setSelectedItems(selectedCodes);
    if (onSelect && wizardStep) onSelect(selectedCodes);

    // Compare if 2 arrays are equal, order doesn't matter

    if (isEqual(selectedCodes, onLoadCodes)) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };

  const handleApplyButton = () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await patchProjectProps(currentProject.id, { uniqueAwsRegions: selectedItems });
        console.log('uniqueAwsRegionsResp:', resp);
        patchCurrentProjectProps({ uniqueAwsRegions: resp.uniqueAwsRegions });
        setButtonDisabled(true);
        setOnLoadCodes(selectedItems);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const orgProperty = (x) => {
    return (
      <div key={x.ORG_ID} className='border rounded-xl border-solid py-1 px-3 border-gray-200 dark:border-gray-600 mb-2'>
        <div className='font-bold mb-1'>{x.ORG_NAME__C}</div>
        <div className='font-light opacity-60'>
          <Space className='btn'>
            <LuBuilding />
            {x.ORG_ID}
          </Space>
        </div>
        <div className='font-light opacity-60'>
          <Space className='btn'>
            <FaAws />
            {x.AWS_REGION__C}
          </Space>
        </div>
      </div>
    );
  };

  const isEqual = (a, b) => a.length === b.length && a.every((v) => b.includes(v));

  return (
    <>
      <Select
        mode='multiple'
        placeholder='Select regions'
        value={selectedNames}
        onChange={handleSelect}
        style={{
          width: '100%',
        }}
        options={filteredOptions.map((option) => ({ ...option, value: AWS_REGION_NAMES[option.value] }))}
      />
      <div className='text-xs text-center text-genesys-gray-500'>
        <Space>
          <p className='text-left'>List of AWS regions related to the Genesys Cloud deployment.</p>
          <AiOutlineInfoCircle className='text-right text-blue-700 dark:text-blue-500' onClick={showModal} />
        </Space>
      </div>

      {!wizardStep && (
        <>
          {regionWarning && <Alert message='The selected regions do not match the regions available online.' type='warning' showIcon closable size='small' onClick={showModal} />}
          <p className='mt-2 mb-0 text-right'>
            <Button disabled={buttonDisabled} type='primary' size='small' onClick={() => handleApplyButton()}>
              Update
            </Button>
          </p>
        </>
      )}

      <Modal
        title={<div className='text-center mb-4'>The organization(s) linked to the account.</div>}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={<Button onClick={handleCancel}>Close</Button>}
        styles={{
          body: {
            maxHeight: '70vh',
            overflowY: 'auto',
          },
        }}
      >
        {wizardStep ? (
          // Wizard Step

          !createNewRegionList ? (
            <Alert message='No organizations found for the account' type='warning' showIcon />
          ) : (
            <div className='flex flex-col gap-4'>{createNewRegionList && createNewRegionList.map((x) => orgProperty(x))}</div>
          )
        ) : (
          // Not Wizard Step - load Data
          deploymentsForAccId && deploymentsForAccId.length > 0 && deploymentsForAccId.map((x) => orgProperty(x))
        )}
      </Modal>
    </>
  );
};

UniqueRegions.propTypes = {
  onSelect: PropTypes.func,
  wizardStep: PropTypes.bool,
  regionDetails: PropTypes.array,
};

UniqueRegions.defaultProps = {
  regionDetails: [],
  wizardStep: false,
};

export default UniqueRegions;
