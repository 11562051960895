import React, { useContext, useState, useEffect } from 'react';
import HomePageContext from 'src/context/HomePageContext';
import BoxType1 from './BoxType1';
import BoxType2 from './BoxType2';
import { PiCertificateFill } from 'react-icons/pi';
import { FaFileDownload, FaFile, FaArrowAltCircleDown, FaEye } from 'react-icons/fa';
import { RiCheckboxMultipleBlankFill, RiCheckboxMultipleFill } from 'react-icons/ri';
import { Button, Space, notification, Modal, Tooltip, Spin } from 'antd';
import DownloadItem from './DownloadItem';
import NoData from 'src/components/layout/NoData';

import { getWatermarkedFile } from 'src/api/microsoft';
import { getAll } from 'src/api/download';
import PropTypes from 'prop-types';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import { CloseOutlined } from '@ant-design/icons';
import DocumentsCheckboxGroup from './DocumentsCheckboxGroup';

const DownloadButtonComponent = ({ children, type, onClick }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await onClick();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button type={type} loading={isLoading} onClick={handleClick} size='large'>
      {children}
    </Button>
  );
};
DownloadButtonComponent.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

const contentStyle = {
  padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
};
const content = <div style={contentStyle} />;

const SectionDownload = () => {
  const [sections, setSections] = useState([]);
  const [busy, setBusy] = useState(false);
  const { openDrawer } = useContext(HomePageContext);

  const [openModal, setOpenModal] = useState(false);
  const [selectedDocsModal, setSelectedDocsModal] = useState([]);

  useEffect(() => {
    loadSections();
  }, []);

  useEffect(() => {
    if (!openModal) setSelectedDocsModal([]);
  }, [openModal]);

  const loadSections = async () => {
    try {
      setBusy(true);
      const resp = (await getAll()) ?? [];
      setSections(resp);
    } catch (error) {
      console.log(error);
    } finally {
      setBusy(false);
    }
  };

  const setPicture = (picId) => {
    const base64Image = 'data:image/png;base64,' + picId;
    return picId ? <img src={base64Image} alt='logo' className='w-16 h-16 object-contain' /> : <NoData label='' />;
  };

  const handleItemOnClick = async (item) => {
    openDrawer(
      <Space className='btn'>{item.title}</Space>,
      <div className='flex flex-col gap-4'>
        <BoxType1 title={setPicture(item.icon)}>
          <div className='flex flex-col gap-4'>
            <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
            <div>
              {item.url && (
                <Button type='primary' href={item.url} target='_blank' size='large'>
                  <Space className='btn'>
                    <FaEye />
                    See more
                  </Space>
                </Button>
              )}
            </div>
          </div>
        </BoxType1>

        {item.files?.map((file, index) => (
          <BoxType1
            key={index}
            title={
              <>
                <Space className='btn'>
                  <FaFile />
                  {file.title ?? file.name}
                </Space>
              </>
            }
          >
            <div dangerouslySetInnerHTML={{ __html: file.desc }}></div>
            <div className='mt-4'>
              <DownloadButtonComponent
                type='primary'
                onClick={async () => {
                  try {
                    const fileUrl = await getWatermarkedFile(item.section_id, item.id, file.id, file.url, file.sp_path ?? 'SPC Portal/AI-approved Documents');

                    let anchor = document.createElement('a');
                    anchor.href = fileUrl.url;
                    anchor.download = file.name;
                    anchor.target = '_self';

                    document.body.appendChild(anchor);
                    anchor.click();
                    document.body.removeChild(anchor);
                  } catch (error) {
                    notification.error(error.message);
                  }
                }}
              >
                <Space className='btn'>
                  <FaFileDownload />
                  Download
                </Space>
              </DownloadButtonComponent>
            </div>
          </BoxType1>
        ))}

        {item.files?.length > 1 && (
          <div className='mt-6'>
            <DownloadButtonComponent
              type='primary'
              onClick={async () => {
                for await (const file of item.files) {
                  const fileUrl = await getWatermarkedFile(item.section_id, item.id, file.id, file.url, file.sp_path ?? 'SPC Portal/AI-approved Documents');

                  let anchor = document.createElement('a');
                  anchor.href = fileUrl.url;

                  document.body.appendChild(anchor);
                  anchor.click();
                  document.body.removeChild(anchor);
                }
              }}
            >
              <Space className='btn'>
                <FaArrowAltCircleDown />
                Download all {item.files?.length} files
              </Space>
            </DownloadButtonComponent>
          </div>
        )}
      </div>
    );
    // window.open(item.url, '_blank');
  };

  const handleBulkDownload = async () => {
    if (true === notArrayOrEmpty(selectedDocsModal)) return;

    for await (const doc of selectedDocsModal) {
      const fileUrl = await getWatermarkedFile(doc.section_id, doc.item_id, doc.id, doc.url, doc.sp_path ?? 'SPC Portal/AI-approved Documents');

      let anchor = document.createElement('a');
      anchor.href = fileUrl.url;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
    setOpenModal(false);
  };

  const getAllFiles = (sectionId) => {
    console.log('getAllFiles() for SectionId', sectionId);
    const currentSection = sections.find((x) => x.id === sectionId);
    const itemList = currentSection?.download_items ?? [];

    let result = [];
    itemList?.forEach((option) => {
      if (option.files) {
        result = result.concat(option.files.map((file) => ({ ...file, section_id: option.section_id, item_id: option.id })));
      }
    });

    return result;
  };

  if (true === notArrayOrEmpty(sections)) return null;

  return (
    <div className='flex flex-col gap-6'>
      {busy && (
        <div className='flex justify-center items-center h-full'>
          <Spin tip='Loading'>{content}</Spin>
        </div>
      )}

      <Modal
        closable={false}
        onCancel={() => setOpenModal(false)}
        title={
          <div className='flex flex-row gap-1'>
            <div className='flex-auto'>
              <Space className='btn text-xl'>
                <FaArrowAltCircleDown />
                Bulk Download
              </Space>
            </div>
            <div className='flex flex-row gap-1'>
              <Tooltip title='Select all'>
                <Button type='text' onClick={() => setSelectedDocsModal(getAllFiles(openModal))}>
                  <RiCheckboxMultipleFill className='text-xl' />
                </Button>
              </Tooltip>
              <Tooltip title='Deselect all'>
                <Button type='text' onClick={() => setSelectedDocsModal([])}>
                  <RiCheckboxMultipleBlankFill className='text-xl' />
                </Button>
              </Tooltip>
              <Tooltip title='Close'>
                <Button type='text' onClick={() => setOpenModal(false)}>
                  <CloseOutlined className='text-xl' />
                </Button>
              </Tooltip>
            </div>
          </div>
        }
        open={openModal ? true : false}
        footer={[
          <Button size='large' key='back' onClick={() => setOpenModal(false)}>
            <Space className='btn'>
              <CloseOutlined />
              Cancel
            </Space>
          </Button>,
          <DownloadButtonComponent size='large' className='font-bold' key='submit' type='primary' onClick={async () => await handleBulkDownload()} disabled={true === notArrayOrEmpty(selectedDocsModal)}>
            <Space className='btn'>
              <FaFileDownload />
              Download {true === notEmptyArray(selectedDocsModal) ? selectedDocsModal.length : '0'}
              {selectedDocsModal.length > 1 ? 'documents' : 'document'}
            </Space>
          </DownloadButtonComponent>,
        ]}
        width={800}
      >
        <div className='py-4 h-[60vh] overflow-auto'>
          <DocumentsCheckboxGroup options={getAllFiles(openModal)} value={selectedDocsModal} onChange={(v) => setSelectedDocsModal(v)} />
        </div>
      </Modal>

      {!busy &&
        sections
          .filter((section) => section.is_active)
          .map((section, index) => (
            <BoxType2
              key={index}
              title={
                <Space className='btn'>
                  <PiCertificateFill />
                  <div>
                    {section.title}
                    <span className='font-thin opacity-60'> | {section.subtitle ? section.subtitle : 'downloads'}</span>
                  </div>
                </Space>
              }
              extras2={
                <>
                  <Button onClick={() => setOpenModal(section.id)}>
                    <Space className='btn'>
                      <FaArrowAltCircleDown />
                      Bulk Download
                    </Space>
                  </Button>
                </>
              }
            >
              <div className='flex flex-col justify-center gap-6 my-4'>
                <div className='grid grid-cols-2 md:grid-cols-4 lg:md:grid-cols-6 gap-6 text-center'>
                  {section?.download_items
                    .filter((item) => item.is_active)
                    .map((item, index) => (
                      <DownloadItem
                        key={index}
                        image={item.icon}
                        title={item.title}
                        hasFiles={notEmptyArray(item.files)}
                        onClick={() => {
                          handleItemOnClick(item);
                        }}
                      />
                    ))}
                </div>
              </div>
            </BoxType2>
          ))}
    </div>
  );
};

SectionDownload.propTypes = {};

export default SectionDownload;
