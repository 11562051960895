import React from 'react';
import FeatureAccess from 'src/components/controls/FeatruesAccess/FeaturesAccess';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import SetupRequestsWizard from './SetupRequestsWizard/SetupRequestsWizard';

const NewDataSubjectRequest = () => {
  return (
    <PermissionsProvider requiredPermissions={['apps.datasubjectrequests.view']}>
      <FeatureAccess feature='subject_request' featureDisplayName='Data Subject Requests'>
        <div className='bg-gray-100 dark:bg-gray-700 min-h-screen flex flex-col justify-center items-center'>
          <SetupRequestsWizard />
        </div>
      </FeatureAccess>
    </PermissionsProvider>
  );
};

NewDataSubjectRequest.propTypes = {};

export default NewDataSubjectRequest;
