import React, { useState, useEffect } from 'react';
import useTheme from 'src/hooks/useTheme';
import { Space, Input, Spin, Button, message, Typography, Tag } from 'antd';
import { searchPermissions, postPermission, patchPermission, deletePermission } from 'src/api/accesscontrol';
import TitleBar from 'src/components/layout/TitleBar';
import StyledButton from 'src/components/layout/StyledButton';
import { FaPlus } from 'react-icons/fa6';
import PermissionCreate from './PermissionCreate';
import { SearchOutlined } from '@ant-design/icons';
import { isRequestCancelled, notEmptyArray } from 'src/misc/Misc';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { FaRegTrashCan } from 'react-icons/fa6';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { MdDelete } from 'react-icons/md';
import { uuid } from 'short-uuid';
import CondensedTable from 'src/components/layout/CondensedTable';

const { Paragraph } = Typography;

const Permissions = () => {
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: 'Permission',
      key: 'permission',
      width: 320,
      render: (record) => (
        <>
          {record.permission} {record.justCreated && <Tag color='blue'>just created</Tag>}
        </>
      ),
    },
    {
      title: 'Description',
      key: 'description',
      render: (record) => (
        <>
          <Paragraph
            className='font-extralight'
            style={{ padding: 0, margin: 0 }}
            editable={{
              onChange: async (description) => {
                if (description === record.description) return;
                if (description.length < 1) {
                  messageApi.open({
                    key: uuid(),
                    type: 'error',
                    content: 'Description is required',
                    duration: 3,
                  });

                  return;
                }
                executeAsyncProcess(async () => {
                  try {
                    const resp = await patchPermission(record.permission, { description });
                    const p = [...permissions];
                    const i = p.findIndex((x) => x.permission === record.permission);
                    p[i] = resp;
                    setPermissions(p);
                    messageApi.open({
                      key: uuid(),
                      type: 'success',
                      content: 'Permission has been updated',
                      duration: 3,
                    });
                  } catch (error) {
                    console.log(error);
                  }
                });
              },
            }}
          >
            {record.description}
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      align: 'center',
      render: (record) => (
        <Space className='btn'>
          <YesNoDialog
            title='Delete'
            body={
              <>
                Do you want to delete permission <strong>{record.permission}</strong>?
              </>
            }
            onYesClick={() => {
              handlePernamentlyDelete(record.permission);
            }}
            iconYes={<MdDelete />}
            showRed={true}
            labelYes='Yes, delete permission'
            labelNo='Cancel'
          >
            <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }}>
              <FaRegTrashCan />
            </Button>
          </YesNoDialog>
        </Space>
      ),
    },
  ];

  const pageSizeOptions = [20, 50, 100];

  const [permissions, setPermissions] = useState(null);
  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[0]));
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [newOpen, setNewOpen] = useState(false);
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   loadData();
  // }, []);

  useEffect(() => {
    setPage(1);
    setTotal(0);
    setPermissions(null);
    loadData();
  }, [query, pageSize]);

  useEffect(() => {
    loadData();
  }, [page]);

  const loadData = async (loadMore = false) => {
    setLoading(true);
    try {
      const q = query?.trim() ?? null;
      const resp = await searchPermissions(q?.length > 0 ? q : null, page, pageSize);
      setTotal(resp?.total ?? 0);
      setPermissions(resp.entities);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      console.log(error);
    }
    setLoading(false);
  };

  const handlePermissionCreateOnSubmit = async (values) => {
    console.log('handleTenantCreateOnSubmit', values);
    setNewOpen(false);
    executeAsyncProcess(async () => {
      try {
        const resp = await postPermission(values);
        const p = true === notEmptyArray(permissions) ? [...permissions] : [];
        p.unshift({ ...resp, justCreated: true });
        setPermissions(p);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'New permission has been created',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handlePernamentlyDelete = async (permission) => {
    console.log('handlePernamentlyDelete', permission);
    executeAsyncProcess(async () => {
      try {
        await deletePermission(permission);
        const o = permissions.filter((x) => x.permission !== permission);
        setPermissions(o);

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Permission has been deleted',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <>
      <PermissionCreate open={newOpen} onClose={() => setNewOpen(false)} onSubmit={handlePermissionCreateOnSubmit} />
      {contextHolder}
      <TitleBar
        isSticky={false}
        busyIndicator={false}
        isLoading={loading}
        colorBackground={theme.backgroundBase}
        title={
          <Space>
            <StyledButton color={theme.textLight} onClick={() => setNewOpen(true)} size='small'>
              <Space className='btn'>
                <FaPlus />
                <WhenDesktop>Create permission</WhenDesktop>
              </Space>
            </StyledButton>
          </Space>
        }
      >
        <Input
          loading
          // style={{ color: theme.textLight, borderColor: theme.textLight }}
          size='small'
          className='w-44 lg:w-60'
          placeholder='Search...'
          prefix={loading ? <Spin size='small' className='mr-2' /> : <SearchOutlined className='mr-2' />}
          allowClear
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </TitleBar>

      <CondensedTable
        pagination={{
          showSizeChanger: true,
          pageSizeOptions,
          size: 'small',
          onShowSizeChange: (current, size) => {
            console.log('onShowSizeChange', current, size);
            setPageSize(size);
          },
          current: page,
          pageSize,
          total,
          onChange: (page) => setPage(page),
        }}
        style={{ marginTop: '1px' }}
        size='small'
        columns={columns}
        dataSource={permissions}
        rowKey={'permission'}
      />
    </>
  );
};

Permissions.propTypes = {};

export default Permissions;
