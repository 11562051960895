import React, { useState, useEffect, useContext } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import FsiReportContext from 'src/context/FsiReportContext';
import { Spin, Tabs, Input, Button, Modal } from 'antd';
import { ReloadOutlined, FilePdfOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { getSimpleResponse } from 'src/api/securityqa';
import PropTypes from 'prop-types';
import { getLanguageFlag } from 'src/components/controls/LangPicker/LangFlag';

const { TabPane } = Tabs;
const { TextArea } = Input;

const ExecutionSummary = ({ disabled, onCreateReport, onCancel, onSave }) => {
  const { isBusy } = useAsyncProcesses();
  const { currentProject, externalAnswers, setExeSummaries, exeSummaries } = useContext(FsiReportContext);
  const determineCurrentLanguage = () => (true === currentProject?.props?.langs?.includes('en') ? 'en' : currentProject?.props?.langs[0]);

  const [currentLanguage, setCurrentLanguage] = useState(determineCurrentLanguage());
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('');
  //const [summaries, setSummaries] = useState({});

  useEffect(() => {
    // Rely on externalAnswers, as those are loaded async and we need to wait for them
    // before we can generate the summary
    // setExeSummaries({});

    if (!currentProject) return;
    if (currentProject?.questions.length === 0) return;
    //if (!externalAnswers) return;

    console.log('Current exeSummaries:', exeSummaries);
    if (!exeSummaries) getSummaries();
  }, []);

  const loadData = async (txt, useLang) => {
    try {
      setLoading(true);

      let prompt = 'You are a security expert reviewing Audit document. Write short executive summary of with data provided below. Use 30 words max. Do not put any title at the begining. Format your response professionally. Provide just the content.';
      if (useLang !== 'en') prompt += `\nRespond in the language indicated by the following language code: ${useLang.toUpperCase()}`;
      const resp = await getSimpleResponse(prompt, JSON.stringify(txt));

      return resp;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getSummaries = async (lang) => {
    // const areAllQuestionsAnswered = () => {
    //   const types = currentProject?.questions.map((q) => q.type).filter((type) => type !== 'yes/no');
    //   return types.every((type) => externalAnswers.hasOwnProperty(type));
    // };

    // if (!areAllQuestionsAnswered()) return;
    setLoading(true);
    try {
      console.log('Available languages', currentProject?.props.langs);
      for (let i = 0; i < currentProject?.props.langs.length; i++) {
        const useLang = currentProject?.props.langs[i];
        console.log('Language:', lang);
        setLoadingMsg(`Generating summary for language ${useLang.toUpperCase()}...`);
        let summarySet = [];

        currentProject.questions.forEach((q) => {
          const questionObj = q.translations.find((t) => t.language === useLang);
          if (!questionObj) return;
          // Load external data if needed for question
          switch (q.type) {
            case 'incidents':
              summarySet.push({
                question: questionObj.question,
                answer: externalAnswers[q.type],
              });
              break;
            case 'deprecations':
              summarySet.push({
                question: questionObj.question,
                answer: externalAnswers[q.type],
              });
              break;

            default:
              summarySet.push({
                question: questionObj.question,
                short_answer: questionObj.default_answer,
                answer: questionObj.default_answer_text,
              });
          }
        });
        const resp = await loadData(summarySet, useLang);
        //setExeSummaries((prev) => ({ ...prev, [useLang]: resp }));
        setExeSummaries({ [useLang]: resp });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Change the language of the question
  const handleLanguageChange = (language) => {
    console.log('Language change:', language);
    setCurrentLanguage(language);
  };

  const handleCreateReport = async () => {
    if (!onCreateReport) return;
    onCreateReport();
  };

  const handleOnCancel = () => {
    if (!onCancel) return;
    onCancel();
  };

  const handleOnSave = () => {
    if (!onSave) return;
    onSave(exeSummaries);
  };

  return (
    <Modal
      title='Executive Summary'
      closable={false}
      width={800}
      footer={[
        <Button key='cancel' icon={<CloseOutlined />} onClick={handleOnCancel} disabled={isBusy || loading}>
          Cancel
        </Button>,
        <Button key='generate' icon={<CheckOutlined />} onClick={handleOnSave} disabled={isBusy || loading}>
          Save
        </Button>,
        <Button key='generate' icon={<ReloadOutlined />} onClick={() => getSummaries()} disabled={isBusy || loading} loading={loading}>
          Re-generate
        </Button>,
        <Button key='create' type='primary' icon={<FilePdfOutlined />} onClick={handleCreateReport} disabled={isBusy || loading} loading={isBusy}>
          Save and create report
        </Button>,
      ]}
      open={true}
      onCancel={handleOnCancel}
    >
      <div className={isBusy && 'disabled'}>
        {/* {!loading && !summary && <div className='text-center mt-4 text-xs'>Waiting for the complete loading of all external resources…</div>} */}
        {loading ? (
          <div className='text-center pt-4 pb-4'>
            <Spin />
            <p className='text-xs text-genesys-gray-500'>{loadingMsg}</p>
          </div>
        ) : (
          <>
            <div className='text-right'>
              <div className='flex items-center space-x-4'>
                <Tabs defaultActiveKey={currentLanguage} onChange={handleLanguageChange} size='small' className='flex-shrink-0'>
                  {currentProject?.props.langs.includes('en') ? <TabPane tab={<div>{getLanguageFlag('en')} EN</div>} key='en' disabled={!currentProject?.props.langs.includes('en') || disabled} /> : <></>}
                  {currentProject?.props.langs.includes('de') ? <TabPane tab={<div>{getLanguageFlag('de')} DE</div>} key='de' disabled={!currentProject?.props.langs.includes('de') || disabled} /> : <></>}
                  {currentProject?.props.langs.includes('fr') ? <TabPane tab={<div>{getLanguageFlag('fr')} FR</div>} key='fr' disabled={!currentProject?.props.langs.includes('fr') || disabled} /> : <></>}
                </Tabs>
              </div>
            </div>

            <TextArea
              disabled={disabled}
              // rows={8}
              value={exeSummaries ? exeSummaries[currentLanguage] : ''}
              onChange={(e) => setExeSummaries({ ...exeSummaries, [currentLanguage]: e.target.value })}
              autoSize={{
                minRows: 4,
                maxRows: 8,
              }}
            />

            {/* <div className='text-xs text-genesys-gray-500'>
              <p className='text-left'>Summary will be saved during report generation.</p>
            </div> */}
          </>
        )}
      </div>
    </Modal>
  );
};

ExecutionSummary.propTypes = {
  disabled: PropTypes.bool,
  onCreateReport: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default ExecutionSummary;
