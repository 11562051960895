import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getAiModel, postAiModel, patchAiModel, deleteAiModelApi } from 'src/api/aiModels';
import { postPermission } from 'src/api/accesscontrol';
import { Button, Space, Form, Input, message, Tabs, Popover, Tooltip, Checkbox, Select } from 'antd';
import TitleBar from 'src/components/layout/TitleBar';
import { LuMenu } from 'react-icons/lu';
import { FaRobot } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';

import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import AiModelConnectorsModal from './AiModelConnectorsModal';
import AiModelConnectorListCard from './AiModelConnectorListCard';
import { MdDelete } from 'react-icons/md';
import { uuid } from 'short-uuid';
import AIModelsContext from 'src/context/AIModelsContext';

const bedrockModels = [
  {
    value: 'eu.anthropic.claude-3-haiku-20240307-v1:0',
    label: 'EU Claude 3 Haiku (cross-region) - Chat optimized',
    description: 'Claude 3 Haiku is Anthropic\'s fastest, most compact model for near-instant responsiveness. It answers simple queries and requests with speed. Customers will be able to build seamless AI experiences that mimic human interactions. Claude 3 Haiku can process images and return text outputs, and features a 200K context window.',
    default: true,
  },
  {
    value: 'eu.anthropic.claude-3-5-sonnet-20240620-v1:0',
    label: 'EU Claude 3.5 Sonnet (cross-region) - Complex reasoning',
    description: 'Claude 3.5 excels at complex tasks like customer support, coding, data analysis, and visual processing. It streamlines workflows, generates insights, and produces high-quality, natural-sounding content. It features a 200k context window.',
  },
];

const AiModelEditor = ({ aiModelId, onSave, onDelete }) => {
  const [form] = Form.useForm();

  const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();
  const { TextArea } = Input;
  const [messageApi, contextHolder] = message.useMessage();
  const [aiModel, setAiModel] = useState(null);
  const [modified, setModified] = useState(false);
  const [connectorsModalOpen, setConnectorsModalOpen] = useState(false);
  const [selectedBedrockModel, setSelectedBedrockModel] = useState(null);
  const [bedrockModelDescription, setBedrockModelDescription] = useState(null);

  const { deleteAiModel, addNewCreatedAiModel, setSelectedModel, selectedModel } = useContext(AIModelsContext);

  useEffect(() => {
    if (!aiModelId) {
      setAiModel(null);
      return;
    }
    if (aiModel?.id === aiModelId) return;

    // Clear current data
    setAiModel(null);

    // Load data
    loadData();
  }, [aiModelId]);

  // Set bedrock model
  useEffect(() => {
    if (!aiModel) return;

    // Set default model if none is selected
    if (!aiModel.bedrock_model) {
      const defaultModel = bedrockModels.find(model => model.default);
      if (defaultModel) {
        setSelectedBedrockModel(defaultModel.value);
        setBedrockModelDescription(defaultModel.description);
      }
    } else {
      // Set selected model from existing aiModel
      const currentModel = bedrockModels.find(model => model.value === aiModel.bedrock_model);
      if (currentModel) {
        setSelectedBedrockModel(currentModel.value);
        setBedrockModelDescription(currentModel.description);
      }
    }
  }, [aiModel]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      console.log('[AiModelEditor] Load data for aiModelId:', aiModelId);
      try {
        setModified(false);

        const currentAiModel = await getAiModel(aiModelId);

        setAiModel(currentAiModel);
        setSelectedModel(currentAiModel);

        setSelectedBedrockModel(currentAiModel.bedrock_model || bedrockModels.find((m) => m.default)?.value);

        form.setFieldsValue({
          id: currentAiModel.id,
          name: currentAiModel.name,
          assigned_permission_id: currentAiModel.assigned_permission_id,
          bedrock_model: currentAiModel.bedrock_model || bedrockModels.find((m) => m.default)?.value,
          created_at: currentAiModel.created_at,
          description: currentAiModel.description,
          prompt_instructions: currentAiModel.prompt_instructions,
          skip_similarity_search: currentAiModel.skip_similarity_search,
          tenant_id: currentAiModel.tenant_id,
          updated_at: currentAiModel.updated_at,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleOnChange = (values) => {
    setModified(true);
  };

  const handleOnFinish = (values) => {
    console.log('[AiModelEditor] Success:', values);
    executeAsyncProcess(async () => {
      try {
        let resp = null;
        if (aiModel) {

          resp = await patchAiModel(aiModelId, { name: values.name, description: values.description, bedrock_model: values.bedrock_model, prompt_instructions: values.prompt_instructions, skip_similarity_search: values.skip_similarity_search });
        } else {
          resp = await postAiModel(values);
          addNewCreatedAiModel(resp);
        }
        if (onSave) onSave(resp);
        setModified(false);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Data has been saved',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('[AiModelEditor] Failed:', errorInfo);
    messageApi.open({
      key: uuid(),
      type: 'error',
      content: 'Provided data is invalid',
      duration: 3,
    });
  };

  const handleOnReset = () => {
    setModified(false);
    if (aiModel) {
      form.setFieldsValue(aiModel);
    } else {
      form.resetFields();
    }
  };

  const handlePermanentlyDelete = () => {
    console.log('[AiModelEditor] handlePernamentlyDelete');
    executeAsyncProcess(async () => {
      try {
        await deleteAiModelApi(aiModel.id);
        deleteAiModel(aiModel.id);
        if (onDelete) onDelete(aiModel.id);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'AI model has been deleted',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleButtonAddConnector = () => {
    setConnectorsModalOpen(true);
  };

  const handleAddConnector = async (data) => {
    try {
      //const updatedAiModel = await addConnectorToAiModel(aiModelId, values);
      //console.log('[AiModelEditor] Updated aiModel:', updatedAiModel);

      console.log('[AiModelEditor] handleAddConnector:', data);
      await loadData(); // hard refresh

      setConnectorsModalOpen(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to add new connector',
        duration: 3,
      });
    }
  };

  if (!aiModel)
    return (
      <div className='h-full flex flex-row justify-center items-center'>
        <div>No data</div>
      </div>
    );

  const handleReCreatePermission = () => {
    console.log('[AiModelEditor] handleReCreatePermission');
    executeAsyncProcess(async () => {
      try {
        await postPermission({
          permission: `apps.copilot.model.${aiModel.id}`,
          description: `AI Assistant AI Model [${aiModel.name}]`,
        });

        await patchAiModel(aiModel.id, { assigned_permission_id: `apps.copilot.model.${aiModel.id}` });

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'AI model has been updated',
          duration: 3,
        });
        loadData();
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleOnBedrockModelSelect = (value) => {
    console.log('Bedrock model selected:', value);

    setSelectedBedrockModel(value);
    form.setFieldsValue({ bedrock_model: value });
    setModified(true); // Mark form as modified when model changes

    // Show model description
    const selectedModel = bedrockModels.find(model => model.value === value);
    if (selectedModel) {
      setBedrockModelDescription(selectedModel.description);
    }
  };

  return (
    <>
      {contextHolder}
      <TitleBar
        busyIndicator={false}
        colorBackground={theme.backgroundBase}
        // title={
        //   <Space className='btn'>
        //     <Space className='btn'>
        //       <Skeleton.Input active={true} size={28} />
        //       <BsChevronRight />
        //       <Skeleton.Input active={true} size={28} />
        //       <BsChevronRight />
        //       <Skeleton.Input active={true} size={28} />
        //     </Space>
        //   </Space>
        // }
        title={aiModel.name}
        afterTitleExtras={<></>}
      >
        <Popover
          content={
            <Space direction='vertical'>
              <YesNoDialog
                title='Permanently delete'
                body={
                  <>
                    Do you want to delete <strong>{aiModel.name}</strong>, with all defined connectors ?
                  </>
                }
                onYesClick={handlePermanentlyDelete}
                iconYes={<MdDelete />}
                showRed={true}
                labelYes='Yes, delete AI model'
                labelNo='Cancel'
              >
                <Tooltip placement='bottom' title={`${selectedModel?.connectors_config.length > 0 ? 'To delete Model, delete first all related connectors.' : ''}`} arrow={true} color='black'>
                  <Button className='w-[200px]' color={theme.textBase} disabled={selectedModel?.id === 'default' || selectedModel?.connectors_config.length > 0}>
                    Permanently delete
                  </Button>
                </Tooltip>
              </YesNoDialog>
            </Space>
          }
          title='Actions'
          trigger='click'
          placement='bottomRight'
        >
          <Button type='text' icon={<LuMenu />} />
        </Popover>
      </TitleBar>
      <div className='p-4'>
        <Tabs
          defaultActiveKey='1'
          items={[
            {
              key: '1',
              label: (
                <Space className='btn'>
                  <FaRobot />
                  AI Model
                </Space>
              ),
              children: (
                <div>
                  <Form form={form} name='aiModelEditor' style={{ maxWidth: 600 }} layout='vertical' initialValues={aiModel} onChange={handleOnChange} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} onReset={handleOnReset} autoComplete='off'>
                    <Form.Item label='Id' name='id'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='Name' name='name' rules={[{ required: true, message: 'Name is required' }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label='Description' name='description' rules={[{ required: true, message: 'Description is required' }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label='Bedrock Model' name='bedrock_model' rules={[{ required: true, message: 'Model is required' }]}>
                      <Select options={bedrockModels} placeholder='Select a Bedrock model...' value={selectedBedrockModel} onSelect={handleOnBedrockModelSelect} autoComplete='off' allowClear={false} />
                    </Form.Item>
                    {bedrockModelDescription && (
                      <div className='mt-2 text-sm text-gray-500'>{bedrockModelDescription}</div>
                    )}
                    <Form.Item label='Prompt instructions' name='prompt_instructions'>
                      <TextArea className='mt-1' rows={6} placeholder='You are an expert in this and that area. Provide short answers. Ask if you are not understand the question.' />
                    </Form.Item>
                    <Form.Item name='skip_similarity_search' valuePropName='checked'>
                      <Checkbox>Skip similarity search</Checkbox>
                    </Form.Item>
                    <Form.Item label='Created at' name='created_at'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='Updated at' name='updated_at'>
                      <Input disabled />
                    </Form.Item>
                    <span className=''>Assigned permission</span>
                    <span className='flex flex-auto w-full pb-8'>
                      <Input value={aiModel.assigned_permission_id} disabled />
                      <Button danger primary disabled={aiModel.assigned_permission_id} className='ml-4' onClick={handleReCreatePermission}>
                        Re-create
                      </Button>
                    </span>

                    <Form.Item>
                      <Space>
                        <Button htmlType='reset' disabled={!modified}>
                          Reset
                        </Button>
                        <Button type='primary' htmlType='submit' disabled={!modified}>
                          Submit
                        </Button>
                      </Space>
                    </Form.Item>
                  </Form>
                </div>
              ),
            },
            {
              key: '2',
              label: (
                <Space className='btn'>
                  <FaRobot />
                  Connectors
                </Space>
              ),
              children: (
                <>
                  <div>
                    <Button onClick={handleButtonAddConnector}>
                      <Space className='btn'>
                        <FaPlus />
                        Add Connector
                      </Space>
                    </Button>
                    <div className='flex flex-row gap-4 p-4 flex-wrap max-w-2xl content-center justify-center'>
                      {selectedModel?.connectors_config?.map((connector, index) => {
                        return (
                          <div key={index}>
                            <AiModelConnectorListCard aiModelId={aiModel.id} connector={connector} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {connectorsModalOpen && <AiModelConnectorsModal aiModelId={aiModelId} open={connectorsModalOpen} onSubmit={handleAddConnector} onClose={() => setConnectorsModalOpen(false)} />}
                </>
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

AiModelEditor.propTypes = {
  aiModelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AiModelEditor;
