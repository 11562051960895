import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getAccountDeployments } from 'src/api/snowflake';
import { Spin, Select } from 'antd';

const DeploymentPicker = ({ accId, defaultValue, onSelect }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const [deploymentOptions, setDeploymentOptions] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () =>
    await executeAsyncProcess(async () => {
      try {
        const deployments = await getAccountDeployments(accId);
        setDeploymentOptions(deployments?.map((x) => ({ value: x.ORG_ID, label: x.ORG_NAME__C })) ?? []);
      } catch (error) {
        console.error(error);
      }
    });

  const optionRender = (option) => {
    return (
      <div>
        <div className='overflow-hidden'>{option.label}</div>
        <div className='text-xs font-light opacity-60 overflow-hidden'>{option.value}</div>
      </div>
    );
  };

  const labelRender = (option) => {
    return (
      <div className='overflow-hidden'>
        {option.label} <span className='text-xs font-light opacity-40'>| {option.value}</span>
      </div>
    );
  };

  const handleOnSelect = (value) => {
    const selected = deploymentOptions.find((x) => x.value === value);
    if (onSelect) onSelect(selected);
  };

  if (deploymentOptions === null)
    return (
      <div className='p-4 flex flex-row justify-center items-center'>
        <Spin />
      </div>
    );

  return (
    <div>
      <Select placeholder='select from list...' style={{ width: '100%' }} defaultValue={defaultValue} onSelect={handleOnSelect} options={deploymentOptions} labelRender={labelRender} optionRender={optionRender} />
    </div>
  );
};

DeploymentPicker.propTypes = {
  accId: PropTypes.string,
  defaultValue: PropTypes.string,
  onSelect: PropTypes.func,
};

export default DeploymentPicker;
