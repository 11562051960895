import React from 'react';
import PropTypes from 'prop-types';
import { Space, Tree } from 'antd';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import { uuid } from 'short-uuid';

const UserResultantPermissions = ({ resultantPermissions }) => {
  const getTreeData = () => {
    if (true === notArrayOrEmpty(resultantPermissions)) return [];
    const result = [];
    for (const rp of resultantPermissions) {
      const item = {
        key: uuid(),
        title: (
          <Space>
            <div className='font-semibold'>{rp.permission}</div>
            <div className='font-extralight opacity-60 text-xs'>permission</div>
          </Space>
        ),
        children: [],
      };
      if (true === notEmptyArray(rp.assignments)) {
        for (const a of rp.assignments) {
          item.children.push({
            key: uuid(),
            title: (
              <Space>
                <div className='font-semibold'>{a.role}</div>
                <div className='font-extralight opacity-60 text-xs'>role</div>
              </Space>
            ),
            children: Object.keys(a)
              ?.filter((k) => null !== a[k])
              ?.map((k) => {
                return {
                  key: uuid(),
                  title: (
                    <Space>
                      <div className='font-extralight opacity-80'>{k}:</div>
                      <div className='font-semibold'>{Array.isArray(a[k]) ? a[k].join(', ') : a[k]}</div>
                    </Space>
                  ),
                };
              }),
          });
        }
      }

      result.push(item);
    }

    return result;
  };

  return (
    <>
      <Tree treeData={getTreeData()} />
    </>
  );
};

UserResultantPermissions.propTypes = {
  resultantPermissions: PropTypes.array,
};

export default UserResultantPermissions;
