import { useContext, useEffect, useState } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import AuditViewerContext from 'src/context/AuditViewerContext';
import { getEventsFilters, putEventsFilter } from 'src/api/auditviewer';
import useUserInfo from 'src/hooks/useUserInfo';
import PropTypes from 'prop-types';
import { getSnsTopic, getSnsSubscriptions } from 'src/api/subscriptions';
import { AV_APP_ID } from '../../Misc/misc';

const FilterLoadSave = ({ children }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { setTopic, setSubscriptions, currentUserFilterConfig, setFilterConfig, hasCurrentUserSubscribed, setHasCurrentUserSubscribed } = useContext(AuditViewerContext);
  const userInfo = useUserInfo();

  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    if (null === currentUserFilterConfig) {
      loadData();
      return;
    }
    if (!initiated) {
      setInitiated(true);
      return;
    }
    saveData();
  }, [currentUserFilterConfig]);

  useEffect(() => {
    console.log('hasCurrentUserSubscribed', hasCurrentUserSubscribed);
    if (null === hasCurrentUserSubscribed) {
      loadSubscribtions();
      return;
    }
  }, [hasCurrentUserSubscribed]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getEventsFilters('email', userInfo.email);
        console.log('resp', resp);
        setFilterConfig(resp.filter_config);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const loadSubscribtions = async () => {
    await executeAsyncProcess(async () => {
      try {
        // <get topic for app id and tenant id>
        let t = await getTopicAndHandle404();
        if (t) {
          setTopic(t);
        } else {
          console.warn('No topic found for app id:', AV_APP_ID);
          return;
        }
        // </get topic for app id and tenant id>

        // <get subscriptions>
        const s = await getSnsSubscriptions(AV_APP_ID);
        if (!Array.isArray(s)) return;
        setSubscriptions(s);
        // </get subscriptions>

        // <check if user is subscribed>
        setHasCurrentUserSubscribed(true === s.some((x) => x.Protocol === 'email' && x.Endpoint?.toLowerCase() === userInfo.email?.toLowerCase()));
        // </check if user is subscribed>
      } catch (error) {
        console.log(error);
      }
    });
  };

  const getTopicAndHandle404 = async () => {
    try {
      const result = await getSnsTopic(AV_APP_ID);
      return result;
    } catch (error) {
      console.log(error);
      if (error?.toString().includes('404')) return null;
      throw error;
    }
  };

  const saveData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await putEventsFilter({ protocol: 'email', endpoint: userInfo.email, filter_config: currentUserFilterConfig });
        console.log('resp', resp);
      } catch (error) {
        console.log(error);
      }
    });
  };

  return children;
};

FilterLoadSave.propTypes = {
  children: PropTypes.node,
};

export default FilterLoadSave;
