import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useTheme from 'src/hooks/useTheme';
import PropTypes from 'prop-types';

const MuiTheme = ({ children }) => {
  const { isDarkMode } = useTheme();
  return <ThemeProvider theme={createTheme({ palette: { mode: isDarkMode ? 'dark' : 'light' } })}>{children}</ThemeProvider>;
};

MuiTheme.propTypes = {
  children: PropTypes.node,
};

export default MuiTheme;
