import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { getEbIntegration } from 'src/api/auditviewer';
import AuditViewerContext from 'src/context/AuditViewerContext';
import { Spin } from 'antd';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import EventsSetupWizard from './EventsSetupWizard';

const EventsSetup = ({ children, allowToInstall }) => {
  const { ebIntegrationInstalled, setEbIntegrationInstalled } = useContext(AuditViewerContext);
  const { executeAsyncProcess } = useAsyncProcesses();

  useEffect(() => {
    if (true === ebIntegrationInstalled) return;
    processSetup();
  }, []);

  const processSetup = async () => {
    console.log('processSetup()');
    await executeAsyncProcess(async () => {
      try {
        const { eventBridgeIntegration: installed } = (await getEbIntegration()) ?? {};
        setEbIntegrationInstalled(installed);
        if (installed) return;
        // if (allowToInstall) {
        //   await postEbIntegration();
        //   setEbIntegrationInstalled(true);
        //   notification.success({
        //     message: 'Success',
        //     description: <>Event Bridge integration has been configured</>,
        //     duration: 5,
        //   });
        // }
      } catch (error) {
        console.log(error);
        setEbIntegrationInstalled(false);
      }
    });
  };

  if (ebIntegrationInstalled === true) {
    // return children if integration is installed
    return <>{children}</>;
  } else if (ebIntegrationInstalled === false && !allowToInstall) {
    // return not configured message if integration is not installed and not allowed to install
    return <div className='flex flex-row justify-center items-center h-32 opacity-60'>Not configured yet</div>;
  } else if (ebIntegrationInstalled === false && allowToInstall) {
    // return wizard if integration is not installed and allowed to install
    return <EventsSetupWizard />;
  } else {
    // return spinner if integration status is unknown
    return (
      <div className='flex flex-row justify-center items-center h-32'>
        <Spin />
      </div>
    );
  }
};

EventsSetup.propTypes = {
  children: PropTypes.node,
  allowToInstall: PropTypes.bool,
};

EventsSetup.defaultProps = {
  allowToInstall: false,
};

export default EventsSetup;
