import { useContext } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import { themeLight, themeDark } from 'src/misc/Theme';

const useTheme = () => {
  const { isDarkMode, toggleDarkMode } = useContext(GlobalContext);
  const theme = isDarkMode ? themeDark : themeLight;
  return { theme, themeLight, themeDark, isDarkMode, toggleDarkMode };
};

export default useTheme;
