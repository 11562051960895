import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
//import useUserInfo from 'src/hooks/useUserInfo';
// import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropertyItem from 'src/components/layout/PropertyItem';
import { notArrayOrEmpty } from 'src/misc/Misc';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const TicketDates = () => {
  const { currentTicketHistory, currentTicket } = useContext(TeacketContext);

  const [latestHistoryItem, setLatestHistoryItem] = useState(null);

  useEffect(() => {
    if (true === notArrayOrEmpty(currentTicketHistory)) return;

    const { timestamp } = currentTicketHistory[0] ?? {};
    if (!timestamp) return;
    setLatestHistoryItem({ value: dayjs(timestamp).fromNow(), secondValue: dayjs(timestamp).format('LLL') });
  }, [currentTicketHistory]);

  return (
    <div className='flex flex-col gap-2 mt-4'>
      <PropertyItem label='Created' value={currentTicket?.created_at ? dayjs(currentTicket?.created_at).fromNow() : null} secondValue={currentTicket?.created_at ? dayjs(currentTicket?.created_at).format('LLL') : null} />
      <PropertyItem label='Latest activity' value={latestHistoryItem?.value ?? null} secondValue={latestHistoryItem?.secondValue ?? null} />
      <PropertyItem label='Closed' value={currentTicket?.closed_at ? dayjs(currentTicket?.closed_at).fromNow() : null} />
    </div>
  );
};

TicketDates.propTypes = {};

export default TicketDates;
