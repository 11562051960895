import React from 'react';
import PropTypes from 'prop-types';

const ColumnsHeader = ({ title, afterTitleExtras, extras }) => {
  return (
    <div className='flex flex-row gap-2'>
      <div className='flex flex-row gap-1 justify-start items-center'>{title}</div>
      {afterTitleExtras && <div className='flex flex-row gap-1 justify-start items-center'>{afterTitleExtras}</div>}
      {extras && <div className='flex-auto flex flex-row gap-1 justify-end items-center'>{extras}</div>}
    </div>
  );
};

ColumnsHeader.propTypes = {
  title: PropTypes.any,
  afterTitleExtras: PropTypes.any,
  extras: PropTypes.any,
};

export default ColumnsHeader;
