import React, { useRef, useEffect, useState } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useTheme from 'src/hooks/useTheme';
import { getMyTicketsGraph } from 'src/api/teacket';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const GraphMyTicketsActive = () => {
  const { isDarkMode } = useTheme();
  const [graphDataStatus, setGraphDataStatus] = useState(undefined);

  const refContainer = useRef();

  const themeMode = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  const loadData = async () => {
    try {
      const resp = await getMyTicketsGraph('grouped-by-status');
      setGraphDataStatus(resp);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!graphDataStatus) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={themeMode}>
      <div ref={refContainer}>
        {!graphDataStatus ? (
          <div className='text-center mt-10'>
            <Spin />
          </div>
        ) : (
          <>
            <div className='flex flex-row justify-center'>
              <PieChart
                title='ByStatus'
                colors={['#76b7b2', 'rgb(251 113 133)', '#f28e2c', '#59a14f', '#edc949', '#af7aa1', '#ff9da7', '#9c755f', '#bab0ab', '#adadad']}
                series={[
                  {
                    arcLabel: (item) => `${item.value}`,
                    data: graphDataStatus || [],
                    innerRadius: 20,
                    outerRadius: 70,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    cy: 100,
                    cx: 100,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold',
                  },
                }}
                width={400}  // Increase the width of the pie chart container
                height={250} // Adjust height to give more space
                slotProps={{
                  legend: {
                    hidden: false,
                    position: { vertical: 'middle', horizontal: 'right' },
                    layout: 'vertical', // Vertically stack items
                    maxWidth: 100,       // Limit the width of the legend to prevent overlap
                    itemGap: 10,         // Increase space between legend items                  
                  },
                }}
              />
            </div>
            <div className='text-xs font-thin text-center whitespace-break-spaces'>List of not closed Tickets where I'm member of.</div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

export default GraphMyTicketsActive;