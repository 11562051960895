import React from 'react';
import Header from 'src/components/pages/Landing/components/ui/Header';
import { MdSecurity } from 'react-icons/md';
import { Space } from 'antd';

const GreenTeacket = () => {
  return (
    <>
      <div className='fixed z-50'>
        <Header />
      </div>
      <div className='absolute flex flex-col items-center w-full'>
        <div className='mt-24 max-w-7xl '>
          <div className='my-8 font-bold text-2xl'>
            <Space>
              <MdSecurity />
              Green Teacket
            </Space>
          </div>
          <div>
            <a href='#presentation' target='_self' className='decoration-solid hover:decoration-slice'>
              Presentation
            </a>
          </div>
          <div>
            <a href='#features' target='_self' className='decoration-solid hover:decoration-slice'>
              Features
            </a>
          </div>
          <div>
            <a href='#supervisorfeatures' target='_self' className='decoration-solid hover:decoration-slice'>
              Supervisor Features
            </a>
          </div>
          <div>
            <a href='#gettingstarted' target='_self' className='decoration-solid hover:decoration-slice'>
              Getting Started
            </a>
          </div>
          <div id='presentation' className='my-8'>
            GreenTeacket is an innovative ticketing service available on the SPC portal, designed to enhance customer support and streamline interactions for specific EU customers and internal groups.
          </div>
          <div className='justify-center flex'>
            <img src='/images/products/GreenTeacket/GreenTeacket_ticket.png' alt='Green Teacket ticket' className='object-contain max-w-full h-auto drop-shadow-md m-auto shadow-2xl dark:shadow-genesys-gray-400 rounded-lg' />
          </div>
          <div className='my-8'>By streamlining communication between customers and support teams, GreenTeacket helps achieve quicker resolution times and improved customer satisfaction.</div>
          <div>Supports the COE Security, Privacy & Compliance group in upholding rigorous data protection and privacy standards.</div>
          <div>The customizable nature of GreenTeacket allows it to adapt to the needs of various groups, providing a scalable solution for ticket management.</div>
          <div id='features' className='my-8 font-bold text-2xl'>
            <Space>Features</Space>
          </div>
          <div className='my-8'>
            Access Green Teacket requires specific permissions when accessing it for the first time.
            <ul className='list-disc list-inside'>
              <li>
                UI personalization: <i>Customize the UI to suit your preferences by moving widgets around and switching between light and dark mode</i>
              </li>
              <li>
                Message Conversation: <i>Feels like a chat conversation</i>
              </li>
              <li>
                Internal/Public Messages <i>Internal messages are only visible to the support team</i>
              </li>
              <li>
                Integration with Salesforce: <i>Integrate with Salesforce to access customer data</i>
              </li>
              <li>
                Custom fields: <i>Customize fields to suit your needs</i>
              </li>
              <li>
                Large File Attachments: <i>Attach large files to tickets</i>
              </li>
              <li>
                Full History: <i>Access full ticket history</i>
              </li>
            </ul>
          </div>
          <div id='supervisor' className='my-8 font-bold text-2xl'>
            <Space>Supervisor Features</Space>
          </div>
          <div className='my-8'>
            Green Teacket offers a range of features for supervisors to manage tickets effectively.
            <ul className='list-disc list-inside'>
              <li>
                Ticket List: <i>View all tickets in a list format</i>
                <div className='justify-center flex m-6'>
                  <img src='/images/products/GreenTeacket/GreenTeacket_ticketlist.png' alt='Green Teacket ticket list' className='object-contain max-w-full h-auto drop-shadow-md m-auto shadow-2xl dark:shadow-genesys-gray-400 rounded-lg' />
                </div>
              </li>
              <li>
                Dashboards: <i>Access dashboards to view ticket statistics</i>
                <div className='justify-center flex m-6'>
                  <img src='/images/products/GreenTeacket/GreenTeacket_dashboard.png' alt='Green Teacket dashboard' className='object-contain max-w-full h-auto drop-shadow-md m-auto shadow-2xl dark:shadow-genesys-gray-400 rounded-lg' />
                </div>
              </li>
            </ul>
          </div>
          <div id='gettingstarted' className='my-8 font-bold text-2xl'>
            <Space>Getting Started</Space>
          </div>
          To create a ticket, click on the 'Create new' button on the Green Teacket homepage. Fill in the required fields and click 'Submit' to create a ticket.
          <div className='justify-center flex m-6'>
            <img src='/images/products/GreenTeacket/GreenTeacket_newticket.png' alt='Green Teacket ticket list' className='object-contain max-w-full h-auto drop-shadow-md m-auto shadow-2xl dark:shadow-genesys-gray-400 rounded-lg' />
          </div>
          <div className='my-8'>Open a ticket by clicking on the ticket id</div>
          <div className='justify-center flex m-6'>
            <img src='/images/products/GreenTeacket/GreenTeacket_openticket.png' alt='Green Teacket open ticket' className='object-contain max-w-full h-auto drop-shadow-md m-auto shadow-2xl dark:shadow-genesys-gray-400 rounded-lg' />
          </div>
          <div className='my-8'>Add a new message using the "new message" input text box and click on Submit</div>
          <div className='justify-center flex m-6'>
            <img src='/images/products/GreenTeacket/GreenTeacket_addnewmessage.png' alt='Green Teacket add new message' className='object-contain max-w-full h-auto drop-shadow-md m-auto shadow-2xl dark:shadow-genesys-gray-400 rounded-lg' />
          </div>
          <div className='my-8'>Update the status using the "Status & Priority" widget.</div>
          <div className='justify-center flex m-6'>
            <img src='/images/products/GreenTeacket/GreenTeacket_statuspriority.png' alt='Green Teacket status and priority' className='object-contain max-w-full h-auto drop-shadow-md m-auto shadow-2xl dark:shadow-genesys-gray-400 rounded-lg' />
          </div>
        </div>
      </div>
    </>
  );
};

// Copilot.propTypes = {};

export default GreenTeacket;
