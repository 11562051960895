'use client';

import React, { useState, useRef, useEffect } from 'react';
import { Transition } from '@headlessui/react';
// import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import CorporateColors from 'src/misc/CorporateColors';

export default function MobileMenu() {
  // const navigate = useNavigate();

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className='flex md:hidden'>
      {/* Hamburger button */}
      <button ref={trigger} className={`hamburger ${mobileNavOpen && 'active'}`} aria-controls='mobile-nav' aria-expanded={mobileNavOpen} onClick={() => setMobileNavOpen(!mobileNavOpen)}>
        <span className='sr-only'>Menu</span>
        <svg className='w-6 h-6 fill-current text-gray-900' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <rect y='4' width='24' height='2' />
          <rect y='11' width='24' height='2' />
          <rect y='18' width='24' height='2' />
        </svg>
      </button>

      {/*Mobile navigation */}
      <div ref={mobileNav}>
        <Transition show={mobileNavOpen} as='nav' id='mobile-nav' className='absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white' enter='transition ease-out duration-200 transform' enterFrom='opacity-0 -translate-y-2' enterTo='opacity-100 translate-y-0' leave='transition ease-out duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <div className='flex flex-col justify-center gap-2 p-4'>
            <Button
              style={{ backgroundColor: CorporateColors.orange, borderColor: CorporateColors.orange }}
              type='primary'
              size='large'
              shape='round'
              className=' hover:scale-105 transition-transform duration-300 ease-in-out group'
              onClick={() => {
                //navigate('/signin');
                window.location.href = '/home';
              }}
            >
              Sign in
              <ArrowRightOutlined />
            </Button>
          </div>
        </Transition>
      </div>
    </div>
  );
}
