import React, { useEffect, useState, useContext } from 'react';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useTheme from 'src/hooks/useTheme';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import TitleBar from 'src/components/layout/TitleBar';
import { LuBuilding } from 'react-icons/lu';
import { Space, Switch, message } from 'antd';
import { getAiModels, postAiModel } from 'src/api/aiModels';
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import { FaPlus } from 'react-icons/fa6';
import StyledButton from 'src/components/layout/StyledButton';
import AiModelCreate from './Components/AiModelCreate';
import AIModelsContext from 'src/context/AIModelsContext';
import AiModelSelector from './Components/AiModelSelector';
import AiModelEditor from './Components/AiModelEditor';
import { uuid } from 'short-uuid';

//import PropTypes from 'prop-types';

const MasterAdminAiModels = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();
  const { updateAiModel, addNewCreatedAiModel, setAiModels, deleteAiModel, setSelectedModelById } = useContext(AIModelsContext);
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedAiModelId, setSelectedAiModelId] = useState(null);
  const [newAiModelOpen, setNewAiModelOpen] = useState(false);
  const [layoutHorizontal, setLayoutHorizontal] = useState(true);

  useEffect(() => {
    loadData();

    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'AI Models',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    console.log('[AiModels] selectedAiModelId', selectedAiModelId);
    setSelectedModelById(selectedAiModelId);
  }, [selectedAiModelId]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getAiModels();
        setAiModels(resp);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleAiModelCreateOnSubmit = (values) => {
    console.log('[AiModels] handleAiModelCreateOnSubmit', values);
    setNewAiModelOpen(false);
    executeAsyncProcess(async () => {
      try {
        const resp = await postAiModel(values);

        addNewCreatedAiModel(resp);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Data has been saved',
          duration: 3,
        });
        setSelectedAiModelId(resp.id);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleAiModelEditorOnSave = (aiModel) => {
    console.log('[AiModels] handleAiModelEditorOnSave', aiModel);
    const { name: aiModel_name, disabled: aiModel_disabled, description: aiModel_description, bedrockModel: bedrock_model } = aiModel;
    updateAiModel(aiModel.id, { name: aiModel_name, description: aiModel_description, disabled: aiModel_disabled, bedrock_model: bedrock_model });
  };

  const handleAiModelEditorOnDelete = (id) => {
    console.log('[AiModels] handleAiModelEditorOnDelete', id);
    deleteAiModel(id);
    setSelectedAiModelId(null);
  };

  return (
    <PermissionsProvider requiredPermissions={['admin.master']}>
      {contextHolder}
      <AiModelCreate open={newAiModelOpen} onClose={() => setNewAiModelOpen(false)} onSubmit={handleAiModelCreateOnSubmit} />
      <TitleBar
        title={
          <Space className='btn'>
            <LuBuilding />
            AI Models
          </Space>
        }
        afterTitleExtras={
          <StyledButton color={theme.textBase} onClick={() => setNewAiModelOpen(true)}>
            <Space className='btn'>
              <FaPlus />
              Create new
            </Space>
          </StyledButton>
        }>
        <Space>
          <Switch
            unCheckedChildren={<>vertical</>}
            checkedChildren={<>horizontal</>}
            checked={layoutHorizontal}
            onChange={() => {
              setLayoutHorizontal(!layoutHorizontal);
            }}
          />
        </Space>
      </TitleBar>
      <div className='p-4 h-full max-h-full w-full overflow-hidden'>
        <Splitter direction={layoutHorizontal ? SplitDirection.Horizontal : SplitDirection.Vertical}>
          <div className={'overflow-hidden h-full max-h-full w-full  p-4'} style={{ backgroundColor: theme.backgroundBase }}>
            <AiModelSelector
              selected={selectedAiModelId}
              onAiModelSearch={(e) => {
                setSelectedAiModelId(null);
              }}
              onAiModelSelected={(e) => {
                setSelectedAiModelId(e);
              }}
            />
          </div>
          <div className={'overflow-scroll h-full max-h-full w-full'} style={{ backgroundColor: theme.backgroundBase }}>
            <AiModelEditor aiModelId={selectedAiModelId} onSave={handleAiModelEditorOnSave} onDelete={handleAiModelEditorOnDelete} />
          </div>
        </Splitter>
      </div>{' '}
    </PermissionsProvider>
  );
};

// MasterAdminTenants.propTypes = {};

export default MasterAdminAiModels;
