import React, { useEffect, useContext, useState } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import AuditViewerContext from 'src/context/AuditViewerContext';
import { notArrayOrEmpty } from 'src/misc/Misc';
import { getEventFilterPresets } from 'src/api/auditviewer';
import { Button, Popover, Skeleton, message } from 'antd';
import { FaChevronDown } from 'react-icons/fa';
import Space2 from 'src/components/layout/Space2';
// import PropTypes from 'prop-types';

const EventFilterPresets = () => {
  const { currentUserFilterConfig, eventsFilterPresetList, setEventsFilterPresetList, addManyCurrentUserFilterList } = useContext(AuditViewerContext);
  const { executeAsyncProcess } = useAsyncProcesses();

  const [popoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    if (true === notArrayOrEmpty(eventsFilterPresetList)) loadData();
  }, []);

  const loadData = async () =>
    await executeAsyncProcess(async () => {
      try {
        const response = await getEventFilterPresets();
        setEventsFilterPresetList(response ?? []);
      } catch (error) {
        console.error(error);
      }
    });

  const handleApplyPreset = (preset) => {
    setPopoverOpen(false);
    console.log('preset', preset.filterConfig, currentUserFilterConfig);
    const { filterConfig } = preset;
    if (true === notArrayOrEmpty(filterConfig)) return;

    let report = {
      added: 0,
      skipped: 0,
    };

    let filterItemsToBeAdded = [];

    for (const filterItem of filterConfig) {
      const { serviceName, entityType, action } = filterItem;

      const exists = currentUserFilterConfig?.some((x) => x.serviceName === serviceName && x.entityType === entityType && x.action === action);
      if (true === exists) {
        report.skipped++;
        continue;
      }
      filterItemsToBeAdded.push(filterItem);
      report.added++;
    }

    addManyCurrentUserFilterList(filterItemsToBeAdded);

    message.success(
      <>
        Filters added: {report.added}
        <br />
        Filters skipped: {report.skipped}
      </>
    );
  };

  if (true === notArrayOrEmpty(eventsFilterPresetList)) return <Skeleton.Button active size='small' />;

  return (
    <Popover
      placement='bottomRight'
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
      trigger={['click']}
      title='Add filters from presets'
      content={
        <div className='flex flex-col gap-2'>
          {eventsFilterPresetList.map((preset, index) => (
            <Button key={index} size='small' onClick={() => handleApplyPreset(preset)}>
              <Space2>
                {preset.name}
                <span className='font-light text-[0.6rem] opacity-60'>
                  ({preset.filterConfig.length} filter{preset.filterConfig.length > 1 ? 's' : ''})
                </span>
              </Space2>
            </Button>
          ))}
        </div>
      }
    >
      <Button size='small'>
        <Space2>
          Presets
          <FaChevronDown />
        </Space2>
      </Button>
    </Popover>
  );
};

EventFilterPresets.propTypes = {};

export default EventFilterPresets;
