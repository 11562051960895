import React, { useState, useEffect } from 'react';
import { Button, Space, Form, Input, notification, Select, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { uuid } from 'short-uuid';
import { getQuestion } from 'src/api/fsireport';

const { Option } = Select;
const { TextArea } = Input;

const QuestionForm = ({ edit, questionId, onSubmit }) => {
  const [form] = Form.useForm();

  const [questions, setQuestions] = useState([]);
  const [questionType, setQuestionType] = useState('yes/no');

  useEffect(() => {
    form.resetFields();
    if (questionId) {
      loadData();
    }
  }, [form, questionId]);

  const loadData = async () => {
    const resp = await getQuestion(questionId);
    setQuestions(resp.questions || []);
    form.setFieldsValue(resp);
  };

  const handleAddQuestion = () => {
    const currentQuestions = form.getFieldValue('questions') || [];
    const newQuestion = { id: uuid(), language: 'en', title: '', question: '', default_answer: 'no', default_answer_text: '' };
    form.setFieldsValue({
      questions: [...currentQuestions, newQuestion],
    });
    setQuestions([...currentQuestions, newQuestion]);
  };

  const handleClear = async () => {
    console.log('handleClear');
  };

  const handleOnFinish = (values) => {
    console.log('Success:', values);
    if (onSubmit) onSubmit(values);
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: 'Error',
      description: <>Provided data is invalid</>,
      duration: 5,
    });
  };

  const handleTypeQuestionChange = (value) => {
    setQuestionType(value);
  };

  const getNoText = (language) => {
    switch (language) {
      case 'en':
        return 'No';
      case 'de':
        return 'Nein';
      case 'fr':
        return 'Non';
      default:
        console.error('Unknown language:', language);
        return 'No';
    }
  };
  const getYesText = (language) => {
    switch (language) {
      case 'en':
        return 'Yes';
      case 'de':
        return 'Ja';
      case 'fr':
        return 'Oui';
      default:
        console.error('Unknown language:', language);
        return 'Yes';
    }
  };

  return (
    <Form
      form={form}
      name='questionForm'
      style={{ maxWidth: 600 }}
      layout='vertical'
      initialValues={
        edit
          ? null
          : {
              type: 'yes/no',
              questions: [],
            }
      }
      onFinish={handleOnFinish}
      onFinishFailed={handleOnFinishFailed}
      autoComplete='off'
    >
      <Form.Item label='Type' name='type' rules={[{ required: true, message: 'Select a question type' }]}>
        <Select allowClear filterOption={false} onChange={handleTypeQuestionChange} onClear={handleClear} style={{ width: '100%' }} autoComplete='off'>
          <Option key='deprecations'>Deprecations</Option>
          <Option key='incidents'>Incidents</Option>
          <Option key='yes/no'>Yes/No</Option>
        </Select>
      </Form.Item>
      <Form.List name='questions'>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <div key={field.key} className='p-2 mb-4 border border-dashed border-gray-500'>
                <Form.Item required={false}>
                  <div className='flex flex-row justify-between'>
                    <Form.Item {...field} label='Language' name={[field.name, 'language']} rules={[{ required: true, message: 'Select a language' }]}>
                      <Select filterOption={false} onClear={handleClear} style={{ width: '100%' }} autoComplete='off'>
                        <Option key='en'>English</Option>
                        <Option key='fr'>French</Option>
                        <Option key='de'>German</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item {...field} className='w-7/12' label='Title' name={[field.name, 'title']} rules={[{ required: true, message: 'Enter a title' }]}>
                      <Input placeholder='Enter a title' autoComplete='off' />
                    </Form.Item>
                    <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.questions?.[field.name]?.language !== curValues.questions?.[field.name]?.language}>
                      {() => {
                        const language = form.getFieldValue(['questions', field.name, 'language']);
                        return (
                          <Form.Item {...field} label='Response' name={[field.name, 'default_answer']} rules={[{ required: questionType === 'yes/no', message: 'Select the default answer (if applicable)' }]}>
                            <Select allowClear filterOption={false} style={{ width: '100%' }} autoComplete='off'>
                              <Option key='no'>{getNoText(language)}</Option>
                              <Option key='yes'>{getYesText(language)}</Option>
                            </Select>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </div>
                  <Form.Item {...field} label='Question' name={[field.name, 'question']} rules={[{ required: true, message: 'Enter a question' }]}>
                    <TextArea placeholder='Enter a question' autoComplete='off' />
                  </Form.Item>
                  <Form.Item {...field} label='Default Answer (Text)' name={[field.name, 'default_answer_text']} rules={[{ required: false, message: 'Set the default answer (if applicable)' }]}>
                    <TextArea placeholder='Enter a default' autoComplete='off' />
                  </Form.Item>
                  <Space>
                    <Tooltip title='Delete question'>
                      <FaMinusCircle
                        className='text-red-600 cursor-pointer'
                        onClick={() => {
                          remove(field.name);
                          setQuestions(questions.filter((_, i) => i !== index));
                        }}
                      />
                    </Tooltip>
                    <Tooltip title='Add a new question'>
                      <FaPlusCircle className='text-green-600 cursor-pointer' onClick={handleAddQuestion} />
                    </Tooltip>
                  </Space>
                </Form.Item>
              </div>
            ))}
          </>
        )}
      </Form.List>
      {questions.length === 0 && (
        <Button type='primary' className='mb-4' onClick={handleAddQuestion}>
          Add Question
        </Button>
      )}
      <Form.Item>
        <Space>
          <Button type='primary' htmlType='submit'>
            {edit ? 'Save' : 'Create'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

QuestionForm.propTypes = {
  edit: PropTypes.bool,
  questionId: PropTypes.string,
  onSubmit: PropTypes.func,
};

QuestionForm.defaultProps = {
  edit: false,
  questionId: null,
};

export default QuestionForm;
