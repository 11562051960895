import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, message, Upload, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { notEmptyArray } from 'src/misc/Misc';
import { FaRegTrashCan } from 'react-icons/fa6';
import { uuid } from 'short-uuid';

const StepPdf = ({ pdfFiles }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const props = {
    showUploadList: false,
    name: 'file',
    //action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    // headers: {
    //   authorization: 'authorization-text',
    // },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done' || info.file.status === 'error') {
        const lpf = [...localPdfFiles];
        lpf.push(info.file.name);
        setLocalPdfFiles(lpf);

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'The file has been uploaded',
          duration: 3,
        });
      }
    },
  };

  const [localPdfFiles, setLocalPdfFiles] = useState([]);

  useEffect(() => {
    setLocalPdfFiles(true === notEmptyArray(pdfFiles) ? pdfFiles : []);
  }, [pdfFiles]);

  return (
    <div className='flex flex-row'>
      {contextHolder}
      <div style={{ borderRight: '1px solid gray' }}>
        <img src='/images/misc/pdf_logo.png' alt='pdf' className='h-12 mr-8' />
      </div>
      <div className='flex-auto ml-8'>
        {true === notEmptyArray(localPdfFiles) &&
          localPdfFiles.map((file, index) => (
            <div key={index} className='my-2'>
              <Space>
                {file}
                <FaRegTrashCan
                  className='cursor-pointer opacity-60 hover:opacity-100'
                  onClick={() => {
                    const lpf = [...localPdfFiles];
                    lpf.splice(index, 1);
                    setLocalPdfFiles(lpf);
                  }}
                />
              </Space>
            </div>
          ))}
        <div className='mt-4'>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </div>
      </div>
    </div>
  );
};

StepPdf.propTypes = {
  pdfFiles: PropTypes.array,
};

export default StepPdf;
