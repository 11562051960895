//import { sleepMs } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';
import { objToQueryStringParams } from 'src/misc/Misc';
import short from 'short-uuid';

var controllerSearchAiTemplates;

//#region AI Models

export const searchAiTemplates = async (query, page = 1, pageSize = 25) => {
  controllerSearchAiTemplates?.abort('cancelled');
  controllerSearchAiTemplates = new AbortController();
  const signal = controllerSearchAiTemplates.signal;
  const queryStringParams = objToQueryStringParams({ query, page, pageSize });
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aitemplates/search${queryStringParams}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getAiTemplates = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aitemplates`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAiTemplate = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aitemplates/${id}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const patchAiTemplate = async (id, changes) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aitemplates/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

export const postAiTemplate = async (newAiTemplate) => {
  const headers = await getAccessHeaders();
  newAiTemplate.id = short.generate();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aitemplates`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(newAiTemplate),
    })
  );
};

export const deleteAiTemplateApi = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityqa/aitemplates/${id}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion
