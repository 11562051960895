import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiMessageSquare } from 'react-icons/fi';
import { RiDeleteBinLine /* ,RiEditLine*/ } from 'react-icons/ri';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import Typewriter from './Typewriter';

const SidebarItem = ({ conversationId, title, isSelected, onSelect, onDelete, isDeleting, animate, isLoading }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className='flex flex-row gap-2 pb-2 text-gray-100 text-sm '
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}>
      <div className={`flex-auto flex h-10 px-3 items-center gap-3 relative rounded-md ${isSelected & !isLoading || isLoading === conversationId ? 'bg-gray-700' : ''}  ${isSelected || isLoading === conversationId ? '' : 'hover:bg-[#2A2B32] '}  cursor-pointer break-all`}>
        <div
          className='flex-auto flex flex-row items-center gap-2 h-full'
          onClick={() => {
            if (!onSelect) return;
            onSelect(conversationId);
          }}>
          {conversationId === isLoading ? <AiOutlineLoading3Quarters className='animate-spin' /> : <FiMessageSquare className='h-4 w-4' />}

          <div className='flex-1 text-ellipsis max-h-5 overflow-hidden break-all relative'>
            {animate && title ? <Typewriter text={title} delay={75} conversationId={conversationId} /> : <>{title}</>}

            {/* <div className='absolute inset-y-0 right-0 w-8 z-10 bg-gradient-to-l from-gray-900 group-hover:from-[#2A2B32]'></div> */}
          </div>
        </div>

        {/* <div
          className={`${isHovered ? 'opacity-100' : 'opacity-0'} transition-all duration-300 hover:scale-125`}
          onClick={() => {
            console.log('Edit');
          }}
        >
          <RiEditLine className='text-white cursor-pointer' />
        </div> */}

        {isDeleting ? (
          <AiOutlineLoading3Quarters className='animate-spin' />
        ) : (
          <div
            className={`${isHovered ? 'opacity-100' : 'opacity-0'} transition-all duration-300 hover:scale-125`}
            onClick={() => {
              if (!onDelete) return;
              onDelete(conversationId);
            }}>
            <RiDeleteBinLine className='text-white cursor-pointer' />
          </div>
        )}
      </div>
    </div>
  );
};

SidebarItem.propTypes = {
  title: PropTypes.string.isRequired,
  conversationId: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  isDeleting: PropTypes.bool,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
  animate: PropTypes.bool,
  isLoading: PropTypes.string,
};

export default SidebarItem;
