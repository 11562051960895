//import { sleepMs } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';
import { objToQueryStringParams } from 'src/misc/Misc';

export const getPreSignedUrl = async (file, env = null, token = null, provider = null) => {
  if (!env || !token || !provider) {
    const userHeaders = await getAccessHeaders();
    env = userHeaders.env;
    token = userHeaders.token;
    provider = userHeaders.provider;
  }

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/presignedurl?file=${encodeURIComponent(file)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env,
        token,
        provider,
        authorization: window.location.hostname === 'localhost' ? 'test' : undefined,
      },
    })
  );
};

export const getJsonFile = async (staticFile) => {
  return (
    await fetch(staticFile, {
      method: 'GET',
    })
  )?.json();
};

export const getJobs = async () => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/jobs`, {
      method: 'GET',
      headers,
    })
  );
};

export const getJob = async (jobId) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/jobs`, {
      method: 'GET',
      headers,
    })
  );
};

export const postJobs = async (data) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/jobs`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const postSetup = async (data) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/setup`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const startJob = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/jobs/start`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const stopJob = async (workerId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/workers/${workerId}/stop`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const patchJob = async (jobId, data) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/jobs/${jobId}`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const deleteJob = async (jobId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/jobs/${jobId}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getJobsCount = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/jobs/count`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#region "EVENT BRIDGE INTEGRATION"

export const getEbIntegration = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/genesyscloud/eb-integration`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postEbIntegration = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/genesyscloud/eb-integration`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const deleteEbIntegration = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/genesyscloud/eb-integration`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "EVENTS"

export const getEvents = async (page = 1, pageSize = 25, filter) => {
  const queryStringParams = objToQueryStringParams({ page, pageSize, filter });
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/events${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "EVENTS FILTERS"

export const getEventsFilters = async (protocol, endpoint) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/events-filters?protocol=${encodeURIComponent(protocol)}&endpoint=${encodeURIComponent(endpoint)}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    }),
    true,
    false
  );
};

export const putEventsFilter = async (data) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/events-filters`, {
      method: 'PUT',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const deleteEventsFilter = async (protocol, endpoint) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/securityadvisor/events-filters?protocol=${encodeURIComponent(protocol)}&endpoint=${encodeURIComponent(endpoint)}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion
