//import { sleepMs } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';

export const subscribe = async (object_type, object_id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/subscriptions`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        object_type,
        object_id,
      }),
    })
  );
};

//#region "SNS SUBSCRIPTIONS"

export const getSnsTopic = async (appId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/subscriptions/sns/topic/${encodeURIComponent(appId)}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    }),
    true,
    false
  );
};

export const postSnsTopic = async (appId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/subscriptions/sns/topic/${encodeURIComponent(appId)}`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    }),
    true,
    false
  );
};

export const deleteSnsTopic = async (appId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/subscriptions/sns/topic/${encodeURIComponent(appId)}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getSnsSubscriptions = async (appId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/subscriptions/sns/topic/${encodeURIComponent(appId)}/subscriptions`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postSubscribe = async (appId, Protocol, Endpoint) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/subscriptions/sns/topic/${encodeURIComponent(appId)}/subscribe`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify({ Protocol, Endpoint }),
    })
  );
};

export const deleteSubscribe = async (appId, subscriptionArn) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/subscriptions/sns/topic/${encodeURIComponent(appId)}/subscribe/${encodeURIComponent(subscriptionArn)}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion
