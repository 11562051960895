import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { Space, Button, Tooltip } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from '../Misc/misc';
import TicketListControl from './components/TicketListControl';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import TicketListSearchBar from './components/TicketListSearchBar';
import LoaderServicesForTenant from '../Loaders/LoaderServicesForTenant';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { getTickets } from 'src/api/teacket';
import { ReloadOutlined } from '@ant-design/icons';

//import PropTypes from 'prop-types';

const TicketList = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const [csvDownloading, setCsvDownloading] = useState(false);
  const { ticketListUsers, ticketListServices, ticketListPriorities, ticketListStatuses, ticketListQuery, ticketListStartDate, ticketListEndDate, ticketListTypes } = useContext(TeacketContext);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      {
        title: 'Ticket List',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  function download(data, filename, type) {
    var file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob)
      // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else {
      // Others
      var a = document.createElement('a'),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  const exportTickets = async (separator = ';') => {
    setCsvDownloading(true);
    console.log('exportTickets()');

    try {
      const resp = await getTickets(1, 1000, ticketListQuery, ticketListServices, ticketListPriorities, ticketListStatuses, ticketListUsers, ticketListStartDate, ticketListEndDate, ticketListTypes);
      console.log(resp);

      let tickets = JSON.parse(JSON.stringify(resp?.entities));
      tickets.forEach((item) => {
        //if (!item.props) item.props = ''; //TODO: Later use it for custom fields
        if (!item.wrap_up_code) item.wrap_up_code = '';
        if (item.teacket_ticket_members && Array.isArray(item.teacket_ticket_members)) {
          item.members = item.teacket_ticket_members.map((x) => x?.user?.name).join('|');
        }
        if (item.owner_id) item.owner = item.teacket_ticket_members.find((x) => x.user_id === item.owner_id)?.user?.name;

        delete item.owner_id;
        delete item.service_id;
        delete item.assignee_id; //TODO: SHould it be deleted ?
        delete item.teacket_ticket_members;
        delete item.props;
      });

      const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
      const header = Object.keys(tickets[0]);
      let csv = tickets.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(separator));
      csv.unshift(header.join(separator));
      csv = csv.join('\r\n');

      download(csv, 'export.csv');
    } catch (error) {
      console.error(error);
    } finally {
      setCsvDownloading(false);
    }
  };

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.list.view']}>
      <LoaderServicesForTenant>
        <TitleBar
          title={
            <Space className='btn'>
              <BsCupHotFill />
              <div>
                <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
              </div>
            </Space>
          }
          afterTitleExtras={
            <div>
              <PageSwitch pageList={pageList} />
            </div>
          }
          bottomExtras={<TicketListSearchBar />}
          defaultExpanded={true}
        >
          <Space>
            <Tooltip title='CSV export limited to 1000 records.'>
              <Button type='text' size='small' onClick={() => exportTickets(',')} loading={csvDownloading} icon={csvDownloading ? <ReloadOutlined className='scale-75' /> : null} className={`${csvDownloading ? 'disabled' : ''}`}>
                <CloudDownloadOutlined />
              </Button>
            </Tooltip>
          </Space>
        </TitleBar>
        <div className='p-4 flex flex-col gap-4'>
          <TicketListControl />
        </div>
      </LoaderServicesForTenant>
    </PermissionsProvider>
  );
};

// TicketList.propTypes = {};

export default TicketList;
