import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SecurityAdvisorContext from 'src/context/SecurityAdvisorContext';
import { red, green, gray } from '@ant-design/colors';
import { statusList } from '../Misc/misc';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getJobs } from 'src/api/securityAdvisor';
import { startJob, stopJob, getPreSignedUrl } from 'src/api/securityAdvisor';
import BarLoader from 'react-bar-loader';
import useTheme from 'src/hooks/useTheme';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { MdStopCircle, MdPlayCircle, MdRefresh, MdDownloadForOffline, MdSettings /* MdWarning */ } from 'react-icons/md';
import { GrSchedulePlay } from 'react-icons/gr';

import { Tooltip, message } from 'antd';

//import PropTypes from 'prop-types';
dayjs.extend(localizedFormat);

const Controls = () => {
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const navigate = useNavigate();

  const [allowStart, setAllowStart] = useState(false);
  const [lastWorkerWithReport, setLastWorkerWithReport] = useState(null);
  const [loading, setLoading] = useState(false);

  const { job, setJob, getSortedWorkers } = useContext(SecurityAdvisorContext);

  useEffect(() => {
    //console.log('UseEfdfect ', job.workers);
    if (job) {
      const sortedWorkers = getSortedWorkers();

      const lastReport = sortedWorkers.find((worker) => worker?.filesExists?.pdf);
      console.log('Last workerId with report:', lastReport?.id);
      setLastWorkerWithReport(lastReport);

      if (sortedWorkers.length === 0 || (sortedWorkers.length > 0 && sortedWorkers[0].status && statusList.find((x) => x.key === sortedWorkers[0].status)?.allowStart)) {
        setAllowStart(true);
      } else {
        setAllowStart(false);
      }
    }
  }, [job]);

  const handleStartStopJob = async () => {
    console.log('handleStartStopJob()', allowStart);

    try {
      setLoading(true);
      if (allowStart) {
        console.log('Starting job...');
        const worker = await startJob();

        let newWorkers = [...job.workers];

        newWorkers.unshift(worker);

        setJob({ ...job, workers: newWorkers });
        message.success('Job started successfully. An email notification will be sent once the job is completed.', 15);
      } else {
        console.log('Stopping job...');
        const workerId = getSortedWorkers()[0].id;

        const updatedWorker = await stopJob(workerId);

        let copyWorkers = [...job.workers];

        const updatedWorkerIndex = copyWorkers.findIndex((worker) => worker.id === updatedWorker.id);
        if (updatedWorkerIndex !== -1) {
          copyWorkers[updatedWorkerIndex] = updatedWorker;
        }

        setJob({ ...job, workers: copyWorkers });
        message.success('Job stopped successfully');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const reloadData = async () => {
    console.log('reloadData()');
    try {
      await executeAsyncProcess(async () => {
        let job = await getJobs();

        setJob(job);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getReport = async () => {
    console.log('getReport()');

    try {
      setLoading(true);
      if (lastWorkerWithReport) {
        const { url } = (await getPreSignedUrl(`${job.id}/${lastWorkerWithReport.id}_report.pdf`)) ?? {};
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.pdf');
        link.click();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? <BarLoader color={theme.primary} backgroundColor={theme.light} height='2' width='100%' /> : <div style={{ height: '1px', marginTop: '1px', backgroundColor: 'transparent' }}></div>}

      <div className={`flex flex-row flex-wrap justify-center ${!loading ? null : 'disabled'}`}>
        <div className='text-2xl text-center m-4 transform transition hover:scale-125 cursor-pointer w-20' onClick={handleStartStopJob}>
          {allowStart ? (
            <>
              <MdPlayCircle color={green[4]} size='2em' />
              <div className='text-sm'>Start</div>
            </>
          ) : (
            <>
              <MdStopCircle color={red[4]} size='2em' />
              <div className='text-sm'>Stop</div>
            </>
          )}
        </div>
        <div className='text-2xl text-center m-4 transform transition hover:scale-125 cursor-pointer w-20' onClick={reloadData}>
          <MdRefresh size='2em' />
          <div className='text-sm'>Refresh</div>
        </div>
        {lastWorkerWithReport ? (
          <div className='text-2xl text-center m-4 transform transition hover:scale-125 cursor-pointer w-20' onClick={getReport}>
            <MdDownloadForOffline size='2em' />
            <div className='text-sm'>Report</div>
          </div>
        ) : (
          <Tooltip title='No report available'>
            <div className='text-2xl text-center m-4'>
              <MdDownloadForOffline color={gray[2]} size='2em' />
              <div className='text-sm text-gray-400'>Report</div>
            </div>
          </Tooltip>
        )}
        <div
          className='text-2xl text-center m-4 transform transition hover:scale-125 cursor-pointer w-20'
          onClick={() => {
            navigate('/security-advisor/options');
          }}>
          <MdSettings size='2em' />
          <div className='text-sm'>Options</div>
        </div>
        {/* SA Events are moved to Audit Viewer */}
        {/* <div
          className='text-2xl text-center m-4 transform transition hover:scale-125 cursor-pointer w-20'
          onClick={() => {
            navigate('/security-advisor/events');
          }}>
          <MdWarning size='2em' />
          <div className='text-sm'>Notifications</div>
        </div> */}
      </div>
      {job?.is_enabled && job?.cron && job?.scheduled && (
        <div className='text-right'>
          <Tooltip title={`Next run ${dayjs(job.scheduled).format('lll')}`}>
            <GrSchedulePlay className='text-green-500 mr-4' />
          </Tooltip>
        </div>
      )}
    </>
  );
};

Controls.propTypes = {};

export default Controls;
