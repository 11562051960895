import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

const FieldBoolean = ({ propName, fieldDef, defaultValue, onChange }) => {
  return (
    <div>
      <div>{fieldDef.description}</div>
      <div>
        <Checkbox
          defaultChecked={defaultValue}
          onChange={(e) => {
            if (!onChange) return;
            onChange({ propName, value: e.target.checked });
          }}
        />
      </div>
    </div>
  );
};

FieldBoolean.propTypes = {
  propName: PropTypes.string,
  fieldDef: PropTypes.object,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default FieldBoolean;
