import React from 'react';
import Header from 'src/components/pages/Landing/components/ui/Header';
import { MdSecurity } from 'react-icons/md';
import { Space } from 'antd';
import HelpSettingsWidget from '../../SecurityAdvisor/Components/Options/HelpSettingsWidget';
//import PropTypes from 'prop-types';

const SecurityAdvisor = () => {
  return (
    <>
      <div className='fixed z-50'>
        <Header />
      </div>
      <div className='absolute flex flex-col items-center w-full'>
        <div className='mt-24 max-w-7xl '>
          <div className='my-8 font-bold text-2xl'>
            <Space>
              <MdSecurity />
              Security Advisor
            </Space>
          </div>
          <div>
            <a href='#installation' target='_self' className='decoration-solid hover:decoration-slice'>
              Requirements & Installation
            </a>
          </div>
          <div>
            <a href='#realtimemonitoring' target='_self' className='decoration-solid hover:decoration-slice'>
              Real-time monitoring
            </a>
          </div>
          <div>
            <a href='#scoringcalculation' target='_self' className='decoration-solid hover:decoration-slice'>
              How scoring is calculated
            </a>
          </div>
          <div className='my-8'>Introducing Security Advisor – your indispensable companion for Genesys Cloud security optimization. This advanced tool meticulously scrutinizes your organization's Genesys Cloud configuration, uncovering any misconfigurations that could potentially compromise security.</div>
          <div className='flex justify-center'>
            <img src='/images/products/SecurityAdvisor/Security_Advisor_Report_1.png' alt='security advisor' className='shadow-xl dark:shadow-genesys-gray-400 rounded-lg' />
          </div>
          <div className='my-8'>Security Advisor operates with precision, meticulously analyzing every aspect of your Genesys Cloud setup to identify areas where adjustments may be needed. By providing detailed insights into misconfigured items, Security Advisor empowers your organization to take proactive steps towards enhancing security.</div>
          <div className='my-8'>Through its user-friendly report, Security Advisor presents findings in a clear and concise manner, allowing your team to efficiently address any identified issues. From user permissions to access controls and more, Security Advisor offers a comprehensive overview of your security landscape.</div>
          <div className='flex justify-center'>
            <img src='/images/products/SecurityAdvisor/Security_Advisor_Report_2.png' alt='security advisor' className='shadow-xl dark:shadow-genesys-gray-400 rounded-lg' />
          </div>
          <div className='my-8'>By leveraging Security Advisor, your organization can improve compliance with security standards and maintain a robust security posture in the face of evolving threats. With Security Advisor as your trusted ally, you can navigate the complexities of Genesys Cloud configuration with confidence, knowing that your organization's sensitive data and operations are safeguarded against potential risks.</div>
          <div className='flex justify-center'>
            <img src='/images/products/SecurityAdvisor/Security_Advisor_Report_3.png' alt='security advisor' className='shadow-xl dark:shadow-genesys-gray-400 rounded-lg' />
          </div>
          <div id='installation' className='my-8 font-bold text-2xl'>
            <Space>Requirements & Installation</Space>
          </div>
          <div className='my-8'>
            Running Security Advisor requires specific permissions when accessing for the first time in order to create an OAuth client that will be used later on.
            <div>It is recommended to create a role for this in Genesys Cloud, assign it to your user account, and then remove it when it is no longer needed.</div>
            <ul className='list-disc list-inside'>
              <li>
                Analytics &gt; Data Retention &gt; View <i>to view your data retention settings</i>
              </li>
              <li>
                Audits &gt; Audit &gt; View <i>to view your audit logs (e.g. to check failed logins)</i>
              </li>
              <li>
                Authorization &gt; Role &gt; View <i>to list your organization's roles and find out who can perform specific actions in your organization</i>
              </li>
              <li>
                Chat &gt; Setting &gt; View <i>to view your organization's chat settings, including the media retention settings</i>
              </li>
              <li>
                Chat &gt; usersettings &gt; View <i>to view your users' chat settings</i>
              </li>
              <li>
                Conversation &gt; Encryption Key &gt; View <i>to view if your conversations use an encryption key</i>
              </li>
              <li>
                Directory &gt; Organization &gt; Admin <i>to get access to your organization settings which includes several settings that need to be accessed by this tool. There is no "View" permission for this topic however our tool only reads data and does not change anything</i>
              </li>
              <li>
                OAuth &gt; Client &gt; Add <i>to be able to create an OAuth client in your organization that will be used to run this tool in the future</i>
              </li>
              <li>
                OAuth &gt; Client &gt; View <i>to check if the OAuth client required by this tool is configured</i>
              </li>
              <li>
                Recording &gt; Encryption Key &gt; View <i>to view if your organization has one or more encryption keys</i>
              </li>
              <li>
                Single Sign-On &gt; Provider &gt; View <i>to view if your organization uses Single Sign-On providers</i>
              </li>
              <li>
                Telephony &gt; Plugin &gt; All <i>to view your telephony settings. There is no "View" permission for this topic but our tool only reads and does not change anything</i>
              </li>
            </ul>
            Here is how your role should look like before saving it:
            <div className='flex justify-center mt-4'>
              <img src='/images/products/SecurityAdvisor/Security_Advisor_Role_Permissions.png' alt='Security Advisor role permissions' className='shadow-xl dark:shadow-genesys-gray-400 rounded-lg' />
            </div>
            <div className='my-8'>After creating the role, assign it to your user account. Logout from this portal by using your profile picture on the top-right.</div>
            <div className='flex justify-center mt-4'>
              <img src='/images/products/User_Sign_Out.png' alt='Log out/Sign out' className='shadow-xl dark:shadow-genesys-gray-400 rounded-lg' />
            </div>
            <div className='my-8'>Log back in and open Security Advisor again.</div>
            <div>Once you have gone through the Setup Wizard, you may delete the role from your organization as the created OAuth client will be used for future requests.</div>
            <div>Future users will need to have the OAuth &gt; Client &gt; View permission assigned as this is required to check if the OAuth client is configured correctly.</div>
          </div>
          <div id='realtimenotifications' className='my-8 font-bold text-2xl'>
            <Space>Real-Time Notifications</Space>
          </div>
          <div className='my-8'>
            Security Advisor also offers the possibility of monitoring your organization in real-time and receiving notifications when new security issues are detected.
            <div>
              This feature requires an integration with{' '}
              <a href='https://aws.amazon.com/eventbridge/' target='blank'>
                AWS EventBridge
              </a>
              . More information about the integration between Genesys Cloud and AWS EventBridge can be found{' '}
              <a href='https://developer.genesys.cloud/notificationsalerts/notifications/event-bridge' target='blank'>
                here
              </a>
              .
            </div>
          </div>
          <div className='my-8'>
            To get started, click on the "Configure" button on the top-right of the "Notifications" section and follow the instructions to set up the integration between Genesys Cloud and AWS EventBridge. This will create a new EventBridge rule in your AWS account that will forward all Genesys Cloud notifications to Security Advisor. Security Advisor will then analyze these notifications and send you an email if a new security issue is detected.
            <div>Once the integration is complete, you will need to enable the "Real-time monitoring" feature by adding a new subscription using a channel such as email to receive notifications.</div>
          </div>
          <div id='scoringcalculation' className='my-8 font-bold text-2xl'>
            <Space>How each item is use to calculate the final score</Space>
          </div>
          <div>Scroll down to see all entries</div>
          <HelpSettingsWidget />
        </div>
      </div>
    </>
  );
};

// Copilot.propTypes = {};

export default SecurityAdvisor;
