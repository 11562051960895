import React, { useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
// import PropTypes from 'prop-types';
import CollapsibleField from 'src/components/layout/CollapsibleField';
import ConversationHistory from './ConversationHistory';
import ConversationNewMessage from './ConversationNewMessage';
import { patchTicket } from 'src/api/teacket';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { message } from 'antd';
import { uuid } from 'short-uuid';
import { CONTENT_MAX_LENGTH, hasTicketEditRights, TITLE_MAX_LENGTH } from '../Misc/misc';
import useUserInfo from 'src/hooks/useUserInfo';
import useTheme from 'src/hooks/useTheme';

const TicketConversation = () => {
  const { currentTicket, getServiceSettingForCurrentTicket, updateCurrentTicket, setTicketAfterUpdateSync, determineIsWidgetReadOnly } = useContext(TeacketContext);
  const { executeAsyncProcess } = useAsyncProcesses();
  const { permissions: userPermissions } = useUserInfo();
  const { theme } = useTheme();
  const [messageApi, contextHolder] = message.useMessage();

  const showNewMessage = () => {
    // show it if the ticket does not have closed_at date
    if (!currentTicket?.closed_at) return true;

    // <show it if the setting is not defined>
    const conversationExpirationDaysStr = getServiceSettingForCurrentTicket('ConversationExpirationDays');
    if (!conversationExpirationDaysStr) return true;
    const conversationExpirationDays = parseInt(conversationExpirationDaysStr);
    if (isNaN(conversationExpirationDays)) return true;
    // </show it if the setting is not defined>

    const closedAt = new Date(currentTicket.closed_at);
    const now = new Date();
    const diffTime = Math.abs(now - closedAt);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > conversationExpirationDays) return false;

    return true;
  };

  const handleTitleOnChange = (title) => {
    console.log('handleTitleOnChange');
    executeAsyncProcess(async () => {
      try {
        const ticket = await patchTicket(currentTicket.id, { title });
        updateCurrentTicket(ticket);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Title successfully updated',
          duration: 3,
        });
        setTicketAfterUpdateSync(true);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleContentOnChange = (content) => {
    console.log('handleContentOnChange()');
    executeAsyncProcess(async () => {
      try {
        const ticket = await patchTicket(currentTicket.id, { content });
        updateCurrentTicket(ticket);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Description successfully updated',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <div className='flex flex-col gap-4 pt-4'>
      {contextHolder}
      <CollapsibleField label='Short summary' value={currentTicket?.title ?? ''} editable={!determineIsWidgetReadOnly('Conversation') && true === hasTicketEditRights(userPermissions) ? { onChange: handleTitleOnChange, multiline: false } : null} maxLength={TITLE_MAX_LENGTH} edgeGradientColor={theme.backgroundBase} />
      <CollapsibleField label='Problem description' value={currentTicket?.content ?? ''} editable={!determineIsWidgetReadOnly('Conversation') && true === hasTicketEditRights(userPermissions) ? { onChange: handleContentOnChange, multiline: true } : null} maxLength={CONTENT_MAX_LENGTH} edgeGradientColor={theme.backgroundBase} />
      <ConversationHistory />
      {true === showNewMessage() && <ConversationNewMessage />}
    </div>
  );
};

// TicketConversation.propTypes = {  };

export default TicketConversation;
