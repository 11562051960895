import React, { useContext } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import Wizard from 'src/components/controls/Wizard/Wizard';
import PropTypes from 'prop-types';
import useUserInfo from 'src/hooks/useUserInfo';
import { TbLockAccess } from 'react-icons/tb';
import { postFeaturesAccessFreeTrialRequest } from 'src/api/accesscontrol';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import CorporateColors from 'src/misc/CorporateColors';

const FreeTrialActivation = ({ feature, featureDisplayName }) => {
  const { tenant_name: tenantName } = useUserInfo();
  const { featureList } = useContext(GlobalContext);
  const { executeAsyncProcess } = useAsyncProcesses();

  console.log('featureList:', featureList);

  const wizardSteps = [
    {
      title: 'Activation',
      header: 'Free Trial Activation',
      content: (
        <div className='text-center'>
          <div>
            <TbLockAccess className='text-8xl text-genesys-patina-base' style={{ filter: `drop-shadow(0px 0px 18px ${CorporateColors.patina})` }} />
          </div>
          <div className='text-xl'>You are about to activate a free trial version of the application.</div>
          <div className='mt-6'>
            <span className='font-light opacity-60'>Application:</span> {featureDisplayName || feature}
          </div>
          <div className='mt-2'>
            <span className='font-light opacity-60'>Tenant:</span> {tenantName}
          </div>
          <div className='mt-2'>
            <span className='font-light opacity-60'>Free trial duration:</span> {featureList?.find((x) => x.feature === feature)?.trial_duration_days} days
          </div>
          <div className='mt-6'>
            Click the <strong>Activate now</strong> button to start the free trial.
          </div>
        </div>
      ),
      // allowNext: selectedDeployment !== null,
    },
  ];

  const handleWizardOnFinish = () => {
    console.log('handleWizardOnFinish');
    executeAsyncProcess(async () => {
      try {
        await postFeaturesAccessFreeTrialRequest(feature);
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    });
  };

  return <Wizard steps={wizardSteps} finishLabel='Activate now' currentStep={0} onFinish={handleWizardOnFinish} />;
};

FreeTrialActivation.propTypes = {
  feature: PropTypes.string,
  featureDisplayName: PropTypes.string,
};

export default FreeTrialActivation;
