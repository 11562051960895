import React, { useContext, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { getTicketHistory } from 'src/api/teacket';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import CondensedTable from 'src/components/layout/CondensedTable';
import dayjs from 'dayjs';
import TicketHistoryItem from './TicketHistoryItem';
import { notArrayOrEmpty } from 'src/misc/Misc';

//import PropTypes from 'prop-types';

const TicketHistory = () => {
  const { currentTicket, setCurrentTicketHistory, currentTicketHistory, currentTicketHistoryForceReload, setCurrentTicketHistoryForceReload, currentTicketIsMasterTenant } = useContext(TeacketContext);
  const { executeAsyncProcess } = useAsyncProcesses();

  const columns = [
    // {
    //   title: 'SubscriptionArn',
    //   dataIndex: 'SubscriptionArn',
    //   key: 'SubscriptionArn',

    // },
    {
      title: 'Event',
      key: 'event',
      width: 140,
      render: (record) => {
        return (
          <div>
            <div className='font-semibold text-xs'>{record.action}</div>
            <div className='font-thin text-xs'>{record.type}</div>
          </div>
        );
      },
    },
    {
      title: 'Time',
      key: 'time',
      width: 140,
      render: (record) => {
        return (
          <div>
            <div className='font-semibold text-xs'>{dayjs(record.timestamp).format('lll')}</div>
            <div className='font-thin text-xs'>by {record.user_name}</div>
          </div>
        );
      },
    },

    // {
    //   title: 'Subscription',
    //   dataIndex: 'SubscriptionArn',
    //   key: 'SubscriptionArn',
    // },
  ];

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (true !== currentTicketHistoryForceReload) return;
    loadData();
    setCurrentTicketHistoryForceReload(null);
  }, [currentTicketHistoryForceReload]);

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        const result = await getTicketHistory(currentTicket.id);
        setCurrentTicketHistory(result);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const getFilteredCurrentTicketHistory = () => {
    if (notArrayOrEmpty(currentTicketHistory)) return null;
    if (true === currentTicketIsMasterTenant) return currentTicketHistory;
    return currentTicketHistory.filter((x) => x.type !== 'Conversation' && x.type !== 'Add internal msg');
  };

  if (!currentTicketHistory) return <SkeletonTable />;

  return (
    <CondensedTable
      columns={columns}
      dataSource={getFilteredCurrentTicketHistory()}
      pagination={{
        pageSize: 5,
        size: 'small',
      }}
      rowKey={(record) => record.id}
      expandable={{
        columnWidth: 20,
        expandedRowRender: (record) => <TicketHistoryItem item={record} ticketId={currentTicket.id} />,
        rowExpandable: (record) => !['conversation'].includes(record?.type?.toLowerCase()),
      }}
    />
  );
};

TicketHistory.propTypes = {};

export default TicketHistory;
