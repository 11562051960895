import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';

const BoxType1 = ({ title, children, footer, onClick, closable = false, onClose, customStyling = '' }) => {
  const [isClosed, setIsClosed] = useState(false);

  const handleOnClose = () => {
    console.log('handleOnClose()');
    setIsClosed(true);
    onClose && onClose();
  };

  if (isClosed) return null;

  return (
    <div className={`relative rounded-2xl shadow-md p-4 border border-solid border-zinc-300 dark:border-zinc-700 ${customStyling?.length > 0 ? customStyling : 'bg-white dark:bg-zinc-900'} ${onClick && 'cursor-pointer'}`} onClick={() => onClick && onClick()}>
      {closable && <FaTimes className='absolute right-4 top-4 text-2xl cursor-pointer opacity-60 hover:opacity-100 hover:scale-105 transition-all' onClick={handleOnClose} />}
      <div className='h-full flex flex-col'>
        <div className='text-2xl mb-4 overflow-hidden break-words'>{title}</div>
        <div className='flex-auto'>{children}</div>
        {footer && <div className='mt-4'>{footer}</div>}
      </div>
    </div>
  );
};

BoxType1.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  footer: PropTypes.node,
  onClick: PropTypes.func,
  closable: PropTypes.bool,
  customStyling: PropTypes.bool,
  onClose: PropTypes.func,
};

export default BoxType1;
