import React from 'react';
import { PropTypes } from 'prop-types';
import { ROW_HEIGHT_INT } from './common';
import { cyan } from '@ant-design/colors';

const ItemLinker = ({ stageIndexFrom, stageIndexTo, leftOffset }) => {
  const getLowerValue = (v1, v2) => (v1 < v2 ? v1 : v2);
  const getHigherValue = (v1, v2) => (v1 > v2 ? v1 : v2);
  const getTopOffset = () => getLowerValue(stageIndexFrom, stageIndexTo) * ROW_HEIGHT_INT;
  const getHeight = () => (getHigherValue(stageIndexFrom, stageIndexTo) - getLowerValue(stageIndexFrom, stageIndexTo)) * ROW_HEIGHT_INT + ROW_HEIGHT_INT;
  const getLineHeight = () => (getHigherValue(stageIndexFrom, stageIndexTo) - getLowerValue(stageIndexFrom, stageIndexTo)) * ROW_HEIGHT_INT + ROW_HEIGHT_INT / 2;

  const getLineX1 = () => (stageIndexFrom < stageIndexTo ? 0 : ROW_HEIGHT_INT / 2);
  const getLineX2 = () => (stageIndexFrom < stageIndexTo ? ROW_HEIGHT_INT / 2 : 0);
  // const getCircleY = () => (stageIndexFrom < stageIndexTo ? getLineHeight() : ROW_HEIGHT_INT / 2);

  // console.log(getHeight());

  return (
    <svg height={getHeight()} width={ROW_HEIGHT_INT / 2} style={{ position: 'absolute', height: getHeight(), top: getTopOffset(), left: `${leftOffset}px` }}>
      <line x1={getLineX1()} y1={ROW_HEIGHT_INT / 2} x2={getLineX2()} y2={getLineHeight()} style={{ stroke: cyan[6], strokeWidth: 3 }} />
      {/* <circle z={100} cx={ROW_HEIGHT_INT / 2} cy={getCircleY()} r={ROW_HEIGHT_INT / 8} stroke={cyan[6]} strokeWidth={3} fill={cyan[2]} /> */}
    </svg>
  );
};

ItemLinker.propTypes = {
  stageIndexFrom: PropTypes.number.isRequired,
  stageIndexTo: PropTypes.number.isRequired,
  leftOffset: PropTypes.number.isRequired,
};

ItemLinker.defaultProps = {
  stageIndexFrom: 0,
  stageIndexTo: 0,
  leftOffset: 0,
};

export default ItemLinker;
