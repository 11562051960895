import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getJsonFile } from 'src/api/dataSubjectRequests';
import { Spin, Input } from 'antd';

const { TextArea } = Input;

const JsonViewer = ({ fileUrl }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (fileUrl) loadData();
  }, [fileUrl]);

  const loadData = async () => {
    console.log('loadData()');
    try {
      const resp = await getJsonFile(fileUrl);
      console.log(resp);
      setContent(resp);
    } catch (error) {
      console.error(error);
    }
  };

  if (!content)
    return (
      <div className='center'>
        <Spin size='small' tip='Loading content...' />
      </div>
    );

  return (
    <div style={{ padding: '6px', fontSize: '0.8rem' }}>
      <TextArea rows={15} value={content} />
    </div>
  );
};

JsonViewer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};

JsonViewer.defaultProps = {};

export default JsonViewer;
