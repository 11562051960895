import React, { useState, useEffect } from 'react';
import CondensedTable from 'src/components/layout/CondensedTable';
import dayjs from 'dayjs';
import Na from 'src/components/layout/Na';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getAdminFeaturesAccessList } from 'src/api/accesscontrol';
import DeploymentPicker from 'src/components/controls/DeploymentPicker/DeploymentPicker';
import FeaturePicker from 'src/components/controls/FeaturePicker/FeaturePicker';
import { Select } from 'antd';
import { describeFeaturesAccess } from 'src/components/controls/FeatruesAccess/Misc';
import { notEmptyArray } from 'src/misc/Misc';
import PageLayoutType1 from 'src/components/layout/PageLayoutType1';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import { uuid } from 'short-uuid';

//import PropTypes from 'prop-types';

const FeaturesAccessList = () => {
  const { executeAsyncProcess } = useAsyncProcesses();

  const pageSizeOptions = [20, 50, 100];

  const accessFilterOptions = [
    { value: 'free_trial_active', label: 'Free trial active' },
    { value: 'free_trial_expired', label: 'Free trial expired' },
    { value: 'paid_access_active', label: 'Paid access active' },
    { value: 'paid_access_expired', label: 'Paid access expired' },
  ];

  const columns = [
    {
      title: 'Feature',
      key: 'feature',
      render: (record) => <>{record.feature}</>,
    },
    {
      title: 'Tenant',
      key: 'tenant',
      align: 'center',
      width: '25%',
      render: (record) => (
        <div>
          <div>{record?.tenant?.name}</div>
          <div className='font-light text-xs opacity-80'>{record?.tenant?.third_party_env}</div>
        </div>
      ),
    },
    {
      title: 'Free trial',
      key: 'freeTrial',
      align: 'center',
      width: '25%',
      render: (record) => (
        <div>
          {/* dates */}
          <div>
            {record.free_trial_from ? <>{dayjs(record.free_trial_from).format('ll')}</> : <Na />} - {record.free_trial_to ? <>{dayjs(record.free_trial_to).format('ll')}</> : <Na />}
          </div>
          {/* description */}
          <div className={`font-light text-xs opacity-80 ${record.freeTrialError ? 'text-red-500' : record.freeTrialHasAccess ? 'text-green-500' : 'text-orange-500'}`}>{record.freeTrialDesc}</div>
          {/* activated by */}
          {record?.free_trial_activated_by_user?.name && <div className='font-light text-xs opacity-80'>activated by {record?.free_trial_activated_by_user?.name}</div>}
        </div>
      ),
    },
    {
      title: 'Paid access',
      key: 'paidAccess',
      align: 'center',
      width: '25%',
      render: (record) => (
        <div>
          {/* dates */}
          <div>
            {record.paid_access_from ? <>{dayjs(record.paid_access_from).format('ll')}</> : <Na />} - {record.paid_access_to ? <>{dayjs(record.paid_access_to).format('ll')}</> : <Na />}
          </div>
          {/* description */}
          <div className={`font-light text-xs opacity-80 ${record.paidAccessError ? 'text-red-500' : record.paidAccessHasAccess ? 'text-green-500' : 'text-orange-500'}`}>{record.paidAccessDesc}</div>
          {/* updated by */}
          {record?.paid_access_updated_by_user?.name && <div className='font-light text-xs opacity-80'>updated by {record?.paid_access_updated_by_user?.name}</div>}
        </div>
      ),
    },
  ];

  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[0]));
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [entites, setEntities] = useState([]);
  const [selectedDeployment, setSelectedDeployment] = useState(null);
  const [selectedAccessType, setSelectedAccessType] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);

  useEffect(() => {
    setEntities(null);
    loadData();
  }, [page]);

  useEffect(() => {
    setEntities(null);
    setPage(1);
    loadData();
  }, [pageSize, selectedDeployment, selectedAccessType, selectedFeature]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getAdminFeaturesAccessList(pageSize, page, selectedDeployment?.ORG_ID ?? null, selectedAccessType ?? null, selectedFeature ?? null);
        setTotal(resp?.total ?? 0);
        setEntities(true === notEmptyArray(resp?.entites) ? resp?.entites.map((x) => describeFeaturesAccess(x)) : null);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleDeploymentOnSelect = (deployment) => setSelectedDeployment(deployment ?? null);

  const handleAccessTypeOnChange = (value) => setSelectedAccessType(value ?? null);

  const handleOnFeaturePick = (feature) => setSelectedFeature(feature ?? null);

  return (
    <>
      <PageLayoutType1
        left={
          <DraggableLayoutItem title='Filters'>
            <div className='flex flex-col gap-4'>
              <div>
                <div>Feature</div>
                <div>
                  <FeaturePicker width='100%' onPick={handleOnFeaturePick} placeholder='[all features]' />
                </div>
              </div>
              <div>
                <div>Access type</div>
                <Select className='w-full' onChange={handleAccessTypeOnChange} options={accessFilterOptions} allowClear placeholder='[any access]' />
              </div>
              <div>
                <div>Tenant</div>
                <DeploymentPicker onSelect={handleDeploymentOnSelect} placeholder='[all tenats]' />
              </div>
            </div>
          </DraggableLayoutItem>
        }
        right={
          <DraggableLayoutItem title='Data'>
            <CondensedTable
              pagination={{
                showSizeChanger: true,
                pageSizeOptions,
                size: 'small',
                onShowSizeChange: (current, size) => {
                  console.log('onShowSizeChange', current, size);
                  setPageSize(size);
                },
                current: page,
                pageSize,
                total,
                onChange: (page) => setPage(page),
              }}
              columns={columns}
              dataSource={entites}
              onRow={(record) => {
                return {
                  onClick: async () => {
                    window.open(`/master-admin/tenants?tenantId=${record.tenant_id}&tab=features-access`, '_blank');
                  },
                  style: { cursor: 'pointer' },
                };
              }}
              rowKey={() => uuid()}
            />
          </DraggableLayoutItem>
        }
      />
    </>
  );
};

FeaturesAccessList.propTypes = {};

export default FeaturesAccessList;
