import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import { getJsonFile } from 'src/api/dataSubjectRequests';
import { Spin } from 'antd';
import ReactJson from 'react-json-view';

const JsonViewer = ({ fileUrl }) => {
  const [content, setContent] = useState(null);
  const { isDarkMode, theme } = useTheme();

  useEffect(() => {
    if (fileUrl) loadData();
  }, [fileUrl]);

  const loadData = async () => {
    console.log('loadData()');
    try {
      const resp = await getJsonFile(fileUrl);
      console.log(resp);
      setContent(resp);
    } catch (error) {
      console.error(error);
    }
  };

  if (!content)
    return (
      <div className='center'>
        <Spin size='small' tip='Loading content...' />
      </div>
    );

  return (
    <div>
      <ReactJson style={{ padding: '12px', border: `0.5px solid ${theme.borderLight}`, borderRadius: '0.25rem', fontSize: '0.7rem' }} theme={isDarkMode ? 'shapeshifter' : 'shapeshifter:inverted'} src={content ?? {}} iconStyle='square' enableClipboard={false} enableAdd={false} enableEdit={false} enableDelete={false} displayDataTypes={false} displayObjectSize={false} />
    </div>
  );
};

JsonViewer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};

JsonViewer.defaultProps = {};

export default JsonViewer;
