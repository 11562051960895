import React, { useEffect, useState } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import { useNavigate } from 'react-router-dom';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { TbReportMoney } from 'react-icons/tb';
import { Space, Button } from 'antd';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import ProjectListWidget from './Components/ProjectListWidget';
import StatsWidget from './Components/StatsWidget';
import { MdAdd } from 'react-icons/md';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from './Misc/misc';
import ProjectListSearch from './Components/ProjectListSearch';

const Home = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const navigate = useNavigate();

  const [layoutItemsSelector, setLayoutItemsSelector] = useState(null);

  const componentsGallery = [
    {
      id: 'Projects',
      col: 0,
      component: (
        <DraggableLayoutItem
          titleExtras={
            <Button
              type='primary'
              size='small'
              onClick={() => {
                navigate('/fsi-report/new-project');
              }}
            >
              <Space className='btn'>
                <MdAdd />
                <WhenDesktop>New project</WhenDesktop>
              </Space>
            </Button>
          }
          startExtras={<ProjectListSearch />}
          title='Projects'
          centered
        >
          <ProjectListWidget />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Stats',
      col: 1,
      component: (
        <DraggableLayoutItem title='Stats' centered>
          <StatsWidget />
        </DraggableLayoutItem>
      ),
    },
  ];

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'FSI Report',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['apps.fsireport.view']}>
      <TitleBar
        title={
          <Space className='btn'>
            <TbReportMoney />
            FSI Report
          </Space>
        }
        afterTitleExtras={
          <div>
            <PageSwitch pageList={pageList} />
          </div>
        }
      >
        {layoutItemsSelector}
      </TitleBar>
      <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={2} mainColumnIndex={0} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutFsiReport} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutFsiReportHidden} />
    </PermissionsProvider>
  );
};

export default Home;
