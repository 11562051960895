import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Dropdown, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { searchRoles } from 'src/api/accesscontrol';
import { DEFAULT_ROLE } from 'src/misc/Config';
import CondensedTable from 'src/components/layout/CondensedTable';
import { Space } from 'antd';
import { FaChevronDown } from 'react-icons/fa';
import useTheme from 'src/hooks/useTheme';
import Highlighter from 'react-highlight-words';
import TitleBar from 'src/components/layout/TitleBar';

const AssignmentsRoleSelector = ({ onRoleSelected }) => {
  const { theme } = useTheme();
  const columns = [
    {
      title: 'Role',
      key: 'role',
      //render: (record) => <>{record.role}</>,
      render: (record) => (
        <span className={record.role === selectedRole ? 'font-bold' : null}>
          <Highlighter textToHighlight={record.role ?? ''} searchWords={[`${query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
        </span>
      ),
    },
    {
      title: 'Description',
      key: 'description',
      render: (record) => <div className='text-xs font-light opacity-60' style={{ textWrap: 'pretty' }} dangerouslySetInnerHTML={{ __html: record.description }} />,
    },
  ];

  const pageSizeOptions = [20, 50, 100];

  const [roles, setRoles] = useState(null);
  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[0]));
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    setPage(1);
    setTotal(0);
    setRoles(null);
    setSelectedRole(null);
    loadData();
  }, [query, pageSize]);

  useEffect(() => {
    setSelectedRole(null);
    loadData();
  }, [page]);

  useEffect(() => {
    if (onRoleSelected) onRoleSelected(roles?.find((x) => x.role === selectedRole));
  }, [selectedRole]);

  const loadData = async () => {
    setLoading(true);
    try {
      const q = query?.trim() ?? null;
      const resp = await searchRoles(q?.length > 0 ? q : null, page, pageSize);
      setTotal(resp?.total ?? 0);
      setRoles(resp.entities);
    } catch (error) {
      if (error?.toString().includes('cancelled')) return;
      console.log(error);
    }
    setLoading(false);
  };

  const handleOnRow = (record) => {
    return {
      style: { cursor: 'pointer', background: record.role === selectedRole ? theme.backgroundLight : null },
      onClick: () => {
        if (DEFAULT_ROLE === record.role) {
          setSelectedRole(null);
          return;
        }
        setSelectedRole(record.role);
      },
    };
  };

  return (
    <div className='flex flex-col h-full max-h-full w-full overflow-hidden'>
      <TitleBar title='' isSticky={false} busyIndicator={false} isLoading={loading} colorBackground={theme.backgroundBase}>
        <div className='flex flex-row justify-end gap-4'>
          <div className='flex flex-row justify-end items-center'>
            <Dropdown
              trigger={['click']}
              menu={{
                activeKey: pageSize,
                items: pageSizeOptions.map((x) => {
                  return { key: x, label: x };
                }),
                onClick: (e) => {
                  console.log(e);
                  setPageSize(parseInt(e.key));
                },
              }}
              className='text-xs cursor-pointer'
            >
              <Space className='opacity-40 hover:opacity-100 btn'>
                <span>page size {pageSize}</span>
                <FaChevronDown />
              </Space>
            </Dropdown>
          </div>
          <Input
            size='small'
            className='w-36'
            placeholder='Search...'
            prefix={loading ? <Spin size='small' className='mr-2' /> : <SearchOutlined className='mr-2' />}
            allowClear
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </div>
      </TitleBar>
      <div className='flex-auto overflow-auto'>
        <CondensedTable
          pagination={{
            showSizeChanger: true,
            pageSizeOptions,
            size: 'small',
            onShowSizeChange: (current, size) => {
              console.log('onShowSizeChange', current, size);
              setPageSize(size);
            },
            current: page,
            pageSize,
            total,
            onChange: (page) => setPage(page),
          }}
          columns={columns}
          dataSource={roles}
          rowKey={'role'}
          onRow={handleOnRow}
        />
      </div>
    </div>
  );
};

AssignmentsRoleSelector.propTypes = {
  onRoleSelected: PropTypes.func,
};

export default AssignmentsRoleSelector;
