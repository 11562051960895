import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PieChart } from '@mui/x-charts/PieChart';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useTheme from 'src/hooks/useTheme';
import { getVectorEmbeddings } from 'src/api/securityqa';
import { Modal, Table } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Spin, Dropdown, Space, Button } from 'antd';

import { DownOutlined } from '@ant-design/icons';

const GraphVectorSources = () => {
  const { isDarkMode, theme } = useTheme();
  const [graphData, setGraphData] = useState(undefined);
  const [allData, setAllData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [documentsDetails, setDocumentsDetails] = useState(false);
  const [availableModels, setAvailableModels] = useState([]);
  const [activeModel, setActiveModel] = useState('');

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  const refContainer = useRef();

  useEffect(() => {
    const handleResize = () => {
      if (refContainer.current) {
        setDimensions({
          width: refContainer.current.offsetWidth,
          height: refContainer.current.offsetHeight,
        });
      }
    };

    handleResize(); // Initial resize
    window.addEventListener('resize', handleResize); // Add event listener for resize
    return () => {
      window.removeEventListener('resize', handleResize); // Clean up event listener on component unmount
    };
  }, [documentsDetails]);

  const themeMode = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  const columns = [
    {
      title: 'Source',
      dataIndex: 'label',
      key: 'source',
    },
    {
      title: 'Documents',
      dataIndex: 'value',
      key: 'documents',
      width: 100,
      sorter: (a, b) => a.value - b.value,
    },
  ];

  //console.log('userInfo', userInfo);

  const loadData = async () => {
    try {
      const resp = await getVectorEmbeddings();

      setAllData(resp);
      setAvailableModels(resp.models);
      setActiveModel(resp.models[1]);

      const newData = { ...resp };
      newData.data = newData.data.filter((item) => item.model_id === resp.models[1].key);

      // Prepare data for the table
      const dataSource = newData.data.map((item, index) => {
        return {
          key: index,
          label: item.label,
          value: item.value,
        };
      });
      setGraphData(newData);
      setDocumentsDetails(dataSource);
    } catch (error) {
      console.error(error);
    }
  };

  const updateGraphData = (modelId) => {
    try {
      console.log('updateGraphData()', modelId);

      setActiveModel(availableModels.find((item) => item.key === modelId));

      const newData = { ...allData };
      console.log('newData', newData);
      newData.data = newData.data.filter((item) => item.model_id === modelId);
      setGraphData(newData);

      console.log('newData updated. Now prepare docs');
      // Prepare data for the table
      const dataSource = newData.data.map((item, index) => {
        return {
          key: index,
          label: item.label,
          value: item.value,
        };
      });

      setDocumentsDetails(dataSource);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!graphData) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuOnClick = (e) => {
    console.log('handleMenuOnCklick()', e);
    if (!e.key) return;
    if (!allData) return;
    if (!availableModels) return;

    updateGraphData(e.key);
  };

  return (
    <ThemeProvider theme={themeMode}>
      <div ref={refContainer}>
        {!graphData ? (
          <div className='text-center mt-10'>
            <Spin />
          </div>
        ) : (
          <>
            <div className='text-center'>
              <Dropdown menu={{ items: availableModels, onClick: handleMenuOnClick }} trigger={['click']}>
                <Button type='link' style={{ color: theme.textBase }}>
                  <Space>
                    {activeModel?.label}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </div>
            <div>
              <PieChart
                colors={['#76b7b2', 'rgb(251 113 133)', '#f28e2c', '#59a14f', '#edc949', '#af7aa1', '#ff9da7', '#9c755f', '#bab0ab', '#adadad']}
                title='TRest'
                tooltip={{ trigger: 'none' }}
                series={[
                  {
                    arcLabel: (item) => `${item.value > 1000 ? Math.floor(item.value / 1000) + 'k' : item.value}`,
                    arcLabelMinAngle: 25,
                    data: graphData?.data || [],
                    innerRadius: 20,
                    outerRadius: 70,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    cy: 100,
                    cx: dimensions.width / 2,
                  },
                ]}
                height={300}
                slotProps={{
                  legend: {
                    hidden: false,
                    position: { vertical: 'bottom', horizontal: 'center' },
                    direction: 'row',
                    itemMarkWidth: 10,
                    itemMarkHeight: 10,
                    labelStyle: {
                      fontFamily: 'Nunito',
                      fontSize: 12,
                    },
                  },
                }}
              />
            </div>
            <div className='text-center pb-2'>
              <Button style={{ color: theme.textBase }} className='opacity-80' size='small' onClick={() => setShowDetails(true)}>
                <QuestionCircleOutlined />
                Counters
              </Button>
            </div>
            <div className='opacity-80 text-center font-nunito'>
              {/* rows: {graphData?.count / 1000}k / documents: {graphData?.data.reduce((acc, item) => acc + parseInt(item.value), 0)} */}
              documents: {graphData?.data.reduce((acc, item) => acc + parseInt(item.value), 0)}
            </div>
            <div className='opacity-80 text-center font-nunito text-sm'>last vector (all models): {dayjs(graphData?.lastVector).fromNow()}</div>
          </>
        )}
      </div>
      <Modal
        open={showDetails}
        title={
          <>
            Documents count for <span className=' text-green-600'>{activeModel?.label}</span>
          </>
        }
        onCancel={() => {
          setShowDetails(false);
        }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <div>
          <Table pagination={false} size='small' dataSource={documentsDetails} columns={columns} />
        </div>
      </Modal>
    </ThemeProvider>
  );
};

GraphVectorSources.propTypes = {
  tickets: PropTypes.array,
};

export default GraphVectorSources;
