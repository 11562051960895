import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';
import ItemModal from './ItemModal';

// import { TbWorldDownload } from 'react-icons/tb';

import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import styled from 'styled-components';
import { patchItem } from 'src/api/download';
import NoData from 'src/components/layout/NoData';

const StyledDiv = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* or any specific height you need */
  }

  .details {
    margin-top: auto;
  }
`;

const Item = ({ sectionId, item, onDeleteItem }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(undefined);
  const { executeAsyncProcess } = useAsyncProcesses();

  useEffect(() => {
    setCurrentItem(item);
  }, [item]);

  const disabledRibbon = (control, isEnabled) => {
    if (!isEnabled)
      return (
        <Badge.Ribbon text='Disabled' color='red'>
          {control}
        </Badge.Ribbon>
      );
    return control;
  };

  const handleEditItem = (values) => {
    console.log('[Item] handleEditItem:', values);

    delete values.id;

    executeAsyncProcess(async () => {
      try {
        const resp = await patchItem(sectionId, item.id, values);
        setCurrentItem(resp);
      } catch (error) {
        console.log(error);
      }
    });
    //setCurrentItem({ ...currentItem, ...values });

    setModalOpen(false);
  };

  const handleDeleteItem = (id) => {
    console.log('[Item] handleDeleteItem:', id);

    if (onDeleteItem) onDeleteItem(id);

    setModalOpen(false);
  };

  const setPicture = (picId) => {
    const base64Image = 'data:image/png;base64,' + picId;
    return picId ? <img src={base64Image} alt='logo' className='w-14 h-14 object-contain' /> : <NoData label='' />;
  };

  return (
    <StyledDiv>
      <div className={'container mt-4 hover:scale-105 hover:opacity-100 hover:shadow-md transition-all '}>
        {currentItem &&
          disabledRibbon(
            <div
              className={'flex flex-col gap-1 justify-start items-center cursor-pointer opacity-80 min-h-40 w-36 h-40  px-1 py-3 dark:border-slate-400 border-solid border rounded-md'}
              onClick={() => {
                setModalOpen(true);
              }}
            >
              <div className='text-center'>{setPicture(currentItem.icon)}</div>
              <div className='pt-1 text-xs text-center overflow-hidden w-16'>{currentItem.title}</div>
              <div className='details'>
                {/* <hr className='w-full text-gray-700' /> */}
                <div className='text-gray-500 text-xs text-center'>Files: {currentItem.files.length}</div>
              </div>
            </div>,
            currentItem.is_active
          )}
      </div>
      {modalOpen && <ItemModal item={currentItem} open={modalOpen} editMode={true} onSubmit={handleEditItem} onClose={() => setModalOpen(false)} onDelete={handleDeleteItem} />}
    </StyledDiv>
  );
};

Item.propTypes = {
  sectionId: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  onDeleteItem: PropTypes.func,
};

export default Item;
