import { FaHome } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';

export const pageList = [
  {
    path: '/fsi-report',
    label: 'Home',
    icon: <FaHome />,
    permissions: ['apps.fsireport.view'],
  },
  {
    path: '/fsi-report/new-project',
    label: 'New project',
    icon: <MdAdd />,
    permissions: ['apps.fsireport.view'],
  },
];

export const LANGS_MAX_NUMBER = 2;
