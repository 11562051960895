import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Modal, Button, Input, Checkbox } from 'antd';

const ConversationHistoryItemEdit = ({ title, item, isVisible, onClose, onSave, busy }) => {
  const [newValue, setNewValue] = useState(null);
  const [checkedInternal, setCheckedInternal] = useState(true);

  const { TextArea } = Input;

  useEffect(() => {
    console.log('useEffect', item);
    setNewValue(item.text);
    setCheckedInternal(item.visibility === 'internal' ? true : false);
  }, [item.text]);

  const handleModalOnSave = async () => {
    console.log('handleModalOnSave');
    if (onSave) onSave(newValue, checkedInternal ? 'internal' : 'external');
  };

  const isSaveDisabled = () => {
    return newValue === item.text && checkedInternal === (item.visibility === 'internal') ? true : false;
  };

  const isCancelDisabled = () => {
    return false;
  };

  const changeInternalHandle = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setCheckedInternal(e.target.checked);
  };

  return (
    <Modal
      closable={false}
      open={isVisible}
      title={title}
      footer={[
        <div className={`flex flex-auto gap-2 ${busy ? 'disabled' : ''}`}>
          <div className='flex flex-auto text-left mb-2'>
            <Checkbox checked={checkedInternal} disabled={busy} onChange={changeInternalHandle}>
              Internal
            </Checkbox>
          </div>

          <div className='flex  text-right'>
            <Button key='Save' onClick={handleModalOnSave} type='primary' disabled={isSaveDisabled()} loading={busy}>
              Save
            </Button>
            <Button key='Cancel' onClick={onClose} className='ml-2' disabled={isCancelDisabled()}>
              Cancel
            </Button>
          </div>
        </div>,
      ]}
    >
      <TextArea
        placeholder='Enter a value'
        disabled={busy}
        multiline
        rows={10}
        value={newValue}
        showCount
        onChange={(e) => {
          setNewValue(e.target.value);
        }}
        maxLength={5000}
      />
      {!checkedInternal ? (
        <div className='text-center text-sm mt-2 text-gray-400 '>
          <span>Message will be visible to everyone. </span>
          <br />
          <span>Cannot be changed once saved.</span>
        </div>
      ) : (
        <p className='pb-4'> </p>
      )}
    </Modal>
  );
};

ConversationHistoryItemEdit.propTypes = {
  title: PropTypes.string,
  item: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
};

export default ConversationHistoryItemEdit;
