import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);

export const getBrowserTimeZone = () => {
  let tz = dayjs.tz.guess(true);
  // console.log('[Timezone] Browser timezone determined: ', tz);
  if (!tz) {
    console.warn('[Timezone] Could not determine a browser timezone');
    return 'UTC';
  }
  return tz;
};
