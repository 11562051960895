import React, { useState, useEffect } from 'react';
import { Button, Form, Input, notification, Checkbox, Space, Popover, Spin, Tooltip, Tag } from 'antd';
import PropTypes from 'prop-types';
import { getSection, postItem, deleteItem } from 'src/api/download';
import Item from './Item';
import useTheme from 'src/hooks/useTheme';
import { FaPlus } from 'react-icons/fa6';
import TitleBar from 'src/components/layout/TitleBar';
import { LuMenu } from 'react-icons/lu';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import { MdDelete } from 'react-icons/md';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import ItemModal from './ItemModal';

const SectionForm = ({ sectionId, onSubmit, onDelete, onRefresh }) => {
  const [form] = Form.useForm();
  const { theme } = useTheme();
  const [itemStatus, setItemStatus] = useState(true);
  const [items, setItems] = useState([]);
  const [filesModalOpen, setFilesModalOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const { executeAsyncProcess } = useAsyncProcesses();

  const contentStyle = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  };
  const content = <div style={contentStyle} />;

  useEffect(() => {
    form.resetFields();
    if (sectionId) {
      console.log('SectionForm: sectionId:', sectionId);
      loadData();
    }
  }, [form, sectionId]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        setBusy(true);
        const resp = await getSection(sectionId);
        console.log('resp.download_items:', resp.download_items);
        setItems(resp.download_items || []);
        setItemStatus(resp.is_active);

        form.setFieldsValue(resp);
      } catch (error) {
        console.log(error);
      } finally {
        setBusy(false);
      }
    });
  };

  const onChangeStatus = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setItemStatus(e.target.checked);
    form.setFieldsValue({ is_active: e.target.checked });
  };

  const handleOnFinish = (values) => {
    console.log('Success:', values);
    if (onSubmit) onSubmit(values);
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: 'Error',
      description: <>Provided data is invalid</>,
      duration: 5,
    });
  };

  const handleAddNewItem = (values) => {
    console.log('[SectionForm] Success:', values);

    delete values.id; // remove id from values

    executeAsyncProcess(async () => {
      try {
        const resp = await postItem(sectionId, values);
        console.log('create new item:', resp);
        setItems((items) => [...items, resp]);
        setFilesModalOpen(false);
        if (onRefresh) onRefresh();
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleDeleteItem = (id) => {
    console.log('[SectionForm] handleDeleteItem:', id);

    executeAsyncProcess(async () => {
      try {
        await deleteItem(sectionId, id);
        setItems((items) => items.filter((item) => item.id !== id));
        if (onRefresh) onRefresh();
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handlePermanentlyDelete = () => {
    console.log('[SectionForm] handlePernamentlyDelete');
    if (onDelete) onDelete(sectionId);
  };

  const handleButtonAddFile = () => {
    setFilesModalOpen(true);
  };

  return (
    <>
      {busy ? (
        <div className='flex justify-center items-center h-full'>
          <Spin tip='Loading'>{content}</Spin>
        </div>
      ) : (
        <>
          <TitleBar busyIndicator={false} colorBackground={theme.backgroundBase} title={form.getFieldValue('title')} afterTitleExtras={<></>}>
            <Popover
              content={
                <Space direction='vertical'>
                  <YesNoDialog
                    title='Permanently delete'
                    body={
                      <>
                        Do you want to delete <strong>{form.getFieldValue('title')}</strong> ?
                      </>
                    }
                    onYesClick={handlePermanentlyDelete}
                    iconYes={<MdDelete />}
                    showRed={true}
                    labelYes='Yes, delete this Item'
                    labelNo='Cancel'>
                    <Button className='w-[200px]' color={theme.textBase}>
                      Permanently delete
                    </Button>
                  </YesNoDialog>
                </Space>
              }
              title='Actions'
              trigger='click'
              placement='bottomRight'>
              <Button type='text' icon={<LuMenu />} />
            </Popover>
          </TitleBar>

          <div className={'bg-white m-4 rounded-2xl shadow-md p-4 border border-solid border-zinc-300 dark:bg-zinc-900 dark:border-zinc-700'}>
            <div className='flex flex-auto'>
              <div className='flex flex-auto text-xl font-bold mb-2 text-left'>Section Details</div>
              <div className='flex-flex-auto text-right'>
                <Tag>features.download.section.{sectionId}</Tag>
              </div>
            </div>
            <Form form={form} name='sectionForm' layout='vertical' onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} autoComplete='off'>
              <Space>
                <div className='flex gap-4'>
                  <Form.Item
                    label='Title'
                    name='title'
                    rules={[{ required: true, message: "Value can't be empty" }]} // todo: for some reason regex validation doesnt work :-(
                    className='flex-grow mb-4'>
                    <Input placeholder='enter section name' autoComplete='off' />
                  </Form.Item>

                  <Form.Item label='Subtitle' name='subtitle' className='flex mb-4' style={{ minWidth: '150px', flex: 1 }}>
                    <Input placeholder='downloads' autoComplete='off' />
                  </Form.Item>
                </div>
              </Space>
              <Form.Item
                label=''
                name='is_active'
                style={{ marginBottom: '12px' }} // Reduced margin
              >
                <Checkbox checked={itemStatus} onChange={onChangeStatus}>
                  Is active
                </Checkbox>
              </Form.Item>

              <Button className={'flex flex-auto text-right'} type='primary' htmlType='submit'>
                Update Section
              </Button>
            </Form>
          </div>
          <div className={'bg-white m-4 rounded-2xl shadow-md p-4 border border-solid border-zinc-300 dark:bg-zinc-900 dark:border-zinc-700'}>
            <div className='text-xl font-bold mb-2'>Configured Items</div>
            <div className='flex flex-row gap-4 p-4 flex-wrap  content-center justify-center'>
              {items?.map((item, index) => {
                return (
                  <div key={index}>
                    <Item sectionId={sectionId} item={item} onDeleteItem={handleDeleteItem} />
                  </div>
                );
              })}
              <Tooltip title='Add new item'>
                <Button onClick={handleButtonAddFile} className='mt-4'>
                  <FaPlus />
                </Button>
              </Tooltip>
            </div>
          </div>
          {filesModalOpen && <ItemModal open={filesModalOpen} onClose={() => setFilesModalOpen(false)} onSubmit={handleAddNewItem} />}
        </>
      )}
    </>
  );
};

SectionForm.propTypes = {
  sectionId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  onRefresh: PropTypes.func,
};

SectionForm.defaultProps = {
  sectionId: null,
};

export default SectionForm;
