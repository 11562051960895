import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import GlobalContext from 'src/context/GlobalContext';
import { Button } from 'antd';
import { BsShift, BsCommand } from 'react-icons/bs';

const CmdBtn = ({ children, style, className, type = 'default', onClick, onClickCommandPressed, onClickShiftPressed, onClickCommandShiftPressed }) => {
  const [hovered, setHovered] = useState(false);
  const { commandPressed, shiftPressed } = useContext(GlobalContext);

  const handleOnMouseEnter = () => {
    if (hovered) return;
    setHovered(true);
  };
  const handleOnMouseLeave = () => {
    if (!hovered) return;
    setHovered(false);
  };

  const handleOnClick = async () => {
    console.log('handleOnClick', commandPressed, shiftPressed);
    if (commandPressed && shiftPressed && onClickCommandShiftPressed) await onClickCommandShiftPressed();
    else if (commandPressed && onClickCommandPressed) await onClickCommandPressed();
    else if (shiftPressed && onClickShiftPressed) await onClickShiftPressed();
    else if (onClick) onClick();
  };

  return (
    <div className='relative' onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      {hovered && (
        <div className='z-10 absolute top-0 right-0 mt-[-10px] pointer-events-none flex flex-row gap-1 bg-white/50 dark:bg-black/50 rounded-sm px-1'>
          {shiftPressed && <BsShift className='relative text-sm text-genesys-orange-base animate-appear3' />}
          {commandPressed && <BsCommand className='relative text-sm text-genesys-orange-base animate-appear3' />}
        </div>
      )}

      <Button type={type} className={className} style={style} onClick={handleOnClick}>
        {children}
      </Button>
    </div>
  );
};

CmdBtn.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.string,
  maxWidth: PropTypes.string,
  onClick: PropTypes.func,
  onClickCommandPressed: PropTypes.func,
  onClickShiftPressed: PropTypes.func,
  onClickCommandShiftPressed: PropTypes.func,
};

export default CmdBtn;
