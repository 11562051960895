import React, { useContext, useEffect, useState } from 'react';
//import PropTypes from 'prop-types';
import GlobalContext from 'src/context/GlobalContext';
import { TbLockAccess } from 'react-icons/tb';
import { Popover } from 'antd';
import * as dayjs from 'dayjs';
import DisplayObjectProps from 'src/components/layout/DisplayObjectProps';
import { describeFeaturesAccess } from './Misc';

const FeaturesAccessIcon = () => {
  const { featuresAccess: contextFeaturesAccess, currentFeature, currentFeatureDisplayName, getFeatureAccess } = useContext(GlobalContext);

  const [featureAccess, setFeatureAccess] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (!currentFeature) {
      setFeatureAccess(null);
      return;
    }
    const fetchData = async () => {
      let data = await getFeatureAccess(currentFeature);
      if (data) data = describeFeaturesAccess(data);
      setFeatureAccess(data);
    };
    fetchData();
  }, [currentFeature, contextFeaturesAccess]);

  if (!currentFeature || !featureAccess) return null;

  const getIconClasses = () => {
    if (!featureAccess) return 'text-gray-500';
    if (featureAccess.paidAccessHasAccess) return 'text-green-500';
    if (featureAccess.freeTrialHasAccess) return 'text-orange-500';
    return 'text-red-500';
  };

  return (
    <div className='h-full cursor-pointer hover:scale-110 flex flex-row justify-center items-center'>
      {/* <Button type='text'> */}
      {/* <div className='flex flex-row items-center justify-center'> */}
      <Popover
        title={currentFeatureDisplayName || currentFeature}
        content={
          <div className='flex flex-col gap-4'>
            <div>
              <div className='font-bold mb-1 text-genesys-azure-700 dark:text-genesys-azure-500'>Free trial</div>
              <div className='ml-2'>
                <DisplayObjectProps
                  className='opacity-60'
                  obj={{
                    state: featureAccess.freeTrialDesc,
                    from: featureAccess?.free_trial_from ? dayjs(featureAccess?.free_trial_from).format('LLL') : null,
                    to: featureAccess?.free_trial_to ? dayjs(featureAccess?.free_trial_to).format('LLL') : null,
                  }}
                />
              </div>
            </div>
            <div>
              <div className='font-bold mb-1 text-genesys-azure-700 dark:text-genesys-azure-500'>Full access</div>
              <div className='ml-2'>
                <DisplayObjectProps
                  obj={{
                    state: featureAccess.paidAccessDesc,
                    from: featureAccess?.paid_access_from ? dayjs(featureAccess?.paid_access_from).format('LLL') : null,
                    to: featureAccess?.paid_access_to ? dayjs(featureAccess?.paid_access_to).format('LLL') : null,
                  }}
                />
              </div>
            </div>
          </div>
        }
        trigger='click'
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
      >
        <TbLockAccess className={`text-lg ${getIconClasses()}`} />
      </Popover>
      {/* </div> */}
      {/* </Button> */}
    </div>
  );
};

// FeaturesAccessIcon.propTypes = {};

export default FeaturesAccessIcon;
