import React, { useEffect, useContext } from 'react';
import MasterAdminContext from 'src/context/MasterAdminContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { AiFillNotification } from 'react-icons/ai';
import Space2 from 'src/components/layout/Space2';
import { FaPlus } from 'react-icons/fa6';
import StyledButton from 'src/components/layout/StyledButton';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import useTheme from 'src/hooks/useTheme';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import AdminMsgsFilter from './Components/AdminMsgsFilter';
import AdminMsgsSelector from './Components/AdminMsgsSelector';
import AdminMsgsEditor from './Components/AdminMsgsEditor';
import PageLayoutType2 from 'src/components/layout/PageLayoutType2';
import PermissionsProvider from 'src/providers/PermissionsProvider';

//import PropTypes from 'prop-types';

const AdminMessages = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { adminMessagesSelectedId, setAdminMessagesNewCreated } = useContext(MasterAdminContext);
  const { theme } = useTheme();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'Admin Messages',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const handleOnCreateMsg = () => {
    console.log('handleOnCreateMsg()');
    setAdminMessagesNewCreated(true);
  };

  return (
    <PermissionsProvider requiredPermissions={['admin.master']}>
      <TitleBar
        title={
          <Space2>
            <AiFillNotification />
            Admin Messages
          </Space2>
        }
        afterTitleExtras={
          <StyledButton color={theme.textBase} onClick={handleOnCreateMsg} size='small'>
            <Space2 className='btn'>
              <FaPlus />
              <WhenDesktop>Create message</WhenDesktop>
            </Space2>
          </StyledButton>
        }
      />
      <PageLayoutType2
        left={
          <DraggableLayoutItem title='Message List' titleExtras={<AdminMsgsFilter />}>
            <AdminMsgsSelector />
          </DraggableLayoutItem>
        }
        right={
          <DraggableLayoutItem title={adminMessagesSelectedId ? 'Details' : null} titleExtras={<span className='text-xs font-light'>{adminMessagesSelectedId}</span>}>
            <AdminMsgsEditor />
          </DraggableLayoutItem>
        }
      />
    </PermissionsProvider>
  );
};

// AdminMessages.propTypes = {};

export default AdminMessages;
