import React, { useEffect, useState, useContext } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import useUserInfo from 'src/hooks/useUserInfo';
import AuditViewerContext from 'src/context/AuditViewerContext';
import { postSubscribe } from 'src/api/subscriptions';
// import { putEventsFilter, deleteEventsFilter } from 'src/api/securityAdvisor';
import { AV_APP_ID } from '../../Misc/misc';
import { Modal, Button, Input, Select, notification, Alert, Result } from 'antd';
import { notEmptyString } from 'src/misc/Misc';
//import EventsFilter from './EventsFilter';

//import PropTypes from 'prop-types';

const SubscriptionAddNew = () => {
  const userInfo = useUserInfo();
  const { executeAsyncProcess } = useAsyncProcesses();
  const { setHasCurrentUserSubscribed, snsSubscriptionsAddNew, setSnsSubscriptionsAddNew, subscriptions, setSubscriptions } = useContext(AuditViewerContext);

  const protocolOptions = [{ value: 'email', label: 'Email' }];

  const [newProtocol, setNewProtocol] = useState(protocolOptions[0].value);
  const [newEndpoint] = useState(userInfo.email);
  const [showResultModal, setShowResultModal] = useState(false);

  useEffect(() => {
    setNewProtocol(protocolOptions[0].value);
  }, [snsSubscriptionsAddNew]);

  const handleNewSubscription = async () => {
    setSnsSubscriptionsAddNew(false);
    if (true === subscriptions?.some((x) => x.Protocol === newProtocol && x.Endpoint === newEndpoint)) {
      notification.error({
        message: 'Error',
        description: 'Subscription already exists',
        duration: 5,
      });
      return;
    }
    await executeAsyncProcess(async () => {
      try {
        // <post subscription>
        await postSubscribe(AV_APP_ID, newProtocol, newEndpoint);
        // notification.success({
        //   message: 'Success',
        //   description: 'New subscription has been created',
        //   duration: 5,
        // });
        const s = [...subscriptions];
        s.unshift({ SubscriptionArn: 'PendingConfirmation', Protocol: newProtocol, Endpoint: newEndpoint, justCreated: true });
        setSubscriptions(s);
        // </post subscription>

        // <check if user is subscribed>
        setHasCurrentUserSubscribed(true === s.some((x) => x.Protocol === newProtocol && newEndpoint?.toLowerCase() === userInfo.email?.toLowerCase()));
        // </check if user is subscribed>

        setShowResultModal(true);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleNewSubscriptionCancel = () => {
    setSnsSubscriptionsAddNew(false);
  };

  return (
    <>
      <Modal
        title='Add new email subscription'
        closable={false}
        open={snsSubscriptionsAddNew}
        forceRender={true}
        footer={[
          <Button key='btnYes' type='primary' className='btn-label' onClick={handleNewSubscription} disabled={false === notEmptyString(newProtocol) || false === notEmptyString(newEndpoint)}>
            Submit
          </Button>,
          <Button key='btnNo' className='btn-label' onClick={handleNewSubscriptionCancel}>
            Cancel
          </Button>,
        ]}>
        {snsSubscriptionsAddNew && (
          <>
            {newProtocol === 'email' && <Alert message='Confirmation required' description='In order to receive notifications, a user must confirm their subscription first. A confirmation email will be sent to the provided email address.' type='info' showIcon />}
            <div className='mt-4'>
              <div>Channel</div>
              <Select className='w-full' options={protocolOptions} value={newProtocol} onChange={(e) => setNewProtocol(e)} />
              <div>Address</div>
              <Input value={newEndpoint} />
            </div>
            <div className='mt-8'>
              {/* <EventsFilter
                addingSubscription={true}
                onChange={(e) => {
                  setNewEventFilters(e);
                }}
              /> */}
            </div>
          </>
        )}
      </Modal>
      <Modal
        open={showResultModal}
        closable={false}
        footer={[
          <Button key='btnYes' type='primary' className='btn-label' onClick={() => setShowResultModal(false)}>
            Close
          </Button>,
        ]}>
        <Result status='success' title='Email subscription has been added' subTitle='Please check your mailbox and confirm the subscription' />
      </Modal>
    </>
  );
};

SubscriptionAddNew.propTypes = {};

export default SubscriptionAddNew;
