import React, { useContext } from 'react';
import FsiReportContext from 'src/context/FsiReportContext';
//import PropTypes from 'prop-types';

const WelcomeStep = () => {
  const { createNewSelectedAccount, createNewLanguages } = useContext(FsiReportContext);

  return (
    <div>
      <div>
        <span className='font-light'>Selected account name:</span> <span className='font-bold'>{createNewSelectedAccount?.ACCOUNT_NAME}</span>
      </div>
      <div>
        <span className='font-light'>Selected account id:</span> <span className='font-bold'>{createNewSelectedAccount?.ACCOUNT_ID}</span>
      </div>
      <div>
        <span className='font-light'>Selected languages:</span> <span className='font-bold'>{createNewLanguages?.join(', ')}</span>
      </div>
      <div className='mt-4'>
        Click the <strong>Finish</strong> button to create the project
      </div>
    </div>
  );
};

WelcomeStep.propTypes = {};

export default WelcomeStep;
