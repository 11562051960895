import React from 'react';
import { Modal, Button, Space, Form, Input, notification } from 'antd';
import PropTypes from 'prop-types';

const SectionCreate = ({ open, onClose, onSubmit }) => {
  const handleOnFinish = (values) => {
    console.log('Success:', values);
    if (onSubmit) onSubmit(values);
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: 'Error',
      description: <>Provided data is invalid</>,
      duration: 5,
    });
  };

  const handleOnReset = () => {
    //setModified(false);
  };

  return (
    open && (
      <Modal
        open={open}
        title='Create new section'
        footer={null}
        onCancel={() => {
          if (onClose) onClose();
        }}
      >
        <Form name='sectionCreate' style={{ maxWidth: 600 }} layout='vertical' initialValues={{}} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} onReset={handleOnReset} autoComplete='off'>
          <Form.Item
            label='Title'
            name='title'
            rules={[{ required: true, message: "Value can't be empty" }]} // todo: for some reason regex validation doesnt work :-(
          >
            <Input placeholder='enter section name' autoComplete='off' />
          </Form.Item>
          <Form.Item label='Subtitle' name='subtitle'>
            <Input placeholder='downloads' autoComplete='off' />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    )
  );
};

SectionCreate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

SectionCreate.defaultProps = {
  open: false,
};

export default SectionCreate;
