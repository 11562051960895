import React from 'react';
import { Space } from 'antd';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import TicketProperties from 'src/components/pages/Teacket/Components/TicketProperties';
import TicketConversation from 'src/components/pages/Teacket/Components/TicketConversation';
import TicketMembers from 'src/components/pages/Teacket/Components/TicketMembers';
import S3Uploader from 'src/components/pages/Teacket/Components/S3Uploader/S3Uploader';
import TicketHistory from 'src/components/pages/Teacket/Components/TicketHistory';
import TicketCustomProperties from 'src/components/pages/Teacket/Components/TicketCustomProps/TicketCustomProperties';
import TicketStatus from 'src/components/pages/Teacket/Components/TicketStatus';
import TicketHistoryBtns from 'src/components/pages/Teacket/Components/TicketHistoryBtns';
import TicketSfData from 'src/components/pages/Teacket/Components/TicketSfData/TicketSfData';
import TicketDates from 'src/components/pages/Teacket/Components/TicketDates';
import OpportunityDetails from 'src/components/pages/Teacket/Components/OpportunityDetails';
import { notArrayOrEmpty } from 'src/misc/Misc';
import TicketTimeTracking from '../Components/TicketTimeTracking/TicketTimeTracking';
import TicketTimeTrackingTotal from '../Components/TicketTimeTracking/TicketTimeTrackingTotal';
import { MASTER_ADMIN_PERMISSION } from 'src/misc/Config';

export const getComponentsGallery = (userPermissions, widgetList) =>
  [
    {
      id: 'Status / Priority',
      col: 0,
      component: (
        <DraggableLayoutItem title='Status & Priority' centered>
          <TicketStatus />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Properties',
      col: 0,
      component: (
        <DraggableLayoutItem title='Properties' centered>
          <TicketProperties />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Opportunity Details',
      col: 0,
      component: (
        <DraggableLayoutItem title='Opportunity Details' centered>
          <OpportunityDetails />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Dates',
      col: 0,
      component: (
        <DraggableLayoutItem title='Dates' centered>
          <TicketDates />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Time Tracking',
      col: 0,
      component: (
        <DraggableLayoutItem title='Time Reporting' titleExtras={<TicketTimeTrackingTotal />} centered collapsible>
          <TicketTimeTracking />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Conversation',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <Space className='btn'>
              {/* <MdOutlineSecurity /> */}
              Conversation
            </Space>
          }
          centered
        >
          <TicketConversation />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'SF Data',
      col: 2,
      component: (
        <DraggableLayoutItem title='Salesforce' centered>
          <TicketSfData />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'People',
      col: 2,
      component: (
        <DraggableLayoutItem title='People' centered>
          <TicketMembers />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Additional Properties',
      col: 2,
      component: (
        <DraggableLayoutItem title='Additional Properties' centered>
          <TicketCustomProperties />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Files',
      col: 2,
      component: (
        <DraggableLayoutItem title='Files' centered>
          <S3Uploader />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'History',
      col: 2,
      component: (
        <DraggableLayoutItem title='History' titleExtras={<TicketHistoryBtns />} centered>
          <TicketHistory />
        </DraggableLayoutItem>
      ),
    },
  ].filter((item) => filterItemsBasedOnWidgetList(item, userPermissions, widgetList));

const filterItemsBasedOnWidgetList = (item, userPermissions, widgetList) => {
  //console.log('filterItemsBasedOnWidgetList()', item, userPermissions, widgetList);
  if (true === notArrayOrEmpty(widgetList)) return false;
  return widgetList
    .filter((x) => {
      if (x.readPermission) {
        //if (userPermissions.includes(x.readPermission)) return true; // for tests only - do not control admin permission
        if (userPermissions.includes(MASTER_ADMIN_PERMISSION) || userPermissions.includes(x.readPermission)) return true;
        return false;
      }
      return true;
    })
    .map((x) => x.widgetId)
    .includes(item.id);
};

export const getCurrentTicketReadOnlyWidgetList = (userPermissions, widgetList) => {
  //console.log('getCurrentTicketReadOnlyWidgetList()', userPermissions, widgetList);
  if (true === notArrayOrEmpty(widgetList)) return null;
  const result = widgetList
    .filter((x) => {
      if (x.writePermission) {
        //if (userPermissions.includes(x.writePermission)) return false; // for tests only - do not control admin permission
        if (userPermissions.includes(MASTER_ADMIN_PERMISSION) || userPermissions.includes(x.writePermission)) return false;
        return true;
      }
      return false;
    })
    .map((x) => x.widgetId);

  return result;
};
