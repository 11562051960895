import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { Button, Space, Select, Alert } from 'antd';
import { loginCognito, loginGenesysCloud } from 'src/misc/Session';
import useUserInfo from 'src/hooks/useUserInfo';
import { DEFAULT_CALLBACK_URL_SIGNED_IN, LOCAL_STORAGE_KEYS, gcRegionList, IDENTITY_PROVIDERS, LOGOUT_REASONS } from 'src/misc/Config';
import { ReactComponent as AwsCognito } from 'src/components/svg/aws-cognito.svg';
import { ReactComponent as GenesysCloud } from 'src/components/svg/genesys-cloud.svg';
import { UserOutlined, ArrowRightOutlined, ArrowDownOutlined, ArrowUpOutlined, HomeOutlined } from '@ant-design/icons';
import useTheme from 'src/hooks/useTheme';
import { ENV } from 'src/misc/Config';
import { getMediaPublicVideo } from 'src/api/media';
import Space2 from 'src/components/layout/Space2';
import { FaPlayCircle } from 'react-icons/fa';
import { notEmptyArray } from 'src/misc/Misc';

const SignIn = () => {
  const { logout_reason: logoutReason } = queryString.parse(window.location.search);

  const navigate = useNavigate();
  const userInfo = useUserInfo();
  const { theme, isDarkMode } = useTheme();
  const [videoUrl, setVideoUrl] = useState(null);

  const BTN_HEIGHT = '64px';

  const [provider, setProvider] = useState(null);
  const [selectedGcRegion, setSelectedGcRegion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gcRegionOptions, setGcRegionOptions] = useState(null);

  useEffect(() => {
    const selectedGcRegion = localStorage.getItem(LOCAL_STORAGE_KEYS.selectedGcRegion);
    if (selectedGcRegion) {
      setSelectedGcRegion(selectedGcRegion);
    }
    setProvider(IDENTITY_PROVIDERS.genesysCloud); // by default GC is expanded
  }, []);

  useEffect(() => {
    processRecentGcRegions();
  }, [gcRegionList]);

  useEffect(() => {
    if (userInfo) {
      navigate(DEFAULT_CALLBACK_URL_SIGNED_IN);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const getLogoutReasonAlert = () => {
    switch (logoutReason) {
      case LOGOUT_REASONS.userLoggedOut:
        return (
          <Alert
            style={{ width: '100%' }}
            message='Thank you for visiting!'
            description={
              <>
                You have been successfully logged out. We look forward to welcoming you back soon. <br />
                <br />
                Have a wonderful day!
              </>
            }
            type='info'
            showIcon
            closable
          />
        );
      case LOGOUT_REASONS.tokenExpired:
        return (
          <Alert
            style={{ width: '100%' }}
            message='Your token has expired'
            description={
              <>
                Oops! It seems your session has expired. Please log in again to continue accessing our site. <br />
                <br /> Thank you for your understanding and patience!
              </>
            }
            type='warning'
            showIcon
            closable
          />
        );
      default:
        return (
          <Alert
            style={{ width: '100%' }}
            message='An error occurred.'
            description={
              <>
                We apologize for the inconvenience. An unexpected error has occurred. Please try again later or contact spc.portal@genesys.com for assistance. <br />
                <br />
                Thank you for your understanding.
              </>
            }
            type='error'
            showIcon
            closable
          />
        );
    }
  };

  const getLoginVideUrl = async () => {
    setLoading(true);
    try {
      const resp = await getMediaPublicVideo('f3d8181d-1e05-45af-a1d7-b85dccb7624d');
      setVideoUrl(resp);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleRegionOnChange = (value) => {
    setSelectedGcRegion(value);
    if (value) localStorage.setItem(LOCAL_STORAGE_KEYS.selectedGcRegion, value);
    else localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedGcRegion);

    if (value) {
      let recentGcRegionsStr = localStorage.getItem(LOCAL_STORAGE_KEYS.recentGcRegions);
      let recentGcRegions = recentGcRegionsStr ? JSON.parse(recentGcRegionsStr) : [];
      if (recentGcRegions.includes(value)) {
        recentGcRegions = recentGcRegions.filter((r) => r !== value);
        recentGcRegions.push(value);
        localStorage.setItem(LOCAL_STORAGE_KEYS.recentGcRegions, JSON.stringify(recentGcRegions));
      } else {
        recentGcRegions.push(value);
        recentGcRegions = recentGcRegions.slice(-3);
        localStorage.setItem(LOCAL_STORAGE_KEYS.recentGcRegions, JSON.stringify(recentGcRegions));
      }
    }

    processRecentGcRegions();
  };

  const processRecentGcRegions = () => {
    let recentGcRegionsStr = localStorage.getItem(LOCAL_STORAGE_KEYS.recentGcRegions);
    let recentGcRegions = recentGcRegionsStr ? JSON.parse(recentGcRegionsStr) : [];

    if (notEmptyArray(recentGcRegions)) {
      setGcRegionOptions([
        {
          label: <span>Recent</span>,
          options: gcRegionList
            .filter((r) => recentGcRegions.includes(r.baseUrl))
            .map((r) => {
              return { label: r.alternateName, value: r.baseUrl };
            }),
        },
        {
          label: <span>Others</span>,
          options: gcRegionList
            .filter((r) => !recentGcRegions.includes(r.baseUrl))
            .map((r) => {
              return { label: r.alternateName, value: r.baseUrl };
            }),
        },
      ]);
    } else {
      setGcRegionOptions([
        {
          label: <span>All regions</span>,
          options: gcRegionList.map((r) => {
            return { label: r.alternateName, value: r.baseUrl };
          }),
        },
      ]);
    }
  };

  return (
    <div className='2xl:w-[1200px] flex flex-col 2xl:flex-row gap-4 2xl:gap-16'>
      <div className='w-full 2xl:w-2/5 flex flex-row justify-center 2xl:justify-end'>
        <div className='w-96 flex flex-col items-center justify-start'>
          {logoutReason && <div className='mb-6 w-full'>{getLogoutReasonAlert()}</div>}
          <div className='mb-6 text-lg w-full text-center'>
            <Space>
              <UserOutlined />
              Sign-in with:
            </Space>
          </div>

          <Space direction='vertical' className='w-full'>
            <div>
              <Button
                size='large'
                style={{ width: '100%', height: BTN_HEIGHT }}
                type='default'
                onClick={() => {
                  setProvider(provider === IDENTITY_PROVIDERS.genesysCloud ? null : IDENTITY_PROVIDERS.genesysCloud);
                }}
              >
                <Space className='btn font-roboto text-xl font-semibold h-full'>
                  <GenesysCloud style={{ width: '48px', height: '36px' }} className='text-orange-500' />
                  Genesys Cloud
                  {provider === IDENTITY_PROVIDERS.genesysCloud ? <ArrowUpOutlined className='opacity-20' /> : <ArrowDownOutlined className='opacity-20' />}
                </Space>
              </Button>

              {provider === IDENTITY_PROVIDERS.genesysCloud && (
                <div className='flex flex-col rounded-b-xl' style={{ marginTop: '-12px', padding: '24px 8px 8px 8px', backgroundColor: theme.backgroundMedium }}>
                  <div className='text-sm pl-1 overflow-auto'>
                    Genesys Cloud is used for authentication.
                    <br />
                    Please select a region.
                  </div>
                  <div className='flex flex-row gap-2 my-1'>
                    <div className='flex-auto'>
                      <Select size='large' allowClear className='w-full' placeholder='Select region...' options={gcRegionOptions} onChange={handleRegionOnChange} value={selectedGcRegion}></Select>
                    </div>
                    {selectedGcRegion && (
                      <Button
                        size='large'
                        type='default'
                        onClick={() => {
                          const sr = gcRegionList.find((region) => region.baseUrl === selectedGcRegion);
                          if (!sr) throw new Error('Invalid selected region');
                          console.log('selectedRegion', sr);
                          loginGenesysCloud(sr.baseUrl, sr.clientId);
                        }}
                      >
                        <ArrowRightOutlined />
                      </Button>
                    )}
                  </div>
                  <div className='text-xs pl-1 overflow-auto flex flex-row gap-1'>
                    <div className='opacity-40'>Are you a</div>
                    <div className='text-genesys-orange-base dark:text-genesys-orange-200 opacity-100'>Genesys</div>
                    <div className='opacity-40'>employee?</div>
                    <div
                      className='opacity-40 hover:opacity-80 cursor-pointer'
                      onClick={() => {
                        const usEast = 'mypurecloud.com';
                        handleRegionOnChange(usEast);
                      }}
                    >
                      Use the Americas (US East) region.
                    </div>
                  </div>
                </div>
              )}
            </div>

            {true === ENV.showCognitoLogin && (
              <>
                {provider === IDENTITY_PROVIDERS.genesysCloud && <hr className='opacity-20' />}
                <Button
                  size='large'
                  className={provider === IDENTITY_PROVIDERS.genesysCloud ? 'opacity-60' : null}
                  style={{ width: '100%', height: BTN_HEIGHT }}
                  type='default'
                  onClick={() => {
                    loginCognito();
                  }}
                >
                  <Space className='btn font-amazon-ember text-xl font-semibold h-full'>
                    <AwsCognito style={{ width: '36px', height: '36px' }} />
                    AWS Cognito
                    <ArrowRightOutlined />
                  </Space>
                </Button>
              </>
            )}
          </Space>

          <div
            className='mt-6 text-sm font-light cursor-pointer opacity-40 hover:opacity-60'
            onClick={() => {
              navigate('/');
            }}
          >
            <Space2>
              <HomeOutlined />
              Back to home page
            </Space2>
          </div>
        </div>
      </div>
      <div className='w-full 2xl:w-1/5 flex flex-col justify-center items-center 2xl:items-start'>
        {/* <div className='text-right w-[386px] 2xl:w-[560px] text-[0.7rem] font-extralight'>
          <div className='pr-4 text-genesys-patina-base'>how to login</div>
        </div> */}
        <div className={'h-[217px] w-[386px] 2xl:h-[314px] 2xl:w-[560px] rounded-xl overflow-hidden'} style={{ border: `1px solid ${theme.borderLight}` }}>
          {!videoUrl ? (
            <div className='w-full h-full flex flex-col justify-center items-center gap-2 2xl:gap-6' style={{ backgroundColor: theme.backgroundBase + 'ee' }}>
              <img src={`/images/misc/dont-know${true === isDarkMode ? '-dark' : ''}.png`} alt='how to login' className='max-h-[120px] 2xl:max-h-[160px]' />
              <Button type='primary' loading={loading} onClick={getLoginVideUrl} size='large'>
                <Space2>
                  {!loading && <FaPlayCircle />}
                  <span>Watch how to login</span>
                  <span className='font-light opacity-60 text-xs'>(44 seconds video)</span>
                </Space2>
              </Button>
            </div>
          ) : (
            <>
              <video autoPlay src={videoUrl} controls crossOrigin='anonymous' className='w-full'>
                <source src={videoUrl} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

SignIn.propTypes = {};

export default SignIn;
