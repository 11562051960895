import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ConfigProvider, theme as themeAntd } from 'antd';
import useTheme from 'src/hooks/useTheme';
import queryString from 'query-string';

const StyledProvider = styled.div`
  background-color: ${(props) => props.theme.backgroundBase};
  color: ${(props) => props.theme.textBase};

  .__dbk__gutter {
    background-color: ${(props) => props.theme.backgroundLight} !important;
  }

  .__dbk__dragger {
    background-color: ${(props) => props.theme.primary} !important;
  }

  .ant-breadcrumb {
    overflow: hidden !important;
    white-space: nowrap !important;
  }

  .ant-breadcrumb ol {
    flex-wrap: nowrap !important;
  }
  /*
  .ant-layout-sider-dark {
    background-color: red !important;
  }

  @layout-header-background {
    background-color: red !important;
  } */

  a {
    color: ${(props) => props.theme.primary} !important;
    text-decoration: none;
  }

  a:hover {
    color: ${(props) => props.theme.primary + 'dd'} !important;
    text-decoration: underline;
  }
`;

const ThemeProvider = ({ children }) => {
  // #region "forceTheme switch"
  let { forceTheme } = queryString.parse(window.location.search); // you can force dark or default theme by adding ?forceTheme=dark or ?forceTheme=default to the URL
  if (forceTheme && !['dark', 'default'].includes(forceTheme)) {
    console.warn('Invalid forceTheme value:', forceTheme);
    forceTheme = null;
  }
  const { isDarkMode, theme, themeDark, themeLight } = useTheme();
  // #endregion

  useEffect(() => {
    // console.log('ThemeProvider()', { isDarkMode });
    let body = document.getElementsByTagName('body')[0];
    body.style.backgroundColor = theme.backgroundBase;

    if (isDarkMode) document.documentElement.classList.add('dark');
    else document.documentElement.classList.remove('dark');
  }, [isDarkMode]);

  const getAntdTheme = () => {
    // console.log('getAntdTheme()', { isDarkMode, forceTheme });
    if (forceTheme === 'dark') return themeAntd.darkAlgorithm;
    if (forceTheme === 'default') return themeAntd.defaultAlgorithm;
    return isDarkMode ? themeAntd.darkAlgorithm : themeAntd.defaultAlgorithm;
  };

  const getStyledComponentsTheme = () => {
    // console.log('getStyledComponentsTheme()', { isDarkMode, forceTheme });
    if (forceTheme === 'dark') return themeDark;
    if (forceTheme === 'default') return themeLight;
    return theme;
  };

  return (
    <StyledProvider theme={getStyledComponentsTheme()}>
      <ConfigProvider
        theme={{
          algorithm: getAntdTheme(),
          token: {
            //colorBgContainer: theme.light1,
            colorPrimary: theme.primary,
            // colorTextBase: theme.textBase,
            colorBgHeader: theme.backgroundBase,
            headerBg: 'red',
          },
          components: {
            Layout: {
              siderBg: theme.backgroundDarkItem,
            },
            Menu: {
              darkItemBg: theme.backgroundDarkItem,
              darkSubMenuItemBg: theme.backgroundDarkSumbMenuItem,
            },
          },
        }}
      >
        {children}
      </ConfigProvider>
    </StyledProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default ThemeProvider;
