import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import PropTypes from 'prop-types';
import { notArrayOrEmpty } from 'src/misc/Misc';
import useTheme from 'src/hooks/useTheme';

const TicketStatusColor = ({ serviceId, status, size = '10px' }) => {
  const { getServicePropsForServiceId } = useContext(TeacketContext);
  const { theme } = useTheme();

  const [statusColor, setStatusColor] = useState('black');

  useEffect(() => {
    const statusList = getServicePropsForServiceId(serviceId, 'statusList');
    if (true === notArrayOrEmpty(statusList)) return;
    const statusConfig = statusList.find((x) => x.status === status);
    if (!statusConfig) return;
    setStatusColor(statusConfig.color);
  }, [serviceId, status]);

  return <div style={{ height: size, width: size, borderRadius: size, backgroundColor: statusColor, border: `1px solid ${theme.light}` }}></div>;
};

TicketStatusColor.propTypes = {
  serviceId: PropTypes.string,
  status: PropTypes.string,
  size: PropTypes.string,
};

export default TicketStatusColor;
