import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useTheme from 'src/hooks/useTheme';
import PropTypes from 'prop-types';
import { getRolePermissions, searchPermissions, postRolePermission, deleteRolePermission } from 'src/api/accesscontrol';
import { Row, Col, Table, Button, Space, Select, Spin, Tag, message } from 'antd';
import { FaCircleMinus, FaArrowsRotate } from 'react-icons/fa6';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import BarLoader from 'react-bar-loader';
import { isRequestCancelled, notEmptyArray } from 'src/misc/Misc';
import { MdDelete } from 'react-icons/md';

const { Option } = Select;

const StyledDiv = styled.div`
  .ant-table-cell {
    padding: 1px 12px !important;
    height: 28px !important;
  }
`;

const RolePermissions = ({ role, onRolePermissionsChanged }) => {
  const { theme } = useTheme();
  const [messageApi, contextHolder] = message.useMessage();
  const [rolePermissions, setRolePermissions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [permLoading, setPermLoading] = useState(false);
  const [pageSize] = useState(10);
  const [permList, setPermList] = useState([]);
  const [selectedPerm, setSelectedPerm] = useState(null);

  const columns = [
    {
      title: 'Permission',
      key: 'permission',
      width: 320,
      render: (record) => (
        <>
          {record.permission} {record.justCreated && <Tag color='blue'>just assigned</Tag>}
        </>
      ),
    },
    {
      title: 'Description',
      key: 'permission_desc',
      render: (record) => <span className='font-extralight'>{record.permission_desc}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      align: 'center',
      render: (record) => (
        <Space className='btn'>
          <YesNoDialog
            title='Unassign'
            body={
              <>
                Do you want to unassign permission <strong>{record.permission}</strong>?
              </>
            }
            onYesClick={() => {
              handleUnassign(role, record.permission);
            }}
            iconYes={<MdDelete />}
            showRed={true}
            labelYes='Yes, unassign permission'
            labelNo='Cancel'
          >
            <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }}>
              <FaCircleMinus />
            </Button>
          </YesNoDialog>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (onRolePermissionsChanged) onRolePermissionsChanged({ role, length: true === notEmptyArray(rolePermissions) ? rolePermissions.length : 0 });
  }, [rolePermissions]);

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = await getRolePermissions(role);
      setRolePermissions(resp);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleClear = async () => {
    console.log('handleClear');
    setPermList([]);
  };

  const handleSearchForPermissions = async (query) => {
    setPermList([]);
    if (query.length < 3) return;
    try {
      setPermLoading(true);
      let resp = await searchPermissions(query, 1, 12);
      console.log('Perms:', resp);
      if (resp?.entities) {
        setPermList(resp.entities);
      }
      setPermLoading(false);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      console.log(error);
      setPermLoading(false);
    }
  };

  const handleAssign = async (perm) => {
    console.log('handleAssign', perm);
    setSelectedPerm(perm);
    setLoading(true);
    try {
      await postRolePermission(role, perm);
      const rp = [...rolePermissions];
      const p = permList.find((x) => x.permission === perm);
      rp.unshift({ ...p, permission_desc: p.description, justCreated: true });
      setRolePermissions(rp);
      messageApi.open({
        type: 'success',
        content: 'Permission has been assigned',
        duration: 3,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setSelectedPerm(null);
    setPermList([]);
  };

  const handleUnassign = async (role, permission) => {
    console.log('handleUnassign', role, permission);
    setLoading(true);
    try {
      await deleteRolePermission(role, permission);
      const rp = [...rolePermissions].filter((x) => x.permission !== permission);
      setRolePermissions(rp);
      messageApi.open({
        type: 'success',
        content: 'Permission has been unassigned',
        duration: 3,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const isOptionDisabled = (key) => {
    return true === notEmptyArray(rolePermissions?.filter((x) => x.permission === key));
  };

  return (
    <StyledDiv className={`ml-12  ${loading && 'disabled'}`}>
      {contextHolder}
      <Row>
        <Col span={12}>
          <Space>
            <div className='font-extralight opacity-60'>Assigned permissions</div>
            <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }} onClick={loadData}>
              <FaArrowsRotate className={loading && 'animate-spin'} />
            </Button>

            <Select
              value={selectedPerm}
              showSearch
              allowClear
              placeholder='type to assign permission...'
              notFoundContent={permLoading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
              filterOption={false}
              onSearch={handleSearchForPermissions}
              onClear={handleClear}
              onSelect={handleAssign}
              style={{ width: '300px' }}
              size='small'
              optionRender={({ value }) => {
                const item = permList.find((x) => x.permission === value);
                if (!item) return null;
                return (
                  <div className='flex flex-col'>
                    <div className='font-bold'>{item.permission}</div>
                    <div className='text-xs font-light opacity-60' style={{ textWrap: 'pretty' }} dangerouslySetInnerHTML={{ __html: item.description }} />
                  </div>
                );
              }}
            >
              {permList.map((d) => (
                <Option key={d.permission} autoComplete='off' disabled={isOptionDisabled(d.permission)}>
                  {d.permission}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col span={12}></Col>
      </Row>

      {loading ? <BarLoader color={theme.primary} height='2' width='100%' /> : <div style={{ height: '1px', marginTop: '1px', backgroundColor: theme.primary }}></div>}
      <Table pagination={{ pageSize }} size='small' columns={columns} dataSource={rolePermissions} rowKey={'permission'} />
    </StyledDiv>
  );
};

RolePermissions.propTypes = {
  role: PropTypes.object.isRequired,
  onRolePermissionsChanged: PropTypes.func,
};

export default RolePermissions;
