import React, { useRef, useEffect, useState } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useTheme from 'src/hooks/useTheme';
import { getMyTicketsGraph } from 'src/api/teacket';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const GraphMyTicketsByPriority = () => {
  const { isDarkMode } = useTheme();
  const [graphDataPriority, setGraphDataPriority] = useState(undefined);
  const refContainer = useRef();

  const themeMode = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  const loadData = async () => {
    try {
      const resp = await getMyTicketsGraph('grouped-by-priority');
      setGraphDataPriority(resp);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!graphDataPriority) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={themeMode}>
      <div ref={refContainer}>
        {!graphDataPriority ? (
          <div className='text-center mt-10'>
            <Spin />
          </div>
        ) : (
          <>
            <div className='flex flex-row flex-wrap justify-center'>
              <PieChart
                title='ByPriority'
                colors={['#76b7b2', 'rgb(251 113 133)', '#f28e2c', '#59a14f', '#edc949', '#af7aa1', '#ff9da7', '#9c755f', '#bab0ab', '#adadad']}
                series={[
                  {
                    arcLabel: (item) => `${item.value}`,
                    data: graphDataPriority || [],
                    arcLabelMinAngle: 25,
                    innerRadius: 20,
                    outerRadius: 70,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    cy: 100,
                    cx: 100,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold',
                  },
                }}
                width={300}
                height={200}
                slotProps={{
                  legend: {
                    hidden: false,
                    position: { vertical: 'middle', horizontal: 'right' },
                  },
                }}
              />
            </div>
            <div className='text-xs font-thin text-center whitespace-break-spaces'>List of open tickets where I'm a member, grouped by priority.</div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

GraphMyTicketsByPriority.propTypes = {};

export default GraphMyTicketsByPriority;
