import React, { useContext } from 'react';
import { Checkbox } from 'antd';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';

const DSRCustomWelcomeStep = () => {
  const { ack, setAck } = useContext(DataSubjectRequestsContext);

  return (
    <div>
      <div className='font-bold text-xl '>Name</div>
      <div>
        <div className='font-bold text-xl '>Welcome to Data Subject Requests</div>
        <div className='font-extralight'>
          <div className='mt-3'>
            This 2 application allows you to send or simulate real or fake <strong>GDPR requests</strong> in your Genesys Cloud organization.
          </div>
          <div className='mt-2'>
            Click on <strong>Next</strong> to continue.
          </div>
        </div>
        <div className='mt-4 font-extralight'>
          <Checkbox checked={ack} onChange={(e) => setAck(e.target.checked)}>
            By clicking on this button, you consent that you have an authorization from your Privacy/Legal office to execute Data Subject Requests on the behalf of your organization
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

DSRCustomWelcomeStep.propTypes = {};

export default DSRCustomWelcomeStep;
