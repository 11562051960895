import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

const StyledButtonWrapper = styled.div`
  :hover {
    opacity: 0.8;
  }
`;

const StyledButton = styled(Button)`
  border: 1px solid ${(props) => props.color ?? 'red'} !important;
  color: ${(props) => props.color ?? 'red'} !important;
  background-color: ${(props) => props.bgColor ?? 'transparent'} !important;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  opacity: 1;

  :hover {
    color: ${(props) => props.color ?? 'red'} !important;
    opacity: 1;
  }

  :active {
    color: ${(props) => props.color ?? 'red'} !important;
    opacity: 1;
  }
`;

const Btn = (props) => {
  return (
    <StyledButtonWrapper>
      <StyledButton {...props}></StyledButton>
    </StyledButtonWrapper>
  );
};

export default Btn;
