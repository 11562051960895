import React, { useContext, useEffect, useState } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import MasterAdminContext from 'src/context/MasterAdminContext';
import { getAdminServicePropList } from 'src/api/teacket';
import TeacketServiceEditorConfigList from './TeacketServiceEditorConfigList';
import PropTypes from 'prop-types';
import { isRequestCancelled, notArrayOrEmpty, textSorter } from 'src/misc/Misc';
import { Select } from 'antd';
import NoData from 'src/components/layout/NoData';

const TeacketServiceEditorConfiguration = ({ serviceId }) => {
  const { teacketServicePropList, setTeacketServicePropList } = useContext(MasterAdminContext);
  const { executeAsyncProcess } = useAsyncProcesses();

  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (!teacketServicePropList) loadServiceProps();
  }, []);

  useEffect(() => setItems(), [teacketServicePropList]);

  const loadServiceProps = async () => {
    await executeAsyncProcess(async () => {
      try {
        const resp = await getAdminServicePropList();
        setTeacketServicePropList(resp);
      } catch (error) {
        if (true === isRequestCancelled(error)) return;
        console.log(error);
      }
    });
  };

  const setItems = () => {
    //console.log('setItems()', teacketServicePropList);
    if (true === notArrayOrEmpty(teacketServicePropList)) {
      setItemList(null);
      return;
    }
    setItemList(teacketServicePropList.map((prop) => ({ label: prop.title, value: prop.title, description: prop.description })).sort((a, b) => textSorter(a, b, 'value')));
  };

  if (!teacketServicePropList) return null;

  return (
    <div className='flex flex-col gap-4'>
      <Select
        showSearch
        options={itemList}
        style={{ width: '300px' }}
        placeholder='What do you want to configure?'
        onSelect={(value) => setSelectedItem(value)}
        optionRender={({ value }) => {
          const item = itemList.find((x) => x.value === value);
          if (!item) return null;
          return (
            <div className='flex flex-col'>
              <div className='overflow-hidden font-bold'>{value}</div>
              <div className='text-xs font-light opacity-60 overflow-hidden'>{item.description}</div>
            </div>
          );
        }}
      />
      {!selectedItem ? (
        <>
          <NoData label='Nothing is selected' />{' '}
        </>
      ) : (
        <TeacketServiceEditorConfigList prop={teacketServicePropList.find((x) => x.title === selectedItem)} serviceId={serviceId} />
      )}
      {/* {teacketServicePropList.map((prop, index) => (
        <TeacketServiceEditorConfigList key={index} prop={prop} serviceId={serviceId} />
      ))} */}
    </div>
  );
};

TeacketServiceEditorConfiguration.propTypes = {
  serviceId: PropTypes.string.isRequired,
};

export default TeacketServiceEditorConfiguration;
