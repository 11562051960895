import React, { useState } from 'react';
import { Badge, Button, message as messageAntd } from 'antd';
// import { FaBell } from 'react-icons/fa';
import { PiBellSimpleDuotone } from 'react-icons/pi';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';
import NotificationCenterDrawer from './NotificationCenterDrawer';

const NotificationCenterIcon = () => {
  const MSG_KEY = '91108896-1f71-4a41-97e1-0d752196a222';
  const ACTION_LIST = ['UNREAD_MSGS_COUNT', 'GENERIC'];
  const [messageApi, contextHolder] = messageAntd.useMessage();

  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(null);

  const handleOnWsMessageReceived = async (message) => {
    console.log('handleOnWsMessageReceived()', message);
    if (!ACTION_LIST.includes(message?.action)) return;
    switch (message?.action) {
      case 'UNREAD_MSGS_COUNT':
        handleUnreadMsgsCount(message);
        break;
      case 'GENERIC':
        if (message.origin === 'system' || message.origin === 'master_admin') // todo: it should be master_admin only, but for some reasone we get system here
          handleGeneric(message);
        break;
      default:
        console.error('Unknown action:', message?.action);
    }
  };

  const handleUnreadMsgsCount = (message) => {
    console.log('handleUnreadMsgsCount()', message);
    try {
      if (!ACTION_LIST.includes(message?.action)) return;
      setUnreadMessages(message?.data?.count ?? null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGeneric = (message) => {
    console.log('handleGeneric()', message);
    try {
      // if (!ACTION_LIST.includes(message?.action)) return;
      // setUnreadMessages(message?.data?.count ?? null);

      messageApi.open({
        key: MSG_KEY,
        type: 'success',
        content: <div>
          <div style={{ fontSize: '1.1rem', fontWeight: '700' }} dangerouslySetInnerHTML={{ __html: message?.data?.title }} />
          <div style={{ fontWeight: '300' }} dangerouslySetInnerHTML={{ __html: message?.data?.description }} />
          <div className='mt-2'><Button size='small' type='primary' onClick={handleMsgClose}>Close</Button></div>
        </div>,
        duration: 0,
        size: 'large'
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleMsgClose = () => {
    messageApi.destroy(MSG_KEY);
  };

  return (
    <>
      {contextHolder}
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={ACTION_LIST} />
      <NotificationCenterDrawer open={sidePanelOpen} onClose={() => setSidePanelOpen(false)} unreadMessages={unreadMessages} />
      <div
        className='h-full mt-1 cursor-pointer hover:scale-110 mx-4 flex flex-row justify-center items-center'
        onClick={() => {
          setSidePanelOpen(true);
        }}
      >
        <Badge count={unreadMessages} size='small'>
          <PiBellSimpleDuotone className={`text-xl ${unreadMessages && unreadMessages > 0 ? 'animate-bounce' : null}`} />
        </Badge>
      </div>
    </>
  );
};

NotificationCenterIcon.propTypes = {};

export default NotificationCenterIcon;
