import React, { useContext } from 'react';
import SecurityAdvisorContext from 'src/context/SecurityAdvisorContext';
import { Checkbox } from 'antd';

//import PropTypes from 'prop-types';

const OptionsStep = () => {
  const { setupHipaa, setSetupHipaa, setupPci, setSetupPci } = useContext(SecurityAdvisorContext);

  return (
    <div>
      <div className='font-bold text-xl '>Options</div>
      <div className='mt-4 mb-4'>Does your Genesys Cloud organization need to comply with the following regulations?</div>
      <div className='mt-1'>
        <Checkbox checked={setupHipaa} onChange={(e) => setSetupHipaa(e.target.checked)}>
          HIPAA
        </Checkbox>
        <Checkbox checked={setupPci} onChange={(e) => setSetupPci(e.target.checked)}>
          PCI
        </Checkbox>
      </div>
    </div>
  );
};

OptionsStep.propTypes = {};

export default OptionsStep;
