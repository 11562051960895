import React, { useState } from 'react';
import { Select, Spin, Space, Checkbox, Radio } from 'antd';
import { getDeployments } from 'src/api/snowflake';
import { FaAws, FaHashtag, FaRegAddressCard } from 'react-icons/fa6';
import PropTypes from 'prop-types';
import PropertySection from 'src/components/layout/PropertySection';
import { notEmptyArray } from 'src/misc/Misc';

const { Option } = Select;

const DeploymentPicker = ({ onSelect, limit = 25, initialValue, placeholder = 'type to find a deployment...' }) => {
  const deploymentSearchOptions = ['org_name', 'org_id', 'account_name'];
  const deploymentTypeOptions = ['like', 'starts_with', 'exact_match'];
  const [loading, setLoading] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [deploymentFilterSearch, setDeploymentFilterSearch] = useState([]);
  const [deploymentFilterType, setDeploymentFilterType] = useState(deploymentTypeOptions[0]);

  const handleSearch = async (query) => {
    setItemsList([]);
    if (query.length < 3) return;
    try {
      setLoading(true);
      let resp = await getDeployments(query, limit, deploymentFilterType, notEmptyArray(deploymentFilterSearch) ? deploymentFilterSearch : null);
      if (resp) {
        setItemsList(resp);
      }
      setLoading(false);
    } catch (error) {
      if (error === 'cancelled') return;
      console.log(error);
      setLoading(false);
    }
  };

  const handleClear = async () => {
    console.log('handleClear');
    setItemsList([]);
    if (onSelect) onSelect(null);
  };

  const handleSelect = async (v) => {
    console.log('handleSelect', v);
    const selectedItem = itemsList.find((x) => x.ORG_ID === v);
    if (onSelect) onSelect(selectedItem);
  };

  const getLabel = (item) => item?.ORG_NAME ?? 'n/a';

  return (
    <PropertySection>
      <div className='flex flex-col gap-2 my-2'>
        <div className='flex flex-col gap-2'>
          <div>
            <div>
              Search fields: <span className='text-xs font-light opacity-60'>(all fields if not selected)</span>
            </div>
            <div>
              <Checkbox.Group options={deploymentSearchOptions} value={deploymentFilterSearch} onChange={(e) => setDeploymentFilterSearch(e)} />
            </div>
          </div>
          <div>
            <div>Search type:</div>
            <div>
              <Radio.Group options={deploymentTypeOptions} value={deploymentFilterType} onChange={(e) => setDeploymentFilterType(e.target.value)} />
            </div>
          </div>
        </div>
        <Select
          defaultValue={() => (initialValue ? getLabel(initialValue) : null)}
          showSearch
          allowClear
          placeholder={placeholder}
          notFoundContent={loading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
          filterOption={false}
          onSearch={handleSearch}
          onClear={handleClear}
          onSelect={handleSelect}
          style={{ width: '100%' }}
          optionRender={({ value }) => {
            const item = itemsList.find((x) => x.ORG_ID === value);
            if (!item) return null;
            return (
              <div className='flex flex-col'>
                <div className='overflow-hidden font-bold'>{item.ORG_NAME}</div>
                <div className='text-xs font-light opacity-60 overflow-hidden'>
                  <Space className='btn'>
                    <FaHashtag />
                    {item.ORG_ID}
                  </Space>
                </div>
                <div className='text-xs font-light opacity-60 overflow-hidden'>
                  <Space className='btn'>
                    <FaAws />
                    {item.ORG_REGION}
                  </Space>
                </div>
                <div className='text-xs font-light opacity-60 overflow-hidden'>
                  <Space className='btn'>
                    <FaRegAddressCard />
                    {item.ACCOUNT_NAME}
                  </Space>
                </div>
              </div>
            );
          }}
        >
          {itemsList.map((item) => (
            <Option key={item.ORG_ID} autoComplete='off'>
              {getLabel(item)}
            </Option>
          ))}
        </Select>
      </div>
    </PropertySection>
  );
};

DeploymentPicker.propTypes = {
  onSelect: PropTypes.func,
  limit: PropTypes.number,
  initialValue: PropTypes.object,
  search: PropTypes.array,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

DeploymentPicker.defaultProps = {};

export default DeploymentPicker;
