import React, { useState /*, useContext*/ } from 'react';
import PropTypes from 'prop-types';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Popover } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useInterval } from 'react-interval-hook';
import { EVENT_GLOBAL_MSG_RCVD } from './misc';
import useUserInfo from 'src/hooks/useUserInfo';
// import NotificationsContext from 'src/context/NotificationsContext';
import { ENV } from '../../misc/Config';

dayjs.extend(utc);

const GlobalWsClient = ({ token, baseUrl }) => {
  // const { addNotification, refreshMyDbNotifications } = useContext(NotificationsContext);

  const [message, setMessage] = useState(null);
  const [heartbeatMessage, setHeartbeatMessage] = useState(null);
  const userInfo = useUserInfo();

  if (!userInfo) {
    console.log('User is not logged in. Skip Web Socket initialization.');
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useInterval(() => {
    if (readyState !== ReadyState.OPEN) {
      console.warn('[GlobalWsClient] Socket is not OPEN');
      return;
    }
    const m = { action: 'heartbeat' };
    sendMessage(JSON.stringify(m), false);
  }, 60000);

  const subscribeForMessages = async () => {
    try {
      //console.log('subscribe userInfo:', userInfo);
      const userId = userInfo.id;
      const m = { userId, token, baseUrl, action: 'subscribe' };
      console.log('m:', m);
      console.log('webSocketsUrl:', ENV.webSocketsUrl);
      sendMessage(JSON.stringify(m), false);
    } catch (error) {
      console.error('[GlobalWsClient] Error when sending a subscribe message:', error);
    }
  };

  const webSocketOptions = {
    onOpen: () => {
      console.log('[GlobalWsClient] WS Opened');
      subscribeForMessages();
    },
    onClose: () => console.warn('[GlobalWsClient] WS Closed'),
    onMessage: (message) => {
      if (message?.data) {
        const body = JSON.parse(message.data);
        const m = { timestamp: dayjs().utc().format(), body };
        if (body?.action === 'heartbeat') {
          setHeartbeatMessage(m);
        } else {
          setMessage(m);
          onMessageReceived(m);
        }
      }
    },
    onError: (e) => console.error('[GlobalWsClient] WS Error:', e),
    onReconnectStop: () => console.warn('[GlobalWsClient] WS Reconnect stopped'),
    shouldReconnect: () => true,
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { sendMessage, /* lastMessage, */ readyState } = useWebSocket(ENV.webSocketsUrl ?? 'wss://x66oc9ydkg.execute-api.eu-central-1.amazonaws.com/dev', webSocketOptions);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Connected',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const connectionStatusClass = {
    [ReadyState.CONNECTING]: 'ws-state-connecting',
    [ReadyState.OPEN]: 'ws-state-open',
    [ReadyState.CLOSING]: 'ws-state-closing',
    [ReadyState.CLOSED]: 'ws-state-closed',
    [ReadyState.UNINSTANTIATED]: 'ws-state-uninstantiated',
  }[readyState];

  const onMessageReceived = (m) => {
    console.log('[GlobalWsClient] onMessageReceived()', m);
    // Please dont put any logic here, just dispatch an event instead.
    // Catch the event by GlobalWsMessenger component and do the logic there.
    // Keep this component as generic as possible.
    // if (m?.body?.title) addNotification(m?.body);
    // if (m?.body?.action === 'notifications_refresh' || window.location.pathname.startsWith('/notifications')) refreshMyDbNotifications();
    const event = new CustomEvent(EVENT_GLOBAL_MSG_RCVD, { detail: m.body });
    document.dispatchEvent(event);
  };

  //#endregion

  return (
    <Popover
      placement='bottomRight'
      title={
        <span style={{ fontSize: '0.6rem' }}>
          Syncing: <span style={{ fontWeight: '300' }}>{connectionStatus}</span>
        </span>
      }
      content={
        <div style={{ fontSize: '0.6rem' }}>
          {!heartbeatMessage ? (
            <div>No heartbeat has been received yet</div>
          ) : (
            <div>
              Last hearbeat received: <span style={{ fontWeight: '300' }}>{dayjs(heartbeatMessage?.timestamp).fromNow() ?? '- no timestamp'}</span>
            </div>
          )}
          {!message ? (
            <div>No message has been received yet</div>
          ) : (
            <div>
              {/* <div> */}
              Last message received: <span style={{ fontWeight: '300' }}>{dayjs(message?.timestamp).fromNow() ?? '- no timestamp'}</span>
              {/* </div>
                <div>Last message body:</div>
                <div style={{ fontWeight: '300', maxWidth: '300px', maxHeight: '500px', overflow: 'auto' }}>{JSON.stringify(message?.body, null, 2) ?? 'No msg body'}</div> */}
            </div>
          )}
        </div>
      }
    >
      <i className={`ion-record clickabke ws-state ${connectionStatusClass}`} />
    </Popover>
  );
};

GlobalWsClient.propTypes = {
  userId: PropTypes.string,
  userName: PropTypes.string,
  orgId: PropTypes.string,
  orgName: PropTypes.string,
  envId: PropTypes.string,
  currentEnv: PropTypes.object,
  token: PropTypes.string,
  baseUrl: PropTypes.string,
};

export default GlobalWsClient;
