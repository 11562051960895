import React, { useState, useEffect, useContext } from 'react';
import MasterAdminContext from 'src/context/MasterAdminContext';
import { Button, Radio, Space, message as messageAntd } from 'antd';
import CondensedTable from 'src/components/layout/CondensedTable';
import TenantPicker from 'src/components/controls/TenantPicker/TenantPicker';
import PermissionPicker from 'src/components/controls/PermissionPicker/PermissionPicker';
import UserPicker from 'src/components/controls/UserPicker/UserPicker';
import { notArrayOrEmpty } from 'src/misc/Misc';
import { FaRegTrashCan } from 'react-icons/fa6';
import Space2 from 'src/components/layout/Space2';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { patchAdminMessage, getTargetsCount } from 'src/api/notifications';
import { uuid } from 'short-uuid';

//import PropTypes from 'prop-types';


const AdminMsgsEditorTargets = () => {
  const typeOptions = ['permission owners', 'tenant members', 'users', 'everyone'];
  const { adminMessagesSelectedId, adminMessagesSelected, setAdminMessagesSelected, setAdminMessagesTabsDisabled } = useContext(MasterAdminContext);
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = messageAntd.useMessage();



  const columns = [
    {
      title: 'Id',
      key: 'id',
      width: '30%',
      render: (record) => <>{record.id}</>
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => <>{record.name}</>
    },
    {
      title: 'Action',
      key: 'action',
      width: '80px',
      align: 'center',
      render: (record) => <Button type='text' onClick={() => { handleRemoveFromList(record.id); }}><FaRegTrashCan /></Button>
    }
  ];

  const [type, setType] = useState(typeOptions[0]);
  const [value, setValue] = useState(null);
  const [valueList, setValueList] = useState([]);
  const [modified, setModified] = useState(false);
  const [resetPickers, setResetPickers] = useState(false);

  useEffect(() => {
    setAdminMessagesTabsDisabled(modified);
  }, [modified]);

  useEffect(() => {
    if (!resetPickers) return;
    setResetPickers(false);
  }, [resetPickers]);

  useEffect(() => handleOnReset(), [adminMessagesSelected]);

  const handlePermissionOnPick = (e) => {
    console.log('handlePermissionOnPick:', e);
    setValue(!e ? null : { id: e.permission, name: e.description });
  };

  const handleTenantOnPick = (e) => {
    console.log('handleTenantOnPick:', e);
    setValue(e);
  };

  const handleAddToList = () => {
    console.log('handleAddToList, value:', value);
    if (!value) return;
    let vl = notArrayOrEmpty(valueList) ? [] : [...valueList];
    if (vl.find((v) => v.id === value.id)) return;
    vl.unshift(value);
    setValueList(vl);
    setValue(null);
    setModified(true);
    setResetPickers(true);
  };

  const handleRemoveFromList = (id) => {
    console.log('handleRemoveFromList, id:', id);
    if (!id) return;
    let vl = [...valueList];
    vl = vl.filter((v) => v.id !== id);
    setValueList(vl);
    setModified(true);
  };

  const handleUserOnPick = (e) => {
    console.log('handleUserOnPick:', e);
    setValue(!e ? null : { id: e.user_id, name: e.user_name });
  };

  const handleOnSave = (values) => {
    console.log('handleOnFinish:', values);
    executeAsyncProcess(async () => {
      try {
        const changes = { target: { type, valueList } };
        const resp = await patchAdminMessage(adminMessagesSelectedId, changes);
        setAdminMessagesSelected(resp);
        setModified(false);
      } catch (error) {
        console.error('error', error);
      }
    });
  };

  const handleOnTest = () => {
    console.log('handleOnTest');
    executeAsyncProcess(async () => {
      try {
        const key = uuid();
        messageApi.open({
          key,
          type: 'loading',
          content: 'Determining...',
          duration: 0,
        });
        const resp = await getTargetsCount(adminMessagesSelectedId);
        // console.log('targets count:', resp);
        messageApi.open({
          key,
          type: 'success',
          content: `${resp.count} users`,
          duration: 5,
        });
      } catch (error) {
        console.error('error', error);
      }
    });
  };

  const handleOnReset = () => {
    setModified(false);
    setValue(null);
    setResetPickers(true);
    if (!adminMessagesSelected) return;
    setType(adminMessagesSelected.target?.type ? adminMessagesSelected.target?.type : typeOptions[0]);
    setValueList(adminMessagesSelected.target?.valueList ? adminMessagesSelected.target?.valueList : null);
  };

  return <div className='flex flex-col gap-4'>
    {contextHolder}
    <div>
      <Radio.Group options={typeOptions} value={type} onChange={(e) => {
        setModified(true);
        setValueList([]);
        setType(e.target.value);
      }} />
    </div>
    {'everyone' !== type &&
      <>
        <Space2>
          {!resetPickers && <div>
            {typeOptions[0] === type && <div>
              <PermissionPicker width={'500px'} onPick={handlePermissionOnPick} />
            </div>}
            {typeOptions[1] === type && <div>
              <TenantPicker width={'500px'} onPick={handleTenantOnPick} />
            </div>}
            {typeOptions[2] === type && <div>
              <UserPicker width={'500px'} onPick={handleUserOnPick} />
            </div>}
          </div>}
          {type && <div>
            <Button type='primary' onClick={handleAddToList} disabled={!value}>Add to list</Button>
          </div>}
        </Space2>
        <div>
          <CondensedTable columns={columns} dataSource={valueList} />
        </div></>
    }

    <div>
      <Space>
        <Button htmlType='reset' disabled={!modified} onClick={handleOnReset}>
          Reset
        </Button>
        <Button disabled={modified} onClick={handleOnTest}>
          Test <span className='text-xs font-light opacity-80'>(show # users)</span>
        </Button>
        <Button type='primary' htmlType='submit' disabled={!modified} onClick={handleOnSave}>
          Save
        </Button>
      </Space>

    </div>
  </div>;
};

AdminMsgsEditorTargets.propTypes = {};

export default AdminMsgsEditorTargets;