import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey } from '@ant-design/colors';
import { IoBody } from 'react-icons/io5';
import { FaCloudDownloadAlt, FaClock } from 'react-icons/fa';
import { HiDocumentReport } from 'react-icons/hi';
import { HiMiniDocumentText } from 'react-icons/hi2';

const _stepList = [
  { id: 'startGDPRProcess', label: 'Start GDPR process', icon: <IoBody />, stage: 'gdpr' },
  { id: 'waitGDPRComplete', label: 'Awaiting GDPR process to be complete', icon: <FaClock />, stage: 'gdpr' },
  { id: 'analyticsDataAfter', label: 'Getting the analytics data', icon: <FaCloudDownloadAlt />, stage: 'report' },
  { id: 'generateSecondReport', label: 'Generating report', icon: <HiMiniDocumentText />, stage: 'report' },
  { id: 'generateFinalReport', label: 'Creating the summary report', icon: <HiDocumentReport />, stage: 'report' },
  { id: 'processingFile', label: 'Processing the export file', icon: <HiDocumentReport />, stage: 'gdpr' },
];

export const getStepLabel = (id) => {
  const step = _stepList.find((x) => id === x.id);
  return step?.label ? step.label : id;
};

export const getStepIcon = (id) => {
  const step = _stepList.find((x) => id === x.id);
  return step?.icon ? step.icon : null;
};

export const getStepStage = (id) => {
  const step = _stepList.find((x) => id === x.id);
  return step?.stage ? step.stage : null;
};

const _statusList = [
  { id: 'notStarted', label: 'Not started yet', color: 'gray' },
  { id: 'searching', label: 'Searching', color: 'gold' },
  { id: 'waiting', label: 'Waiting', color: 'gold' },
  { id: 'started', label: 'Started', color: 'gold' },
  { id: 'updating', label: 'Updating', color: 'gold' },

  { id: 'completed', label: 'Completed', color: 'green' },
  { id: 'failed', label: 'Failed', color: 'red' },
  { id: 'deleting', label: 'Deleting', color: 'magenta' },
  { id: 'deleted', label: 'Deleted', color: 'red' },

  { id: 'processing', label: 'Processing', color: 'blue' },
  { id: 'ambigous', label: 'Ambigous', color: 'black' },
];

export const getStatusLabel = (id) => {
  const status = _statusList.find((x) => id === x.id);
  return status?.label ? status.label : id;
};

export const getStatusIcon = (id) => {
  const status = _statusList.find((x) => id === x.id);
  return status?.icon ? status.icon : null;
};

export const getStatusColor = (id) => {
  const status = _statusList.find((x) => id === x.id);
  return status?.color ? status.color : '#000000';
};

const _demoTypeList = [
  { id: '1', label: 'GDPR Requests', icon: 'ion-ios-body' },
  { id: '2', label: 'GDPR Demo', icon: 'ion-network' },
];

export const getDemoTypeLabel = (id) => {
  id = id ?? '2'; // a default type for old records is '2'
  const item = _demoTypeList.find((x) => id === x.id);
  return item?.label ? item.label : id;
};

export const getDemoTypeIcon = (id) => {
  id = id ?? '2'; // a default type for old records is '2'
  const item = _demoTypeList.find((x) => id === x.id);
  return item?.icon ?? null;
};

export const extractName = (key) => {
  if (!key?.includes('/')) return key;
  return key.split('/').pop();
};

export const getColor = (color, hue) => {
  switch (color) {
    case 'red':
      return red[hue];
    case 'volcano':
      return volcano[hue];
    case 'gold':
      return gold[hue];
    case 'yellow':
      return yellow[hue];
    case 'lime':
      return lime[hue];
    case 'green':
      return green[hue];
    case 'cyan':
      return cyan[hue];
    case 'blue':
      return blue[hue];
    case 'geekblue':
      return geekblue[hue];
    case 'purple':
      return purple[hue];
    case 'magenta':
      return magenta[hue];
    default:
      if (hue === 2) return '#eeeeee';
      return grey[hue];
  }
};

export const requestTypeList = [
  { value: 'GDPR_EXPORT', label: 'Export', icon: 'ion-android-download' },
  { value: 'GDPR_UPDATE', label: 'Update', icon: 'ion-edit' },
  { value: 'GDPR_DELETE', label: 'Delete', icon: 'ion-trash-a' },
];

export const replacementTypeList = [
  { value: 'NAME', label: 'Name' },
  { value: 'ADDRESS', label: 'Address' },
  { value: 'PHONE', label: 'Phone' },
  { value: 'EMAIL', label: 'Email' },
  { value: 'TWITTER', label: 'Twitter' },
];
