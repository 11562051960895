import React from 'react';
import PropTypes from 'prop-types';
//import useTheme from 'src/hooks/useTheme';

const TicketTimeTrackingHoursSelectorItem = ({ value, label, selected, onClick }) => {
  // const { theme } = useTheme();

  const handleOnClick = () => {
    if (!onClick) return;
    onClick(value);
  };

  return (
    <div key={label} className={'hover:scale-110  transition-all'} onClick={!selected ? handleOnClick : null}>
      <div className={`text-xs font-semibold flex flex-row items-center opacity-80 py-[1px] px-1 rounded-md border border-solid ${!selected ? 'cursor-pointer border-gray-300 dark:border-gray-700' : 'cursor-default border-genesys-azure-500 shadow-selected shadow-genesys-navy-500'}`} style={{ userSelect: 'none', fontStretch: 'semi-condensed' }}>
        {label}
      </div>
    </div>
  );
};

TicketTimeTrackingHoursSelectorItem.propTypes = {
  value: PropTypes.any,
  label: PropTypes.any,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TicketTimeTrackingHoursSelectorItem;
