import React, { useEffect, useState } from 'react';
import useTheme from 'src/hooks/useTheme';
import { invertColor } from 'src/misc/Misc';
import PropTypes from 'prop-types';
import { originConfig } from '../misc/misc';
import * as dayjs from 'dayjs';
import { Button, Tooltip } from 'antd';
import { FaTrash, FaCheck, FaChevronDown, FaCircle } from 'react-icons/fa';
import DisplayObjectProps from 'src/components/layout/DisplayObjectProps';
import ReactJson from 'react-json-view';

const NotificationCenterItem = ({ item, onMarkItemAsRead, onDeleteItem }) => {
  const { isDarkMode, theme } = useTheme();

  const [config, setConfig] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [hasDetails, setHasDetails] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const border = `${item?.is_read ? '0.5px' : '1px'} solid ${theme.borderLight}`;

  useEffect(() => {
    if (!item) return;
    let c = originConfig.find((o) => o.origin === item.origin) ?? originConfig.find((o) => o.origin === 'default');
    setConfig(c);
    const hd = item?.details && Object.keys(item.details).length > 0;
    setHasDetails(hd);
  }, [item]);

  useEffect(() => {
    if (showDetails) {
      handleMarkAsread({ stopPropagation: () => {} });
    }
  }, [showDetails]);

  if (!config) return null;
  if (!item) return null;

  const handleDelete = (e) => {
    e.stopPropagation();
    if (!onDeleteItem) return;
    onDeleteItem(item.id);
  };

  const handleMarkAsread = (e) => {
    e.stopPropagation();
    if (!onMarkItemAsRead) return;
    onMarkItemAsRead(item.id);
  };

  return (
    <div
      className={`relative ${hovered ? 'scale-[102%]' : null} transition-transform duration-300 ${hasDetails && !showDetails ? 'cursor-pointer' : null} `}
      key={item.id}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => {
        setHovered(false);
        setShowDetails(false);
      }}
      onClick={() => {
        if (!item?.details || Object.keys(item.details).length === 0) return;
        setShowDetails(true);
      }}
    >
      <div className='absolute left-3 right-3 top-[-12px] flex flex-row h-6 z-10'>
        <div className='flex-initial flex flex-row justify-start items-center'>
          {!item?.is_read && (
            <div className='flex-initial flex flex-row justify-start items-center'>
              <Tooltip title='Unread'>
                <FaCircle className='text-sky-500 text-xs' />
              </Tooltip>
            </div>
          )}
        </div>
        <div className='flex-auto flex flex-row justify-end items-center gap-1'>
          {hovered && (
            <>
              {!item.is_read && (
                <Tooltip title='Mark as read'>
                  <Button size='small' shape='circle' className='animate-appear' onClick={handleMarkAsread}>
                    <FaCheck className='text-xs' />
                  </Button>
                </Tooltip>
              )}
              <Tooltip title='Delete'>
                <Button size='small' shape='circle' className='animate-appear' onClick={handleDelete}>
                  <FaTrash className='text-xs' />
                </Button>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <div className={`z-0 rounded-2xl p-2 transition-shadow ${hovered ? 'shadow-md' : ''} shadow-gray-500/30 }`} style={{ border }}>
        <div className='flex flex-row gap-2'>
          <div className='flex-initial text-2xl'>
            <div className='w-12 h-full flex flex-row justify-center items-center' style={{ color: isDarkMode ? invertColor(config.color) : config.color }}>
              {config.icon}
            </div>
          </div>
          <div className='flex-auto overflow-hidden mr-4'>
            <div className='flex flex-row'>
              <div className='font-bold w-1/2 overflow-hidden'>{config.title}</div>
              <div className='w-1/2 overflow-hidden text-right'>{dayjs(item.created_at).fromNow()}</div>
            </div>

            <div dangerouslySetInnerHTML={{ __html: item.title }} />
            <div className='mt-2 font-extralight' dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        </div>
        {showDetails && (
          <div className='mt-2 ml-14 animate-appear'>
            {['audit_viewer'].includes(item.origin) ? <ReactJson style={{ padding: '2px', border: `0.5px solid ${theme.borderLight}`, borderRadius: '0.25rem', fontSize: '0.6rem' }} src={item?.details ?? {}} theme={isDarkMode ? 'shapeshifter' : 'shapeshifter:inverted'} iconStyle='square' enableClipboard={false} enableAdd={false} enableEdit={false} enableDelete={false} displayDataTypes={false} displayObjectSize={false} /> : <DisplayObjectProps obj={item.details} columns={2} />}
          </div>
        )}
        {hasDetails && !showDetails && (
          <div className='relative h-3'>
            <div className='absolute top-1 left-0 right-0 text-center opacity-20'>
              <FaChevronDown />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

NotificationCenterItem.propTypes = {
  item: PropTypes.object,
  onMarkItemAsRead: PropTypes.func,
  onDeleteItem: PropTypes.func,
};

export default NotificationCenterItem;
