import React from 'react';
import PropTypes from 'prop-types';

const SectionTitle = ({ title }) => {
  return <div className='text-center text-xl font-light border-solid border-l-0 border-t-0 border-r-0 border-b border-sky-50 dark:border-sky-950'>{title ?? 'Unknown title'}</div>;
};

SectionTitle.propTypes = {
  title: PropTypes.any.isRequired,
};

export default SectionTitle;
