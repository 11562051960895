import LangToCountry from './LangToCountry';
import countryCodeToFlagEmoji from 'country-code-to-flag-emoji';

export const getLanguageFlag = (code) => {
  // flags are related to countries, not languages
  // some languages are spoken in multiple countries
  // so we need to map the language code to a country code sometines
  const countryCode = LangToCountry[code];
  if (!countryCode && !code) return null;
  const flag = countryCodeToFlagEmoji(countryCode ?? code);
  return flag;
};
