import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import { AiOutlineFileUnknown } from 'react-icons/ai';
import { invertColor } from 'src/misc/Misc';

const OptionButton = ({ title, icon, onClick, bgColor, bgColorDark, imageBgColor, imageBgColorDark }) => {
  const { isDarkMode, theme } = useTheme();

  return (
    <div
      className='flex flex-col justify-start items-center cursor-pointer opacity-90 min-h-28 w-28 p-2 hover:scale-105 hover:opacity-100 shadow-sm hover:shadow-md shadow-gray-300 dark:shadow-gray-800 border border-solid border-gray-400 dark:border-gray-600 rounded-xl transition-all'
      style={{ backgroundColor: false === isDarkMode ? bgColor : bgColorDark }}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <div className='relative overflow-hidden text-5xl rounded-md w-full p-2 text-center h-20' style={{ backgroundColor: false === isDarkMode ? imageBgColor : invertColor(imageBgColor), color: theme.backgroundBase }}>
        <div className='absolute z-10 roubded-md top-0 right-0 bottom-0 left-0' style={{ background: `linear-gradient(  ${false === isDarkMode ? '#00000033' : '#ffffff66'}, #ffffff00)` }}></div>
        <div className='absolute z-20 rounded-md top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center'>{icon}</div>
      </div>
      <div className='mt-2 overflow-hidden whitespace-nowrap text-xs font-bold text-center'>{title}</div>
    </div>
  );
};

OptionButton.propTypes = {
  title: PropTypes.node.isRequired,
  icon: PropTypes.node,
  bgColor: PropTypes.string,
  bgColorDark: PropTypes.string,
  imageBgColor: PropTypes.string,
  imageBgColorDark: PropTypes.string,
  onClick: PropTypes.func,
};

OptionButton.defaultProps = {
  icon: <AiOutlineFileUnknown />,
  bgColor: '#ffffff',
  bgColorDark: '#202020',
  imageBgColor: '#222222',
  imageBgColorDark: '#e0e7ff',
};

export default OptionButton;
