import React from 'react';
import PropTypes from 'prop-types';
import UserImage from 'src/components/layout/UserImage';
import useTheme from 'src/hooks/useTheme';
import { Skeleton } from 'antd';

const UserSelectorItem = ({ id, name, image, selected, placeholder, onClick }) => {
  const { theme } = useTheme();

  const handleOnClick = () => {
    if (!onClick) return;
    onClick(id);
  };

  if (true === placeholder)
    return (
      <div className='flex flex-row gap-1 items-center py-1 px-2 rounded-md' style={{ userSelect: 'none', border: `1px solid ${theme.borderLight}` }}>
        <Skeleton.Avatar style={{ width: '24px', height: '24px' }} />
        <Skeleton.Input style={{ height: '16px', marginTop: '2px' }} size='small' />
      </div>
    );

  return (
    <div key={id} className={'hover:scale-105 transition-all'} onClick={!selected ? handleOnClick : null}>
      <div className={`flex flex-row gap-1 items-center py-[2px] px-2 rounded-md border border-solid ${!selected ? 'cursor-pointer border-gray-300 dark:border-gray-700' : 'cursor-default border-genesys-azure-500 shadow-selected shadow-genesys-navy-500'}`} style={{ userSelect: 'none' }} onClick={!selected ? handleOnClick : null}>
        <UserImage image={image} size='24px' scaleOnHover={false} />
        <div className='text-xs font-semibold' style={{ fontStretch: 'semi-condensed' }}>
          {name}
        </div>
      </div>
    </div>
  );
};

UserSelectorItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  selected: PropTypes.bool,
  placeholder: PropTypes.bool,
  onClick: PropTypes.func,
};

export default UserSelectorItem;
