import React from 'react';
import PropTypes from 'prop-types';
import UserImage from 'src/components/layout/UserImage';
import { Button, Space, Tooltip } from 'antd';
import { IoPersonRemove } from 'react-icons/io5';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import useTheme from 'src/hooks/useTheme';

const TicketMembersItem = ({ member, onRemove, canManageMembers }) => {
  const { theme } = useTheme();
  // console.log('Member:', member);
  return (
    <div className='flex flex-row gap-2 my-1 w-full'>
      <div>
        <UserImage image={member.user_pic} size={'32px'} />
      </div>
      <div className='flex-auto flex flex-col'>
        <div className='overflow-hidden font-bold'>{member.user_name}</div>
        <div className='text-[0.6rem] font-light overflow-hidden opacity-60'>{member.tenant_name}</div>
        <div className='text-xs font-light overflow-hidden'>{member.role_display_name || member.role}</div>
      </div>
      {canManageMembers && (
        <div>
          <Tooltip title='Remove this person'>
            <YesNoDialog
              title='Remove person from ticket'
              body={
                <>
                  Do you want to remove <strong>{member.user_name}</strong> from the ticket?
                </>
              }
              onYesClick={() => {
                if (!onRemove) return;
                onRemove(member.user_id);
              }}
              labelYes='Yes, remove'
              labelNo='Cancel'>
              <Button disabled={!canManageMembers} className='w-full' onClick={() => {}}>
                <Space className='btn'>
                  <IoPersonRemove style={{ color: theme.primary }} />
                </Space>
              </Button>
            </YesNoDialog>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

TicketMembersItem.propTypes = {
  member: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  canManageMembers: PropTypes.bool,
};

export default TicketMembersItem;
