import React, { useState, useEffect } from 'react';
import CondensedTable from 'src/components/layout/CondensedTable';
import dayjs from 'dayjs';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getFileStats } from 'src/api/microsoft';

//import PropTypes from 'prop-types';

const DownloadStats = () => {
  const { executeAsyncProcess } = useAsyncProcesses();

  const pageSizeOptions = [20, 50, 100];

  const [fileStats, setFileStats] = useState([]);
  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[0]));
  const [page, setPage] = useState(1);

  const columns = [
    {
      title: 'Document',
      key: 'filename',
      render: (record) => <>{record.filename}</>,
    },
    {
      title: '# Of Downloads',
      key: 'record',
      render: (record) => <>{record.items?.length}</>,
      sorter: (a, b) => a.items.length - b.items.length,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Downloaders',
      key: 'items',
      render: (record) => (
        <>
          {record.items.map((item) => {
            return (
              <div key={item.id}>
                <div>{item.email} ({dayjs(item.timestamp).fromNow()})</div>
              </div>
            );
          })}
        </>
      )
    },
  ];

  useEffect(() => {
    if (fileStats?.length > 0) {
      console.log('fileStats', fileStats);
    }
  }, [fileStats]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getFileStats();
        setFileStats(resp);
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <div className='flex flex-col lg:flex-row gap-4'>
      <div className='flex-auto'>
        <CondensedTable
          pagination={{
            showSizeChanger: true,
            pageSizeOptions,
            size: 'small',
            onShowSizeChange: (current, size) => {
              console.log('onShowSizeChange', current, size);
              setPageSize(size);
            },
            current: page,
            pageSize,
            // total,
            onChange: (page) => setPage(page),
          }}
          columns={columns}
          dataSource={fileStats}
        // onRow={(record) => {
        //   return {
        //     onClick: async () => {
        //       window.open(`/master-admin/tenants?tenantId=${record.tenant_id}&tab=features-access`, '_blank');
        //     },
        //     style: { cursor: 'pointer' },
        //   };
        // }}
        />
      </div>
    </div>
  );
};

DownloadStats.propTypes = {};

export default DownloadStats;
