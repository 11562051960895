import React, { useEffect, useState, useContext } from 'react';
import TrendChart2 from './TrendChart2';
import SecurityAdvisorContext from 'src/context/SecurityAdvisorContext';
import dayjs from 'dayjs';
import { red, orange, green, gold } from '@ant-design/colors';
import NoData from 'src/components/layout/NoData';

//import PropTypes from 'prop-types';

const RiskLevelHistory = () => {
  const [chartData, setChartData] = useState(null);
  const { job } = useContext(SecurityAdvisorContext);

  const calculateTrends = () => {
    console.log('[SA Trends] calculating trends...');

    const risks = [
      { treshold: 25, riskLevel: 100, label: 'CRITICAL', color: red[4] },
      { treshold: 50, riskLevel: 75, label: 'HIGH', color: orange[4] },
      { treshold: 75, riskLevel: 50, label: 'MEDIUM', color: gold[4] },
      { treshold: 100, riskLevel: 25, label: 'LOW', color: green[4] },
    ];

    const getRiskLevel = (value) => {
      for (const r of risks) if (value <= r.treshold) return r.riskLevel;
    };

    const getColor = (value) => {
      for (const r of risks) if (value <= r.treshold) return r.color;
    };

    let jobTrends = [];

    // First get workers, and order them by date descending
    const workers = job.workers.sort((a, b) => {
      return new Date(a.updated_at) - new Date(b.updated_at);
    });

    workers.forEach((w) => {
      if (w.score) {
        jobTrends.push({
          date: dayjs(w.updated_at).format('DD/MM/YY'),
          score: getRiskLevel(w.score),
          fill: getColor(w.score),
        });
      }
    });

    console.log('[SA Trends] items processed: ', jobTrends.length);
    console.log('[SA Trends] jobTrends: ', jobTrends);
    if (jobTrends.length > 0) setChartData(jobTrends);
  };

  useEffect(() => {
    if (job?.workers && job.workers.length > 0) calculateTrends();
  }, [job]);

  return (
    <div>
      {!chartData ? (
        <div className='text-2xl text-center m-4'>
          <NoData />
        </div>
      ) : (
        <TrendChart2 datas={chartData} />
      )}
    </div>
  );
};

RiskLevelHistory.propTypes = {};

export default RiskLevelHistory;
