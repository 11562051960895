import React from 'react';
import { Modal, Form } from 'antd';
import PropTypes from 'prop-types';
import QuestionForm from './QuestionForm';

const QuestionCreate = ({ open, onClose, onSubmit }) => {
  const [form] = Form.useForm();

  const handleOnFinish = (values) => {
    console.log('Success:', values);
    if (onSubmit) onSubmit(values);
  };

  return (
    open && (
      <Modal
        open={open}
        title='Create Question'
        footer={null}
        onCancel={() => {
          if (onClose) onClose();
        }}>
        <QuestionForm form={form} onSubmit={handleOnFinish} />
      </Modal>
    )
  );
};

QuestionCreate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

QuestionCreate.defaultProps = {
  open: false,
};

export default QuestionCreate;
