import React from 'react';
import short from 'short-uuid';
import { Skeleton } from 'antd';

const AiModelBadgeSkeleton = () => {
  return (
    <div key={short.generate()} className='flex flex-col gap-2 justify-start items-center cursor-not-allowed opacity-80 min-h-28 w-28 px-2 py-4  dark:shadow-sky-800 border border-solid border-gray-300 dark:border-gray-700 rounded-md transition-all'>
      <div className='text-center'>
        <Skeleton.Avatar active={true} size={56} shape='circle' />
      </div>
      <div className='text-center'>
        <Skeleton.Button active={true} block size={18} />
      </div>
      <div className='text-center'>
        <Skeleton.Button active={true} block size={12} />
      </div>
    </div>
  );
};

AiModelBadgeSkeleton.propTypes = {};

export default AiModelBadgeSkeleton;
