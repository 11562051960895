import React from 'react';
import { MdSecurity } from 'react-icons/md';
import { FaLink } from 'react-icons/fa';
import { Space } from 'antd';
import { ReactComponent as SecurityAdvisorSvg } from 'src/components/svg/security-advisor.svg';
import CorporateColors from 'src/misc/CorporateColors';
//import PropTypes from 'prop-types';

const WelcomeStep = () => {
  return (
    <div className='text-center'>
      <div className='font-bold text-xl flex flex-row items-center justify-center gap-2'>
        Welcome to the <MdSecurity /> Security Advisor tool
      </div>
      <div className='mt-2'>The tool can monitor security risks in your organization.</div>
      <div>
        <SecurityAdvisorSvg className='w-64 h-64' style={{ filter: `drop-shadow(0px 0px 15px ${CorporateColors.aqua})` }} />
      </div>
      <div>
        <a href='/products/security-advisor' target='_blank'>
          <Space className='btn'>
            <FaLink />
            Learn more on the product page
          </Space>
        </a>
      </div>
      <div className='mt-4'>
        This tool requires an <strong>OAuth Client</strong> to work.
        <br /> Click the <strong>Next</strong> button to start the initial configuration.
      </div>
    </div>
  );
};

WelcomeStep.propTypes = {};

export default WelcomeStep;
