import React, { useEffect, useContext } from 'react';
import FsiReportContext from 'src/context/FsiReportContext';
import SnowflakeAccountPicker from 'src/components/controls/SnowflakeAccountPicker/SnowflakeAccountPicker';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getAccOrgs } from 'src/api/snowflake';

import UniqueRegions from '../UniqueRegions.js';
//import PropTypes from 'prop-types';

const PickAccountStep = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { createNewSelectedAccount, setCreateNewSelectedAccount, setCreateNewRegionList, setCreateNewUniqueRegions } = useContext(FsiReportContext);

  useEffect(() => {
    if (!createNewSelectedAccount) {
      setCreateNewRegionList(null);
      setCreateNewUniqueRegions([]);
      return;
    }
    loadRegions();
  }, [createNewSelectedAccount]);

  const loadRegions = async () => {
    console.log('loadRegions', createNewSelectedAccount);
    await executeAsyncProcess(async () => {
      try {
        const orgs = await getAccOrgs(createNewSelectedAccount.ACCOUNT_ID);
        setCreateNewRegionList(orgs);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleSelect = (regions) => {
    console.log('handleSelect', regions);
    setCreateNewUniqueRegions(regions);
  };

  return (
    <div>
      <h2>Select an account:</h2>
      <SnowflakeAccountPicker
        initialValue={createNewSelectedAccount}
        onSelect={(e) => {
          setCreateNewSelectedAccount(e);
        }}
        displayOrganizationCount={true}
        showOnlyWithOrganization={true}
      />
      <p className='text-xs text-genesys-gray-500'>Only active accounts with at least one Genesys Cloud deployment will be displayed.</p>

      {createNewSelectedAccount && (
        <>
          <p className='mb-1'>Regions:</p>
          <UniqueRegions onSelect={handleSelect} wizardStep={true} />
        </>
      )}
    </div>
  );
};

PickAccountStep.propTypes = {};

export default PickAccountStep;
