import { useContext } from 'react';
import GlobalContext from 'src/context/GlobalContext';

const useBreadcrumb = () => {
  const { breadcrumbItems, setBreadcrumbItems } = useContext(GlobalContext);

  const setBreadcrumb = (items) => {
    setBreadcrumbItems(items);
  };

  const dropBreadcrumb = () => {
    setBreadcrumbItems(null);
  };

  return { breadcrumbItems, setBreadcrumb, dropBreadcrumb };
};

export default useBreadcrumb;
