import React from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';
//import PropTypes from 'prop-types';

const CondensedTabs1 = styled.div`
  .ant-tabs-tab {
    padding: 2px 8px !important;
  }
  .ant-tabs-nav {
    margin-bottom: 4px !important;
  }
`;

const CondensedTabs = (props) => {
  return (
    <CondensedTabs1>
      <Tabs {...props} />
    </CondensedTabs1>
  );
};

//CondensedTabs.propTypes = {};

export default CondensedTabs;
