import React, { useContext } from 'react';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';

//import PropTypes from 'prop-types';

const DSRSummaryStep = () => {
  const { jobName, useRealApi, requestType, subject, mode, forceCleanup } = useContext(DataSubjectRequestsContext);

  return (
    <div>
      <div className='font-bold text-xl '>Summary</div>
      <div className='font-extralight'>
        <div className='mt-3'>
          Here is a summary of what you have selected:
          <div className='mt-2'>
            <table className='table-auto'>
              <tbody>
                <tr>
                  <td className='text-right'>Name:</td>
                  <td>{jobName}</td>
                </tr>
                {mode === 'requests' && (
                  <>
                    <tr>
                      <td className='text-right'>Use Real API:</td>
                      <td>{useRealApi || 'false'}</td>
                    </tr>
                    <tr>
                      <td className='text-right'>Type:</td>
                      <td>{requestType}</td>
                    </tr>
                    <tr>
                      <td className='text-right'>Subject:</td>
                      <td>{subject.name}</td>
                    </tr>
                  </>
                )}
                {mode === 'demo' && (
                  <>
                    <tr>
                      <td className='text-right'>Force Cleanup:</td>
                      <td>{forceCleanup?.toString() || 'false'}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className='mt-4'>
          Click on <strong>Next</strong> to start the process.
        </div>
      </div>
    </div>
  );
};

DSRSummaryStep.propTypes = {};

export default DSRSummaryStep;
