import React from 'react';
import PropTypes from 'prop-types';
import CondensedTable from 'src/components/layout/CondensedTable';

const Contacts = ({ data }) => {
  const columns = [
    {
      title: 'Designated contacts',
      key: 'Designated contacts',
      render: (record) => {
        return (
          <div>
            <div className='text-sm'>{record.NAME}</div>
            <div className='text-xs font-light opacity-60'>{record.TITLE}</div>
            {record.PHONE && (
              <div className='text-xs text-right'>
                <span className=' font-light opacity-60'>phone: </span>
                {record.PHONE}
              </div>
            )}
            {record.EMAIL && (
              <div className='text-xs text-right'>
                <span className='font-light opacity-60'>email: </span>
                {record.EMAIL}
              </div>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <CondensedTable
      columns={columns}
      dataSource={data}
      pagination={{
        pageSize: 5,
        size: 'small',
      }}
    />
  );
};

Contacts.propTypes = {
  data: PropTypes.array,
};

export default Contacts;
