import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const FieldCustomObject = ({ propName, fieldDef, defaultValue, onChange }) => {
  const [data, setData] = useState(defaultValue || []);

  useEffect(() => {
    setData(defaultValue || []);
  }, [defaultValue]);

  const handleRowChange = (index, key, value) => {
    const updatedValue = [...data];
    updatedValue[index][key] = value;
    setData(updatedValue);
    onChange({ propName, value: updatedValue });
  };

  const handleNestedChange = (index, nestedPropName, value) => {
    const updatedValue = [...data];
    updatedValue[index][nestedPropName] = value;
    setData(updatedValue);
    onChange({ propName, value: updatedValue });
  };

  const addRow = () => {
    const newValue = [...data, {}];
    setData(newValue);
    onChange({ propName, value: newValue });
  };

  const removeRow = (index) => {
    const newValue = data.filter((_, i) => i !== index);
    setData(newValue);
    onChange({ propName, value: newValue });
  };

  const columns = Object.keys(fieldDef.items.properties).map((key) => {
    if (key === 'items' && fieldDef.items.properties[key].type === 'array') {
      // Nested array handling
      return {
        title: fieldDef.items.properties[key].description || key,
        dataIndex: key,
        key,
        render: (_, record, index) => <FieldCustomObject propName={`${propName}[${index}].${key}`} fieldDef={fieldDef.items.properties[key]} defaultValue={record[key]} onChange={(e) => handleNestedChange(index, key, e.value)} />,
      };
    }

    // Standard fields
    return {
      title: fieldDef.items.properties[key].description || key,
      dataIndex: key,
      key,
      render: (_, record, index) => <Input value={record[key]} onChange={(e) => handleRowChange(index, key, e.target.value)} />,
    };
  });

  columns.push({
    title: 'Actions',
    key: 'actions',
    align: 'center',
    render: (_, __, index) => (
      <Button type='text' size='small' onClick={() => removeRow(index)}>
        <DeleteOutlined />
      </Button>
    ),
  });

  return (
    <div>
      <Table size='small' dataSource={data} columns={columns} rowKey={(record, index) => index} pagination={false} />
      <div className='mt-4'>
        <Button type='primary' onClick={addRow}>
          Add Row
        </Button>
      </div>
    </div>
  );
};

FieldCustomObject.propTypes = {
  propName: PropTypes.string.isRequired,
  fieldDef: PropTypes.object.isRequired,
  defaultValue: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default FieldCustomObject;
