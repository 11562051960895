import React, { Component } from 'react';
import DashboardsContext from './DashboardsContext';

export default class DashboardsProvider extends Component {
  state = {
    layoutItemsSelector: null,
  };

  render() {
    return (
      <DashboardsContext.Provider
        value={{
          ...this.state,
          setLayoutItemsSelector: (layoutItemsSelector) => {
            this.setState({ layoutItemsSelector });
          },
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </DashboardsContext.Provider>
    );
  }
}
