import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';

export const getPageVisits = async (url) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/stats/pagevisits?url=${url}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const logPageVisit = async (url, userInfo) => {
  const headers = await getAccessHeaders();

  if (!userInfo || !userInfo.id || !userInfo.tenant_id) {
    return;
  }

  return handleResponse(
    await fetch(`${ENV.apiUrl}/stats/pagevisits?url=${url}&userId=${userInfo.id}&tenantId=${userInfo.tenant_id}`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};
