import { useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';
import PropTypes from 'prop-types';
import { SCREEN_MODES } from '../../misc/Config';

const WhenDesktop = ({ children }) => {
  const globalContext = useContext(GlobalContext);
  if (globalContext.screenMode === SCREEN_MODES.desktop) return children;
  return null;
};

WhenDesktop.propTypes = {
  children: PropTypes.any,
};

export default WhenDesktop;
