import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import FsiReportContext from 'src/context/FsiReportContext';
import { Tabs, Switch, Space, Button, Input, Tooltip } from 'antd';
import { CheckOutlined, ExclamationOutlined, DeleteOutlined, WarningOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { getLanguageFlag } from 'src/components/controls/LangPicker/LangFlag';
import useTheme from 'src/hooks/useTheme';
import Deprecations from './Deprecations';
import Incidents from './Incidents';
import dayjs from 'dayjs';
import { notArrayOrEmpty } from 'src/misc/Misc';

/*

* User creates a new project using the wizard
* User selects the SFDC account, languages and regions
* Project is created with all questions by default
* User can add, remove or modify questions from the project
* User works on the answers
* User has to click on the "Reviewed" switch to mark the question as reviewed
* When reviewed, set the question as reviewed in the DB and set the last reviewed date for this question
* When loading the project again, disable the reviewed switch if the question was last reviewed more than a month ago

*/

const StyledDiv = styled.div`
  .ant-switch.review-switch {
    background-color: ${(props) => props.theme.red} !important;
  }

  .ant-switch.ant-switch-checked.review-switch {
    background-color: ${(props) => props.theme.green} !important;
  }
`;

const { TabPane } = Tabs;
const { TextArea } = Input;

const QuestionCard = ({ projectQuestion, onChange, onDelete, onMoveUp, onMoveDown, onTranslationChange, onTranslationChangeOther, isLast, isFirst }) => {
  const { theme } = useTheme();
  const { currentProject, setExternalAnswer, currentProjectQuestionsDepricated } = useContext(FsiReportContext);

  const determineCurrentLanguage = () => (true === currentProject?.props?.langs?.includes('en') ? 'en' : currentProject?.props?.langs[0]);

  const [currentLanguage, setCurrentLanguage] = useState(determineCurrentLanguage());
  const [currentTranslation, setCurrentTranslation] = useState(null);
  const [additionalText, setAdditionalText] = useState(null);

  useEffect(() => setCurrentLanguage(determineCurrentLanguage()), [currentProject]);
  useEffect(() => setCurrentTranslation(projectQuestion?.translations?.find((q) => q.language === currentLanguage)), [currentLanguage, projectQuestion]);
  useEffect(() => setAdditionalText(currentTranslation?.default_answer_text), [currentTranslation]);

  const handleIsReviewedChange = async (checked) => {
    if (!onChange) return;
    onChange({ questionId: projectQuestion.question_id, changes: { reviewed: checked, reviewed_date: checked ? dayjs().format() : null } });
  };

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
  };

  const handleAdditionalTextOnChange = (e) => {
    setAdditionalText(e.target.value);
  };

  const handleAdditionalTextOnCancel = () => {
    setAdditionalText(currentTranslation?.default_answer_text);
  };

  const handleAdditionalTextOnSave = (e) => {
    if (!onChange) return;
    onTranslationChange({ questionId: projectQuestion.question_id, language: currentLanguage, changes: { default_answer_text: additionalText } });
  };

  const handleOnDelete = () => {
    if (!onDelete) return;
    onDelete(projectQuestion.question_id);
  };

  const handleOnMoveUp = () => {
    if (!onMoveUp) return;
    onMoveUp(projectQuestion.question_id);
  };

  const handleOnMoveDown = () => {
    if (!onMoveDown) return;
    onMoveDown(projectQuestion.question_id);
  };

  // Update the default answer text
  // const handleDefaultAnswerText = async (e) => {
  //   // const updatedQuestion = { ...currentProject.questions.find((q) => q.id === question.id), default_answer_text: e.target.value };
  //   // patchCurrentProjectQuestion(updatedQuestion);
  //   // // Save to DB
  //   // if (isReviewed) {
  //   //   console.log('Updating DB questions:', currentProject.questions);
  //   //   //TODO: Do not use await here otherwise this will block the UI? This might cause a race condition
  //   //   patchProjectQuestions(currentProject.id, currentProject.questions);
  //   // }
  // };

  const isDefaultAnswerTextModified = () => additionalText !== currentTranslation?.default_answer_text;

  const addReviewDeprocatedBadge = (control) => {
    if (true === projectQuestion.reviewed) return control;
    if (true === notArrayOrEmpty(currentProjectQuestionsDepricated)) return control;
    if (true !== currentProjectQuestionsDepricated.some((q) => q.question_id === projectQuestion.question_id)) return control;
    console.log('Deprocated question:', projectQuestion.question_id, currentProjectQuestionsDepricated);
    return (
      <div className='flex flex-row justify-center items-center gap-1'>
        {control}
        <Tooltip title='Review has been disabled because of deprication'>
          <WarningOutlined className='animate-pulse text-orange-800 dark:text-orange-300 text-lg' />
        </Tooltip>
      </div>
    );
  };

  if (!currentTranslation) return null;

  return (
    <StyledDiv theme={theme} className={'rounded-lg  pr-4 pl-4 pb-2 mb-4 flex'} style={{ border: `1px solid ${theme.backgroundMedium}` }}>
      <div className='flex flex-row w-full'>
        <div className='flex-initial pt-8'>
          <Tabs tabPosition='left' defaultActiveKey={currentLanguage} onChange={handleLanguageChange} className='flex-shrink-0'>
            {currentProject?.props.langs.includes('en') ? (
              <TabPane
                tab={
                  <div className=''>
                    {getLanguageFlag('en')}
                    <br />
                    EN
                  </div>
                }
                key='en'
                disabled={!currentProject?.props.langs.includes('en')}
              />
            ) : (
              <></>
            )}
            {currentProject?.props.langs.includes('de') ? (
              <TabPane
                tab={
                  <div className=''>
                    {getLanguageFlag('de')}
                    <br />
                    DE
                  </div>
                }
                key='de'
                disabled={!currentProject?.props.langs.includes('de')}
              />
            ) : (
              <></>
            )}
            {currentProject?.props.langs.includes('fr') ? (
              <TabPane
                tab={
                  <div className=''>
                    {getLanguageFlag('fr')}
                    <br />
                    FR
                  </div>
                }
                key='fr'
                disabled={!currentProject?.props.langs.includes('fr')}
              />
            ) : (
              <></>
            )}
          </Tabs>
        </div>
        <div className='flex-auto'>
          {/* <HEADER> */}
          <div className='flex items-center justify-between'>
            <div className='flex items-center space-x-4'>
              <h3 className='text-xl font-semibold'>{currentTranslation?.title || 'Untitled Question'}</h3>
            </div>
            <div className='flex flex-row gap-1 items-center'>
              <Tooltip title='Click to move the question up'>
                <Button type='text' onClick={handleOnMoveUp} disabled={isFirst}>
                  <UpOutlined />
                </Button>
              </Tooltip>
              <Tooltip title='Click to move the question down'>
                <Button type='text' onClick={handleOnMoveDown} disabled={isLast}>
                  <DownOutlined />
                </Button>
              </Tooltip>
              <Tooltip title='Click to remove the question'>
                <Button type='text' onClick={handleOnDelete}>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
              {addReviewDeprocatedBadge(
                <Switch
                  className='review-switch'
                  checkedChildren={
                    <Space>
                      <CheckOutlined />
                      Reviewed
                    </Space>
                  }
                  unCheckedChildren={
                    <Space>
                      <ExclamationOutlined />
                      Not Reviewed
                    </Space>
                  }
                  checked={projectQuestion.reviewed}
                  onChange={handleIsReviewedChange}
                />
              )}
            </div>
          </div>
          {/* </HEADER> */}
          <div className='mb-4'>{currentTranslation?.question}</div>
          {currentTranslation?.default_answer && (
            <div className='mb-4 text-sm font-bold'>
              <span className='opacity-60 mr-2'>Response:</span>
              <Switch
                checkedChildren={<span>yes</span>}
                unCheckedChildren={<span>no</span>}
                checked={currentTranslation?.default_answer === 'no' ? false : true}
                onChange={() => {
                  const langs = currentProject?.props?.langs;
                  const newValue = currentTranslation?.default_answer === 'no' ? 'yes' : 'no';
                  for (const lang of langs) {
                    if (lang === currentLanguage) onTranslationChange({ questionId: projectQuestion.question_id, language: currentLanguage, changes: { default_answer: newValue } });
                    else onTranslationChangeOther({ questionId: projectQuestion.question_id, language: lang, changes: { default_answer: newValue } });
                  }
                }}
              />
            </div>
          )}

          {/* Custom Components */}
          {projectQuestion?.type === 'deprecations' && <Deprecations exportData={(x) => setExternalAnswer({ deprecations: x })} />}
          {projectQuestion?.type === 'incidents' && <Incidents regions={currentProject?.props?.uniqueAwsRegions} exportData={(x) => setExternalAnswer({ incidents: x })} />}
          {/* Custom Components */}

          <div className='mb-4'>
            {/* <TextArea placeholder='Additional text (optional)' value={currentTranslation?.default_answer_text} autoSize={{ minRows: 3 }} className='w-full border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white' onChange={handleDefaultAnswerText} onBlur={handleDefaultAnswerText} /> */}
            <div>
              <TextArea placeholder='Additional text (optional)' value={additionalText} autoSize={{ minRows: 3 }} className='w-full' onChange={handleAdditionalTextOnChange} style={{ border: true === isDefaultAnswerTextModified() ? '2px solid red' : null }} />
            </div>
            {isDefaultAnswerTextModified() && (
              <div className='text-right'>
                <Button type='primary' className='mt-2' onClick={handleAdditionalTextOnSave} size='small'>
                  Save
                </Button>
                <Button className='mt-2 ml-2' onClick={handleAdditionalTextOnCancel} size='small'>
                  Cancel
                </Button>
              </div>
            )}
          </div>
          <div className='flex justify-end gap-2'>
            <span className='text-[0.6rem]'>
              <span className='font-light opacity-20'>question id:</span> <span className='opacity-30'>{projectQuestion?.question_id}</span>
            </span>
            <span className='text-[0.6rem]'>
              <span className='font-light opacity-20'>order:</span> <span className='opacity-30'>{projectQuestion?.order}</span>
            </span>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

QuestionCard.propTypes = {
  projectQuestion: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  onTranslationChange: PropTypes.func.isRequired,
  onTranslationChangeOther: PropTypes.func.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default QuestionCard;
