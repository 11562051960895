import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuditViewerContext from 'src/context/AuditViewerContext';
import { notEmptyArray } from 'src/misc/Misc';
import FilterPanelItem from './FilterPanelItem';
import { TbFilterOff } from 'react-icons/tb';
import { Alert, Button, Tag } from 'antd';
import SubscriptionAddNew from '../Events/SubscriptionAddNew';
//import PropTypes from 'prop-types';

const FilterPanel = () => {
  const { currentUserFilterConfig, ebIntegrationInstalled, hasCurrentUserSubscribed, setSnsSubscriptionsAddNew } = useContext(AuditViewerContext);
  const navigate = useNavigate();
  return (
    <>
      <SubscriptionAddNew />
      {false === ebIntegrationInstalled && (
        <div className='mb-4'>
          <Alert
            closable
            message='Real-time notifications'
            description={
              <>
                <div>Audit Viewer can automatically send real-time notifications to your email mailbox. In order to receive notifications you need to perform following steps:</div>

                <div className='mt-4'>
                  <strong>Step 1</strong>
                  <br />
                  Configure Event Bridge integration in your organization.
                </div>
                <div className='mt-1'>
                  <Button type='primary' size='small' onClick={() => navigate('/audit-viewer/events')}>
                    Configure now
                  </Button>
                </div>
                <div className='mt-2 opacity-40'>
                  <strong>Step 2</strong>
                  <br />
                  Add email subscription.
                </div>
                <div className='mt-2 opacity-40'>
                  <strong>Step 3</strong>
                  <br />
                  Subscribe for a selected filter selection.
                </div>
              </>
            }
            type='info'
            showIcon
          />
        </div>
      )}
      {true === ebIntegrationInstalled && false === hasCurrentUserSubscribed && (
        <div className='mb-4'>
          <Alert
            closable
            message='Real-time notifications'
            description={
              <>
                <div>Audit Viewer can automatically send real-time notifications to your email mailbox. In order to receive notifications you need to perform following steps:</div>

                <div className='mt-4'>
                  <strong className='opacity-40'>Step 1</strong> <Tag color='blue'>Done</Tag>
                  <br />
                  <span className='opacity-40'>Configure Event Bridge integration in your organization.</span>
                </div>

                <div className='mt-2'>
                  <strong>Step 2</strong>
                  <br />
                  Add email subscription.
                </div>
                <div className='mt-1'>
                  <Button type='primary' size='small' onClick={() => setSnsSubscriptionsAddNew(true)}>
                    Add now
                  </Button>
                </div>
                <div className='mt-2 opacity-40'>
                  <strong>Step 3</strong>
                  <br />
                  Subscribe for a selected filter selection.
                </div>
              </>
            }
            type='info'
            showIcon
          />
        </div>
      )}

      {true === notEmptyArray(currentUserFilterConfig) ? (
        <>
          {currentUserFilterConfig.map((filterItem, index) => (
            <div key={index}>
              <FilterPanelItem key={index} filterItem={filterItem} />
            </div>
          ))}
        </>
      ) : (
        <div className='py-8 text-center opacity-20 flex flex-col gap-2'>
          <div>
            <TbFilterOff className='text-4xl' />
          </div>
          <div>No data</div>
        </div>
      )}
    </>
  );
};

FilterPanel.propTypes = {};

export default FilterPanel;
