import React, { useEffect } from 'react';
import Header from 'src/components/pages/Landing/components/ui/Header';
import { TbFaceIdError } from 'react-icons/tb';
import queryString from 'query-string';
import Space2 from 'src/components/layout/Space2';
import { SESSION_STORAGE_KEYS } from 'src/misc/Config';
import AccessDenied from './ErrorDetails/AccessDenied';
import NoAdmins from './ErrorDetails/NoAdmins';
import UnknownAccType from './ErrorDetails/UnknownAccType';
import TenantNotFound from './ErrorDetails/TenantNotFound';
import UserLimitReached from './ErrorDetails/UsersLimitReached';
import UserAccountDisabled from './ErrorDetails/UserAccountDisabled';
import { logout } from 'src/misc/Session';
import useUserInfo from 'src/hooks/useUserInfo';
import { objToQueryStringParams } from 'src/misc/Misc';
import { Alert } from 'antd';
//import PropTypes from 'prop-types';

const SignInError = () => {
  const userInfo = useUserInfo();
  const { error, error_description } = queryString.parse(window.location.search);

  useEffect(() => {
    console.log('error', error);
    console.log('error_description', error_description);
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.authTargetPath);
    if (userInfo) {
      logout(null, `${window.location.pathname}${objToQueryStringParams({ error, error_description })}`);
      return;
    }
  }, []);

  const HANDLED_ERRORS = ['no_tenant_admins', 'acc_type_unknown', 'tenant_not_found', 'users_limit_reached', 'user_account_disabled'];

  return (
    <>
      <div className='fixed z-50'>
        <Header hideSignInBtn={true} />
      </div>
      <div className='absolute flex flex-col items-center w-full'>
        <div className='mt-24 w-full xl:w-[1200px]'>
          <div className='mx-4'>
            <div className='my-8 font-bold text-2xl'>
              <Space2>
                <TbFaceIdError className={'text-4xl'} />
                Oops... we couldn't sign you in
              </Space2>
            </div>
            {!HANDLED_ERRORS.includes(error) && (
              <>
                <Alert type='error' message={error} description={error_description} showIcon />
              </>
            )}
            {error === 'access_denied' && error_description === 'the client has not been authorized for the organization' && <AccessDenied />}
            {error === 'access_denied' && error_description === "the client's authorization has been revoked" && <AccessDenied />}
            {error === 'no_tenant_admins' && <NoAdmins />}
            {error === 'acc_type_unknown' && <UnknownAccType />}
            {error === 'tenant_not_found' && <TenantNotFound />}
            {error === 'users_limit_reached' && <UserLimitReached />}
            {error === 'user_account_disabled' && <UserAccountDisabled />}
          </div>
        </div>
      </div>
    </>
  );
};

// SignInError.propTypes = {};

export default SignInError;
