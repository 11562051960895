import React, { Component } from 'react';
import NotificationsContext from './NotificationsContext';
import { getMyNotifications } from 'src/api/notifications';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';

export default class GlobalProvider extends Component {
  state = {
    notifications: [],
    dbNotifications: [],
    dbActionPerformed: false,
  };

  componentDidMount = async () => {
    try {
      const authToken = localStorage.getItem(LOCAL_STORAGE_KEYS.authAccessToken);
      if (!authToken) return;
      if (window.location.pathname === '/' || window.location.pathname.startsWith('/signin') || window.location.pathname.startsWith('/security-qa-widget/') || window.location.pathname.startsWith('/reports/') || window.location.pathname.startsWith('/products/') || window.location.pathname.startsWith('/org-setup') || window.location.pathname.startsWith('/landing') || window.location.pathname.startsWith('/404') || window.location.pathname.startsWith('/403')) {
        return;
      }
      // console.log('[NotificationsProvider] componentDidMount() - getMyNotifications()');
      // this.setState({ dbNotifications: await getMyNotifications() });
    } catch (error) {
      console.error(error);
    }
  };

  refreshMyDbNotifications = async () => {
    console.log('refreshMyDbNotifications()');
    const myNotifications = await getMyNotifications();
    this.setState({ dbNotifications: myNotifications });
    this.setState({ dbActionPerformed: false });
  };

  render() {
    return (
      <NotificationsContext.Provider
        value={{
          dbNotifications: this.state.dbNotifications,
          refreshMyDbNotifications: this.refreshMyDbNotifications,
          addDbNotification: (newItem) => this.setState({ dbNotifications: [...this.state.dbNotifications, newItem] }),
          removeAlldbNotifications: () => this.setState({ dbNotifications: [] }),
          notifications: this.state.notifications,
          setNotifications: (notifications) => this.setState({ notifications }),
          addNotification: (notification) => {
            const notifications = [...this.state.notifications];
            notifications.unshift(notification);
            this.setState({ notifications });
            if (notification?.db) {
              //   this.refreshMyDbNotifications(); //TODO: No other way, as event from WS is not triggered on AppLyaout.js. Find a solution
              this.setState({ dbActionPerformed: true });
            }
          },
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </NotificationsContext.Provider>
    );
  }
}
