import React, { useContext } from 'react';
import { Input } from 'antd';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';

//import PropTypes from 'prop-types';

const DSRForceCleanupStep = () => {
  const { jobName, setJobName } = useContext(DataSubjectRequestsContext);

  return (
    <div>
      <div className='font-bold text-xl '>Name</div>
      <div className='font-extralight'>
        <div className='mt-3'>
          Give your request a name
          <div className='mt-2'>
            <Input
              value={jobName}
              onChange={(e) => {
                setJobName(e.target.value);
              }}
              placeholder='Enter a name for your request here'
            />
          </div>
        </div>
        <div className='mt-2'>
          Then click on <strong>Next</strong> to continue.
        </div>
      </div>
    </div>
  );
};

DSRForceCleanupStep.propTypes = {};

export default DSRForceCleanupStep;
