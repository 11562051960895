import styled from 'styled-components';
import { yellow, blue } from '@ant-design/colors';

const StylesWrapper = styled.div`
  .anticon-folder {
    color: ${yellow[6]} !important;
    font-size: 1.1rem;
  }

  .anticon-folder-open {
    color: ${yellow[6]} !important;
    font-size: 1.1rem;
  }

  .anticon-file {
    color: ${blue[6]} !important;
  }

  .exp-vwr-panel {
    height: 360px;
    overflow: auto;
  }

  .exp-vwr-panel-right {
    border-left: 1px solid #dddddd;
  }
`;

export default StylesWrapper;
