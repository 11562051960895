import React, { useContext } from 'react';
import AuditViewerContext from 'src/context/AuditViewerContext';
import PropTypes from 'prop-types';
import { TbPointFilled, TbFilterMinus, TbMail, TbBellFilled } from 'react-icons/tb';
import { FaCircle } from 'react-icons/fa';
import { Tooltip, Space } from 'antd';

const FilterPanelItem = ({ filterItem }) => {
  const { ebIntegrationInstalled, hasCurrentUserSubscribed, serviceName, entityType, action, setServiceName, setEntityType, setAction, removeCurrentUserFilterList, subCurrentUserFilterList, unsubCurrentUserFilterList } = useContext(AuditViewerContext);

  const handleOnSelect = () => {
    console.log('handleOnSelect');
    setServiceName(filterItem.serviceName);
    setEntityType(filterItem.entityType);
    setAction(filterItem.action);
  };

  const handleOnFilterRemove = () => {
    console.log('handleOnFilterRemove');
    removeCurrentUserFilterList(filterItem);
  };

  const handleOnFilterSubscribe = () => {
    console.log('handleOnFilterSubscribe');
    subCurrentUserFilterList(filterItem);
  };

  const handleOnFilterUnsubscribe = () => {
    console.log('handleOnFilterUnsubscribe');
    unsubCurrentUserFilterList(filterItem);
  };

  const handleOnFilterSubscribeLive = () => {
    console.log('handleOnFilterSubscribeLive');
    subCurrentUserFilterList(filterItem, 'subscribedLive');
  };

  const handleOnFilterUnsubscribeLive = () => {
    console.log('handleOnFilterUnsubscribeLive');
    unsubCurrentUserFilterList(filterItem, 'subscribedLive');
  };

  return (
    <div className='rounded-md border border-solid border-gray-100 dark:border-gray-800 flex flex-row'>
      <div className='px-1 py-1 flex flex-row items-center'>
        {ebIntegrationInstalled ? (
          <Space className='btn'>
            {hasCurrentUserSubscribed ? (
              <>
                {filterItem.subscribed ? (
                  <Tooltip title='Email Notifications: ON'>
                    <TbMail className='text-sky-700 dark:text-sky-300 cursor-pointer hover:scale-125' onClick={handleOnFilterUnsubscribe} />
                  </Tooltip>
                ) : (
                  <Tooltip title='Email Notifications: OFF'>
                    <TbMail className='opacity-20 text-sky-700 dark:text-sky-300 cursor-pointer hover:scale-125' onClick={handleOnFilterSubscribe} />
                  </Tooltip>
                )}
              </>
            ) : (
              <>
                <Tooltip title='Email not subscribed'>
                  <TbMail className='opacity-20 text-gray-700 dark:text-gray-300 cursor-pointer hover:scale-125' />
                </Tooltip>
              </>
            )}
            {filterItem.subscribedLive ? (
              <Tooltip title='Live Notifications: ON'>
                <TbBellFilled className='text-sky-700 dark:text-sky-300 cursor-pointer hover:scale-125' onClick={handleOnFilterUnsubscribeLive} />
              </Tooltip>
            ) : (
              <Tooltip title='Live Notifications: OFF'>
                <TbBellFilled className='opacity-20 text-sky-700 dark:text-sky-300 cursor-pointer hover:scale-125' onClick={handleOnFilterSubscribeLive} />
              </Tooltip>
            )}
          </Space>
        ) : (
          <>
            <Tooltip title='Notifications are not configured'>
              <FaCircle className='opacity-20 text-gray-700 dark:text-gray-300 cursor-pointer hover:scale-125' />
            </Tooltip>
          </>
        )}
      </div>
      <div className='flex-auto cursor-pointer hover:font-semibold px-4 py-1 flex flex-row items-center gap-2 overflow-hidden' onClick={handleOnSelect}>
        {filterItem.serviceName} <span className='opacity-40'>/</span> {filterItem.entityType} <span className='opacity-40'>/</span> {filterItem.action}
        {serviceName === filterItem.serviceName && entityType === filterItem.entityType && action === filterItem.action && <TbPointFilled className='text-blue-700 dark:text-blue-300' />}
      </div>
      <div className='px-4 py-1 flex flex-row items-center gap-2'>
        <Tooltip title='Delete'>
          <TbFilterMinus className='text-red-700 dark:text-red-300 cursor-pointer hover:scale-125' onClick={handleOnFilterRemove} />
        </Tooltip>
      </div>
    </div>
  );
};

FilterPanelItem.propTypes = {
  filterItem: PropTypes.object.isRequired,
};

export default FilterPanelItem;
