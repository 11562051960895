import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logPageVisit } from 'src/api/pageVisits'; // This should be your actual API call
import useUserInfo from 'src/hooks/useUserInfo';

function PageViewTracker() {
  const location = useLocation();
  const userInfo = useUserInfo();

  useEffect(() => {
    try {
      if (!userInfo) {
        return;
      }

      console.log('UserInfo:', userInfo);

      logPageVisit(location.pathname, userInfo);
    } catch (error) {
      console.error('Error tracking page visit:', error);
    }
  }, [location]);

  return null; // This component doesn't render anything
}

export default PageViewTracker;
