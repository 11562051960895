import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Space, Input, Button, message } from 'antd';
import { notEmptyArray } from 'src/misc/Misc';
import { FaRegTrashCan } from 'react-icons/fa6';
import { TbWorldWww } from 'react-icons/tb';
import { uuid } from 'short-uuid';

const StepScrap = ({ urls }) => {
  const [localUrls, setLocalUrls] = useState([]);
  const [url, setUrl] = useState('');

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setLocalUrls(true === notEmptyArray(urls) ? urls : []);
  }, [urls]);

  return (
    <>
      {contextHolder}
      <div className='flex flex-row'>
        <div style={{ borderRight: '1px solid gray' }}>
          <TbWorldWww className='text-5xl mr-8' />
        </div>
        <div className='flex-auto ml-8'>
          {true === notEmptyArray(localUrls) &&
            localUrls.map((url, index) => (
              <div key={index} className='my-2'>
                <Space>
                  {url}
                  <FaRegTrashCan
                    className='cursor-pointer opacity-60 hover:opacity-100'
                    onClick={() => {
                      const lpf = [...localUrls];
                      lpf.splice(index, 1);
                      setLocalUrls(lpf);
                    }}
                  />
                </Space>
              </div>
            ))}

          <div className='mt-4'>
            <Space>
              <Input placeholder='Enter URL' value={url} onChange={(e) => setUrl(e.target.value)} />
              <Button
                className='ml-2'
                onClick={() => {
                  if (!url) return;
                  const lu = [...localUrls];
                  lu.push(url);
                  setLocalUrls(lu);
                  setUrl('');

                  messageApi.open({
                    key: uuid(),
                    type: 'success',
                    content: 'The URL has been added',
                    duration: 3,
                  });
                }}
              >
                Add URL
              </Button>
            </Space>
          </div>
        </div>
      </div>
    </>
  );
};

StepScrap.propTypes = {
  urls: PropTypes.array,
};

export default StepScrap;
