import React from 'react';
import { Alert } from 'antd';

//import PropTypes from 'prop-types';

const TenantNotFound = () => {
  return (
    <>
      <div className='mt-8'>
        <Alert message={<span className='font-bold'>Before Genesys Cloud users can sign in using your organization, an administrator needs to complete the initial portal setup.</span>} type='warning' showIcon />
        <div className='my-4 font-bold text-2xl'>What should you do?</div>
        <div>
          <ul className='list-decimal space-y-4'>
            <li>
              Please contact your <span className='font-bold text-genesys-arctic-500 dark:text-genesys-arctic-400'>Genesys Cloud</span> administrator to navigate to <a href='https://spc.genesys.com'>https://spc.genesys.com</a> and sign first.
            </li>
            <li>
              If you are an administrator and you are seeing this message, please reach out to <a href='mailto:spc.portal@genesys.com'>spc.portal@genesys.com</a> and we will help you. Make sure you have the <span className='font-bold text-genesys-arctic-500 dark:text-genesys-arctic-400'>admin</span> role in your <span className='font-bold text-genesys-arctic-500 dark:text-genesys-arctic-400'>Genesys Cloud</span> organization though before you contact us.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

TenantNotFound.propTypes = {};

export default TenantNotFound;
