import React from 'react';
import PropTypes from 'prop-types';
import useUserInfo from 'src/hooks/useUserInfo';

const WhenEmeabilling = ({ children }) => {
  const { tenant_id } = useUserInfo();
  if (tenant_id !== '3b03b67a-2349-4a03-8b28-c8ac5c26c49a') return null;
  return <>{children}</>;
};

WhenEmeabilling.propTypes = {
  children: PropTypes.any,
};

export default WhenEmeabilling;
