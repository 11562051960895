import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';

const Content = ({ children }) => {
  const { theme } = useTheme();

  return (
    <div className='w-full p-1 lg:p-2 overflow-auto'>
      <div className='p-2 lg:p-4 w-full rounded-xl shadow-lg' style={{ backgroundColor: theme.backgroundBase }}>
        {children}
      </div>
    </div>
  );
};

Content.propTypes = {
  children: PropTypes.any,
};

export default Content;
