import React, { useEffect, useContext, useState, useRef } from 'react';
import SecurityQaWidgetContext from 'src/context/SecurityQaWidgetContext';
import { useParams, useNavigate } from 'react-router-dom';
import { Input, Button } from 'antd';
import { SendOutlined, LoadingOutlined } from '@ant-design/icons';
import Message from './Components/Message';
import { createChatWidgetConversation, getResponse } from 'src/api/securityqa';
import { notEmptyString } from 'src/misc/Misc';
import { ALLOWED_MODELS, THEMES } from './Misc/Config';
import queryString from 'query-string';
import StyledScrollbars from 'src/components/layout/StyledScrollbars';

//import PropTypes from 'prop-types';

const Home = () => {
  const { id } = useParams();
  const { theme: requestedTheme } = queryString.parse(window.location.search);
  const navigate = useNavigate();
  const { conversation, setConversation, addQuestion, updateResponse, theme, setTheme } = useContext(SecurityQaWidgetContext);
  const bottomOfChatRef = useRef(null);

  const [question, setQuestion] = useState('');
  const [isWriting, setIsWriting] = useState(false);

  useEffect(() => {
    if (ALLOWED_MODELS.includes(id)) {
      createNewConversation();
    } else {
      navigate('/404');
    }
  }, [id]);

  useEffect(() => {
    // Use following query string params to control chat widget theme: ?forceTheme=default&theme=1
    //
    // forceTheme: default or dark - it determines which global theme to use ---> styled components and antd.
    // theme: 0, 1, ... - it determines which chat widget local theme to use ---> /src/components/pages/SecurityQaWidget/Misc/Config.js ---> THEMES
    //
    // examples:
    // http://localhost:3000/security-qa-widget/9ApTTp1ovNQttKwb17jR2h?forceTheme=default&theme=0
    // http://localhost:3000/security-qa-widget/9ApTTp1ovNQttKwb17jR2h?forceTheme=dark&theme=1

    const theme = THEMES[requestedTheme ?? 0];
    setTheme(theme);
  }, [requestedTheme]);

  const createNewConversation = async () => {
    try {
      const resp = await createChatWidgetConversation({
        title: 'Chat Widget Conversation',
        content: [
          {
            role: 'system',
            message: "Hey, I'm your AI assistant.",
          },
          {
            role: 'system',
            message: 'How can I help you today?',
          },
        ],
        model_id: id,
      });
      setConversation(resp);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetResponse = async () => {
    try {
      if (false === notEmptyString(question)) return;
      console.log('conversation', conversation);
      addQuestion(question);
      setQuestion('');
      setIsWriting(true);
      await getResponse(question, conversation.id, conversation.model_id, getResponseCallback, getResponseErrorCallback, false, true);
      setIsWriting(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getResponseCallback = async (resp) => {
    console.log('getResponseCallback()', resp);
    updateResponse(resp);
    scrollToBottom();
  };

  const getResponseErrorCallback = async (error) => {
    console.error('getResponseErrorCallback()', error);
  };

  const scrollToBottom = () => {
    if (bottomOfChatRef.current) {
      bottomOfChatRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleKeyDown = (e) => {
    if (e.shiftKey) return;
    if (e.keyCode !== 13) return;
    e.preventDefault();
    if (question?.length === 0) return;
    handleGetResponse();
  };

  if (!theme) return null;

  return (
    <StyledScrollbars color={theme.scrollbar} bgColor='transparent'>
      <div className='h-[100vh] w-full flex flex-col items-center' style={{ backgroundColor: theme.mainBackground }}>
        <div className='h-[100vh] w-full max-w-[600px] flex flex-col'>
          <div className='flex-auto overflow-auto p-2 flex flex-col gap-2'>
            {conversation?.content?.map((item, index) => (
              <Message key={index} isSystem={item.role === 'system'} body={item?.message} isWriting={item.role === 'system' && index === conversation?.content?.length - 1 && isWriting} />
            ))}
            <div ref={bottomOfChatRef}></div>
          </div>
          <div className={`flex flex-row gap-2 p-2 ${isWriting && 'disabled'}`}>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 2 }}
              placeholder='type a question...'
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              onKeyDown={(e) => {
                handleKeyDown(e, handleGetResponse);
              }}
            />
            <Button className='h-full' onClick={handleGetResponse}>
              {isWriting ? <LoadingOutlined /> : <SendOutlined />}
            </Button>
          </div>
        </div>
      </div>
    </StyledScrollbars>
  );
};

// Home.propTypes = {};

export default Home;
