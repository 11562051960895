import React, { useEffect } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import GcPermissionsProvider from 'src/providers/GcPermissionsProvider';
import FeatureAccess from 'src/components/controls/FeatruesAccess/FeaturesAccess';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import AuditViewer from './AuditViewer';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);
dayjs.extend(quarterOfYear);

const AuditViewerHome = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  //#region useEffect(s)

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Audit Viewer',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  return (
    <PermissionsProvider requiredPermissions={['apps.auditviewer.view']}>
      <FeatureAccess feature='audit_viewer' featureDisplayName='Audit Viewer'>
        <GcPermissionsProvider
          requiredPermissions={[
            {
              domain: 'audits',
              entityName: 'audit',
              action: 'view',
            },
          ]}
        >
          <AuditViewer />
        </GcPermissionsProvider>
      </FeatureAccess>
    </PermissionsProvider>
  );
};

export default AuditViewerHome;
