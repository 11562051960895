import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

const FullScreenSpin = ({ title, subtitle }) => {
  return (
    <div className='flex flex-col justify-center items-center' style={{ height: '100vh' }}>
      <div>
        <Spin size='large' />
      </div>
      <div className='mt-4 text-lg font-semibold'>{title}</div>
      <div className='text-sm font-extralight'>{subtitle}</div>
    </div>
  );
};

FullScreenSpin.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default FullScreenSpin;
