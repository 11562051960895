import PropTypes from 'prop-types';

const JSONViewer = ({ data, fontSize }) => {
  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <ul className='list-disc list-inside'>
          {value.map((item, index) => (
            <li key={index}>{renderValue(item)}</li>
          ))}
        </ul>
      );
    } else if (typeof value === 'object' && value !== null) {
      return <JSONViewer data={value} fontSize={fontSize} />;
    } else {
      return String(value);
    }
  };

  const filteredEntries = Object.entries(data).filter(([_, value]) => value !== null);

  return (
    <div className={'p-2 overflow-x-auto'} style={{ fontSize }}>
      <table className='w-full border-collapse table-fixed'>
        <thead>
          <tr>
            <th className='text-left w-1/3'>Item</th>
            <th className='text-left w-2/3'>Value</th>
          </tr>
        </thead>
        <tbody>
          {filteredEntries.map(([key, value]) => (
            <tr key={key}>
              <td className='font-semibold truncate'>{key}</td>
              <td className='break-words'>{renderValue(value)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

JSONViewer.propTypes = {
  data: PropTypes.object.isRequired,
  fontSize: PropTypes.string,
};

JSONViewer.defaultProps = {
  fontSize: 'inherit',
};

export default JSONViewer;
