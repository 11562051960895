import React, { useEffect, useState } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import TitleBar from 'src/components/layout/TitleBar';
import { FaVideo } from 'react-icons/fa';
import { Space } from 'antd';
import { getMediaVideoList } from 'src/api/media';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import VideoItem from './Components/VideoItem';
//import PropTypes from 'prop-types';

const Videos = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { executeAsyncProcess } = useAsyncProcesses();

  useEffect(() => {
    loadVideoList();

    setBreadcrumb([
      {
        title: 'Videos',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const [videoList, setVideoList] = useState(null);

  const loadVideoList = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getMediaVideoList();
        console.log('resp:', resp);
        if (resp) setVideoList(resp);
      } catch (error) {
        console.error(error);
      }
    });
  };

  if (!videoList) return <FullScreenSpin title='Please wait' subtitle='Loading video list' />;

  return (
    <>
      <TitleBar
        title={
          <Space className='btn'>
            <FaVideo />
            Videos
          </Space>
        }
      />

      <div className='p-8 flex flex-row flex-wrap gap-12 justify-center'>
        {videoList.map((video, index) => (
          <VideoItem key={index} videoId={video.id} title={video.title} description={video.description} thumbnail={video.thumbnail} modifiedDate={video.modifiedDate} />
        ))}
      </div>
    </>
  );
};

// Videos.propTypes = {};

export default Videos;
