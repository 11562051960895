import React from 'react';
import { useNavigate } from 'react-router-dom';
import BoxType1 from './BoxType1';
import { Button, Space } from 'antd';
import { BsRobot } from 'react-icons/bs';
import { ReactComponent as CopilotSvg } from 'src/components/svg/copilot.svg';
import CorporateColors from 'src/misc/CorporateColors';
import PermissionsProvider from 'src/providers/PermissionsProvider';

const SectionTrust = () => {
  const navigate = useNavigate();

  return (
    <PermissionsProvider requiredPermissions={['apps.copilot.view']} isControl>
      <BoxType1>
        <div className='flex flex-row gap-8'>
          <div>
            <CopilotSvg className='h-[300px]' style={{ filter: `drop-shadow(0px 0px 15px ${CorporateColors.aqua})` }} />
          </div>
          <div className='flex flex-col justify-center'>
            <div>
              <div className='text-3xl'>
                <Space className='btn'>
                  <BsRobot />
                  Your AI Assistant, Ask Anything!
                </Space>
              </div>
              <p>COE AI Assistant is designed to be your virtual advisor, providing real-time assistance, insights, and expert guidance tailored specifically to the intricacies of Genesys Cloud product, security, privacy and Compliance. Whether you're a seasoned professional seeking advanced strategies or a newcomer looking to understand the fundamentals, COE AI Assistant is here to assist you every step of the way with most up to date information coming from managed data sources.</p>
              <p>This tool will not only provide you with answers to your questions but will also help to build tables & diagram from actual texts using AWS Bedrock within Genesys COE team managed environment.</p>
              <div className='mt-4'>
                <Button
                  size='large'
                  type='primary'
                  onClick={() => {
                    navigate('/COEAIAssistant');
                  }}>
                  <Space className='btn'>
                    Open
                    <span className='font-bold'>COE AI Assistant</span>
                    <BsRobot />
                  </Space>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </BoxType1>
    </PermissionsProvider>
  );
};

SectionTrust.propTypes = {};

export default SectionTrust;
