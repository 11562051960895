import { filterItemsBasedOnUserPermissions } from 'src/misc/Session';
import { LuUsers2, LuShieldCheck, LuBuilding } from 'react-icons/lu';
import { AiOutlineRobot, AiFillNotification } from 'react-icons/ai';
import ReactDOMServer from 'react-dom/server';
import { scrubHtml } from 'src/misc/Misc';
import { BsCupHotFill } from 'react-icons/bs';
import { TbReportMoney } from 'react-icons/tb';
import { FaFileDownload } from 'react-icons/fa';
import { GiEntryDoor } from 'react-icons/gi';
import { TiDocumentText } from 'react-icons/ti';
import { LiaUserLockSolid } from 'react-icons/lia';
import { MdGroups } from 'react-icons/md';
import { MASTER_ADMIN_PERMISSION, TENANT_ADMIN_PERMISSION } from 'src/misc/Config';
import { notEmptyArray } from 'src/misc/Misc';

export const getAdminItems = (userPermissions, homePageFilter) =>
  [
    {
      group: 'People and permissions',
      items: [
        { id: 'tenants', title: 'Tenants', icon: <LuBuilding />, permissions: [MASTER_ADMIN_PERMISSION], path: '/master-admin/tenants', imageBgColor: '#ea580c' },
        { id: 'users', title: 'Users', icon: <LuUsers2 />, permissions: [MASTER_ADMIN_PERMISSION, TENANT_ADMIN_PERMISSION], path: '/master-admin/users', imageBgColor: '#16a34a' },
        {
          id: 'permissions',
          title: (
            <>
              Roles & <br />
              Permissions
            </>
          ),
          icon: <LuShieldCheck />,
          permissions: [MASTER_ADMIN_PERMISSION],
          path: '/master-admin/roles-permissions',
          imageBgColor: '#0284c7',
        },
        {
          id: 'invitationLinks',
          title: 'Invitation links',
          icon: <GiEntryDoor />,
          permissions: [MASTER_ADMIN_PERMISSION, TENANT_ADMIN_PERMISSION],
          path: '/master-admin/invitation-links',
          imageBgColor: '#ca8a04',
        },
        {
          id: 'Groups',
          title: 'Groups',
          icon: <MdGroups />,
          permissions: ['features.groups'],
          path: '/master-admin/groups',
          imageBgColor: '#800306',
        },
      ]
        .filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions))
        .filter((item) => !homePageFilter?.length > 0 || scrubHtml(ReactDOMServer.renderToString(item.title))?.toLowerCase().includes(homePageFilter?.toLowerCase())),
    },
    {
      group: 'AI Assistant',
      items: [
        {
          id: 'aiModels',
          title: (
            <>
              AI Assistant
              <br />
              Models
            </>
          ),
          icon: <AiOutlineRobot />,
          permissions: [MASTER_ADMIN_PERMISSION],
          path: '/master-admin/aimodels',
          imageBgColor: '#c026d3',
        },
        {
          id: 'aiTemplates',
          title: (
            <>
              AI Assistant
              <br />
              Templates
            </>
          ),
          icon: <TiDocumentText />,
          permissions: [MASTER_ADMIN_PERMISSION],
          path: '/master-admin/aitemplates',
          imageBgColor: '#c026d3',
        },
      ]
        .filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions))
        .filter((item) => !homePageFilter?.length > 0 || scrubHtml(ReactDOMServer.renderToString(item.title))?.toLowerCase().includes(homePageFilter?.toLowerCase())),
    },
    {
      group: 'Applications',
      items: [
        {
          id: 'Services',
          title: (
            <>
              Green Teacket
              <br />
              Services
            </>
          ),
          icon: <BsCupHotFill />,
          permissions: [MASTER_ADMIN_PERMISSION, TENANT_ADMIN_PERMISSION],
          path: '/master-admin/teacket-services',
          imageBgColor: '#0d9488',
        },
        {
          id: 'Questions',
          title: (
            <>
              FSI Report
              <br />
              Questions
            </>
          ),
          icon: <TbReportMoney />,
          permissions: [MASTER_ADMIN_PERMISSION],
          path: '/master-admin/fsi-report',
          imageBgColor: '#9333ea',
        },
      ]
        .filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions))
        .filter((item) => !homePageFilter?.length > 0 || scrubHtml(ReactDOMServer.renderToString(item.title))?.toLowerCase().includes(homePageFilter?.toLowerCase())),
    },
    {
      group: 'Other',
      items: [
        {
          id: 'Files & Sections',
          title: (
            <>
              Home Page
              <br />
              Files & Sections
            </>
          ),
          icon: <FaFileDownload />,
          permissions: [MASTER_ADMIN_PERMISSION],
          path: '/master-admin/downloads',
          imageBgColor: '#d97706',
        },
        {
          id: 'GDPR Requests',
          title: 'GDPR Requests',
          icon: <LiaUserLockSolid />,
          permissions: ['features.gdpr'],
          path: '/master-admin/gdpr',
          imageBgColor: '#800306',
        },
        {
          id: 'adminMessages',
          title: 'Admin Messages',
          icon: <AiFillNotification />,
          permissions: [MASTER_ADMIN_PERMISSION],
          path: '/master-admin/admin-messages',
          imageBgColor: '#0891B2',
        },
      ]
        .filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions))
        .filter((item) => !homePageFilter?.length > 0 || scrubHtml(ReactDOMServer.renderToString(item.title))?.toLowerCase().includes(homePageFilter?.toLowerCase())),
    },
  ].filter((group) => true === notEmptyArray(group.items));
