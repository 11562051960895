import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';

export const getSubjects = async (searchType, searchValue) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/gdpr/subjects?searchType=${searchType}&searchValue=${encodeURIComponent(searchValue)}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const postRequest = async (body) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/gdpr/requests`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};
