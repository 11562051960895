import React from 'react';
import PropTypes from 'prop-types';
import QuestionForm from '../QuestionForm';
import { message } from 'antd';
import { patchQuestion } from 'src/api/fsireport';
import { uuid } from 'short-uuid';

const QuestionEditorConfiguration = ({ questionId, onSubmit }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const handleOnSubmit = async (values) => {
    const updatedQuestion = await patchQuestion(questionId, values);
    console.log('Success:', values);
    console.log('Updated question:', updatedQuestion);

    messageApi.open({
      key: uuid(),
      type: 'success',
      content: 'Question updated successfully',
      duration: 3,
    });
  };

  return (
    <>
      {contextHolder}
      <QuestionForm questionId={questionId} edit={true} onSubmit={handleOnSubmit} />
    </>
  );
};

QuestionEditorConfiguration.propTypes = {
  questionId: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default QuestionEditorConfiguration;
