import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CondensedTable from 'src/components/layout/CondensedTable';
import { Modal } from 'antd';
import ProductLine from './ProductLine';

const Deployments = ({ dataDeployments, ticketDeploymentId, dataProductLine }) => {
  const columns = [
    {
      title: 'Deployments',
      key: 'Deployments',
      render: (record) => {
        return (
          <div>
            <div className={`text-sm ${ticketDeploymentId === record?.PURECLOUD_ORGID__C ? 'text-blue-500' : ''}  `}>{record.ORG_NAME__C}</div>
            <div className='text-xs text-right'>
              <span className=' font-light opacity-60'>region: </span>
              {record.AWS_REGION__C}
            </div>
            <div className='text-xs text-right'>
              <span className=' font-light opacity-60'>org id: </span>
              {record.PURECLOUD_ORGID__C}
            </div>
          </div>
        );
      },
    },
  ];

  const [selectedDeployment, setSelectedDeployment] = useState(null);

  return (
    <>
      <Modal
        width={1024}
        title={
          <>
            {dataDeployments.find((x) => x.PURECLOUD_ORGID__C === selectedDeployment)?.ORG_NAME__C}
            <span className='font-light opacity-60'> | product line</span>{' '}
          </>
        }
        open={selectedDeployment}
        onCancel={() => {
          setSelectedDeployment(null);
        }}
        footer={[]}
      >
        <ProductLine orgId={selectedDeployment} />
      </Modal>
      <CondensedTable
        columns={columns}
        dataSource={dataDeployments}
        rowKey={'PURECLOUD_ORGID__C'}
        size='small'
        pagination={{
          showSizeChanger: false,
          defaultPageSize: 5,
          hideOnSinglePage: true,
        }}
        onRow={(record) => ({
          onClick: () => {
            setSelectedDeployment(record.PURECLOUD_ORGID__C);
          },
        })}
        rowClassName={'cursor-pointer'}
      />
    </>
  );
};

Deployments.propTypes = {
  dataDeployments: PropTypes.array,
  ticketDeploymentId: PropTypes.string,
  dataProductLine: PropTypes.array,
};

export default Deployments;
