import React from 'react';
import { Button, Result } from 'antd';

const Page403 = () => {
  return (
    <div>
      <Result
        status='403'
        title='403'
        subTitle='You are not authorized to access this page.'
        extra={
          <Button type='primary' onClick={() => (window.location.href = '/home')}>
            Go Back Home
          </Button>
        }
      />
    </div>
  );
};

Page403.propTypes = {};

export default Page403;
