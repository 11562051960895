import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Drawer, Space, Button, Tooltip, Pagination, Select, Popover, Badge, Switch, Card, Tag } from 'antd';
import useTheme from 'src/hooks/useTheme';
import { FaTimes, FaCheckDouble, FaTrash } from 'react-icons/fa';
import { PiBellSimpleDuotone } from 'react-icons/pi';
import { MdMenu, MdRefresh } from 'react-icons/md';
// import { MdMoreHoriz } from 'react-icons/md';
import PropTypes from 'prop-types';
import BarLoader from 'react-bar-loader';
import { getNotificationCenterMy, patchNotificationCenterMyMarkAsRead, deleteNotificationCenterMy, patchNotificationCenterMyMarkAsReadAll, deleteNotificationCenterMyAll } from 'src/api/notifications';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import NoData from 'src/components/layout/NoData';
import NotificationCenterItem from './NotificationCenterItem';
import { originConfig } from '../misc/misc';
import useUserInfo from 'src/hooks/useUserInfo';
import { filterItemsBasedOnUserPermissions } from 'src/misc/Session';
import { PiShootingStarDuotone } from 'react-icons/pi';

var unreadMessages1 = 0;
const NotificationCenterDrawer = ({ open, onClose, unreadMessages }) => {
  // const navigate = useNavigate();
  const { theme } = useTheme();
  const { permissions: userPermissions } = useUserInfo();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [paginationHover, setPaginationHovered] = useState(false);
  const [filterOptions, setFilterOptions] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [unreadOnlyFilter, setUnreadOnlyFilter] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (open) {
      getFilterOptions();
      loadData();
    } else {
      setData(null);
      setCurrentPage(1);
      setSelectedFilter(null);
    }
  }, [open]);

  useEffect(() => {
    if (!open) return;
    const increased = unreadMessages > unreadMessages1;
    unreadMessages1 = unreadMessages;
    if (!increased) return;
    loadData();
  }, [unreadMessages]);

  useEffect(() => {
    if (!open) return;
    loadData();
  }, [currentPage]);

  useEffect(() => {
    if (!open) return;
    const cp = 1;
    setCurrentPage(cp);
    loadData(cp);
  }, [selectedFilter, unreadOnlyFilter]);

  const loadData = async (forceCurrentPage = null) => {
    setLoading(true);
    try {
      const response = await getNotificationCenterMy(forceCurrentPage || currentPage, pageSize, selectedFilter, unreadOnlyFilter, unreadMessages);
      setData(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationOnChange = (page) => {
    setCurrentPage(page);
  };

  const getFilterOptions = () => {
    let options = originConfig
      .filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions))
      .filter((o) => o.origin !== 'default')
      .map((o) => ({ value: o.origin, label: o.title }));
    setFilterOptions(options);
  };

  const handleMarkItemAsRead = async (id) => {
    if (data.entities.find((item) => item.id === id).is_read) return;
    console.log('handleMarkItemAsRead', id);
    setLoading(true);
    try {
      patchNotificationCenterMyMarkAsRead(id);
      // Update is_read in the data array
      const newData = data.entities.map((item) => {
        if (item.id === id) {
          item.is_read = true;
        }
        return item;
      });
      setData({ ...data, entities: newData });
      console.log('data', data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleDeleteItem = async (id) => {
    console.log('handleDeleteItem', id);
    setLoading(true);
    try {
      await deleteNotificationCenterMy(id);
      const response = await getNotificationCenterMy(currentPage, pageSize, selectedFilter, unreadOnlyFilter);
      setData(response);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleRefresh = async () => {
    console.log('handleRefresh');
    setLoading(true);
    try {
      setMenuOpen(false);
      loadData();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleMarkAllItemsAsRead = async (id) => {
    console.log('handleMarkAllItemsAsRead', id);
    setLoading(true);
    try {
      setMenuOpen(false);
      await patchNotificationCenterMyMarkAsReadAll(id);
      const response = await getNotificationCenterMy(currentPage, pageSize, selectedFilter, unreadOnlyFilter);
      setData(response);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleDeleteAllItems = async (id) => {
    console.log('handleDeleteAllItems', id);
    setLoading(true);
    try {
      setMenuOpen(false);
      await deleteNotificationCenterMyAll();
      const response = await getNotificationCenterMy(currentPage, pageSize, selectedFilter, unreadOnlyFilter);
      setData(response);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <Drawer
      width='520px'
      open={open}
      onClose={() => {
        if (onClose) onClose(false);
      }}
      closable={false}
      title={
        <Space className='btn' style={{ fontStretch: 'condensed' }}>
          <PiBellSimpleDuotone />
          Notification Center
          <Badge count={unreadMessages} size='small'></Badge>
        </Space>
      }
      extra={
        <Space className='btn'>
          {true === notEmptyArray(filterOptions) && (
            <Tooltip title='Filter' mouseEnterDelay={2}>
              <Select style={{ width: '150px' }} size='small' value={selectedFilter} onChange={(value) => setSelectedFilter(value)} options={filterOptions} placeholder='[All notifications]' allowClear />
            </Tooltip>
          )}
          <Switch style={{ fontStretch: 'extra-condensed' }} checked={unreadOnlyFilter} onChange={(checked) => setUnreadOnlyFilter(checked)} size='small' checkedChildren='Unread' unCheckedChildren='All' />
          <Popover
            open={menuOpen}
            onOpenChange={(open) => setMenuOpen(open)}
            content={
              <div className='flex flex-col gap-2 w-44'>
                <Button size='small' onClick={handleRefresh}>
                  <Space className='btn'>
                    <MdRefresh className='text-yellow-700 dark:text-yellow-300' />
                    Refresh
                  </Space>
                </Button>
                <Button size='small' onClick={handleMarkAllItemsAsRead}>
                  <Space className='btn'>
                    <FaCheckDouble className='text-lime-700 dark:text-lime-300' />
                    Mark all as read
                  </Space>
                </Button>
                <Button size='small' onClick={handleDeleteAllItems}>
                  <Space className='btn'>
                    <FaTrash className='text-red-700 dark:text-red-300' />
                    Delete all
                  </Space>
                </Button>
              </div>
            }
            // title='Notifications Menu'
            trigger='click'
          >
            <Tooltip title='Show Menu' mouseEnterDelay={2}>
              <Button type='text' className='px-2'>
                <MdMenu />
              </Button>
            </Tooltip>
            <Tooltip title='Close' mouseEnterDelay={2}>
              <Button type='text' onClick={() => onClose(false)} className='px-2'>
                <FaTimes />
              </Button>
            </Tooltip>
          </Popover>
        </Space>
      }
      styles={{ body: { padding: 0 }, header: { padding: '8px 12px', border: 'none' } }}
    >
      <div className={`h-full flex flex-col ${loading ? 'disabled' : null}`}>
        <div className='flex-initial'>{loading ? <BarLoader color={theme.primary} backgroundColor={theme.light} height='2' width='100%' /> : <div style={{ height: '1px', marginTop: '1px', backgroundColor: theme.primary }}></div>}</div>

        <div className='flex-auto overflow-auto'>
          {data?.summary && (
            <div className='p-4'>
              <Card className='rounded-lg shadow-sm'>
                <div className='absolute top-0 right-0 pt-2'>
                  <Tag className='bg-yellow-600'>
                    <PiShootingStarDuotone className='pt-1 scale-125 mr-2' />
                    Summary
                  </Tag>
                </div>

                <div className='text-sm text-gray-600 dark:text-gray-400'>
                  <div className={'my-1 overflow-auto'} dangerouslySetInnerHTML={{ __html: data?.summary.replace(/\n/g, '<br />') }} />
                </div>
                <div className='flex justify-end gap-2 mt-4'>
                  <Button size='small' onClick={handleMarkAllItemsAsRead}>
                    <Space className='btn'>Mark all as read</Space>
                  </Button>
                  <Button size='small' onClick={handleDeleteAllItems}>
                    <Space className='btn'>Delete all</Space>
                  </Button>
                </div>
              </Card>
            </div>
          )}

          {notArrayOrEmpty(data?.entities) ? (
            <div className='h-full flex flex-col items-center justify-center'>
              <NoData />
            </div>
          ) : (
            <div className='p-4 flex flex-col gap-4'>
              {data.entities.map((item) => (
                <NotificationCenterItem item={item} onMarkItemAsRead={handleMarkItemAsRead} onDeleteItem={handleDeleteItem} />
              ))}
              <div className='h-16' />
            </div>
          )}
        </div>
        <div onMouseEnter={() => setPaginationHovered(true)} onMouseLeave={() => setPaginationHovered(false)} className='absolute bottom-0 left-0 right-0 px-2 py-4' style={{ background: `linear-gradient(180deg, ${theme.backgroundBase}${paginationHover ? 'ee' : '33'} 0%, ${theme.backgroundBase} 70%)` }}>
          <Pagination current={currentPage} pageSize={pageSize} total={data?.total} onChange={handlePaginationOnChange} />
        </div>
      </div>
    </Drawer>
  );
};

NotificationCenterDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  unreadMessages: PropTypes.number,
};

export default NotificationCenterDrawer;
