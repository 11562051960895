import React, { useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const WeekPicker = ({ value, onChange, minDate, maxDate }) => {
  const [currentWeek, setCurrentWeek] = useState(
    dayjs(value ?? undefined)
      .utc(true)
      .startOf('week')
      .startOf('day')
  );

  const handleDatePickerOnChange = (date) => {
    const week = date.utc(true).startOf('week').startOf('day');
    setCurrentWeek(week);
    if (!onChange) return;
    onChange(week);
  };

  return (
    <div>
      <DatePicker value={currentWeek} size='small' picker='week' onChange={handleDatePickerOnChange} format={(value) => `week of ${value.startOf('week').startOf('day').format('MMM DD')}`} allowClear={false} minDate={minDate} maxDate={maxDate} />
    </div>
  );
};

WeekPicker.propTypes = {
  value: PropTypes.object,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  onChange: PropTypes.func,
};

export default WeekPicker;
