import React, { useEffect } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import FeatureAccess from 'src/components/controls/FeatruesAccess/FeaturesAccess';

//import PropTypes from 'prop-types';

const Test = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Test',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <FeatureAccess feature='ai_assistant' featureDisplayName='AI Assistant'>
      <TitleBar title='Test' />
      <div>HELLO TEST :-)</div>
    </FeatureAccess>
  );
};

// Test.propTypes = {};

export default Test;
