import React from 'react';
import Header from 'src/components/pages/Landing/components/ui/Header';
import { IoIosStats } from 'react-icons/io';
import { Space } from 'antd';
//import PropTypes from 'prop-types';

const CustomerInsights = () => {
  return (
    <>
      <div className='fixed z-50'>
        <Header />
      </div>
      <div className='absolute flex flex-col items-center w-full'>
        <div className='mt-24 max-w-7xl '>
          <div className='my-8 font-bold text-2xl'>
            <Space>
              <IoIosStats />
              Customer Insights
            </Space>
          </div>
          <div className='my-8'>
            Introducing the Genesys Cloud Customer Insights app – a revolutionary tool designed to unlock the full potential of your data and transform it into actionable insights that drive strategic decision-making and foster unparalleled experiences. Developed by the Genesys SPC team, Customer Insights represents the cutting edge of customer data analytics, offering organizations a powerful platform for understanding, analyzing, and leveraging interactions across multiple channels and
            touchpoints.
          </div>
          <div className='my-8'>At its core, the Customer Insights app harnesses the vast wealth of data generated within your Genesys Cloud organization – including recordings, interactions, and more.</div>
          <div className='my-8'>One of the key features of the Customer Insights app is its flexibility and scalability. Whether you're a small business looking to gain a better understanding of your customer base or a large enterprise managing millions of interactions per day, the Customer Insights app can be tailored to meet your unique needs and scale alongside your business.</div>
          <div className='my-8'>In today's hyper-competitive marketplace, understanding your data is more important than ever. With Customer Insights, you can unlock the full potential of their your data, drive strategic decision-making, and deliver exceptional customer experiences that drive loyalty, retention, and growth.</div>
        </div>
      </div>
    </>
  );
};

// Copilot.propTypes = {};

export default CustomerInsights;
