import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TicketTimeTrackingHoursSelectorItem from '../TicketTimeTracking/TicketTimeTrackingHoursSelectorItem';
import dayjs from 'dayjs';
import Space2 from 'src/components/layout/Space2';
import { Button, message } from 'antd';
import { FaRegClock } from 'react-icons/fa6';
import useUserInfo from 'src/hooks/useUserInfo';
import { getTicketTimeTrackingDate, postTicketTimeTracking, getTicketTimeTrackingTotal } from 'src/api/teacket';
import { v4 as uuid } from 'uuid';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { sleepMs } from 'src/misc/Misc';
import Smile from 'src/components/layout/Smile';

const UserTicketListHoursSubmit = ({ ticketId, onSubmit }) => {
  const { id: userId, name: userName } = useUserInfo();
  const { executeAsyncProcess, isBusy } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const HOURS = [
    { hours: 1, label: '1 hr' },
    { hours: 2, label: '2 hrs' },
    { hours: 3, label: '3 hrs' },
    { hours: 5, label: '5 hrs' },
    { hours: 8, label: '8 hrs' },
  ];

  const [reportedDate, setReportDate] = useState(null);
  const [selectedHours, setSelectedHours] = useState(1);
  const [selectedWeek] = useState(dayjs().utc(true).startOf('week').startOf('day'));
  const [dayList, setDayList] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [loadingReportedDate, setLoadingReportedDate] = useState(false);
  const [showSmileIcon, setShowSmileIcon] = useState(false);

  useEffect(() => {
    setSelectedDay(dayjs().utc(true).startOf('day'));
  }, []);

  useEffect(() => {
    if (!selectedDay) return;
    loadReportedDate();
  }, [selectedDay]);

  useEffect(() => {
    const week = selectedWeek;
    const dl = [];
    for (let i = 0; i < 5; i++) {
      const day = week.add(i, 'day').startOf('day');
      console.log('day:', day, day.format());
      dl.push(day);
    }
    setDayList(dl);
  }, [selectedWeek]);

  const loadReportedDate = async () => {
    console.log('loadReportedDate:', ticketId, userId, selectedDay);
    setLoadingReportedDate(true);
    setReportDate(null);
    try {
      const resp = await getTicketTimeTrackingDate(ticketId, userId, selectedDay);
      setReportDate(resp);
    } catch (error) {
      console.log(error);
    }
    setLoadingReportedDate(false);
  };

  const handleHourSelectorItemOnClick = (hours) => {
    console.log('handleSelectorItemOnClick:', hours);
    setSelectedHours(hours);
  };

  const handleDaySelectorItemOnClick = (day) => {
    console.log('handleDaySelectorItemOnClick:', day);
    setSelectedDay(day);
  };

  const handleSubmit = () => {
    console.log('handleSubmit:', userId, selectedHours);

    executeAsyncProcess(async () => {
      const key = uuid();
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Submitting hours...',
          duration: 0,
        });
        await postTicketTimeTracking(ticketId, userId, selectedHours, selectedDay.format());
        const resultTotal = await getTicketTimeTrackingTotal(ticketId);
        setSelectedHours(1);
        setSelectedDay(null);
        messageApi.open({
          key,
          type: 'success',
          content: 'Hours submitted',
          duration: 3,
        });
        triggetShowingSmileIcon(resultTotal);
      } catch (error) {
        console.log(error);
        messageApi.destroy(key);
      }
    });
  };

  const triggetShowingSmileIcon = async (resultTotal) => {
    setShowSmileIcon(true);
    await sleepMs(1000);
    setShowSmileIcon(false);
    if (onSubmit) onSubmit(resultTotal);
  };

  return (
    <div className={`flex flex-col gap-2 ${isBusy ? ' disabled' : null}`}>
      {contextHolder}
      <div className='flex flex-row gap-2 justify-center'>
        {HOURS?.map((x) => (
          <React.Fragment key={x.hours}>
            <TicketTimeTrackingHoursSelectorItem value={x.hours} label={x.label} onClick={handleHourSelectorItemOnClick} selected={x.hours === selectedHours} />
          </React.Fragment>
        ))}
      </div>
      <div className='flex flex-row gap-2 justify-center'>
        {dayList?.map((x) => (
          <React.Fragment key={x.format()}>
            <TicketTimeTrackingHoursSelectorItem
              value={x}
              label={
                <Space2>
                  <span>{x.format('ddd')}</span>
                  {/* <span className='font-light opacity-60 text-[0.6rem]'>{x.format('MMM DD')}</span> */}
                </Space2>
              }
              onClick={handleDaySelectorItemOnClick}
              selected={x.isSame(selectedDay)}
            />
          </React.Fragment>
        ))}
      </div>
      <div className='relative'>
        <div className='absolute z-50 flex flex-row justify-center mt-[-37px] w-full'>
          <Smile show={showSmileIcon} label='Thank you!' />
        </div>
        <Button type={reportedDate ? 'text' : 'default'} size='large' className={'w-full'} onClick={handleSubmit} disabled={!selectedHours || !selectedDay || loadingReportedDate || reportedDate}>
          <Space2 className={` ${reportedDate ? 'text-green-900 dark:text-green-100 opacity-60' : null}`}>
            <div>
              <FaRegClock className={loadingReportedDate ? 'animate-pulse' : null} />
            </div>
            {selectedHours && selectedDay ? (
              <div style={{ lineHeight: '1.1rem' }} className='text-sm'>
                {reportedDate ? (
                  <div>
                    <span className='font-light opacity-80' style={{ fontStretch: 'condensed' }}>
                      Already reported
                    </span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {reportedDate.hours} {reportedDate.hours >= 2 ? 'hours' : 'hour'}
                    </span>{' '}
                    <span className='font-light opacity-80'>on</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedDay.format('MMM DD')}
                    </span>{' '}
                  </div>
                ) : (
                  <div>
                    <span className='font-light opacity-80'>Submit</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedHours} {selectedHours >= 2 ? 'hours' : 'hour'}
                    </span>{' '}
                    <span className='font-light opacity-80'>on</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedDay.format('MMM DD')}
                    </span>{' '}
                  </div>
                )}
                <div>
                  <span className='font-light opacity-80'>for</span>{' '}
                  <span className='font-bold' style={{ fontStretch: 'condensed', overflow: 'hidden' }}>
                    {userName}
                  </span>
                </div>
              </div>
            ) : (
              <span className='text-sm font-light opacity-80'>Submit</span>
            )}
          </Space2>
        </Button>
      </div>
    </div>
  );
};

UserTicketListHoursSubmit.propTypes = {
  ticketId: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default UserTicketListHoursSubmit;
