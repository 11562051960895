import React, { useContext, useEffect } from 'react';
import FsiReportContext from 'src/context/FsiReportContext';
import LangPicker from 'src/components/controls/LangPicker/LangPicker';
import { LANGS_MAX_NUMBER } from '../../Misc/misc';
//import PropTypes from 'prop-types';

const LangsStep = () => {
  const { createNewLanguages, setCreateNewLanguages } = useContext(FsiReportContext);

  useEffect(() => {
    if (null !== createNewLanguages) return;
    setCreateNewLanguages(['en']);
  }, []);

  const handleOnChange = (value) => {
    setCreateNewLanguages(value);
  };

  return (
    <div>
      <h2>
        Select report languages <span className='font-light opacity-60'>(max {LANGS_MAX_NUMBER} languages)</span>:
      </h2>
      <div>
        <LangPicker size='large' value={createNewLanguages} onChange={handleOnChange} supportedLanguages={['en', 'fr', 'de']} maxCount={LANGS_MAX_NUMBER} />
      </div>
    </div>
  );
};

LangsStep.propTypes = {};

export default LangsStep;
