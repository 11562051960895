import React from 'react';
//import PropTypes from 'prop-types';

const StatsWidget = () => {
  return <>Hello StatsWidget :-)</>;
};

StatsWidget.propTypes = {};

export default StatsWidget;
