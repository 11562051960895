import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { BsRobot } from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa';
import SecurityQaWidgetContext from 'src/context/SecurityQaWidgetContext';

const Message = ({ isSystem, isWriting, body }) => {
  const { theme } = useContext(SecurityQaWidgetContext);

  const className = isSystem ? `mr-2 py-2 px-4 text-sm rounded-b-xl rounded-tl-xl ${isWriting && 'animate-pulse'}` : 'ml-2 py-2 px-4 text-sm rounded-b-xl rounded-tr-xl';
  const style = isSystem ? { backgroundColor: theme.machineBackground, color: theme.machineText } : { backgroundColor: theme.humanBackground, color: theme.humanText };

  return (
    <div className={`flex ${isSystem ? 'flex-row-reverse' : 'flex-row'}`}>
      <div>{isSystem ? <BsRobot style={{ color: theme.machineIcon }} /> : <FaRegUser style={{ color: theme.humanIcon }} />}</div>
      <div className={className} style={style}>
        {body}
      </div>
    </div>
  );
};

Message.propTypes = {
  isSystem: PropTypes.bool,
  isWriting: PropTypes.bool,
  body: PropTypes.any,
};

export default Message;
