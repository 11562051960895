import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Card, Spin } from 'antd';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useTheme from 'src/hooks/useTheme'; // Adjust the import based on your project structure
import { getPageVisits } from 'src/api/pageVisits'; // This should be your actual API call
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { eachWeekOfInterval, format } from 'date-fns';

const PageVisits = ({ url }) => {
  const { isDarkMode } = useTheme();
  const [data, setData] = useState(undefined);
  const [labels, setLabels] = useState(undefined);
  const [weeklyData, setWeeklyData] = useState(undefined);
  const [weeklyLabels, setWeeklyLabels] = useState(undefined);
  const [loadingData, setLoadingData] = useState(false);

  const themeMode = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  const loadData = async () => {
    try {
      setLoadingData(true);
      const resp = await getPageVisits(url); // Fetch the page_visits data
      // Ensure the data is sorted by creation date
      resp.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

      //#region Line chart data

      // Create cumulative count of page visits
      const data = resp.map((_, index) => index + 1);
      const labels = resp.map((item) => new Date(item.created_at));

      setData(data);
      setLabels(labels);

      //#endregion

      //#region Weekly chart data

      if (resp.length > 0) {
        const startDate = new Date(resp[0].created_at);
        const endDate = new Date(resp[resp.length - 1].created_at);
        const weeks = eachWeekOfInterval({
          start: startDate,
          end: endDate,
        });
        setWeeklyLabels(weeks.map((week) => format(week, 'yyyy-MM-dd')));

        // Aggregate page visits into weekly buckets
        const weeklyData = weeks.map((weekStart, index) => {
          const weekEnd = index + 1 < weeks.length ? weeks[index + 1] : new Date();
          const weekVisits = resp.reduce((acc, item) => {
            const date = new Date(item.created_at);
            if (date >= weekStart && date < weekEnd) {
              return acc + 1;
            }
            return acc;
          }, 0);
          return weekVisits;
        });
        setWeeklyData(weeklyData);
      }

      //#endregion
    } catch (error) {
      console.error('Error fetching page visits:', error);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <ThemeProvider theme={themeMode}>
      <div>
        {data === undefined ? (
          <div className='text-center'>
            <Spin />
          </div>
        ) : (
          <>
            {/* Line Chart for Cumulative Page Visits */}
            <div>
              <Card loading={loadingData} title='Cumulative Page Visits Over Time'>
                {data && labels && (
                  <LineChart
                    xAxis={[
                      {
                        scaleType: 'time',
                        data: labels,
                        label: 'Date',
                      },
                    ]}
                    series={[
                      {
                        type: 'line',
                        data,
                        showMark: false,
                        label: 'Total Visits',
                      },
                    ]}
                    width={450}
                    height={400}
                    showHighlight={true}
                    showTooltip={true}
                  />
                )}
              </Card>
            </div>
            {/* Bar Chart for Weekly Page Visits */}
            <div style={{ marginTop: '20px' }}>
              <Card loading={loadingData} title='Weekly Page Visits'>
                {weeklyData && weeklyLabels && (
                  <BarChart
                    width={450}
                    height={400}
                    series={[
                      {
                        data: weeklyData,
                        label: 'Visits',
                      },
                    ]}
                    xAxis={[
                      {
                        data: weeklyLabels,
                        scaleType: 'band',
                        label: 'Week Starting',
                      },
                    ]}
                    showHighlight={true}
                    showTooltip={true}
                  />
                )}
              </Card>
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

PageVisits.propTypes = {
  url: propTypes.string.isRequired,
};

export default PageVisits;
