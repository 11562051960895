import React, { useEffect } from 'react';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import Content from 'src/components/layout/Content';
import { LuShieldCheck } from 'react-icons/lu';
import { Space, Tabs } from 'antd';
import Roles from 'src/components/pages/MasterAdmin/PermissionsAndRoles/Components/Roles';
import Permissions from 'src/components/pages/MasterAdmin/PermissionsAndRoles/Components/Permissions';
import Assignments from './Components/Assignments';
import CondensedTabs from 'src/components/layout/CondensedTabs';
import Orphans from './Components/Orphans';
import PermissionsExplorer from './Components/PermissionsExplorer/PermissionsExplorer';

//import PropTypes from 'prop-types';

const MasterAdminPermissions = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'Roles & Permissions',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['admin.master']}>
      <TitleBar
        title={
          <Space className='btn'>
            <LuShieldCheck />
            Roles & Permissions
          </Space>
        }
      />
      <Content>
        <CondensedTabs defaultActiveKey='assignments' className='overflow-hidden h-full max-h-full w-full' type='card'>
          <Tabs.TabPane tab='Assignments' key='assignments'>
            <Assignments />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Roles' key='roles'>
            <Roles />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Permissions' key='permissions'>
            <Permissions />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Orphans' key='orphans'>
            <Orphans />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Permissions Explorer' key='permissions-explorer'>
            <PermissionsExplorer />
          </Tabs.TabPane>
        </CondensedTabs>
      </Content>
    </PermissionsProvider>
  );
};

// MasterAdminPermissions.propTypes = {};

export default MasterAdminPermissions;
