import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';
import { objToQueryStringParams } from 'src/misc/Misc';

export const getPreSignedUrl = async (file, env = null, token = null, provider = null) => {
  if (!env || !token || !provider) {
    const userHeaders = await getAccessHeaders();
    env = userHeaders.env;
    token = userHeaders.token;
    provider = userHeaders.provider;
  }

  return handleResponse(
    await fetch(`${ENV.apiUrl}/datasubjectrequests/presignedurl?file=${encodeURIComponent(file)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env,
        token,
        provider,
        authorization: window.location.hostname === 'localhost' ? 'test' : undefined,
      },
    })
  );
};

// export const getGdprSubjects = async (searchType, searchValue, page = 1, pageSize = 25) => {
//   const accessHeaders = await getAccessHeaders();
//   console.log('accessHeaders:', accessHeaders);

//   const url = `https://api.${accessHeaders.env}/api/v2/gdpr/subjects?searchType=${searchType}&searchValue=${encodeURIComponent(searchValue)}`;

//   return handleResponse(
//     await fetch(url, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${accessHeaders.token}`,
//       },
//     })
//   );
// };

export const getGdprSubjects = async (searchType, searchValue) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/datasubjectrequests/subjects?searchType=${searchType}&searchValue=${encodeURIComponent(searchValue)}`, {
      method: 'GET',
      headers,
    })
  );
};

export const getJsonFile = async (staticFile) => {
  return (
    await fetch(staticFile, {
      method: 'GET',
    })
  )?.json();
};

export const getJobs = async () => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/datasubjectrequests/jobs`, {
      method: 'GET',
      headers,
    })
  );
};

export const getJob = async (jobId) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/datasubjectrequests/jobs/${jobId}`, {
      method: 'GET',
      headers,
    })
  );
};

export const postJob = async (data) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/datasubjectrequests/jobs`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const postRequest = async (id, data) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/datasubjectrequests/jobs/${id}/requests`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const postSetup = async (data) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/datasubjectrequests/setup`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const patchJob = async (jobId, data) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/datasubjectrequests/jobs/${jobId}`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const deleteJob = async (jobId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/datasubjectrequests/jobs/${jobId}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getJobFile = async (jobId, fileName) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/datasubjectrequests/jobs/${jobId}/files/${fileName}`, {
      method: 'GET',
      headers,
    })
  );
};

export const getContentFileUrl = async (key) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/datasubjectrequests/jobs/requests/files/${encodeURIComponent(key)}`, {
      method: 'GET',
      headers,
    })
  );
};

export const getContentTree = async (jobId, requestId, limit = 100, manySeparators = false) => {
  const p = objToQueryStringParams({ limit, many_separators: manySeparators.toString() });
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/datasubjectrequests/jobs/${jobId}/requests/${requestId}/tree${p}`, {
      method: 'GET',
      headers,
    })
  );
};
