import React, { useContext, useEffect } from 'react';
import FsiReportContext from 'src/context/FsiReportContext';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import QuestionCard from './QuestionCard';
import { getProjectQuestions } from 'src/api/fsireport';
import { notArrayOrEmpty } from 'src/misc/Misc';
import { Alert, message } from 'antd';
import { patchProjectQuestion, patchProjectQuestionTranslation, deleteProjectQuestion, patchProjectQuestionMoveUp, patchProjectQuestionMoveDown } from 'src/api/fsireport';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { uuid } from 'short-uuid';

const QuestionsWidget = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();
  const { currentProject, currentProjectQuestions, setCurrentProjectQuestions, setCurrentProjectQuestionsDepricated, patchCurrentProjectQuestion, patchCurrentProjectQuestions, patchCurrentProjectQuestionTranslation, deleteCurrentProjectQuestion } = useContext(FsiReportContext);

  useEffect(() => {
    if (!currentProject?.id) return;
    loadData();
  }, [currentProject]);

  // useEffect(() => {
  //   console.log('currentProjectQuestions:', currentProjectQuestions);
  // }, [currentProjectQuestions]);

  const loadData = async () => {
    try {
      const result = (await getProjectQuestions(currentProject.id)) ?? {};
      let questions = result?.entities ?? [];
      let depricated = result?.depricatedReviews ?? [];
      setCurrentProjectQuestions(questions);
      setCurrentProjectQuestionsDepricated(depricated);
    } catch (error) {
      console.error(error);
    }
  };

  if (!currentProjectQuestions) return <SkeletonTable />;
  if (true === notArrayOrEmpty(currentProject?.props?.langs)) return <Alert message='Error' description='Language is not selected!' type='error' showIcon />;

  const handleQuestionOnChange = async ({ questionId, changes }) => {
    console.log('Question ID:', questionId, 'Changes:', changes);
    const key = uuid();
    try {
      messageApi.open({
        key,
        type: 'loading',
        content: 'Updating question...',
        duration: 0,
      });

      await patchCurrentProjectQuestion(questionId, changes);
      await patchProjectQuestion(currentProject.id, questionId, changes);
      messageApi.open({
        key,
        type: 'success',
        content: 'Question updated',
        duration: 3,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleQuestionOnDelete = async (questionId) => {
    console.log('Question ID:', questionId);
    const key = uuid();
    try {
      messageApi.open({
        key,
        type: 'loading',
        content: 'Deleting question...',
        duration: 0,
      });

      await deleteCurrentProjectQuestion(questionId); // remove from the UI
      const result = await deleteProjectQuestion(currentProject.id, questionId);
      patchCurrentProjectQuestions(result); // apply new order
      messageApi.open({
        key,
        type: 'success',
        content: 'Question deleted',
        duration: 3,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleQuestionTranslationOnChange = async ({ questionId, language, changes }) => {
    console.log('Question ID:', questionId, 'Language:', language, 'Changes:', changes);
    const key = uuid();
    try {
      messageApi.open({
        key,
        type: 'loading',
        content: 'Updating question...',
        duration: 0,
      });

      await patchCurrentProjectQuestionTranslation(questionId, language, changes);
      await patchProjectQuestionTranslation(currentProject.id, questionId, language, changes);

      messageApi.open({
        key,
        type: 'success',
        content: 'Question updated',
        duration: 3,
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Used from QuestionCard.js, to update other languages. When used with handleQuestionTranslationOnChange - state is not updated
  const handleQuestionTranslationOnChangeOther = async ({ questionId, language, changes }) => {
    try {
      await patchProjectQuestionTranslation(currentProject.id, questionId, language, changes);
      await patchCurrentProjectQuestionTranslation(questionId, language, changes);
    } catch (error) {
      console.error(error);
    }
  };

  // const handleMoveQuestion = (index, direction) => {
  //   const newQuestions = [...currentProjectQuestions];
  //   const [removed] = newQuestions.splice(index, 1);
  //   newQuestions.splice(index + direction, 0, removed);
  //   setCurrentProjectQuestions(newQuestions);
  // };

  const handleQuestionOnMoveUp = async (questionId) => {
    console.log('Question ID:', questionId);
    const key = uuid();
    executeAsyncProcess(async () => {
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Moving question...',
          duration: 0,
        });

        const result = await patchProjectQuestionMoveUp(currentProject.id, questionId);
        patchCurrentProjectQuestions(result);
        messageApi.open({
          key,
          type: 'success',
          content: 'Question moved',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleQuestionOnMoveDown = async (questionId) => {
    console.log('Question ID:', questionId);
    const key = uuid();
    executeAsyncProcess(async () => {
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Moving question...',
          duration: 0,
        });

        const result = await patchProjectQuestionMoveDown(currentProject.id, questionId);
        patchCurrentProjectQuestions(result);
        messageApi.open({
          key,
          type: 'success',
          content: 'Question moved',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <div className='flex flex-col gap-2'>
      {contextHolder}
      {currentProjectQuestions
        ?.sort((a, b) => {
          const orderA = a.order ?? 0;
          const orderB = b.order ?? 0;
          return orderA < orderB ? -1 : orderA > orderB ? 1 : 0;
        })
        .map((projectQuestion, index) => (
          <div key={index} className='transition-all duration-300 flex items-stretch'>
            <div className='flex-grow'>
              <QuestionCard projectQuestion={projectQuestion} onChange={handleQuestionOnChange} onTranslationChange={handleQuestionTranslationOnChange} onTranslationChangeOther={handleQuestionTranslationOnChangeOther} onDelete={handleQuestionOnDelete} onMoveUp={handleQuestionOnMoveUp} onMoveDown={handleQuestionOnMoveDown} isFirst={index === 0} isLast={currentProjectQuestions.length <= index + 1} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default QuestionsWidget;
