import React, { useEffect, useState, useContext } from 'react';
import FsiReportContext from 'src/context/FsiReportContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { useNavigate } from 'react-router-dom';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList, LANGS_MAX_NUMBER } from './Misc/misc';
import { Space } from 'antd';
import { TbReportMoney } from 'react-icons/tb';
import Wizard from 'src/components/controls/Wizard/Wizard';
import WelcomeStep from './Components/CreateNewSteps/WelcomeStep';
import PickAccountStep from './Components/CreateNewSteps/PickAccountStep';
import SummaryStep from './Components/CreateNewSteps/SummaryStep';
import { postProject } from 'src/api/fsireport';
import LangsStep from './Components/CreateNewSteps/LangsStep';

//import PropTypes from 'prop-types';

var wizardCurrentStepGlobal = null;
const CreateNew = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const navigate = useNavigate();
  const { createNewSelectedAccount, createNewUniqueRegions, createNewLanguages, resetCreateNew } = useContext(FsiReportContext);
  const { executeAsyncProcess } = useAsyncProcesses();

  const [wizardCurrentStep, setWizardCurrentStep] = useState(0);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'FSI Report',
        path: '/fsi-report',
      },
      {
        title: 'New Project',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    wizardCurrentStepGlobal = wizardCurrentStep;
  }, [wizardCurrentStep]);

  const wizardSteps = [
    {
      title: 'Welcome',
      allowBack: false,
      header: <Space className='btn'>Welcome</Space>,
      content: <WelcomeStep />,
    },
    {
      title: 'Account',
      allowBack: true,
      allowNext: createNewSelectedAccount !== null,
      header: <Space className='btn'>Account</Space>,
      content: <PickAccountStep />,
    },
    {
      title: 'Languages',
      allowBack: true,
      allowNext: createNewLanguages?.length > 0 && createNewLanguages?.length <= LANGS_MAX_NUMBER,
      header: <Space className='btn'>Languages</Space>,
      content: <LangsStep />,
    },
    {
      title: 'Summary',
      allowBack: true,
      header: <Space className='btn'>Summary</Space>,
      content: <SummaryStep />,
    },
  ];

  const handleWizardOnNext = async () => {
    const step = wizardSteps[wizardCurrentStepGlobal];
    if (step.onNext) {
      await step.onNext();
    }

    setWizardCurrentStep(wizardCurrentStepGlobal + 1);
  };

  const handleWizardOnPrev = () => {
    setWizardCurrentStep(wizardCurrentStepGlobal - 1);
  };

  const handleWizardOnFinish = async () => {
    await executeAsyncProcess(async () => {
      try {
        const newProject = {
          accName: createNewSelectedAccount.ACCOUNT_NAME,
          snowflake_data: createNewSelectedAccount.SNOWFLAKE_DATA,
          accId: createNewSelectedAccount.ACCOUNT_ID,
          props: {
            uniqueAwsRegions: createNewUniqueRegions,
            langs: createNewLanguages,
          },
        };
        const project = await postProject(newProject);
        resetCreateNew();
        navigate(`/fsi-report/projects/${project.id}`);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleWizardOnAbort = () => {
    navigate('/fsi-report');
  };

  return (
    <PermissionsProvider requiredPermissions={['apps.fsireport.view']}>
      <TitleBar
        title={
          <Space className='btn'>
            <TbReportMoney />
            FSI Report
          </Space>
        }
        afterTitleExtras={
          <div>
            <PageSwitch pageList={pageList} />
          </div>
        }
      />
      <Wizard steps={wizardSteps} currentStep={wizardCurrentStep} onNext={handleWizardOnNext} onPrev={handleWizardOnPrev} onFinish={handleWizardOnFinish} onAbort={handleWizardOnAbort} />
    </PermissionsProvider>
  );
};

// CreateNew.propTypes = {};

export default CreateNew;
