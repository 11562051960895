import React, { useRef, useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useTheme from 'src/hooks/useTheme';
import { getMyTicketsGraph } from 'src/api/teacket';
import { Spin, Tag, Space } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const GraphMyTicketsActiveOldest = () => {
  const { isDarkMode } = useTheme();
  const [graphData, setGraphData] = useState(undefined);
  const refContainer = useRef();

  const themeMode = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  const loadData = async () => {
    try {
      let resp = await getMyTicketsGraph('active-oldest');
      if (!resp) resp = {};
      setGraphData(resp);
    } catch (error) {
      console.error(error);
      setGraphData({});
    }
  };

  useEffect(() => {
    if (!graphData) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={themeMode}>
      <div ref={refContainer}>
        {!graphData ? (
          <div className='text-center mt-10'>
            <Spin />
          </div>
        ) : (
          <>
            {graphData?.id ? (
              <>
                <div className='flex flex-row flex-wrap justify-center text-sm'>
                  <Space>
                    Ticket
                    <a href={'/teacket/tickets/' + graphData?.id}>{graphData?.id}</a>
                    <span>was updated</span> <Tag>{dayjs(graphData.updated_at).fromNow()}</Tag>
                  </Space>
                </div>
                <div className='text-xs font-thin text-center whitespace-break-spaces mt-2'>Ticket title: {graphData?.title}</div>
              </>
            ) : (
              <div className='flex flex-row flex-wrap justify-center'>You've no open tickets.</div>
            )}
          </>
        )}
      </div>
    </ThemeProvider>
  );
};
GraphMyTicketsActiveOldest.propTypes = {};

export default GraphMyTicketsActiveOldest;
