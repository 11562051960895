import React from 'react';
import useUserInfo from 'src/hooks/useUserInfo';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Space } from 'antd';
import { logout } from 'src/misc/Session';

import { LogoutOutlined } from '@ant-design/icons';
import { ReactComponent as UserMockup } from 'src/components/svg/user-mockup.svg';
import { LOGOUT_REASONS } from 'src/misc/Config';
//import PropTypes from 'prop-types';

const UserControl = () => {
  const userInfo = useUserInfo();
  const navigate = useNavigate();

  const items = [
    {
      label: <>My profile</>,
      key: 'my-profile',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <>
          <div>
            <div>
              <Space>
                <LogoutOutlined />
                Sign out
              </Space>
            </div>
            <div style={{ minWidth: '120px', maxWidth: '280px' }} className='overflow-hidden truncate text-ellipsis font-light text-xs'>
              <div>{userInfo.name}</div>
            </div>
          </div>
        </>
      ),
      key: 'sign-out',
    },
  ];

  const handleOnClick = ({ key }) => {
    switch (key) {
      case 'my-profile':
        navigate('/home/my-profile');
        break;
      case 'sign-out':
        logout(LOGOUT_REASONS.userLoggedOut);
        break;
      default:
        console.log('unhandled menu key');
        break;
    }
  };

  return (
    <div className='flex flex-col justify-center'>
      <Dropdown menu={{ items, onClick: handleOnClick }} trigger={['click']}>
        {/* <Button type='text' icon={<UserOutlined />} /> */}
        {userInfo.image ? <img src={userInfo.image} alt='user' className='h-10 w-10 cursor-pointer rounded-full border-2 border-solid border-genesys-azure-500' /> : <UserMockup className='h-10 w-10 cursor-pointer rounded-full border-2 border-solid border-sky-500' />}
      </Dropdown>
    </div>
  );
};

UserControl.propTypes = {};

export default UserControl;
