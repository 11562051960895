import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';

const PropertySection = ({ children }) => {
  const { theme } = useTheme();
  return (
    <div className='rounded-xl py-1 px-3' style={{ border: `1px solid ${theme.borderLight}` }}>
      {children}
    </div>
  );
};

PropertySection.propTypes = {
  children: PropTypes.any,
};

export default PropertySection;
