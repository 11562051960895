import React from 'react';
import { ReactComponent as FsiReportSvg } from 'src/components/svg/fsi-report.svg';
import CorporateColors from 'src/misc/CorporateColors';
//import PropTypes from 'prop-types';

const WelcomeStep = () => {
  return (
    <div className='text-center'>
      <h2>Welcome to the FSI Report Wizard</h2>
      <FsiReportSvg className='w-64 h-64' style={{ filter: `drop-shadow(0px 0px 15px ${CorporateColors.aqua})` }} />
      <p>Follow the steps to create a new project</p>
    </div>
  );
};

WelcomeStep.propTypes = {};

export default WelcomeStep;
