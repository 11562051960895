import React, { useState, useEffect } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { postEbIntegration } from 'src/api/auditviewer';
import GcPermissionsProvider from 'src/providers/GcPermissionsProvider';
import Wizard from 'src/components/controls/Wizard/Wizard';
import { Space, Result } from 'antd';
import { AiOutlineAudit } from 'react-icons/ai';
import { ReactComponent as SecurityAdvisorNotificationsSvg } from 'src/components/svg/audit-viewer-notifications.svg';
import CorporateColors from 'src/misc/CorporateColors';
import { FaLink } from 'react-icons/fa';
import { AV_APP_ID } from '../../Misc/misc';
// import { sleepMs } from 'src/misc/Misc';

var wizardCurrentStepGlobal = null;
const EventsSetupWizard = () => {
  const { executeAsyncProcess } = useAsyncProcesses();

  const wizardSteps = [
    {
      title: 'Welcome',
      allowBack: false,
      header: (
        <Space className='btn'>
          <AiOutlineAudit />
          Audit Viewer
          <span className='font-extralight'>Notifications</span>
        </Space>
      ),
      content: (
        <div className='text-center'>
          <div className='font-bold text-xl flex flex-row items-center justify-center gap-2'>
            Welcome to the <AiOutlineAudit /> Audit Viewer <span className='font-extralight'>Notifications</span>
          </div>
          <div className='mt-2'>The tool can automatically send real-time notifications to your email mailbox.</div>
          <div>
            <SecurityAdvisorNotificationsSvg className='w-64 h-64' style={{ filter: `drop-shadow(0px 0px 15px ${CorporateColors.aqua})` }} />
          </div>
          <div>
            <a href='/products/audit-viewer' target='_blank'>
              <Space className='btn'>
                <FaLink />
                Learn more on the product page
              </Space>
            </a>
          </div>
          <div className='mt-4'>
            Click the <strong>Next</strong> button to start configuring the tool.
          </div>
          <div className='mt-4'>
            We will handle the process for you.
            <br />
            Following items will be created:
            <br />
            - dedicated Event Bridge integration in your Genesys Cloud organization,
            <br />- notifications channel in SPC.
          </div>
        </div>
      ),
      onNext: async () => {
        executeAsyncProcess(async () => {
          try {
            // await sleepMs(5000);
            await postEbIntegration(AV_APP_ID);
            setWizardCurrentStep(wizardCurrentStepGlobal + 1);
          } catch (error) {
            console.log(error);
          }
        });
      },
    },
    {
      title: 'Configuring',
      allowBack: false,
      allowNext: false,
      header: (
        <Space className='btn'>
          <AiOutlineAudit />
          Audit Viewer
          <span className='font-extralight'>Notifications</span>
        </Space>
      ),
      content: (
        <>
          <div className='font-bold text-xl flex flex-row items-center justify-center gap-2'>Configuring</div>
          <div className='mt-2 text-center'>Please wait</div>
        </>
      ),
    },
    {
      title: 'Done',
      allowBack: false,
      header: (
        <Space className='btn'>
          <AiOutlineAudit />
          Audit Viewer
          <span className='font-extralight'>Notifications</span>
        </Space>
      ),
      content: (
        <div className='w-full h-full flex flex-col justify-center items-center gap-2'>
          <Result
            status='success'
            title='Configuration complete'
            subTitle={
              <>
                In order to receive notifications, click the <strong>Finish</strong> button, and then add a subscription.
              </>
            }
          />
        </div>
      ),
    },
  ];

  const [wizardCurrentStep, setWizardCurrentStep] = useState(0);

  useEffect(() => {
    wizardCurrentStepGlobal = wizardCurrentStep;
  }, [wizardCurrentStep]);

  const handleWizardOnNext = async () => {
    const step = wizardSteps[wizardCurrentStepGlobal];
    if (step.onNext) {
      await step.onNext();
    }

    setWizardCurrentStep(wizardCurrentStepGlobal + 1);
  };

  const handleWizardOnPrev = () => {
    setWizardCurrentStep(wizardCurrentStepGlobal - 1);
  };

  const handleWizardOnFinish = () => {
    window.location.reload();
  };
  //#end

  return (
    <GcPermissionsProvider
      requiredPermissions={[
        {
          domain: 'integrations',
          entityName: 'integration',
          action: 'add',
        },
        {
          domain: 'integrations',
          entityName: 'integration',
          action: 'edit',
        },
        {
          domain: 'integrations',
          entityName: 'integration',
          action: 'view',
        },
      ]}
    >
      <Wizard steps={wizardSteps} currentStep={wizardCurrentStep} onNext={handleWizardOnNext} onPrev={handleWizardOnPrev} onFinish={handleWizardOnFinish} />
    </GcPermissionsProvider>
  );
};

EventsSetupWizard.propTypes = {};

export default EventsSetupWizard;
