import StylesWrapper from './UserJourneyChart.styles';
import React from 'react';
import { PropTypes } from 'prop-types';
import ItemStarter from './ItemStarter';
import ItemItem from './ItemItem';
import ItemLinker from './ItemLinker';
import ItemFinisher from './ItemFinisher';
import { ROW_HEIGHT_INT } from './common';
import { v4 as uuid } from 'uuid';

const UserJourneyChart = ({ stageList, itemList }) => {
  const getHeaders = () =>
    stageList?.map((stage) => (
      <div key={uuid()} className='ujc-headers-item text-green-800 dark:text-green-200'>
        {stage.label}
      </div>
    ));

  const getBackground = () =>
    stageList?.map(() => (
      <div key={uuid()} className='ujc-rows-backgrounds-item'>
        <div className='ujc-rows-backgrounds-item-line' />
      </div>
    ));

  const getTopOffset = (stageId) => stageList.findIndex((x) => x.id === stageId) * ROW_HEIGHT_INT;

  const getData = () => {
    if (!Array.isArray(itemList) || itemList.length < 1) return [];
    const result = [];
    let leftOffset = 0;

    // <add starter>
    result.push(<ItemStarter key={uuid()} topOffset={getTopOffset(itemList[0].stageId)} leftOffset={leftOffset} />);
    leftOffset += ROW_HEIGHT_INT * 2;
    // </add starter>

    for (let i = 0; i < itemList.length; i++) {
      if (i > 0 && itemList[i - 1].stageId !== itemList[i].stageId) {
        // <add linker>
        result.push(<ItemLinker key={uuid()} stageIndexFrom={stageList.findIndex((x) => x.id === itemList[i - 1].stageId)} stageIndexTo={stageList.findIndex((x) => x.id === itemList[i].stageId)} leftOffset={leftOffset} />);
        leftOffset += ROW_HEIGHT_INT / 2;
        // </add linker>
      }
      // <add item>
      result.push(<ItemItem key={uuid()} topOffset={getTopOffset(itemList[i].stageId)} leftOffset={leftOffset} timestamp={itemList[i].timestamp} color={itemList[i].color} icon={itemList[i].icon} blink={itemList[i].blink} props={itemList[i].props} />);
      leftOffset += ROW_HEIGHT_INT;
      // </add item>
    }

    // <add finisher>
    result.push(<ItemFinisher key={uuid()} topOffset={getTopOffset(itemList[itemList.length - 1].stageId)} leftOffset={leftOffset} />);
    // </add finisher>

    return result;
  };

  if (!Array.isArray(stageList) || stageList.length < 1) return null;

  return (
    <StylesWrapper>
      <div className='ujc-main-box'>
        <div className='ujc-row-headers'>
          {getHeaders()}
          <div className='ujc-headers-footer' />
        </div>
        <div className='ujc-rows'>
          <div className='ujc-rows-backgrounds'>
            {getBackground()}
            <div className='ujc-backgrounds-footer' />
          </div>
          <div className='ujc-rows-data'>{getData()}</div>
        </div>
      </div>
    </StylesWrapper>
  );
};

UserJourneyChart.propTypes = {
  stageList: PropTypes.array,
  itemList: PropTypes.array,
};

export default UserJourneyChart;
