import React, { Component } from 'react';
import GlobalContext from './GlobalContext';
import { determineEnv, LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import { HomeOutlined } from '@ant-design/icons';
import { SCREEN_MODES, MOBILE_TRESHOLD } from 'src/misc/Config';

var runningProcesses1 = 0;
var dictionary1 = {};

export default class GlobalProvider extends Component {
  handleResize = () => {
    const screenMode = this.getScreenMode();
    if (screenMode !== this.state.screenMode) this.setState({ screenMode });
  };

  handleKeyDown = (e) => {
    // console.log('handleKeyDown', e.key);
    switch (e.key) {
      case 'Meta':
        this.setState({ commandPressed: true });
        break;
      case 'Shift':
        this.setState({ shiftPressed: true });
        break;
      default:
        break;
    }
  };

  handleKeyUp = (e) => {
    //console.log('handleKeyUp', e.key);
    switch (e.key) {
      case 'Meta':
        this.setState({ commandPressed: false });
        break;
      case 'Shift':
        this.setState({ shiftPressed: false });
        break;
      default:
        break;
    }
  };

  getScreenMode = () => {
    const { innerWidth: width } = window;
    return MOBILE_TRESHOLD < width ? SCREEN_MODES.desktop : SCREEN_MODES.mobile;
  };

  determineIsDarkMode = () => {
    // <user preference>
    const isDarkMode = localStorage.getItem(LOCAL_STORAGE_KEYS.isDarkMode);
    if (isDarkMode === 'true') return true;
    if (isDarkMode === 'false') return false;
    // </user preference>

    // <system preference>
    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return true;
      } else {
        return false;
      }
    }
    // </system preference>

    return false; // default if not determined
  };

  componentDidMount = () => {
    determineEnv();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
  };

  breadcrumbHome = [
    {
      title: <HomeOutlined className='cursor-pointer' />,
      path: '/home',
    },
  ];

  state = {
    isDarkMode: this.determineIsDarkMode(),
    screenMode: this.getScreenMode(),
    userInfo: null,
    runningProcesses: 0,
    breadcrumbItems: this.breadcrumbHome,
    oAuthVerified: {},
    oAuthClientCredentialsConfig: {},
    oAuthWizardSteps: () => {},
    dictionary: {},
    tokenExpiring: false,
    thinMode: false,
    featureList: null,
    featuresAccess: null,
    currentFeature: null,
    currentFeatureDisplayName: null,
    // keys:
    commandPressed: false,
    shiftPressed: false,
  };

  render() {
    return (
      <GlobalContext.Provider
        value={{
          ...this.state,
          setUserInfo: (userInfo) => this.setState({ userInfo }),
          executeAsyncProcess: async (func) => {
            runningProcesses1++;
            this.setState({ runningProcesses: runningProcesses1 });
            await func();
            runningProcesses1--;
            this.setState({ runningProcesses: runningProcesses1 });
          },
          isBusy: this.state.runningProcesses > 0,
          setBreadcrumbItems: (items) => this.setState({ breadcrumbItems: !items ? this.breadcrumbHome : this.breadcrumbHome.concat(items) }),
          toggleDarkMode: () => {
            const isDarkMode = !this.state.isDarkMode;
            this.setState({ isDarkMode });
            localStorage.setItem(LOCAL_STORAGE_KEYS.isDarkMode, isDarkMode ? 'true' : 'false');
          },
          setOAuthVerified: (appId, value) => {
            console.log('setOAuthVerified()', appId, value);
            let oAuthVerified = this.state.oAuthVerified ? { ...this.state.oAuthVerified } : {};
            oAuthVerified[appId] = value;
            this.setState({ oAuthVerified });
          },
          setOAuthClientCredentialsConfig: (appId, value) => {
            console.log('setOAuthClientCredentialsConfig()', appId, value);
            let oAuthClientCredentialsConfig = this.state.oAuthClientCredentialsConfig ? { ...this.state.oAuthClientCredentialsConfig } : {};
            oAuthClientCredentialsConfig[appId] = value;
            this.setState({ oAuthClientCredentialsConfig });
          },
          setOAuthWizardSteps: (appId, value) => {
            console.log('setOAuthWizardSteps()', appId, value);
            let oAuthWizardSteps = this.state.oAuthWizardSteps ? { ...this.state.oAuthWizardSteps } : {};
            oAuthWizardSteps[appId] = value;
            this.setState({ oAuthWizardSteps });
          },
          updateDictionary: (group, keys) => {
            let dictionary = dictionary1 ? { ...dictionary1 } : {};
            dictionary[group] = keys;
            dictionary1 = dictionary;
            this.setState({ dictionary });
          },
          setTokenExpiring: (tokenExpiring) => this.setState({ tokenExpiring }),
          setThinMode: (thinMode) => this.setState({ thinMode }),
          setFeatureList: (featureList) => this.setState({ featureList }),
          setFeaturesAccess: (feature, data) => {
            let featuresAccess = this.state.featuresAccess ? { ...this.state.featuresAccess } : {};
            featuresAccess[feature] = data;
            this.setState({ featuresAccess });
          },
          getFeatureAccess: (feature) => {
            return this.state.featuresAccess ? this.state.featuresAccess[feature] : null;
          },
          setCurrentFeature: (currentFeature) => this.setState({ currentFeature }),
          resetCurrentFeature: () => this.setState({ currentFeature: null }),
          setCurrentFeatureDisplayName: (currentFeatureDisplayName) =>
            this.setState({
              currentFeatureDisplayName,
            }),
          resetCurrentFeatureDisplayName: () => this.setState({ currentFeatureDisplayName: null }),
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}
