import React from 'react';
import PropTypes from 'prop-types';
import { notEmptyArray } from 'src/misc/Misc';
import UserSelectorItem from './UserSelectorItem';

const UserSelector = ({ userList, onSelect, value }) => {
  const handleOnClick = (id) => {
    if (!onSelect) return;
    onSelect(id);
  };

  return (
    <div className='flex flex-row gap-2 flex-wrap'>
      {true === notEmptyArray(userList) ? (
        <>
          {userList.map((x) => (
            <React.Fragment key={x.id}>
              <UserSelectorItem id={x.id} name={x.name} image={x.image} onClick={handleOnClick} selected={x.id === value} />
            </React.Fragment>
          ))}
        </>
      ) : (
        <UserSelectorItem placeholder={true} />
      )}
    </div>
  );
};

UserSelector.propTypes = {
  userList: PropTypes.array,
  onSelect: PropTypes.func,
  value: PropTypes.string,
};

export default UserSelector;
