import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .isoChartControl {
    display: flex;
    align-items: center;
    margin-left: ${(props) => (props['data-rtl'] === 'rtl' ? 'inherit' : 'auto')};
    margin-right: ${(props) => (props['data-rtl'] === 'rtl' ? 'auto' : 'inherit')};
    margin-bottom: 20px;

    span {
      font-size: 13px;
      font-weight: 400;
      margin-right: ${(props) => (props['data-rtl'] === 'rtl' ? 'inherit' : '15px')};
      margin-left: ${(props) => (props['data-rtl'] === 'rtl' ? '15px' : 'inherit')};
    }

    button {
      padding: 0 10px;
      border-radius: 0;
      position: relative;

      span {
        margin: 0;
      }

      &:last-child {
        margin-left: ${(props) => (props['data-rtl'] === 'rtl' ? 'inherit' : '-1px')};
        margin-right: ${(props) => (props['data-rtl'] === 'rtl' ? '-1px' : 'inherit')};
      }

      &:hover {
        z-index: 1;
        span {
        }
      }
    }
  }
`;

class TrendChart2 extends Component {
  render() {
    const { datas } = this.props;

    function formatYAxis(value) {
      switch (value) {
        case 100:
          return 'CRIT';
        case 75:
          return 'HIGH';
        case 50:
          return 'MED';
        case 25:
          return 'LOW';
        case 0:
          return '';
        default:
          return '';
      }
    }

    return (
      <StyledDiv className='isoChartWrapper' style={{ overflowY: 'hidden' }}>
        <ResponsiveContainer minHeight='217px'>
          <BarChart data={datas} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
            <XAxis dataKey={'date'} hide={false} angle={this.props.xAngle} dy={this.props.xDy} dx={this.props.xDx} height={this.props.xHeight} />
            <YAxis tickFormatter={formatYAxis} domain={[0, 100]} />
            <Bar dataKey={'score'} fill='#00b2af' />
          </BarChart>
        </ResponsiveContainer>
      </StyledDiv>
    );
  }
}

TrendChart2.propTypes = {
  datas: PropTypes.array,
  xAngle: PropTypes.number,
  xDy: PropTypes.number,
  xDx: PropTypes.number,
  xHeight: PropTypes.number,
};

TrendChart2.defaultProps = {
  datas: [],
  xAngle: 0,
  xDy: 10,
  xDx: 0,
  xHeight: 40,
};

export default TrendChart2;
