import React from 'react';
import { PropTypes } from 'prop-types';
import { EVENT_GLOBAL_MSG_RCVD } from './misc';

const GlobalWsMessenger = ({ children, actionList, onMessageReceived }) => {
  React.useEffect(() => {
    console.log('[GlobalWsMessenger] adding listener:', EVENT_GLOBAL_MSG_RCVD);
    document.addEventListener(EVENT_GLOBAL_MSG_RCVD, onEventReceived);
    return () => {
      // console.log('[GlobalWsMessenger] removing listener:', EVENT_GLOBAL_MSG_RCVD);
      document.removeEventListener(EVENT_GLOBAL_MSG_RCVD, onEventReceived);
    };
  }, []);

  const onEventReceived = (e) => {
    // console.log('[GlobalWsMessenger] onEventReceived()', { e, actionList });
    const { detail } = e;
    if (Array.isArray(actionList) && actionList.includes(detail.action)) {
      onMessageReceived(detail);
    }
  };

  if (!children) return null;
  return children;
};

GlobalWsMessenger.propTypes = {
  children: PropTypes.any,
  actionList: PropTypes.array.isRequired,
  onMessageReceived: PropTypes.func.isRequired,
};

GlobalWsMessenger.defaultProps = {
  actionList: [],
  onMessageReceived: (e) => {
    console.warn('[GlobalWsMessenger] WS message has been received bit the event is not handled.', e);
  },
};

export default GlobalWsMessenger;
