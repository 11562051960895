import React from 'react';
import { Card } from 'antd';

const HelpSettingsWidget = () => {
  return (
    <div className='max-h-[600px] overflow-y-auto border border-gray-300 p-4'>
      {/* <Card
        title={
          <Space2>
            <AiOutlineAudit />
            <span className='font-light'>
              <span className='text-genesys-warmred-base'>Live notifications</span> with <strong>Audit Viewer</strong>
            </span>
          </Space2>
        }
        extra={
          <Button size='small' type='primary' onClick={() => window.open('/products/audit-viewer', '_blank')}>
            Learn more...
          </Button>
        }
      >
        Security is an important aspect of your Genesys Cloud organization. If you want to be informed in real time about security events in you org try our <strong>Audit Viewer</strong> app.
        <br />
        <br />
        <strong>Audit Viewer</strong> provides a centralized platform for viewing and analyzing audit logs generated within your Genesys Cloud environment. With its streamlined interface, <strong>Audit Viewer</strong> enables users to quickly locate and review specific events, actions, or changes across a wide range of parameters, including user activity and configuration modifications.
      </Card> */}
      <Card title='Authorization' className='mt-4'>
        <h3>Stale OAuth Clients</h3>
        <p className='ml-2'>
          <p>Stale OAuth Clients refer to OAuth clients that are defined within your system but have not been utilized for a specified period. These clients, while still registered, have remained inactive and have not participated in any OAuth transactions or activity within the set timeframe. Identifying and managing stale OAuth clients is crucial for maintaining the security and efficiency of your system, ensuring that only actively used clients retain access and permissions.</p>
          <p>We begin by downloading all OAuth clients defined in your system through the following API endpoint:</p>
          <p className='bg-gray-800 font-mono text-yellow-400 px-1 rounded p-1'>GET /api/v2/oauth/clients</p>
          <p>Next, we filter out all clients that have been used for the configured amount of time (in months), including the current month. For example (3 months configuration), if the report is generated on August 4th, we will filter out all clients that have been used since May 1st.</p>
          <p>Finally, we compare the complete list of clients with the list of clients that have been used in the last three months. Any clients that have not been used in this timeframe will be marked as stale.</p>
        </p>
        <h3>Users Not Logged In</h3>
        <p className='ml-2'>
          <p>Users Not Logged In are those who are registered within the system, complete with assigned permissions, roles, and other relevant configurations, but have not accessed or logged into the system for a specified duration. Monitoring and addressing this inactivity is essential for maintaining the integrity and security of user management, ensuring that access and roles are up-to-date and reflective of current usage.</p>
          <p>First we download list of all users defined in your system with dateLastLogin property through the following API endpoint:</p>
          <p className='bg-gray-800 font-mono text-yellow-400 px-1 rounded p-1'>GET /api/v2/users?expand=dateLastLogin</p>
          <p>As a last step we filter out those users that do not logged in for the defined amount of time (in months)</p>
        </p>
        <h3>Session Timeout</h3>
        <p className='ml-2'>
          <p>The Session Timeout setting, available in the Organisation Settings panel, enforces user authentication after a period of inactivity. When enabled, it requires users to re-authenticate if they remain idle for a specified duration. The timeout period can be configured between 5 and 480 minutes, ensuring a balance between security and user convenience. This feature helps maintain system security by preventing unauthorized access due to prolonged inactivity.</p>
          <p>We obtain necessery settings through the following API endpoint:</p>
          <p className='bg-gray-800 font-mono text-yellow-400 px-1 rounded p-1'>GET /platform/api/v2/tokens/timeout</p>
        </p>

        <h3>Password Requirements</h3>
        <p className='ml-2'>
          <p>Password Requirements refer to the rules and constraints that define the complexity and security of user passwords within your system. These requirements, such as minimum length, character types, and expiration periods, help ensure that user accounts are protected against unauthorized access and security breaches. By enforcing strong password requirements, you can enhance the overall security of your system and safeguard user data and accounts.</p>
          <p>We obtain necessery settings through the following API endpoint:</p>
          <p className='bg-gray-800 font-mono text-yellow-400 px-1 rounded p-1'>GET /api/v2/organizations/authentication/settings</p>
          Then we compare the settings with the criteria defined by the admin. If any of the parameters differ from the Genesys Cloud Organization settings, the risk level will be set to High.
          <br />
          This approach ensures that password requirements meet established security best practices and effectively protect user accounts.
        </p>
      </Card>

      <Card title='Encryption' className='mt-4'>
        <h3>Encryption Keys (for recordings)</h3>
        <p className='ml-2'>
          <p>During evaluation several API requests are made:</p>
          <p className='bg-gray-800 font-mono text-yellow-400 px-1 rounded p-1'>GET /api/v2/recording/recordingkeys/rotationschedule</p>
          <p className='bg-gray-800 font-mono text-yellow-400 px-1 rounded p-1'>GET /api/v2/recording/recordingkeys</p>
          <p className='bg-gray-800 font-mono text-yellow-400 px-1 rounded p-1'>GET /api/v2/conversations/keyconfigurations</p>
          {/* <p>Final score depends on information if Organization is PCI Enabled.</p> */}
          <p>Stauts is calculated as follows:</p>
          {/* <p>
            For <b>Not</b> PCI Enabled Organizations, if last created Recording Key is older than 1 year Warning status is returned, otherwise Success.
          </p> */}
          <p>
            Final satus is determined by the age of the last created Recording Key. <br />
            For example if definition is set as a range between 90 and 180 days, the following statuses are returned: <br />
            If last generated date for Encryption keys retrieved from Genesys Cloud will be lower or equal 90 days, security level will be set to Low risk, if the same parameter will be between 90 and 180, it will be marked as Medium risk, finally it will be marked as High risk if the value will be greater than 180 days
          </p>
        </p>
        <h3>Not TLS Trunks</h3>
        <p className='ml-2'>
          <p>
            This feature performs a comprehensive check of trunk base settings to ensure that the TLS (Transport Layer Security) option is enabled. Key aspects include:
            <ul>
              <li>Security Verification</li>
              <li>Compliance Check</li>
              <li>Enchanced Protection</li>
            </ul>
          </p>
          <p>We obtain necessery settings through the following API endpoint:</p>
          <p className='bg-gray-800 font-mono text-yellow-400 px-1 rounded p-1'>GET /api/v2/telephony/providers/edges/trunkbasesettings</p>
          <p>The final score is determined by the proportion of enabled trunks that have TLS activated compared to the total number of enabled trunks.</p>
          <p>This feature is crucial for maintaining secure and reliable communication channels within the organization, ensuring all trunks meet the highest security standards.</p>
        </p>
      </Card>

      <Card title='Integrations' className='mt-4'>
        <h3>Embeddable Domain List</h3>
        <p className='ml-2'>
          <p>This feature ensures secure and controlled embedding of Genesys Cloud applications, aligning with organizational security policies. Restricts the embedding of Genesys Cloud applications to a specified list of domains. Only the domains entered in the list are authorized to embed Genesys Cloud applications.</p>
          <p>We obtain necessery settings through the following API endpoint:</p>
          <p className='bg-gray-800 font-mono text-yellow-400 px-1 rounded p-1'>GET /api/v2/organizations/authentication/settings</p>
          <p>If Restrict Genesys Cloud Embedding feature is enabled, and there is at least 1 domain provided - 100% points are submitted, otherwise 0 points are returned.</p>
        </p>
        <h3>IP Address Allowlist</h3>
        <p className='ml-2'>
          <p>This feature ensures secure and controlled embedding of Genesys Cloud applications, aligning with organizational security policies. Restricts the embedding of Genesys Cloud applications to a specified list of IP Addresses.</p>
          <p>We obtain necessery settings through the following API endpoint:</p>
          <p className='bg-gray-800 font-mono text-yellow-400 px-1 rounded p-1'>GET /api/v2/organizations/authentication/settings</p>
          <p>If there is at least 1 IP address provided - 100% of the points are submitted, otherwise 0 points are returned.</p>
        </p>
      </Card>

      <Card title='Regulations' className='mt-4'>
        <h3>HIPAA</h3>
        <p className='ml-2'>
          <p>This feature is required for compliance with the Health Insurance Portability and Accountability Act (HIPAA).</p>
          <p>We obtain necessery settings through the following API endpoint:</p>
          <p className='bg-gray-800 font-mono text-yellow-400 px-1 rounded p-1'>GET /api/v2/organizations/me</p>
          <p>If this feature is enabled - 100% points are submitted, otherwise 0 points are returned.</p>
        </p>
        <h3>PCI DSS</h3>
        <p className='ml-2'>
          <p>This feature is required for compliance with the Payment Card Industry Data Security Standard (PCI DSS) for handling payment cardholder data.</p>
          <p>We obtain necessery settings through the following API endpoint:</p>
          <p className='bg-gray-800 font-mono text-yellow-400 px-1 rounded p-1'>GET /api/v2/organizations/me</p>
          <p>If this feature is enabled - 100% points are submitted, otherwise 0 points are returned.</p>
        </p>
      </Card>
    </div>
  );
};

HelpSettingsWidget.propTypes = {};

export default HelpSettingsWidget;
