//import { sleepMs } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';
import { objToQueryStringParams } from 'src/misc/Misc';

//#region "PROJECTS"

var controllerGetProjectList;

export const getProjectList = async (query, page = 1, pageSize = 24) => {
  controllerGetProjectList?.abort('cancelled');
  controllerGetProjectList = new AbortController();
  const signal = controllerGetProjectList.signal;
  const queryStringParams = objToQueryStringParams({ query, page, pageSize });

  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getProject = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const deleteProject = async (id, deleteReports) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}${deleteReports ? '?deleteReports=true' : ''}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postProject = async (body) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchProject = async (id, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchProjectProps = async (id, changes) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}/props`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

export const addQuestionToProject = async (id, question) => {
  console.log('addQuestionToProject', id, question);
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}/questions`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(question),
    })
  );
};

export const removeQuestionFromProject = async (id, questionId) => {
  console.log('removeQuestionFromProject', id, questionId);
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}/questions/${questionId}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const patchProjectQuestions = async (id, questions) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}/questions`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ questions }),
    })
  );
};

//#endregion

//#region "PROJECT QUESTIONS"

export const getProjectQuestionsAvailable = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}/questions/available`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getProjectQuestions = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}/questions`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const patchProjectQuestion = async (projectId, questionId, changes) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${projectId}/questions/${questionId}`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

export const patchProjectQuestionMoveUp = async (projectId, questionId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${projectId}/questions/${questionId}/move/up`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const patchProjectQuestionMoveDown = async (projectId, questionId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${projectId}/questions/${questionId}/move/down`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const patchProjectQuestionTranslation = async (projectId, questionId, language, changes) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${projectId}/questions/${questionId}/translations/${language}`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

export const deleteProjectQuestion = async (projectId, questionId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${projectId}/questions/${questionId}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postProjectQuestions = async (id, questionIdList) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}/questions`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(questionIdList),
    })
  );
};

//#endregion

//#region "REPORTS"

export const getReportList = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}/reports`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postCreateReport = async (id, delivery = 'sharepoint') => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}/reports?delivery=${delivery}`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const deleteReportFile = async (id, reportId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}/reports/${reportId}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getReportFolder = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/projects/${id}/folder`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "OTHERS"

export const getIncidentsForRegions = async (regions) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/incidents?regions=${encodeURIComponent(regions)}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDeprecations = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/deprecations`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region Questions

var controllerGetQuestions;

export const getQuestions = async (query, page = 1, pageSize = 24) => {
  controllerGetQuestions?.abort('cancelled');
  controllerGetQuestions = new AbortController();
  const signal = controllerGetQuestions.signal;
  const queryStringParams = objToQueryStringParams({ query, page, pageSize });

  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/questions${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getQuestion = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/questions/${id}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postQuestion = async (body) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/questions`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchQuestion = async (id, body) => {
  const headers = await getAccessHeaders();

  return handleResponse(
    await fetch(`${ENV.apiUrl}/fsireport/questions/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

//#endregion
