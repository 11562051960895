import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import { refreshToken } from 'src/api/common';
import { LOGOUT_REASONS } from 'src/misc/Config';
import { Alert, Button } from 'antd';
import { logout } from 'src/misc/Session';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import dayjs from 'dayjs';

//import PropTypes from 'prop-types';

const TokenRefreshBanner = () => {
  const { tokenExpiring, setTokenExpiring } = useContext(GlobalContext);
  const { executeAsyncProcess } = useAsyncProcesses();
  const [expiriesAt, setExpiriesAt] = useState(null);

  useEffect(() => {
    if (!tokenExpiring) {
      setExpiriesAt(null);
      return;
    }
    const expiresAtStr = localStorage.getItem(LOCAL_STORAGE_KEYS.authExpiresAt);
    if (!expiresAtStr) return;
    //const expiresAtDate = new dayjs(expiresAtStr);
    setExpiriesAt(expiresAtStr);
  }, [tokenExpiring]);

  if (!tokenExpiring) return null;

  return (
    <Alert
      className='sticky top-0 z-50 h-[50px] rounded-none'
      message={
        <span>
          Your session is about to expire {expiriesAt ? dayjs(expiriesAt).fromNow() : null}. Click{' '}
          <Button
            type='link'
            className='p-0.5'
            onClick={() => {
              executeAsyncProcess(async () => {
                try {
                  await refreshToken();
                  setTokenExpiring(false);
                } catch (error) {
                  console.error('Error refreshing token:', error);
                  await logout(LOGOUT_REASONS.tokenExpired);
                }
              });
            }}
          >
            here
          </Button>{' '}
          to refresh it now or wait for auto-refresh.
        </span>
      }
      type='warning'
      showIcon
    />
  );
};

TokenRefreshBanner.propTypes = {};

export default TokenRefreshBanner;
