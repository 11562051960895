import React, { useState } from 'react';
import { Select, Spin, Space, Modal, Button, Tree } from 'antd';
import { getSites, getDriveId, getFiles } from 'src/api/sharepoint';
import { PiIdentificationBadgeFill } from 'react-icons/pi';
import PropTypes from 'prop-types';

const { Option } = Select;

const SharepointItemsPicker = ({ open, onSelect, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [driveId, setDriveId] = useState(null);
  const [folderRootPath, setFolderRootPath] = useState('/');

  const [selectedItem, setSelectedItem] = useState(null);
  const [treeData, setTreeData] = useState([]);

  const handleGetFiles = async (driveId, folderPath) => {
    try {
      setLoading(true);
      const resp = await getFiles(driveId, folderPath);
      return resp;
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (query) => {
    setItemsList([]);
    if (query.length < 3) return;
    try {
      setLoadingSearch(true);
      setDriveId(null);
      setTreeData([]);
      const resp = await getSites(query);
      if (resp) {
        setItemsList(resp);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSearch(false);
    }
  };

  const handleClear = () => {
    setSelectedItem(null);
    setItemsList([]);
    setDriveId(null);
    setTreeData([]);
    setFolderRootPath('/');
  };

  const handleAssign = async (itemId) => {
    console.log('Selected item:', itemId);
    setSelectedItem(itemId);

    try {
      setLoading(true);
      setDriveId(null);
      setTreeData([]);
      setFolderRootPath('/');
      const resp = await getDriveId(itemId);
      if (resp) {
        setDriveId(resp.driveId);
        const rootItems = await handleGetFiles(resp.driveId, '/');
        setTreeData(formatTreeData(rootItems, '/'));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatTreeData = (items, folderPath) => {
    return items.map((item) => ({
      title: item.name,
      key: folderPath + item.name,
      isLeaf: /\.\w{3,4}$/.test(item.name), // Check if the name has an extension (is a file)
    }));
  };

  const updateTreeData = (list, key, children) => {
    return list.map((node) => {
      if (node.key === key) {
        return { ...node, children }; // Replace node with loaded children
      }
      if (node.children) {
        return { ...node, children: updateTreeData(node.children, key, children) }; // Recursively update nested nodes
      }
      return node;
    });
  };

  const onLoadData = async (node) => {
    if (node.children || node.isLeaf) return; // If children already loaded or it's a file, do nothing
    const childNodes = await handleGetFiles(driveId, node.key);
    const formattedChildNodes = formatTreeData(childNodes, node.key + '/');
    setTreeData((prev) => updateTreeData(prev, node.key, formattedChildNodes)); // Update tree with new children
  };

  const onSelectNode = async (selectedKeys, { node }) => {
    console.log('selectedNode', node);
    setFolderRootPath(node.key);
  };

  const onSubmit = () => {
    console.log('onSubmit');
    if (onSelect) onSelect({ siteId: selectedItem, driveId, folderRootPath });
  };

  return (
    <Modal
      open={open}
      title='Sharepoint Picker'
      forceRender
      onCancel={onClose}
      footer={[
        <>
          <div className='flex flex-auto'>
            <div className='flex flex-auto text-left w-full'>
              <div className='text-xs font-light opacity-60 overflow-hidden mt-2'>{folderRootPath.length > 40 ? '...' + folderRootPath.slice(-40) : folderRootPath}</div>
            </div>
            <div className='flex flex-auto text-right '>
              <Button key='cancel' onClick={onClose}>
                Cancel
              </Button>
              <Button key='submit' type='primary' className='ml-2' onClick={onSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </>,
      ]}
    >
      <div className='flex flex-row gap-2 my-1'>
        <Select
          value={selectedItem}
          showSearch
          allowClear
          placeholder='type to find a site...'
          notFoundContent={loadingSearch ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
          filterOption={false}
          onSearch={handleSearch}
          onClear={handleClear}
          onSelect={handleAssign}
          style={{ width: '100%' }}
          size='large'
          optionRender={(option) => {
            const item = itemsList.find((x) => x.siteId === option.key);
            if (!item) return null;

            return (
              <div className='flex flex-row gap-2 my-1'>
                <div className='flex flex-col'>
                  <div className='overflow-hidden'>{item.displayName}</div>

                  <div className='text-xs font-light opacity-60 overflow-hidden'>
                    <Space className='btn'>
                      <PiIdentificationBadgeFill />
                      {item.description ?? <span className='italic font-extralight'>Description unavailable</span>}
                    </Space>
                  </div>
                </div>
              </div>
            );
          }}
        >
          {itemsList.map((item) => (
            <Option key={item.siteId} autoComplete='off'>
              {item.displayName}
            </Option>
          ))}
        </Select>
      </div>

      <div className='text-xs font-light opacity-60 overflow-hidden mt-2 mb-2'>#drive Id: {driveId ? driveId.substring(0, 40) + '...' : <span className='italic'>not set</span>}</div>
      {treeData.length === 0 && (
        <div className='text-center'>
          <Spin spinning={loading} size='small' tip='Loading...'></Spin>
        </div>
      )}
      {treeData.length > 0 && (
        <Tree
          showLine
          loadData={onLoadData} // Load subfolders only on expand
          treeData={treeData}
          style={{ marginTop: 16 }}
          onSelect={onSelectNode}
          height={300}
        />
      )}
    </Modal>
  );
};

SharepointItemsPicker.propTypes = {
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

SharepointItemsPicker.defaultProps = {
  onSelect: () => {
    console.warn('Function onSelect not provided');
  },
};

export default SharepointItemsPicker;
