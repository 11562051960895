import React, { useState } from 'react';
import { Checkbox, Input, Space } from 'antd';
//import PropTypes from 'prop-types';

const StepSnowFlake = () => {
  const [enabled, setEnabled] = useState(false);

  return (
    <div className='flex flex-row'>
      <div style={{ borderRight: '1px solid gray' }}>
        <img src='/images/misc/snowflake_logo.png' alt='snowflake' className='h-12 mr-8' />
      </div>
      <div className='flex-auto ml-8'>
        <Space direction='vertical'>
          <Checkbox
            checked={enabled}
            onChange={() => {
              setEnabled(!enabled);
            }}
          >
            Enable Snowflake integration
          </Checkbox>
          <div className='mb-[-4px] text-xs'>API URL:</div>
          <Input placeholder='Enter URL' disabled={!enabled} allowClear />
          <div className='mb-[-4px] text-xs'>Client ID:</div>
          <Input placeholder='Enter client id' disabled={!enabled} allowClear />
          <div className='mb-[-4px] text-xs'>Client Secret:</div>
          <Input.Password placeholder='Enter client secret' disabled={!enabled} allowClear />
        </Space>
      </div>
    </div>
  );
};

StepSnowFlake.propTypes = {};

export default StepSnowFlake;
