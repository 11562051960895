import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//import useAsyncProcesses from 'src/hooks/useAsyncProcesses';

import { Button, Form, Input, Modal, notification, Select, Space, Checkbox } from 'antd';
import { getFiles } from 'src/api/microsoft';

import styled from 'styled-components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FiExternalLink } from 'react-icons/fi';
import { TfiReload } from 'react-icons/tfi';
import { uuid } from 'short-uuid';

dayjs.extend(relativeTime);

const StylesWrapper = styled.div`
  .ant-upload .ant-upload-btn {
    padding: 0 !important;
  }
`;

const { Option } = Select;

const FileModal = ({ item, open, onClose, onSubmit }) => {
  const [form] = Form.useForm();

  const { TextArea } = Input;
  const [disabled, setDisabled] = useState(true);
  const [spFiles, setSpFiles] = useState([]);
  const [selectedSPFile, setSelectedSPFile] = useState(null);
  const [busy, setBusy] = useState(false);
  const [skipWatermark, setSkipWatermark] = useState(false);

  const handleOnReset = () => {
    //setModified(false);
  };

  const handleOnSelect = (v) => {
    console.log('handleOnSelect()');
    const spFile = spFiles.find((file) => file.id === v);
    setSelectedSPFile(spFile);
    form.setFieldsValue({ name: spFile.name, url: spFile.name });
  };

  const handleOpenLink = () => {
    console.log('handleOpenLink()');
    window.open(selectedSPFile.webUrl, '_blank');
  };

  const onChangeStatus = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setSkipWatermark(e.target.checked);
  };

  useEffect(() => {
    if (item?.id) {
      console.log('Edit item:', item);

      setSkipWatermark(item.skip_watermark);
      form.setFieldsValue({
        id: item.id,
        title: item.title,
        name: item.name,
        desc: item.desc,
        url: item.url,
        sp_path: item.sp_path ?? 'SPC Portal/AI-approved Documents',
      });
    } else {
      form.setFieldsValue({
        id: uuid(),
        sp_path: 'SPC Portal/AI-approved Documents',
      });
    }
    loadFiles();
  }, [item]);

  const loadFiles = async () => {
    try {
      setBusy(true);
      const resp = await getFiles(form.getFieldValue('sp_path'));
      setSpFiles(resp);

      if (item?.id) {
        const spFile = resp.find((file) => file.name === item.url);
        setSelectedSPFile(spFile);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBusy(false);
    }
  };

  const handleOnFinish = async (values) => {
    console.log('[Modal] Success:', values);

    values.url = selectedSPFile?.name;
    values.web_link = selectedSPFile?.webUrl;
    values.skip_watermark = skipWatermark;
    if (onSubmit) onSubmit(values);
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('[Modal] Failed:', errorInfo);
    notification.error({
      message: 'Error',
      description: <>Provided data is invalid</>,
      duration: 5,
    });
  };

  const handleOnValuesChange = async (changedValues, allValues) => {
    form
      .validateFields()
      .then(() => {
        setDisabled(false);
      })
      .catch((errorFields) => {
        setDisabled(errorFields.errorFields.length > 0);
      });
  };

  return (
    <Modal
      open={open}
      title={item?.id ? 'Edit File' : 'New File'}
      forceRender
      okButtonProps={{ disabled: disabled }}
      onCancel={() => {
        if (onClose) onClose();
      }}
      footer={[
        <>
          <div className={'flex flex-auto'}>
            <div className='flex  text-right'>
              <Button key='cancel' onClick={onClose}>
                Cancel
              </Button>
              <Button key='submit' type='primary' className='ml-2' onClick={form.submit}>
                Add
              </Button>
            </div>
          </div>
        </>,
      ]}
    >
      <StylesWrapper>
        <Form form={form} style={{ maxWidth: 600 }} layout='vertical' initialValues={{}} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} onReset={handleOnReset} onValuesChange={handleOnValuesChange} autoComplete='off'>
          <Form.Item name='id' hidden={true} />
          <Form.Item label='Title' name='title' rules={[{ required: true, message: 'Title is Required' }]}>
            <Input placeholder='Enter a name for item' autoComplete='off' maxLength={64} allowClear />
          </Form.Item>
          <Space>
            <Form.Item label='Sharepoint Path' name='sp_path' className='w-96' rules={[{ required: true, message: 'Path is required' }]}>
              <Input disabled={busy} placeholder='Enter Sharepoint path' autoComplete='off' maxLength={64} allowClear />
            </Form.Item>
            <Button type='link'>
              <TfiReload onClick={() => loadFiles()} />
            </Button>
          </Space>
          <Space>
            <Form.Item label='Linked File' name='url' className='w-96' rules={[{ required: true, message: 'Select Sharepoint File' }]}>
              <div className='ml-1 mt-2'>
                <Select loading={busy} disabled={busy} style={{ width: '100%' }} placeholder='Select Sharepoint File' value={selectedSPFile?.name} onSelect={handleOnSelect}>
                  {spFiles?.map((file) => (
                    <Option key={file.id} value={file.id}>
                      {file.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
            <Button type='link'>
              <FiExternalLink onClick={handleOpenLink} />
            </Button>
          </Space>
          <Form.Item label='Downloadable Name' name='name' rules={[{ required: true, message: 'Enter a name for your downloadable item' }]}>
            <Input placeholder='Enter a name for item' autoComplete='off' maxLength={64} allowClear />
          </Form.Item>
          <Form.Item label='Description' name='desc'>
            <TextArea className='mt-1' rows={6} placeholder='Description of your Item' autoComplete='off' />
          </Form.Item>

          <div className='flex flex-auto'>
            <Checkbox name='is_active' checked={skipWatermark} onChange={onChangeStatus}>
              <span>Skip watermarking {skipWatermark && <span className='text-xs pl-2 text-orange-600'>(When enabled, Item is not watermarked)</span>}</span>
            </Checkbox>
          </div>
        </Form>
      </StylesWrapper>
    </Modal>
  );
};

FileModal.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default FileModal;
