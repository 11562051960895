import React, { useEffect, useState } from 'react';
import useTheme from 'src/hooks/useTheme';
import PropTypes from 'prop-types';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getAdminServiceTenants, putAdminServiceTenant, deleteAdminServiceTenant } from 'src/api/teacket';
import { Button, Checkbox, Tag, Space, Switch, message } from 'antd';
import TenantPicker from 'src/components/controls/TenantPicker/TenantPicker';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import { FaCircleMinus } from 'react-icons/fa6';
import { uuid } from 'short-uuid';
import CondensedTable from 'src/components/layout/CondensedTable';
import TeacketServiceEditorPermissions from './TeacketServiceEditorPermissions';
import NoData from 'src/components/layout/NoData';
import { isRequestCancelled } from 'src/misc/Misc';

const TeacketServiceEditorTenants = ({ serviceId }) => {
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: 'Tenant ID',
      key: 'tenant_id',
      width: 360,
      render: (record) => (
        <Button
          className='px-0'
          type='link'
          onClick={() => {
            window.open(`/master-admin/tenants?tenantId=${record.tenant_id}`, '_blank');
          }}
        >
          {record.tenant_id}
        </Button>
      ),
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => (
        <Space className='btn'>
          {record.tenant_name}
          {record.justCreated && <Tag color='green'>new</Tag>}
        </Space>
      ),
    },
    {
      title: 'Is Master',
      key: 'isMaster',
      render: (record) => (
        <YesNoDialog
          title='Is Master'
          body={
            <>
              Do you want to switch <strong>isMaster</strong> flag for <strong>{record.tenant_name}</strong>?
            </>
          }
          onYesClick={() => {
            handleSwitchIsMaster(record.tenant_id, !record.isMaster);
          }}
          labelYes='Yes, switch'
          labelNo='Cancel'
        >
          <Switch checkedChildren='Yes' unCheckedChildren='No' checked={record.isMaster} />
        </YesNoDialog>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      align: 'center',
      render: (record) => (
        <Space className='btn'>
          <YesNoDialog
            title='Unassign'
            body={
              <>
                Do you want to unassign tenant <strong>{record.tenant_name}</strong>?
              </>
            }
            onYesClick={() => {
              handleTenantRemove(record.tenant_id);
            }}
            iconYes={<FaCircleMinus />}
            showRed={true}
            labelYes='Yes, unassign'
            labelNo='Cancel'
          >
            <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }}>
              <FaCircleMinus />
            </Button>
          </YesNoDialog>
        </Space>
      ),
    },
  ];

  const [tenants, setTenants] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [isMaster, setIsMaster] = useState(false);
  const [resetSelection, setResetSelection] = useState(false);

  //#region "USE EFFECTS"

  useEffect(() => {
    if (!serviceId) return;
    loadData();
  }, []);

  useEffect(() => {
    if (resetSelection) setResetSelection(false);
  }, [resetSelection]);

  //#endregion

  //#region "LOAD DATA"

  const loadData = async (loadMore = false) => {
    await executeAsyncProcess(async () => {
      try {
        const resp = await getAdminServiceTenants(serviceId);
        setTenants(resp);
      } catch (error) {
        if (true === isRequestCancelled(error)) return;
        console.log(error);
      }
    });
  };

  //#endregion

  //#region "HANDLERS"

  const handleTenantOnPick = (tenant) => {
    setSelectedTenant(tenant);
    // console.log('Tenant:', selectedTenant);
  };

  const handleTenantAdd = async () => {
    if (!selectedTenant) return;
    await executeAsyncProcess(async () => {
      try {
        await putAdminServiceTenant(serviceId, selectedTenant.id, isMaster);
        const newCreated = { tenant_id: selectedTenant.id, isMaster, tenant_name: selectedTenant.name, justCreated: true };
        let t = [...tenants];
        t.unshift(newCreated);
        setTenants(t);
        setSelectedTenant(null);
        setIsMaster(false);
        setResetSelection(true);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Tenant has been assigned',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleTenantRemove = async (tenantId) => {
    await executeAsyncProcess(async () => {
      try {
        await deleteAdminServiceTenant(serviceId, tenantId);
        setTenants(tenants.filter((x) => x.tenant_id !== tenantId));
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Tenant has been unassigned',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleSwitchIsMaster = async (tenantId, isMaster) => {
    await executeAsyncProcess(async () => {
      try {
        await putAdminServiceTenant(serviceId, tenantId, isMaster);
        let t = [...tenants];
        let i = t.findIndex((x) => x.tenant_id === tenantId);
        t[i].isMaster = isMaster;
        setTenants(t);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Tenant assignment has been updated',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  //#endregion

  if (!tenants) return <NoData />;

  return (
    <>
      {contextHolder}
      <div className='px-2 h-14 flex-initial flex flex-row gap-2 overflow-hidden items-center' style={{ backgroundColor: theme.backgroundLight }}>
        {!resetSelection && <TenantPicker width={'300px'} onPick={handleTenantOnPick} />}

        <Checkbox checked={isMaster} onChange={(e) => setIsMaster(e.target.checked)}>
          Is Master
        </Checkbox>
        <Button type='primary' disabled={!selectedTenant || tenants.some((x) => x.tenant_id === selectedTenant.id)} onClick={handleTenantAdd}>
          Assign
        </Button>
      </div>
      <div className='flex-auto overflow-auto' style={{ marginTop: '6px', paddingBottom: '70px' }}>
        <CondensedTable
          size='small'
          pagination={{
            pageSize: 50,
          }}
          columns={columns}
          dataSource={tenants}
          rowKey={'tenant_id'}
          expandable={{
            expandedRowRender: (record) => (
              <div className='ml-14 mt-2'>
                <TeacketServiceEditorPermissions tenantId={record.tenant_id} serviceId={record.service_id} />
              </div>
            ),
          }}
        />
      </div>
    </>
  );
};

TeacketServiceEditorTenants.propTypes = {
  serviceId: PropTypes.string,
};

export default TeacketServiceEditorTenants;
