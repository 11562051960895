//import { objToQueryStringParams } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';

var controller;

export const getSites = async (name) => {
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/sites?search=${encodeURIComponent(name)}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getDriveId = async (siteId) => {
  controller = new AbortController();
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/sites/${siteId}/drive`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getFiles = async (driveId, rootFolderName) => {
  controller = new AbortController();
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/files?driveId=${driveId}&rootFolderName=${rootFolderName}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};
