import React, { useEffect, useState } from 'react';
import useTheme from 'src/hooks/useTheme';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import TitleBar from 'src/components/layout/TitleBar';
import { BsRobot } from 'react-icons/bs';
import { Space, Table, Tag, message } from 'antd';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import StyledButton from 'src/components/layout/StyledButton';
import { FaPlus } from 'react-icons/fa6';
import { ReactComponent as GenesysLogo } from 'src/components/svg/genesys-logo.svg';
import CopilotCreate from './Components/CopilotCreate';
import CopilotTenant from './Components/CopilotTenant';
import { sleepMs } from 'src/misc/Misc';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { uuid } from 'short-uuid';
import CorporateColors from 'src/misc/CorporateColors';

const MasterAdminCopilot = () => {
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: 'AI Assistant Tenant',
      name: 'tenant',
      render: (record) => (
        <Space className='btn'>
          {record.name === 'Genesys SPC' ? (
            <>
              <div style={{ height: '16px', width: '14px', color: CorporateColors.orange }}>
                <GenesysLogo />
              </div>
              <div className='font-roboto'>{record.name}</div>
            </>
          ) : (
            <>
              <HiOutlineOfficeBuilding />
              {record.name}
              {record.justCreated && <Tag color='green'>new</Tag>}
            </>
          )}
        </Space>
      ),
    },
  ];
  const [data, setData] = useState([
    {
      name: 'Genesys SPC',
      configuration: {
        pdfFiles: ['Genesys Security.pdf', 'Genesys Privacy.pdf', 'Genesys Compliance.pdf'],
        urls: ['https://help.mypurecloud.com/', 'https://developer.genesys.cloud/'],
      },
    },
    {
      name: 'Velocity Motors Group',
      configuration: {
        pdfFiles: ['Velocity Motors Group.pdf'],
      },
    },
    {
      name: 'Tasty Bites Ltd',
      configuration: { pdfFiles: ['Tasty Bites Data Privacy.pdf'] },
    },
    {
      name: 'Tech Connect Europe',
      configuration: { pdfFiles: ['Tech Connect - Data Privacy.pdf', 'Tech Connect - GDPR.pdf'] },
    },
  ]);
  const [newTenantOpen, setNewTenantOpen] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedTenantData, setSelectedTenantData] = useState(null);

  useEffect(() => {
    setSelectedTenantData(data.find((x) => x.name === selectedTenant));
  }, [selectedTenant]);

  const rowSelection = {
    onChange: async (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);

      setSelectedTenantData(null);
      await sleepMs(100);
      setSelectedTenant(selectedRowKeys[0] ?? null);
    },
    selectedRowKeys: [selectedTenant],
    type: 'radio',
  };

  const handleOnRow = (record) => {
    return {
      onClick: async () => {
        setSelectedTenantData(null);
        await sleepMs(100);
        setSelectedTenant(record.name);
      },
    };
  };

  return (
    <PermissionsProvider requiredPermissions={['admin.master']}>
      {contextHolder}
      <CopilotCreate
        open={newTenantOpen}
        onClose={() => setNewTenantOpen(false)}
        onSubmit={async (v) => {
          executeAsyncProcess(async () => {
            setNewTenantOpen(false);
            const n = { name: v.name, justCreated: true, configuration: {} };
            setData([...data, n]);
            setSelectedTenantData(null);
            await sleepMs(1000);
            setSelectedTenant(n.name);
            messageApi.open({
              key: uuid(),
              type: 'success',
              content: 'AI Assistant tenant has been created',
              duration: 3,
            });
          });
        }}
      />
      <TitleBar
        title={
          <Space className='btn'>
            <BsRobot />
            AI Assistant Manager
          </Space>
        }
        afterTitleExtras={
          <StyledButton color={theme.textBase} onClick={() => setNewTenantOpen(true)} size='small'>
            <Space className='btn'>
              <FaPlus />
              Create tenant
            </Space>
          </StyledButton>
        }
      ></TitleBar>
      <div className='p-4 h-full max-h-full w-full overflow-hidden'>
        <Splitter direction={SplitDirection.Horizontal} initialSizes={[25, 75]}>
          <div className='mr-4'>
            <Table style={{ marginTop: '1px' }} size='small' columns={columns} dataSource={data} rowKey={'name'} rowSelection={rowSelection} onRow={handleOnRow} />
          </div>
          <div className='ml-4'>{selectedTenantData && <CopilotTenant tenant={selectedTenantData} />}</div>
        </Splitter>
      </div>
    </PermissionsProvider>
  );
};

MasterAdminCopilot.propTypes = {};

export default MasterAdminCopilot;
