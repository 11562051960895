import React, { useContext, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import LoaderCurrentTicket from '../../Loaders/LoaderCurrentTicket';
import NoData from 'src/components/layout/NoData';
import TicketModal from '../../Components/TicketModal';
//import PropTypes from 'prop-types';

const ItemDetails = () => {
  const { myTicketsSelectedTicket, currentTicket, setMyTicketsLayoutItemsSelector } = useContext(TeacketContext);

  useEffect(() => {}, [myTicketsSelectedTicket]);

  return (
    <div className='min-h-full h-full border-l-4 border-t-0 border-r-0 border-b-0 border-solid border-genesys-navy-200 dark:border-genesys-navy-800'>
      {!myTicketsSelectedTicket ? (
        <div className='h-full flex flex-col justify-center'>
          <NoData label='Nothing is selected' />
        </div>
      ) : (
        <>
          <LoaderCurrentTicket ticketId={myTicketsSelectedTicket}>{currentTicket && <TicketModal setLayoutItemsSelector={setMyTicketsLayoutItemsSelector} rootComponentId='layout-root-container' />}</LoaderCurrentTicket>
        </>
      )}
    </div>
  );
};

ItemDetails.propTypes = {};

export default ItemDetails;
