import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//import { ReactSVG } from 'react-svg';
import { red, orange, green } from '@ant-design/colors';
import { ReactComponent as Shield } from 'src/components/svg/shield.svg';
import { MdCancel, MdWarning, MdCheckCircle } from 'react-icons/md';
import NoData from 'src/components/layout/NoData';

const RiskChart = ({ value, width, height, fontSize, iconSize, margin, noBackground, categories }) => {
  const risks = [
    { treshold: 25, label: 'CRITICAL', color: red[5], icon: <MdCancel /> },
    { treshold: 50, label: 'HIGH', color: red[5], icon: <MdCancel /> },
    { treshold: 75, label: 'MEDIUM', color: orange[5], icon: <MdWarning /> },
    { treshold: 100, label: 'LOW', color: green[5], icon: <MdCheckCircle /> },
  ];

  const [risk, setRisk] = useState();

  useEffect(() => {
    for (const r of risks) {
      if (value <= r.treshold) {
        setRisk(r);
        return;
      }
    }
    setRisk({ treshold: 0, label: '', color: '#88888811', icon: null });
  }, [value]);

  if (!risk) return null;

  const calculateRisk = (value) => {
    for (const r of risks) {
      if (value <= r.treshold) {
        return r;
      }
    }
    return { treshold: 0, label: '', color: '#88888811', icon: null };
  };

  if (noBackground) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: risk.color }}>
        <div>
          <div>{risk.icon}</div>
        </div>
        <div className='text-xs'>{risk.label}</div>
      </div>
    );
  }

  return (
    <>
      <div style={{ position: 'relative', margin, width, height }}>
        <div style={{ position: 'absolute', top: 2, right: 0, bottom: 0, left: 0, zIndex: 0 }}>
          <Shield style={{ color: risk.color, width, height, filter: value ? 'drop-shadow(0px 0px 3px gray)' : null }} />
        </div>
        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {!value ? (
            <NoData />
          ) : (
            <>
              <div style={{ fontSize: iconSize, color: 'white' }}>{risk.icon}</div>
              <div style={{ fontSize, color: 'white' }}>{risk.label}</div>
            </>
          )}
        </div>
      </div>

      {categories && categories.length > 0 && (
        <div className='pt-2 pb-4'>
          {categories
            .filter((x) => x.score === 0 || x.score)
            .map((category, index) => (
              <div key={index} style={{ fontSize: '0.8rem', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
                <span className='text-slate-600 dark:text-white'>{category.name}:</span>
                <span style={{ color: calculateRisk(category.score).color, marginLeft: '20px', textAlign: 'right' }}>{calculateRisk(category.score).label}</span>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

RiskChart.propTypes = {
  value: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  iconSize: PropTypes.string,
  fontSize: PropTypes.string,
  margin: PropTypes.string,
  noBackground: PropTypes.bool,
  categories: PropTypes.array,
};

RiskChart.defaultProps = {
  width: '120px',
  margin: '12px',
  height: '160px',
  iconSize: '2.0rem',
  fontSize: '1.0rem',
  noBackground: false,
  categories: [],
};

export default RiskChart;
