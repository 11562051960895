//#region Imports

import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components';
import useTheme from 'src/hooks/useTheme';
import PropTypes from 'prop-types';
import { FiSend } from 'react-icons/fi';
import { FaRegCircleStop } from 'react-icons/fa6';
import { BsPlusLg } from 'react-icons/bs';
import { AiOutlineLoading3Quarters, AiOutlineMenuFold } from 'react-icons/ai';
import { RxHamburgerMenu } from 'react-icons/rx';
import useAutoResizeTextArea from '../../../hooks/useAutoResizeTextArea';
import Message from './Message';
import { getResponse, createConversation, patchConversation, abortRequest, postCopilotFeedback, patchCopilotFeedback } from 'src/api/securityqa';
import { getMyAiModels } from 'src/api/aiModels';
import { getAiTemplates } from 'src/api/aiTemplates';
import SQAConversationContext from 'src/context/SQAConversationContext';
import useUserInfo from 'src/hooks/useUserInfo';
import StyledButton from 'src/components/layout/StyledButton';
import GcPicture from '../UserPicture/GcPicture';
import { notification, Card, Select, Upload, Menu, Input } from 'antd';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import MNDAPopup from './MNDAPopup';
import { GoPaperclip } from 'react-icons/go';
import LoadingScreen from './LoadingScreen';

const { TextArea } = Input;

//#endregion

const StyledDiv = styled.div`
  .react-scroll-to-bottom--css-uzqrz-79elbk {
    position: relative;
  }

  .react-scroll-to-bottom--css-ikyem-1n7m0yu {
    height: 100%;
    overflow-y: auto;
    width: 100%;
  }

  textarea:focus {
    outline: none;
    box-shadow: none;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --foreground-rgb: 255, 255, 255;
      --background-start-rgb: 0, 0, 0;
      --background-end-rgb: 0, 0, 0;
    }
  }

  .ant-select-selection-item {
    color: #dddddd !important;
  }

  .ant-select-arrow {
    color: #dddddd !important;
  }

  .ant-upload-select {
    max-height: 20px;
    max-width: 20px;
    border: 0px !important;
    border-radius: 0px !important;
    background-color: transparent !important;
    margin: 0px !important;
    padding: 0px !important;
  }
`;

const Chat = ({ toggleComponentVisibility }) => {
  const { conversation, setConversation, addMessage, addConversation, resetCurrentMessage, newQuestionMessage, switchingConversation, setSelectedModel, selectedModel, availableModels, setAvailableModels, showPopup, streaming, setStreaming } = useContext(SQAConversationContext);
  const userInfo = useUserInfo();
  const { theme } = useTheme();
  const textAreaRef = useAutoResizeTextArea();
  const bottomOfChatRef = useRef(null);

  //#region Use States

  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [noModelError, setNoModelError] = useState(false);
  const [feedbackId, setFeedbackId] = useState(undefined);
  const [showThanksForFeedback, setShowThanksForFeedback] = React.useState(false);
  const [imagesBase64, setImagesBase64] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [lastTraceMsg, setLastTraceMsg] = useState();
  const [collapsed, setCollapsed] = useState(true);
  const [availableTemplates, setAvailableTemplates] = useState([]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  //#endregion

  //#region Use Effects

  useEffect(() => {
    console.log('Load available AI Models...');
    setAvailableModels([]);
    getAssignedAiModels();
    getTemplates();
  }, []);

  useEffect(() => {
    console.log('show popup from useEffect:', showPopup);
  }, [showPopup]);

  useEffect(() => {
    if (textAreaRef.current && textAreaRef.current.style) {
      textAreaRef.current.style.height = '24px';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
    if (message === '') scrollToBottom();
  }, [message, textAreaRef]);

  useEffect(() => {
    console.log('Conversation updated:', conversation);
    if (conversation) {
      const newModel = availableModels.filter((x) => x.id === conversation.model_id)[0];
      if (newModel) {
        setSelectedModel(newModel);
        setNoModelError(false);
      } else {
        setNoModelError(true);
        setSelectedModel(undefined);
      }
    }
    scrollToBottom();
  }, [conversation]);

  useEffect(() => {
    if (showThanksForFeedback) {
      setTimeout(() => {
        setShowThanksForFeedback(false);
      }, 3000);
    }
  }, [showThanksForFeedback]);

  //#endregion

  const beforeUpload = async (file) => {
    console.log('beforeUpload()', file.type, file.size);

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      console.log('You can only upload JPG/PNG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      console.error('Image must smaller than 3MB!', file.uid);
      notification.warning({ message: 'Warning', description: 'Image must smaller than 3MB!', duration: 5 });
      return;
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Data = reader.result.split(',')[1];
      setImagesBase64([...imagesBase64, { uid: file.uid, base64Data }]);
    };
    reader.readAsDataURL(file);

    return false;
  };

  const onChange = ({ fileList: newFileList }) => {
    console.log(newFileList);
    setImagesList(newFileList);
  };

  const onRemove = (file) => {
    setImagesBase64(imagesBase64.filter((x) => x.uid !== file.uid));
  };

  const getAssignedAiModels = async () => {
    const models = await getMyAiModels();
    console.log('My AI Models:', models);
    setAvailableModels(models);
    const favModel = localStorage.getItem(LOCAL_STORAGE_KEYS.appsCopilotDefaultModelId);

    if (!favModel) {
      setSelectedModel(models[0]);
    } else {
      const newFavModel = models.filter((x) => x.id === favModel)[0];
      if (newFavModel) setSelectedModel(newFavModel);
      else {
        if (models.length > 0) {
          console.log('Saved Model is no longer available, use first available model');
          setSelectedModel(models[0]);
          localStorage.setItem(LOCAL_STORAGE_KEYS.appsCopilotDefaultModelId, models[0].id);
        } else {
          setNoModelError(true);
          setSelectedModel(undefined);
        }
      }
    }
  };

  const getTemplates = async () => {
    const aiTemplates = await getAiTemplates();
    console.log('AI Templates:', aiTemplates);

    let templates = [
      {
        key: 'templates',
        label: 'Templates',
        children: aiTemplates.map((x) => {
          return {
            key: x.id,
            label: x.name,
            onClick: () => {
              const selectedTemplate = aiTemplates.find((template) => template.id === x.id);
              if (selectedTemplate) {
                setMessage(selectedTemplate.content);
              }
            },
          };
        }),
      },
    ];

    setAvailableTemplates(templates);
  };

  const saveSelectedModel = async (modelId) => {
    console.log('Set Model Id:', modelId);
    //if (!conversation?.id) return;
    try {
      const newModel = availableModels.filter((x) => x.id === modelId)[0];
      setSelectedModel(newModel);
      localStorage.setItem(LOCAL_STORAGE_KEYS.appsCopilotDefaultModelId, modelId);

      let newConversation = { ...conversation };
      newConversation.model_id = newModel.id;
      setConversation(newConversation);
      console.log(`Model Updated. ConversationId: ${conversation?.id}, newModelId: ${newModel.id}. Now patch conversation...`);
      console.log('conversation object:', conversation);

      if (conversation?.id) await patchConversation(conversation.id, { model_id: newModel.id });
    } catch (error) {
      console.error(error);
    }
  };

  const scrollToBottom = () => {
    if (bottomOfChatRef.current) {
      bottomOfChatRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const messageReceivedCallback = (messageReceived) => {
    //console.log('messageReceivedCallback:', messageReceived);
    if (!messageReceived) return;

    if (messageReceived === 'Internal Server Error') {
      setErrorMessage('We are currently experiencing technical difficulties. Please try again later.');
      addMessage('We are currently experiencing technical difficulties. Please try again later.', 'system');
      scrollToBottom();
      return;
    }

    if (messageReceived.startsWith('TRACE:')) {
      //console.log(message);
      setLastTraceMsg(messageReceived.replace('TRACE:', ''));
      return;
    }
    addMessage(messageReceived, 'system');
    scrollToBottom();
  };

  const sendMessageToModel = async () => {
    setStreaming(true);

    try {
      const m = message;

      // Clear error messages and user message in text area
      setErrorMessage('');
      setMessage('');

      const currentConversation = await getCurrentConversation(); // TODO: Why we get everytime conversation ??

      console.log('Getting response for conversation:', currentConversation);
      resetCurrentMessage();

      // If images are included, generate Base64 for them

      //if (currentConversation?.id && currentConversation.title === 'New Conversation') await subscribeForEvents(currentConversation.id.toString());

      setImagesBase64([]);
      setImagesList([]);

      // Check for sessionKey (copilot_v2) local storage
      // If it exists, send it to the server

      const copilot_v2 = localStorage.getItem('useCopilot_v2');

      await getResponse(
        m,
        currentConversation.id,
        currentConversation.model_id,
        messageReceivedCallback,
        setErrorMessage,
        currentConversation.title === 'New Conversation',
        false,
        imagesBase64.map((item) => item.base64Data),
        copilot_v2 ? true : false
      );
      console.log('Finished getting response...');

      // Get new Title if current title is 'New Conversation'
      // if (currentConversation.title === 'New Conversation') {
      //   const conversationObj = await getConversation(currentConversation.id);
      //   updateConversationSubject(currentConversation.id, conversationObj.title);
      // }
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
    }
    setStreaming(false);
  };

  const getCurrentConversation = async () => {
    // Show image for user query

    let newMessage = message;

    if (imagesList.length > 0) {
      let messageWithImage = '';

      imagesList.forEach((image) => {
        messageWithImage += `<img src="${image.thumbUrl}" alt="" height="150px" className="pr-2"  />`;
      });

      messageWithImage += '<p>' + message + '</p>';
      newMessage = messageWithImage;
    }

    // <conversation is selected>
    if (conversation) return await newQuestionMessage(newMessage);
    // </conversation is selected>

    // <conversation is not selected, creating a new one>
    let newConversation;

    // Add the conversation to the database

    if (availableModels.length === 0) {
      console.error('No models available');
      notification.error({ newMessage: 'Error', description: 'No Models are assigned to you.', duration: 5 });
      return;
    }

    newConversation = await createConversation(userInfo.id, {
      title: 'New Conversation',
      content: [],
      model_id: selectedModel?.id ?? availableModels[0].id,
    });
    console.log('New Conversation:', newConversation);

    // Add the conversation to the context
    addConversation({
      ...newConversation,
      content: [
        { sender: 'user', message: newMessage },
        { sender: 'system', message: '' },
      ],
    });

    return newConversation;
    // </conversation is not selected, creating a new one>
  };

  const handleBtnOnClick = (e) => {
    if (!streaming && message?.length > 0) sendMessageToModel();
    else if (streaming) {
      // Stop Generating Response
      abortRequest();
    }
  };

  const handleOnKeyDown = (e) => {
    // console.log('handleOnKeyDown()');
    if (e.shiftKey) return;
    if (e.keyCode !== 13) return;
    e.preventDefault();
    if (streaming) return;
    if (message?.length === 0) return;
    sendMessageToModel();
  };

  const reportBadResponse = async (index, notes) => {
    console.log('reportBadResponse() withNotes:', notes);
    try {
      // Mark conversation as "reported bad response"
      conversation.content[index].badResponse = true;
      if (!notes) {
        console.log('Create new feedback');
        const feedback = await postCopilotFeedback({
          model_id: conversation?.model_id,
          conversation_id: conversation.id,
          question: conversation.content[index - 1].message,
          answer: conversation.content[index].message,
          value: 'bad',
        });
        console.log('Feedback created:', feedback);
        setFeedbackId(feedback.id);
      } else if (feedbackId && notes) {
        await patchCopilotFeedback(feedbackId, {
          notes,
        });
      }
      setShowThanksForFeedback(true);
    } catch (error) {
      console.error(error);
      notification.error({ message: 'Error', description: 'Failed to report bad response', duration: 5 });
    }
  };

  const reportGreatResponse = async (index) => {
    console.log('reportGreatResponse()');
    try {
      await postCopilotFeedback({
        model_id: conversation?.model_id,
        conversation_id: conversation.id,
        question: conversation.content[index - 1].message,
        answer: conversation.content[index].message,
        value: 'great',
      });
      setShowThanksForFeedback(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {showPopup && <MNDAPopup open={showPopup} />}
      {selectedModel?.id === 'k8Zdfi43Uq2S2CZQT8Micb' && true === streaming && <LoadingScreen lastTraceMsg={lastTraceMsg} />}

      <StyledDiv className='flex max-w-full flex-1 flex-col bg-gray-800'>
        <div className='sticky top-0 z-10 flex items-center border-b border-white/20 pl-1 pt-1 text-gray-200 sm:pl-3 md:hidden'>
          <button type='button' className='-ml-0.5 -mt-0.5 inline-flex h-10 w-10 items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:hover:text-white' onClick={toggleComponentVisibility}>
            <span className='sr-only'>Open sidebar</span>
            <RxHamburgerMenu className='h-6 w-6 text-white' />
          </button>
          <h1 className='flex-1 text-center text-base font-normal'>New chat</h1>
          <button type='button' className='px-3'>
            <BsPlusLg className='h-6 w-6' />
          </button>
        </div>

        <div className='relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1'>
          <div className={switchingConversation ? 'flex-1 overflow-hidden blur-sm' : 'flex-1 overflow-hidden'}>
            <div className='react-scroll-to-bottom--css-ikyem-79elbk h-full dark:bg-gray-800'>
              <div className='react-scroll-to-bottom--css-ikyem-1n7m0yu'>
                {conversation?.content?.length > 0 ? (
                  <div className='flex flex-col items-center text-sm bg-gray-800'>
                    <div className='flex w-full items-center justify-center gap-1 border-b border-black/10 bg-gray-50 p-3 text-gray-500 dark:border-gray-900/50 dark:bg-gray-700 dark:text-gray-300'>Model: {availableModels.filter((x) => x.id === conversation.model_id)[0]?.name ?? 'n/a'}</div>
                    {conversation.content.map((content, index) => (
                      <Message key={index} content={content} isTyping={index === conversation.content.length - 1 && streaming} reportBadResponse={(notes) => reportBadResponse(index, notes)} reportGreatResponse={() => reportGreatResponse(index)} />
                    ))}
                    <div className='w-full h-32 md:h-48 flex-shrink-0'></div>
                    <div ref={bottomOfChatRef}></div>
                  </div>
                ) : (
                  <>
                    <div className='py-2 relative w-full flex flex-col h-full'>
                      <div className='flex items-center justify-center gap-2'>
                        <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 flex flex-row justify-center'>
                          <div className='relative z-10 flex cursor-pointer items-center overflow-hidden rounded-xl border border-slate-800 p-[1.5px]'>
                            <div className='animate-rotate absolute inset-0 h-full w-full rounded-full bg-[conic-gradient(#0ea5e9_20deg,transparent_120deg)]'></div>
                            <div className={'w-72 relative z-20 flex rounded-[0.60rem] bg-slate-900 p-2'}>
                              <div className={`${imagesList.length > 0 ? 'w-full disabled' : 'w-full '}`}>
                                {availableModels.length > 0 ? (
                                  <Select variant='borderless' size='large' className='w-full' value={selectedModel?.id} onChange={saveSelectedModel}>
                                    {availableModels
                                      .sort((a, b) => a.name.localeCompare(b.name))
                                      .map((model) => (
                                        <Select.Option key={model.id} value={model.id}>
                                          {model.name}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                ) : (
                                  <div className='h-10 w-full flex flex-row items-center justify-center'>
                                    <AiOutlineLoading3Quarters className='animate-spin text-lg' />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-col justify-center items-center pt-2'>
                        <div className='text-gray-200 dark:text-gray-400 text-center'>{selectedModel?.description || <></>}</div>
                      </div>
                      <div className='flex flex-col justify-center items-center h-screen'>
                        <div className='text-gray-200 dark:text-gray-400 w-3/4 max-w-[600px] text-center'>
                          <h1 className='text-2xl lg:text-4xl font-semibold  flex flex-row gap-2 items-center justify-center'>
                            <GcPicture color='#ffffff' bgColor='#fc492b' />
                            How can I help you today?
                          </h1>
                          <p>Welcome to COE AI Assistant, your ultimate guide to navigating the complex landscape of Genesys Cloud security, privacy, and compliance! Much more than just a chat tool, COE AI Assistant is your trusted companion on the journey to ensuring the utmost protection and integrity of your data and operations.</p>
                          <p>
                            This tool is currently in beta testing, anything related to security, privacy and compliance from this tool{' '}
                            <u>
                              <b>can only be shared externally with an email approval</b>
                            </u>{' '}
                            of COE team leadership team: <a href='mailto:GlobalPresalesSecurityLT@genesys.com'>GlobalPresalesSecurityLT@genesys.com</a>
                          </p>
                          <p>
                            If you have any suggestions or improvements, let us know at:
                            <br />
                            <a href='mailto:spc.portal@genesys.com'>spc.portal@genesys.com</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className='flex flex-col items-center text-sm dark:bg-gray-800'></div>
              </div>
            </div>
          </div>

          <div className='bottom-0 left-0 w-full border-t md:border-t-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:bg-vert-light-gradient bg-white dark:bg-gray-800 md:!bg-transparent dark:md:bg-vert-dark-gradient pt-2'>
            {showThanksForFeedback && (
              <span className='flex justify-center pb-4'>
                <Card size='small'>Thank you for your feedback!</Card>
              </span>
            )}
            <form className='stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl'>
              <div className='relative flex flex-col h-full flex-1 items-stretch md:flex-col'>
                {errorMessage ? (
                  <div className='mb-2 md:mb-0'>
                    <div className='h-full flex ml-1 md:w-full md:m-auto md:mb-2 gap-0 md:gap-2 justify-center'>
                      <span className='text-red-500 text-sm'>{errorMessage}</span>
                    </div>
                  </div>
                ) : null}
                {noModelError ? (
                  <div className='mb-2 md:mb-0'>
                    <div className='h-full flex ml-1 md:w-full md:m-auto md:mb-2 gap-0 md:gap-2 justify-center'>
                      <span className='text-red-500 text-sm'>Previously used AI Model is no longer available</span>
                    </div>
                  </div>
                ) : null}
                <div
                  style={{
                    position: 'absolute',
                    top: '7px',
                    left: '-90px',
                    zIndex: 1000, // Ensure the menu appears on top
                    borderRadius: '2px',
                  }}>
                  <Menu mode='horizontal' items={availableTemplates} onClick={toggleCollapsed} overflowedIndicator={<AiOutlineMenuFold />} style={{ backgroundColor: 'transparent' }} />
                </div>
                <Upload maxCount={3} accept='.jpg,.png,.gif' name='images' listType='picture-card' fileList={imagesList} beforeUpload={beforeUpload} onChange={onChange} onRemove={onRemove} showUploadList={{ showPreviewIcon: false }}></Upload>
                <div className='flex flex-col w-full py-2 flex-grow md:py-3 md:pl-4 relative border border-black/10 bg-white dark:border-gray-900/50 dark:text-white dark:bg-gray-700 rounded-md shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)]'>
                  {userInfo.permissions.includes('admin.master') && (
                    <Upload className='absolute pt-2 scale-125 -left-8' maxCount={3} action='https://localhost/error' accept='.jpg,.png,.gif' name='dropHanleInline' listType='none' fileList={imagesList} beforeUpload={beforeUpload} onChange={onChange} onRemove={onRemove} showUploadList={false}>
                      {imagesList.length < 3 && <GoPaperclip />}
                    </Upload>
                  )}
                  <TextArea
                    disabled={noModelError || !selectedModel}
                    ref={textAreaRef}
                    value={message}
                    tabIndex={0}
                    data-id='root'
                    autoFocus
                    style={{
                      height: '24px',
                      maxHeight: '200px',
                      overflowY: 'hidden',
                      fontFamily: "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                    }}
                    // rows={1}
                    placeholder='Do not just type keywords, ask a question or describe your problem in detail'
                    className='m-0 w-full resize-none border-0 bg-transparent pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent dark:text-white pl-2 md:pl-0'
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleOnKeyDown}></TextArea>
                  <div className='absolute bottom-1.5 md:bottom-2.5 right-1 md:right-2 flex items-center'>
                    <StyledButton color={theme.textBase} disabled={!streaming && message?.length === 0 && availableModels.length === 0} onClick={handleBtnOnClick} className='disabled:opacity-40 mr-2 mb-1'>
                      {streaming ? <FaRegCircleStop className='h-4 w-4' /> : <FiSend className='h-4 w-4' />}
                    </StyledButton>
                  </div>
                </div>
              </div>
            </form>
            <div className='px-3 pt-2 pb-3 text-center text-xs text-white/50 dark:text-white/50 md:px-4 md:pt-3 md:pb-6'>
              <span>COE AI Assistant may produce inaccurate information about people, places or facts.</span>
            </div>
          </div>
        </div>
      </StyledDiv>
    </>
  );
};

Chat.propTypes = {
  toggleComponentVisibility: PropTypes.func,
};

export default Chat;
