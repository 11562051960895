import React, { useEffect, useState } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import AdminItems from './Components/AdminItems';
import AdminStats from './Components/AdminStats';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import { MdManageAccounts } from 'react-icons/md';
import { Space } from 'antd';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import HomePageFilter from './Components/HomePageFilter';
//import PropTypes from 'prop-types';

const HomeMasterAdmin = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  const componentsGallery = [
    {
      id: 'What are you going to manage?',
      col: 0,
      component: (
        <DraggableLayoutItem title='What are you going to manage?' titleExtras={<HomePageFilter />}>
          <AdminItems />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Statistics',
      col: 1,
      component: (
        <DraggableLayoutItem title='Admin Stats'>
          <AdminStats />
        </DraggableLayoutItem>
      ),
    },
  ];

  const [layoutItemsSelector, setLayoutItemsSelector] = useState(null);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Admin',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['admin.master', 'admin.tenant']}>
      <TitleBar
        title={
          <Space className='btn'>
            <MdManageAccounts />
            Admin
          </Space>
        }>
        {layoutItemsSelector}
      </TitleBar>

      <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={2} mainColumnIndex={0} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutMasterAdmin} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutMasterAdminHidden} />
    </PermissionsProvider>
  );
};

// HomeMasterAdmin.propTypes = {};

export default HomeMasterAdmin;
