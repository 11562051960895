import React from 'react';
import { Button, Result } from 'antd';

const Page404 = () => {
  return (
    <div>
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        extra={
          <Button type='primary' onClick={() => (window.location.href = '/')}>
            Back Home
          </Button>
        }
      />
    </div>
  );
};

Page404.propTypes = {};

export default Page404;
