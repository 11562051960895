import { red, green, grey, blue, orange } from '@ant-design/colors';
import { MdCancel, MdWarning, MdCheckCircle, MdOutlinePlayCircleFilled } from 'react-icons/md';
import dayjs from 'dayjs';
import { Space, Tooltip } from 'antd';
import { MdInfoOutline } from 'react-icons/md';
import { notEmptyArray } from 'src/misc/Misc';

export const statusList = [
  { key: 'pending', value: 'Pending', color: blue[6], icon: <MdOutlinePlayCircleFilled />, allowRefresh: true, allowStart: false },
  { key: 'processing', value: 'Processing', color: blue[6], icon: <MdOutlinePlayCircleFilled />, allowRefresh: true, allowStart: false },
  { key: 'finalizing', value: 'Finalizing', color: blue[6], icon: <MdOutlinePlayCircleFilled />, allowRefresh: true, allowStart: false },
  { key: 'failed', value: 'Failed', color: red[6], icon: <MdCancel />, allowRefresh: false, allowStart: true },
  { key: 'success', value: 'Success', color: green[6], icon: <MdCheckCircle />, allowRefresh: false, allowStart: true },
  { key: 'aborted', value: 'Aborted', color: orange[6], icon: <MdWarning />, allowRefresh: true, allowStart: true },
  { key: 'default', color: grey[6], icon: <MdWarning />, allowStart: false },
];

export const getFixedScoringColor = (percentage) => {
  if (percentage < 40) return red[5];
  if (percentage < 100) return orange[4];
  return green[5];
};

export const getFixedStatusColor = (status) => {
  if ('failure' === status) return red[5];
  if ('warning' === status) return orange[4];
  if ('success' === status) return green[5];
  return grey[5];
};

export const SA_APP_ID = 'securityadvisor';

export const getEventListColumns = (actionDictionary, entityTypeDictionary) => {
  // console.log('actionDictionary', actionDictionary, entityTypeDictionary);
  return [
    // {
    //   title: 'Service Name',
    //   key: 'serviceName',
    //   align: 'center',
    //   render: (record) => (record?.detail?.eventBody?.serviceName ? <span>{record.detail.eventBody.serviceName}</span> : <span className='ultralight'>n/a</span>),
    // },
    {
      title: 'Entity Type',
      key: 'entityType',
      align: 'center',
      // render: (record) => record.detail.eventBody.entityType ?? 'n/a',
      render: (record) => {
        const dictItem = entityTypeDictionary?.find((item) => item.key === record?.detail?.eventBody?.entityType) ?? null;
        if (dictItem)
          return (
            <Space className='btn'>
              {dictItem?.valueJson?.label ?? 'n/a'}
              <Tooltip title={dictItem?.valueJson?.desc ?? 'No description'}>
                <MdInfoOutline className='opacity-60' />
              </Tooltip>
            </Space>
          );
        return record.detail.eventBody.entityType ?? 'n/a';
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (record) => {
        const dictItem = actionDictionary?.find((item) => item.key === `${record?.detail?.eventBody?.entityType}-${record?.detail?.eventBody?.action}`) ?? null;

        // <Message>
        let message = null;
        if ('AuthUser' === record?.detail?.eventBody?.entityType) {
          message = record?.detail?.eventBody?.message?.message;
        }
        if ('Role' === record?.detail?.eventBody?.entityType) {
          const { roleName, userName } = record?.detail?.extras ?? {};
          message = (
            <>
              role: {roleName ?? 'n/a'}
              <br />
              user: {userName ?? 'n/a'}
            </>
          );
        }
        // </Message>

        if (dictItem)
          return (
            <div>
              <Space className='btn'>
                {dictItem?.valueJson?.label ?? 'n/a'}
                <Tooltip title={dictItem?.valueJson?.desc ?? 'No description'}>
                  <MdInfoOutline className='opacity-60' />
                </Tooltip>
              </Space>
              <div className='text-xs font-extralight opacity-60'>{message}</div>
            </div>
          );
        return record.detail.eventBody.action ?? 'n/a';
      },
    },
    {
      title: 'Remote IP',
      key: 'remoteIp',
      align: 'center',
      render: (record) => (notEmptyArray(record.detail.eventBody.remoteIp) ? record.detail.eventBody.remoteIp.join(', ') : 'n/a'),
    },
    {
      title: 'By user',
      key: 'userentityType',
      align: 'center',
      render: (record) => {
        if (record?.detail?.eventBody?.entityType === 'AuthUser') return record?.detail?.eventBody?.entity?.name ?? 'n/a';
        if (record?.detail?.eventBody?.entityType === 'Role') return record.detail.eventBody?.userDisplay ?? 'n/a';
        return 'n/a';
      },
    },
    {
      title: 'Time',
      key: 'time1',
      align: 'center',
      render: (record) =>
        record.time ? (
          <>
            <div>{dayjs(record.time).fromNow()}</div>
            <div className='font-extralight text-xs'>at {dayjs(record.time).format('lll')}</div>
          </>
        ) : (
          'n/a'
        ),
    },
  ];
};

export const optionsRole = ['Create', 'MemberAdd', 'MemberRemove', 'MemberUpdate', 'Update'];
export const optionsAuthUser = ['AuthenticationFailed', 'Authenticate'];
