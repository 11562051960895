import React, { useContext, useEffect, useState } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import MasterAdminContext from 'src/context/MasterAdminContext';
import { patchAdminMessage, postAdminMessageTest } from 'src/api/notifications';
import { Form, Input, Space, Button, message as messageAntd, Checkbox } from 'antd';
import { uuid } from 'short-uuid';

//import PropTypes from 'prop-types';

const AdminMsgsEditorData = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { adminMessagesSelectedId, adminMessagesList, setAdminMessagesList, adminMessagesSelected, setAdminMessagesSelected, setAdminMessagesTabsDisabled } = useContext(MasterAdminContext);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = messageAntd.useMessage();
  const [modified, setModified] = useState(false);

  useEffect(() => {
    setAdminMessagesTabsDisabled(modified);
  }, [modified]);

  useEffect(() => {
    setModified(false);
    if (!adminMessagesSelected) return;
    form.setFieldsValue(adminMessagesSelected);
  }, [adminMessagesSelected]);

  const handleOnChange = (values) => {
    setModified(true);
  };

  const handleOnReset = () => {
    setModified(false);
  };

  const handleOnFinish = (values) => {
    console.log('handleOnFinish:', values);
    executeAsyncProcess(async () => {
      try {
        const changes = { title: values.title, description: values.description, important: values.important };
        const resp = await patchAdminMessage(adminMessagesSelectedId, changes);
        setAdminMessagesSelected(resp);
        setModified(false);
        updateList(resp);
      } catch (error) {
        console.error('error', error);
      }
    });
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('handleOnFinishFailed:', errorInfo);
  };

  const handleOnTest = async () => {
    console.log('handleOnTest');
    executeAsyncProcess(async () => {
      try {
        const key = uuid();
        messageApi.open({
          key,
          type: 'loading',
          content: 'Sending...',
          duration: 0,
        });
        await postAdminMessageTest(adminMessagesSelectedId);
        messageApi.open({
          key,
          type: 'success',
          content: 'Test notification has been sent',
          duration: 5,
        });
      } catch (error) {
        console.error('error', error);
      }
    });
  };

  const updateList = (resp) => {
    const index = adminMessagesList.findIndex((item) => item.id === adminMessagesSelected.id);
    if (index === -1) return;
    const list = [...adminMessagesList];
    list[index] = resp;
    setAdminMessagesList(list);
  };

  return <div>
    {contextHolder}
    <Form form={form} name='adminMsgsEditor' initialValues={adminMessagesSelected} layout='vertical' onChange={handleOnChange} onReset={handleOnReset} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} autoComplete='off' >
      <Form.Item
        label={<Space><span>Title</span><span className='font-light opacity-60 text-[0.6rem]'>Use HTML tags for formatting</span></Space>}
        name='title'
        rules={[{ required: true, message: "Value can't be empty" }]}>
        <Input placeholder='enter a title' autoComplete='off' maxLength={250} showCount />
      </Form.Item>
      <Form.Item
        label={<Space><span>Description</span><span className='font-light opacity-60 text-[0.6rem]'>Use HTML tags for formatting</span></Space>}
        name='description'
        rules={[{ required: true, message: "Value can't be empty" }]}>
        <Input.TextArea placeholder='enter a description' autoComplete='off' autoSize={{ minRows: 2, maxRows: 6 }} maxLength={2000} showCount />
      </Form.Item>
      <Form.Item name='important' valuePropName='checked'>
        <Checkbox >Important</Checkbox>
      </Form.Item>
      <Form.Item label='Created at' name='created_at'>
        <Input disabled />
      </Form.Item>
      <Form.Item label='Updated at' name='updated_at'>
        <Input disabled />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button htmlType='reset' disabled={!modified}>
            Reset
          </Button>
          <Button disabled={modified} onClick={handleOnTest}>
            Test <span className='text-xs font-light opacity-80'>(send to me)</span>
          </Button>
          <Button type='primary' htmlType='submit' disabled={!modified}>
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  </div >;
};

AdminMsgsEditorData.propTypes = {};

export default AdminMsgsEditorData;
